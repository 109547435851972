import React from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { Row, Col, CardBody, Card, Container } from "reactstrap";

import { resendVerifyLink } from "store/auth/ResendVerifyLink/actions";

import closeImg from "../../../assets/images/close.png";

const LinkExpired = () => {
  //meta title
  document.title = "Link Expire | Greenalytics";
  const dispatch = useDispatch();

  const resendLink = async () => {
    const { value: email } = await Swal.fire({
      input: "email",
      inputLabel: "Your email address",
      inputPlaceholder: "Enter your email address",
      confirmButtonText: "Send",
      confirmButtonColor: "#3c7c44"
    });
    if (email) {
      dispatch(resendVerifyLink(email));
    }
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5" id="register">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={4} xl={4}>
              <Card className="overflow-hidden">
                <CardBody className="pt-5 pb-5">
                  <div style={{ textAlign: "center" }}>
                    <h2>Link Expired!</h2>
                  </div>
                  <div
                    className="p-2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      clear: "both",
                      fontSize: "130px"
                    }}
                  >
                    <img
                      src={closeImg}
                      alt="verified"
                      className="rounded-circle"
                      height="100"
                      width="100"
                    />
                  </div>
                  <div className="mt-3 text-center">
                    <button className="btn btn-primary" onClick={resendLink}>
                      Resend <i className="mdi mdi-arrow-right ms-1"></i>
                    </button>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center copyright-text">
                <p>
                  © {new Date().getFullYear()} Greenalytics. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Glasier Inc.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LinkExpired;
