import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  RESET_LOGIN
} from "./actionTypes";

const initialState = {
  user: null,
  error: null,
  loading: false
};

const login = (state = initialState, action) => {
  switch (action.type) {
  case LOGIN_USER:
    state = {
      ...state,
      loading: true
    };
    break;
  case LOGIN_SUCCESS:
    state = {
      ...state,
      user: action.payload.data,
      error: null,
      loading: false
    };
    break;
  case LOGOUT_USER:
    state = { ...state, error: null, user: null };
    break;
  case LOGOUT_USER_SUCCESS:
    state = { ...state, error: null, user: null };
    break;
  case API_ERROR:
    state = { ...state, error: action.payload.message, user: null, loading: false };
    break;
  case RESET_LOGIN :
    state = { ...state, error: null, user: null, loading: false };
    break;
  default:
    state = { ...state };
    break;
  }
  return state;
};

export default login;
