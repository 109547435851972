import React from "react";
import { set as _set } from "lodash";

const ModalSection = ({ modalData, state, setModalData, setState, setmodal_center }) => {

  const modalhandleChange = e => {
    let currState = _set({ ...state }, e.target.name, e.target.value);
    setModalData({ ...modalData, value: e.target.value });
    setState(currState);
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{modalData.title}</h5>
        <button
          type="button"
          onClick={() => {
            setmodal_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <textarea
          value={modalData.value}
          type={modalData.type}
          name={modalData.name}
          onChange={modalhandleChange}
          required
          className="form-control"
          autoFocus={true}
        ></textarea>
      </div>
    </>
  );
};

export default ModalSection;
