import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import { toast } from "react-toastify";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ErrorMessage, Field, FieldArray, Formik, useFormik } from "formik";
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormFeedback, Input, InputGroup, Row } from "reactstrap";
import { AxiosPost } from "common/axiosService";

function SidebarItem ({
  onUpdateCategory, onChangeCreateForm, onStageSelect, selectedStage, currentStage, onStageChange, stage,
  isMainCategoryOpen, onSubCategoryOpen, onCategorySubmit, item, statusChange, mainCategory, setChangeText, changetext, ...rest
}) {
  const { categoryName, subCategory } = item;
  const apiUrl = process.env.REACT_APP_SUPERADMIN_API_PATH;

  const [, setShowModal] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [otherMenu, setOtherMenu] = useState(false);
  const [searchMenu, setSearchMenu] = useState(false);
  const [stageChange, setStageChange] = useState(stage);
  const [subCategoryList, setSubcategoryList] = useState([]);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [inputBoxList, setInputBoxList] = useState({ users: [{ name: "" }] });
  const [selectedCategory, setSelectedCategory] = useState({ category: "", mainCategory: "" });

  const [oldData, setOldData] = useState();
  const [newData, setNewData] = useState();

  const toggleCollapse = () => setCollapsed(prevValue => !prevValue);

  useEffect(() => {
    setSubcategoryList(subCategory);
  }, [subCategory]);

  useEffect(() => {
    if (subCategoryList.length > 0) {
      const isAnyOpen = subCategoryList.filter(item => item.isOpen);
      if (isAnyOpen.length === 0) {
        onStageChange({ ...stage, [`${currentStage}`]: false });
        setStageChange({ ...stageChange, [`${currentStage}`]: false });
      } else {
        if (currentStage !== parseInt(selectedStage - 1)) {
          onSubcategoryStatusChange();
        }
      }
    }
    (Object.entries(stageChange).filter(item => {
      if (item[0] !== `${parseInt(selectedStage - 1)}`) {
        if (item[1]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }).length.length > 0 ||
      isCategoryOpen) &&
      onSubcategoryStatusChange();
  }, [
    Object.entries(stageChange).filter(item => {
      if (item[0] !== `${parseInt(selectedStage - 1)}`) {
        if (item[1]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }).length,
    isCategoryOpen
  ]);

  /* ==============>>>>>>>>  New Category OnForm Submit  <<<<<<<<============== */
  const submitSubCategory = async (data, mainCategory, addedCategories) => {
    const params = { mainCategoryId: data._id };
    if (mainCategory) {
      const addSubCategory = (data, childId) => {
        if (data._id === childId) {
          let subCategoryArray = data.subCategory.map(item => {
            item.isOpen = false;
            return item;
          });
          if (subCategoryArray.length > 0) {
            let subCategoryData = addedCategories.map(item => ({
              categoryName: item.name,
              isActive: true,
              isOpen: false
            }));
            subCategoryArray = subCategoryArray.concat(subCategoryData);
          } else {
            subCategoryArray = addedCategories.map(item => ({
              categoryName: item.name,
              isActive: true,
              isOpen: false
            }));
          }
          return subCategoryArray;
        } else {
          return data.subCategory.map(subItem => mapData(subItem, childId));
        }
      };
      function mapData (data, childId) {
        const result = {
          ...data, // copy all data
          isOpen: false,
          subCategory: addSubCategory(data, childId)
        };
        return result;
      }
      const mainParams = mainCategory.subCategory.map(d => mapData(d, data._id));
      params["mainCategoryId"] = mainCategory._id;
      params["isNewSubCategoryAdded"] = true;
      params["subCategory"] = mainParams;
      params["typeOfData"] = mainCategory.typeOfData;
    } else {
      let subCategoryData = addedCategories.map(item => ({
        categoryName: item.name,
        isActive: true,
        isOpen: false
      }));
      if (data.subCategory.length > 0) {
        subCategoryData = subCategoryData.concat(data.subCategory);
      }
      params["isNewSubCategoryAdded"] = true;
      params["subCategory"] = subCategoryData;
    }
    try {
      await AxiosPost(`${apiUrl}/update-category-category`, params);
      toast.success("Sub-category Created Successfully.", {
        autoClose: 1000
      });
      setShowModal(true);
      setTimeout(() => {
        onCategorySubmit();
      }, 500);
      toggleCollapse();
      setInputBoxList({ users: [{ name: "" }] });
      // setIsItemsChanged(!isItemsChanged)
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  /* ==============>>>>>>>>  Toggle on Category Open and close  <<<<<<<<============== */
  const onSubcategoryStatusChange = subCategoryData => {
    setSubcategoryList(
      subCategoryList.map(item => {
        if (subCategoryData && item._id === subCategoryData._id) {
          item.isOpen = !item.isOpen;
          setStageChange({ ...stageChange, [`${currentStage}`]: item.isOpen });
          onStageChange({ ...stage, [`${currentStage}`]: item.isOpen });
        } else {
          item.isOpen = false;
        }
        return item;
      })
    );
    if (subCategoryData) {
      onSubCategoryOpen(true);
      // statusChange();
    } else {
      setIsCategoryOpen(false);
    }
  };

  const categoryValidationSchema = Yup.object({
    users: Yup.array().of(Yup.object().shape({ bname: Yup.string().required("Please Enter Category Name") }))
  });

  /* ==============>>>>>>>>  On Sub Category Status Update  <<<<<<<<============== */
  const onIsActiveUpdate = async (data, mainItemData, isFormRemoved) => {
    if (mainItemData) {
      const params = { mainCategoryId: mainItemData._id, typeOfData: mainItemData.typeOfData };
      const updateSubCategory = (data, childData) => {
        if (data._id === childData._id) {
          let subCategoryArray = data.subCategory.map(item => {
            item.isOpen = false;
            return item;
          });
          if (subCategoryArray.length > 0) {
            const subCategoryData = subCategoryArray.map(item => {
              if (item._id === childData._id) {
                childData.isOpen = false;
                return childData;
              } else {
                item.isOpen = false;
                return item;
              }
            });
            return subCategoryData;
          } else {
            return subCategoryArray;
          }
        } else {
          return data.subCategory.map(subItem => mapData(subItem, childData));
        }
      };
      function mapData (data, childData) {
        const result = {
          ...data, // copy all data
          isOpen: false,
          subCategory: updateSubCategory(data, childData)
        };
        return result;
      }
      const mainParams = mainCategory.subCategory.map(d => mapData(d, data));
      params["subCategory"] = mainParams;
      try {
        await AxiosPost(`${apiUrl}/update-category-category`, params);
        setTimeout(() => {
          onCategorySubmit();
          if (isFormRemoved) {
            toast.success("Form Removed Successfully.", {
              autoClose: 1000
            });
          } else {
            toast.success("Sub-category Status Updated Successfully.", {
              autoClose: 1000
            });
          }
        }, 1000);
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    }
  };

  /* ==============>>>>>>>>  On Sub Category Edit Submit  <<<<<<<<============== */
  const handleEditCategory =async categoryName => {
    if (selectedCategory.mainCategory) {
      const params = { mainCategoryId: selectedCategory.mainCategory._id };
      const updateSubCategory = (data, childData) => {
        if (data._id === childData._id) {
          let subCategoryArray = data.subCategory.map(item => {
            item.isOpen = false;
            return item;
          });
          if (subCategoryArray.length > 0) {
            const subCategoryData = subCategoryArray.map(item => {
              if (item._id === childData._id) {
                childData.isOpen = false;
                return childData;
              } else {
                item.isOpen = false;
                return item;
              }
            });
            return subCategoryData;
          } else {
            return subCategoryArray;
          }
        } else {
          return data.subCategory.map(subItem => mapData(subItem, childData));
        }
      };
      function mapData (data, childData) {
        const result = {
          ...data, // copy all data
          isOpen: false,
          subCategory: updateSubCategory(data, childData)
        };
        return result;
      }

      const category = selectedCategory.category;
      category["categoryName"] = categoryName;
      const mainParams = selectedCategory.mainCategory.subCategory.map(d =>
        mapData(d, category, 1)
      );
      params["subCategory"] = mainParams;
      params["typeOfData"] = selectedCategory.mainCategory.typeOfData;
      params["old"] = { ...oldData, categoryId: selectedCategory.mainCategory._id };
      params["new"] = {};
      params["new"][`${newData[0]}`] = categoryName;
      try {
        await AxiosPost(`${apiUrl}/update-category-category`, params);
        setTimeout(() => {
          onCategorySubmit();
          toast.success(" Sub-category Updated Successfully.", {
            autoClose: 1000
          });
        }, 1000);
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    } else {
      const params = { _id: selectedCategory.category._id };
      params["categoryName"] = categoryName;
      params["isActive"] = selectedCategory.category.isActive;
      try {
        await AxiosPost(`${apiUrl}/update-main-category`, params);
        setTimeout(() => {
          toast.success("Category Updated Successfully.", {
            autoClose: 1000
          });
          onCategorySubmit();
        }, 1000);
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      category: selectedCategory.category.categoryName
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Please Enter Category Name")
    }),
    onSubmit: values => {
      handleEditCategory(values.category);
      validation.resetForm();
    }
  });

  return (
    <>
      <ul
        className="sidebar-item main-sub-tab"
        // button
        // dense
        {...rest}
        // onClick={onClick}
      >
        <div className="main-category-box">
          <div className="main-dis cursor-pointer" onClick={toggleCollapse}>
            <div className="sidebar-item-text ">
              <h6 className="accordion-header" id="headingOne">
                {categoryName}
              </h6>
            </div>
            {subCategoryList.length
              ?(collapsed
                ? <ExpandMoreIcon className="sidebar-item-expand-arrow" />
                :<ExpandLessIcon className={"sidebar-item-expand-arrow" + " sidebar-item-expand-arrow-expanded"}/>)
              :""}
          </div>
          <div style={{ display: "flex" }}>
            <Dropdown
              isOpen={otherMenu}
              toggle={() => setOtherMenu(!otherMenu)}
            >
              <DropdownToggle
                tag="span"
                className="dots-icon"
                onClick={() => {
                  statusChange();
                  onChangeCreateForm(false);
                }}
              >
                <i className="bx bx-dots-horizontal-rounded w-100" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={e => {
                    let newparams = [];
                    e.stopPropagation();
                    onUpdateCategory({ category: item, mainCategory: mainCategory });
                    setSelectedCategory({ category: item, mainCategory: mainCategory });
                    setSearchMenu(true);

                    let actualparams = {
                      category: mainCategory.categoryName
                    };
                    function mapData (data, childData, index, parentCategory ) {
                      if (data._id === childData._id) {
                        if(index){
                          newparams.push(`subCategory${index}`);
                          actualparams[`subCategory${index}`] = data.categoryName;
                        }
                        if (index === 1) {
                          return;
                        } else {
                          if (parentCategory) {
                            mainCategory.subCategory?.map(d => {
                              mapData(d, parentCategory, 1);
                            });
                          }
                        }
                      } else {
                        data.subCategory?.map(d => {
                          mapData(d, childData, index + 1, data);
                        });
                      }
                    }
                    const selectCategory = item;
                    mainCategory?.subCategory?.map(d =>mapData(d, selectCategory, 1));
                    setOldData(actualparams);
                    setNewData(newparams);
                  }}
                > Edit </DropdownItem>

                {(item.subCategory.length === 0 && item.data.length === 0) && (
                  <DropdownItem
                    onClick={() => {
                      onUpdateCategory({ category: item, mainCategory: mainCategory });
                      onChangeCreateForm(true, false);
                      setChangeText(true);
                    }}>
                    Create Form
                  </DropdownItem>)}

                {item.data.length > 0 && (
                  <>
                    <DropdownItem
                      onClick={() => {
                        onUpdateCategory({ category: item, mainCategory: mainCategory });
                        onChangeCreateForm(true, true);
                        setChangeText(false);
                      }}
                    > Edit Form </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        const updatedData = item;
                        updatedData["isActive"] = item.isActive;
                        updatedData["isOpen"] = false;
                        updatedData["data"] = [];
                        onIsActiveUpdate(updatedData, mainCategory);
                        // onUpdateCategory({ category: item, mainCategory: "" })
                        // onChangeCreateForm(true, true);
                      }}
                    > Remove Form </DropdownItem>
                  </> )
                }
                {item.subCategory.length === 0 ?
                  item.data.length === 0
                    ? (
                      <DropdownItem
                        href="#"
                        onClick={e => {
                          e.stopPropagation();
                          setIsCategoryOpen(true);
                          onChangeCreateForm(false);
                          onStageSelect(currentStage);
                          statusChange(item);
                        }}
                      > Add Sub Points </DropdownItem>)
                    : null
                  :
                  <DropdownItem
                    href="#"
                    onClick={e => {
                      e.stopPropagation();
                      setIsCategoryOpen(true);
                      onChangeCreateForm(false);
                      onStageSelect(currentStage);
                      statusChange(item);
                    }}
                  > Add Sub Points </DropdownItem>}

                <DropdownItem>
                  <div className="form-check form-switch form-switch-lg">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitch2"
                      checked={item.isActive}
                      onClick={e => {
                        e.stopPropagation();
                        const updatedData = item;
                        updatedData["isActive"] = !item.isActive;
                        updatedData["isOpen"] = false;
                        onIsActiveUpdate(updatedData, mainCategory);
                      }}
                      onChange={() => {
                        const updatedData = item;
                        updatedData["isActive"] = !item.isActive;
                        updatedData["isOpen"] = false;
                        onIsActiveUpdate(updatedData, mainCategory);
                      }}
                    />
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Dropdown
              isOpen={searchMenu}
              toggle={() => {
                setSearchMenu(!searchMenu);
              }}
            >
              <DropdownMenu className="dropdown-menu-end py-0 dropdown-menu-md">
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  className="p-3"
                >
                  <div className="m-0">
                    <InputGroup>
                      <Input
                        name="category"
                        placeholder="Enter Category..."
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                        autoComplete="off"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.category || ""}
                        invalid={validation.touched.category && validation.errors.category ? true : false}
                      />
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={validation.errors.category ? true : false}
                      >
                        update
                      </button>
                      {validation.touched.category && validation.errors.category && (
                        <FormFeedback type="invalid"> {validation.errors.category}  </FormFeedback>
                      )}
                    </InputGroup>
                  </div>
                </Form>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        {item.isOpen && (
          <div className="container add-category-input-box">
            <Formik
              validationSchema={categoryValidationSchema}
              initialValues={inputBoxList}
              // onSubmit={handleSubmit}
            >
              {({ values, errors, touched, setErrors, setTouched }) => (
                // step four form
                <Form className="row">
                  <FieldArray name="users">
                    {({ remove, push }) => (
                      <>
                        <Col md="12">
                          {inputBoxList.users.length > 0 &&
                              inputBoxList.users.map((user, index) => {
                                const userErrors = (errors.users?.length && errors?.users[index]) || {};
                                const userTouched = (touched.users?.length && touched.users[index]) || {};
                                return (
                                  <div
                                    className="position-relative shadow border mb-3 p-3 d-block inner"
                                    key={index + 1}
                                  >
                                    <div data-repeater-list="group-a">
                                      <Row data-repeater-item>
                                        <Col className="col-md-10">
                                          <Field
                                            type="text"
                                            name={`users[${index}].name`}
                                            className={ "form-control" + (userErrors.name && userTouched.name ? " is-invalid" : "") }
                                            placeholder="Enter Category....."
                                          />
                                          <ErrorMessage
                                            name={`users[${index}].name`}
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </Col>
                                        {inputBoxList.users.length - 1 === index ?
                                          <Col lg="1" className="close-btn mt-1 col-md-2" >
                                            <Button
                                              onClick={e => {
                                                e.stopPropagation();
                                                setInputBoxList({ users: [ ...inputBoxList.users, { name: "" } ] });
                                                push({ name: "" });
                                              }}
                                              color="default"
                                              className="btn btn-outline-primary btn-sm btn btn-default"
                                            > + </Button>
                                          </Col>
                                          :
                                          <Col lg="1" className="close-btn">
                                            <i
                                              className="mdi mdi-close-box mdi-close-btn"
                                              onClick={e => {
                                                e.stopPropagation();
                                                setInputBoxList({ users: inputBoxList.users.filter( (dataItem, dataIndex) =>dataIndex !== index ) });
                                                remove(index);
                                              }}
                                            />
                                          </Col>
                                        }
                                      </Row>
                                    </div>
                                  </div>
                                );
                              })}
                        </Col>
                        <Row className="mt-3 next-prev-btn">
                          <Col>
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={e => {
                                e.stopPropagation();
                                setErrors(errors);
                                const filterTouched = inputBoxList.users.map(() => ({ name: true }));
                                setTouched({ users: filterTouched });
                                const checkError = values.users.some( item => item.name === "" );
                                if (!checkError) {
                                  submitSubCategory( item, mainCategory, values.users );
                                }
                                // removeEmptyValue(values, pop);
                              }}
                            > {" "} Save{" "}  </button>
                          </Col>
                          <Col>
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={e => {
                                e.stopPropagation();
                                setInputBoxList({ users: [{ name: "" }] });
                                statusChange(item);
                              }}
                            > {" "} Cancel{" "} </button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </FieldArray>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </ul>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        <List style={{ marginLeft: "10px" }} disablePadding dense>
          {subCategoryList.map((subItem, index) => (
            <React.Fragment key={`${subItem.categoryName}${index}`}>
              <SidebarItem
                onUpdateCategory={onUpdateCategory}
                onChangeCreateForm={onChangeCreateForm}
                onStageSelect={onStageSelect}
                selectedStage={selectedStage}
                onStageChange={onStageChange}
                stage={stageChange}
                onCategorySubmit={onCategorySubmit}
                currentStage={currentStage + 1}
                isMainCategoryOpen={isMainCategoryOpen}
                onSubCategoryOpen={() => onSubCategoryOpen(true)}
                statusChange={onSubcategoryStatusChange}
                item={subItem}
                mainitem={item}
                mainCategory={mainCategory}
                setChangeText={setChangeText}
                changetext={changetext}
              />
            </React.Fragment>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default SidebarItem;
