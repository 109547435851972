import React from "react";

function TableTextArea (props) {
  const { name, type = "text", className, isDisabled = false, value, onChange, onFocus, colSpan, title, placeholder } = props;
  return <td className={className} colSpan={colSpan}>
    <textarea
      onChange={onChange}
      onFocus={(e) => onFocus && onFocus(e, title, name)}
      value={value}
      name={name}
      type={type}
      disabled={isDisabled}
      placeholder={placeholder}
      className="form-control"
    />
  </td>;
}

export default TableTextArea;