import React, { useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import { toast } from "react-toastify";
import { AxiosGet } from "common/axiosService";

import Loading from "components/Common/Loading";
import IsRoleAuth from "pages/Authentication/IsRoleAuth";
import TableContainer from "components/Common/TableContainer";
import { CompanyName, Phone, Website, PersonalUrl } from "./contactlistCol";

import "../../../../src/assets/scss/datatables.scss";
import "react-toastify/dist/ReactToastify.css";

const CompaniesList = () => {
  const apipath = process.env.REACT_APP_SUPERADMIN_API_PATH;

  const [getdata, setGetData] = useState(false);
  const [showloader, setShowLoader] = useState(false);
  const [companiesdata, setCompaniesData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/companies`);
        if (response && response.statusCode === 200) {
          setShowLoader(true);
          setCompaniesData(response.data);
        } else {
          setShowLoader(true);
          return;
        }
      } catch (error) {
        setShowLoader(true);
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, [getdata]);

  const changeStatus = async id => {
    try {
      const response = await AxiosGet(`${apipath}/company-status-update/${id}`);
      if (response) {
        setGetData(prevState => !prevState);
        toast.success("Company Status Updated Successfully", {
          autoClose: 2000
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "CompanyName",
        accessor: "companyName",
        filterable: true,
        Cell: cellProps => <CompanyName {...cellProps} />
      },
      {
        Header: "Phone",
        accessor: "phoneNumber",
        filterable: true,
        Cell: cellProps => <Phone {...cellProps} />
      },
      {
        Header: "Website",
        accessor: "projects",
        filterable: true,
        Cell: cellProps => <Website {...cellProps} />
      },
      {
        Header: "PersonalUrl",
        accessor: "personalUrl",
        filterable: true,
        Cell: cellProps => <PersonalUrl {...cellProps} />
      },
      {
        Header: "Action",
        Cell: cellProps => (
          <div className="form-check form-switch mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="customSwitch2"
              checked={cellProps.row.original.isActive}
              onClick={e => editGeneral(e.target, cellProps.row.original._id)}
              onChange={()=>{}}
            />
          </div>
        )
      },
      {
        Header: "Company details",
        Cell: cellProps => (
          <Link
            to={`/superadmin/company/view-company-details/${cellProps.row.original._id}`}
            color="link"
            className="btn btn-link "
          > View </Link>
        )
      }
    ],
    []
  );

  function editGeneral (el, id) {
    Swal.fire({
      text: `Are you sure you want to ${el.checked ? "Deactivate" : "Activate" }  this company`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      cancelButtonText: "No"
    }).then(result => {
      if (result.isConfirmed) {
        el.checked ? (el.checked = true) : (el.checked = false);
        changeStatus(id);
      }
      if (!result.isConfirmed) {
        el.checked ? (el.checked = true) : (el.checked = false);
      }
    }).catch(err => console.log(err));
  }

  return showloader
    ? (
      <React.Fragment>
        <IsRoleAuth />
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={companiesdata}
                      isGlobalFilter={true}
                      customPageSize={10}
                      isCustomPageSize={true}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
    : (
      <Loading />
    );
};

export default CompaniesList;
