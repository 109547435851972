
import React from "react";
import dateFormat from "dateformat";
import { Dots } from "react-activity";
import { useState, useEffect } from "react";
import { Button, Card, CardBody, Col, Table } from "reactstrap";

import { AxiosGet } from "common/axiosService";
import { Link } from "react-router-dom";

const Activity = ({ loading, setLoading, toast }) => {
  const [savedraft, setSavedraft] = useState([]);
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;

  /*getting data on mounting fase*/
  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/get-all-sebi-data`);
        if (response && response.statusCode === 200) {
          setSavedraft(response.data);
          setLoading((loading) => ({
            ...loading, logs: true
          }));
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, []);

  const disand = savedraft && savedraft.filter((list) => !list.isReportComplete);

  return (
    <>
      <Col lg={6}>
        <Card>
          <CardBody>
            <div className="title">
              <h5>Draft Reports</h5>
            </div>
            {loading.logs ?
              <div className="table-responsive h-315 mt-3">
                <Table className="table table-nowrap align-middle table-hover mb-0">
                  <tbody>
                    {disand.length >= 1
                      ? disand.map((file, i) =>{
                        if(i > 4) return;
                        return(
                          <tr key={"_file_" + i}>
                            <td>
                              <h5 className="text-truncate font-size-14 mb-1">
                                <Link to="#" className="text-dark">
                                      BRSR REPORT-{file.year}
                                </Link>
                              </h5>
                              <p className="text-muted mb-0">Last Update: {dateFormat(`${file.modifiedAt}`, "mmm dS yyyy, h:MM TT")}</p>
                            </td>
                            <td width="4%">
                              <Link to={`/admin/report/brsr/${file.year}`} className="text-success p-1">
                                <Button color="light" className="btn btn-soft-success">Continue</Button>
                              </Link>
                            </td>
                          </tr>
                        );}
                      ):
                      <h4 className="react-activity">No Data Found</h4>
                    }
                  </tbody>
                </Table>
                {disand.length >= 1 &&
                <div className="user-btn">
                  <Link to={"/admin/draft"} className="add-location-btn">
                    <button type="button" className="btn btn-primary waves-effect waves-light text-right">View More</button>
                  </Link>
                </div>

                }
              </div>
              :
              <div className="react-activity">
                <Dots color="#3C7C44" size={20} speed={0.6} />
              </div>
            }
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
export default Activity;
