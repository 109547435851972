import * as actionTypes from "./actionTypes";
import { API_URLS } from "common/constants";
import { AxiosGet, AxiosPost } from "common/axiosService";
import { toast } from "react-toastify";

export const getSebiForm = params => async dispatch => {
  dispatch({ type: actionTypes.LOADING_SEBI_FORM, payload: true });
  try {
    const response = await AxiosGet(`${API_URLS.SEBI_DETAILS}/${params.year}`);
    if (response && response.statusCode === 200) {
      if (response.status) {
        dispatch({
          type: actionTypes.GET_SEBI_FORM,
          payload: response.data[0]
        });
      }
    }
  } catch (error) {
    toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
  }
};
export const draftSebiData = params => async dispatch => {
  dispatch({
    type: actionTypes.DRAFT_SEBI_FORM,
    payload: params
  });
};

export const addSebiData = params => async dispatch => {
  dispatch({ type: actionTypes.LOADING_SEBI_FORM, payload: true });
  try {
    const response = await AxiosPost(API_URLS.ADD_SEBI, params);
    if (response && response.statusCode === 200) {
      if (response.status) {
        dispatch({ type: actionTypes.ADD_SEBI_FORM });
      }
    }
  } catch (error) {
    toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
  }
};

export const updateSebiData = params => async dispatch => {
  dispatch({ type: actionTypes.LOADING_SEBI_FORM, payload: true });
  try {
    const response = await AxiosPost(API_URLS.ADD_SEBI, params);
    if (response && response.statusCode === 200) {
      toast.success("Data updated successfully.", { toastId: "success" });
      const payload = {
        sectionA: response.data[0]?.sectionA,
        sectionB: response.data[0]?.sectionB,
        sectionC: response.data[0]?.sectionC,
        year: response.data[0]?.year
      };
      dispatch({
        type: actionTypes.UPDATE_SEBI_FORM,
        payload: { currentYear: payload }
      });
    }
  } catch (error) {
    toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
  }
};

export const getFile = params => async dispatch => {
  try {
    const response = await AxiosPost(`${API_URLS.GET_FILE}`, params);
    if (response && response.statusCode === 200) {
      if (response.status) {
        dispatch({
          type: actionTypes.GET_FILES,
          payload: { [params.subSection]: response.data[0]?.files }
        });
      }
    }
  } catch (error) {
    toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
  }
};