import { AxiosGet } from "common/axiosService";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, DropdownToggle, DropdownMenu, Label, Row, UncontrolledDropdown } from "reactstrap";
import Slider from "react-slick";
import Questions from "./supportingName.json";
import { API_URLS } from "common/constants";
import Loading from "components/Common/Loading";
import { toast } from "react-toastify";

function index () {
  const { directyear } = new useParams();
  const userToken = localStorage.getItem("accessToken");
  const [year, setYear] = useState(directyear);
  const [docList, setDocList] = useState();
  const [loading, setLoading] = useState(false);
  const years = Array.from(
    { length: 25 },
    (_, i) => new Date().getFullYear() - i
  );
  const handleYearChange = (e) => {
    setLoading(true);
    setYear(e.target.value);
    getSupportingDoc(e.target.value);
  };
  const getSupportingDoc = async (selectedYear) => {
    const res = await AxiosGet(`${API_URLS.SUPPORTING_DOC}/${selectedYear}`);
    if (res.statusCode === 200) {
      setDocList(res?.data[0]);
    } else {
      toast.error("Data Not Found.");
    }
    setLoading(false);
  };
  useEffect(() => {
    getSupportingDoc(directyear);
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3, infinite: true, dots: true } },
      { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 2, initialSlide: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } }
    ]
  };

  return (
    <div className="page-content">
      {loading && <Loading />}
      <Container fluid={true}>
        <Card>
          <CardBody>
            <Row>
              <h3 className="mb-4" style={{ width: "50%" }}>Supporting Doc</h3>
              <Col
                lg={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "left",
                  width: "50%"
                }}
              >
                <Col lg={6} >
                  <Label>Select Year</Label>
                  <select
                    className="form-select"
                    defaultValue={year}
                    onChange={e => handleYearChange(e)}
                  >
                    <option disabled value="">Select Year</option>
                    {years.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </Col>
              </Col>
            </Row>
            <div className="row">
              <div className="col-md-12 tree-tab">
                <div className="tab-content mt-4">
                  <div className="tab-pane active tree-view" id="transactions-all-tab" role="tabpanel">
                    <div id="collapseDVR3" className="panel-collapse collapse in">
                      <div className="tree">
                        {
                          docList && <ul>
                            {
                              docList?.sectionA?.length > 0 && <li className="scope parent_li">
                                <span ><i className="fa fa-caret-down"></i>section A</span>
                                <ul>
                                  {
                                    docList?.sectionA.map(item => <li key={item?.subSection}>
                                      <span onClick={() => {
                                        const elm = document.getElementById(item?.subSection);
                                        const iconElem = document.getElementById(`${item?.subSection}_`);
                                        elm.classList.toggle("displayNone");
                                        if (iconElem.classList.contains("fa-caret-right")) {
                                          iconElem.classList.remove("fa-caret-right");
                                          iconElem.classList.add("fa-caret-down");
                                        } else {
                                          iconElem.classList.remove("fa-caret-down");
                                          iconElem.classList.add("fa-caret-right");
                                        }
                                      }} className="subsectionCapital"><i id={`${item?.subSection}_`} className="fa fa-caret-right"></i>{item?.subSection?.split("_").join(" ")}</span>
                                      <ul id={item?.subSection} className="displayNone">
                                        {
                                          Object.entries(item?.files)?.map(subItem => <li key={subItem[0]}>
                                            <p onClick={() => {
                                              const elm = document.getElementById(subItem[0]);
                                              const iconElem = document.getElementById(`${subItem[0]}_`);
                                              elm.classList.toggle("displayNone");
                                              if (iconElem.classList.contains("fa-caret-right")) {
                                                iconElem.classList.remove("fa-caret-right");
                                                iconElem.classList.add("fa-caret-down");
                                              } else {
                                                iconElem.classList.remove("fa-caret-down");
                                                iconElem.classList.add("fa-caret-right");
                                              }
                                            }}>
                                              <i className="fa fa-caret-right" id={`${subItem[0]}_`}></i>
                                              {Questions[subItem[0]]}</p>
                                            <ul id={subItem[0]} className="tree-box displayNone">
                                              <li >
                                                <Slider {...settings}>
                                                  {
                                                    subItem[1]?.map(InnerItem => {
                                                      let ext = InnerItem.slice((InnerItem.lastIndexOf(".") - 1 >>> 0) + 2);
                                                      return <div key={InnerItem} className="shadow-none border card" style={{ backgroundColor: "#eaeaef" }}>
                                                        <div className="card-body">
                                                          <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                                            <UncontrolledDropdown direction="left mt-2">
                                                              <DropdownToggle
                                                                color="white"
                                                                className="btn btn-link text-muted mt-n2"
                                                              >
                                                                <i className="mdi mdi-dots-vertical bg-light text-secondary" style={{ marginRight: "0px" }}></i>
                                                              </DropdownToggle>
                                                              <DropdownMenu id={InnerItem}>
                                                                <button type="button" className="dropdown-item"
                                                                  onClick={ async () => {
                                                                    try {
                                                                      const res = await AxiosGet(`${API_URLS.DOWNLOAD_SUPPORTING_DOC}/${InnerItem}/${userToken}`);
                                                                      if (res && (res.statusCode === 200 && res.data[0])) {
                                                                        let a = document.createElement("a");
                                                                        a.download = InnerItem;
                                                                        a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                                        a.click();
                                                                        a.remove();
                                                                        toast.success("Download Succesfully", { toastId: "Download_Succesfully" });
                                                                        // document?.getElementById(InnerItem).classList.remove("show");
                                                                      }
                                                                    } catch (error) {
                                                                      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                                                    }
                                                                  }}
                                                                >Download</button>
                                                              </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                          </div>
                                                          <div className="avatar-xs me-3 mb-3">
                                                            <div className="avatar-title bg-transparent rounded">
                                                              {ext === "sheet"
                                                                ? <i className="fas fa-file-csv font-size-24 align-middle text-primary bg-light"></i>
                                                                : ext === "png"
                                                                  ? <i className="mdi mdi-image font-size-24 align-middle text-info bg-light"></i>
                                                                  : ext === "pdf"
                                                                    ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger bg-light"></i> :
                                                                    <i className="mdi mdi-text-box font-size-24 text-warning bg-light"></i>
                                                              }
                                                            </div>
                                                          </div>
                                                          <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{InnerItem}</h5>
                                                        </div>
                                                      </div>;
                                                    })
                                                  }
                                                </Slider>
                                              </li>
                                            </ul>
                                          </li>)
                                        }
                                      </ul>
                                    </li>)
                                  }
                                </ul>
                              </li>
                            }
                            {
                              docList?.sectionB?.length > 0 && <li className="scope">
                                <span><i className="fa fa-caret-down"></i>section B</span>
                                <ul>
                                  {
                                    docList?.sectionB.map(item => <li key={item?.subSection}>
                                      <span onClick={() => {
                                        const elm = document.getElementById(item?.subSection);
                                        const iconElem = document.getElementById(`${item?.subSection}_`);
                                        elm.classList.toggle("displayNone");
                                        if (iconElem.classList.contains("fa-caret-right")) {
                                          iconElem.classList.remove("fa-caret-right");
                                          iconElem.classList.add("fa-caret-down");
                                        } else {
                                          iconElem.classList.remove("fa-caret-down");
                                          iconElem.classList.add("fa-caret-right");
                                        }
                                      }} className="subsectionCapital"><i id={`${item?.subSection}_`} className="fa fa-caret-right"></i>{item?.subSection?.split("_").join(" ")}</span>
                                      <ul id={item?.subSection} className="displayNone">
                                        {
                                          Object.entries(item?.files)?.map(subItem => <li key={subItem[0]}>
                                            <p onClick={() => {
                                              const elm = document.getElementById(subItem[0]);
                                              const iconElem = document.getElementById(`${subItem[0]}_`);
                                              elm.classList.toggle("displayNone");
                                              if (iconElem.classList.contains("fa-caret-right")) {
                                                iconElem.classList.remove("fa-caret-right");
                                                iconElem.classList.add("fa-caret-down");
                                              } else {
                                                iconElem.classList.remove("fa-caret-down");
                                                iconElem.classList.add("fa-caret-right");
                                              }
                                            }}>
                                              <i className="fa fa-caret-right" id={`${subItem[0]}_`}></i>
                                              {Questions[subItem[0]]}</p>
                                            <ul id={subItem[0]} className="tree-box displayNone">
                                              <li >
                                                <Slider {...settings}>
                                                  {
                                                    subItem[1]?.map(InnerItem => {
                                                      let ext = InnerItem.slice((InnerItem.lastIndexOf(".") - 1 >>> 0) + 2);
                                                      return <div key={InnerItem} className="shadow-none border card" style={{ backgroundColor: "#eaeaef" }}>
                                                        <div className="card-body">
                                                          <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                                            <UncontrolledDropdown direction="left mt-2">
                                                              <DropdownToggle
                                                                color="white"
                                                                className="btn btn-link text-muted mt-n2"
                                                              >
                                                                <i className="mdi mdi-dots-vertical bg-light text-secondary" style={{ marginRight: "0px" }}></i>
                                                              </DropdownToggle>
                                                              <DropdownMenu id={InnerItem}>
                                                                <button type="button" className="dropdown-item"
                                                                  onClick={ async () => {
                                                                    try {
                                                                      const res = await AxiosGet(`${API_URLS.DOWNLOAD_SUPPORTING_DOC}/${InnerItem}/${userToken}`);
                                                                      if (res && (res.statusCode === 200 && res.data[0])) {
                                                                        let a = document.createElement("a");
                                                                        a.download = InnerItem;
                                                                        a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                                        a.click();
                                                                        a.remove();
                                                                        toast.success("Download Succesfully");
                                                                        document?.getElementById(InnerItem).classList.remove("show");
                                                                      }
                                                                    } catch (error) {
                                                                      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                                                    }
                                                                  }}
                                                                >Download</button>
                                                              </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                          </div>
                                                          <div className="avatar-xs me-3 mb-3">
                                                            <div className="avatar-title bg-transparent rounded">
                                                              {ext === "sheet"
                                                                ? <i className="fas fa-file-csv font-size-24 align-middle text-primary bg-light"></i>
                                                                : ext === "png"
                                                                  ? <i className="mdi mdi-image font-size-24 align-middle text-info bg-light"></i>
                                                                  : ext === "pdf"
                                                                    ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger bg-light"></i> :
                                                                    <i className="mdi mdi-text-box font-size-24 text-warning bg-light"></i>
                                                              }
                                                            </div>
                                                          </div>
                                                          <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{InnerItem}</h5>
                                                        </div>
                                                      </div>;
                                                    })
                                                  }
                                                </Slider>
                                              </li>
                                            </ul>
                                          </li>)
                                        }
                                      </ul>
                                    </li>)
                                  }
                                </ul>
                              </li>
                            }
                            {
                              docList?.sectionC?.length > 0 && <li className="scope">
                                <span><i className="fa fa-caret-down"></i>section C</span>
                                <ul>
                                  {
                                    docList?.sectionC.map(item => <li key={item?.subSection}>
                                      <span onClick={() => {
                                        const elm = document.getElementById(item?.subSection);
                                        const iconElem = document.getElementById(`${item?.subSection}_`);
                                        elm.classList.toggle("displayNone");
                                        if (iconElem.classList.contains("fa-caret-right")) {
                                          iconElem.classList.remove("fa-caret-right");
                                          iconElem.classList.add("fa-caret-down");
                                        } else {
                                          iconElem.classList.remove("fa-caret-down");
                                          iconElem.classList.add("fa-caret-right");
                                        }
                                      }} className="subsectionCapital"><i id={`${item?.subSection}_`} className="fa fa-caret-right"></i>{item?.subSection?.split("_").join(" ")}</span>
                                      <ul id={item?.subSection} className="displayNone">
                                        {
                                          Object.entries(item?.files)?.map(subItem => <li key={subItem[0]}>
                                            <p onClick={() => {
                                              const elm = document.getElementById(subItem[0]);
                                              const iconElem = document.getElementById(`${subItem[0]}_`);
                                              elm.classList.toggle("displayNone");
                                              if (iconElem.classList.contains("fa-caret-right")) {
                                                iconElem.classList.remove("fa-caret-right");
                                                iconElem.classList.add("fa-caret-down");
                                              } else {
                                                iconElem.classList.remove("fa-caret-down");
                                                iconElem.classList.add("fa-caret-right");
                                              }
                                            }}>
                                              <i className="fa fa-caret-right" id={`${subItem[0]}_`}></i>
                                              {Questions[subItem[0]]}</p>
                                            <ul id={subItem[0]} className="tree-box displayNone">
                                              <li >
                                                <Slider {...settings}>
                                                  {
                                                    subItem[1]?.map(InnerItem => {
                                                      let ext = InnerItem.slice((InnerItem.lastIndexOf(".") - 1 >>> 0) + 2);
                                                      return <div key={InnerItem} className="shadow-none border card" style={{ backgroundColor: "#eaeaef" }}>
                                                        <div className="card-body">
                                                          <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                                            <UncontrolledDropdown direction="left mt-2">
                                                              <DropdownToggle
                                                                color="white"
                                                                className="btn btn-link text-muted mt-n2"
                                                              >
                                                                <i className="mdi mdi-dots-vertical bg-light text-secondary" style={{ marginRight: "0px" }}></i>
                                                              </DropdownToggle>
                                                              <DropdownMenu id={InnerItem}>
                                                                <button type="button" className="dropdown-item"
                                                                  onClick={ async () => {
                                                                    try {
                                                                      const res = await AxiosGet(`${API_URLS.DOWNLOAD_SUPPORTING_DOC}/${InnerItem}/${userToken}`);
                                                                      if (res && (res.statusCode === 200 && res.data[0])) {
                                                                        let a = document.createElement("a");
                                                                        a.download = InnerItem;
                                                                        a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                                        a.click();
                                                                        a.remove();
                                                                        toast.success("Download Succesfully");
                                                                        document?.getElementById(InnerItem).classList.remove("show");
                                                                      }
                                                                    } catch (error) {
                                                                      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                                                    }
                                                                  }}
                                                                >Download</button>
                                                              </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                          </div>
                                                          <div className="avatar-xs me-3 mb-3">
                                                            <div className="avatar-title bg-transparent rounded">
                                                              {ext === "sheet"
                                                                ? <i className="fas fa-file-csv font-size-24 align-middle text-primary bg-light"></i>
                                                                : ext === "png"
                                                                  ? <i className="mdi mdi-image font-size-24 align-middle text-info bg-light"></i>
                                                                  : ext === "pdf"
                                                                    ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger bg-light"></i> :
                                                                    <i className="mdi mdi-text-box font-size-24 text-warning bg-light"></i>
                                                              }
                                                            </div>
                                                          </div>
                                                          <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{InnerItem}</h5>
                                                        </div>
                                                      </div>;
                                                    })
                                                  }
                                                </Slider>
                                              </li>
                                            </ul>
                                          </li>)
                                        }
                                      </ul>
                                    </li>)
                                  }
                                </ul>
                              </li>
                            }
                          </ul>
                        }
                        {docList &&docList?.sectionA?.length === 0 &&docList?.sectionB?.length === 0 && docList?.sectionC?.length === 0 &&<div> No Data</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default index;