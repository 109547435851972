import React, { useEffect, useState } from "react";
import _ from "lodash";
import jsPDF from "jspdf";
import moment from "moment";
import Select from "react-select";
import html2canvas from "html2canvas";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { toast } from "react-toastify";
import TestChart from "./mines/TestChart";
import { API_URLS } from "common/constants";
import { AxiosGet } from "common/axiosService";
import Loading from "components/Common/Loading";
import CarbonChart from "../Carbon/mines/TestChart";
import logo from "../../../assets/images/logo-lg.png";
// import Consolidate from "../Carbon/mines/consolidate";
import SocialTestChart from "../Social/mines/TestChart";
import IsRoleAuth from "pages/Authentication/IsRoleAuth";

const Index = () => {
  document.title = "Analytics | Greenalytics";
  const analyticsList = [
    { value: "Environmental", label: "Environmental", city: "Environmental" },
    { value: "Social", label: "Social", city: "Social" },
    { value: "Governance", label: "Governance", city: "Governance" },
    { value: "Greenhouse Gas", label: "Greenhouse Gas", city: "Greenhouse Gas" }
    // { value: "Carbon Footprint", label: "Carbon Footprint", city: "Carbon Footprint" }
  ];
  const authorizedUser = localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser"));
  const [datatype, setDataType] = useState([]);
  const [datatypeList, setDataTypeList] = useState([]);
  const [datatypeMainList, setDataTypeMainList] = useState([]);
  const [loading, setloading] = useState(true);
  const [applyDisable, setApplyDisable] = useState(false);
  const [level, setLevel] = useState(0);
  // const [chartList, setChartList] = useState();
  const [newchartList, setNewChartList] = useState();
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  // const [carbonAllChartData, setCarbonAllChartData] = useState([]);
  const [greenHouseAllChartData, setGreenHouseAllChartData] = useState();
  const [carbonIntencityChartData, setCarbonIntencityChartData] = useState();
  const [selectedAnalytics, setSelectedAnalytics] = useState({ value: "Environmental", label: "Environmental", city: "Environmental" });
  const [fullChart, setFullChart] = useState({
    showPredictiveChart: false, mainCategory: "", mainHeading: "", activeStandAloneChartTitle: "", selectedChart: [], chartType: "", predictedChart: "", isPredictive: false, predictiveYears: 2
  });
  const fetchChartData = async (locationId, analytics) => {
    if (authorizedUser) {
      setloading(true);
      try {
        if (analytics === "Greenhouse Gas") {
          fetchGreenhouseAllChartData(locationId._id);
          fetchCarbonIntencityChartData(locationId._id);
        }
        const response = await AxiosGet(`${API_URLS.GET_CHART_DATA}?locationId=${locationId?._id}&typeOfData=${analytics}&categoryIds=${JSON.stringify(datatype)}`);
        if (response && response.statusCode === 200) {
          let filterArray = [];
          Object.entries(Object.values(response.data[0])[0]).map(
            (item) => {
              const splitedTitle = item[0]?.split("_");
              if (filterArray.length > 0 && filterArray.some(filteritem => filteritem?.categoryName === splitedTitle[0])) {
                filterArray = filterArray.map(filteritem => {
                  if (filteritem?.categoryName === splitedTitle[0]) {
                    filteritem.chartList = {
                      [`${locationId.city}`]: {
                        ...filteritem.chartList?.[`${locationId.city}`],
                        [splitedTitle[1]]: item[1]
                      }
                    };
                  }
                  return filteritem;
                });
              } else {
                filterArray = [...filterArray, {
                  categoryName: splitedTitle[0],
                  chartList: {
                    [`${locationId.city}`]: {
                      [splitedTitle[1]]: item[1]
                    }
                  }
                }];
              }
            });
          if (filterArray.length > 0) {
            setNewChartList(filterArray);
          } else {
            setNewChartList();
          }
          // setChartList(response.data[0]);
          setloading(false);
        }
        setloading(false);
      } catch (error) {
        setloading(false);
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    }
  };
  const getCarbonChartData = async (locationId) => {
    if (authorizedUser) {
      setloading(true);
      try {
        const response = await AxiosGet(`${API_URLS.CARBON_CHART}?locationId=${locationId}`);
        if (response && response.statusCode === 200) {
          // setChartList(response.data[0]);
          setloading(false);
        }
        setloading(false);
      } catch (error) {
        setloading(false);
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    }
  };

  const fetchAllChartData = async (analytics) => {
    setloading(true);
    try {
      if (analytics === "Greenhouse Gas") {
        fetchGreenhouseAllChartData();
        fetchCarbonIntencityChartData();
      }
      const response = await AxiosGet(`${API_URLS.GET_ALL_CHART_DATA}/${analytics}?categoryIds=${JSON.stringify(datatype)}`);
      if (response && response.statusCode === 200) {
        let filterArray = [];
        Object.entries(Object.values(response.data[0])[0]).map(
          (item) => {
            const splitedTitle = item[0]?.split("_");
            if (filterArray.length > 0 && filterArray.some(filteritem => filteritem?.categoryName === splitedTitle[0])) {
              filterArray = filterArray.map(filteritem => {
                if (filteritem?.categoryName === splitedTitle[0]) {
                  filteritem.chartList = {
                    consolidatedData: {
                      ...filteritem.chartList?.consolidatedData,
                      [splitedTitle[1]]: item[1]
                    }
                  };
                }
                return filteritem;
              });
            } else {
              filterArray = [...filterArray, {
                categoryName: splitedTitle[0],
                chartList: {
                  consolidatedData: {
                    [splitedTitle[1]]: item[1]
                  }
                }
              }];
            }
          });
        if (filterArray.length > 0) {
          setNewChartList(filterArray);
        } else {
          setNewChartList();
        }
        // setChartList(response.data[0]);
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  const fetchCarbonAllChartData = async () => {
    const response = await AxiosGet(`${API_URLS.GET_CARBON_ALL_CHART_DATA}`);
    if (response && response.statusCode === 200) {
      // setCarbonAllChartData(response.data);
    }
  };
  const fetchGreenhouseAllChartData = async (locationId) => {
    if (datatype.find(item=> item === "63ea431ad03dee0177f28e28")) {
      const response = await AxiosGet(locationId ? `${API_URLS.GET_GREENHOUSE_CHART_DATA}?locationId=${locationId}` :API_URLS.GET_GREENHOUSE_ALL_CHART_DATA);
      if (response && response.statusCode === 200 && response.data.length > 0) {
        setGreenHouseAllChartData(Object.entries(response.data[0])[0][1]);
      }
    }
  };
  const fetchCarbonIntencityChartData = async (locationId) => {
    if (datatype.find(item=> item === "642aaddf32d6fac0a31eccfd")) {
      const response = await AxiosGet(locationId
        ?`${API_URLS.GET_CARBONINTENSITY_CHART_DATA}?locationId=${locationId}&typeOfData=Greenhouse Gas&categoryIds=["642aaddf32d6fac0a31eccfd"]`
        : `${API_URLS.GET_CARBONINTENSITY_ALL_CHART_DATA}?typeOfData=Greenhouse Gas&categoryIds=["642aaddf32d6fac0a31eccfd"]`
      );
      if (Object.entries(response.data[0])[0][0]) {
        setCarbonIntencityChartData(Object.entries(response.data[0])[0][1]);
      } else {
        setCarbonIntencityChartData();
      }
    }
  };

  const fullScreenViewClick = (title, data, chartType, previous, predictMainCategory, mainTitle) => {
    setLevel(previous ? previous.length : 0);
    setFullChart({
      ...fullChart,
      activeStandAloneChartTitle: title,
      showPredictiveChart: !fullChart.showPredictiveChart,
      selectedChart: data,
      chartType: chartType,
      isPredictive: false,
      predictedChart: "",
      predictiveYears: 2,
      mainCategory: predictMainCategory,
      mainHeading: mainTitle
    });
  };

  const changeChartListData = (e, dataPoints, chartHeading, previousLevel, mainCategory, mainTitle) => {
    newchartList?.forEach(chartItem => {
      if (chartItem?.categoryName === mainTitle) {
        Object.entries(Object.values(chartItem?.chartList)[0]).forEach((item) => {
          if (item[0] === chartHeading) {
            let chartData = Object.values(chartItem?.chartList)[0];
            let oldData = Object.values(chartItem?.chartList)[0][`${chartHeading}`];
            oldData["currentChart"] = e ? { dataPoints, e, mainCategory } : undefined;
            if (!previousLevel || previousLevel === "") {
              oldData["Level1Data"] = e ? { dataPoints, e, mainCategory } : undefined;
            } else {
              oldData[`${previousLevel}Data`] = e ? { dataPoints, e } : undefined;
            }
            if (fullChart.showPredictiveChart) {
              setFullChart({ ...fullChart, selectedChart: oldData, mainHeading: mainTitle });
            }
            chartData[chartHeading] = oldData;
            const params = {};
            params[Object.keys(chartItem?.chartList)[0]] = chartData;
            const filterData = newchartList.map(chartNewItem => {
              if (chartNewItem?.categoryName === mainTitle) {
                chartNewItem.chartList = params;
              }
              return chartNewItem;
            });
            if (filterData.length > 0) {
              setNewChartList(filterData);
            } else {
              setNewChartList();
            }
            // setChartList(params);
          }
        });
      }
    });
  };
  const changeLocation = e => {
    setGreenHouseAllChartData();
    setCarbonIntencityChartData();
    setNewChartList();
    setFullChart({
      ...fullChart,
      activeStandAloneChartTitle: "", showPredictiveChart: false, selectedChart: [], predictedChart: "", chartType: "", predictiveYears: 2, mainHeading: ""
    });
    if (selectedAnalytics.value === "Greenhouse Gas") {
      if (e._id) {
        fetchGreenhouseAllChartData(e._id);
        fetchCarbonIntencityChartData(e._id);
      } else {
        fetchGreenhouseAllChartData();
        fetchCarbonIntencityChartData();
      }
    }
    if (selectedAnalytics.value === "Carbon Footprint") {
      fetchCarbonAllChartData();
      if (e._id) {
        getCarbonChartData(e._id);
      } else {
        // setChartList();
      }
    } else {
      if (e._id) {
        fetchChartData(e, selectedAnalytics.value);
      } else {
        fetchAllChartData(selectedAnalytics.value);
      }
    }
    setSelectedLocation(e);
    setApplyDisable(true);
  };
  const changeAnalytics = e => {
    setGreenHouseAllChartData();
    setCarbonIntencityChartData();
    const filterData = datatypeMainList.filter(item => item?.typeOfData?.includes(e?.value));
    setDataTypeList(filterData);
    setDataType([]);
    setNewChartList();
    setFullChart({
      ...fullChart,
      activeStandAloneChartTitle: "", showPredictiveChart: false, selectedChart: [], predictedChart: "", chartType: "", predictiveYears: 2, mainHeading: ""
    });
    setSelectedAnalytics(e);
    if (e.value==="Carbon Footprint") {
      fetchCarbonAllChartData();
      if (selectedLocation._id) {
        getCarbonChartData(selectedLocation._id);
      } else {
        // setChartList();
      }
    } else {
      // if (selectedLocation._id) {
      //   fetchChartData(selectedLocation, e.value);
      // } else {
      //   fetchAllChartData(e.value);
      // }
    }
    setApplyDisable(true);
  };

  const getAllLocation = async () => {
    try {
      const response = await AxiosGet(`${API_URLS.GET_ALL_LOCATIONS}/${authorizedUser.userData.user?.companyId?._id}`);
      if (response && response.statusCode === 200) {
        if (response.data.length > 0) {
          let locations = response.data.map((item) => {
            item.value = item._id;
            item.label = `${item.city} - ${item.addressType}`;
            return item;
          });

          locations.unshift({ value: "", label: "All", city: "All" });
          setSelectedLocation({ value: "", label: "All", city: "All" });
          setLocationList(locations);
          // fetchAllChartData(selectedAnalytics.value);
        } else {
          toast.success("Please Add Location First", { autoClose: 2000 });
        }
      } else {
        toast.error(`${response.message}`, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("No Data Found Something Went Wrong", { toastId: "nodata" });
    }
  };
  const getAllCategory = async () => {
    setloading(true);
    try {
      const response = await AxiosGet(API_URLS.GET_ALL_CATEGORY);
      if (response && response.statusCode === 200) {
        if ( response.data?.length > 0) {
          const dataList = response.data.map(item => {
            item.value = item?.categoryName;
            item.label = item?.categoryName;
            item.isSelected = false;
            return item;
          });
          setDataTypeMainList(dataList);
          const filterData = dataList.filter(item => item?.typeOfData?.includes(selectedAnalytics?.value));
          setDataTypeList(filterData);
          setloading(false);
        }
      } else {
        setloading(false);
        toast.error(`${response.message}`, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  useEffect(() => {
    getAllLocation();
    getAllCategory();
  }, []);

  const onFullScreenPredictiveToggle = async (isPredict, activeChart, chartType, categoryName, year, predictMainCategory, mainTitle) => {
    const chartData = fullChart;
    if (level > 0) {
      newchartList?.forEach(chartItem => {
        if (chartItem?.categoryName === mainTitle) {
          _.set(chartData, "selectedChart", {
            Level1: Object.entries(Object.values(chartItem?.chartList)[0]).map((item) => {
              if (item[0] === predictMainCategory) {
                return item[1].Level1;
              }
            }).filter(i => i !== undefined)[0]
          });
        }
      });
    }
    setloading(prevState => !prevState);
    if (isPredict) {
      let response;
      let predictResponse;
      if (selectedLocation._id) {
        if (selectedAnalytics.value === "Carbon Footprint") {
          response = await AxiosGet(`${API_URLS.PREDICTIVE_CARBON_CHART}/${selectedLocation._id}/Scope 1/${year}`);
          predictResponse = response.data[0];
        } else {
          response = await AxiosGet(`${API_URLS.PREDICTIVE_CHART}?locationId=${selectedLocation._id}&typeOfData=${selectedAnalytics.value}&category=${predictMainCategory}&totalYears=${year}`);
          predictResponse = response.data[0];
        }
      } else {
        response = await AxiosGet(`${API_URLS.ALL_PREDICTIVE_CHART}?typeOfData=${selectedAnalytics.value}&category=${predictMainCategory}&totalYears=${year}`);
        predictResponse = response.data[0].consolidatedData;
      }
      if (response && response.statusCode === 200) {
        if (response.data[0] && predictResponse && predictResponse.Level1) {
          setFullChart({ ...chartData, isPredictive: isPredict, predictedChart: predictResponse, chartType: chartType, predictiveYears: year, mainHeading: mainTitle });
          setTimeout(() => {
            setloading(prevState => !prevState);
          }, 1000);
        }
      }
    } else {
      setFullChart({ ...chartData, predictedChart: "", isPredictive: false, activeStandAloneChart: true, predictiveYears: year, mainHeading: mainTitle });
      setTimeout(() => {
        setloading(prevState => !prevState);
      }, 1000);
    }
  };

  const fetchPredictData = () => {};
  const onPredictToggle = async (title, data, chartType, isPredict, activeChart, categoryName, year, predictMainCategory, previousName, mainTitle) => {
    const chartData = fullChart;
    setloading(isPredict);
    newchartList?.forEach(chartItem => {
      if (chartItem?.categoryName === mainTitle) {
        _.set(chartData, "selectedChart", data);
      }
    });
    let response;
    let predictResponse;
    if (selectedLocation._id) {
      if (selectedAnalytics.value === "Carbon Footprint") {
        response = await AxiosGet(`${API_URLS.PREDICTIVE_CARBON_CHART}/${selectedLocation._id}/Scope 1/${year}`);
        predictResponse = response.data[0];
      } else {
        response = await AxiosGet(`${API_URLS.PREDICTIVE_CHART}?locationId=${selectedLocation._id}&typeOfData=${selectedAnalytics.value}&category=${predictMainCategory}&totalYears=${year}`);
        predictResponse = response.data[0];
      }
    } else {
      response = await AxiosGet(`${API_URLS.ALL_PREDICTIVE_CHART}?typeOfData=${selectedAnalytics.value}&category=${predictMainCategory}&totalYears=${year}`);
      predictResponse = response.data[0].consolidatedData;
    }
    if (response && response.statusCode === 200) {
      if (response.data[0]) {
        setFullChart({
          ...chartData,
          activeStandAloneChartTitle: title,
          activeStandAloneChart: activeChart,
          isPredictive: isPredict,
          showPredictiveChart: isPredict ? isPredict : !fullChart.showPredictiveChart,
          predictedChart: predictResponse,
          chartType: chartType,
          predictiveYears: year,
          mainHeading: mainTitle
        });
      }
      setloading(!isPredict);
    }
  };

  const printDocument = (chartListData) => {
    let divsToHide = document.getElementsByClassName("predictive_actions"); //divsToHide is an array
    let btnsToHide = document.getElementsByClassName("btn"); //divsToHide is an array
    let iconToHide = document.getElementsByClassName("canvasjs-chart-toolbar"); //divsToHide is an array
    for(let i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; // depending on what you're doing
    }
    for(let k = 0; k < btnsToHide.length; k++){
      btnsToHide[k].style.display = "none"; // depending on what you're doing
    }
    for(let a = 0; a < iconToHide.length; a++){
      iconToHide[a].style.display = "none"; // depending on what you're doing
    }
    const elems = document.querySelectorAll(".elemClass");
    let pdf = new jsPDF("l", "px", "a4");
    pdf.internal.scaleFactor = 30;
    elems.forEach((elem, idx) => {
      html2canvas(elem, { scale: 2 }).then(canvas => {
        if (idx < elems.length - 1) {
          if (chartListData[idx]?.chartList?.consolidatedData && Object.keys(chartListData[idx]?.chartList?.consolidatedData).length <= 3) {
            let pdfWidth = pdf.internal.pageSize.getWidth();
            pdf.addImage(canvas.toDataURL("image/png"), 0, 0, pdfWidth, 300);
            pdf.addPage();
          } else {
            let pdfWidth = pdf.internal.pageSize.getWidth();
            let pdfHeight = pdf.internal.pageSize.getHeight();
            pdf.addImage(canvas.toDataURL("image/png"), 0, 0, pdfWidth, pdfHeight);
            pdf.addPage();
          }
        } else {
          if (chartListData[idx]?.chartList?.consolidatedData && Object.keys(chartListData[idx]?.chartList?.consolidatedData).length <= 3) {
            let pdfWidth = pdf.internal.pageSize.getWidth();
            pdf.addImage(canvas.toDataURL("image/png"), 0, 0, pdfWidth, 300);
            pdf.addImage(logo, "PNG", 10, pdf.internal.pageSize.height - 8, 25, 6);
            pdf.setFontSize(8);// optional
            pdf.text(pdfWidth-30, pdf.internal.pageSize.height - 8, `${moment().format("DD-MM-YYYY HH:mm")}`);
            pdf.save("anlytic_screenshot.pdf");
          } else {
            let pdfWidth = pdf.internal.pageSize.getWidth();
            let pdfHeight = pdf.internal.pageSize.getHeight();
            pdf.addImage(canvas.toDataURL("image/png"), 0, 0, pdfWidth, pdfHeight);
            pdf.addImage(logo, "PNG", 10, pdf.internal.pageSize.height - 8, 25, 6);
            pdf.setFontSize(8);// optional
            pdf.text(pdfWidth-30, pdf.internal.pageSize.height - 8, `${moment().format("DD-MM-YYYY HH:mm")}`);
            pdf.save("anlytic_screenshot.pdf");
          }
        }
      });
    });
    for(let j = 0; j < divsToHide.length; j++){
      divsToHide[j].style.display = "block"; // depending on what you're doing
    }
    for(let l = 0; l < btnsToHide.length; l++){
      btnsToHide[l].style.display = "block"; // depending on what you're doing
    }
    for(let b = 0; b < iconToHide.length; b++){
      iconToHide[b].style.display = "block"; // depending on what you're doing
    }
  };
  const onChange = (e) => {
    setGreenHouseAllChartData();
    setCarbonIntencityChartData();
    setDataType(Array.isArray(e) ? e.map(x => x._id) : []);
    setApplyDisable(false);
  };

  return (
    <React.Fragment>
      <IsRoleAuth />
      <div className="page-content sticky-head-section">
        <Container fluid>
          <Row>
            <div id="area-chart-1" dir="ltr">
              <Card>
                <CardBody>
                  <Row className="mb-3 is-sticky fixed align-items-center m-0">
                    <Col lg={1}>
                      <button type="button"
                        className="btn btn-primary mb-2 w-sm" onClick={() => printDocument(newchartList)}>
                        <i className="mdi mdi-download font-size-12"></i>{" "}Download</button>
                    </Col>
                    {datatype.length < 3 && <Col lg="3">
                    </Col>}
                    <Col lg="2" className="analyticsSelect">
                      <Label>Select Analytics</Label>
                      <Select
                        value={selectedAnalytics}
                        options={analyticsList}
                        onChange={changeAnalytics}
                      />
                    </Col>
                    <Col lg={datatype.length < 3 ? "3" : "6"}>
                      <Label>Select Data Type</Label>
                      <Select
                        isMulti={true}
                        closeMenuOnSelect={false}
                        onChange={onChange}
                        value={datatypeList.filter(obj => datatype.includes(obj._id))}
                        options={datatypeList}
                        // components={{ Option }}
                      />
                      {datatype.length <= 0 && <span style={{ position: "absolute", top: "62px" }} className="datatype-error">Please Select Datatype first</span>}
                    </Col>
                    <Col lg="1">
                      <button
                        disabled={datatype.length > 0 ? applyDisable ? true : false : true}
                        className="btn btn-primary applybutton"
                        type="button"
                        onClick={() => {
                          if (selectedLocation._id) {
                            fetchChartData(selectedLocation, selectedAnalytics.value);
                            setApplyDisable(prev => !prev);
                          } else {
                            fetchAllChartData(selectedAnalytics.value);
                            setApplyDisable(prev => !prev);
                          }
                        }}
                      >Apply</button>
                    </Col>
                    <Col lg="2">
                      <Label>Select Location</Label>
                      <Select
                        value={selectedLocation}
                        options={locationList}
                        onChange={changeLocation}
                      />
                    </Col>
                  </Row>
                  <div className="marginTop">
                    {
                      !loading && !fullChart.showPredictiveChart && selectedAnalytics.value === "Greenhouse Gas"
                       && newchartList && greenHouseAllChartData && <Card className="elemClass" style={{ backgroundColor: "#281f1f0d" }}>
                        <CardBody><Row>
                          <div style={{ fontSize: "18px", fontWeight: 600, marginBottom: "10px" }}>Total Greenhouse Gas Emissions</div><Col xl="4" lg="6" md="12">
                            <TestChart
                              index={0}
                              items={greenHouseAllChartData["Greenhouse Gas Emission"]}
                              heading="Total GHG Emissions"
                              mainHeading="Total GHG Emissions"
                              back1={"back1Greenhouse"}
                              back2="back2"
                              locationId={selectedLocation._id}
                              currentChart={fullChart.selectedChart && fullChart.selectedChart.currentChart}
                              predictiveChart={fullChart.isPredictive}
                              onChartSegmentClick={changeChartListData}
                              standAloneChart={false}
                              onFullScreenButtonClick={fullScreenViewClick}
                              onSelectPredictData={fetchPredictData}
                              onPredictiveToggle={onPredictToggle}
                            />
                          </Col>
                        </Row>
                        </CardBody>
                      </Card>
                    }
                    {loading
                      ? (
                        <Loading />
                      )
                      : fullChart.showPredictiveChart
                        ? (
                          <div className="elemClass">
                            <i
                              className="bx bx-left-arrow"
                              style={{ cursor: "pointer", fontSize: "18px" }}
                              onClick={fullScreenViewClick}
                            />
                            {
                              ( selectedAnalytics.value === "Social" || selectedAnalytics.value === "Governance" )
                                ?
                                <SocialTestChart
                                  items={fullChart.selectedChart}
                                  alyticsType={selectedAnalytics.value}
                                  mainHeading={fullChart?.mainHeading}
                                  heading={fullChart.activeStandAloneChartTitle}
                                  back1="back1"
                                  back2="back2"
                                  mainCategory={fullChart.mainCategory}
                                  standAloneChart={true}
                                  predictiveChart={fullChart.isPredictive}
                                  locationId={selectedLocation._id}
                                  currentChart={fullChart.selectedChart && fullChart.selectedChart.currentChart}
                                  predictChartData={fullChart.predictedChart}
                                  onChartSegmentClick={changeChartListData}
                                  onFullScreenButtonClick={fullScreenViewClick}
                                  onFullScreenPredictButtonClick={onFullScreenPredictiveToggle}
                                  chartType={fullChart.chartType}
                                  predictiveYears={fullChart.predictiveYears}
                                />
                                : selectedAnalytics.value === "Carbon Footprint"
                                  ?
                                  <CarbonChart
                                    items={fullChart.selectedChart}
                                    alyticsType={selectedAnalytics.value}
                                    heading={fullChart.activeStandAloneChartTitle}
                                    back1="back1"
                                    back2="back2"
                                    mainCategory={fullChart.mainCategory}
                                    standAloneChart={true}
                                    predictiveChart={fullChart.isPredictive}
                                    locationId={selectedLocation._id}
                                    currentChart={fullChart.selectedChart && fullChart.selectedChart.currentChart}
                                    predictChartData={fullChart.predictedChart}
                                    onChartSegmentClick={changeChartListData}
                                    onFullScreenButtonClick={fullScreenViewClick}
                                    onFullScreenPredictButtonClick={onFullScreenPredictiveToggle}
                                    chartType={fullChart.chartType}
                                    predictiveYears={fullChart.predictiveYears}
                                  />
                                  :
                                  <TestChart
                                    items={fullChart.selectedChart}
                                    alyticsType={selectedAnalytics.value}
                                    heading={fullChart.activeStandAloneChartTitle}
                                    mainHeading={fullChart?.mainHeading}
                                    back1="back1"
                                    back2="back2"
                                    mainCategory={fullChart.mainCategory}
                                    standAloneChart={true}
                                    predictiveChart={fullChart.isPredictive}
                                    locationId={selectedLocation._id}
                                    currentChart={fullChart.selectedChart && fullChart.selectedChart.currentChart}
                                    predictChartData={fullChart.predictedChart}
                                    onChartSegmentClick={changeChartListData}
                                    onFullScreenButtonClick={fullScreenViewClick}
                                    onFullScreenPredictButtonClick={onFullScreenPredictiveToggle}
                                    chartType={fullChart.chartType}
                                    predictiveYears={fullChart.predictiveYears}
                                  />
                            }
                          </div>
                        )
                        : newchartList
                          ? newchartList.map((chartItem, index) => chartItem?.categoryName !== "Carbon Intensity Metrics"
                            ?<Card className="elemClass" style={{ backgroundColor: "#281f1f0d" }} key={`${chartItem?.categoryName}_${index}`}>
                              <CardBody><Row>
                                <div style={{ fontSize: "18px", fontWeight: 600, marginBottom: "10px" }}>{chartItem?.categoryName}</div>
                                {
                                  chartItem?.chartList && Object.entries(Object.values(chartItem?.chartList)[0]).map(
                                    (item, i) => (
                                      <Col key={`Environmental${i}`} xl="4" lg="6" md="12">
                                        {(selectedAnalytics.value === "Environmental"|| selectedAnalytics.value === "Greenhouse Gas") && <TestChart
                                          index={i}
                                          items={item[1]}
                                          heading={item[0]}
                                          mainHeading={chartItem?.categoryName}
                                          back1={"back1" + i + index}
                                          back2="back2"
                                          locationId={selectedLocation._id}
                                          currentChart={item[1].currentChart}
                                          predictiveChart={fullChart.isPredictive}
                                          onChartSegmentClick={changeChartListData}
                                          standAloneChart={false}
                                          onFullScreenButtonClick={fullScreenViewClick}
                                          onSelectPredictData={fetchPredictData}
                                          onPredictiveToggle={onPredictToggle}
                                        />}
                                        {selectedAnalytics.value === "Social" && <SocialTestChart
                                          index={i}
                                          items={item[1]}
                                          heading={item[0]}
                                          back1={"back1" + i + index}
                                          back2="back2"
                                          mainHeading={chartItem?.categoryName}
                                          locationId={selectedLocation._id}
                                          currentChart={item[1].currentChart}
                                          predictiveChart={fullChart.isPredictive}
                                          onChartSegmentClick={changeChartListData}
                                          standAloneChart={false}
                                          onFullScreenButtonClick={fullScreenViewClick}
                                          onSelectPredictData={fetchPredictData}
                                          onPredictiveToggle={onPredictToggle}
                                          chartType={fullChart.chartType}
                                        />}
                                        {selectedAnalytics.value === "Governance" && <SocialTestChart
                                          analyticsType="governance"
                                          index={i}
                                          items={item[1]}
                                          heading={item[0]}
                                          mainHeading={chartItem?.categoryName}
                                          back1={"back1" + i + index}
                                          back2="back2"
                                          locationId={selectedLocation._id}
                                          currentChart={item[1].currentChart}
                                          predictiveChart={fullChart.isPredictive}
                                          onChartSegmentClick={changeChartListData}
                                          standAloneChart={false}
                                          onFullScreenButtonClick={fullScreenViewClick}
                                          onSelectPredictData={fetchPredictData}
                                          onPredictiveToggle={onPredictToggle}
                                        />}
                                        {selectedAnalytics.value === "Carbon Footprint" &&
                                  <CarbonChart
                                    index={i}
                                    items={item[1]}
                                    heading={item[0]}
                                    mainHeading={chartItem?.categoryName}
                                    back1={"back1" + i + index}
                                    back2="back2"
                                    locationId={selectedLocation._id}
                                    currentChart={item[1].currentChart}
                                    onChartSegmentClick={changeChartListData}
                                    standAloneChart={false}
                                    onFullScreenButtonClick={fullScreenViewClick}
                                    chartType={fullChart.chartType}
                                    onPredictiveToggle={onPredictToggle}
                                  />}
                                      </Col>
                                    ) )
                                }
                              </Row>
                              </CardBody>
                            </Card>
                            :
                            carbonIntencityChartData && <Card className="elemClass" style={{ backgroundColor: "#281f1f0d" }} key={`${chartItem?.categoryName}_${index}`}>
                              <CardBody><Row>
                                <div style={{ fontSize: "18px", fontWeight: 600, marginBottom: "10px" }}>{chartItem?.categoryName}</div> {
                                  Object.entries(carbonIntencityChartData).map((item, i) => (
                                    <Col key={`carbonIntencityChartData${i}`} xl="4" lg="6" md="12">
                                      <TestChart
                                        index={i}
                                        items={item[1]}
                                        heading={item[0]}
                                        mainHeading={chartItem?.categoryName}
                                        back1={"back1" + i + index}
                                        back2="back2"
                                        locationId={selectedLocation._id}
                                        currentChart={item[1].currentChart}
                                        predictiveChart={fullChart.isPredictive}
                                        onChartSegmentClick={changeChartListData}
                                        standAloneChart={false}
                                        onFullScreenButtonClick={fullScreenViewClick}
                                        onSelectPredictData={fetchPredictData}
                                        onPredictiveToggle={onPredictToggle}
                                      />
                                    </Col>
                                  ))}
                              </Row>
                              </CardBody>
                            </Card>
                          )
                          : (
                            <div>No Data Found</div>
                          )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;
