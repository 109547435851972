
import AxiosInstance from "./axios";

export const AxiosPost = async (URL, body) => {
  try {
    return await AxiosInstance.post(URL, body);
  } catch (error) {
    return error;
  }
};

export const AxiosGet = async (URL) => {
  try {
    return await AxiosInstance.get(URL);
  } catch (error) {
    return error;
  }
};
