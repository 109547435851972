import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Formik, FieldArray, Field } from "formik";
import { Card, Col, DropdownToggle, DropdownMenu, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import FormUpload from "../Modalfileupload";
import { AxiosGet, AxiosPost } from "common/axiosService";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const HSA = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionA.h_s_a;
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, onSectionChange, oldData, getFile, filesData } = props;

  const [filedatachange, setFileDataChange] = useState(true);
  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);
  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionA", subSection: "h_s_a", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect (() => {
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionA",
        "subSection": "h_s_a"
      });
    }
  }, [newReport, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionA",
      "subSection": "h_s_a"
    });
  }, [filedatachange]);

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, h_s_a: data });
    setData({ ...data });
  };

  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionA",
        "subSection": "h_s_a",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionA",
          "subSection": "h_s_a"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  return (
    <React.Fragment>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className=" d-sm-flex align-items-center justify-content-between"
                    style={{ borderBottom: "1px solid #ccc", paddingBottom: "15px", marginBottom: "30px" }}>
                    <h4 className="mb-sm-0 font-size-18"> Holding, Subsidiary and Associate Companies (including joint ventures) </h4>
                  </div>
                </div>
              </div>
              <Formik
                initialValues={data}
              >
                {({ values, setFieldValue }) => (
                  <form
                    onChange={onHandleChange}
                    onSubmit={(e) => {
                      e.preventDefault();
                      _set(sebiCurrentData, "sectionA.h_s_a", data);
                      updateSebiData(sebiCurrentData);
                      draftSebiData({ ...draftReport, h_s_a: false });
                    }}>
                    <div className="table-responsive">
                      <p><b>21. (A) Names of holding / subsidiary / associate companies / joint ventures</b> </p>
                      <table className="table table-bordered mb-0">
                        <FieldArray name="subsidiary_companies">
                          {({ remove, push }) => (
                            <>
                              <thead className="table-light">
                                <tr>
                                  <th className="text-align" width="4%">Sr. No.</th>
                                  <th className="text-align" width="27%">Name of the holding / subsidiary / associate companies / joint ventures (A) </th>
                                  <th className="text-align" width="24%">Indicate whether holding/ Subsidiary/ Associate/ Joint Venture </th>
                                  <th className="text-align">% of shares held by listed entity</th>
                                  <th className="text-align" width="30%">Does the entity indicated at column A, participate in the
                                  Business Responsibility initiatives of the listed entity?(Yes/No) </th>
                                  <th width="3%" onClick={() => {
                                    push({ name_of_the_company: "", indicate_whether: "", shares_held: "", responsibility_initiatives: "" });
                                    setData({
                                      ...data, subsidiary_companies: [
                                        ...data.subsidiary_companies,
                                        { name_of_the_company: "", indicate_whether: "", shares_held: "", responsibility_initiatives: "" }
                                      ]
                                    });
                                    draftSebiData({
                                      ...draftReport, h_s_a: {
                                        ...data, subsidiary_companies: [
                                          ...data.subsidiary_companies,
                                          { name_of_the_company: "", indicate_whether: "", shares_held: "", responsibility_initiatives: "" }
                                        ]
                                      }
                                    });
                                  }}>
                                    <i className="bx bxs-plus-circle mdi-add-btn" /></th>
                                </tr>
                              </thead>
                              <tbody>
                                {values?.subsidiary_companies?.length > 0 &&
                                   values?.subsidiary_companies.map((friend, index) => (
                                     <tr key={index}>
                                       <td className="text-align">{index+1}.</td>
                                       <td>
                                         <Field
                                           name={`subsidiary_companies[${index}].name_of_the_company`}
                                           placeholder="Name of the company"
                                           type="text"
                                           className="form-control"
                                         />
                                       </td>
                                       <td>
                                         <Field
                                           as="select" className="form-select"
                                           name={`subsidiary_companies[${index}].indicate_whether`}
                                           value={values.subsidiary_companies[index].indicate_whether}
                                         >
                                           <option value="">Select Option</option>
                                           <option value="Holding">Holding</option>
                                           <option value="Subsidiary">Subsidiary</option>
                                           <option value="Associate">Associate</option>
                                           <option value="Joint Venture">Joint Venture</option>
                                         </Field>
                                       </td>
                                       <td>
                                         <Field
                                           className="form-control"
                                           name={`subsidiary_companies[${index}].shares_held`}
                                           placeholder="% of shares held"
                                           type="number"
                                           onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                           onWheel={(e) => e.target.blur()}
                                         />
                                       </td>
                                       <td className="text-align" role="group" aria-labelledby="my-radio-group">
                                         <div className="form-check form-check-inline">
                                           <input
                                             className="form-check-input"
                                             type="radio"
                                             value="Yes"
                                             name={`subsidiary_companies[${index}].responsibility_initiatives`}
                                             id={`radioBtn301[${index}]`}
                                             onClick={(e) => {
                                               _set(data, `subsidiary_companies[${index}].responsibility_initiatives`, e.target.value);
                                               setFieldValue(`subsidiary_companies[${index}].responsibility_initiatives`, e.target.value );
                                             }}
                                             onChange={() => {}}
                                             checked={_get(values, `subsidiary_companies[${index}].responsibility_initiatives`, "") === "Yes"}
                                           />
                                           <label className="form-check-label" htmlFor={`radioBtn3[${index}]`}>Yes</label>
                                         </div>
                                         <div className="form-check form-check-inline">
                                           <input
                                             className="form-check-input"
                                             type="radio"
                                             value="No"
                                             name={`subsidiary_companies[${index}].responsibility_initiatives`}
                                             id={`radioBtn301[${index}]`}
                                             onClick={(e) => {
                                               _set(data, `subsidiary_companies[${index}].responsibility_initiatives`, e.target.value);
                                               setFieldValue(`subsidiary_companies[${index}].responsibility_initiatives`, e.target.value );
                                             }}
                                             onChange={() => {}}
                                             checked={_get(values, `subsidiary_companies[${index}].responsibility_initiatives`, "") === "No"}
                                           />
                                           <label className="form-check-label" htmlFor={`radioBtn3[${index}]`}>No</label>
                                         </div>
                                       </td>
                                       {values.subsidiary_companies?.length > 1 && <td onClick={() => {
                                         remove(index);
                                         const newr = data.subsidiary_companies.filter((item, i) => index !== i);
                                         draftSebiData({ ...draftReport, h_s_a: { ...data, subsidiary_companies: newr } });
                                         setData({ ...data, subsidiary_companies: newr });
                                       }}>
                                         <i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                     </tr>
                                   ))}
                              </tbody>
                            </>
                          )}
                        </FieldArray>
                      </table>
                    </div>

                    <Row className="mt-2 align-items-center">
                      {filesData?.names_of_holding?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && res.statusCode === 200 && res.data[0]) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "names_of_holding" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);
                      })}
                      {filesData?.names_of_holding?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "names_of_holding" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "names_of_holding" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => onSectionChange(4)}>Previous</button>{" "}
                      </div>
                      <div>
                        <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => onSectionChange(6)}>Next</button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionA?.h_s_a,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.h_s_a,
    filesData: state.NewReportReducer?.draftFiles?.h_s_a
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(HSA);