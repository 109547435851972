import React from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import tally from "./Logos/Tally.png";
import sap from "./Logos/SAP.png";
import oracle from "./Logos/Oracle.png";

const Integrations = () => (
  <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={4}>
            <Card className="integrationCom">
              <CardBody>
                <div className="p-20 img-div">
                  <img
                    width={400}
                    height={180}
                    src={tally}
                    alt="img"
                    className="mx-auto d-block"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="integrationCom">
              <CardBody>
                <div className="p-20 img-div">
                  <img
                    width={400}
                    height={180}
                    src={sap}
                    alt="img"
                    className=" mx-auto d-block"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="integrationCom">
              <CardBody>
                <div className="p-20 img-div">
                  <img
                    width={400}
                    height={180}
                    src={oracle}
                    alt="img"
                    className=" mx-auto d-block"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
);

export default Integrations;
