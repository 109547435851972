import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import { Col, Row, Button, TabContent, TabPane } from "reactstrap";
import { Field, FieldArray, Formik, Form } from "formik";
import { Country, State, City } from "country-state-city";

// step three function
const Locations = props => {
  const address = ["Sales Office", "Warehouse", "Branch Office", "Corporate Office", "Manufacturing Unit"];
  const withaoutaddress = ["Sales Office", "Warehouse", "Branch Office", "Manufacturing Unit"];

  const [details, setDetails] = useState([{
    country: "", state: "", city: ""
  }]);

  const CompanyProfileValidationSchema = Yup.object({
    locations: Yup.array().of(
      Yup.object().shape(
        {
          addressType: Yup.string().when(["address", "city", "country", "pincode", "state"], {
            is: (address, city, country, pincode, state) => address||city || country||pincode||state,
            then: Yup.string().required("Please Add FacilityType")
          }),
          country: Yup.string().when(["address", "addressType", "city", "pincode", "state"], {
            is: (address, addressType, city, pincode, state) => address||addressType||city ||pincode||state,
            then: Yup.string().required("Please Add Country")
          }),
          city: Yup.string().when(["address", "addressType", "country", "pincode", "state"], {
            is: (address, addressType, country, pincode, state) => address||addressType || country||pincode||state,
            then: Yup.string().required("Please Add City")
          }),
          state: Yup.string().when(["address", "addressType", "city", "country", "pincode"], {
            is: (address, addressType, city, country, pincode) => address||addressType||city || country||pincode,
            then: Yup.string().required("Please Add State")
          }),
          address: Yup.string().when(["addressType", "city", "country", "pincode", "state"], {
            is: (addressType, city, country, pincode, state) => addressType||city || country||pincode||state,
            then: Yup.string().required("Please Add Address")
          }),
          pincode: Yup.string().when(["address", "addressType", "city", "country", "state"], {
            is: (address, addressType, city, country, state) => address||addressType||city || country||state,
            then: Yup.string().required("Please Add Pincode")
          })
        },
        [
          ["addressType", "country"], ["addressType", "city"], ["addressType", "pincode"], ["addressType", "address"], ["addressType", "state"],
          ["country", "city"], ["country", "pincode"], ["country", "address"], ["country", "state"],
          ["address", "city"], ["address", "pincode"], ["address", "state"],
          ["state", "city"], ["state", "pincode"],
          ["city", "pincode"]
        ]
      )
    )
  });

  useEffect(() => {
    props.setstepLoading(prev => ({ ...prev, ["step3"]: false }));
    if (props?.data?.details) {
      setDetails(props?.data?.details);
    }
  }, [1]);

  const handleSubmit = async values => {
    const locationData = values.locations.filter((location) => {
      if (location.addressType == "" && location.address == "" && location.country == "" && location.state == "" && location.city == "" && location.pincode == "" ) {
        return false;
      } else {
        return true;
      }
    });
    const valueData = { ...values, locations: locationData, details };
    props.setstepLoading(prev => ({
      ...prev,
      ["step3"]: true
    }));
    setTimeout(() => {
      props.next(valueData);
      props.toggleTab(3, 75);
      props.setstep(prev => ({
        ...prev,
        ["step3"]: true
      }));
      props.setstepLoading(prev => ({
        ...prev,
        ["step3"]: false
      }));
    }, 600);
  };

  const removeEmptyValue = async (values) => {
    values.locations.filter((location) => {
      if ( location.addressType == "" && location.address == "" && location.country == "" && location.state == "" && location.city == "" && location.pincode == "" ) {
        return false;
      } else {
        return true;
      }
    });
  };

  const getIosname = (item, mainIndex) =>{
    const index = item.target.selectedIndex;
    const el = item.target.childNodes[index];
    const option = el.getAttribute("id");
    const temp = details.map((value, i) => {
      if (mainIndex === i ) {
        value.country = option;
      }
      return value;
    });
    setDetails(temp);
  };
  const getIosnamestate = (item, mainIndex) =>{
    const index = item.target.selectedIndex;
    const el = item.target.childNodes[index];
    const option = el.getAttribute("id");
    const temp = details.map((value, i) => {
      if (mainIndex === i ) {
        value.state = option;
      }
      return value;
    });
    setDetails(temp);
  };

  return (
    <div className="content w-100 clearfix mt-0">
      <TabContent activeTab={2} className="body">
        <TabPane tabId={2}>
          <Formik
            validationSchema={CompanyProfileValidationSchema}
            initialValues={props.data}
            // enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ values, errors, setFieldValue }) => (
              <Form>
                <FieldArray name="locations">
                  {({ remove, push }) => (
                    <>
                      <Row>
                        {values.locations.length > 0 &&
                          values.locations.map((location, index) => {
                            const locationsValues = (values.locations?.length && values.locations[index]) || {};
                            const locationsErrors = (errors.locations?.length && errors.locations[index]) || {};
                            return (
                              <Col
                                xs="12" lg="12" key={index}
                                className="bg-white rounded mb-3 d-block position-relative"
                              >
                                <div
                                  className="shadow border p-3 d-block inner-div"
                                  // eslint-disable-next-line
                                  count={index + 1}
                                >
                                  <div data-repeater-list="group-a">
                                    <Row data-repeater-item>
                                      <Col lg="6">
                                        <Field
                                          as="select"
                                          name={`locations[${index}].addressType`}
                                          className={
                                            "form-control" +
                                            (locationsErrors.addressType ? " is-invalid" : "")
                                          }
                                        >
                                          <option value="">
                                          Select Facility type
                                          </option>

                                          {values.locations.some(i=> i.addressType === "Corporate Office")
                                            ? locationsValues.addressType === "Corporate Office" ?
                                              address.map((item, i) => (
                                                <option key={i} value={item}> {item} </option>
                                              ))
                                              :withaoutaddress.map((item, i) => (
                                                <option key={i} value={item}> {item} </option>
                                              ))
                                            : address.map((item, i) => (
                                              <option key={i} value={item}> {item} </option>
                                            ))}

                                        </Field>
                                        {
                                          Object.values(locationsValues).some(i => i!== "")
                                            ?<div className="invalid-feedback">Please Select Facility type</div>
                                            : locationsErrors.addressType &&
                                          <div className="invalid-feedback">Please Select Facility type</div>
                                        }
                                      </Col>

                                      <Col lg="6" className="mb-3">
                                        <select
                                          name={`locations[${index}].country`}
                                          className={"form-select"+(locationsErrors?.country ? " is-invalid" : "")}
                                          value={values.locations[index]?.country }
                                          onChange={ (val) => {
                                            const el = val.target.childNodes[val.target.selectedIndex];
                                            const option = el.getAttribute("id");
                                            if (State.getStatesOfCountry(option).length > 0) {
                                              setFieldValue(`locations[${index}].state`, "" );
                                              setFieldValue(`locations[${index}].city`, "" );
                                            } else {
                                              setFieldValue(`locations[${index}].state`, " " );
                                              setFieldValue(`locations[${index}].city`, " " );
                                            }
                                            setFieldValue(`locations[${index}].country`, val.target.value );
                                            getIosname(val, index);
                                          }
                                          }
                                        >
                                          <option>Select Country</option>
                                          {
                                            Country.getAllCountries()?.map((item, i) => (
                                              <option
                                                id={item.isoCode}
                                                value={item.name} key={i}>
                                                {item.name}
                                              </option>
                                            ))}
                                        </select>

                                        {locationsErrors?.country &&<div className="invalid-feedback">Please Enter Country</div>}
                                      </Col>

                                      <Col lg="6" className="mb-3">
                                        <select
                                          name={`locations[${index}].state`}
                                          className={"form-select"+(locationsErrors?.state ? " is-invalid": "")}
                                          value={values.locations[index]?.state}
                                          onChange={(val) => {
                                            setFieldValue(`locations[${index}].city`, "" );
                                            setFieldValue(`locations[${index}].state`, val.target.value );
                                            getIosnamestate(val, index);
                                          }
                                          }
                                        >
                                          <option>Select state</option>
                                          {
                                            State.getStatesOfCountry(details[index]?.country)?.map((item, i) => (<option id={item.isoCode} value={item.name} key={i}>
                                              {item.name}
                                            </option>))}
                                        </select>
                                        {locationsErrors?.state &&<div className="invalid-feedback">Please Enter State</div>}
                                      </Col>

                                      <Col lg="6" className="mb-3">
                                        <Field
                                          as="select"
                                          name={`locations[${index}].city`}
                                          className={"form-select" + (locationsErrors?.city ? " is-invalid" : "")}
                                          value={values.locations[index].city}
                                        >
                                          <option>Select City</option>
                                          {
                                            City.getCitiesOfState(details[index]?.country, details[index]?.state)?.map((item, i) => (
                                              <option value={item.name} key={i}>
                                                {item.name}
                                              </option>
                                            ))}
                                        </Field>

                                        {locationsErrors?.city &&<div className="invalid-feedback">Please Enter City</div>}
                                      </Col>

                                      <Col lg="6" className="mb-3">
                                        <Field
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          name={`locations[${index}].pincode`}
                                          placeholder="Enter Pincode"
                                          className={
                                            "form-control" +
                                            (locationsErrors.pincode ? " is-invalid" : "")
                                          }
                                        />
                                        {locationsErrors.pincode &&<div className="invalid-feedback">Please Enter Pincode</div>}
                                      </Col>

                                      <Col lg="6" className="mb-0">
                                        <Field
                                          as="textarea"
                                          name={`locations[${index}].address`}
                                          placeholder="Enter Address"
                                          className={
                                            "form-control" +
                                            (locationsErrors.address ? " is-invalid" : "")
                                          }
                                        />
                                        {locationsErrors.address &&<div className="invalid-feedback">Please Enter Address</div>}
                                      </Col>
                                    </Row>
                                  </div>

                                  {values.locations.length > 1 && <div className="mb-3 close-btn">
                                    {" "}
                                    <i
                                      className="bx bxs-x-circle mdi-close-btn"
                                      onClick={() => {
                                        remove(index);
                                        const newr = details.filter((item, i) => index !==i);
                                        setDetails(newr);
                                      }}
                                    />
                                  </div>}
                                </div>
                              </Col>
                            );
                          })}
                      </Row>
                      <Button
                        onClick={() => {
                          setDetails([...details, {
                            country: "", state: "", city: ""
                          }]);
                          push({ addressType: "", address: "", country: "", state: "", city: "", pincode: "" });
                        } }
                        color="default"
                        className="mt-3 mt-lg-0 btn btn-outline-primary btn-sm"
                      >
                        + Add More Locations{" "}
                      </Button>{" "}
                      <Row className="mt-3 next-prev-btn">
                        <Col>
                          <button
                            className="btn btn-primary btn-block"
                            type="button"
                            onClick={() => {
                              removeEmptyValue(values);
                              const locationData = values.locations.filter((location) => {
                                if (location.addressType == "" && location.address == "" && location.country == "" && location.state == "" && location.city == "" && location.pincode == "" ) {
                                  return false;
                                } else {
                                  return true;
                                }
                              });
                              const valueData = { ...values, locations: locationData };
                              setTimeout(() => {
                                props.next(valueData);
                              }, 500);
                            }}
                          >
                            Skip
                          </button>{" "}
                          <button
                            className="btn btn-primary btn-block"
                            type="button"
                            onClick={() => {
                              props.prev(values);
                            }}
                            disabled={props.stepLoading.step3}
                          >
                            Prev
                          </button>{" "}
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            onClick={() => {
                              setTimeout(() => {
                                removeEmptyValue(values);
                              }, 500);
                            }}
                            disabled={props.stepLoading.step3}
                          >
                            {props.stepLoading.step3
                              ? (
                                <div
                                  className="spinner-border text-white"
                                  role="status"
                                  style={{ width: "1rem", height: "1rem" }}
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )
                              : (
                                "Next"
                              )}
                          </button>
                        </Col>
                      </Row>
                    </>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withRouter(Locations);
