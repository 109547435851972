import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";

import { API_URLS } from "common/constants";
import { AxiosGet } from "common/axiosService";
import Loading from "components/Common/Loading";
import TestChart from "../Analytics/mines/TestChart";
import IsRoleAuth from "pages/Authentication/IsRoleAuth";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const Index = () => {
  document.title = "Analytics | Greenalytics";

  const [chartList, setChartList] = useState();
  const [loading, setloading] = useState(true);
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [fullChart, setFullChart] = useState({
    showPredictiveChart: false,
    activeStandAloneChartTitle: "",
    selectedChart: [],
    chartType: ""
  });

  const authorizedUser =localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser"));

  const fetchChartData = async (locationId) => {
    setloading(true);
    try {
      const response = await AxiosGet(`${API_URLS.GET_CHART_DATA}?locationId=${locationId}&typeOfData=Governance`, {});
      if (response && response.statusCode === 200) {
        setChartList(response.data[0]);
        setloading(false);
      } else {
        setloading(false);
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }

  };

  const fetchAllChartData = async () => {
    if (authorizedUser) {
      setloading(true);
      try {
        const response = await AxiosGet(`${API_URLS.GET_ALL_CHART_DATA}/Governance`);
        if (response && response.statusCode === 200) {
          setChartList(response.data[0]);
          setloading(false);
        }
      } catch (error) {
        setloading(false);
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    }
  };

  const fullScreenViewClick = (title, data, chartType) => {
    setFullChart({
      activeStandAloneChartTitle: title,
      showPredictiveChart: !fullChart.showPredictiveChart,
      selectedChart: data,
      chartType: chartType
    });
  };

  const changeChartListData = (e, dataPoints, chartHeading, previousLevel) => {
    Object.entries(Object.values(chartList)[0]).forEach((item) => {
      if (item[0] === chartHeading) {
        let chartData = Object.values(chartList)[0];
        let oldData = Object.values(chartList)[0][`${chartHeading}`];
        oldData["currentChart"] = e ? { dataPoints, e } : undefined;
        if (!previousLevel || previousLevel === "") {
          oldData["Level1Data"] = e ? { dataPoints, e } : undefined;
        } else {
          oldData[`${previousLevel}Data`] = e ? { dataPoints, e } : undefined;
        }
        chartData[chartHeading] = oldData;
        const params = {};
        params[Object.keys(chartList)[0]] = chartData;
        setChartList(params);
      }
    });
  };
  const changeLocation = e => {
    setFullChart({
      activeStandAloneChartTitle: "",
      showPredictiveChart: false,
      selectedChart: [],
      chartType: ""
    });
    if (e._id) {
      fetchChartData(e._id);
    } else {
      fetchAllChartData();
    }
    setSelectedLocation(e);
  };

  const getAllLocation = async () => {
    try {
      const response = await AxiosGet( `${API_URLS.GET_ALL_LOCATIONS}/${authorizedUser.userData.user.companyId
        ? authorizedUser.userData.user.companyId._id
        : ""
      }`, {});

      if (response && response.statusCode === 200) {
        if (response.data.length > 0) {
          let locations = response.data.map((item) => {
            item.value = item._id;
            item.label = `${item.city} - ${item.addressType}`;
            // if (index === 0) {
            //   setSelectedLocation(item);
            // }
            return item;
          });
          // fetchChartData(response.data.data[0]._id);
          locations.unshift({ value: "", label: "All", city: "All" });
          setSelectedLocation({ value: "", label: "All", city: "All" });
          setLocationList(locations);
          fetchAllChartData();
        } else {
          setloading(false);
          toast.success("Please Add Location First", {
            autoClose: 2000
          });
        }
      } else {
        setloading(false);
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }

  };
  useEffect(() => {
    getAllLocation();
  }, []);
  return (
    <React.Fragment>
      <IsRoleAuth />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Analytics" breadcrumbItem="Governance" />
          <Row>
            <div id="area-chart-1" dir="ltr">
              <Card>
                <CardBody>
                  <Row className="mb-3 fixed">
                    <Col lg="9">
                      <Label>
                        Current Location:{" "}
                        {selectedLocation ? selectedLocation.city : ""}
                      </Label>
                    </Col>
                    <Col lg="3">
                      <Label>Select Location</Label>
                      <Select
                        value={selectedLocation}
                        options={locationList}
                        onChange={changeLocation}
                      />
                    </Col>
                  </Row>

                  <Row className="marginTop">
                    {loading
                      ? (
                        <Loading />
                      )
                      : fullChart.showPredictiveChart
                        ? (
                          <>
                            <i
                              className="bx bx-left-arrow"
                              style={{ cursor: "pointer", fontSize: "18px" }}
                              onClick={fullScreenViewClick}
                            />
                            <TestChart
                              analyticsType="governance"
                              items={fullChart.selectedChart}
                              heading={fullChart.activeStandAloneChartTitle}
                              back1="back1"
                              back2="back2"
                              standAloneChart={true}
                              locationId={selectedLocation._id}
                              currentChart={fullChart.selectedChart.currentChart}
                              onChartSegmentClick={changeChartListData}
                              onFullScreenButtonClick={fullScreenViewClick}
                              chartType={fullChart.chartType}
                            />
                          </>
                        )
                        : chartList
                          ? (
                            <Row>
                              {Object.entries(Object.values(chartList)[0]).map(
                                (item, i) => (
                                  <Col key={`governance${i}`} xl="4" lg="6" md="12">
                                    <TestChart
                                      analyticsType="governance"
                                      index={i}
                                      items={item[1]}
                                      heading={item[0]}
                                      back1={"back1" + i}
                                      back2="back2"
                                      locationId={selectedLocation._id}
                                      currentChart={item[1].currentChart}
                                      onChartSegmentClick={changeChartListData}
                                      standAloneChart={false}
                                      onFullScreenButtonClick={fullScreenViewClick}
                                    />
                                  </Col>
                                )
                              )}
                            </Row>
                          )
                          : (
                            <div>No Data Found</div>
                          )}
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;
