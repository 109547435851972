import React from "react";
import { Container, Row } from "reactstrap";

import Earning from "./earning";
import CardUser from "./CardUser";
import Settings from "./Settings";
import SalesAnalytics from "./sales-analytics";

const index = () => {
  //meta title
  document.title = "Blog Dashboard | Skote - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <CardUser />
            <Settings />
          </Row>
          <Row>
            <Earning />
            <SalesAnalytics />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default index;
