import React from "react";

function Input (props) {
  const { name, type = "text", isDisabled = false, value, onFocus, onChange, id, title, placeholder } = props;
  return <input
    id={id}
    onWheel={(e) => e.target.blur()}
    onChange={onChange}
    onFocus={(e) => onFocus && onFocus(e, title, name)}
    onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
    value={value}
    name={name}
    type={type}
    disabled={isDisabled}
    placeholder={placeholder}
    className="form-control"
  />;
}

export default Input;