import React from "react";

function TableInput (props) {
  const { name, type= "text", className, isDisabled = false, value, onChange, onFocus, placeholder, key, title, colSpan } = props;
  return <td className={className} key={key} colSpan={colSpan}>
    <input
      onWheel={(e) => e.target.blur()}
      onChange={onChange}
      onFocus={(e) => onFocus && onFocus(e, title, name)}
      onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
      value={value}
      name={name}
      type={type}
      disabled={isDisabled}
      placeholder={placeholder}
      className="form-control"
    />
  </td>;
}

export default TableInput;