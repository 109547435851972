import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import { profileUpdate } from "./auth/profile/reducer";
import ReportReducer from "./report/reducer";
import NewReportReducer from "./newReport/reducer";
import ResendVerifyLink from "./auth/ResendVerifyLink/reducer";
import AdminUsers from "./admin/users/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ReportReducer,
  NewReportReducer,
  ResendVerifyLink,
  AdminUsers,
  profileUpdate
});

export default rootReducer;
