import { AxiosGet, AxiosPost } from "common/axiosService";
import { API_URLS } from "common/constants";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, Form, Label, Row } from "reactstrap";

function index () {
  const [year, setYear] = useState(new Date().getFullYear());
  const years = Array.from(
    { length: 25 },
    (_, i) => new Date().getFullYear() - i
  );

  const handleYearChange = (e) => {
    setYear(e.target.value);
    getBusinessDetails(e.target.value);
  };

  const getBusinessDetails = async (selectedYear) => {
    try {
      const response = await AxiosGet(`${API_URLS.GET_BUSINESS_DATA}/${selectedYear}`);
      if (response && response.statusCode === 200) {
        const formData = document.getElementById("businessInformation");
        for ( var i = 0; i < formData?.elements?.length; i++ ) {
          if (response.data[0]) {
            Object.entries(response.data[0]).forEach(item => {
              if (formData.elements[i].name === item[0]) {
                formData.elements[i].value = item[1];
              }
            });
          } else {
            formData.elements[i].value = "";
          }
        }
        // setChartList(Object.entries(response.data[0]).map(item => ({ [item[0]]: { [activeAnalyticsScope]: item[1][activeAnalyticsScope] } })));
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  useEffect(() => {
    getBusinessDetails(year);
  }, []);

  const onBusinessUpdate= async (e) => {
    e.preventDefault();
    let params = {};
    let form = e.target;
    for ( var i = 0; i < form.elements.length; i++ ) {
      var values = form.elements[i];
      if (values.name) {
        params = { ...params, [values.name]: values.value };
      }
    }
    params.year = year;
    try {
      const response = await AxiosPost(API_URLS.ADD_BUSINESS_DATA, params);
      if (response && response.statusCode === 200) {
        toast.success(response.message, { toastId: "success", autoClose: 2000 });
        // setChartList(Object.entries(response.data[0]).map(item => ({ [item[0]]: { [activeAnalyticsScope]: item[1][activeAnalyticsScope] } })));
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  return (
    <div className="page-content">
      <Container fluid={true}>
        <Card>
          <CardBody>
            <Row>
              <h3 className="mb-4" style={{ width: "50%" }}>Business Information</h3>
              <Col
                lg={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "left",
                  width: "50%"
                }}
              >
                <Col lg={6} >
                  <Label>Select Year</Label>
                  <select
                    className="form-select"
                    defaultValue={year}
                    onChange={e => handleYearChange(e)}
                  >
                    <option disabled value="">Select Year</option>
                    {years.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </Col>
              </Col>
            </Row>
            <Form onSubmit={onBusinessUpdate} id="businessInformation">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row className="mb-3">
                        <label htmlFor="turnover" className="col-md-2 col-form-label" > Turn over ( ₹ ) </label>
                        <div className="col-md-5">
                          <input
                            name="turnover"
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label htmlFor="rawMaterial" className="col-md-2 col-form-label" > Raw material ( ₹ ) </label>
                        <div className="col-md-5">
                          <input
                            name="rawMaterial"
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label htmlFor="capitalExpenditure" className="col-md-2 col-form-label" > Capital Expenditure ( ₹ ) </label>
                        <div className="col-md-5">
                          <input
                            name="capitalExpenditure"
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label htmlFor="researchAndDevelopment" className="col-md-2 col-form-label" > R&D Expenses ( ₹ ) </label>
                        <div className="col-md-5">
                          <input
                            name="researchAndDevelopment"
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label htmlFor="productionData" className="col-md-2 col-form-label" > Production data ( MT ) </label>
                        <div className="col-md-5">
                          <input
                            name="productionData"
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </Row>
                      <div>
                        <button type="submit" className="btn btn-primary w-md">
                        Submit
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default index;