import React from "react";
import ReactApexChart from "react-apexcharts";

const Spinearea = (props) => {
  const { data, scope } = props;
  const options = {
    chart: { toolbar: "false", id: "line" },
    dataLabels: { enabled: !1 },
    stroke: { curve: "smooth", width: 2 },
    xaxis: {
      show: true,
      showAlways: true
    },
    yaxis: {
      axisBorder: {
        show: true
      },
      show: true,
      title: {
        text: "kg CO₂e"
      },
      showAlways: true,
      labels: {
        formatter: function (value) {
          return Number(value).toLocaleString("en-IN");
        }
      }
    },
    markers: {
      sizeOffset: 7,
      style: "hollow",
      strokeColors: "#3f7e44",
      hover: {
        sizeOffset: 5
      }
    },
    colors: ["#3f7e44"]
  };
  return (
    <ReactApexChart
      options={options}
      series={[{
        name: scope,
        data: data
      }]}
      type="area"
      height="350"
    />
  );
};

export default Spinearea;
