import React from "react";
import { BiSort, BiSortDown, BiSortUp } from "react-icons/bi";

const Sorting = ({ column }) => (
  <span className="react-table__column-header sortable float-end">
    {column.isSortedDesc === undefined
      ? (
        <BiSort />
      )
      : (
        <span>
          {column.isSortedDesc
            ? <BiSortDown />
            : <BiSortUp />}
        </span>
      )}
  </span>
);

export default Sorting;
