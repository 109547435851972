import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Select from "react-select";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import { toast } from "react-toastify";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup, Row, Label } from "reactstrap";

import SidebarItem from "./SubCategory";
import { AxiosPost } from "common/axiosService";

const SidebarMainItem = ({
  onUpdateCategory, onStageSelect, selectedStage, currentStage, onStageChange, stage, onCategoryToggle,
  onCategoryOpen, isMainCategoryOpen, onNewCategoryAdd, onChangeCreateForm, item, statusChange, setChangeText, changetext, ...rest
}) => {

  const { categoryName, subCategory, typeOfData } = item;
  const apiUrl = process.env.REACT_APP_SUPERADMIN_API_PATH;
  const [, setCollapsed] = useState(true);
  const [otherMenu, setOtherMenu] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [searchMenu, setSearchMenu] = useState(false);
  const [stageChange, setStageChange] = useState(stage);
  const [subCategoryList, setSubcategoryList] = useState([]);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [inputBoxList, setInputBoxList] = useState({ users: [{ name: "" }] });
  const [selectedCategory, setSelectedCategory] = useState({ category: "", mainCategory: "" });

  useEffect(() => {
    setSubcategoryList(subCategory);
  }, [subCategory]);

  useEffect(() => {
    if (subCategoryList.length > 0) {
      const isAnyOpen = subCategoryList.filter(item => item.isOpen);
      if (isAnyOpen.length === 0) {
        setStageChange({ ...stageChange, [`${currentStage}`]: false });
        onStageChange({ ...stage, [`${currentStage}`]: false });
      } else {
        if (currentStage !== parseInt(selectedStage - 1)) {
          onSubcategoryStatusChange();
        }
      }
    }
    (Object.entries(stageChange).filter(item => {
      if (item[0] !== `${parseInt(selectedStage - 1)}`) {
        if (item[1]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }).length.length > 0 ||
      isCategoryOpen) &&
      onSubcategoryStatusChange();
  }, [
    Object.entries(stageChange).filter(item => {
      if (item[0] !== `${parseInt(selectedStage - 1)}`) {
        if (item[1]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }).length,
    isCategoryOpen
  ]);

  function toggleCollapse () {
    setCollapsed(prevValue => !prevValue);
  }

  /* ==============>>>>>>>>  Toggle on Category open and close  <<<<<<<<============== */
  const onSubcategoryStatusChange = subCategoryData => {
    setSubcategoryList(
      subCategoryList.map(item => {
        if (subCategoryData && item._id === subCategoryData._id) {
          item.isOpen = !item.isOpen;
          onStageChange({ ...stage, [`${currentStage}`]: item.isOpen });
          setStageChange({ ...stageChange, [`${currentStage}`]: item.isOpen });
        } else {
          item.isOpen = false;
        }
        return item;
      })
    );
    if (subCategoryData) {
      onCategoryOpen(true);
    } else {
      setIsCategoryOpen(false);
    }
    // statusChange();
  };

  /* ==============>>>>>>>>  New Category add OnFormSubmit  <<<<<<<<============== */
  const submitSubCategory = async (data, addedCategories) => {
    const params = { mainCategoryId: data._id };

    let subCategoryData = addedCategories.map(item => ({
      categoryName: item.name,
      isActive: true,
      isOpen: false
    }));

    if (data.subCategory.length > 0) {
      subCategoryData = subCategoryData.concat(data.subCategory);
    }
    params["subCategory"] = subCategoryData;
    params["isNewSubCategoryAdded"] = true;
    params["typeOfData"] = data.typeOfData;
    try {
      await AxiosPost(`${apiUrl}/update-category-category`, params);
      setTimeout(() => {
        onNewCategoryAdd();
        toast.success("Sub-category added Successfully.", {
          autoClose: 1000
        });
      }, 1000);
      toggleCollapse();
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  const categoryValidationSchema = Yup.object({
    users: Yup.array().of( Yup.object().shape({ name: Yup.string().required("Please Enter Category Name") }))
  });

  /* ==============>>>>>>>>  On Category Status Update  <<<<<<<<============== */
  const onIsActiveUpdate = async (data, isFormRemoved) => {
    const params = { _id: data._id };
    params["isActive"] = data.isActive;
    params["data"] = data.data;
    params["categoryName"] = data.categoryName;
    params["typeOfData"] = data.typeOfData;
    try {
      await AxiosPost(`${apiUrl}/update-main-category`, params);
      onNewCategoryAdd();
      setOtherMenu(false);
      setSearchMenu(false);
      if (isFormRemoved) {
        toast.success("Form Removed Successfully.", {
          autoClose: 1000
        });
      } else {
        toast.success("Category Status Updated Successfully.", {
          autoClose: 1000
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  const handleEditCategory =async (e) => {
    e.preventDefault();
    const params = { _id: selectedCategory.category._id };
    params["categoryName"] = currentItem?.categoryName;
    params["isActive"] = selectedCategory.category.isActive;
    params["typeOfData"] = currentItem?.typeOfData;
    setSearchMenu(false);
    try {
      await AxiosPost(`${apiUrl}/update-main-category`, params);
      setOtherMenu(false);
      setSearchMenu(false);
      onNewCategoryAdd();
      toast.success("Category Updated Successfully.", {
        autoClose: 1000
      });
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  const optionsforcsv = [
    { value: "Environmental", label: "Environmental" },
    { value: "Greenhouse Gas", label: "Greenhouse Gas" },
    { value: "Social", label: "Social" },
    { value: "Governance", label: "Governance" },
    { value: "BRSR", label: "BRSR" }
  ];

  const handleChange = (e) => {
    if (e.target) {
      setCurrentItem({ ...currentItem, categoryName: e.target.value });
    } else {
      setCurrentItem({ ...currentItem, typeOfData: Array.isArray(e) ? e.map(x => x.value) : [] });
    }
  };

  return (
    <>
      <ul
        className="sidebar-item main-sub-tab"
        // button
        // dense
        {...rest}
        // onClick={onClick}
      >
        <div className="main-category-box">
          <div
            className="main-dis cursor-pointer"
            onClick={() => onCategoryToggle(item)}
          >
            <div className="sidebar-item-text ">
              <h6 className="accordion-header" id="headingOne">
                {categoryName} {typeOfData?.map((item, i) => {
                  let pattern = "";
                  pattern = item + " ";
                  return <small key={i}>({pattern})</small>;})}
              </h6>
            </div>
            {subCategoryList.length
              ? (item.isToggle
                ? <ExpandLessIcon className={"sidebar-item-expand-arrow" + " sidebar-item-expand-arrow-expanded" }/>
                : <ExpandMoreIcon className="sidebar-item-expand-arrow" />)
              :""}
          </div>
          <div style={{ display: "flex" }}>
            <Dropdown
              isOpen={otherMenu}
              toggle={() => setOtherMenu(!otherMenu)}
            >
              <DropdownToggle
                tag="span"
                className="dots-icon"
                onClick={() => {
                  statusChange();
                  onChangeCreateForm(false);
                }}
              >
                <i className="bx bx-dots-horizontal-rounded w-100" />
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedCategory({ category: item, mainCategory: "" });
                    setSearchMenu(true);
                    setCurrentItem({ ...item });
                  }}
                > Edit </DropdownItem>

                {(item.subCategory?.length === 0 && item.data.length === 0) && (
                  <DropdownItem
                    onClick={() => {
                      onUpdateCategory({ category: item, mainCategory: "" });
                      onChangeCreateForm(true, false);
                      setChangeText(true);
                    }}>
                    Create Form
                  </DropdownItem>)}

                {item.data.length === 0 &&
                  <DropdownItem
                    href="#"
                    onClick={e => {
                      e.stopPropagation();
                      setIsCategoryOpen(true);
                      onChangeCreateForm(false);
                      onStageSelect(currentStage);
                      statusChange(item);
                    }}
                  > Add Sub Points </DropdownItem>
                }

                {item.data.length > 0 && (
                  <>
                    <DropdownItem
                      onClick={() => {
                        onUpdateCategory({ category: item, mainCategory: "" });
                        onChangeCreateForm(true, true);
                        setChangeText(false);
                      }}
                    > Edit Form </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        const updatedData = item;
                        updatedData["isOpen"] = false;
                        updatedData["data"] = [];
                        onIsActiveUpdate(updatedData, true);
                        // onUpdateCategory({ category: item, mainCategory: "" })
                        // onChangeCreateForm(true, true);
                      }}
                    > Remove Form </DropdownItem>
                  </> )
                }

                <DropdownItem>
                  <div className="form-check form-switch form-switch-lg">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitch2"
                      checked={item.isActive}
                      onClick={e => {
                        e.stopPropagation();
                        const updatedData = item;
                        updatedData["isActive"] = !item.isActive;
                        updatedData["isOpen"] = false;
                        onIsActiveUpdate(updatedData);
                      }}
                      onChange={() => {
                        const updatedData = item;
                        updatedData["isActive"] = !item.isActive;
                        updatedData["isOpen"] = false;
                        onIsActiveUpdate(updatedData);
                      }}
                    />
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              isOpen={searchMenu}
              toggle={() => setSearchMenu(!searchMenu)}
            >
              <DropdownMenu id="sAeditinputbox" className="dropdown-menu-end py-0 dropdown-menu-md">
                <Form
                  onSubmit={(e, currentItem) => handleEditCategory(e, currentItem)}
                  className="p-3"
                >
                  <div className="m-0">
                    <div className="mb-3 lg-3">
                      <Label>Select Data Type</Label>
                      <Select
                        className="zIndex"
                        value={optionsforcsv.filter(obj => currentItem?.typeOfData?.includes(obj.value))}
                        options={optionsforcsv}
                        onChange={handleChange}
                        isMulti={true}
                      />
                    </div>

                    <InputGroup>
                      <Input
                        name="category"
                        placeholder="Enter Category..."
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                        autoComplete="off"
                        onChange={handleChange}
                        value={currentItem?.categoryName}
                      />
                    </InputGroup>
                    <button
                      className="btn btn-primary mt-3"
                      type="submit"
                      disabled={currentItem?.categoryName?.length && currentItem?.typeOfData?.length ? false : true}
                    >
                        Update
                    </button>

                  </div>
                </Form>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>

        {item.isOpen && (
          <div
            className="container add-category-input-box"
            onClick={e => e.stopPropagation()}>
            <Formik
              validationSchema={categoryValidationSchema}
              initialValues={inputBoxList}
            >
              {({ values, errors, touched, setErrors, setTouched }) => (
                // step four form
                <Form className="row">
                  <FieldArray name="users">
                    {({ remove, push }) => (
                      <>
                        <Col md="12">
                          {inputBoxList.users.length > 0 &&
                              inputBoxList.users.map((user, index) => {
                                const userErrors = (errors.users?.length && errors?.users[index]) || {};
                                const userTouched = (touched.users?.length && touched.users[index]) ||{};
                                return (
                                  <div
                                    className="position-relative shadow border mb-3 p-3 d-block inner"
                                    key={index + 1}
                                  >
                                    <div data-repeater-list="group-a">
                                      <Row data-repeater-item>
                                        <Col className="col-md-10">
                                          <Field
                                            type="text"
                                            name={`users[${index}].name`}
                                            className={ "form-control" + (userErrors.name && userTouched.name ? " is-invalid" : "") }
                                            placeholder="Enter Category....."
                                          />
                                          <ErrorMessage
                                            name={`users[${index}].name`}
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </Col>
                                        {inputBoxList.users.length - 1 === index?
                                          <Col md="2" className="close-btn mt-1">
                                            <Button
                                              onClick={e => {
                                                e.stopPropagation();
                                                setInputBoxList({ users: [ ...inputBoxList.users, { name: "" } ] });
                                                push({ name: "" });
                                              }}
                                              color="default"
                                              className="btn btn-outline-primary btn-sm"
                                            >  + </Button>
                                          </Col>
                                          : (
                                            <Col md="2" className="close-btn">
                                              <i
                                                className="mdi mdi-close-box mdi-close-btn"
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  setInputBoxList({ users: inputBoxList.users.filter((dItem, dataIndex) =>dataIndex !== index) });
                                                  remove(index);
                                                }}
                                              />
                                            </Col>
                                          )}
                                      </Row>
                                    </div>
                                  </div>
                                );
                              })}
                        </Col>
                        <Row className="mt-3 next-prev-btn">
                          <Col>
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={e => {
                                e.stopPropagation();
                                setErrors(errors);
                                const filterTouched = inputBoxList.users.map(() => ({ name: true }));
                                setTouched({ users: filterTouched });
                                const checkError = values.users.some(item => item.name === "");
                                if (!checkError) {
                                  submitSubCategory(item, values.users);
                                }
                                // removeEmptyValue(values, pop);
                              }}
                            > {" "}  Save{" "} </button>
                          </Col>
                          <Col>
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={() => {
                                setInputBoxList({ users: [{ name: "" }] });
                                statusChange(item);
                              }}
                            >  {" "}  Cancel{" "} </button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </FieldArray>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </ul>
      <Collapse in={item.isToggle} timeout="auto" unmountOnExit>
        {Array.isArray(subCategoryList) && (
          <List
            style={{ marginLeft: "10px" }}
            disablePadding dense >
            {subCategoryList.map((subItem, index) => (
              <React.Fragment key={`${subItem.categoryName}${index}`}>
                <SidebarItem
                  onUpdateCategory={onUpdateCategory}
                  onChangeCreateForm={onChangeCreateForm}
                  onStageSelect={onStageSelect}
                  selectedStage={selectedStage}
                  onStageChange={onStageChange}
                  stage={stageChange}
                  currentStage={currentStage + 1}
                  isMainCategoryOpen={isMainCategoryOpen}
                  onSubCategoryOpen={() => onCategoryOpen(true)}
                  onCategorySubmit={onNewCategoryAdd}
                  statusChange={onSubcategoryStatusChange}
                  item={subItem}
                  mainitem={item}
                  mainCategory={item}
                  changetext={changetext}
                  setChangeText={setChangeText}
                />
              </React.Fragment>
            ))}
          </List>
        )}
      </Collapse>
    </>
  );
};

export default SidebarMainItem;
