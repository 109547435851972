import React, { useMemo } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

// action
import { userForgetPassword, resetForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo-sm.png";

const ForgetPasswordPage = () => {
  //meta title
  document.title = "Forget Password | Greenalytics";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email")
    }),
    onSubmit: (values, actions) => {
      dispatch(userForgetPassword(values));
      setTimeout(() => {
        actions.resetForm();
      }, 3000);
    }
  });

  const { forgetError, forgetSuccessMsg, loading } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
    loading: state.ForgetPassword.loading
  }));

  useMemo(() => {
    if (forgetSuccessMsg !== null) {
      Swal.fire({
        html: `<div class='d-flex align-items-center'>
            <i class='mdi mdi-24px mdi-check-circle d-flex me-3'></i>
            <h5 class='m-0 text-primary'>${forgetSuccessMsg}</h5> </div>`,
        padding: "2em",
        color: "#3c7c44",
        showConfirmButton: false,
        showCloseButton: true
      }).then(() => {
        setTimeout(() => {
          dispatch(resetForgetPassword());
        }, 2000);
      });
    }
  }, [forgetSuccessMsg]);

  useMemo(() => {
    if (forgetError !== null) {
      Swal.fire({
        html: `<div class='d-flex align-items-center'>
            <i class='mdi mdi-24px mdi-close-circle d-flex me-3'></i>
            <h5 class='m-0 text-danger'>${forgetError}</h5> </div>`,
        padding: "2em",
        color: "#c35555",
        showConfirmButton: false,
        showCloseButton: true
      }).then(() => {
        setTimeout(() => {
          dispatch(resetForgetPassword());
        }, 2000);
      });
    }
  }, [forgetError]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft bg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Forgot password GreenAlytics.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email
                          ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          )
                          : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                            disabled={loading}
                          >
                            {loading
                              ? (
                                <div
                                  className="spinner-border text-white"
                                  role="status"
                                  style={{ width: "1.2rem", height: "1.2rem" }}
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )
                              : (
                                "Reset"
                              )}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Greenalytics. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Glasier Inc.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object
};

export default withRouter(ForgetPasswordPage);
