import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, Row, Label } from "reactstrap";

import { Name, Email, Role } from "./CryptoCol";
import IsRoleAuth from "pages/Authentication/IsRoleAuth";
import TableContainer from "components/Common/TableContainer";

import "react-toastify/dist/ReactToastify.css";
import { AxiosGet } from "common/axiosService";

const CompanyDetails = () => {
  const { id } = useParams();
  const apipath = process.env.REACT_APP_SUPERADMIN_API_PATH;

  const [userdata, setUserData] = useState({ companyData: {}, usersData: [] });
  const [count, setCount] = useState({ active: 0, deactivated: 0, suspended: 0 });

  const getData = async () => {
    try {
      const response = await AxiosGet(`${apipath}/companies/${id}`);
      setUserData(response?.data[0]);
      let activeCount = 0;
      let deactivatedCount = 0;
      let suspendedCount = 0;
      response?.data[0].usersData.forEach(element => {
        if (element.status === "Active") {
          activeCount = activeCount + 1;
        }
        if (element.status === "Deactivated") {
          deactivatedCount = deactivatedCount + 1;
        }
        if (element.status === "Suspended") {
          suspendedCount = suspendedCount + 1;
        }
      });
      setCount({
        active: activeCount,
        deactivated: deactivatedCount,
        suspended: suspendedCount
      });
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const productData = userdata.usersData;
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => <Name {...cellProps} />
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => <Email {...cellProps} />
      },
      {
        Header: "Role",
        accessor: "role",
        filterable: true,
        Cell: cellProps => <Role {...cellProps} />
      }
    ],
    []
  );
  return (
    <React.Fragment>
      <IsRoleAuth />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <div>
                    <div>
                      <Label>Name</Label>
                      <p className="text-muted">
                        {userdata.companyData.companyName}
                      </p>
                      <Label>URL</Label>
                      <p className="text-muted">{userdata.companyData.personalUrl}</p>
                      <Label>CIN No.</Label>
                      <p className="text-muted">{userdata.companyData.cin}</p>
                      <Label>Address</Label>
                      <p className="text-muted">{userdata.companyData.corporataeAddress}</p>
                      <Label>Phone No.</Label>
                      <p className="text-muted">{userdata.companyData.phoneNumber}</p>
                      <Label>Year Of Incorporation</Label>
                      <p className="text-muted">{userdata.companyData.yearOfIncorporation} Year </p>
                      <Label>Paid Up Capital</Label>
                      <p className="text-muted">{userdata.companyData.currency} {" "}{userdata.companyData.paidUpCapital}</p>
                      <Label>Authorized Capital</Label>
                      <p className="text-muted">{userdata.companyData.currency} {" "}{userdata.companyData.authorizedCapital}</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Row>
                <Col lg={4}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Active Users</p>
                          <h5 className="mb-0">{count.active}</h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="fas fa-users"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card className="blog-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Deactivated</p>
                          <h5 className="mb-0">{count.deactivated}</h5>
                        </div>
                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="fas fa-users"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card className="blog-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Suspended</p>
                          <h5 className="mb-0">{count.suspended}</h5>
                        </div>
                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="fas fa-users"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <h4 className="card-title mb-3">
                    Users ( {userdata.usersData.length})
                  </h4>
                  <div>
                    <div id="overview-chart" dir="ltr">
                      <TableContainer
                        columns={columns}
                        data={productData}
                        isGlobalFilter={true}
                        customPageSize={5}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CompanyDetails;
