import React from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { Link } from "react-router-dom";
import verifyImg from "../../../assets/images/verify.gif";

const EmailVerify = () => {
  //meta title
  document.title = "Verify | Greenalytics";

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5" id="register">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={4} xl={4}>
              <Card className="overflow-hidden">
                <CardBody className="pt-5 pb-5">
                  <div style={{ textAlign: "center" }}>
                    <h2>Verified</h2>
                  </div>
                  <div
                    className="p-2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      clear: "both",
                      fontSize: "130px"
                    }}
                  >
                    {/* <i className="bx bxs-check-circle text-primary"></i> */}
                    <img
                      src={verifyImg}
                      alt="verified"
                      className="rounded-circle"
                      height="100"
                      width="100"
                    />
                  </div>
                  <div className="mt-3 text-center">
                    <Link className="btn btn-primary" to="/login">
                      Get Started <i className="mdi mdi-arrow-right ms-1"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center copyright-text">
                <p>
                  © {new Date().getFullYear()} Greenalytics. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Glasier Inc.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmailVerify;
