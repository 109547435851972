import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Formik, FieldArray, Field } from "formik";
import { Modal, Card, Col, DropdownToggle, DropdownMenu, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import ModalSection from "../ModalSection";
import FormUpload from "../Modalfileupload";
import Input from "components/Inputs/Input";
import TextArea from "components/Inputs/TextArea";
import TableInput from "components/Inputs/TableInput";
import RadioButton from "components/Inputs/RadioButton";
import { AxiosGet, AxiosPost } from "common/axiosService";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const Principle6 = (props) => {
  const categories = ["Plastic waste", "E-waste", "Bio-medical waste",
    "Construction and demolition", "Battery waste", "Radioactive waste", "Other Hazardous waste", "Other Non-hazardous waste generated(H)"];
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionC.principle_6;
  const { businessInformation, draftSebiData, draftData, updateSebiData, previousData, newReport, draftReport, onSectionChange, oldData, prefillData, getFile, filesData } = props;

  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);
  const [disposedCategories, setDisposedCategories] = useState(categories);
  const [recoverdCategories, setRecoverdCategories] = useState(categories);

  const [filedatachange, setFileDataChange] = useState(true);

  const [modal_center, setmodal_center] = useState(false);
  const [modalData, setModalData] = useState({ name: "", value: "", type: "", title: "" });

  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionC", subSection: "principle_6", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect(() =>{
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_6"
      });
    }
  }, [newReport, filesData]);
  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionC",
      "subSection": "principle_6"
    });
  }, [filedatachange]);

  useEffect(() =>{
    if (previousData) {
      _set(data, "total_energy_consumption_and_energy.previous", _get(previousData, "total_energy_consumption_and_energy.current", undefined));
      _set(data, "disclosures_related_to_water.previous", _get(previousData, "disclosures_related_to_water.current", undefined));
      _set(data, "air_emissions.previous", _get(previousData, "air_emissions.current", undefined));
      _set(data, "greenhouse_gas_emissions.previous", _get(previousData, "greenhouse_gas_emissions.current", undefined));

      _set(data, "details_related_to_waste_management.previous.waste_generated", _get(previousData, "details_related_to_waste_management.current.waste_generated", undefined));

      if (_get(previousData, "details_related_to_waste_management.current.waste_recovered", undefined)) {
        const previousRecovered = _get(previousData, "details_related_to_waste_management.current.waste_recovered", undefined);
        const currentRecovered = _get(data, "details_related_to_waste_management.current.waste_recovered", undefined);
        let filterArray = [];
        _set(data, "details_related_to_waste_management.previous.waste_recovered", _get(previousData, "details_related_to_waste_management.current.waste_recovered", undefined));
        if (previousRecovered.length > currentRecovered.length) {
          const requiredLength = previousRecovered.length - currentRecovered.length;
          for (let index = 0; index < requiredLength; index++) {
            currentRecovered.push({
              name: "",
              recycled: "",
              reused: "",
              other_recovery_operations: "",
              total: ""
            });
          }
          previousRecovered.forEach((prevItem, prevIndex) => {
            currentRecovered.forEach((currItem, currIndex) =>{
              if (currIndex === prevIndex) {
                if (currItem.name) {
                  const index = previousRecovered.findIndex(item => item.name === currItem.name);
                  filterArray.splice(index, 1, currItem);
                  filterArray.splice(prevIndex, 1, {
                    name: prevItem.name, recycled: "",
                    reused: "",
                    other_recovery_operations: "",
                    total: ""
                  });
                } else {
                  if (!filterArray.some(item => item.name === prevItem.name)) {
                    filterArray.splice(prevIndex, 0, {
                      name: prevItem.name, recycled: "",
                      reused: "",
                      other_recovery_operations: "",
                      total: ""
                    });
                  }
                }
              }
              return currItem;
            });
          });
          let filterData = [];
          filterArray.forEach(item => {
            filterData = [...filterData, item.name];
          });
          setRecoverdCategories(categories.filter(i => !filterData.includes(i)));
          _set(data, "details_related_to_waste_management.current.waste_recovered", filterArray);
        } else {
          const requiredLength = currentRecovered.length - previousRecovered.length;
          for (let index = 0; index < requiredLength; index++) {
            requiredLength !== 0 && previousRecovered.push({
              name: "",
              recycled: "",
              reused: "",
              other_recovery_operations: "",
              total: ""
            });
          }
          if (previousRecovered.length > 0) {
            let filteredArray = [];
            previousRecovered.forEach(prevItem => {
              currentRecovered.map(currItem =>{
                if (currItem.name === prevItem.name) {
                  filteredArray = [...filteredArray, currItem];
                }
                return currItem;
              });
            });
            const currentData = [...filteredArray, ...currentRecovered].filter((v, i, a)=>a.findIndex(v2=>(v2.name===v.name))===i);
            let filterData = [];
            currentData.forEach(item => {
              filterData = [...filterData, item.name];
            });
            setRecoverdCategories(categories.filter(i => !filterData.includes(i)));
            _set(data, "details_related_to_waste_management.current.waste_recovered", currentData);
          }
        }
      }
      if (_get(previousData, "details_related_to_waste_management.current.waste_disposed", undefined)) {
        const previousDisposal = _get(previousData, "details_related_to_waste_management.current.waste_disposed", undefined);
        const currentDisposal = _get(data, "details_related_to_waste_management.current.waste_disposed", undefined);
        let filterArray = [];
        _set(data, "details_related_to_waste_management.previous.waste_disposed", _get(previousData, "details_related_to_waste_management.current.waste_disposed", undefined));
        if (previousDisposal.length > currentDisposal.length) {
          const requiredLength = previousDisposal.length - currentDisposal.length;
          for (let index = 0; index < requiredLength; index++) {
            currentDisposal.push({
              name: "",
              recycled: "",
              reused: "",
              other_recovery_operations: "",
              total: ""
            });
          }
          previousDisposal.forEach((prevItem, prevIndex) => {
            currentDisposal.forEach((currItem, currIndex) =>{
              if (currIndex === prevIndex) {
                if (currItem.name) {
                  const index = previousDisposal.findIndex(item => item.name === currItem.name);
                  filterArray.splice(index, 1, currItem);
                  filterArray.splice(prevIndex, 1, {
                    name: prevItem.name, recycled: "",
                    reused: "",
                    other_recovery_operations: "",
                    total: ""
                  });
                } else {
                  if (!filterArray.some(item => item.name === prevItem.name)) {
                    filterArray.splice(prevIndex, 0, {
                      name: prevItem.name, recycled: "",
                      reused: "",
                      other_recovery_operations: "",
                      total: ""
                    });
                  }
                }
              }
              return currItem;
            });
          });
          let filterData = [];
          filterArray.forEach(item => {
            filterData = [...filterData, item.name];
          });
          setDisposedCategories(categories.filter(i => !filterData.includes(i)));
          _set(data, "details_related_to_waste_management.current.waste_disposed", filterArray);
        } else {
          const requiredLength = currentDisposal.length - previousDisposal.length;
          for (let index = 0; index < requiredLength; index++) {
            requiredLength !== 0 && previousDisposal.push({
              name: "",
              recycled: "",
              reused: "",
              other_recovery_operations: "",
              total: ""
            });
          }
          if (previousDisposal.length > 0) {
            let filteredArray = [];
            previousDisposal.forEach(prevItem => {
              currentDisposal.map(currItem =>{
                if (currItem.name === prevItem.name) {
                  filteredArray = [...filteredArray, currItem];
                }
                return currItem;
              });
            });
            const currentData = [...filteredArray, ...currentDisposal].filter((v, i, a)=>a.findIndex(v2=>(v2.name===v.name))===i);
            let filterData = [];
            currentData.forEach(item => {
              filterData = [...filterData, item.name];
            });
            setDisposedCategories(categories.filter(i => !filterData.includes(i)));
            _set(data, "details_related_to_waste_management.current.waste_disposed", currentData);
          }
        }
      }
      _set(data, "renewable_and_nonrenewable_sources.previous", _get(previousData, "renewable_and_nonrenewable_sources.current", undefined));
      _set(data, "water_discharged.previous", _get(previousData, "water_discharged.current", undefined));
      _set(data, "areas_of_water_stress.previous", _get(previousData, "areas_of_water_stress.current", undefined));
      _set(data, "details_of_total_scope3_emissions.previous", _get(previousData, "details_of_total_scope3_emissions.current", undefined));
    }
    if (prefillData?.electricity_consumption) {
      const totalelectricity = Number(_get(prefillData, "electricity_consumption.renewable_sources.solar", 0))+
      Number(_get(prefillData, "electricity_consumption.renewable_sources.tidal", 0))+
      Number(_get(prefillData, "electricity_consumption.renewable_sources.others", 0))+
      Number(_get(prefillData, "electricity_consumption.renewable_sources.wind", 0))+
      Number(_get(prefillData, "electricity_consumption.nonrenewable_sources.other", 0))+
      Number(_get(prefillData, "electricity_consumption.nonrenewable_sources.state_electricity_board", 0));
      // water_discharged.current.total
      // disclosures_related_to_water.current.total_water_consumption
      _set(data, "total_energy_consumption_and_energy.current.electricity_consumption", totalelectricity);

      const otherTotal = Number(_get(prefillData, "energy.consumption_joules_watthours_kwh.cooling", 0))+
      Number(_get(prefillData, "energy.consumption_joules_watthours_kwh.heating", 0))+
      Number(_get(prefillData, "energy.consumption_joules_watthours_kwh.steam", 0));

      _set(data, "total_energy_consumption_and_energy.current.energy_consumption_through_other_sources", otherTotal);

      const total = Number(_get(data, "total_energy_consumption_and_energy.current.electricity_consumption", 0))+
      Number(_get(data, "total_energy_consumption_and_energy.current.fuel_consumption", 0)) + otherTotal;

      _set(data, "total_energy_consumption_and_energy.current.total_energy_consumption", total);
    }
    if (prefillData?.water_usage?.water_withdrawal) {
      _set(data, "disclosures_related_to_water.current.surface", _get(prefillData, "water_usage.water_withdrawal.surface_water", ""));
      _set(data, "disclosures_related_to_water.current.ground", _get(prefillData, "water_usage.water_withdrawal.ground_water", ""));
      _set(data, "disclosures_related_to_water.current.third_party", _get(prefillData, "water_usage.water_withdrawal.third_party_water", ""));
      _set(data, "disclosures_related_to_water.current.seawater_desalinated", _get(prefillData, "water_usage.water_withdrawal.seawater_or_desalinated_water", ""));
      _set(data, "disclosures_related_to_water.current.others", _get(prefillData, "water_usage.water_withdrawal.others", ""));

      const total = Number(_get(prefillData, "water_usage.water_withdrawal.surface_water", 0))+
      Number(_get(prefillData, "water_usage.water_withdrawal.ground_water", 0))+
      Number(_get(prefillData, "water_usage.water_withdrawal.others", 0))+
      Number(_get(prefillData, "water_usage.water_withdrawal.seawater_or_desalinated_water", 0))+
      Number(_get(prefillData, "water_usage.water_withdrawal.third_party_water", 0));
      _set(data, "disclosures_related_to_water.current.total_water_withdrawal", total);
    }

    if (prefillData?.water_usage?.water_discharge && prefillData?.water_usage?.water_withdrawal && !_get(data, "disclosures_related_to_water.current.total_water_consumption", "")) {
      const totalwithdrawal = Number(_get(prefillData, "water_usage.water_withdrawal.surface_water", 0))+
      Number(_get(prefillData, "water_usage.water_withdrawal.ground_water", 0))+
      Number(_get(prefillData, "water_usage.water_withdrawal.others", 0))+
      Number(_get(prefillData, "water_usage.water_withdrawal.seawater_or_desalinated_water", 0))+
      Number(_get(prefillData, "water_usage.water_withdrawal.third_party_water", 0));

      const totaldischarge = Number(_get(prefillData, "water_usage.water_discharge.surface_water", 0))+
      Number(_get(prefillData, "water_usage.water_discharge.ground_water", 0))+
      Number(_get(prefillData, "water_usage.water_discharge.others", 0))+
      Number(_get(prefillData, "water_usage.water_discharge.seawater_or_desalinated_water", 0))+
      Number(_get(prefillData, "water_usage.water_discharge.third_party_water", 0));
      _set(data, "disclosures_related_to_water.current.total_water_consumption", totalwithdrawal- totaldischarge);
      if (businessInformation && businessInformation.turnover) {
        _set(data, "disclosures_related_to_water.current.water_intensity_per_rupee", Number((totalwithdrawal- totaldischarge)/businessInformation?.turnover).toFixed(2));
      }
    }
    if (prefillData?.waste_management?.waste_generated) {
      _set(data, "details_related_to_waste_management.current.waste_generated.plastic", _get(prefillData, "waste_management.waste_generated.plastic_waste", ""));
      _set(data, "details_related_to_waste_management.current.waste_generated.ewaste", _get(prefillData, "waste_management.waste_generated.ewaste", ""));
      _set(data, "details_related_to_waste_management.current.waste_generated.bio_medical", _get(prefillData, "waste_management.waste_generated.biomedical_waste", ""));
      _set(data, "details_related_to_waste_management.current.waste_generated.construction_and_demolition",
        _get(prefillData, "waste_management.waste_generated.construction_and_demolition_waste", ""));
      _set(data, "details_related_to_waste_management.current.waste_generated.battery", _get(prefillData, "waste_management.waste_generated.battery_waste", ""));
      _set(data, "details_related_to_waste_management.current.waste_generated.radioactive", _get(prefillData, "waste_management.waste_generated.radioactive_waste", ""));
      _set(data, "details_related_to_waste_management.current.waste_generated.other_hazardous", _get(prefillData, "waste_management.waste_generated.other_hazardous_waste", ""));
      _set(data, "details_related_to_waste_management.current.waste_generated.other_non_hazardous", _get(prefillData, "waste_management.waste_generated.other_non_hazardous_waste", ""));

      const total = Number(_get(prefillData, "waste_management.waste_generated.plastic_waste", 0))+
      Number(_get(prefillData, "waste_management.waste_generated.ewaste", 0))+
      Number(_get(prefillData, "waste_management.waste_generated.biomedical_waste", 0))+
      Number(_get(prefillData, "waste_management.waste_generated.construction__demolition_waste", 0))+
      Number(_get(prefillData, "waste_management.waste_generated.battery_waste", 0))+
      Number(_get(prefillData, "waste_management.waste_generated.radioactive_waste", 0))+
      Number(_get(prefillData, "waste_management.waste_generated.other_hazardous_waste", 0))+
      Number(_get(prefillData, "waste_management.waste_generated.other_non_hazardous_waste", 0));
      _set(data, "details_related_to_waste_management.current.waste_generated.total", total);
    }

    if (prefillData?.greenhouse_gas_emissions) {
      const scope1 = Number(_get(prefillData, "greenhouse_gas_emissions.scope_1.stationery_combustion", 0))+
      Number(_get(prefillData, "greenhouse_gas_emissions.scope_1.mobile_combustion", 0))+
      Number(_get(prefillData, "greenhouse_gas_emissions.scope_1.process_emissions", 0))+
      Number(_get(prefillData, "greenhouse_gas_emissions.scope_1.fugitive_emissions", 0));
      _set(data, "greenhouse_gas_emissions.current.scope_1", scope1);

      _set(data, "greenhouse_gas_emissions.current.scope_2", ( _get(prefillData, "greenhouse_gas_emissions.scope_2.from_produced_electricity", "") +
      _get(prefillData, "greenhouse_gas_emissions.scope_2.from_purchased_electricity", "")));

      _set(data, "details_of_total_scope3_emissions.current.scope3_emissions", ( _get(prefillData, "greenhouse_gas_emissions.scope_3.downstream_emissions", "") +
      _get(prefillData, "greenhouse_gas_emissions.scope_3.upstream_emissions", "")));
      if (businessInformation && businessInformation.turnover) {
        _set(data, "details_of_total_scope3_emissions.current.scope3_emissions_per_rupee.value",
          _get(data, "details_of_total_scope3_emissions.current.scope3_emissions", 0) ?
            Number( _get(data, "details_of_total_scope3_emissions.current.scope3_emissions", 0)/businessInformation?.turnover).toFixed(2)
            : 0);
        const total = Number(_get(data, "greenhouse_gas_emissions.current.scope_1", 0)) +Number( _get(data, "greenhouse_gas_emissions.current.scope_2", 0));
        _set(data, "greenhouse_gas_emissions.current.scope1_scope2_emissions_per_rupee.value", Number(total/businessInformation?.turnover).toFixed(2) );
      }
    }
  }, [previousData, prefillData]);

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, principle_6: data });
    if (e.target.options) {
      const name = e.target.name.split("[");
      const recoverdArray = _get(data, name[0], "");
      if (recoverdArray && recoverdArray?.length > 0) {
        let categoryName = name[0]?.split(".");
        categoryName = categoryName[categoryName.length -1];
        if (categoryName === "waste_recovered") {
          let filterData = [];
          recoverdArray.forEach(item => {
            filterData = [...filterData, item.name];
          });
          setRecoverdCategories(categories.filter(i => !filterData.includes(i)));
        }
        if (categoryName === "waste_disposed") {
          let filterData = [];
          recoverdArray.forEach(item => {
            filterData = [...filterData, item.name];
          });
          setDisposedCategories(categories.filter(i => !filterData.includes(i)));
        }
      }
    }
    setData({ ...data });
  };

  const onScopeHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    if (businessInformation && businessInformation.turnover) {
      _set(data, "details_of_total_scope3_emissions.current.scope3_emissions_per_rupee.value", e.target.value ? Number(e.target.value/businessInformation?.turnover).toFixed(2) : 0);
    }
    draftSebiData({ ...draftReport, principle_6: data });
    setData({ ...data });
  };
  const onScopesHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    if (businessInformation && businessInformation.turnover) {
      const total = Number(_get(data, "greenhouse_gas_emissions.current.scope_1", 0)) + Number(_get(data, "greenhouse_gas_emissions.current.scope_2", 0)); console.log("total==>", total);
      _set(data, "greenhouse_gas_emissions.current.scope1_scope2_emissions_per_rupee.value", Number(total/businessInformation?.turnover).toFixed(2));
    }
    draftSebiData({ ...draftReport, principle_6: data });
    setData({ ...data });
  };

  const onEnergyHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    const total = Number(_get(data, "total_energy_consumption_and_energy.current.electricity_consumption", 0))+
    Number(_get(data, "total_energy_consumption_and_energy.current.fuel_consumption", 0))+
    Number(_get(data, "total_energy_consumption_and_energy.current.energy_consumption_through_other_sources", 0));
    _set(data, "total_energy_consumption_and_energy.current.total_energy_consumption", total);
    draftSebiData({ ...draftReport, principle_6: data });
    setData({ ...data });
  };
  const onWaterHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    const total = Number(_get(data, "disclosures_related_to_water.current.surface", 0))+
      Number(_get(data, "disclosures_related_to_water.current.ground", 0))+
      Number(_get(data, "disclosures_related_to_water.current.others", 0))+
      Number(_get(data, "disclosures_related_to_water.current.seawater_desalinated", 0))+
      Number(_get(data, "disclosures_related_to_water.current.third_party", 0));
    _set(data, "disclosures_related_to_water.current.total_water_withdrawal", total);
    const waterDischargeTotal = _get(data, "water_discharged.current.total", 0);
    _set(data, "disclosures_related_to_water.current.total_water_consumption", total - waterDischargeTotal);
    if (businessInformation && businessInformation.turnover) {
      _set(data, "disclosures_related_to_water.current.water_intensity_per_rupee", Number((total - waterDischargeTotal)/businessInformation?.turnover).toFixed(2));
    }
    draftSebiData({ ...draftReport, principle_6: data });
    setData({ ...data });
  };
  const onWaterWithdrawalHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    const total = Number(_get(data, "areas_of_water_stress.current.withdrawal.surface", 0))+
      Number(_get(data, "areas_of_water_stress.current.withdrawal.ground", 0))+
      Number(_get(data, "areas_of_water_stress.current.withdrawal.others", 0))+
      Number(_get(data, "areas_of_water_stress.current.withdrawal.seawater_desalinated", 0))+
      Number(_get(data, "areas_of_water_stress.current.withdrawal.third_party", 0));
    _set(data, "areas_of_water_stress.current.withdrawal.total_withdrawal", total);
    draftSebiData({ ...draftReport, principle_6: data });
    setData({ ...data });
  };
  const onWaterDischargeHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    const total = Number(_get(data, "areas_of_water_stress.current.discharge.surface.no_treatment", 0))+
      Number(_get(data, "areas_of_water_stress.current.discharge.surface.with_treatment", 0))+
      Number(_get(data, "areas_of_water_stress.current.discharge.ground.no_treatment", 0))+
      Number(_get(data, "areas_of_water_stress.current.discharge.ground.with_treatment", 0))+
      Number(_get(data, "areas_of_water_stress.current.discharge.sea.no_treatment", 0))+
      Number(_get(data, "areas_of_water_stress.current.discharge.sea.with_treatment", 0))+
      Number(_get(data, "areas_of_water_stress.current.discharge.others.no_treatment", 0))+
      Number(_get(data, "areas_of_water_stress.current.discharge.others.with_treatment", 0))+
      Number(_get(data, "areas_of_water_stress.current.discharge.third_parties.no_treatment", 0))+
      Number(_get(data, "areas_of_water_stress.current.discharge.third_parties.with_treatment", 0));
    _set(data, "areas_of_water_stress.current.discharge.total", total);
    draftSebiData({ ...draftReport, principle_6: data });
    setData({ ...data });
  };
  const onWaterDischargedHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    const total = Number(_get(data, "water_discharged.current.surface.no_treatment", 0))+
      Number(_get(data, "water_discharged.current.surface.with_treatment", 0))+
      Number(_get(data, "water_discharged.current.ground.no_treatment", 0))+
      Number(_get(data, "water_discharged.current.ground.with_treatment", 0))+
      Number(_get(data, "water_discharged.current.sea.no_treatment", 0))+
      Number(_get(data, "water_discharged.current.sea.with_treatment", 0))+
      Number(_get(data, "water_discharged.current.others.no_treatment", 0))+
      Number(_get(data, "water_discharged.current.others.with_treatment", 0))+
      Number(_get(data, "water_discharged.current.third_parties.no_treatment", 0))+
      Number(_get(data, "water_discharged.current.third_parties.with_treatment", 0));
    _set(data, "water_discharged.current.total", total);
    const waterWithdrawlTotal = _get(data, "disclosures_related_to_water.current.total_water_withdrawal", 0);
    _set(data, "disclosures_related_to_water.current.total_water_consumption", waterWithdrawlTotal - total);
    if (businessInformation && businessInformation.turnover) {
      _set(data, "disclosures_related_to_water.current.water_intensity_per_rupee", Number((waterWithdrawlTotal - total)/businessInformation?.turnover).toFixed(2));
    }
    draftSebiData({ ...draftReport, principle_6: data });
    setData({ ...data });
  };
  const onWasteGenerateHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    const total = Number(_get(data, "details_related_to_waste_management.current.waste_generated.plastic", 0))+
      Number(_get(data, "details_related_to_waste_management.current.waste_generated.ewaste", 0))+
      Number(_get(data, "details_related_to_waste_management.current.waste_generated.bio_medical", 0))+
      Number(_get(data, "details_related_to_waste_management.current.waste_generated.construction_and_demolition", 0))+
      Number(_get(data, "details_related_to_waste_management.current.waste_generated.battery", 0))+
      Number(_get(data, "details_related_to_waste_management.current.waste_generated.radioactive", 0))+
      Number(_get(data, "details_related_to_waste_management.current.waste_generated.other_hazardous", 0))+
      Number(_get(data, "details_related_to_waste_management.current.waste_generated.other_non_hazardous", 0));
    _set(data, "details_related_to_waste_management.current.waste_generated.total", total);
    draftSebiData({ ...draftReport, principle_6: data });
    setData({ ...data });
  };
  const onWasteRecoverHandleChange = (e, index) => {
    _set(data, e.target.name, e.target.value);
    const total = Number(_get(data, `details_related_to_waste_management.current.waste_recovered[${index}].recycled`, 0))+
      Number(_get(data, `details_related_to_waste_management.current.waste_recovered[${index}].reused`, 0))+
      Number(_get(data, `details_related_to_waste_management.current.waste_recovered[${index}].other_recovery_operations`, 0));
    _set(data, `details_related_to_waste_management.current.waste_recovered[${index}].total`, total);
    draftSebiData({ ...draftReport, principle_6: data });
    setData({ ...data });
  };
  const onWasteDisposedHandleChange = (e, index) => {
    _set(data, e.target.name, e.target.value);
    const total = Number(_get(data, `details_related_to_waste_management.current.waste_disposed[${index}].incineration`, 0))+
      Number(_get(data, `details_related_to_waste_management.current.waste_disposed[${index}].landfilling`, 0))+
      Number(_get(data, `details_related_to_waste_management.current.waste_disposed[${index}].other_disposal_operations`, 0));
    _set(data, `details_related_to_waste_management.current.waste_disposed[${index}].total`, total);
    draftSebiData({ ...draftReport, principle_6: data });
    setData({ ...data });
  };
  const onRenewableHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    const total = Number(_get(data, "renewable_and_nonrenewable_sources.current.renewable.electricity", 0))+
      Number(_get(data, "renewable_and_nonrenewable_sources.current.renewable.fuel", 0))+
      Number(_get(data, "renewable_and_nonrenewable_sources.current.renewable.energy", 0));
    _set(data, "renewable_and_nonrenewable_sources.current.renewable.total_energy", total);
    draftSebiData({ ...draftReport, principle_6: data });
    setData({ ...data });
  };
  const onNonRenewableHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    const total = Number(_get(data, "renewable_and_nonrenewable_sources.current.nonrenewable.electricity", 0))+
    Number(_get(data, "renewable_and_nonrenewable_sources.current.nonrenewable.fuel", 0))+
    Number(_get(data, "renewable_and_nonrenewable_sources.current.nonrenewable.energy", 0));
    _set(data, "renewable_and_nonrenewable_sources.current.nonrenewable.total_energy_consumed", total);
    draftSebiData({ ...draftReport, principle_6: data });
    setData({ ...data });
  };

  function tog_center () {
    setmodal_center(!modal_center);
  }

  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_6",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionC",
          "subSection": "principle_6"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  return (
    <React.Fragment >
      <Modal className="center-modal"
        size="lg" isOpen={modal_center}
        toggle={() => { tog_center(); }}
        centered
        autoFocus={false}
        backdrop={"static"}
      >
        <ModalSection modalData={modalData} setModalData={setModalData} state={data} setmodal_center={setmodal_center}/>
        <div className="modal-footer">
          <button
            onClick={(e) => {
              setmodal_center(false);
              e.target.blur();
              setModalData({ name: "", value: "", type: "", title: "" });
            }}
            className="btn btn-primary"
          >Update</button>
        </div>
      </Modal>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <Row>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="mt-2"><b>PRINCIPLE 6: Businesses should respect and make efforts to protect and restore the environment</b> </h5>
                <p className="txt-decoration mt-2"><b>Essential Indicators</b></p>
                <Formik initialValues={data}>
                  {({ values, setFieldValue }) => (
                    <form
                      onChange={onHandleChange}
                      onSubmit={(e) => {e.preventDefault();
                        _set(sebiCurrentData, "sectionC.principle_6", data);
                        updateSebiData(sebiCurrentData);
                        draftSebiData({ ...draftReport, principle_6: false });
                      }}>
                      <p>1. Details of total energy consumption (in Joules or multiples) and energy intensity, in the following format:</p>
                      <table className="table table-bordered mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>Parameter</th>
                            <th className="text-align">FY (Current Financial Year)</th>
                            <th className="text-align">FY (Previous Financial Year)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-align-left">Total electricity consumption (A)</td>
                            <TableInput
                              name="total_energy_consumption_and_energy.current.electricity_consumption"
                              value={_get(data, "total_energy_consumption_and_energy.current.electricity_consumption", "")}
                              onChange={onEnergyHandleChange}
                            />
                            <TableInput
                              isDisabled={true}
                              name="total_energy_consumption_and_energy.previous.electricity_consumption"
                              value={_get(data, "total_energy_consumption_and_energy.previous.electricity_consumption", "")}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">Total fuel consumption (B)</td>
                            <TableInput
                              name="total_energy_consumption_and_energy.current.fuel_consumption"
                              value={_get(data, "total_energy_consumption_and_energy.current.fuel_consumption", "")}
                              onChange={onEnergyHandleChange}
                            />
                            <TableInput
                              isDisabled={true}
                              name="total_energy_consumption_and_energy.previous.fuel_consumption"
                              value={_get(data, "total_energy_consumption_and_energy.previous.fuel_consumption", "")}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">Energy consumption through other sources (C) </td>
                            <TableInput
                              name="total_energy_consumption_and_energy.current.energy_consumption_through_other_sources"
                              value={_get(data, "total_energy_consumption_and_energy.current.energy_consumption_through_other_sources", "")}
                              onChange={onEnergyHandleChange}
                            />
                            <TableInput
                              isDisabled={true}
                              name="total_energy_consumption_and_energy.previous.energy_consumption_through_other_sources"
                              value={_get(data, "total_energy_consumption_and_energy.previous.energy_consumption_through_other_sources", "")}
                            />
                          </tr>
                          <tr>
                            <th>Total energy consumption (A+B+C) </th>
                            <TableInput
                              isDisabled={true}
                              name="total_energy_consumption_and_energy.current.total_energy_consumption"
                              value={_get(data, "total_energy_consumption_and_energy.current.total_energy_consumption", "")}
                            />
                            <TableInput
                              isDisabled={true}
                              name="total_energy_consumption_and_energy.previous.total_energy_consumption"
                              value={_get(data, "total_energy_consumption_and_energy.previous.total_energy_consumption", "")}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">Energy intensity per rupee of turnover <i>(Total energy consumption turnover in rupees)</i> </td>
                            <TableInput
                              name="total_energy_consumption_and_energy.current.energy_intensity_per_rupee_of_turnover"
                              value={_get(data, "total_energy_consumption_and_energy.current.energy_intensity_per_rupee_of_turnover", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              isDisabled={true}
                              name="total_energy_consumption_and_energy.previous.energy_intensity_per_rupee_of_turnover"
                              value={_get(data, "total_energy_consumption_and_energy.previous.energy_intensity_per_rupee_of_turnover", "")}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">Energy intensity (optional) – the relevant metric may be selected by the entity</td>
                            <TableInput
                              name="total_energy_consumption_and_energy.current.relevant_metric_may_be_selected"
                              value={_get(data, "total_energy_consumption_and_energy.current.relevant_metric_may_be_selected", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              isDisabled={true}
                              name="total_energy_consumption_and_energy.previous.relevant_metric_may_be_selected"
                              value={_get(data, "total_energy_consumption_and_energy.previous.relevant_metric_may_be_selected", "")}
                            />
                          </tr>
                        </tbody>
                      </table>

                      <p>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) <br /> If yes, name of the external agency.</p>
                      <RadioButton id="radio_360button" className="dis-right"
                        name="carried_out_by_an_external_agency_1"
                        value={_get(data, "carried_out_by_an_external_agency_1", "")}
                        onChange={onHandleChange}
                        onSubmit={(e) => {e.preventDefault();
                          _set(sebiCurrentData, "sectionC.principle_6", data);
                          updateSebiData(sebiCurrentData);
                          draftSebiData({ ...draftReport, principle_6: false });
                        }} />
                      <TextArea
                        name="name_1"
                        value={_get(data, "name_1", "")}
                        onChange={onHandleChange}
                      />

                      <Row className="mt-2 align-items-center">
                        {filesData?.energy_consumption?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "energy_consumption" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}

                        {filesData?.energy_consumption?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "energy_consumption" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "energy_consumption" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>

                      <p className="mt-2">2. Does the entity have any sites / facilities identified as designated consumers (DCs) under the Performance, Achieve and Trade
                    (PAT) Scheme of the Government of India?<br />  If yes, disclose whether targets set under the PAT scheme have been achieved.
                    In case targets have not been achieved, provide the remedial action taken, if any.</p>
                      <RadioButton id="radio_127button" className="dis-right"
                        name="entity_have_any_sites_facilities_identified"
                        value={_get(data, "entity_have_any_sites_facilities_identified", "")}
                        onChange={onHandleChange}
                      />
                      <TextArea
                        name="disclose_whether_targets"
                        value={_get(data, "disclose_whether_targets", "")}
                        onChange={onHandleChange}
                      />

                      <p className="mt-2">3. Provide details of the following disclosures related to water, in the following format:</p>
                      <table className="table table-bordered mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>Parameter</th>
                            <th className="text-align">FY (Current Financial Year) </th>
                            <th className="text-align">FY (Previous Financial Year) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan="3">Water withdrawal by source (in kilolitres)</th>
                          </tr>
                          <tr>
                            <td className="text-align-left">(i) Surface water</td>
                            <TableInput
                              type="number"
                              name="disclosures_related_to_water.current.surface"
                              value={_get(data, "disclosures_related_to_water.current.surface", "")}
                              onChange={onWaterHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="disclosures_related_to_water.previous.surface"
                              value={_get(data, "disclosures_related_to_water.previous.surface", "")}
                              onChange={onWaterHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">(ii) Groundwater</td>
                            <TableInput
                              type="number"
                              name="disclosures_related_to_water.current.ground"
                              value={_get(data, "disclosures_related_to_water.current.ground", "")}
                              onChange={onWaterHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="disclosures_related_to_water.previous.ground"
                              value={_get(data, "disclosures_related_to_water.previous.ground", "")}
                              onChange={onWaterHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">(iii) Third party water</td>
                            <TableInput
                              type="number"
                              name="disclosures_related_to_water.current.third_party"
                              value={_get(data, "disclosures_related_to_water.current.third_party", "")}
                              onChange={onWaterHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="disclosures_related_to_water.previous.third_party"
                              value={_get(data, "disclosures_related_to_water.previous.third_party", "")}
                              onChange={onWaterHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">(iv) Seawater / desalinated water</td>
                            <TableInput
                              type="number"
                              name="disclosures_related_to_water.current.seawater_desalinated"
                              value={_get(data, "disclosures_related_to_water.current.seawater_desalinated", "")}
                              onChange={onWaterHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="disclosures_related_to_water.previous.seawater_desalinated"
                              value={_get(data, "disclosures_related_to_water.previous.seawater_desalinated", "")}
                              onChange={onWaterHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">(v) Others</td>
                            <TableInput
                              type="number"
                              name="disclosures_related_to_water.current.others"
                              value={_get(data, "disclosures_related_to_water.current.others", "")}
                              onChange={onWaterHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="disclosures_related_to_water.previous.others"
                              value={_get(data, "disclosures_related_to_water.previous.others", "")}
                              onChange={onWaterHandleChange}
                            />
                          </tr>
                          <tr>
                            <th className="text-align-left">Total volume of water withdrawal (in kilolitres) (i + ii + iii + iv + v) </th>
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="disclosures_related_to_water.current.total_water_withdrawal"
                              value={_get(data, "disclosures_related_to_water.current.total_water_withdrawal", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="disclosures_related_to_water.previous.total_water_withdrawal"
                              value={_get(data, "disclosures_related_to_water.previous.total_water_withdrawal", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                          <tr>
                            <th className="text-align-left">Total volume of water consumption (in kilolitres)&nbsp;&nbsp;&nbsp; (Total water withdrawal - Total water discharge) </th>
                            <TableInput
                              isDisabled={true}
                              type="number"
                              name="disclosures_related_to_water.current.total_water_consumption"
                              value={_get(data, "disclosures_related_to_water.current.total_water_consumption", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="disclosures_related_to_water.previous.total_water_consumption"
                              value={_get(data, "disclosures_related_to_water.previous.total_water_consumption", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left"><b>Water intensity per rupee of turnover </b><i> (Water consumed / turnover) </i></td>
                            <TableInput
                              isDisabled={true}
                              type="number"
                              name="disclosures_related_to_water.current.water_intensity_per_rupee"
                              value={_get(data, "disclosures_related_to_water.current.water_intensity_per_rupee", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="disclosures_related_to_water.previous.water_intensity_per_rupee"
                              value={_get(data, "disclosures_related_to_water.previous.water_intensity_per_rupee", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left"><b>Water intensity</b><i> (optional)</i> – the relevant metric may be selected by the entity </td>
                            <TableInput
                              type="number"
                              name="disclosures_related_to_water.current.water_intensity"
                              value={_get(data, "disclosures_related_to_water.current.water_intensity", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="disclosures_related_to_water.previous.water_intensity"
                              value={_get(data, "disclosures_related_to_water.previous.water_intensity", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                        </tbody>
                      </table>

                      <p>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) <br /> If yes, name of the external agency.</p>
                      <RadioButton id="radio_361button" className="dis-right"
                        name="carried_out_by_an_external_agency_3"
                        value={_get(data, "carried_out_by_an_external_agency_3", "")}
                        onChange={onHandleChange}
                      />
                      <TextArea
                        name="name_3"
                        value={_get(data, "name_3", "")}
                        onChange={onHandleChange}
                      />

                      <Row className="mt-2 align-items-center">
                        {filesData?.disclosures_related_to_water?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "disclosures_related_to_water" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}

                        {filesData?.disclosures_related_to_water?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "disclosures_related_to_water" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "disclosures_related_to_water" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>

                      <p className="mt-2">4. Has the entity implemented a mechanism for Zero Liquid Discharge? If yes, provide details of its coverage and implementation.</p>
                      <RadioButton id="radio_362button" className="dis-right"
                        name="mechanism_for_zero_liquid_discharge"
                        value={_get(data, "mechanism_for_zero_liquid_discharge", "")}
                        onChange={onHandleChange}
                      />
                      <TextArea
                        name="details_of_coverage_and_implementation"
                        value={_get(data, "details_of_coverage_and_implementation", "")}
                        onChange={onHandleChange}
                      />

                      <p className="mt-2">5. Please provide details of air emissions (other than GHG emissions) by the entity, in the following format:</p>
                      <table className="table table-bordered mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>Parameter</th>
                            <th>Please specify unit</th>
                            <th>FY  (Current Financial Year) </th>
                            <th>FY  (Previous Financial Year) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-align-left">NOx</td>
                            <TableInput
                              name="air_emissions.current.nox.unit"
                              value={_get(data, "air_emissions.current.nox.unit", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              name="air_emissions.current.nox.value"
                              value={_get(data, "air_emissions.current.nox.value", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="air_emissions.previous.nox.value"
                              value={_get(data, "air_emissions.previous.nox.value", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">SOx</td>
                            <TableInput
                              name="air_emissions.current.sox.unit"
                              value={_get(data, "air_emissions.current.sox.unit", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              name="air_emissions.current.sox.value"
                              value={_get(data, "air_emissions.current.sox.value", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="air_emissions.previous.sox.value"
                              value={_get(data, "air_emissions.previous.sox.value", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">Particulate matter (PM) </td>
                            <TableInput
                              name="air_emissions.current.particulate_matter.unit"
                              value={_get(data, "air_emissions.current.particulate_matter.unit", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              name="air_emissions.current.particulate_matter.value"
                              value={_get(data, "air_emissions.current.particulate_matter.value", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="air_emissions.previous.particulate_matter.value"
                              value={_get(data, "air_emissions.previous.particulate_matter.value", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">Persistent organic pollutants (POP) </td>
                            <TableInput
                              name="air_emissions.current.organic_pollutants.unit"
                              value={_get(data, "air_emissions.current.organic_pollutants.unit", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              name="air_emissions.current.organic_pollutants.value"
                              value={_get(data, "air_emissions.current.organic_pollutants.value", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="air_emissions.previous.organic_pollutants.value"
                              value={_get(data, "air_emissions.previous.organic_pollutants.value", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">Volatile organic compounds (VOC) </td>
                            <TableInput
                              name="air_emissions.current.organic_compounds.unit"
                              value={_get(data, "air_emissions.current.organic_compounds.unit", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              name="air_emissions.current.organic_compounds.value"
                              value={_get(data, "air_emissions.current.organic_compounds.value", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="air_emissions.previous.organic_compounds.value"
                              value={_get(data, "air_emissions.previous.organic_compounds.value", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">Hazardous air pollutants (HAP) </td>
                            <TableInput
                              name="air_emissions.current.hazardous_air.unit"
                              value={_get(data, "air_emissions.current.hazardous_air.unit", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              name="air_emissions.current.hazardous_air.value"
                              value={_get(data, "air_emissions.current.hazardous_air.value", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="air_emissions.previous.hazardous_air.value"
                              value={_get(data, "air_emissions.previous.hazardous_air.value", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">Others – please specify </td>
                            <TableInput
                              name="air_emissions.current.others.unit"
                              value={_get(data, "air_emissions.current.others.unit", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              name="air_emissions.current.others.value"
                              value={_get(data, "air_emissions.current.others.value", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="air_emissions.previous.others.value"
                              value={_get(data, "air_emissions.previous.others.value", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                        </tbody>
                      </table>

                      <p>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) <br /> If yes, name of the external agency.</p>
                      <RadioButton id="radio_363button" className="dis-right"
                        name="carried_out_by_an_external_agency_5"
                        value={_get(data, "carried_out_by_an_external_agency_5", "")}
                        onChange={onHandleChange}
                      />
                      <TextArea
                        name="name_5"
                        value={_get(data, "name_5", "")}
                        onChange={onHandleChange}
                      />

                      <Row className="mt-2 align-items-center">
                        {filesData?.air_emissions?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "air_emissions" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}

                        {filesData?.air_emissions?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "air_emissions" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "air_emissions" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>

                      <p className="mt-2">6. Provide details of greenhouse gas emissions (Scope 1 and Scope 2 emissions) & its intensity, in the following format:</p>
                      <table className="table table-bordered mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>Parameter</th>
                            <th className="text-align">Unit</th>
                            <th className="text-align">FY (Current Financial Year) </th>
                            <th className="text-align">FY (Previous Financial Year) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-align-left"><b>Total Scope 1 emissions </b> (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available) </td>
                            <td className="text-align-left"><i>Metric tonnes of CO2 equivalent </i></td>
                            <TableInput
                              type="number"
                              name="greenhouse_gas_emissions.current.scope_1"
                              value={_get(data, "greenhouse_gas_emissions.current.scope_1", "")}
                              onChange={onScopesHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="greenhouse_gas_emissions.previous.scope_1"
                              value={_get(data, "greenhouse_gas_emissions.previous.scope_1", "")}
                              onChange={onHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left"><b>Total Scope 2 emissions  </b> (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available) </td>
                            <td className="text-align-left"><i>Metric tonnes of CO2 equivalent </i></td>
                            <TableInput
                              type="number"
                              name="greenhouse_gas_emissions.current.scope_2"
                              value={_get(data, "greenhouse_gas_emissions.current.scope_2", "")}
                              onChange={onScopesHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="greenhouse_gas_emissions.previous.scope_2"
                              value={_get(data, "greenhouse_gas_emissions.previous.scope_2", "")}
                              onChange={onHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left"><b>Total Scope 1 and Scope 2 emissions per rupee of turnover </b></td>
                            <td className="text-align-left"><i>Metric tonnes of CO2 equivalent per rupee of turnover</i></td>
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="greenhouse_gas_emissions.current.scope1_scope2_emissions_per_rupee.value"
                              value={_get(data, "greenhouse_gas_emissions.current.scope1_scope2_emissions_per_rupee.value", "")}
                              onChange={onHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="greenhouse_gas_emissions.previous.scope1_scope2_emissions_per_rupee.value"
                              value={_get(data, "greenhouse_gas_emissions.previous.scope1_scope2_emissions_per_rupee.value", "")}
                              onChange={onHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left"><b>Total Scope 1 and Scope 2 emission intensity  </b><i>(optional)</i> – the relevant metric may be selected by the entity </td>
                            <TableInput
                              name="greenhouse_gas_emissions.current.scope1_scope2_emission_intensity.unit"
                              value={_get(data, "greenhouse_gas_emissions.current.scope1_scope2_emission_intensity.unit", "")}
                              onChange={onHandleChange} />
                            <TableInput
                              type="number"
                              name="greenhouse_gas_emissions.current.scope1_scope2_emission_intensity.value"
                              value={_get(data, "greenhouse_gas_emissions.current.scope1_scope2_emission_intensity.value", "")}
                              onChange={onHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="greenhouse_gas_emissions.previous.scope1_scope2_emission_intensity.value"
                              value={_get(data, "greenhouse_gas_emissions.previous.scope1_scope2_emission_intensity.value", "")}
                              onChange={onHandleChange} />
                          </tr>
                        </tbody>
                      </table>

                      <p>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N)  <br /> If yes, name of the external agency.</p>
                      <RadioButton id="radio_364button" className="dis-right"
                        name="carried_out_by_an_external_agency_6"
                        value={_get(data, "carried_out_by_an_external_agency_6", "")}
                        onChange={onHandleChange}
                      />
                      <TextArea
                        name="name_6"
                        value={_get(data, "name_6", "")}
                        onChange={onHandleChange}
                      />

                      <Row className="mt-2 align-items-center">
                        {filesData?.greenhouse_gas_emissions?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "greenhouse_gas_emissions" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}

                        {filesData?.greenhouse_gas_emissions?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "greenhouse_gas_emissions" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "greenhouse_gas_emissions" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>

                      <p className="mt-2">7. Does the entity have any project related to reducing Green House Gas emission? If Yes, then provide details.</p>
                      <RadioButton id="radio_365button" className="dis-right"
                        name="project_related_to_reducing_ghg_emission"
                        value={_get(data, "project_related_to_reducing_ghg_emission", "")}
                        onChange={onHandleChange} />
                      <TextArea
                        name="provide_details_7"
                        value={_get(data, "provide_details_7", "")}
                        onChange={onHandleChange}
                      />

                      <p className="mt-2">8. Provide details related to waste management by the entity, in the following format:</p>
                      <table className="table table-bordered mb-0">
                        <thead className="table-light">
                          <tr>
                            <th colSpan={2}>Parameter</th>
                            <th className="text-align">FY (Current Financial Year)</th>
                            <th className="text-align">FY (Previous Financial Year)</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan="5" className="text-center">Total Waste generated (in metric tonnes)</th>
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left">Plastic waste <b>(A)</b></td>
                            <TableInput
                              type="number"
                              name="details_related_to_waste_management.current.waste_generated.plastic"
                              value={_get(data, "details_related_to_waste_management.current.waste_generated.plastic", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_related_to_waste_management.previous.waste_generated.plastic"
                              value={_get(data, "details_related_to_waste_management.previous.waste_generated.plastic", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left">E-waste <b>(B)</b></td>
                            <TableInput
                              type="number"
                              name="details_related_to_waste_management.current.waste_generated.ewaste"
                              value={_get(data, "details_related_to_waste_management.current.waste_generated.ewaste", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_related_to_waste_management.previous.waste_generated.ewaste"
                              value={_get(data, "details_related_to_waste_management.previous.waste_generated.ewaste", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left">Bio-medical waste <b>(C)</b></td>
                            <TableInput
                              type="number"
                              name="details_related_to_waste_management.current.waste_generated.bio_medical"
                              value={_get(data, "details_related_to_waste_management.current.waste_generated.bio_medical", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_related_to_waste_management.previous.waste_generated.bio_medical"
                              value={_get(data, "details_related_to_waste_management.previous.waste_generated.bio_medical", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left">Construction and demolition <b>(C)</b></td>
                            <TableInput
                              type="number"
                              name="details_related_to_waste_management.current.waste_generated.construction_and_demolition"
                              value={_get(data, "details_related_to_waste_management.current.waste_generated.construction_and_demolition", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_related_to_waste_management.previous.waste_generated.construction_and_demolition"
                              value={_get(data, "details_related_to_waste_management.previous.waste_generated.construction_and_demolition", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left">Battery waste  <b>(E)</b></td>
                            <TableInput
                              type="number"
                              name="details_related_to_waste_management.current.waste_generated.battery"
                              value={_get(data, "details_related_to_waste_management.current.waste_generated.battery", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_related_to_waste_management.previous.waste_generated.battery"
                              value={_get(data, "details_related_to_waste_management.previous.waste_generated.battery", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left">Radioactive waste  <b>(F)</b></td>
                            <TableInput
                              type="number"
                              name="details_related_to_waste_management.current.waste_generated.radioactive"
                              value={_get(data, "details_related_to_waste_management.current.waste_generated.radioactive", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_related_to_waste_management.previous.waste_generated.radioactive"
                              value={_get(data, "details_related_to_waste_management.previous.waste_generated.radioactive", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left">Other Hazardous waste. Please specify, if any.  <b>(G)</b></td>
                            <TableInput
                              type="number"
                              name="details_related_to_waste_management.current.waste_generated.other_hazardous"
                              value={_get(data, "details_related_to_waste_management.current.waste_generated.other_hazardous", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_related_to_waste_management.previous.waste_generated.other_hazardous"
                              value={_get(data, "details_related_to_waste_management.previous.waste_generated.other_hazardous", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left">Other Non-hazardous waste generated<b>(H)</b>
                              <i>Please specify, if any.</i>(Break-up by composition i.e. by materials relevant to the sector) </td>
                            <TableInput
                              type="number"
                              name="details_related_to_waste_management.current.waste_generated.other_non_hazardous"
                              value={_get(data, "details_related_to_waste_management.current.waste_generated.other_non_hazardous", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_related_to_waste_management.previous.waste_generated.other_non_hazardous"
                              value={_get(data, "details_related_to_waste_management.previous.waste_generated.other_non_hazardous", "")}
                              onChange={onWasteGenerateHandleChange}
                            />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left"><b>Total (A+B + C + D + E + F + G + H) </b></td>
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_related_to_waste_management.current.waste_generated.total"
                              value={_get(data, "details_related_to_waste_management.current.waste_generated.total", "")}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_related_to_waste_management.previous.waste_generated.total"
                              value={_get(data, "details_related_to_waste_management.previous.waste_generated.total", "")}
                            />
                          </tr>
                          <tr>
                            <th colSpan="5" className="text-center">
                          For each category of waste generated, total waste recovered through recycling, re-using or other recovery operations (in metric tonnes)
                            </th>
                          </tr>
                          <FieldArray name="details_related_to_waste_management.current.waste_recovered">
                            {({ remove, push }) => (
                              <>
                                <tr>
                                  <td colSpan="4"><b>Category of waste</b></td>
                                  <td onClick={() => {
                                    push({ name: "", recycled: "", reused: "", other_recovery_operations: "", total: "" });
                                    _set(data, "details_related_to_waste_management", {
                                      previous: {
                                        ...data?.details_related_to_waste_management.previous,
                                        waste_recovered: [
                                          ...data.details_related_to_waste_management.previous.waste_recovered,
                                          { name: "", recycled: "", reused: "", other_recovery_operations: "", total: "" }
                                        ]
                                      },
                                      current: {
                                        ...data.details_related_to_waste_management.current,
                                        waste_recovered: [
                                          ...data.details_related_to_waste_management.current.waste_recovered,
                                          { name: "", recycled: "", reused: "", other_recovery_operations: "", total: "" }
                                        ]
                                      }
                                    });
                                    draftSebiData({ ...draftReport, principle_6: data });
                                  }}
                                  className={data?.details_related_to_waste_management?.current?.waste_recovered.length > 7 ? "plusiconfreez" : ""}
                                  ><i className="bx bxs-plus-circle mdi-add-btn" /></td>
                                </tr>
                                {data?.details_related_to_waste_management?.current?.waste_recovered?.length > 0 &&
                                 data?.details_related_to_waste_management?.current?.waste_recovered?.map((friend, index) => (
                                   <React.Fragment key={index}>
                                     <tr>
                                       <td colSpan={2}>Name of category</td>
                                       <td className="text-align">
                                         <Field
                                           as="select" className="form-select"
                                           name={`details_related_to_waste_management.current.waste_recovered[${index}].name`}
                                           value={data.details_related_to_waste_management?.current?.waste_recovered[index]?.name}
                                         >
                                           <option value="" disabled>Select Option</option>
                                           {data.details_related_to_waste_management?.current?.waste_recovered[index]?.name &&
                                            <option value={data.details_related_to_waste_management?.current?.waste_recovered[index]?.name}>
                                              {data.details_related_to_waste_management?.current?.waste_recovered[index]?.name}
                                            </option>}
                                           {!data.details_related_to_waste_management?.previous?.waste_recovered[index]?.name &&
                                            recoverdCategories.map(item => <option key={`${index}_${item}`} value={item}>{item}</option>)}
                                         </Field>
                                       </td>
                                       <TableInput
                                         isDisabled={true}
                                         type="text"
                                         name={`details_related_to_waste_management.previous.waste_recovered[${index}].name`}
                                         value={data.details_related_to_waste_management?.previous?.waste_recovered[index]?.name}
                                       />
                                       {data?.details_related_to_waste_management?.current?.waste_recovered?.length > 1 && <td onClick={() => {
                                         remove(index);
                                         const newCurrent = data.details_related_to_waste_management?.current?.waste_recovered.filter((item, i) => index !==i);
                                         const newPrevious = data.details_related_to_waste_management?.previous?.waste_recovered.filter((item, i) => index !==i);
                                         if (data.details_related_to_waste_management?.current?.waste_recovered[index]?.name) {
                                           setRecoverdCategories([...recoverdCategories, data.details_related_to_waste_management?.current?.waste_recovered[index]?.name]);
                                         }
                                         _set(data, "details_related_to_waste_management", {
                                           previous: {
                                             ...data?.details_related_to_waste_management.previous,
                                             waste_recovered: newPrevious
                                           },
                                           current: {
                                             ...data.details_related_to_waste_management.current,
                                             waste_recovered: newCurrent
                                           }
                                         });
                                         draftSebiData({ ...draftReport, principle_6: data });
                                       }}><i className="bx bxs-x-circle mdi-close-btn" /></td>}
                                     </tr>
                                     <tr>
                                       <td colSpan={2} className="text-align-left">(i) Recycled</td>
                                       <TableInput
                                         type="number"
                                         name={`details_related_to_waste_management.current.waste_recovered[${index}].recycled`}
                                         value={data.details_related_to_waste_management?.current?.waste_recovered[index]?.recycled}
                                         onChange={(e)=> onWasteRecoverHandleChange(e, index)}
                                       />
                                       <TableInput
                                         type="number"
                                         isDisabled={true}
                                         name={`details_related_to_waste_management.previous.waste_recovered[${index}].recycled`}
                                         value={data.details_related_to_waste_management?.previous?.waste_recovered[index]?.recycled}
                                         onChange={onWasteRecoverHandleChange}
                                       />
                                     </tr>
                                     <tr>
                                       <td colSpan={2} className="text-align-left">(ii) Re-used</td>
                                       <TableInput
                                         type="number"
                                         name={`details_related_to_waste_management.current.waste_recovered[${index}].reused`}
                                         value={data.details_related_to_waste_management?.current?.waste_recovered[index]?.reused}
                                         onChange={(e)=> onWasteRecoverHandleChange(e, index)}
                                       />
                                       <TableInput
                                         type="number"
                                         isDisabled={true}
                                         name={`details_related_to_waste_management.previous.waste_recovered[${index}].reused`}
                                         value={data.details_related_to_waste_management?.previous?.waste_recovered[index]?.reused}
                                         onChange={onWasteRecoverHandleChange}
                                       />
                                     </tr>
                                     <tr>
                                       <td colSpan={2} className="text-align-left">(iii) Other recovery operations</td>
                                       <TableInput
                                         type="number"
                                         name={`details_related_to_waste_management.current.waste_recovered[${index}].other_recovery_operations`}
                                         value={data.details_related_to_waste_management?.current?.waste_recovered[index]?.other_recovery_operations}
                                         onChange={(e)=> onWasteRecoverHandleChange(e, index)}
                                       />
                                       <TableInput
                                         type="number"
                                         isDisabled={true}
                                         name={`details_related_to_waste_management.previous.waste_recovered[${index}].other_recovery_operations`}
                                         value={data.details_related_to_waste_management?.previous?.waste_recovered[index]?.other_recovery_operations}
                                         onChange={onWasteRecoverHandleChange}
                                       />
                                     </tr>
                                     <tr>
                                       <th colSpan={2} className="text-align-left">Total</th>
                                       <TableInput
                                         type="number"
                                         isDisabled={true}
                                         name={`details_related_to_waste_management.current.waste_recovered[${index}].total`}
                                         value={data.details_related_to_waste_management?.current?.waste_recovered[index]?.total}
                                       />
                                       <TableInput
                                         type="number"
                                         isDisabled={true}
                                         name={`details_related_to_waste_management.previous.waste_recovered[${index}].total`}
                                         value={data.details_related_to_waste_management?.previous?.waste_recovered[index]?.total}
                                       />
                                     </tr>
                                   </React.Fragment>
                                 ))}
                              </>
                            )}
                          </FieldArray>

                          <tr>
                            <th colSpan="5" className="text-center">For each category of waste generated, total waste disposed by nature of disposal method
                          (in metric tonnes) </th>
                          </tr>
                          <FieldArray name="details_related_to_waste_management.current.waste_disposed">
                            {({ remove, push }) => (
                              <>
                                <tr>
                                  <td colSpan="4"><b>Category of waste</b></td>
                                  <td
                                    onClick={() => {
                                      push({ name: "", incineration: "", landfilling: "", other_disposal_operations: "", total: "" });
                                      _set(data, "details_related_to_waste_management", {
                                        previous: {
                                          ...data?.details_related_to_waste_management.previous,
                                          waste_disposed: [
                                            ...data.details_related_to_waste_management.previous.waste_disposed,
                                            { name: "", incineration: "", landfilling: "", other_disposal_operations: "", total: "" }
                                          ]
                                        },
                                        current: {
                                          ...data.details_related_to_waste_management.current,
                                          waste_disposed: [
                                            ...data.details_related_to_waste_management.current.waste_disposed,
                                            { name: "", incineration: "", landfilling: "", other_disposal_operations: "", total: "" }
                                          ]
                                        }
                                      });
                                      draftSebiData({ ...draftReport, principle_6: data });
                                    }}
                                    className={data?.details_related_to_waste_management?.current?.waste_disposed.length > 7 ? "plusiconfreez" : ""}
                                  ><i className="bx bxs-plus-circle mdi-add-btn" />
                                  </td>
                                </tr>
                                {data?.details_related_to_waste_management.current.waste_disposed?.length > 0 && data?.
                                  details_related_to_waste_management.current.waste_disposed?.map((friend, index) => (
                                    <React.Fragment key={index}>
                                      <tr>
                                        <td colSpan={2}>Name of category</td>
                                        <td className="text-align">
                                          <Field
                                            as="select" className="form-select"
                                            name={`details_related_to_waste_management.current.waste_disposed[${index}].name`}
                                            value={data.details_related_to_waste_management?.current?.waste_disposed[index]?.name}
                                          >
                                            <option value="" disabled>Select Option</option>
                                            {data.details_related_to_waste_management?.current?.waste_disposed[index]?.name &&
                                            <option value={data.details_related_to_waste_management?.current?.waste_disposed[index]?.name}>
                                              {data.details_related_to_waste_management?.current?.waste_disposed[index]?.name}
                                            </option>}
                                            {!data.details_related_to_waste_management?.previous?.waste_disposed[index]?.name &&
                                            disposedCategories.map(item => <option key={`${index}_${item}`} value={item}>{item}</option>)}
                                          </Field>
                                        </td>
                                        <TableInput
                                          isDisabled={true}
                                          type="text"
                                          name={`details_related_to_waste_management.previous.waste_disposed[${index}].name`}
                                          value={data.details_related_to_waste_management?.previous?.waste_disposed[index]?.name}
                                          onChange={onWasteDisposedHandleChange}
                                        />
                                        {data?.details_related_to_waste_management?.current?.waste_disposed?.length > 1 && <td onClick={() => {
                                          remove(index);
                                          const newCurrent = data.details_related_to_waste_management?.current?.waste_disposed.filter((item, i) => index !==i);
                                          const newPrevious = data.details_related_to_waste_management?.previous?.waste_disposed.filter((item, i) => index !==i);
                                          if (data.details_related_to_waste_management?.current?.waste_disposed[index]?.name) {
                                            setDisposedCategories([...disposedCategories, data.details_related_to_waste_management?.current?.waste_disposed[index]?.name]);
                                          }
                                          _set(data, "details_related_to_waste_management", {
                                            previous: {
                                              ...data?.details_related_to_waste_management.previous,
                                              waste_disposed: newPrevious
                                            },
                                            current: {
                                              ...data.details_related_to_waste_management.current,
                                              waste_disposed: newCurrent
                                            }
                                          });
                                          draftSebiData({ ...draftReport, principle_6: data });
                                        }}><i className="bx bxs-x-circle mdi-close-btn" /></td>}
                                      </tr>
                                      <tr>
                                        <td colSpan={2} className="text-align-left">(i) Incineration</td>
                                        <TableInput
                                          type="number"
                                          name={`details_related_to_waste_management.current.waste_disposed[${index}].incineration`}
                                          value={data.details_related_to_waste_management?.current?.waste_disposed[index]?.incineration}
                                          onChange={(e)=> onWasteDisposedHandleChange(e, index)}
                                        />
                                        <TableInput
                                          type="number"
                                          isDisabled={true}
                                          name={`details_related_to_waste_management.previous.waste_disposed[${index}].incineration`}
                                          value={_get(data, `details_related_to_waste_management.previous.waste_disposed[${index}].incineration`, "")}
                                          onChange={onWasteDisposedHandleChange}
                                        />
                                      </tr>
                                      <tr>
                                        <td colSpan={2} className="text-align-left">(ii) Landfilling</td>
                                        <TableInput
                                          type="number"
                                          name={`details_related_to_waste_management.current.waste_disposed[${index}].landfilling`}
                                          value={data.details_related_to_waste_management?.current?.waste_disposed[index]?.landfilling}
                                          onChange={(e)=> onWasteDisposedHandleChange(e, index)}
                                        />
                                        <TableInput
                                          type="number"
                                          isDisabled={true}
                                          name={`details_related_to_waste_management.previous.waste_disposed[${index}].landfilling`}
                                          value={_get(data, `details_related_to_waste_management.previous.waste_disposed[${index}].landfilling`, "")}
                                          onChange={onWasteDisposedHandleChange}
                                        />
                                      </tr>
                                      <tr>
                                        <td colSpan={2} className="text-align-left">(iii) Other disposal operations</td>
                                        <TableInput
                                          type="number"
                                          name={`details_related_to_waste_management.current.waste_disposed[${index}].other_disposal_operations`}
                                          value={data.details_related_to_waste_management?.current?.waste_disposed[index]?.other_disposal_operations}
                                          onChange={(e)=> onWasteDisposedHandleChange(e, index)}
                                        />
                                        <TableInput
                                          type="number"
                                          isDisabled={true}
                                          name={`details_related_to_waste_management.previous.waste_disposed[${index}].other_disposal_operations`}
                                          value={_get(data, `details_related_to_waste_management.previous.waste_disposed[${index}].other_disposal_operations`, "")}
                                          onChange={onWasteDisposedHandleChange}
                                        />
                                      </tr>
                                      <tr>
                                        <th colSpan={2} className="text-align-left">Total</th>
                                        <TableInput
                                          type="number"
                                          isDisabled={true}
                                          name={`details_related_to_waste_management.current.waste_disposed[${index}].total`}
                                          value={data.details_related_to_waste_management?.current?.waste_disposed[index]?.total}
                                        />
                                        <TableInput
                                          type="number"
                                          isDisabled={true}
                                          name={`details_related_to_waste_management.previous.waste_disposed[${index}].total`}
                                          value={data.details_related_to_waste_management?.previous?.waste_disposed[index]?.total}
                                        />
                                      </tr>
                                    </React.Fragment>
                                  ))}
                              </>
                            )}
                          </FieldArray>
                        </tbody>
                      </table>

                      <p>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N)  <br /> If yes, name of the external agency.</p>
                      <RadioButton id="radio_366button" className="dis-right"
                        name="carried_out_by_an_external_agency_8"
                        value={_get(data, "carried_out_by_an_external_agency_8", "")}
                        onChange={onHandleChange}
                      />
                      <TextArea
                        name="name_8"
                        value={_get(data, "name_8", "")}
                        onChange={onHandleChange}
                      />

                      <Row className="mt-2 align-items-center">
                        {filesData?.waste_management?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "waste_management" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}

                        {filesData?.waste_management?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "waste_management" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "waste_management" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>

                      <p className="mt-2">9. Briefly describe the waste management practices adopted in your establishments. Describe the strategy
                      adopted by your company to reduce usage of hazardous and toxic chemicals in your products and processes and the practices adopted to manage such wastes.</p>
                      <TextArea
                        name="reduce_usage_of_hazardous_and_toxic_chemicals"
                        value={_get(data, "reduce_usage_of_hazardous_and_toxic_chemicals", "")}
                        onChange={onHandleChange}
                      />

                      <p className="mt-2">10. If the entity has operations/offices in/around ecologically sensitive areas (such as national parks,
                    wildlife sanctuaries, biosphere reserves, wetlands, biodiversity hotspots, forests, coastal regulation zones etc.) where
                    environmental approvals/ clearances are required, please specify details in the following format:</p>
                      <table className="table table-bordered mb-0">
                        <FieldArray name="specify_details_10">
                          {({ remove, push }) => (
                            <>
                              <thead className="table-light">
                                <tr>
                                  <th width="4%" className="text-align">Sr. No.</th>
                                  <th className="text-align">Location of operations/offices</th>
                                  <th className="text-align">Type	of operations</th>
                                  <th className="text-align">Whether the conditions of environmental approval / clearance are being complied with? (Y/N)
                                    <br /> If no, the reasons thereof and corrective action taken, if any.</th>
                                  <th width="3%" onClick={() => {
                                    push({ location: "", type_of_operations: "", conditions_of_environmental_approval: "", action: "" });
                                    setData({
                                      ...data, specify_details_10: [
                                        ...data.specify_details_10,
                                        { location: "", type_of_operations: "", conditions_of_environmental_approval: "", action: "" }
                                      ]
                                    });
                                    draftSebiData({
                                      ...draftReport, principle_6: {
                                        ...data, specify_details_10: [
                                          ...data.specify_details_10,
                                          { location: "", type_of_operations: "", conditions_of_environmental_approval: "", action: "" }
                                        ]
                                      }
                                    });
                                  }}><i className="bx bxs-plus-circle mdi-add-btn" /></th>
                                </tr>
                              </thead>
                              <tbody>
                                {values?.specify_details_10?.length > 0 && values?.specify_details_10?.map((friend, index) => (
                                  <tr key={index}>
                                    <td className="text-align">{index + 1}.</td>
                                    <td className="text-align">
                                      <Field
                                        as="select" className="form-select"
                                        name={`specify_details_10[${index}].location`}
                                        value={values?.specify_details_10[index].indicate_whether}
                                      >
                                        <option value="">Select option</option>
                                        <option value="National parks">National parks</option>
                                        <option value="Wildlife sanctuaries">Wildlife sanctuaries</option>
                                        <option value="Biosphere reserves">Biosphere reserves</option>
                                        <option value="Wetlands">Wetlands</option>
                                        <option value="Biodiversity hotspots">Biodiversity hotspots</option>
                                        <option value="Forests">Forests</option>
                                        <option value="Coastal regulation zones">Coastal regulation zones</option>
                                      </Field>
                                    </td>
                                    <td className="text-align">
                                      <Field
                                        as="textarea"
                                        name={`specify_details_10[${index}].type_of_operations`}
                                        placeholder="Type of operations"
                                        className="form-control"
                                        value={data.specify_details_10[index].type_of_operations}
                                        onFocus={(e) => {
                                          if(!modal_center){ e.target.blur(); }
                                          setModalData({
                                            title: "Type of operations",
                                            name: `specify_details_10[${index}].type_of_operations`,
                                            value: _get(data, `specify_details_10[${index}].type_of_operations`, "")
                                          });
                                          tog_center();
                                        }}
                                      />
                                    </td>
                                    <td className="text-align" role="group" aria-labelledby="my-radio-group" style={{ display: "flex" }}>
                                      <div style={{ marginRight: "10px" }}>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            value="Yes"
                                            name={`specify_details_10[${index}].conditions_of_environmental_approval`}
                                            id={`radioBtn371${index}`}
                                            onClick={(e) => {
                                              onHandleChange(e);
                                              setFieldValue(`specify_details_10[${index}].conditions_of_environmental_approval`, e.target.value);
                                            }}
                                            onChange={() => {}}
                                            checked={values?.specify_details_10[index].conditions_of_environmental_approval === "Yes"}
                                          />
                                          <label className="form-check-label" htmlFor={`radioBtn371${index}`}>Yes</label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            value="No"
                                            name={`specify_details_10[${index}].conditions_of_environmental_approval`}
                                            id={`radioBtn372${index}`}
                                            onClick={(e) =>{onHandleChange(e);
                                              setFieldValue(`specify_details_10[${index}].conditions_of_environmental_approval`, e.target.value);
                                            }}
                                            onChange={() => {}}
                                            checked={values?.specify_details_10[index].conditions_of_environmental_approval === "No"}
                                          />
                                          <label className="form-check-label" htmlFor={`radioBtn372${index}`}>No</label>
                                        </div>
                                      </div>
                                      <Field
                                        as="textarea"
                                        name={`specify_details_10[${index}].action`}
                                        placeholder="Action taken"
                                        className="form-control"
                                        value={data.specify_details_10[index].action}
                                        onFocus={(e) => {
                                          if(!modal_center){ e.target.blur(); }
                                          setModalData({
                                            title: " the reasons thereof and corrective action taken",
                                            name: `specify_details_10[${index}].action`,
                                            value: _get(data, `specify_details_10[${index}].action`, "")
                                          });
                                          tog_center();
                                        }}
                                      />
                                    </td>
                                    {values?.specify_details_10?.length > 1 && <td onClick={() => {
                                      remove(index);
                                      const newr = data.specify_details_10.filter((item, i) => index !==i);
                                      draftSebiData({ ...draftReport, principle_6: { ...data, specify_details_10: newr } });
                                      setData({ ...data, specify_details_10: newr });
                                    }}><i className="bx bxs-x-circle mdi-close-btn" /></td>}
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </FieldArray>
                      </table>

                      <Row className="mt-2 align-items-center">
                        {filesData?.ecologically_sensitive_areas?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "ecologically_sensitive_areas" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}

                        {filesData?.ecologically_sensitive_areas?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "ecologically_sensitive_areas" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "ecologically_sensitive_areas" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>

                      <p className="mt-2">11. Details of environmental impact assessments of projects undertaken by the entity based on applicable laws, in current financial year:</p>
                      <table className="table table-bordered mb-0">
                        <FieldArray name="projects_based_on_applicable_laws">
                          {({ remove, push }) => (
                            <>
                              <thead className="table-light">
                                <tr>
                                  <th className="text-align">Name and brief details of project</th>
                                  <th className="text-align">EIA Notification No. </th>
                                  <th className="text-align" width="8%">Date</th>
                                  <th className="text-align" width="15%">Whether conducted by independent external agency</th>
                                  <th className="text-align" width="13%">Results communicated in public domain</th>
                                  <th className="text-align">Relevant Web link</th>
                                  <th width="3%" onClick={() => {
                                    push({ law_regulation: "", noncompliance: "", fines_penalties: "", action: "" });
                                    setData({
                                      ...data, projects_based_on_applicable_laws: [
                                        ...data.projects_based_on_applicable_laws,
                                        { law_regulation: "", noncompliance: "", fines_penalties: "", action: "" }
                                      ]
                                    });
                                    draftSebiData({
                                      ...draftReport, principle_6: {
                                        ...data, projects_based_on_applicable_laws: [
                                          ...data.projects_based_on_applicable_laws,
                                          { law_regulation: "", noncompliance: "", fines_penalties: "", action: "" }
                                        ]
                                      }
                                    });
                                  }}><i className="bx bxs-plus-circle mdi-add-btn" /></th>
                                </tr>
                              </thead>
                              <tbody>
                                {values?.projects_based_on_applicable_laws?.length > 0 && values?.projects_based_on_applicable_laws?.map((friend, index) => (
                                  <tr key={index}>
                                    <td>
                                      <Field
                                        as="textarea"
                                        name={`projects_based_on_applicable_laws[${index}].name`}
                                        placeholder="Name"
                                        className="form-control"
                                        value={data.projects_based_on_applicable_laws[index].name}
                                        onFocus={(e) => {
                                          if(!modal_center){ e.target.blur(); }
                                          setModalData({
                                            title: "Name and brief details of project",
                                            name: `projects_based_on_applicable_laws[${index}].name`,
                                            value: _get(data, `projects_based_on_applicable_laws[${index}].name`, "")
                                          });
                                          tog_center();
                                        }}
                                      />
                                    </td>
                                    <td className="text-align">
                                      <Field
                                        className="form-control"
                                        name={`projects_based_on_applicable_laws[${index}].eia`}
                                        placeholder="EIA"
                                        type="text"
                                      />
                                    </td>
                                    <td className="text-align">
                                      <Field
                                        className="form-control"
                                        name={`projects_based_on_applicable_laws[${index}].date`}
                                        type="date"
                                      />
                                    </td>
                                    <td className="text-align" role="group" aria-labelledby="my-radio-group">
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          value="Yes"
                                          name={`projects_based_on_applicable_laws[${index}].external_agency`}
                                          id={`radioBtn373${index}`}
                                          onClick={(e) => {onHandleChange(e);
                                            setFieldValue(`projects_based_on_applicable_laws[${index}].external_agency`, e.target.value);
                                          }}
                                          onChange={() => {}}
                                          checked={values?.projects_based_on_applicable_laws[index].external_agency === "Yes"}
                                        />
                                        <label className="form-check-label" htmlFor={`radioBtn373${index}`}>Yes</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          value="No"
                                          name={`projects_based_on_applicable_laws[${index}].external_agency`}
                                          id={`radioBtn374${index}`}
                                          onClick={(e) => {
                                            onHandleChange(e);
                                            setFieldValue(`projects_based_on_applicable_laws[${index}].external_agency`, e.target.value);
                                          }}
                                          onChange={() => {}}
                                          checked={values?.projects_based_on_applicable_laws[index].external_agency === "No"}
                                        />
                                        <label className="form-check-label" htmlFor={`radioBtn374${index}`}>No</label>
                                      </div>
                                    </td>
                                    <td className="text-align" role="group" aria-labelledby="my-radio-group">
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          value="Yes"
                                          name={`projects_based_on_applicable_laws[${index}].results`}
                                          id={`radioBtn375${index}`}
                                          onClick={(e) => {
                                            onHandleChange(e);
                                            setFieldValue(`projects_based_on_applicable_laws[${index}].results`, e.target.value);
                                          }}
                                          onChange={() => {}}
                                          checked={values?.projects_based_on_applicable_laws[index].results === "Yes"}
                                        />
                                        <label className="form-check-label" htmlFor={`radioBtn375${index}`}>Yes</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          value="No"
                                          name={`projects_based_on_applicable_laws[${index}].results`}
                                          id={`radioBtn376${index}`}
                                          onClick={(e) => {
                                            onHandleChange(e);
                                            setFieldValue(`projects_based_on_applicable_laws[${index}].results`, e.target.value);
                                          }}
                                          onChange={() => {}}
                                          checked={values?.projects_based_on_applicable_laws[index].results === "No"}
                                        />
                                        <label className="form-check-label" htmlFor={`radioBtn376${index}`}>No</label>
                                      </div>
                                    </td>
                                    <td className="text-align">
                                      <Field
                                        className="form-control"
                                        name={`projects_based_on_applicable_laws[${index}].weblink`}
                                        placeholder="weblink"
                                        type="text"
                                      />
                                    </td>
                                    {values?.projects_based_on_applicable_laws?.length > 1 && <td onClick={() => {
                                      remove(index);
                                      const newr = data.projects_based_on_applicable_laws.filter((item, i) => index !==i);
                                      draftSebiData({ ...draftReport, principle_6: { ...data, projects_based_on_applicable_laws: newr } });
                                      setData({ ...data, projects_based_on_applicable_laws: newr });
                                    }}><i className="bx bxs-x-circle mdi-close-btn" /></td>}
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </FieldArray>
                      </table>

                      <Row className="mt-2 align-items-center">
                        {filesData?.projects_undertaken?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "projects_undertaken" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}

                        {filesData?.projects_undertaken?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "projects_undertaken" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "projects_undertaken" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>

                      <p className="mt-2">12. Is the entity compliant with the applicable environmental law/ regulations/ guidelines in India; such as the
                    Water(Prevention and Control of Pollution) Act, Air (Prevention and Control of Pollution) Act, Environment protection act and
                    rules thereunder (Y/N). If not, provide details of all such non-compliances, in the following format:</p>
                      <RadioButton />
                      <table className="table table-bordered mb-0 mt-1">
                        <FieldArray name="provide_details_12">
                          {({ remove, push }) => (
                            <>
                              <thead className="table-light">
                                <tr>
                                  <th width="4%" className="text-align">Sr. No.</th>
                                  <th className="text-align">Specify the law / regulation / guidelines which was not complied with</th>
                                  <th className="text-align">Provide details of the non- compliance</th>
                                  <th className="text-align">Any fines / penalties / action taken by regulatory agencies such as pollution control boards or by courts </th>
                                  <th className="text-align">Corrective action taken, if any</th>
                                  <th width="3%" onClick={() => {
                                    push({ name: "", eia: "", date: "", external_agency: "", results: "", weblink: "" });
                                    setData({
                                      ...data, provide_details_12: [
                                        ...data.provide_details_12,
                                        { name: "", eia: "", date: "", external_agency: "", results: "", weblink: "" }
                                      ]
                                    });
                                    draftSebiData({
                                      ...draftReport, principle_6: {
                                        ...data, provide_details_12: [
                                          ...data.provide_details_12,
                                          { name: "", eia: "", date: "", external_agency: "", results: "", weblink: "" }
                                        ]
                                      }
                                    });
                                  }}><i className="bx bxs-plus-circle mdi-add-btn" /></th>
                                </tr>
                              </thead>
                              <tbody>
                                {values?.provide_details_12?.length > 0 && values?.provide_details_12?.map((friend, index) => (
                                  <tr key={index}>
                                    <td className="text-align">{index + 1}.</td>
                                    <td>
                                      <Field
                                        as="textarea"
                                        name={`provide_details_12[${index}].law_regulation`}
                                        placeholder="Specify the law"
                                        className="form-control"
                                        value={data.provide_details_12[index].law_regulation}
                                        onFocus={(e) => {
                                          if(!modal_center){ e.target.blur(); }
                                          setModalData({
                                            title: "Specify the law / regulation / guidelines which was not complied with",
                                            name: `provide_details_12[${index}].law_regulation`,
                                            value: _get(data, `provide_details_12[${index}].law_regulation`, "")
                                          });
                                          tog_center();
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        as="textarea"
                                        name={`provide_details_12[${index}].noncompliance`}
                                        placeholder="Details of the non-compliance"
                                        className="form-control"
                                        value={data.provide_details_12[index].noncompliance}
                                        onFocus={(e) => {
                                          if(!modal_center){ e.target.blur(); }
                                          setModalData({
                                            title: "Provide details of the non- compliance",
                                            name: `provide_details_12[${index}].noncompliance`,
                                            value: _get(data, `provide_details_12[${index}].noncompliance`, "")
                                          });
                                          tog_center();
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        as="textarea"
                                        name={`provide_details_12[${index}].fines_penalties`}
                                        placeholder="Any fines / penalties / action taken "
                                        className="form-control"
                                        value={data.provide_details_12[index].fines_penalties}
                                        onFocus={(e) => {
                                          if(!modal_center){ e.target.blur(); }
                                          setModalData({
                                            title: "Any fines / penalties / action taken by regulatory agencies such as pollution control boards or by courts",
                                            name: `provide_details_12[${index}].fines_penalties`,
                                            value: _get(data, `provide_details_12[${index}].fines_penalties`, "")
                                          });
                                          tog_center();
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        as="textarea"
                                        name={`provide_details_12[${index}].action`}
                                        placeholder="Action taken"
                                        className="form-control"
                                        value={data.provide_details_12[index].action}
                                        onFocus={(e) => {
                                          if(!modal_center){ e.target.blur(); }
                                          setModalData({
                                            title: "Corrective action taken, if any",
                                            name: `provide_details_12[${index}].action`,
                                            value: _get(data, `provide_details_12[${index}].action`, "")
                                          });
                                          tog_center();
                                        }}
                                      />
                                    </td>
                                    {values?.provide_details_12?.length > 1 && <td onClick={() => {
                                      remove(index);
                                      const newr = data.provide_details_12.filter((item, i) => index !==i);
                                      draftSebiData({ ...draftReport, principle_6: { ...data, provide_details_12: newr } });
                                      setData({ ...data, provide_details_12: newr });
                                    }}><i className="bx bxs-x-circle mdi-close-btn" /></td>}
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </FieldArray>
                      </table>

                      <Row className="mt-2 align-items-center">
                        {filesData?.environmental_law?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "environmental_law" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}

                        {filesData?.environmental_law?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "environmental_law" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "environmental_law" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>

                      <p className="txt-decoration mt-2"><b>Leadership Indicators</b></p>
                      <p>1. Provide break-up of the total energy consumed (in Joules or multiples) from renewable and non-renewable sources, in the following format:</p>
                      <table className="table table-bordered mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>Parameter</th>
                            <th className="text-align">FY (Current Financial Year) </th>
                            <th className="text-align">FY (Previous Financial Year) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan="3">From renewable sources</th>
                          </tr>
                          <tr>
                            <td className="text-align-left">Total electricity consumption (A) </td>
                            <TableInput
                              type="number"
                              name="renewable_and_nonrenewable_sources.current.renewable.electricity"
                              value={_get(data, "renewable_and_nonrenewable_sources.current.renewable.electricity", "")}
                              onChange={onRenewableHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="renewable_and_nonrenewable_sources.previous.renewable.electricity"
                              value={_get(data, "renewable_and_nonrenewable_sources.previous.renewable.electricity", "")}
                              onChange={onRenewableHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">Total fuel consumption (B) </td>
                            <TableInput
                              type="number"
                              name="renewable_and_nonrenewable_sources.current.renewable.fuel"
                              value={_get(data, "renewable_and_nonrenewable_sources.current.renewable.fuel", "")}
                              onChange={onRenewableHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="renewable_and_nonrenewable_sources.previous.renewable.fuel"
                              value={_get(data, "renewable_and_nonrenewable_sources.previous.renewable.fuel", "")}
                              onChange={onRenewableHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">Energy consumption through other sources (C) </td>
                            <TableInput
                              type="number"
                              name="renewable_and_nonrenewable_sources.current.renewable.energy"
                              value={_get(data, "renewable_and_nonrenewable_sources.current.renewable.energy", "")}
                              onChange={onRenewableHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="renewable_and_nonrenewable_sources.previous.renewable.energy"
                              value={_get(data, "renewable_and_nonrenewable_sources.previous.renewable.energy", "")}
                              onChange={onRenewableHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left"><b>Total energy consumed from renewable sources (A+B+C) </b> </td>
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="renewable_and_nonrenewable_sources.current.renewable.total_energy"
                              value={_get(data, "renewable_and_nonrenewable_sources.current.renewable.total_energy", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="renewable_and_nonrenewable_sources.previous.renewable.total_energy"
                              value={_get(data, "renewable_and_nonrenewable_sources.previous.renewable.total_energy", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                          <tr>
                            <th colSpan="3">From Non-renewable sources</th>
                          </tr>
                          <tr>
                            <td className="text-align-left">Total electricity consumption (D) </td>
                            <TableInput
                              type="number"
                              name="renewable_and_nonrenewable_sources.current.nonrenewable.electricity"
                              value={_get(data, "renewable_and_nonrenewable_sources.current.nonrenewable.electricity", "")}
                              onChange={onNonRenewableHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="renewable_and_nonrenewable_sources.previous.nonrenewable.electricity"
                              value={_get(data, "renewable_and_nonrenewable_sources.previous.nonrenewable.electricity", "")}
                              onChange={onNonRenewableHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">Total fuel consumption (E) </td>
                            <TableInput
                              type="number"
                              name="renewable_and_nonrenewable_sources.current.nonrenewable.fuel"
                              value={_get(data, "renewable_and_nonrenewable_sources.current.nonrenewable.fuel", "")}
                              onChange={onNonRenewableHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="renewable_and_nonrenewable_sources.previous.nonrenewable.fuel"
                              value={_get(data, "renewable_and_nonrenewable_sources.previous.nonrenewable.fuel", "")}
                              onChange={onNonRenewableHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left">Energy consumption through other sources (F) </td>
                            <TableInput
                              type="number"
                              name="renewable_and_nonrenewable_sources.current.nonrenewable.energy"
                              value={_get(data, "renewable_and_nonrenewable_sources.current.nonrenewable.energy", "")}
                              onChange={onNonRenewableHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="renewable_and_nonrenewable_sources.previous.nonrenewable.energy"
                              value={_get(data, "renewable_and_nonrenewable_sources.previous.nonrenewable.energy", "")}
                              onChange={onNonRenewableHandleChange}
                            />
                          </tr>
                          <tr>
                            <td className="text-align-left"><b>Total energy consumed from non-renewable sources (D+E+F) </b></td>
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="renewable_and_nonrenewable_sources.current.nonrenewable.total_energy_consumed"
                              value={_get(data, "renewable_and_nonrenewable_sources.current.nonrenewable.total_energy_consumed", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="renewable_and_nonrenewable_sources.previous.nonrenewable.total_energy_consumed"
                              value={_get(data, "renewable_and_nonrenewable_sources.previous.nonrenewable.total_energy_consumed", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                        </tbody>
                      </table>

                      <p>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N)  <br /> If yes, name of the external agency.</p>
                      <RadioButton id="radio_367button" className="dis-right"
                        name="carried_out_by_an_external_agency_li1"
                        value={_get(data, "carried_out_by_an_external_agency_li1", "")}
                        onChange={onHandleChange}
                      />
                      <TextArea
                        name="name_li1"
                        value={_get(data, "name_li1", "")}
                        onChange={onHandleChange}
                      />

                      <Row className="mt-2 align-items-center">
                        {filesData?.break_up_of_the_total_energy?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "break_up_of_the_total_energy" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}

                        {filesData?.break_up_of_the_total_energy?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "break_up_of_the_total_energy" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "break_up_of_the_total_energy" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>

                      <p className="mt-2">2. Provide the following details related to water discharged:</p>
                      <table className="table table-bordered mb-0">
                        <thead className="table-light">
                          <tr>
                            <th colSpan={2}>Parameter</th>
                            <th className="text-align">FY (Current Financial Year)</th>
                            <th className="text-align">FY (Previous  Financial Year)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan="2">Water discharge by destination and level of treatment (in kilolitres)</th>
                          </tr>
                          <tr>
                            <td className="text-align-left" rowSpan={2}>(i) To Surface water</td>
                            <td className="text-align-left">- No treatment</td>
                            <TableInput
                              type="number"
                              name="water_discharged.current.surface.no_treatment"
                              value={_get(data, "water_discharged.current.surface.no_treatment", "")}
                              onChange={onWaterDischargedHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="water_discharged.previous.surface.previous.no_treatment"
                              value={_get(data, "water_discharged.previous.surface.previous.no_treatment", "")}
                              onChange={onWaterDischargedHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left">- With treatment – please specify level of treatment </td>
                            <TableInput
                              type="number"
                              name="water_discharged.current.surface.with_treatment"
                              value={_get(data, "water_discharged.current.surface.with_treatment", "")}
                              onChange={onWaterDischargedHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="water_discharged.previous.surface.previous.with_treatment"
                              value={_get(data, "water_discharged.previous.surface.previous.with_treatment", "")}
                              onChange={onWaterDischargedHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left" rowSpan={2}>(ii) To Groundwater</td>
                            <td className="text-align-left">- No treatment</td>
                            <TableInput
                              type="number"
                              name="water_discharged.current.ground.no_treatment"
                              value={_get(data, "water_discharged.current.ground.no_treatment", "")}
                              onChange={onWaterDischargedHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="water_discharged.previous.ground.no_treatment"
                              value={_get(data, "water_discharged.previous.ground.no_treatment", "")}
                              onChange={onWaterDischargedHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left">- With treatment – please specify level of treatment </td>
                            <TableInput
                              type="number"
                              name="water_discharged.current.ground.with_treatment"
                              value={_get(data, "water_discharged.current.ground.with_treatment", "")}
                              onChange={onWaterDischargedHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="water_discharged.previous.ground.with_treatment"
                              value={_get(data, "water_discharged.previous.ground.with_treatment", "")}
                              onChange={onWaterDischargedHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left" rowSpan={2}>(iii) To Seawater</td>
                            <td className="text-align-left">- No treatment</td>
                            <TableInput
                              type="number"
                              name="water_discharged.current.sea.no_treatment"
                              value={_get(data, "water_discharged.current.sea.no_treatment", "")}
                              onChange={onWaterDischargedHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="water_discharged.previous.sea.no_treatment"
                              value={_get(data, "water_discharged.previous.sea.no_treatment", "")}
                              onChange={onWaterDischargedHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left">- With treatment – please specify level of treatment </td>
                            <TableInput
                              type="number"
                              name="water_discharged.current.sea.with_treatment"
                              value={_get(data, "water_discharged.current.sea.with_treatment", "")}
                              onChange={onWaterDischargedHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="water_discharged.previous.sea.with_treatment"
                              value={_get(data, "water_discharged.previous.sea.with_treatment", "")}
                              onChange={onWaterDischargedHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left" rowSpan={2}>(iv) Sent to third-parties</td>
                            <td className="text-align-left">- No treatment</td>
                            <TableInput
                              type="number"
                              name="water_discharged.current.third_parties.no_treatment"
                              value={_get(data, "water_discharged.current.third_parties.no_treatment", "")}
                              onChange={onWaterDischargedHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="water_discharged.previous.third_parties.no_treatment"
                              value={_get(data, "water_discharged.previous.third_parties.no_treatment", "")}
                              onChange={onWaterDischargedHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left">- With treatment – please specify level of treatment </td>
                            <TableInput
                              type="number"
                              name="water_discharged.current.third_parties.with_treatment"
                              value={_get(data, "water_discharged.current.third_parties.with_treatment", "")}
                              onChange={onWaterDischargedHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="water_discharged.previous.third_parties.with_treatment"
                              value={_get(data, "water_discharged.previous.third_parties.with_treatment", "")}
                              onChange={onWaterDischargedHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left" rowSpan={2}>(v) Others</td>
                            <td className="text-align-left">- No treatment</td>
                            <TableInput
                              type="number"
                              name="water_discharged.current.others.no_treatment"
                              value={_get(data, "water_discharged.current.others.no_treatment", "")}
                              onChange={onWaterDischargedHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="water_discharged.previous.others.no_treatment"
                              value={_get(data, "water_discharged.previous.others.no_treatment", "")}
                              onChange={onWaterDischargedHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left">- With treatment – please specify level of treatment </td>
                            <TableInput
                              type="number"
                              name="water_discharged.current.others.with_treatment"
                              value={_get(data, "water_discharged.current.others.with_treatment", "")}
                              onChange={onWaterDischargedHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="water_discharged.previous.others.with_treatment"
                              value={_get(data, "water_discharged.previous.others.with_treatment", "")}
                              onChange={onWaterDischargedHandleChange} />
                          </tr>

                          <tr>
                            <td colSpan={2} className="ml-10"><b>Total water discharged (in kilolitres)</b></td>
                            <TableInput
                              type="number" isDisabled={true}
                              name="water_discharged.current.total"
                              value={_get(data, "water_discharged.current.total", "")}
                            />
                            <TableInput
                              type="number" isDisabled={true}
                              name="water_discharged.previous.total"
                              value={_get(data, "water_discharged.previous.total", "")}
                            />
                          </tr>
                        </tbody>
                      </table>

                      <p>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N)   <br /> If yes, name of the external agency.</p>
                      <RadioButton id="radio_368button" className="dis-right"
                        name="carried_out_by_an_external_agency_li2"
                        value={_get(data, "carried_out_by_an_external_agency_li2", "")}
                        onChange={onHandleChange} />
                      <TextArea
                        name="name_li2"
                        value={_get(data, "name_li2", "")}
                        onChange={onHandleChange} />

                      <Row className="mt-2 align-items-center">
                        {filesData?.water_discharged?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "water_discharged" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}

                        {filesData?.water_discharged?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "water_discharged" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "water_discharged" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>

                      <p className="mt-2">3. Water withdrawal, consumption and discharge in areas of water stress (in kilolitres):</p>
                      <p>For each facility / plant located in areas of water stress, provide the following information:</p>
                      <p className="top-9">(i) Name of the area</p>
                      <TextArea
                        name="name_of_the_area"
                        value={_get(data, "name_of_the_area", "")}
                        onChange={onHandleChange} />
                      <p className="top-9">(ii) Nature of operations</p>
                      <TextArea
                        name="nature_of_operations"
                        value={_get(data, "nature_of_operations", "")}
                        onChange={onHandleChange} />

                      <p className="mt-2">(iii) Water withdrawal, consumption and discharge in the following format:</p>
                      <table className="table table-bordered mb-0">
                        <thead className="table-light">
                          <tr>
                            <th colSpan={2}>Parameter</th>
                            <th className="text-align">FY (Current Financial Year) </th>
                            <th className="text-align">FY (Previous Financial Year) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan="3">Water withdrawal by source (in kilolitres)</th>
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left">(i) Surface water</td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.withdrawal.surface"
                              value={_get(data, "areas_of_water_stress.current.withdrawal.surface", "")}
                              onChange={onWaterWithdrawalHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.withdrawal.surface"
                              value={_get(data, "areas_of_water_stress.previous.withdrawal.surface", "")}
                              onChange={onWaterWithdrawalHandleChange} />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left">(ii) Groundwater</td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.withdrawal.ground"
                              value={_get(data, "areas_of_water_stress.current.withdrawal.ground", "")}
                              onChange={onWaterWithdrawalHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.withdrawal.ground"
                              value={_get(data, "areas_of_water_stress.previous.withdrawal.ground", "")}
                              onChange={onWaterWithdrawalHandleChange} />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left">(iii) Third party water</td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.withdrawal.third_party"
                              value={_get(data, "areas_of_water_stress.current.withdrawal.third_party", "")}
                              onChange={onWaterWithdrawalHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.withdrawal.third_party"
                              value={_get(data, "areas_of_water_stress.previous.withdrawal.third_party", "")}
                              onChange={onWaterWithdrawalHandleChange} />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left">(iv) Seawater / desalinated water</td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.withdrawal.sea"
                              value={_get(data, "areas_of_water_stress.current.withdrawal.sea", "")}
                              onChange={onWaterWithdrawalHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.withdrawal.sea"
                              value={_get(data, "areas_of_water_stress.previous.withdrawal.sea", "")}
                              onChange={onWaterWithdrawalHandleChange} />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left">(v) Others</td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.withdrawal.others"
                              value={_get(data, "areas_of_water_stress.current.withdrawal.others", "")}
                              onChange={onWaterWithdrawalHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.withdrawal.others"
                              value={_get(data, "areas_of_water_stress.previous.withdrawal.others", "")}
                              onChange={onWaterWithdrawalHandleChange} />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left"><b>Total volume of water withdrawal (in kilolitres)</b></td>
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.current.withdrawal.total_withdrawal"
                              value={_get(data, "areas_of_water_stress.current.withdrawal.total_withdrawal", "")}
                              onChange={onHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.withdrawal.total_withdrawal"
                              value={_get(data, "areas_of_water_stress.previous.withdrawal.total_withdrawal", "")}
                              onChange={onHandleChange} />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left"><b>Total volume of water consumption (in kilolitres) </b></td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.withdrawal.total_consumption"
                              value={_get(data, "areas_of_water_stress.current.withdrawal.total_consumption", "")}
                              onChange={onHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.withdrawal.total_consumption"
                              value={_get(data, "areas_of_water_stress.previous.withdrawal.total_consumption", "")}
                              onChange={onHandleChange} />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left"><b>Water intensity per rupee of turnover  </b> <i>(Water consumed / turnover)</i></td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.withdrawal.water_intensity_per_rupee"
                              value={_get(data, "areas_of_water_stress.current.withdrawal.water_intensity_per_rupee", "")}
                              onChange={onHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.withdrawal.water_intensity_per_rupee"
                              value={_get(data, "areas_of_water_stress.previous.withdrawal.water_intensity_per_rupee", "")}
                              onChange={onHandleChange} />
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-align-left"><b>Water intensity per rupee of turnover  </b> <i>(optional)</i>– the relevant metric may be selected by the entity </td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.withdrawal.water_intensity"
                              value={_get(data, "areas_of_water_stress.current.withdrawal.water_intensity", "")}
                              onChange={onHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.withdrawal.water_intensity"
                              value={_get(data, "areas_of_water_stress.previous.withdrawal.water_intensity", "")}
                              onChange={onHandleChange} />
                          </tr>
                          <tr>
                            <th colSpan="3">Water discharge by destination and level of treatment (in kilolitres)</th>
                          </tr>
                          <tr>
                            <td className="text-align-left" rowSpan={2}>(i) To Surface water</td>
                            <td className="text-align-left">- No treatment</td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.discharge.surface.no_treatment"
                              value={_get(data, "areas_of_water_stress.current.discharge.surface.no_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.discharge.surface.no_treatment"
                              value={_get(data, "areas_of_water_stress.previous.discharge.surface.no_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left">- With treatment – please specify level of treatment </td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.discharge.surface.with_treatment"
                              value={_get(data, "areas_of_water_stress.current.discharge.surface.with_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.discharge.surface.with_treatment"
                              value={_get(data, "areas_of_water_stress.previous.discharge.surface.with_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left" rowSpan={2}>(ii) To Groundwater</td>
                            <td className="text-align-left">- No treatment</td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.discharge.ground.no_treatment"
                              value={_get(data, "areas_of_water_stress.current.discharge.ground.no_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.discharge.ground.no_treatment"
                              value={_get(data, "areas_of_water_stress.previous.discharge.ground.no_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left">- With treatment – please specify level of treatment </td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.discharge.ground.with_treatment"
                              value={_get(data, "areas_of_water_stress.current.discharge.ground.with_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.discharge.ground.with_treatment"
                              value={_get(data, "areas_of_water_stress.previous.discharge.ground.with_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left" rowSpan={2}>(iii) To Seawater</td>
                            <td className="text-align-left">- No treatment</td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.discharge.sea.no_treatment"
                              value={_get(data, "areas_of_water_stress.current.discharge.sea.no_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.discharge.sea.no_treatment"
                              value={_get(data, "areas_of_water_stress.previous.discharge.sea.no_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left">- With treatment – please specify level of treatment </td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.discharge.sea.with_treatment"
                              value={_get(data, "areas_of_water_stress.current.discharge.sea.with_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.discharge.sea.with_treatment"
                              value={_get(data, "areas_of_water_stress.previous.discharge.sea.with_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left" rowSpan={2}>(iv) Sent to third-parties</td>
                            <td className="text-align-left">- No treatment</td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.discharge.third_parties.no_treatment"
                              value={_get(data, "areas_of_water_stress.current.discharge.third_parties.no_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.discharge.third_parties.no_treatment"
                              value={_get(data, "areas_of_water_stress.previous.discharge.third_parties.no_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left">- With treatment – please specify level of treatment </td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.discharge.third_parties.with_treatment"
                              value={_get(data, "areas_of_water_stress.current.discharge.third_parties.with_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.discharge.third_parties.with_treatment"
                              value={_get(data, "areas_of_water_stress.previous.discharge.third_parties.with_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left" rowSpan={2}>(v) Others</td>
                            <td className="text-align-left">- No treatment</td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.discharge.others.no_treatment"
                              value={_get(data, "areas_of_water_stress.current.discharge.others.no_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.discharge.others.no_treatment"
                              value={_get(data, "areas_of_water_stress.previous.discharge.others.no_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                          </tr>
                          <tr>
                            <td className="text-align-left">- With treatment – please specify level of treatment </td>
                            <TableInput
                              type="number"
                              name="areas_of_water_stress.current.discharge.others.with_treatment"
                              value={_get(data, "areas_of_water_stress.current.discharge.others.with_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="areas_of_water_stress.previous.discharge.others.with_treatment"
                              value={_get(data, "areas_of_water_stress.previous.discharge.others.with_treatment", "")}
                              onChange={onWaterDischargeHandleChange} />
                          </tr>
                          <tr>
                            <td colSpan={2}><b>Total water discharged (in kilolitres)</b></td>
                            <TableInput
                              type="number" isDisabled={true}
                              name="areas_of_water_stress.current.discharge.total"
                              value={_get(data, "areas_of_water_stress.current.discharge.total", "")}
                            />
                            <TableInput
                              type="number" isDisabled={true}
                              name="areas_of_water_stress.previous.discharge.total"
                              value={_get(data, "areas_of_water_stress.previous.discharge.total", "")}
                            />
                          </tr>
                        </tbody>
                      </table>

                      <p>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N)  <br /> If yes, name of the external agency.</p>
                      <RadioButton id="radio_369button" className="dis-right"
                        name="carried_out_by_an_external_agency_li3"
                        value={_get(data, "carried_out_by_an_external_agency_li3", "")}
                        onChange={onHandleChange}
                      />
                      <TextArea
                        name="name_li3"
                        value={_get(data, "name_li3", "")}
                        onChange={onHandleChange}
                      />

                      <Row className="mt-2 align-items-center">
                        {filesData?.water_withdrawal_consumption?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "water_withdrawal_consumption" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}

                        {filesData?.water_withdrawal_consumption?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "water_withdrawal_consumption" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "water_withdrawal_consumption" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>

                      <p className="mt-2">4. Please provide details of total Scope 3 emissions & its intensity, in the following format:</p>
                      <table className="table table-bordered mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>Parameter</th>
                            <th className="text-align">Unit</th>
                            <th className="text-align">FY (Current Financial Year) </th>
                            <th className="text-align">FY (Previous Financial Year) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><b>Total Scope 3 emissions</b> (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available) </td>
                            <td><i>Metric tonnes of CO2 equivalent </i></td>
                            <TableInput
                              type="number"
                              name="details_of_total_scope3_emissions.current.scope3_emissions"
                              value={_get(data, "details_of_total_scope3_emissions.current.scope3_emissions", "")}
                              onChange={onScopeHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_of_total_scope3_emissions.previous.scope3_emissions"
                              value={_get(data, "details_of_total_scope3_emissions.previous.scope3_emissions", "")}
                            />
                          </tr>
                          <tr>
                            <td><b>Total Scope 3 emissions per rupee of turnover</b></td>
                            <td className="text-align-left"><i>Metric tonnes of CO2 equivalent per rupee of turnover</i></td>
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_of_total_scope3_emissions.current.scope3_emissions_per_rupee.value"
                              value={_get(data, "details_of_total_scope3_emissions.current.scope3_emissions_per_rupee.value", "")}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_of_total_scope3_emissions.previous.scope3_emissions_per_rupee.value"
                              value={_get(data, "details_of_total_scope3_emissions.previous.scope3_emissions_per_rupee.value", "")}
                            />
                          </tr>
                          <tr>
                            <td><b>Total Scope 3 emission intensity </b><i>(optional)</i> – the relevant metric may be selected by the entity </td>
                            <TableInput
                              type="number"
                              name="details_of_total_scope3_emissions.current.scope3_emission_intensity.unit"
                              value={_get(data, "details_of_total_scope3_emissions.current.scope3_emission_intensity.unit", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              name="details_of_total_scope3_emissions.current.scope3_emission_intensity.value"
                              value={_get(data, "details_of_total_scope3_emissions.current.scope3_emission_intensity.value", "")}
                              onChange={onHandleChange}
                            />
                            <TableInput
                              type="number"
                              isDisabled={true}
                              name="details_of_total_scope3_emissions.previous.scope3_emission_intensity.value"
                              value={_get(data, "details_of_total_scope3_emissions.previous.scope3_emission_intensity.value", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                        </tbody>
                      </table>

                      <p>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) <br /> If yes, name of the external agency.</p>
                      <RadioButton id="radio_370button" className="dis-right"
                        name="carried_out_by_an_external_agency_li4"
                        value={_get(data, "carried_out_by_an_external_agency_li4", "")}
                        onChange={onHandleChange}
                      />
                      <TextArea
                        name="name_li4"
                        value={_get(data, "name_li4", "")}
                        onChange={onHandleChange}
                      />

                      <Row className="mt-2 align-items-center">
                        {filesData?.total_scope3_emissions?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "total_scope3_emissions" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}

                        {filesData?.total_scope3_emissions?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "total_scope3_emissions" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "total_scope3_emissions" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>

                      <p className="mt-2">5. With respect to the ecologically sensitive areas reported at Question 10 of Essential Indicators above, provide
                    details of significant direct & indirect impact of the entity on biodiversity in such areas along-with prevention and remediation activities.</p>
                      <TextArea
                        name="areas_along_with_prevention_and_remediation_activities"
                        value={_get(data, "areas_along_with_prevention_and_remediation_activities", "")}
                        onChange={onHandleChange}
                      />

                      <p className="mt-2">6. If the entity has undertaken any specific initiatives or used innovative technology or solutions
                      to improve resource efficiency, or reduce impact due to emissions / effluent discharge / waste generated, please provide
                      details of the same as well as outcome of such initiatives, as per the following format:</p>
                      <table className="table table-bordered mb-0">
                        <FieldArray name="improve_resource_efficiency_or_reduce_impact">
                          {({ remove, push }) => (
                            <>
                              <thead className="table-light">
                                <tr>
                                  <th width="4%" className="text-align">Sr. No</th>
                                  <th className="text-align">Initiative undertaken</th>
                                  <th width="27%" className="text-align">Details of the initiative <i>(Web-link, if any, may be provided along-with summary) </i></th>
                                  <th className="text-align">Outcome of the initiative</th>
                                  <th width="3%" onClick={() => {
                                    push({
                                      initiative_undertaken: "",
                                      details_initiative: "",
                                      outcome_initiative: ""
                                    });
                                    setData({
                                      ...data, improve_resource_efficiency_or_reduce_impact: [
                                        ...data.improve_resource_efficiency_or_reduce_impact,
                                        {
                                          initiative_undertaken: "",
                                          details_initiative: "",
                                          outcome_initiative: ""
                                        }
                                      ]
                                    });
                                  }}><i className="bx bxs-plus-circle mdi-add-btn" /></th>
                                </tr>
                              </thead>
                              <tbody>
                                {values?.improve_resource_efficiency_or_reduce_impact?.length > 0 && values?.improve_resource_efficiency_or_reduce_impact?.map((friend, index) => (
                                  <tr key={index}>
                                    <td className="text-align">{index + 1}.</td>
                                    <td>
                                      <Field
                                        as="textarea"
                                        name={`improve_resource_efficiency_or_reduce_impact[${index}].initiative_undertaken`}
                                        placeholder="Initiative undertaken"
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        as="textarea"
                                        name={`improve_resource_efficiency_or_reduce_impact[${index}].details_initiative`}
                                        placeholder="Details of the initiative"
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        as="textarea"
                                        name={`improve_resource_efficiency_or_reduce_impact[${index}].outcome_initiative`}
                                        placeholder="Outcome of the initiative"
                                        className="form-control"
                                      />
                                    </td>
                                    {values?.improve_resource_efficiency_or_reduce_impact?.length > 1 && <td onClick={() => {
                                      remove(index);
                                      const newr = data.improve_resource_efficiency_or_reduce_impact.filter((item, i) => index !==i);
                                      draftSebiData({ ...draftReport, principle_6: { ...data, improve_resource_efficiency_or_reduce_impact: newr } });
                                      setData({ ...data, improve_resource_efficiency_or_reduce_impact: newr });
                                    }}><i className="bx bxs-x-circle mdi-close-btn" /></td>}
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </FieldArray>
                      </table>

                      <Row className="mt-2 align-items-center">
                        {filesData?.innovative_technology?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "innovative_technology" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}

                        {filesData?.innovative_technology?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "innovative_technology" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "innovative_technology" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>

                      <p className="mt-2">7. Does the entity have a business continuity and disaster management plan? Give details in 100 words/ web link.</p>
                      <RadioButton id="radio_143button" className="dis-right ml-10"
                        name="continuity_and_disaster_management_plan"
                        value={_get(data, "continuity_and_disaster_management_plan", "")}
                        onChange={onHandleChange}
                      />
                      <Input
                        name="continuity_and_disaster_management_plan_weblink"
                        value={_get(data, "continuity_and_disaster_management_plan_weblink", "")}
                        onChange={onHandleChange}
                      />

                      <p className="mt-2">8. Disclose any significant adverse impact to the environment, arising from the value chain of the entity. What
                    mitigation or adaptation measures have been taken by the entity in this regard.</p>
                      <TextArea
                        name="what_mitigation_or_adaptation_measures_have_been_taken"
                        value={_get(data, "what_mitigation_or_adaptation_measures_have_been_taken", "")}
                        onChange={onHandleChange}
                      />

                      <p className="top-9">9. Percentage of value chain partners (by value of business done with such partners) that were assessed for environmental impacts.</p>
                      <div style={{ maxWidth: "300px" }}>
                        <Input
                          name="percentage_of_value_chain_partners"
                          value={_get(data, "percentage_of_value_chain_partners", "")}
                          onChange={onHandleChange}
                        />
                      </div>

                      <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                          <button type="button" className="btn btn-primary w-md "
                            onClick={() => onSectionChange(13)}>Previous</button>{" "}
                        </div>
                        <div>
                          <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                          <button type="button" className="btn btn-primary w-md "
                            onClick={() => onSectionChange(15)}>Next</button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionC?.principle_6,
    previousData: state.NewReportReducer?.sebiForm?.previousYearData?.sectionC?.principle_6,
    prefillData: state.NewReportReducer?.sebiForm?.sebiPrefil,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.principle_6,
    filesData: state.NewReportReducer?.draftFiles?.principle_6
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(Principle6);
