import {
  GET_ADMIN_USERS_FAIL,
  GET_ADMIN_USERS_SUCCESS,
  ADD_ADMIN_USER_SUCCESS,
  ADD_ADMIN_USER_FAIL,
  UPDATE_ADMIN_USER_SUCCESS,
  UPDATE_ADMIN_USER_FAIL,
  DELETE_ADMIN_USER_SUCCESS,
  DELETE_ADMIN_USER_FAIL
} from "./actionTypes";

const INIT_STATE = {
  adminusers: []
};

const AdminUsers = (state = INIT_STATE, action) => {
  switch (action.type) {
  case GET_ADMIN_USERS_SUCCESS:
    return {
      ...state,
      adminusers: action.payload.data
    };

  case GET_ADMIN_USERS_FAIL:
    return {
      ...state,
      error: action.payload
    };

  case ADD_ADMIN_USER_SUCCESS:
    return {
      ...state
      // adminusers: [...state.adminusers, action.payload],
    };

  case ADD_ADMIN_USER_FAIL:
    return {
      ...state,
      error: action.payload
    };

  case UPDATE_ADMIN_USER_SUCCESS:
    return {
      ...state
      // adminusers: state.adminusers.map(user =>
      //   user_.id.toString() === action.payload_.id.toString()
      //     ? { user, ...action.payload }
      //     : user
      // ),
    };

  case UPDATE_ADMIN_USER_FAIL:
    return {
      ...state,
      error: action.payload
    };

  case DELETE_ADMIN_USER_SUCCESS:
    return {
      ...state
      // adminusers: action.payload
      // adminusers: state.adminusers.filter(
      //   user => user._id.toString() !== action.payload._id.toString()
      // ),
    };

  case DELETE_ADMIN_USER_FAIL:
    return {
      ...state,
      error: action.payload
    };
  default:
    return state;
  }
};

export default AdminUsers;
