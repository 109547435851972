import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Row, Col, CardBody, Card, Container, Form, Input, FormFeedback, Label } from "reactstrap";

// actions
import { resetLogin } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo-sm.png";
import { API_URLS, ROLES } from "common/constants";
import { AxiosPost } from "common/axiosService";

import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  //meta title
  document.title = "Login | Greenalytics";
  const [eyeIcon, seteyeIcon] = useState({ password: "", confirmPassword: "" });
  const [isLoading, setisLoading] = useState(false);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { email: "", password: "" },
    validationSchema: Yup.object({
      // eslint-disable-next-line
      email: Yup.string().email("Email must be valid").matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Email must be valid").required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password")
    }),
    onSubmit: async values => {
      // dispatch(loginUser(values, props.history))
      setisLoading(true);
      const response = await AxiosPost(API_URLS.LOGIN, values);
      if (response && response.statusCode === 200) {
        if (response.data[0]) {
          localStorage.setItem("accessToken", response.data[0]?.userData.token);
          localStorage.setItem("authUser", JSON.stringify(response.data[0]));
        }
        if (response.data[0].userData.user.role === ROLES.SUPERADMIN) {
          history.push("/");
        } else if (response.data[0].userData.user.role === ROLES.ADMIN) {
          if (response.data[0].userData.user.companyId === undefined || response.data[0].userData.user.companyId === null) {
            history.push({ pathname: "/register-company" });
          } else {
            history.push("/");
          }
        } else {
          history.push("/");
        }
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    }
  });

  const passToggleEye = val => {
    let el = document.getElementById(val);
    if (el.type === "password") {
      el.type = "text";
      seteyeIcon(prev => ({ ...prev, [val]: "-off" }));
    } else {
      el.type = "password";
      seteyeIcon(prev => ({ ...prev, [val]: "" }));
    }
  };

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading
  }));

  useEffect(() => {
    if (error) {
      toast.error(error);
      setTimeout(() => {
        dispatch(resetLogin());
      }, 2000);
    }
  }, [loading]);

  const resendLink = async () => {
    const { value: email } = await Swal.fire({
      input: "email",
      inputLabel: "Your email address",
      inputPlaceholder: "Enter your email address",
      confirmButtonText: "Send",
      confirmButtonColor: "#3c7c44"
    });
    if (email) {
      try {
        let res = await fetch(API_URLS.RESEND_VERIFY_LINK, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: email })
        });
        let resJson = await res.json();
        if (resJson.statusCode === 200) {
          toast.success(resJson.message);
        } else {
          toast.error(resJson.message);
        }
      } catch (err) {
        console.error(err);
        toast.error("Something want wrong! Please Try again..");
      }
    }
  };
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to GreenAlytics.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/login">
                      <div
                        className="avatar-md profile-user-wid mb-4"
                        style={{ float: "left" }}
                      >
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            // className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2" style={{ clear: "both" }}>
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email
                          ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          )
                          : null}
                      </div>

                      <div className="mb-3 position-relative">
                        <Label className="form-label">Password</Label>
                        <Input
                          id="password"
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          className="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                              validation.errors.password
                              ? true
                              : false
                          }
                        />
                        <span onClick={() => passToggleEye("password")}>
                          <i className={`mdi mdi-eye${eyeIcon.password}`}></i>
                        </span>
                        {validation.touched.password &&
                          validation.errors.password
                          ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          )
                          : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading
                            ? (
                              <div
                                className="spinner-border text-white"
                                role="status"
                                style={{ width: "1.2rem", height: "1.2rem" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            )
                            : (
                              "Log In"
                            )}
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-3 text-center">
                <p>
                  Don&#39;t have email verified ?{" "}
                  <span
                    onClick={resendLink}
                    className="fw-medium text-primary cursor-pointer"
                  >
                    {" "}
                    Resend{" "}
                  </span>{" "}
                </p>
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link
                    to="/register"
                    className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}

                <p>
                  © {new Date().getFullYear()} Greenalytics. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Glasier Inc.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object
};
