import React, { useState, useEffect } from "react";
import { AxiosGet, AxiosPost } from "common/axiosService";
import { toast } from "react-toastify";
import IsRoleAuth from "pages/Authentication/IsRoleAuth";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import dateFormat from "dateformat";
import DeleteModal from "components/Common/DeleteModal";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";

const DraftReports = () => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;

  const [savedraft, setSavedraft] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sebiyear, setSebiYear] = useState("");
  const [isItemsChanged, setIsItemsChanged] = useState(false);

  const handleDeleteSebiReport = async () => {
    try {
      const response = await AxiosPost(`${apipath}/delete-sebi-data/${sebiyear}`, {});
      if (response && response.statusCode === 200) {
        setIsItemsChanged(prev => !prev);
        toast.success(`${response.message}`, {
          autoClose: 2000
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });

    }
    setDeleteModal(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/get-all-sebi-data`);
        if (response && response.statusCode === 200) {
          setSavedraft(response.data);
          setLoading(true);
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, [isItemsChanged]);

  const deleteSebiReport = () => {
    setDeleteModal(true);
  };

  const disand = savedraft && savedraft.filter((list) => !list.isReportComplete);

  return (
    <React.Fragment>
      <IsRoleAuth />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSebiReport}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">Draft Reports</h4>
                    <div>
                      <Link to="/admin/report">
                        <Button color="primary">Create New Report</Button>
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>

            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Reports in Draft</CardTitle> */}
                  <SimpleBar style={{ minHeight: "62vh", maxHeight: "65vh" }}>
                    <div className="table-responsive" >
                      <Table className="table table-nowrap align-middle table-hover mb-0">
                        <tbody>
                          {loading ?
                            disand.length >= 1
                              ? disand.map((file, i) =>
                              // if(i > 2) return;
                                (
                                  <tr key={"_file_" + i}>
                                    <td width="4%">
                                      <div className="avatar-sm">
                                        <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                                          <i className="bx bxs-file-doc" />
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <h5 className="text-truncate font-size-14 mb-1">
                                        <Link to="#" className="text-dark">
                                      BRSR REPORT-{file.year}
                                        </Link>
                                      </h5>
                                      <p className="text-muted mb-0">Last Update: {dateFormat(`${file.modifiedAt}`, "mmm dS yyyy, h:MM TT")}</p>
                                    </td>
                                    <td width="4%">
                                      <Link to={`/admin/report/brsr/${file.year}`} className="text-success p-1">
                                        <Button
                                          color="light"
                                          className="btn btn-soft-success"
                                        >
                                           Continue
                                        </Button>
                                      </Link>
                                    </td>
                                    <td width="3%">
                                      <Link to="#" className="text-danger p-1" onClick={() => {
                                        deleteSebiReport(),
                                        setSebiYear(file.year);
                                      }}>
                                        <i className="mdi mdi-delete" />
                                      </Link>
                                    </td>
                                  </tr>
                                )
                              ):
                              <h4 className="react-activity">No Data Found</h4>
                            :
                            <div className="react-activity">
                              <Dots color="#3C7C44" size={20} speed={0.6} />
                            </div>}

                        </tbody>
                      </Table>
                    </div>
                  </SimpleBar>
                </CardBody>

              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default DraftReports;
