import { AxiosGet, AxiosPost } from "common/axiosService";
import { API_URLS } from "common/constants";
import Loading from "components/Common/Loading";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { Col, Label } from "reactstrap";
import logo_lg from "../../assets/images/logo-lg.png";

function BRSRApprove () {
  const { token } = new useParams();
  const history = useHistory();
  const [base64Data, setBase64Data] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [comment, setComment] = useState();
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const getPdfData = async (pdfName) => {
    // const userToken = localStorage.getItem("accessToken");
    try {
      const response = await AxiosGet(`${apipath}/download-brsr-pdf/${pdfName}/${token}`);
      if (response && response.statusCode === 200) {
        setBase64Data(response.data[0]);
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  const verifyApprovalToken = async () => {
    try {
      const response = await AxiosPost(`${API_URLS.VERIFY_REPORT_APPROVAL_TOKEN}/${token}`);
      if (response && response.statusCode === 200) {
        getPdfData(response.data[0]?.pdfName);
      } else {
        history.push("/login");
        return;
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  useEffect(() => {
    if (token) {
      verifyApprovalToken();
    }
  }, []);
  const verifyReport = async (status) => {
    setIsDisable(true);
    try {
      const response = await AxiosPost(`${API_URLS.APPROVE_REPORT}/${token}`, { status, comment });
      if (response.statusCode === 200) {
        toast.success(response.message);
        setTimeout(() => {
          setIsDisable(false);
          history.push("/login");
        }, 2000);
      } else {
        setIsDisable(false);
        toast.error(response.message);
        return;
      }
    } catch (e) {
      toast.error("Something Went Wrong", { toastId: "nodata" });
    }
  };

  return (
    <div className="report-pdf">
      <div className="page-content sticky-head-section">
        <div className="container-fluid">
          <span className="logo-lg">
            <img src={logo_lg} alt="" height="45" />
          </span>
          <div className="card">
            <div className="card-body">
              {base64Data
                ? <div>
                  <form onSubmit={async (e) => {
                    e.preventDefault();
                    verifyReport("Approve");
                  }}>
                    <object title="sebiReport.pdf" data={`data:application/pdf;base64,${base64Data}`} type="application/pdf" width="100%" className="h-100vh">
                      <param name="sebi" value="sebi.pdf" />
                    </object>
                    <div>
                      <Col lg={12} >
                        <Label>Comment *</Label>
                        <textarea name={"Comment"}
                          required
                          placeholder="Please add Comment" className="form-control"
                          onChange={e => setComment(e.target.value)}
                        ></textarea>
                      </Col>
                      <div className="mt-3 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div >
                          <button disabled={comment ? isDisable : true} type="submit" className="btn btn-primary w-md">Approve</button>{" "}
                          <button disabled={comment ? isDisable : true} type="button" onClick={() => verifyReport("Reject")} className="btn btn-danger w-md " >Reject</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                : <Loading />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BRSRApprove;