import React from "react";
import { Redirect } from "react-router-dom";
import { ROLES } from "common/constants";

function IsRoleAuth () {
  if (!localStorage.getItem("authUser")) {
    return <Redirect to={"/login"} />;
  } else if (JSON.parse(localStorage.getItem("authUser")).userData.user.role === ROLES.SUPERADMIN) {
    return <div></div>;
  } else if (JSON.parse(localStorage.getItem("authUser")).userData.user.role ===
    ROLES.ADMIN) {
    if (JSON.parse(localStorage.getItem("authUser")).userData.user.companyId === null) {
      return <Redirect to={"/login"} />;
    }else {
      return <div></div>;
    }
  }
  return <div></div>;

}

export default IsRoleAuth;
