import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import BRSRApprove from "pages/Admin/BRSRApprove";
// Import Routes all
import { ToastContainer } from "react-toastify";
import PageNotFound from "PageNotFound";
import { ROLES } from "common/constants";
import Authmiddleware from "./routes/route";
import NonAuthLayout from "./components/NonAuthLayout";
import VerticalLayout from "./components/VerticalLayout/";
import { superAdminRoutes, adminRoutes, authProtectedRoutes, publicRoutes, stakeholdersRoutes, userRoutes } from "./routes";

import "./assets/scss/theme.scss";
import "./assets/style.css";

const App = () => {
  const Layout = VerticalLayout;

  const checkRoutes = () => {
    if (!JSON.parse(localStorage.getItem("authUser"))) {
      const checkAdmin = adminRoutes.filter( item => item.path === window.location.pathname);
      const checkUser = userRoutes.filter(item => item.path === window.location.pathname);
      const checkStakeholder = stakeholdersRoutes.filter(item => item.path === window.location.pathname);
      const checkSuperAdmin = superAdminRoutes.filter(item => item.path === window.location.pathname);
      if (checkAdmin.length > 0 || checkSuperAdmin.length > 0 || checkUser.length > 0 || checkStakeholder.length > 0) {
        window.location.replace("/login");
      }
    }
  };

  useEffect(() => {
    checkRoutes();
  });

  return (
    <React.Fragment>
      <ToastContainer />
      <Router>
        <Switch>
          <Route path={"/brsrApprove/:token"} component={BRSRApprove} />
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}

          {JSON.parse(localStorage.getItem("authUser")) &&
            JSON.parse(localStorage.getItem("authUser")).userData?.user?.role === ROLES.SUPERADMIN
            && superAdminRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          {JSON.parse(localStorage.getItem("authUser")) &&
            JSON.parse(localStorage.getItem("authUser")).userData?.user?.role ===
            ROLES.STAKEHOLDERS
            && stakeholdersRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          {JSON.parse(localStorage.getItem("authUser")) &&
            JSON.parse(localStorage.getItem("authUser")).userData?.user?.role ===
            ROLES.ADMIN
            && adminRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}

          {JSON.parse(localStorage.getItem("authUser")) &&
            JSON.parse(localStorage.getItem("authUser")).userData?.user?.role ===
            ROLES.USER
            && userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}

          <Route path="*" component={PageNotFound} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => ({
  layout: state.Layout
});

export default connect(mapStateToProps, null)(App);
