import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import classnames from "classnames";
import { Field, Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import { Col, FormFeedback, Label, Row } from "reactstrap";
import CurrencyFormat from "react-currency-format";
// import getSymbolFromCurrency from "currency-symbol-map";
import { Country } from "./Country";

const CompanyProfileValidationSchema = Yup.object().shape({
  companyName: Yup.string().required("Please Enter Company Name"),
  currency: Yup.string().when(["authorizedCapital", "paidUpCapital"], {
    is: (authorizedCapital, paidUpCapital) => authorizedCapital || paidUpCapital,
    then: Yup.string().required("Please Select Currency Type" )
  }),
  authorizedCapital: Yup.string().when(["paidUpCapital", "currency"], {
    is: (paidUpCapital, currency) => !paidUpCapital && currency,
    then: Yup.string().required("Please Add Amount")
  }),
  paidUpCapital: Yup.string().when(["authorizedCapital", "currency"], {
    is: (authorizedCapital, currency) => !authorizedCapital && currency,
    then: Yup.string().required("Please Add Amount")
  })
}, [["authorizedCapital", "paidUpCapital"],
  ["paidUpCapital", "currency"],
  ["authorizedCapital", "currency"] ]);

// step two function
const CompanyProfile = props => {
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    props.setstepLoading(prev => ({ ...prev, ["step2"]: false }));
  }, [1]);

  const handleSubmit = async values => {
    if (!isError) {
      props.setstepLoading(prev => ({ ...prev, ["step2"]: true }));
      setTimeout(() => {
        props.next(values);
        props.toggleTab(2, 50);
        props.setstep(prev => ({ ...prev, ["step2"]: true }));
        props.setstepLoading(prev => ({ ...prev, ["step2"]: false }));
      }, 600);
    }
  };

  return (
    <div className="content w-100 clearfix mt-0">
      <Formik
        validationSchema={CompanyProfileValidationSchema}
        initialValues={props.data}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue }) =>{
          if (errors && Object.keys(errors).length !== 0) {
            setIsError(true);
          } else {
            setIsError(false);
          }
          return (
          // step two form
            <Form>
              <Label>
                <b>Company Details</b>
              </Label>
              <Row className="mb-3">
                <Col lg="6">
                  <div className="mb-2">
                    <Label for="cin">
                    Corporation Identity Number (CIN) of the Company
                    </Label>
                    <Field
                      id="cin"
                      name="cin"
                      className={classnames(
                        "form-control",
                        touched.cin && errors.cin ? "is-invalid" : ""
                      )}
                      placeholder="Enter Company CIN"
                      type="text"
                    />
                    {touched.cin && errors.cin
                      ? (
                        <FormFeedback type="invalid">{errors.cin}</FormFeedback>
                      )
                      : null}
                  </div>
                </Col>

                <Col lg="6">
                  <div className="mb-2">
                    <Label for="companyName">Name of the Company*</Label>
                    <Field
                      id="companyName"
                      name="companyName"
                      className={classnames(
                        "form-control",
                        touched.companyName && errors.companyName
                          ? "is-invalid"
                          : ""
                      )}
                      placeholder="Enter Company Name*"
                      type="text"
                    />
                    {touched.companyName && errors.companyName
                      ? (
                        <FormFeedback type="invalid">
                          {errors.companyName}
                        </FormFeedback>
                      )
                      : null}
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg="6">
                  <div className="mb-2">
                    <Label for="yearOfIncorporation">Year of Incorporation</Label>
                    <Field
                      id="yearOfIncorporation"
                      name="yearOfIncorporation"
                      className={classnames(
                        "form-control",
                        touched.yearOfIncorporation && errors.yearOfIncorporation
                          ? "is-invalid"
                          : ""
                      )}
                      placeholder="Enter Year of Incorporation"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                    />
                    {touched.yearOfIncorporation && errors.yearOfIncorporation
                      ? (
                        <FormFeedback type="invalid">
                          {errors.yearOfIncorporation}
                        </FormFeedback>
                      )
                      : null}
                  </div>
                </Col>

                <Col lg="6">
                  <div className="mb-2">
                    <Label for="corporataeAddress">Corporate Address</Label>
                    <Field
                      id="corporataeAddress"
                      name="corporataeAddress"
                      className={classnames(
                        "form-control",
                        touched.corporataeAddress && errors.corporataeAddress
                          ? "is-invalid"
                          : ""
                      )}
                      placeholder="Enter Corporate Address"
                      type="text"
                    />
                    {touched.corporataeAddress && errors.corporataeAddress
                      ? (
                        <FormFeedback type="invalid">
                          {errors.corporataeAddress}
                        </FormFeedback>
                      )
                      : null}
                  </div>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col lg="6">
                  <Row>
                    <Col lg="4">
                      <div className="mb-2">
                        <Label for="currency">Currency</Label>
                        <Field
                          id="currency"
                          as="select"
                          name="currency"
                          className={classnames(
                            "form-select",
                            errors.currency
                              ? "is-invalid"
                              : ""
                          )}
                        >
                          <option selected value="">
                             Select Currency
                          </option>
                          {Country.map((item, i) =>
                            <option key={i} value={`${item.countryCode}, ${item.symbol}`}>{item.countryCode},<span style={{ fontSize: "20px", fontWidth: "700" }}> <b>{item.symbol}</b></span></option>
                          )}
                        </Field>
                        {errors.currency
                          ? (
                            <FormFeedback type="invalid">
                              {errors.currency}
                            </FormFeedback>
                          )
                          : null}
                      </div>
                    </Col>

                    <Col lg="8">
                      <div className="mb-2">
                        <Label for="authorizedCapital">Authorized Capital</Label>
                        <CurrencyFormat
                          id="authorizedCapital"
                          name="authorizedCapital"
                          value={values.authorizedCapital}
                          onValueChange={(value) => {
                            const { formattedValue } = value;
                            setFieldValue("authorizedCapital", formattedValue);
                          }}
                          thousandSeparator={true}
                          thousandSpacing={"2s"}
                          className={classnames(
                            "form-control",
                            errors.authorizedCapital
                              ? "is-invalid"
                              : ""
                          )}
                          placeholder="Enter Authorized Capital"
                          type="text"
                        />
                        { errors.authorizedCapital
                          ? (
                            <FormFeedback type="invalid">
                              {errors.authorizedCapital}
                            </FormFeedback>
                          )
                          : null}
                      </div>
                    </Col>

                  </Row>
                </Col>

                <Col lg="6">
                  <div className="mb-2">
                    <Label for="paidUpCapital">PaidUp Capital </Label>
                    <CurrencyFormat
                      id="paidUpCapital"
                      name="paidUpCapital"
                      className={classnames(
                        "form-control",
                        errors.paidUpCapital
                          ? "is-invalid"
                          : ""
                      )}
                      placeholder="Enter PaidUp Capital"
                      type="text"
                      thousandSeparator={true}
                      thousandSpacing={"2s"}
                      value={values.paidUpCapital}
                      onValueChange={(value) => {
                        const { formattedValue } = value;
                        setFieldValue("paidUpCapital", formattedValue);
                      }}
                    />
                    { errors.paidUpCapital
                      ? (
                        <FormFeedback type="invalid">
                          {errors.paidUpCapital}
                        </FormFeedback>
                      )
                      : null}
                  </div>
                </Col>
              </Row>
              <Row className={"mt-3 next-prev-btn"}>
                <Col>
                  <button
                    className="btn btn-primary btn-block"
                    type="button"
                    onClick={() => props.prev(values)}
                    disabled={props.stepLoading.step2}
                  >
                  Prev
                  </button>{" "}
                  {props.step && props.step.step2
                    ? (
                      <button
                        className="btn btn-primary btn-block"
                        type="button"
                        onClick={() => {
                          if (!isError) {
                            props.next(values);
                          }
                        }}
                        disabled={props.stepLoading.step2}
                      >
                    Next
                      </button>
                    )
                    : (
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                        disabled={props.stepLoading.step2}
                      >
                        {props.stepLoading.step2
                          ? (
                            <div
                              className="spinner-border text-white"
                              role="status"
                              style={{ width: "1rem", height: "1rem" }}
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )
                          : (
                            "Next"
                          )}
                      </button>
                    )}
                </Col>
              </Row>
            </Form>
          );}}
      </Formik>
      {/* </TabPane>
      </TabContent> */}
    </div>
  );
};
export default withRouter(CompanyProfile);
