import React, { useState, useEffect } from "react";
import { AxiosGet } from "common/axiosService";
import { API_URLS } from "common/constants";
import ReactApexChart from "react-apexcharts";
import { toast } from "react-toastify";
import _, { get as _get } from "lodash";
import Spinearea from "./spline";
import Slider from "react-slick";
// import NoDataFound from "../../../assets/images/no-data-img.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./product.css";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import Select from "react-select";
import CarbonChart from "../Carbon/mines/TestChart";
import Consolidate from "../Carbon/mines/consolidate";
import Loading from "components/Common/Loading";

function index () {
  const session = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")) : {};
  const [activeScope, setActiveScope] = useState("Scope 1");
  const [activeAnalyticsScope, setActiveAnalyticsScopee] = useState("Consolidated");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedScope, setSelectedScope] = useState("Scope 1");
  const [dataaaa, setDataaaa] = useState();
  const [chartEmmissionList, setChartEmmissionList] = useState({
    series: [], labels: [], data: {}
  });
  const [chartScopeList, setChartScopeList] = useState([]);
  const authorizedUser = localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser"));
  const [loading, setloading] = useState(false);
  const [level, setLevel] = useState(0);
  const [chartList, setChartList] = useState();
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [carbonAllChartData, setCarbonAllChartData] = useState([]);
  const [fullChart, setFullChart] = useState({
    showPredictiveChart: false, mainCategory: "", activeStandAloneChartTitle: "", selectedChart: [], chartType: "", predictedChart: "", isPredictive: false, predictiveYears: 2
  });
  const getCarbonChartData = async (locationId, scopeName) => {
    if (authorizedUser) {
      setloading(true);
      try {
        const response = await AxiosGet(`${API_URLS.CARBON_CHART}?locationId=${locationId}&scope=${scopeName}`);
        if (response && response.statusCode === 200) {
          // setChartList(Object.entries(response.data[0]).map(item => ({ [item[0]]: { [activeAnalyticsScope]: item[1][activeAnalyticsScope] } })));
          setChartList(response.data[0]);
          setloading(false);
        } else {
          setloading(false);
        }
      } catch (error) {
        setloading(false);
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    }
  };
  const getCarbonAllChartData = async (scopeName) => {
    if (authorizedUser) {
      setloading(true);
      try {
        const response = await AxiosGet(`${API_URLS.GET_SCOPE_ALL_CHART_DATA}?scope=${scopeName}`);
        if (response && response.statusCode === 200) {
          // setChartList(Object.entries(response.data[0]).map(item => ({ [item[0]]: { [activeAnalyticsScope]: item[1][activeAnalyticsScope] } })));
          setChartList(response.data[0]);
          setloading(false);
        } else {
          setloading(false);
        }
      } catch (error) {
        setloading(false);
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    }
  };
  const fetchCarbonAllChartData = async (locationId) => {
    setChartList();
    setCarbonAllChartData([]);
    const response = await AxiosGet(locationId ? `${API_URLS.GET_CARBON_CHART_DATA}/${locationId}`: API_URLS.GET_CARBON_ALL_CHART_DATA);
    if (response && response.statusCode === 200) {
      setCarbonAllChartData(response.data);
    }
    setloading(false);
  };
  const fullScreenViewClick = (title, data, chartType, previous, predictMainCategory) => {
    setLevel(previous ? previous.length : 0);
    setFullChart({
      ...fullChart,
      activeStandAloneChartTitle: title,
      showPredictiveChart: !fullChart.showPredictiveChart,
      selectedChart: data,
      chartType: chartType,
      isPredictive: false,
      predictedChart: "",
      predictiveYears: 2,
      mainCategory: predictMainCategory
    });
  };

  const changeChartListData = (e, dataPoints, chartHeading, previousLevel, mainCategory) => {
    Object.entries(Object.values(chartList)[0]).forEach((item) => {
      if (item[0] === chartHeading) {
        let chartData = Object.values(chartList)[0];
        let oldData = Object.values(chartList)[0][`${chartHeading}`];
        oldData["currentChart"] = e ? { dataPoints, e, mainCategory } : undefined;
        if (!previousLevel || previousLevel === "") {
          oldData["Level1Data"] = e ? { dataPoints, e, mainCategory } : undefined;
        } else {
          oldData[`${previousLevel}Data`] = e ? { dataPoints, e } : undefined;
        }
        if (fullChart.showPredictiveChart) {
          setFullChart({ ...fullChart, selectedChart: oldData });
        }
        chartData[chartHeading] = oldData;
        const params = {};
        params[Object.keys(chartList)[0]] = chartData;
        setChartList(params);
      }
    });
  };

  const changeLocation = e => {
    setloading(true);
    setFullChart({
      ...fullChart,
      activeStandAloneChartTitle: "", showPredictiveChart: false, selectedChart: [], predictedChart: "", chartType: "", predictiveYears: 2
    });
    if (activeAnalyticsScope === "Consolidated") {
      fetchCarbonAllChartData(e?._id);
    } else {
      if (e._id) {
        getCarbonChartData(e._id, activeAnalyticsScope);
      } else {
        getCarbonAllChartData(activeAnalyticsScope);
      }
    }
    setSelectedLocation(e);
  };

  const getScopeChartData = async () => {
    try {
      const response = await AxiosGet(`${API_URLS.GET_SCOPE_MONTH_CHART_DATA}/${selectedScope}/${selectedYear}`);
      if (response && response.statusCode === 200) {
        setChartScopeList(response.data[0]?.result);
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  const getAllLocation = async () => {
    try {
      const response = await AxiosGet(`${API_URLS.GET_ALL_LOCATIONS}/${authorizedUser.userData.user?.companyId?._id}`);
      if (response && response.statusCode === 200) {
        if (response.data.length > 0) {
          let locations = response.data.map((item) => {
            item.value = item._id;
            item.label = `${item.city} - ${item.addressType}`;
            return item;
          });
          locations.unshift({ value: "", label: "All", city: "All" });
          setSelectedLocation({ value: "", label: "All", city: "All" });
          // locations.unshift({ value: response.data[0]?._id, label: response.data[0]?.city, city: response.data[0]?.city });
          // setSelectedLocation(response.data[0]);
          setLocationList(locations);
          fetchCarbonAllChartData();
        } else {
          setloading(false);
          toast.success("Please Add Location First", { autoClose: 2000 });
        }
      } else {
        setloading(false);
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  const onFullScreenPredictiveToggle = async (isPredict, activeChart, chartType, categoryName, year, predictMainCategory) => {
    const chartData = fullChart;
    if (level > 0) {
      _.set(chartData, "selectedChart", {
        Level1: Object.entries(Object.values(chartList)[0]).map((item) => {
          if (item[0] === predictMainCategory) {
            return item[1].Level1;
          }
        }).filter(i => i !== undefined)[0]
      });
    }
    setloading(prevState => !prevState);
    if (isPredict) {
      let response;
      let predictResponse;
      if (selectedLocation._id) {
        response = await AxiosGet(`${API_URLS.PREDICTIVE_CARBON_CHART}/${selectedLocation._id}/Scope 1/${year}`);
        predictResponse = response.data[0];
      } else {
        response = await AxiosGet(`${API_URLS.ALL_PREDICTIVE_CHART}?typeOfData=Carbon Footprint&category=${predictMainCategory}&totalYears=${year}`);
        predictResponse = response.data[0].consolidatedData;
      }
      if (response && response.statusCode === 200) {
        if (response.data[0] && predictResponse && predictResponse.Level1) {
          setFullChart({ ...chartData, isPredictive: isPredict, predictedChart: predictResponse, chartType: chartType, predictiveYears: year });
          setTimeout(() => setloading(prevState => !prevState), 1000);
        }
      }
    } else {
      setFullChart({ ...chartData, predictedChart: "", isPredictive: false, activeStandAloneChart: true, predictiveYears: year });
      setTimeout(() => setloading(prevState => !prevState), 1000);
    }
  };

  const onPredictToggle = async (title, data, chartType, isPredict, activeChart, categoryName, year, predictMainCategory) => {
    const chartData = fullChart;
    setloading(isPredict);
    _.set(chartData, "selectedChart", {
      Level1: Object.entries(Object.values(chartList)[0]).map((item) => {
        if (item[0] === predictMainCategory) {
          return item[1].Level1;
        }
      }).filter(i => i !== undefined)[0]
    });
    let response;
    let predictResponse;
    if (selectedLocation._id) {
      response = await AxiosGet(`${API_URLS.PREDICTIVE_CARBON_CHART}/${selectedLocation._id}/Scope 1/${year}`);
      predictResponse = response.data[0];
    } else {
      response = await AxiosGet(`${API_URLS.ALL_PREDICTIVE_CHART}?typeOfData=Carbon Footprint&category=${predictMainCategory}&totalYears=${year}`);
      predictResponse = response.data[0].consolidatedData;
    }
    if (response && response.statusCode === 200) {
      if (response.data[0]) {
        setFullChart({
          ...chartData,
          activeStandAloneChartTitle: title,
          activeStandAloneChart: activeChart,
          isPredictive: isPredict,
          showPredictiveChart: isPredict ? isPredict : !fullChart.showPredictiveChart,
          predictedChart: predictResponse,
          chartType: chartType,
          predictiveYears: year
        });
      }
      setloading(!isPredict);
    }
  };

  useEffect(() => {
    getAllLocation();
  }, []);

  useEffect(() => {
    getScopeData();
  }, [selectedYear]);

  const onScopeToggle = (name) => setActiveScope(name);

  const onAnalyticsScopeToggle = (name) =>{
    setloading(true);
    if (selectedLocation.value) {
      getCarbonChartData(selectedLocation?._id, name);
    } else if (name === "Consolidated"){
      fetchCarbonAllChartData(selectedLocation?._id);
    } else {
      getCarbonAllChartData(name);
    }
    setFullChart({ showPredictiveChart: false, mainCategory: "", activeStandAloneChartTitle: "", selectedChart: [], chartType: "", predictedChart: "", isPredictive: false, predictiveYears: 2 });
    setActiveAnalyticsScopee(name);
  };

  const options = {
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#3f7e44"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100]
      }
    },
    markers: {
      sizeOffset: 7,
      strokeColors: "#3f7e44",
      hover: {
        sizeOffset: 5
      }
    },
    tooltip: {
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return`<div>${data.x} : <span>${Number(data.y).toLocaleString("en-IN")}</span></div>`;
      },
      x: {
        show: true
      }
    }
  };

  useEffect(() => {
    getScopeLoopData();
  }, [activeScope, selectedYear]);

  useEffect(() => {
    getScopeChartData();
  }, [selectedScope, selectedYear]);

  const scopeOptions = {
    chart: {
      height: 350,
      type: "radialBar"
    },
    colors: ["#3f7e44", "#d2df64", "#4c9f38"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: { fontSize: "22px" },
          value: { fontSize: "16px" },
          total: {
            show: true,
            label: "Total",
            formatter: () => "100%"
          }
        }
      }
    },
    labels: chartEmmissionList.labels
  };
  const getScopeData = async () => {
    try {
      const response = await AxiosGet(`${API_URLS.GET_SCOPE_CHART_DATA}/${selectedYear}`);
      if (response && response.statusCode === 200) {
        const series = Object.values(response.data[0]);
        const total = series.reduce((a, b) => a + b, 0);
        const seriesPercentage = series.map(item => ((item / total)*100).toFixed(2));
        setChartEmmissionList({ series: seriesPercentage, data: response.data[0], labels: Object.keys(response.data[0]) });
      } else {
        setChartEmmissionList({ series: [], labels: [] });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  const getScopeLoopData = async () => {
    try {
      const response = await AxiosGet(`${API_URLS.GET_SCOPE_LOOP_CHART_DATA}/${activeScope}/${selectedYear}`);
      if (response && response.statusCode === 200) {
        setDataaaa(response.data);
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3, infinite: true, dots: true } },
      { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 2, initialSlide: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } }
    ]
  };

  const years = Array.from(
    { length: 25 },
    (_, i) => _get(session, "userData.user.companyId.financialStartMonth", 1) === 1
      ? new Date().getFullYear() - i
      : `${new Date().getFullYear() - i}-${(Number(new Date().getFullYear().toString().slice(-2)) -i + 1).toString().padStart(2, "0")}`
  );

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row overview-year">
          <div className="col-md-6">
            <h4 className="mt-1 title-heading">GHG Emissions Overview</h4>
          </div>
          <div className="col-md-6 d-flex">
            <span>Emissions overview for the year</span>
            <select className="form-select form-select-sm ms-2" onChange={(e) => e.target.value && setSelectedYear(e.target.value.split("-")[0])}>
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <div className="card">
              <div className="card-body h-465">
                <div>
                  <div id="overview-chart" className="apex-charts" dir="ltr">
                    <div className="toolbar button-items text-center">
                      <button type="button" className={selectedScope === "Scope 1" ? "btn btn-light btn-sm activeScope" : "btn btn-light btn-sm"}
                        id="one_month" onClick={() => setSelectedScope("Scope 1")}>
                            Scope 1
                      </button>
                      <button type="button" className={selectedScope === "Scope 2" ? "btn btn-light btn-sm activeScope" : "btn btn-light btn-sm"}
                        id="six_months" onClick={() => setSelectedScope("Scope 2")}>
                            Scope 2
                      </button>
                      <button type="button" className={selectedScope === "Scope 3" ? "btn btn-light btn-sm activeScope" : "btn btn-light btn-sm"}
                        id="one_year" onClick={() => setSelectedScope("Scope 3")}>
                            Scope 3
                      </button>
                    </div>
                    {chartScopeList.length > 0 ?
                      <div id="overview-chart-timeline">
                        <Spinearea data={chartScopeList} scope={selectedScope} />
                      </div>
                      : <div>No Data </div>
                      // : <img style={ { width: "466px" } } src={NoDataFound} alt="no data" />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="card">
              <div className="card-body h-465">
                <h4 className="card-title mb-3">Current year Scope-wise Calculations</h4>
                <div className="row">
                  <div className="col-lg-7 col-sm-6 mt-70">
                    <div>
                      <ReactApexChart
                        options={scopeOptions}
                        series={chartEmmissionList.series}
                        type="radialBar"
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-sm-6 align-self-center mt-70">
                    <div>
                      <p className="mb-2"><i className="mdi mdi-circle align-middle font-size-10 me-2 text-green-one"></i> Scope 1 - Direct Emissions</p>
                      <h5>{Number(_get(chartEmmissionList, "data.Scope 1", 0)).toLocaleString("en-IN")} kg CO<sub>2</sub> e</h5>
                    </div>
                    <div className="mt-4 pt-2">
                      <p className="mb-2"><i className="mdi mdi-circle align-middle font-size-10 me-2 text-green-two"></i> Scope 2 - Indirect Emissions</p>
                      <h5>{Number(_get(chartEmmissionList, "data.Scope 2", 0)).toLocaleString("en-IN")} kg CO<sub>2</sub> e</h5>
                    </div>
                    <div className="mt-4 pt-2">
                      <p className="mb-2"><i className="mdi mdi-circle align-middle font-size-10 me-2 text-green-three"></i> Scope 3 - Other Indirect Emissions</p>
                      <h5>{Number(_get(chartEmmissionList, "data.Scope 3", 0)).toLocaleString("en-IN")} kg CO<sub>2</sub> e</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5 className="mb-3"><b>Emissions Breakdown</b></h5>
        <div className="card emission-section">
          <div className="card-body">
            <div className="row">
              <div className="col-md-9">
                <ul className="nav nav-pills rounded" role="tablist">
                  <li className="nav-item"
                    onClick={() => onScopeToggle("Scope 1")}
                  >
                    <a className={activeScope === "Scope 1" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" role="tab">SCOPE 1</a>
                  </li>
                  <li className="nav-item"
                    onClick={() => onScopeToggle("Scope 2")}
                  >
                    <a className={activeScope === "Scope 2" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" role="tab">SCOPE 2</a>
                  </li>
                  <li className="nav-item"
                    onClick={() => onScopeToggle("Scope 3")}
                  >
                    <a className={activeScope === "Scope 3" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" role="tab">SCOPE 3</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-4" >
                <label>TOTAL EMISSIONS : {Number(_.get(chartEmmissionList, "data.Scope 1", 0) + _.get(chartEmmissionList, "data.Scope 2", 0) + _.get(chartEmmissionList,
                  "data.Scope 3", 0)).toLocaleString("en-IN")} kg CO<sub>2</sub>e </label>
              </div>
              <div className="col-md-12 tree-tab">
                <div className="tab-content mt-4">
                  <div className={activeScope === "Scope 1" ? "tab-pane active tree-view" : "tab-pane tree-view"} id="transactions-all-tab" role="tabpanel">
                    <div id="collapseDVR3" className="panel-collapse collapse in">
                      <div className="tree">
                        {
                          dataaaa && dataaaa[0]?.data?.length > 0 ?
                            <ul>
                              <li className="scope">
                                <span><i className="fa fa-caret-down"></i>{dataaaa[0]?.name}</span>
                                <span className="total-scope">{dataaaa[0]?.value && Number(dataaaa[0]?.value).toLocaleString("en-IN")} kg CO<sub>2</sub> e </span>
                                <ul>
                                  {
                                    dataaaa[0]?.data?.map((item, i) => <li key={`scope1${i}`}>
                                      <span><i className="fa fa-caret-down"></i> {item?.name}</span>
                                      <span className="total-scope">{item?.value && Number(item?.value).toLocaleString("en-IN")} kg CO<sub>2</sub> e</span>
                                      <ul className="tree-box">
                                        <li className="border-none">
                                          <Slider {...settings}>
                                            {item?.dataPoints && item?.dataPoints?.map( (subItem, index)=> (
                                              <div className="card" key={index}>
                                                <div className="card-body">
                                                  <div className="row">
                                                    <div className="col-6">
                                                      <div>
                                                        <h5>{subItem?.name}</h5>
                                                        <p className="text-muted text-truncate mb-0">{subItem?.categoryTotal &&
                                                        Number(subItem?.categoryTotal).toLocaleString("en-IN")} kg CO<sub>2</sub> e</p>
                                                      </div>
                                                    </div>
                                                    <div className="col-6">
                                                      <div id="area-sparkline-chart-1">
                                                        <ReactApexChart
                                                          options={options}
                                                          series={[{ data: subItem?.subData }]}
                                                          type="area"
                                                          height={40}
                                                          className="apex-charts"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </Slider>
                                        </li>
                                      </ul>
                                    </li>)
                                  }
                                </ul>
                              </li>
                            </ul>
                            :<div>No Data</div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className={activeScope === "Scope 2" ? "tab-pane active tree-view" : "tab-pane tree-view"} id="transactions-buy-tab" role="tabpanel">
                    <div className="tree">
                      {
                        dataaaa && dataaaa[0]?.data?.length > 0 ?
                          <ul>
                            <li className="scope">
                              <span><i className="fa fa-caret-down"></i>{dataaaa[0]?.name}</span>
                              <span className="total-scope">{dataaaa[0]?.value && Number(dataaaa[0]?.value).toLocaleString("en-IN")} kg CO<sub>2</sub> e </span>
                              <ul>
                                {
                                  dataaaa[0]?.data?.map((item, i) => <li key={`scope1${i}`}>
                                    <span><i className="fa fa-caret-down"></i>{item?.name}</span>
                                    <span className="total-scope">{item?.value && Number(item?.value).toLocaleString("en-IN")} kg CO<sub>2</sub> e</span>
                                    <ul className="tree-box">
                                      <li className="border-none">
                                        <Slider {...settings}>
                                          {item?.dataPoints && item?.dataPoints?.map( (subItem, index)=> (
                                            <div className="card" key={index}>
                                              <div className="card-body">
                                                <div className="row">
                                                  <div className="col-6">
                                                    <div>
                                                      <h5>{subItem?.name}</h5>
                                                      <p className="text-muted text-truncate mb-0">{subItem?.categoryTotal &&
                                                      Number(subItem?.categoryTotal).toLocaleString("en-IN")} CO<sub>2</sub> e</p>
                                                    </div>
                                                  </div>
                                                  <div className="col-6">
                                                    <div id="area-sparkline-chart-1">
                                                      <ReactApexChart
                                                        options={options}
                                                        series={[{ data: subItem?.subData }]}
                                                        type="area"
                                                        height={40}
                                                        className="apex-charts"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </Slider>
                                      </li>
                                    </ul>
                                  </li>)
                                }
                              </ul>
                            </li>
                          </ul>
                          :<div>No Data</div>
                      }
                    </div>
                  </div>
                  <div className={activeScope === "Scope 3" ? "tab-pane active tree-view" : "tab-pane tree-view"} id="transactions-sell-tab" role="tabpanel">
                    <div className="tree">
                      {
                        dataaaa && dataaaa[0]?.data?.length > 0 ?
                          <ul>
                            <li className="scope">
                              <span><i className="fa fa-caret-down"></i>{dataaaa[0]?.name}</span>
                              <span className="total-scope">{dataaaa[0]?.value && Number(dataaaa[0]?.value).toLocaleString("en-IN")} kg CO<sub>2</sub> e </span>
                              <ul>
                                {
                                  dataaaa[0]?.data?.map((item, i) => <li key={`scope1${i}`}>
                                    <span><i className="fa fa-caret-down"></i> {item?.name}</span>
                                    <span className="total-scope">{item?.value && Number(item?.value).toLocaleString("en-IN")} kg CO<sub>2</sub> e</span>
                                    <ul className="tree-box">
                                      <li className="border-none">
                                        <Slider {...settings}>
                                          {item?.dataPoints && item?.dataPoints?.map( (subItem, index)=> (
                                            <div className="card" key={index}>
                                              <div className="card-body">
                                                <div className="row">
                                                  <div className="col-6">
                                                    <div>
                                                      <h5>{subItem?.name}</h5>
                                                      <p className="text-muted text-truncate mb-0">{subItem?.categoryTotal &&
                                                      Number(subItem?.categoryTotal).toLocaleString("en-IN")} CO<sub>2</sub> e</p>
                                                    </div>
                                                  </div>
                                                  <div className="col-6">
                                                    <div id="area-sparkline-chart-1">
                                                      <ReactApexChart
                                                        options={options}
                                                        series={[{ data: subItem?.subData }]}
                                                        type="area"
                                                        height={40}
                                                        className="apex-charts"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </Slider>
                                      </li>
                                    </ul>
                                  </li>)
                                }
                              </ul>
                            </li>
                          </ul>
                          :<div>No Data</div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container fluid>
        <h5 className="mb-3"><b>Carbon Footprint Analytics</b></h5>
        <Row>
          <div id="area-chart-1" dir="ltr">
            <Card>
              <CardBody>
                <Row>
                  <div className="col-md-9 emission-section d-flex align-items-end">
                    <ul className="nav nav-pills rounded" role="tablist">
                      <li className="nav-item"
                        onClick={() => onAnalyticsScopeToggle("Consolidated")}
                      >
                        <a className={activeAnalyticsScope === "Consolidated" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" role="tab">Consolidated</a>
                      </li>
                      <li className="nav-item"
                        onClick={() => onAnalyticsScopeToggle("Scope 1")}
                      >
                        <a className={activeAnalyticsScope === "Scope 1" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" role="tab">SCOPE 1</a>
                      </li>
                      <li className="nav-item"
                        onClick={() => onAnalyticsScopeToggle("Scope 2")}
                      >
                        <a className={activeAnalyticsScope === "Scope 2" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" role="tab">SCOPE 2</a>
                      </li>
                      <li className="nav-item"
                        onClick={() => onAnalyticsScopeToggle("Scope 3")}
                      >
                        <a className={activeAnalyticsScope === "Scope 3" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" role="tab">SCOPE 3</a>
                      </li>
                    </ul>
                  </div>
                  <Col lg="3">
                    <Label>Select Location</Label>
                    <Select
                      value={selectedLocation}
                      options={locationList}
                      onChange={changeLocation}
                    />
                  </Col>
                  <Row className="marginTop">
                    {loading
                      ? (
                        <Loading />
                      )
                      : fullChart.showPredictiveChart
                        ? (
                          <>
                            <i
                              className="bx bx-left-arrow"
                              style={{ cursor: "pointer", fontSize: "18px" }}
                              onClick={fullScreenViewClick}
                            />
                            <CarbonChart
                              items={fullChart.selectedChart}
                              alyticsType="Carbon Footprint"
                              heading={fullChart.activeStandAloneChartTitle}
                              back1="back1"
                              back2="back2"
                              mainCategory={fullChart.mainCategory}
                              standAloneChart={true}
                              predictiveChart={fullChart.isPredictive}
                              locationId={selectedLocation._id}
                              currentChart={fullChart.selectedChart && fullChart.selectedChart.currentChart}
                              predictChartData={fullChart.predictedChart}
                              onChartSegmentClick={changeChartListData}
                              onFullScreenButtonClick={fullScreenViewClick}
                              onFullScreenPredictButtonClick={onFullScreenPredictiveToggle}
                              chartType={fullChart.chartType}
                              predictiveYears={fullChart.predictiveYears}
                            />
                          </>
                        )
                        : (
                          <Row>
                            {carbonAllChartData.length && activeAnalyticsScope === "Consolidated" ?
                              <Col xl="12" lg="12" md="12">
                                <Consolidate items={carbonAllChartData} />
                              </Col>
                              : null}
                            {chartList && Object.entries(Object.values(chartList)[0]).map(
                              (item, i) => (
                                <Col key={`Carbon footprint${i}`} xl="12" lg="6" md="12">
                                  <CarbonChart
                                    index={i}
                                    items={item[1]}
                                    heading={item[0]}
                                    back1={"back1" + i}
                                    back2="back2"
                                    locationId={selectedLocation._id}
                                    currentChart={item[1].currentChart}
                                    onChartSegmentClick={changeChartListData}
                                    standAloneChart={true}
                                    onFullScreenButtonClick={fullScreenViewClick}
                                    chartType={fullChart.chartType}
                                    onPredictiveToggle={onPredictToggle}
                                  />
                                </Col>
                              )
                            )}
                          </Row>
                        )
                    }
                  </Row>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default index;
