import React, { useState } from "react";
import moment from "moment";
import { get as _get } from "lodash";
import { connect } from "react-redux";
import { Row, Col, Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import { addSebiData, getSebiForm, updateSebiData, draftSebiData } from "../../../store/newReport/actions";

import "../../../assets/css/app.min.css";
import { useHistory } from "react-router-dom";

function SebiYearModal (props) {
  const { addSebiData, draftSebiData } = props;
  const history = useHistory();
  const user = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")) : {};
  const [sebimodal, setSebimodal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [year, setyear] = useState(moment().format("YYYY"));
  const [selctedyear, setSelectedyear] = useState(moment().format("YYYY")-moment().add(1).format("YY"));

  const goToPreviousPath = () => {
    history.goBack();
  };
  const years = Array.from(
    { length: 25 },
    (_, i) => `${new Date().getFullYear() - i}-${(Number(new Date().getFullYear().toString().slice(-2)) -i + 1).toString().padStart(2, "0")}`
  );

  return (
    <>
      <Modal
        isOpen={sebimodal}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
        data-toggle="modal"
        backdrop={"static"}
        toggle={() => {
          setSebimodal(!sebimodal);
        }}
      >
        <ModalHeader
        >
            SEBI Report
        </ModalHeader>
        <div className="modal-body">
          <div className="text-center mb-4">
            <Row className="mb-3">
              <Col md="4" xl="4" sm="4" xs="4" style={{ marginTop: "8px" }}>
                  Select financial year:
              </Col>
              <Col md="8" xl="8" sm="8" xs="8">
                <select
                  className="form-select"
                  value={selctedyear}
                  onChange={e => {
                    setSelectedyear(e.target.value);
                    setyear(e.target.value.split("-")[0]);
                  }}
                >
                  {years.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </div>
        </div>
        <ModalFooter className="justify-content-end">
          <Button onClick={goToPreviousPath}>
              Back
          </Button>
          <Button
            type="button"
            color="primary"
            style={{ width: "150px" }}
            disabled={loading}
            onClick={() => {
              setLoading(true);
              addSebiData({
                year: year,
                sectionA: {
                  company_details: {
                    cin: _get(user, "userData.user.companyId.cin", ""),
                    name_of_company: _get(user, "userData.user.companyId.companyName", ""),
                    year_of_incorporation: _get(user, "userData.user.companyId.yearOfIncorporation", ""),
                    corporate_address: _get(user, "userData.user.companyId.corporataeAddress", ""),
                    email: _get(user, "userData.user.email", ""),
                    telephone: _get(user, "userData.user.mobileNumber", ""),
                    website: _get(user, "userData.user.companyId.personalUrl", ""),
                    financial_year: year,
                    name_of_the_stock_exchange: "",
                    paid_up_capital: _get(user, "userData.user.companyId.paidUpCapital", ""),
                    name_and_contact_details: "",
                    reporting_boundary: ""
                  }
                },
                isCreateData: true
              }).then(() => {
                setLoading(false);
                setSebimodal(false);
                history.push(`/admin/report/brsr/${year}`);
                draftSebiData();
              });
            }}
          >
            {loading
              ? (
                <div
                  className="spinner-border text-white"
                  role="status"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )
              : (
                "Create New Report"
              )}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    draftReport: state.NewReportReducer.draftSebiForm
  };
}
const mapDispatchToProps = { addSebiData, getSebiForm, draftSebiData, updateSebiData };

export default connect(mapStateToProps, mapDispatchToProps)(SebiYearModal);