import axios from "axios";
const token = localStorage.getItem("accessToken");

//apply base url for axios
const API_URL = process.env.REACT_APP_API_PATH;
// const authUser = JSON.parse(localStorage.getItem('authUser'))

// const token = authUser && authUser.userData.token ? authUser.userData.token : null
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`
  }
});

// axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export async function get (url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post (url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put (url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del (url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
