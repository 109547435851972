import React, { useEffect, useState, useMemo } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Field, FieldArray, Formik } from "formik";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Form, Button } from "reactstrap";
import { Country, State, City } from "country-state-city";

//Import from other Component
import { ROLES } from "common/constants";
// import { country_list } from "./Country";
import Loading from "components/Common/Loading";
import IsRoleAuth from "pages/Authentication/IsRoleAuth";
import { AxiosGet, AxiosPost } from "common/axiosService";
import TableContainer from "../../../components/Common/TableContainer";
import { Countrry, Statte, Cityy, AddressType, Address, Pincode } from "./LocationsCol";

const LocationsList = () => {
  //meta title
  document.title = "Locations | Greenalytics";

  const [details, setDetails] = useState([{
    country: "", state: "", city: ""
  }]);

  const [modal, setModal] = useState(false);
  const [location, setLocation] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showloader, setShowLoader] = useState(true);
  const [locationsList, setLocationsList] = useState([]);
  const [isItemsChanged, setIsItemsChanged] = useState(false);
  const [data, setData] = useState({
    locations: [
      { addressType: "", country: "", state: "", city: "", pincode: "", address: "", isCorporateAddress: false }]
  });

  const stepFourValidationSchema = Yup.object({
    locations: Yup.array().of(
      Yup.object().shape(
        {
          addressType: Yup.string().when(["address", "city", "country", "pincode", "state"], {
            is: (address, city, country, pincode, state) => address||city || country||pincode||state,
            then: Yup.string().required("Please Add FacilityType")
          }),
          country: Yup.string().when(["address", "addressType", "city", "pincode", "state"], {
            is: (address, addressType, city, pincode, state) => address||addressType||city ||pincode||state,
            then: Yup.string().required("Please Add Country")
          }),
          city: Yup.string().when(["address", "addressType", "country", "pincode", "state"], {
            is: (address, addressType, country, pincode, state) => address||addressType || country||pincode||state,
            then: Yup.string().required("Please Add City")
          }),
          state: Yup.string().when(["address", "addressType", "city", "country", "pincode"], {
            is: (address, addressType, city, country, pincode) => address||addressType||city || country||pincode,
            then: Yup.string().required("Please Add State")
          }),
          address: Yup.string().when(["addressType", "city", "country", "pincode", "state"], {
            is: (addressType, city, country, pincode, state) => addressType||city || country||pincode||state,
            then: Yup.string().required("Please Add Address")
          }),
          pincode: Yup.string().when(["address", "addressType", "city", "country", "state"], {
            is: (address, addressType, city, country, state) => address||addressType||city || country||state,
            then: Yup.string().required("Please Add Pincode")
          })
        },
        [
          ["addressType", "country"], ["addressType", "city"], ["addressType", "pincode"], ["addressType", "address"], ["addressType", "state"],
          ["country", "city"], ["country", "pincode"], ["country", "address"], ["country", "state"],
          ["address", "city"], ["address", "pincode"], ["address", "state"],
          ["state", "city"], ["state", "pincode"],
          ["city", "pincode"]
        ]
      )
    )
  });

  const apipath = process.env.REACT_APP_ADMIN_API_PATH;

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  const handleAdminuserClick = arg => {
    const location = arg;
    setLocation(arg);
    const countryCode = Country.getAllCountries().find(item => item.name === location?.country)?.isoCode;
    const stateCode = State.getStatesOfCountry(countryCode).find(item => item.name === location?.state)?.isoCode;
    const cityCode = City.getCitiesOfState(countryCode, stateCode).find(item => item.name === location?.state)?.isoCode;
    setDetails([{ country: countryCode, state: stateCode, city: cityCode }]);
    setData({
      locations: [
        {
          addressType: location.addressType,
          country: location.country,
          state: location.state,
          city: location.city,
          pincode: location.pincode,
          address: location.address,
          isCorporateAddress: false
        }
      ]
    });
    setIsEdit(true);
    toggle();
  };

  const handleAdminuserClicks = () => {
    setDetails([{
      country: "", state: "", city: ""
    }]);
    setData({
      locations: [
        { addressType: "", country: "", state: "", city: "", pincode: "", address: "", isCorporateAddress: false }]
    });
    setIsEdit(false);
    toggle();
  };

  // Customber Column
  const columns = useMemo(
    () => [
      { Header: "Country", accessor: "country", filterable: true, Cell: cellProps => <Countrry {...cellProps} /> },
      { Header: "State", accessor: "state", filterable: true, Cell: cellProps => <Statte {...cellProps} /> },
      { Header: "City", accessor: "city", filterable: true, Cell: cellProps => <Cityy {...cellProps} /> },
      { Header: "FacilityType", accessor: "addressType", filterable: true, Cell: cellProps => <AddressType {...cellProps} /> },
      { Header: "Address", accessor: "address", filterable: true, Cell: cellProps => <Address {...cellProps} /> },
      { Header: "Pincode", accessor: "pincode", filterable: true, Cell: cellProps => <Pincode {...cellProps} /> },
      {
        Header: "Action",
        Cell: cellProps => (
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="d-flex gap-3"
          >
            <Link
              to="#"
              className="text-success"
              onClick={() => {
                const locationData = cellProps.row.original;
                handleAdminuserClick(locationData);
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>

            <div className="form-check form-switch">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitch2"
                checked={cellProps.row.original.isActive}
                onClick={e => editGeneral(e.target, cellProps.row.original._id) }
                onChange={e => editGeneral(e, cellProps.row.original._id) }
              />
            </div>
          </div>
        )
      }
    ],
    []
  );
  const viewOnlycolumns = useMemo(
    () => [
      { Header: "Country", accessor: "country", filterable: true, Cell: cellProps => <Countrry {...cellProps} /> },
      { Header: "State", accessor: "state", filterable: true, Cell: cellProps => <Statte {...cellProps} /> },
      { Header: "City", accessor: "city", filterable: true, Cell: cellProps => <Cityy {...cellProps} /> },
      { Header: "FacilityType", accessor: "addressType", filterable: true, Cell: cellProps => <AddressType {...cellProps} /> },
      { Header: "Address", accessor: "address", filterable: true, Cell: cellProps => <Address {...cellProps} /> },
      { Header: "Pincode", accessor: "pincode", filterable: true, Cell: cellProps => <Pincode {...cellProps} /> }
    ],
    []
  );
  const address = ["Sales Office", "Warehouse", "Branch Office", "Corporate Office", "Manufacturing Unit", "Other"];
  const withaoutaddress = ["Sales Office", "Warehouse", "Branch Office", "Manufacturing Unit", "Other"];

  const session = JSON.parse(localStorage.getItem("authUser"));
  const companyId = session.userData.user.companyId
    ? session.userData.user.companyId._id
    : "";
    /*------------------------------------- */

  //Get all Locations list

  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/locations/${companyId}`);
        if (response && response.statusCode === 200) {
          setLocationsList(response.data);
        }
        setShowLoader(false);
      } catch (error) {
        setShowLoader(false);
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }

    })();
  }, [isItemsChanged]);

  /*------------------------------------- */

  /*------------------------------------- */
  // Add new location
  const handleSubmit = async (values) => {
    try {
      const response = await AxiosPost(`${apipath}/add-location`, values );
      if (response && response.statusCode === 200) {
        setModal(false);
        setIsItemsChanged(prev => !prev);
        toast.success(`${response.message}`, {
          autoClose: 2000
        });
      }
    }catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }

  };
    /*------------------------------------- */

  /*------------------------------------- */

  // Update a location data
  const handleUpdateSubmit = async (values) => {
    setIsDisabled(true);
    const params = {
      ...location,
      addressType: values.locations[0].addressType,
      country: values.locations[0].country,
      state: values.locations[0].state,
      city: values.locations[0].city,
      pincode: values.locations[0].pincode,
      address: values.locations[0].address,
      isCorporateAddress: values.locations[0].isCorporateAddress
    };
    try {
      const response = await AxiosPost(`${apipath}/update-location/${location._id}`, params);
      if (response && response.statusCode === 200) {
        setModal(false);
        setIsItemsChanged(!isItemsChanged);
        toast.success(`${response.message}`, {
          autoClose: 2000
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
    setIsDisabled(false);
  };

  /*------------------------------------- */

  /*------------------------------------- */

  //changing location status
  function editGeneral (el, id) {
    let customtext;
    if (!el.checked) {
      customtext = "Are you sure you want to Activate this company";
    } else {
      customtext = "Are you sure you want to Deactivate this company";
    }
    Swal.fire({
      text: customtext,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      cancelButtonText: "No"
    })
      .then(result => {
        if (result.isConfirmed) {
          el.checked ? (el.checked = true) : (el.checked = false);
          changeStatus(id);
        }
        if (!result.isConfirmed) {
          el.checked ? (el.checked = true) : (el.checked = false);
        }
      })
      .catch(err => console.log(err));
  }

  const changeStatus = async (id) => {
    try {
      const response = await AxiosPost(`${apipath}/delete-location/${id}`, {});
      if (response && response.statusCode === 200) {
        setIsItemsChanged(prev => !prev);
        toast.success(`${response.message}`, {
          autoClose: 2000
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }

  };
  const removeEmptyValue = async (values, errors, setErrors) => {
    setModal(false);
    setIsDisabled(true);
    let locationData = values.locations.filter((location) => {
      if (location.addressType == "" && location.address == "" && location.country == "" && location.state == "" && location.city == "" && location.pincode == "" ) {
        return false;
      } else {
        return true;
      }
    });
    if (Object.keys(errors).length === 0 && locationData.length > 0) {
      if (isEdit) {
        handleUpdateSubmit({ locations: locationData });
      } else {
        handleSubmit({ locations: locationData });
      }
    } else {
      if (Object.keys(errors).length === 0) {
        setErrors({
          locations: [{
            "addressType": "Please Add FacilityType",
            "country": "Please Add Country",
            "city": "Please Add City",
            "state": "Please Add State",
            "address": "Please Add Address",
            "pincode": "Please Add Pincode"
          }]
        });
      }
    }
    setIsDisabled(false);
  };
  /*------------------------------------- */

  const getIosname = (item, mainIndex) =>{
    const index = item.target.selectedIndex;
    const el = item.target.childNodes[index];
    const option = el.getAttribute("id");
    const temp = details.map((value, i) => {
      if (mainIndex === i ) {
        value.country = option;
      }
      return value;
    });
    setDetails(temp);
  };
  const getIosnamestate = (item, mainIndex) =>{
    const index = item.target.selectedIndex;
    const el = item.target.childNodes[index];
    const option = el.getAttribute("id");
    const temp = details.map((value, i) => {
      if (mainIndex === i ) {
        value.state = option;
      }
      return value;
    });
    setDetails(temp);
  };
  return showloader ?
    <Loading />
    :
    <React.Fragment>
      <IsRoleAuth />
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Greenalytics" breadcrumbItem="Superadmin Locations" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={
                      JSON.parse(localStorage.getItem("authUser")) &&JSON.parse(localStorage.getItem("authUser")).userData.user.role === ROLES.ADMIN
                        ? columns
                        : viewOnlycolumns
                    }
                    data={locationsList}
                    isGlobalFilter={true}
                    isAddCustList={
                      JSON.parse(localStorage.getItem("authUser")) &&
                      JSON.parse(localStorage.getItem("authUser")).userData.user.role === ROLES.ADMIN
                        ? true
                        : false
                    }
                    handleAdminuserClick={handleAdminuserClicks}
                    customPageSize={10}
                    isCustomPageSize={true}
                    isAddNewLocation={true}
                    isLocationMessage={true}
                    className="custom-header-css"
                  />

                  {/* backdrop={"static"} */}
                  <Modal isOpen={modal} toggle={toggle} className="modal-xl" >
                    {JSON.parse(localStorage.getItem("authUser")) &&
                    JSON.parse(localStorage.getItem("authUser")).userData.user.role === ROLES.STAKEHOLDERS && (
                      <ModalHeader toggle={toggle} tag="h4">
                        {isEdit ? "Edit Location" : "Add New Location"}
                      </ModalHeader>
                    )}
                    <ModalBody>
                      <Formik
                        validationSchema={stepFourValidationSchema}
                        initialValues={data}
                      // enableReinitialize={true}
                      // onSubmit={handleSubmit}
                      >
                        {({ values, errors, setErrors, setFieldValue }) => (
                          <Form>
                            <FieldArray name="locations">
                              {({ remove, push }) => (
                                <>
                                  <Row>
                                    {values.locations.length > 0 && values.locations.map((location, index) => {
                                      const locationsValues = (values.locations?.length && values.locations[index]) || {};
                                      const locationsErrors = (errors.locations?.length && errors.locations[index]) || {};
                                      return (
                                        <Col
                                          xs="12" lg="12" key={index} className="bg-white rounded mb-3 d-block position-relative"
                                        >
                                          <div
                                            className="shadow border p-3 d-block inner-div" count={index + 1}
                                          >
                                            <div data-repeater-list="group-a">
                                              <Row data-repeater-item>
                                                <Col lg="6">
                                                  <Field
                                                    as="select" name={`locations[${index}].addressType`}
                                                    className={ "form-select" + (locationsErrors.addressType ? " is-invalid" : "")}
                                                  >
                                                    <option value="">Select Facility type</option>

                                                    {values.locations.some(i=> i.addressType === "Corporate Office")
                                                      ? locationsValues.addressType === "Corporate Office"
                                                        ? address.map((item, i) => <option key={i} value={item}> {item} </option>)
                                                        : withaoutaddress.map((item, i) => <option key={i} value={item}> {item} </option>)
                                                      : address.map((item, i) => <option key={i} value={item}> {item} </option>)}

                                                  </Field>
                                                  {
                                                    Object.values(locationsValues).some(i => i!== "")
                                                      ?<div className="invalid-feedback">Please Facility type</div>
                                                      : locationsErrors.addressType &&
                                                  <div className="invalid-feedback">Please Facility type</div>
                                                  }
                                                </Col>

                                                <Col lg="6" className="mb-3">
                                                  <select
                                                    name={`locations[${index}].country`}
                                                    className={"form-select"+(locationsErrors.country ? " is-invalid" : "")}
                                                    value={values.locations[index].country }
                                                    onChange={ (val) => {
                                                      const el = val.target.childNodes[val.target.selectedIndex];
                                                      const option = el.getAttribute("id");
                                                      if (State.getStatesOfCountry(option).length > 0) {
                                                        setFieldValue(`locations[${index}].state`, "" );
                                                        setFieldValue(`locations[${index}].city`, "" );
                                                      } else {
                                                        setFieldValue(`locations[${index}].state`, " " );
                                                        setFieldValue(`locations[${index}].city`, " " );
                                                      }
                                                      setFieldValue(`locations[${index}].country`, val.target.value );
                                                      getIosname(val, index);
                                                    }
                                                    }
                                                  >
                                                    <option>Select Country</option>
                                                    {
                                                      Country.getAllCountries()?.map((item, i) => (
                                                        <option
                                                          id={item.isoCode}
                                                          value={item.name} key={i}>
                                                          {item.name}
                                                        </option>
                                                      ))}
                                                  </select>

                                                  {locationsErrors.country &&<div className="invalid-feedback">Please Enter Country</div>}
                                                </Col>

                                                <Col lg="6" className="mb-3">
                                                  <select
                                                    name={`locations[${index}].state`}
                                                    className={"form-select"+(locationsErrors.state ? " is-invalid": "")}
                                                    value={values.locations[index]?.state}
                                                    onChange={(val) => {
                                                      setFieldValue(`locations[${index}].city`, "" );
                                                      setFieldValue(`locations[${index}].state`, val.target.value );
                                                      getIosnamestate(val, index);
                                                    }
                                                    }
                                                  >
                                                    <option>Select state</option>
                                                    {
                                                      State.getStatesOfCountry(details[index]?.country)?.map((item, i) => (<option id={item.isoCode} value={item.name} key={i}>
                                                        {item.name}
                                                      </option>))}
                                                  </select>
                                                  {locationsErrors.state &&<div className="invalid-feedback">Please Enter State</div>}
                                                </Col>

                                                <Col lg="6" className="mb-3">
                                                  <Field
                                                    as="select"
                                                    name={`locations[${index}].city`}
                                                    className={"form-select" + (locationsErrors.city ? " is-invalid" : "")}
                                                    value={values.locations[index].city}
                                                  >
                                                    <option>Select City</option>
                                                    {
                                                      City.getCitiesOfState(details[index]?.country, details[index]?.state)?.map((item, i) => (
                                                        <option value={item.name} key={i}>
                                                          {item.name}
                                                        </option>
                                                      ))}
                                                  </Field>

                                                  {locationsErrors.city &&<div className="invalid-feedback">Please Enter City</div>}
                                                </Col>

                                                <Col lg="6" className="mb-3">
                                                  <Field
                                                    type="number"
                                                    onWheel={(e) => e.target.blur()}
                                                    name={`locations[${index}].pincode`}
                                                    placeholder="Enter Pincode"
                                                    className={"form-control" + (locationsErrors.pincode ? " is-invalid" : "")}
                                                  />
                                                  {locationsErrors.pincode &&<div className="invalid-feedback">Please Enter Pincode</div>}
                                                </Col>

                                                <Col lg="6" className="mb-0">
                                                  <Field
                                                    as="textarea"
                                                    name={`locations[${index}].address`}
                                                    placeholder="Enter Address"
                                                    className={"form-control" +(locationsErrors.address ? " is-invalid" : "")}
                                                  />
                                                  {locationsErrors.address &&<div className="invalid-feedback">Please Enter Address</div>}
                                                </Col>
                                              </Row>
                                            </div>

                                            {values.locations.length > 1 && <div className="mb-3 close-btn">
                                              {" "}
                                              <i
                                                className="bx bxs-x-circle mdi-close-btn"
                                                onClick={() => {
                                                  remove(index);
                                                  const newr = details.filter((item, i) => index !==i);
                                                  setDetails(newr);
                                                }}
                                              />
                                            </div>}
                                          </div>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                  {!isEdit && <Button
                                    onClick={() => {
                                      setDetails([...details, {
                                        country: "", state: "", city: ""
                                      }]);
                                      push({ addressType: "", address: "", country: "", state: "", city: "", pincode: "" });
                                    } }
                                    color="default"
                                    className="mt-3 mt-lg-0 btn btn-outline-primary btn-sm"
                                  >
                                + Add More Locations{" "}
                                  </Button>}{" "}
                                  <Row className="mt-3 next-prev-btn">
                                    <Col>
                                      <button
                                        className="btn btn-primary btn-block"
                                        type="button"
                                        disabled={isDisabled}
                                        onClick={() => removeEmptyValue(values, errors, setErrors)}
                                      >
                                      Submit
                                      </button>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </FieldArray>
                          </Form>
                        )}
                      </Formik>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>;
};

export default LocationsList;
