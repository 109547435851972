import React from "react";

function TextArea (props) {
  const { name, type = "text", isDisabled = false, value, onChange } = props;
  return <textarea
    onChange={onChange}
    value={value}
    name={name}
    type={type}
    disabled={isDisabled}
    className="form-control"
  />;
}

export default TextArea;