import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { Card, CardBody, CardTitle, Col, Container, Label, Row } from "reactstrap";
import { AxiosGet } from "common/axiosService";
import { Action, Date, Role, Name } from "../TimeLineLog/CryptoCol";
import TableContainer from "components/Common/TableContainer";
import { Link } from "react-router-dom";
import { get } from "lodash";

const YourCompany = () =>{
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const [companyTimeline, setCompanyTimeline] = useState({});

  const session = JSON.parse(localStorage.getItem("authUser"))?.userData.user.companyId;

  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/specific-timeline-logs/company`);
        if (response && response.statusCode === 200) {
          setCompanyTimeline(response.data);
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, []);

  const data = companyTimeline.length > 0 ? companyTimeline : [];
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "",
        filterable: true,
        Cell: (row) => <div>{Number(row.row.id) + 1}</div>
      },
      {
        Header: "Date",
        accessor: "modifiedAt",
        filterable: true,
        Cell: cellProps => <Date {...cellProps} />
      },
      {
        Header: "Role",
        accessor: "performerId.role",
        filterable: true,
        Cell: cellProps => <Role {...cellProps} />
      },
      {
        Header: "Email",
        accessor: "performerId.email",
        filterable: true,
        Cell: cellProps => <Name {...cellProps} />
      },
      {
        Header: "Action",
        accessor: "actionDone",
        filterable: true,
        Cell: cellProps => <Action {...cellProps} />
      }
    ],
    []
  );

  let docToPrint = React.createRef();
  return(
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="10">
              <Card>
                <CardBody>
                  <h3>Company Details</h3>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row ref={docToPrint}>
            <Col lg="3">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={9}>
                      <CardTitle className="mb-4">Current Details</CardTitle></Col>
                    <Col >
                      <Link to={"/admin/profile"}>
                        {/* <Button color="light"
                          className="btn btn-soft" style={{ float: "right" }}>Edit</Button> */}
                      </Link>
                    </Col>
                  </Row>
                  <div>
                    <Label>Name</Label>
                    <p className="text-muted border-b">
                      {session.companyName}
                    </p>
                    <Label>URL</Label>
                    <p className="text-muted border-b">{session.personalUrl}</p>
                    <Label>CIN No.</Label>
                    <p className="text-muted border-b">{Number(get(session, "cin", 0)).toLocaleString("en-IN")}</p>
                    <Label>Corporate Address</Label>
                    <p className="text-muted border-b">{session.corporataeAddress}</p>
                    <Label>Phone No.</Label>
                    <p className="text-muted border-b">{session.phoneNumber}</p>
                    <Label>Year Of Incorporation</Label>
                    <p className="text-muted border-b">{session.yearOfIncorporation} Year </p>
                    <Label>Paid Up Capital</Label>
                    <p className="text-muted border-b">{session.currency} {" "}{session.paidUpCapital}</p>
                    <Label>Authorized Capital</Label>
                    <p className="text-muted border-b">{session.currency} {" "}{session.authorizedCapital}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="9">
              <Card>
                <CardBody>
                  <div id="overview-chart" dir="ltr">
                    <TableContainer
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isCustomPageSize={true}
                      customPageSize={7}
                      isActiveMessage={true}
                      // customPageSizeOptions={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default YourCompany;
