import React from "react";
import { useState } from "react";
import { Container, Row } from "reactstrap";
import { toast } from "react-toastify";

//import component
import Cards from "./Cards";
import Activity from "./Activity";
import Loactions from "./Locations";
import ActiveUsers from "./GetUsers";
import ActivityBRSR from "./ActivityBRSR";

const index = () => {
  //meta title
  document.title = "Dashboard";

  const session = JSON.parse(localStorage.getItem("authUser"))?.userData.user.companyId;
  const [loading, setLoading] = useState({
    location: false, user: false, logs: false, brsrtimeline: false
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h5 className="mb-3"><b>Welcome, {session?.companyName}</b></h5>
          <Cards toast={toast}/>
          <Row>
            <Loactions loading={loading} setLoading={setLoading} toast={toast}/>
            <ActiveUsers loading={loading} setLoading={setLoading} toast={toast}/>
          </Row>
          <Row>
            <ActivityBRSR loading={loading} setLoading={setLoading} toast={toast}/>
            <Activity loading={loading} setLoading={setLoading} toast={toast}/>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default index;
