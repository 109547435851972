import React from "react";

const UserName = cell => cell.cell && <span style={{ textTransform: "uppercase" }}>{cell.cell.row.original.name}</span>;

const Email = cell => cell.cell && cell.cell.row.original.email.replace(
  /(\w{2})[\w.-]+@([\w.]+\w)/,
  "$1*****@$2"
);

const Role = cell => cell.cell ? cell.cell.row.original.role : "";

const Status = cell => cell.cell && <h5>
  {
    cell.cell.row.original.status === "Invited" ?
      <span className="badge  bg-warning text-dark">Not Verified</span>
      :<span className="badge bg-success">Verified</span>
  }
</h5>;

export { UserName, Email, Role, Status };
