import React, { useState } from "react";
import { Row, Col, Card, Modal } from "reactstrap";
import { uniqBy } from "lodash";
import Dropzone from "react-dropzone";
import { AxiosPost } from "common/axiosService";
import { toast } from "react-toastify";
const FormUpload = ({ tog_backdrop, modal_backdrop, setmodal_backdrop, fileData, setFileDataChange }) => {
  const [selectedFiles, setselectedFiles] = useState([]);
  const [btn, setBtn] = useState(false);
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;

  function handleAcceptedFiles (files) {
    files.map(file =>
      Object.assign(file, {
        accept: "application/pdf",
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    );
    let non_duplicated_data = uniqBy(files, "path");
    setselectedFiles(non_duplicated_data);
  }

  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const fileUpload = async () => {
    setBtn(true);
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("file", file, file.name);
    });
    formData.append("year", fileData.year);
    formData.append("section", fileData.section);
    formData.append("subSection", fileData.subSection);
    formData.append("keyName", fileData.keyName);
    try {
      const response = await AxiosPost(`${apipath}/upload-brsr-document`, formData);
      if(response && response.statusCode === 200){
        setBtn(false);
        setselectedFiles([]);
        toast.success(`${response.message}`, {
          autoClose: 2000
        });
        tog_backdrop();
        setFileDataChange(prev => !prev);
      }
      else{
        setBtn(false);
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  const remove = file => {
    const newFiles = [...selectedFiles];
    newFiles.splice(file, 1);
    setselectedFiles(newFiles);
  };

  return (
    <React.Fragment>
      <Modal
        className="center-modal"
        size="lg" isOpen={modal_backdrop}
        toggle={() => { tog_backdrop(); }}
        backdrop={"static"}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">Attach Supporting Documents</h5>
          <button type="button" className="btn-close"
            disabled={btn}
            onClick={() => {
              setmodal_backdrop(false);
              setselectedFiles([]);
            }} aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <Dropzone
            multiple
            onDrop={acceptedFiles => {
              handleAcceptedFiles([...selectedFiles, ...acceptedFiles]);
            }}
            accept={{
              "text/csv": [".csv"],
              "image/*": [".jpeg", ".jpg", ".png"],
              "application/*": [".xls"],
              "application/pdf": [".pdf"]
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div
                  className="dz-message needsclick mt-2"
                  {...getRootProps()}
                >
                  <input {...getInputProps()}/>
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews mt-3" id="file-previews">
            {selectedFiles.map((f, i) => (
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col lg={6}>
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={f.name}
                        src={f.preview}
                      />
                    </Col>
                    <Col lg={5}>
                      <span>{f.name}</span>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                    <Col>
                      <button type="button" className="btn-close" onClick={() => remove(i)}></button>
                    </Col>
                  </Row>
                </div>
              </Card>
            ))}
          </div>
        </div>

        <div className="modal-footer">
          <button type="button" className="btn btn-light"
            disabled={btn}
            onClick={() => {
              setmodal_backdrop(false);
              setselectedFiles([]);
            }}>Close</button>
          <button disabled={btn} type="button" className="btn btn-primary" onClick={fileUpload}>Upload</button>
        </div>

      </Modal>
    </React.Fragment>
  );
};

export default FormUpload;
