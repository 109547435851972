import {
  REGISTER_COMPANY_SUCCESSFUL,
  REGISTER_COMPANY_FAILED,
  REGISTER_COMPANY,
  REGISTER_PROGRESS,
  RESET_REGISTRATION
} from "./actionTypes";

export const registerCompany = company => ({
  type: REGISTER_COMPANY,
  payload: { company }
});

export const registerCompanySuccessful = company => ({
  type: REGISTER_COMPANY_SUCCESSFUL,
  payload: company
});

export const registerCompanyFailed = company => ({
  type: REGISTER_COMPANY_FAILED,
  payload: company
});

export const registerProgress = num => ({
  type: REGISTER_PROGRESS,
  payload: num
});

export const resetRegistration = () => ({
  type: RESET_REGISTRATION
});
