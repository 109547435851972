import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Formik, FieldArray, Field } from "formik";
import { Modal, Card, Col, DropdownToggle, DropdownMenu, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import ModalSection from "../ModalSection";
import FormUpload from "../Modalfileupload";
import TextArea from "components/Inputs/TextArea";
import RadioButton from "components/Inputs/RadioButton";
import { AxiosGet, AxiosPost } from "common/axiosService";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const Principle4 = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionC.principle_4;
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, onSectionChange, oldData, getFile, filesData } = props;

  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);

  const [filedatachange, setFileDataChange] = useState(true);

  const [modal_center, setmodal_center] = useState(false);
  const [modalData, setModalData] = useState({ name: "", value: "", type: "", title: "" });

  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionC", subSection: "principle_4", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() =>{
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_4"
      });
    }
  }, [newReport, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionC",
      "subSection": "principle_4"
    });
  }, [filedatachange]);

  function tog_center () {
    setmodal_center(!modal_center);
  }

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, principle_4: data });
    setData({ ...data });
  };

  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_4",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionC",
          "subSection": "principle_4"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  return (
    <React.Fragment>
      <Modal className="center-modal"
        size="lg" isOpen={modal_center}
        toggle={() => { tog_center(); }}
        centered
        autoFocus={false}
        backdrop={"static"}
      >
        <ModalSection modalData={modalData} setModalData={setModalData} state={data} setmodal_center={setmodal_center}/>
        <div className="modal-footer">
          <button
            onClick={(e) => {
              setmodal_center(false);
              e.target.blur();
              setModalData({ name: "", value: "", type: "", title: "" });
            }}
            className="btn btn-primary"
          >Update</button>
        </div>
      </Modal>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <Row>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h5 className="mt-2"><b>PRINCIPLE 4: Businesses should respect the interests of and be responsive to all its stakeholders</b> </h5>
              <p className="txt-decoration mt-2"><b>Essential Indicators</b></p>
              <Formik initialValues={data}>
                {({ values, setFieldValue }) => (
                  <form
                    onChange={onHandleChange}
                    onSubmit={(e) => {
                      e.preventDefault();
                      _set(sebiCurrentData, "sectionC.principle_4", data);
                      updateSebiData(sebiCurrentData);
                      draftSebiData({ ...draftReport, principle_4: false });
                    }}>
                    <p className="mt-2">1.	Describe the processes for identifying key stakeholder groups of the entity.</p>
                    <TextArea
                      name="describe_the_processes_for_identifying_key"
                      value={_get(data, "describe_the_processes_for_identifying_key", "")}
                      onChange={onHandleChange}
                    />

                    <p className="mt-2">2. List stakeholder groups identified as key for your entity and the frequency of engagement with each stakeholder group.</p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="frequency_of_engagement">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th width="15%">Stakeholder Group</th>
                                <th width="15%">Whether identified as Vulnerable & Marginalized Group</th>
                                <th>Channels of communication</th>
                                <th>Frequency of engagement</th>
                                <th>Purpose and scope of engagement including key topics and concerns raised during such engagement</th>
                                <th width="3%" onClick={() => {
                                  push({
                                    stakeholder_group: "",
                                    identified_vulnerable: "",
                                    channels_of_communication: "",
                                    frequency: "",
                                    purpose_and_scope: ""
                                  });
                                  setData({
                                    ...data, frequency_of_engagement: [
                                      ...data.frequency_of_engagement,
                                      {
                                        stakeholder_group: "",
                                        identified_vulnerable: "",
                                        channels_of_communication: "",
                                        frequency: "",
                                        purpose_and_scope: ""
                                      }
                                    ]
                                  });
                                }
                                }><i className="bx bxs-plus-circle mdi-add-btn" /></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.frequency_of_engagement?.length > 0 && values?.frequency_of_engagement?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">
                                    <Field
                                      as="select" className="form-select"
                                      name={`frequency_of_engagement[${index}].stakeholder_group`}
                                      value={values.frequency_of_engagement[index].stakeholder_group}
                                    >
                                      <option value="">Select Option</option>
                                      <option value="Communities">Communities</option>
                                      <option value="Investors">Investors (other than shareholders)</option>
                                      <option value="Shareholders">Shareholders</option>
                                      <option value="Employees and workers">Employees and workers</option>
                                      <option value="Customers">Customers</option>
                                      <option value="Value Chain Partners">Value Chain Partners</option>
                                      <option value="Other">Other</option>
                                    </Field>
                                  </td>

                                  <td className="text-align" role="group" aria-labelledby="my-radio-group">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="Yes"
                                        name={`frequency_of_engagement[${index}].identified_vulnerable`}
                                        id={`radioBtn34${index}`}
                                        onClick={(e) => {
                                          setFieldValue(`frequency_of_engagement[${index}].identified_vulnerable`, e.target.value);
                                          onHandleChange(e);
                                        }}
                                        onChange={() => {}}
                                        checked={values?.frequency_of_engagement[index]?.identified_vulnerable === "Yes"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn34${index}`}>Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="No"
                                        name={`frequency_of_engagement[${index}].identified_vulnerable`}
                                        id={`radioBtn33${index}`}
                                        onClick={(e) => {
                                          setFieldValue(`frequency_of_engagement[${index}].identified_vulnerable`, e.target.value);
                                          onHandleChange(e);
                                        }}
                                        onChange={() => {}}
                                        checked={values?.frequency_of_engagement[index]?.identified_vulnerable === "No"}

                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn33${index}`}>No</label>
                                    </div>
                                  </td>

                                  <td className="text-align">
                                    <Field
                                      as="select" className="form-select"
                                      name={`frequency_of_engagement[${index}].channels_of_communication`}
                                      value={values?.frequency_of_engagement[index]?.channels_of_communication}
                                    >
                                      <option value="">Select Option</option>
                                      <option value="Email">Email</option>
                                      <option value="SMS">SMS</option>
                                      <option value="Newspaper">Newspaper</option>
                                      <option value="Pamphlates">Pamphlates</option>
                                      <option value="Advertisement">Advertisement</option>
                                      <option value="Community Meetings">Community Meetings</option>
                                      <option value="Notice Board">Notice Board</option>
                                      <option value="Website">Website</option>
                                      <option value="Other">Other</option>
                                    </Field>
                                  </td>

                                  <td className="text-align">
                                    <Field
                                      as="select" className="form-select"
                                      name={`frequency_of_engagement[${index}].frequency`}
                                      value={values?.frequency_of_engagement[index]?.frequency}
                                    >
                                      <option value="">Select Option</option>
                                      <option value="Annually">Annually</option>
                                      <option value="Half yearly">Half yearly</option>
                                      <option value="Quarterly">Quarterly</option>
                                      <option value="Others">Others</option>
                                    </Field>
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      as="textarea"
                                      name={`frequency_of_engagement[${index}].purpose_and_scope`}
                                      placeholder="Purpose and scope"
                                      className="form-control"
                                      value={data.frequency_of_engagement[index].purpose_and_scope}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Purpose and scope of engagement including key topics and concerns raised during such engagement",
                                          name: `frequency_of_engagement[${index}].purpose_and_scope`,
                                          value: _get(data, `frequency_of_engagement[${index}].purpose_and_scope`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>

                                  {values?.frequency_of_engagement?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.frequency_of_engagement.filter((item, i) => index !== i);
                                    draftSebiData({ ...draftReport, principle_4: { ...data, frequency_of_engagement: newr } });
                                    setData({ ...data, frequency_of_engagement: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn" /></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.list_stakeholder_groups?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "list_stakeholder_groups" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.list_stakeholder_groups?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "list_stakeholder_groups" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "list_stakeholder_groups" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">1. Provide the processes for consultation between stakeholders and the Board on economic, environmental, and social
                    topics or if consultation is delegated, how is feedback from such consultations provided to the Board.</p>
                    <TextArea
                      name="processes_for_consultation"
                      value={_get(data, "processes_for_consultation", "")}
                      onChange={onHandleChange}
                    />

                    <p className="txt-decoration mt-2"><b>Leadership Indicators</b></p>

                    <p className="mt-2">2. Whether stakeholder consultation is used to support the identification and management of environmental, and social topics
                    (Yes / No).</p>
                    <RadioButton id="radio_123button" className="dis-right ml-10"
                      name="whether_stakeholder_consultation"
                      value={_get(data, "whether_stakeholder_consultation", "")}
                      onChange={onHandleChange}
                    />

                    <p className="mt-2">
                    If so, provide details of instances as to how the inputs received from stakeholders on these topics were incorporated into policies and activities of the entity.
                    </p>
                    <TextArea
                      name="how_the_inputs_received_from_stakeholders"
                      value={_get(data, "how_the_inputs_received_from_stakeholders", "")}
                      onChange={onHandleChange}
                    />

                    <p className="mt-2">3. Provide details of instances of engagement with, and actions taken to, address the concerns of vulnerable/ marginalized stakeholder groups.</p>
                    <TextArea
                      name="address_the_concerns_of_vulnerable"
                      value={_get(data, "address_the_concerns_of_vulnerable", "")}
                      onChange={onHandleChange}
                    />
                    <hr />
                    <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => onSectionChange(11)}>Previous</button>{" "}
                      </div>
                      <div>
                        <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => onSectionChange(13)}>Next</button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>

  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionC?.principle_4,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.principle_4,
    filesData: state.NewReportReducer?.draftFiles?.principle_4
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(Principle4);
