import { PROFILE_ERROR, PROFILE_SUCCESS, IS_PROFILE_UPDATED, EDIT_PROFILE, RESET_PROFILE_FLAG } from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  data: {}
};

export const profileUpdate = (state = { isProfileUpdated: false }, action) => {
  switch (action.type) {
  case IS_PROFILE_UPDATED:
    state = { ...state, isProfileUpdated: !state.isProfileUpdated };
    break;
  default:
    state = { ...state };
    break;
  }
  return state;
};

const profile = (state = initialState, action) => {
  switch (action.type) {
  case EDIT_PROFILE:
    state = { ...state };
    break;
  case PROFILE_SUCCESS:
    state = { ...state, success: action.payload };
    break;
  case PROFILE_ERROR:
    state = { ...state, error: action.payload };
    break;
  case RESET_PROFILE_FLAG:
    state = { ...state, success: null };
    break;
  default:
    state = { ...state };
    break;
  }
  return state;
};

export default profile;
