import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Modal, Card, Col, DropdownToggle, DropdownMenu, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import ModalSection from "../ModalSection";
import FormUpload from "../Modalfileupload";
import TextArea from "components/Inputs/TextArea";
import TableInput from "components/Inputs/TableInput";
import RadioButton from "components/Inputs/RadioButton";
import { AxiosGet, AxiosPost } from "common/axiosService";
import TableTextArea from "components/Inputs/TableTextArea";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const Principle5 = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionC.principle_5;
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, employeeData, onSectionChange, previousData, prefillData, oldData, getFile, filesData } = props;
  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);

  const [filedatachange, setFileDataChange] = useState(true);

  const [modal_center, setmodal_center] = useState(false);
  const [modalData, setModalData] = useState({ name: "", value: "", type: "", title: "" });

  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionC", subSection: "principle_5", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect(() =>{
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_5"
      });
    }
  }, [newReport, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionC",
      "subSection": "principle_5"
    });
  }, [filedatachange]);

  const empPrefillCounting = (currentObj) => {
    ["workers", "employees", "differently_abled_employees", "differently_abled_workers"].forEach(item => {
      let totalMale = 0;
      let totalFemale = 0;
      let totalOther = 0;
      ["other_than_permanent", "permanent"].forEach(subItem=>{
        const male = Number(_get(currentObj, `${item}.${subItem}.male`, 0));
        const female = Number(_get(currentObj, `${item}.${subItem}.female`, 0));
        const other = Number(_get(currentObj, `${item}.${subItem}.other`, 0));
        totalMale += male;
        totalFemale += female;
        totalOther += other;
        const total = male + female + other;
        _set(employeeData, `${item}.${subItem}.total`, total);
        _set(employeeData, `${item}.${subItem}.malepercentage`, _calculatePercentage(male, total));
        _set(employeeData, `${item}.${subItem}.femalepercentage`, _calculatePercentage(female, total));
        _set(employeeData, `${item}.${subItem}.otherpercentage`, _calculatePercentage(other, total));
      });
      const permanent = Number(_get(currentObj, `${item}.permanent.total`, 0));
      const otherThanPermanent = Number(_get(currentObj, `${item}.other_than_permanent.total`, 0));
      _set(employeeData, `${item}.total.total`, permanent+otherThanPermanent);
      _set(employeeData, `${item}.total.male`, totalMale);
      _set(employeeData, `${item}.total.female`, totalFemale);
      _set(employeeData, `${item}.total.other`, totalOther);
      _set(employeeData, `${item}.total.malepercentage`, _calculatePercentage(totalMale, permanent+otherThanPermanent));
      _set(employeeData, `${item}.total.femalepercentage`, _calculatePercentage(totalFemale, permanent+otherThanPermanent));
      _set(employeeData, `${item}.total.otherpercentage`, _calculatePercentage(totalOther, permanent+otherThanPermanent));
    });
  };
  useEffect(() => {
    if (employeeData) {
      empPrefillCounting(employeeData);
      ["workers", "employees"].forEach(item => {
        ["other_than_permanent", "permanent"].forEach(subItem => {
          ["male", "female", "other", "total"].forEach(subInnerItem => {
            _set(data, `wages.${item}.${subItem}.${subInnerItem}.total`, _get(employeeData, `${item}.${subItem}.${subInnerItem}`, "" ));
          });
          _set(data, `trainings.human_rights.${item}.${subItem}.total`, _get(employeeData, `${item}.${subItem}.total`, "" ));
        });
        _set(data, `trainings.human_rights.${item}.total.total`, _get(employeeData, `${item}.total.total`, "" ));
      });
    }
  }, [employeeData]);
  useEffect(() =>{
    if (prefillData?.human_rights_complaints) {
      ["sexual_harrasment", "discrimination_at_workplace", "child_labour", "forced_or_involuntary_labour", "wages", "any_other"].forEach(item => {
        ["no_received", "no_resolved"].forEach(subItem => {
          const setData = _get(data, `human_rights_complaints.${item}.${subItem}`, "")
            ? _get(data, `human_rights_complaints.${item}.${subItem}`, "")
            : (_get(prefillData, `human_rights_complaints.${item}.employees.${subItem}`, "") + _get(prefillData, `human_rights_complaints.${item}.workers.${subItem}`, ""));
          _set(data, `human_rights_complaints.${item}.${subItem}`, setData);
        });
      });
    }

    if(prefillData?.trainings?.human_rights){
      ["workers", "employees"].forEach(item => {
        ["other_than_permanent", "permanent"].forEach(subItem => {
          _set(data, `trainings.human_rights.${item}.${subItem}.no_of_employees`,
            _get(data, `trainings.human_rights.${item}.${subItem}.no_of_employees`, "")
              ? Number(_get(data, `trainings.human_rights.${item}.${subItem}.no_of_employees`, ""))
              : Number(_get(prefillData, `trainings.human_rights.${item}.${subItem}`, "" )));
          _set(data, `trainings.human_rights.${item}.${subItem}.percentage`,
            _calculatePercentage(_get(data, `trainings.human_rights.${item}.${subItem}.no_of_employees`, "" ),
              _get(data, `trainings.human_rights.${item}.${subItem}.total`, "" )));
        });
        _set(data, `trainings.human_rights.${item}.total.no_of_employees`,
          _get(data, `trainings.human_rights.${item}.other_than_permanent.no_of_employees`, 0 )+
        _get(data, `trainings.human_rights.${item}.permanent.no_of_employees`, 0 ));
        _set(data, `trainings.human_rights.${item}.total.percentage`,
          _calculatePercentage(_get(data, `trainings.human_rights.${item}.total.no_of_employees`, "" ),
            _get(data, `trainings.human_rights.${item}.total.total`, "" )));
      });
    }
    if (previousData) {
      ["workers", "employees"].forEach(item => {
        ["other_than_permanent", "permanent", "total"].forEach(subItem => {
          _set(data, `trainings.human_rights.${item}.${subItem}.previous.total`, _get(previousData, `trainings.human_rights.${item}.${subItem}.total`, "" ));
          _set(data, `trainings.human_rights.${item}.${subItem}.previous.percentage`, _get(previousData, `trainings.human_rights.${item}.${subItem}.percentage`, "" ));
          _set(data, `trainings.human_rights.${item}.${subItem}.previous.no_of_employees`, _get(previousData, `trainings.human_rights.${item}.${subItem}.no_of_employees`, "" ));
          if (subItem !== "total") {
            ["male", "female", "other", "total"].forEach(subInnerItem => {
              _set(data, `wages.${item}.${subItem}.${subInnerItem}.previous.equal_percentage`, _get(previousData, `wages.${item}.${subItem}.${subInnerItem}.equal_percentage`, "" ));
              _set(data, `wages.${item}.${subItem}.${subInnerItem}.previous.equal_to_minimum_wage`, _get(previousData, `wages.${item}.${subItem}.${subInnerItem}.equal_to_minimum_wage`, "" ));
              _set(data, `wages.${item}.${subItem}.${subInnerItem}.previous.more_percentage`, _get(previousData, `wages.${item}.${subItem}.${subInnerItem}.more_percentage`, "" ));
              _set(data, `wages.${item}.${subItem}.${subInnerItem}.previous.more_than_minimum_wage`, _get(previousData, `wages.${item}.${subItem}.${subInnerItem}.more_than_minimum_wage`, "" ));
              _set(data, `wages.${item}.${subItem}.${subInnerItem}.previous.total`, _get(previousData, `wages.${item}.${subItem}.${subInnerItem}.total`, "" ));
            });
          }
        });
      });
      ["sexual_harrasment", "discrimination_at_workplace", "child_labour", "forced_or_involuntary_labour", "wages", "any_other"].forEach(item => {
        _set(data, `human_rights_complaints.${item}.previous.no_received`, _get(previousData, `human_rights_complaints.${item}.no_received`, ""));
        _set(data, `human_rights_complaints.${item}.previous.no_resolved`,
          _get(previousData, `human_rights_complaints.${item}.no_resolved`, ""));
        _set(data, `human_rights_complaints.${item}.previous.remarks`, _get(previousData, `human_rights_complaints.${item}.remarks`, ""));
      });
    }

  }, [previousData, prefillData]);

  useEffect(() => {
    if (prefillData?.wages) {
      ["workers", "employees"].forEach(item => {
        ["other_than_permanent", "permanent"].forEach(subItem => {
          let totalEqual = 0;
          let totalMore = 0;
          ["male", "female", "other"].forEach(subInnerItem => {
            _set(data, `wages.${item}.${subItem}.${subInnerItem}.equal_to_minimum_wage`, _get(prefillData, `wages.${item}.${subItem}.${subInnerItem}.equal_to_minimum_wage`, "" ));
            _set(data, `wages.${item}.${subItem}.${subInnerItem}.more_than_minimum_wage`, _get(prefillData, `wages.${item}.${subItem}.${subInnerItem}.more_than_minimum_wage`, "" ));
            _set(data, `wages.${item}.${subItem}.${subInnerItem}.equal_percentage`,
              _calculatePercentage(_get(data, `wages.${item}.${subItem}.${subInnerItem}.equal_to_minimum_wage`, 0), _get(data, `wages.${item}.${subItem}.${subInnerItem}.total`, 0)));
            _set(data, `wages.${item}.${subItem}.${subInnerItem}.more_percentage`,
              _calculatePercentage(_get(data, `wages.${item}.${subItem}.${subInnerItem}.more_than_minimum_wage`, 0), _get(data, `wages.${item}.${subItem}.${subInnerItem}.total`, 0)));
            totalEqual += _get(prefillData, `wages.${item}.${subItem}.${subInnerItem}.equal_to_minimum_wage`, 0 );
            totalMore += _get(prefillData, `wages.${item}.${subItem}.${subInnerItem}.more_than_minimum_wage`, 0 );
          });
          _set(data, `wages.${item}.${subItem}.total.equal_to_minimum_wage`, totalEqual);
          _set(data, `wages.${item}.${subItem}.total.more_than_minimum_wage`, totalMore);
          _set(data, `wages.${item}.${subItem}.total.equal_percentage`, _calculatePercentage(totalEqual, _get(data, `wages.${item}.${subItem}.total.total`, 0)));
          _set(data, `wages.${item}.${subItem}.total.more_percentage`, _calculatePercentage(totalMore, _get(data, `wages.${item}.${subItem}.total.total`, 0)));
        });
      });
    }
  }, [prefillData]);

  const _calculatePercentage = (a, b) => {
    if (!a || !b || +a == 0 || +b == 0) return "0%";
    return `${((+a / +b) * 100).toFixed(1)}%`;
  };

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, principle_5: data });
    setData({ ...data });
  };
  const onTrainingHandleChange = (e) => {
    const keys = e.target.name.split(".");
    const lastKey = keys.pop();
    const currentObj = keys.reduce((data, key) => data[key] = data[key] || {}, data);
    currentObj[lastKey] = e.target.value;
    currentObj["percentage"] = _calculatePercentage(e.target.value, currentObj["total"]);
    ["permanent", "other_than_permanent"].forEach(i => {
      if (keys.includes(i)) {
        const newKeys = e.target.name.split(".");
        newKeys.splice(newKeys.indexOf(i), 1, "permanent");
        const permanent = Number(_get(data, newKeys.join("."), 0)
        );
        newKeys.splice(newKeys.indexOf("permanent"), 1, "other_than_permanent");
        const other_than_permanent = Number(_get(data, newKeys.join("."), 0));
        newKeys.splice(newKeys.indexOf("other_than_permanent"), 1, "total");
        _set(data, newKeys.join("."), permanent+other_than_permanent);
        newKeys.splice(-1, 1, "total");
        const totalName = newKeys.join(".");
        newKeys.splice(-1, 1, "percentage");
        _set(data, newKeys.join("."), _calculatePercentage( permanent+other_than_permanent, _get(data, totalName, 0)));
      }
    });
    setData({ ...data });
  };
  const onEmployeeHandleChange = (e) => {
    const keys = e.target.name.split(".");
    const lastKey = keys.pop();
    const currentObj = keys.reduce((data, key) => data[key] = data[key] || {}, data);
    currentObj[lastKey] = e.target.value;
    const onlyKey = lastKey.split("_")[0];
    if (lastKey.split("_").length > 1) {
      currentObj[`${onlyKey}_percentage`] =_calculatePercentage(e.target.value, currentObj.total);
    } else {
      currentObj["percentage"] =_calculatePercentage(e.target.value, currentObj.total);
    }
    ["male", "female", "other"].forEach(i => {
      if (keys.includes(i)) {
        const newKeys = e.target.name.split(".");
        newKeys.splice(newKeys.indexOf(i), 1, "male");
        const male = Number(_get(data, newKeys.join("."), 0));
        newKeys.splice(newKeys.indexOf("male"), 1, "female");
        const female = Number(_get(data, newKeys.join("."), 0));
        newKeys.splice(newKeys.indexOf("female"), 1, "other");
        const other = Number(_get(data, newKeys.join("."), 0));
        newKeys.splice(newKeys.indexOf("other"), 1, "total");
        _set(data, newKeys.join("."), male+female+other);
        newKeys.splice(-1, 1, "total");
        const totalName = newKeys.join(".");
        if (lastKey.split("_").length > 1) {
          newKeys.splice(-1, 1, `${onlyKey}_percentage`);
        } else {
          newKeys.splice(-1, 1, "percentage");
        }
        _set(data, newKeys.join("."), _calculatePercentage(male+female+other, _get(data, totalName, 0)));
      }
    });
    setData({ ...data });
  };

  function tog_center () {
    setmodal_center(!modal_center);
  }

  const oninputFocus = (e, title, name1) => {
    if(!modal_center){
      e.target.blur();
    }
    setModalData({
      title: title,
      name: name1,
      value: _get(data, name1, "")
    });
    tog_center();
  };

  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_5",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionC",
          "subSection": "principle_5"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  return (
    <React.Fragment>
      <Modal className="center-modal"
        size="lg" isOpen={modal_center}
        toggle={() => { tog_center(); }}
        centered
        autoFocus={false}
        backdrop={"static"}
      >
        <ModalSection modalData={modalData} setModalData={setModalData} state={data} setmodal_center={setmodal_center}/>
        <div className="modal-footer">
          <button
            onClick={(e) => {
              setmodal_center(false);
              e.target.blur();
              setModalData({ name: "", value: "", type: "", title: "" });
            }}
            className="btn btn-primary"
          >Update</button>
        </div>
      </Modal>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <Row>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">

              <form
                onChange={onHandleChange}
                onSubmit={(e) => {
                  e.preventDefault();
                  _set(sebiCurrentData, "sectionC.principle_5", data);
                  updateSebiData(sebiCurrentData);
                  draftSebiData({ ...draftReport, principle_5: false });
                }}>
                <h5 className="mt-2"><b>PRINCIPLE 5 Businesses should respect and promote human rights</b> </h5>
                <p className="txt-decoration mt-2"><b>Essential Indicators</b></p>
                <p>1. Employees and workers who have been provided training on human rights issues and policy(ies) of the entity, in the following format:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th width="15%" rowSpan="2">Category</th>
                      <th colSpan="3" className="text-center">FY  (Current Financial Year) </th>
                      <th colSpan="3" className="text-center">FY  (Previous Financial Year) </th>
                    </tr>
                    <tr>
                      <th className="text-align">Total (A)</th>
                      <th className="text-align">No.  of  employees/ workers covered (B) </th>
                      <th width="8%" className="text-align">% (B/A) </th>
                      <th className="text-align">Total (C)</th>
                      <th className="text-align">No.  of  employees/ workers covered (D) </th>
                      <th width="8%" className="text-align">% (D/C) </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th colSpan="7" className="text-center">Employees</th>
                    </tr>
                    <tr>
                      <td>Permanent</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.employees.permanent.total"
                        value={_get(data, "trainings.human_rights.employees.permanent.total", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <TableInput
                        type="number"
                        name="trainings.human_rights.employees.permanent.no_of_employees"
                        value={_get(data, "trainings.human_rights.employees.permanent.no_of_employees", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <td>{_get(data, "trainings.human_rights.employees.permanent.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.employees.permanent.previous.total"
                        value={_get(data, "trainings.human_rights.employees.permanent.previous.total", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.employees.permanent.previous.no_of_employees"
                        value={_get(data, "trainings.human_rights.employees.permanent.previous.no_of_employees", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <td>{_get(data, "trainings.human_rights.employees.permanent.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <td>Other permanent than</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.employees.other_than_permanent.total"
                        value={_get(data, "trainings.human_rights.employees.other_than_permanent.total", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <TableInput
                        type="number"
                        name="trainings.human_rights.employees.other_than_permanent.no_of_employees"
                        value={_get(data, "trainings.human_rights.employees.other_than_permanent.no_of_employees", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <td>{_get(data, "trainings.human_rights.employees.other_than_permanent.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.employees.other_than_permanent.previous.total"
                        value={_get(data, "trainings.human_rights.employees.other_than_permanent.previous.total", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.employees.other_than_permanent.previous.no_of_employees"
                        value={_get(data, "trainings.human_rights.employees.other_than_permanent.previous.no_of_employees", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <td>{_get(data, "trainings.human_rights.employees.other_than_permanent.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <th>Total Employees</th>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.employees.total.total"
                        value={_get(data, "trainings.human_rights.employees.total.total", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.employees.total.no_of_employees"
                        value={_get(data, "trainings.human_rights.employees.total.no_of_employees", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <td>{_get(data, "trainings.human_rights.employees.total.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.employees.total.previous.total"
                        value={_get(data, "trainings.human_rights.employees.total.previous.total", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.employees.total.previous.no_of_employees"
                        value={_get(data, "trainings.human_rights.employees.total.previous.no_of_employees", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <td>{_get(data, "trainings.human_rights.employees.total.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <th colSpan="7" className="text-center">Workers</th>
                    </tr>
                    <tr>
                      <td>Permanent</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.workers.permanent.total"
                        value={_get(data, "trainings.human_rights.workers.permanent.total", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <TableInput
                        type="number"
                        name="trainings.human_rights.workers.permanent.no_of_employees"
                        value={_get(data, "trainings.human_rights.workers.permanent.no_of_employees", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <td>{_get(data, "trainings.human_rights.workers.permanent.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.workers.permanent.previous.total"
                        value={_get(data, "trainings.human_rights.workers.permanent.previous.total", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.workers.permanent.previous.no_of_employees"
                        value={_get(data, "trainings.human_rights.workers.permanent.previous.no_of_employees", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <td>{_get(data, "trainings.human_rights.workers.permanent.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <td>Other permanent than</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.workers.other_than_permanent.total"
                        value={_get(data, "trainings.human_rights.workers.other_than_permanent.total", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <TableInput
                        type="number"
                        name="trainings.human_rights.workers.other_than_permanent.no_of_employees"
                        value={_get(data, "trainings.human_rights.workers.other_than_permanent.no_of_employees", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <td>{_get(data, "trainings.human_rights.workers.other_than_permanent.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.workers.other_than_permanent.previous.total"
                        value={_get(data, "trainings.human_rights.workers.other_than_permanent.previous.total", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.workers.other_than_permanent.previous.no_of_employees"
                        value={_get(data, "trainings.human_rights.workers.other_than_permanent.previous.no_of_employees", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <td>{_get(data, "trainings.human_rights.workers.other_than_permanent.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <th>Total Workers</th>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.workers.total.total"
                        value={_get(data, "trainings.human_rights.workers.total.total", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.workers.total.no_of_employees"
                        value={_get(data, "trainings.human_rights.workers.total.no_of_employees", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <td>{_get(data, "trainings.human_rights.workers.total.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.workers.total.previous.total"
                        value={_get(data, "trainings.human_rights.workers.total.previous.total", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="trainings.human_rights.workers.total.previous.no_of_employees"
                        value={_get(data, "trainings.human_rights.workers.total.previous.no_of_employees", "")}
                        onChange={onTrainingHandleChange}
                      />
                      <td>{_get(data, "trainings.human_rights.workers.total.previous.percentage", "")}</td>
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.training_on_human_rights?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "training_on_human_rights" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.training_on_human_rights?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "training_on_human_rights" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "training_on_human_rights" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">2. Details of minimum wages paid to employees and workers, in the following format:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th rowSpan="3">Category</th>
                      <th colSpan="5" className="text-center">FY (Current Financial Year) </th>
                      <th colSpan="5" className="text-center">FY (Previous Financial Year) </th>
                    </tr>
                    <tr>
                      <th rowSpan="2" className="text-center">Total (A)</th>
                      <th colSpan="2" className="text-center">Equal to Minimum Wage  </th>
                      <th colSpan="2" className="text-center">More than Minimum Wage </th>
                      <th rowSpan="2" className="text-center">Total (D)</th>
                      <th colSpan="2" className="text-center">Equal to Minimum Wage  </th>
                      <th colSpan="2" className="text-center">More than Minimum Wage </th>
                    </tr>
                    <tr>
                      <th className="text-align">No. (B)</th>
                      <th className="text-align" width="8%">% (B/A)</th>
                      <th className="text-align">No. (C)</th>
                      <th className="text-align" width="8%">% (C/A)</th>
                      <th className="text-align">No. (E)</th>
                      <th className="text-align" width="8%">% (E/A)</th>
                      <th className="text-align">No. (F)</th>
                      <th className="text-align" width="8%">% (F/A)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th colSpan="12" className="text-center">Employees</th>
                    </tr>
                    <tr>
                      <th>Permenent</th>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.total.total"
                        value={_get(data, "wages.employees.permanent.total.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.total.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.permanent.total.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.total.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.total.more_than_minimum_wage"
                        value={_get(data, "wages.employees.permanent.total.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.total.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.total.previous.total"
                        value={_get(data, "wages.employees.permanent.total.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.total.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.permanent.total.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.total.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.total.previous.more_than_minimum_wage"
                        value={_get(data, "wages.employees.permanent.total.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.total.previous.more_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td>Male</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.male.total"
                        value={_get(data, "wages.employees.permanent.male.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        name="wages.employees.permanent.male.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.permanent.male.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.male.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        name="wages.employees.permanent.male.more_than_minimum_wage"
                        value={_get(data, "wages.employees.permanent.male.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.male.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.male.previous.total"
                        value={_get(data, "wages.employees.permanent.male.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.male.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.permanent.male.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.male.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.male.previous.more_than_minimum_wage"
                        value={_get(data, "wages.employees.permanent.male.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.male.previous.more_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td>Female</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.female.total"
                        value={_get(data, "wages.employees.permanent.female.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        name="wages.employees.permanent.female.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.permanent.female.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.female.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        name="wages.employees.permanent.female.more_than_minimum_wage"
                        value={_get(data, "wages.employees.permanent.female.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.female.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.female.previous.total"
                        value={_get(data, "wages.employees.permanent.female.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.female.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.permanent.female.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.female.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.female.previous.more_than_minimum_wage"
                        value={_get(data, "wages.employees.permanent.female.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.female.previous.more_percentage", "")}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Other</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.other.total"
                        value={_get(data, "wages.employees.permanent.other.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        name="wages.employees.permanent.other.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.permanent.other.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.other.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        name="wages.employees.permanent.other.more_than_minimum_wage"
                        value={_get(data, "wages.employees.permanent.other.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.other.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.other.previous.total"
                        value={_get(data, "wages.employees.permanent.other.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.other.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.permanent.other.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.other.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.permanent.other.previous.more_than_minimum_wage"
                        value={_get(data, "wages.employees.permanent.other.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.permanent.other.previous.more_percentage", "")}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Other than Permanent </th>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.total.total"
                        value={_get(data, "wages.employees.other_than_permanent.total.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.total.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.total.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.total.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.total.more_than_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.total.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.total.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.total.previous.total"
                        value={_get(data, "wages.employees.other_than_permanent.total.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.total.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.total.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.total.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.total.previous.more_than_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.total.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.total.previous.more_percentage", "")}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Male</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.male.total"
                        value={_get(data, "wages.employees.other_than_permanent.male.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        name="wages.employees.other_than_permanent.male.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.male.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.male.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        name="wages.employees.other_than_permanent.male.more_than_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.male.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.male.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.male.previous.total"
                        value={_get(data, "wages.employees.other_than_permanent.male.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.male.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.male.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.male.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.male.previous.more_than_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.male.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.male.previous.more_percentage", "")}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Female</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.female.total"
                        value={_get(data, "wages.employees.other_than_permanent.female.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        name="wages.employees.other_than_permanent.female.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.female.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.female.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        name="wages.employees.other_than_permanent.female.more_than_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.female.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.female.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.female.previous.total"
                        value={_get(data, "wages.employees.other_than_permanent.female.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.female.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.female.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.female.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.female.previous.more_than_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.female.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.female.previous.more_percentage", "")}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Other</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.other.total"
                        value={_get(data, "wages.employees.other_than_permanent.other.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        name="wages.employees.other_than_permanent.other.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.other.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.other.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        name="wages.employees.other_than_permanent.other.more_than_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.other.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.other.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.other.previous.total"
                        value={_get(data, "wages.employees.other_than_permanent.other.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.other.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.other.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.other.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.employees.other_than_permanent.other.previous.more_than_minimum_wage"
                        value={_get(data, "wages.employees.other_than_permanent.other.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.employees.other_than_permanent.other.previous.more_percentage", "")}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th colSpan="12" className="text-center">Workers</th>
                    </tr>
                    <tr>
                      <th>Permenent</th>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.total.total"
                        value={_get(data, "wages.workers.permanent.total.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.total.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.permanent.total.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.total.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.total.more_than_minimum_wage"
                        value={_get(data, "wages.workers.permanent.total.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.total.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.total.previous.total"
                        value={_get(data, "wages.workers.permanent.total.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.total.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.permanent.total.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.total.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.total.previous.more_than_minimum_wage"
                        value={_get(data, "wages.workers.permanent.total.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.total.previous.more_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td>Male</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.male.total"
                        value={_get(data, "wages.workers.permanent.male.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        name="wages.workers.permanent.male.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.permanent.male.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.male.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        name="wages.workers.permanent.male.more_than_minimum_wage"
                        value={_get(data, "wages.workers.permanent.male.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.male.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.male.previous.total"
                        value={_get(data, "wages.workers.permanent.male.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.male.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.permanent.male.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.male.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.male.previous.more_than_minimum_wage"
                        value={_get(data, "wages.workers.permanent.male.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.male.previous.more_percentage", "")}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Female</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.female.total"
                        value={_get(data, "wages.workers.permanent.female.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        name="wages.workers.permanent.female.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.permanent.female.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.female.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        name="wages.workers.permanent.female.more_than_minimum_wage"
                        value={_get(data, "wages.workers.permanent.female.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.female.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.female.previous.total"
                        value={_get(data, "wages.workers.permanent.female.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.female.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.permanent.female.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.female.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.female.previous.more_than_minimum_wage"
                        value={_get(data, "wages.workers.permanent.female.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.female.previous.more_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td>Other</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.other.total"
                        value={_get(data, "wages.workers.permanent.other.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        name="wages.workers.permanent.other.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.permanent.other.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.other.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        name="wages.workers.permanent.other.more_than_minimum_wage"
                        value={_get(data, "wages.workers.permanent.other.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.other.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.other.previous.total"
                        value={_get(data, "wages.workers.permanent.other.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.other.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.permanent.other.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.other.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.permanent.other.previous.more_than_minimum_wage"
                        value={_get(data, "wages.workers.permanent.other.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.permanent.other.previous.more_percentage", "")}</td>
                    </tr>
                    <tr>
                      <th>Other than Permanent </th>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.total.total"
                        value={_get(data, "wages.workers.other_than_permanent.total.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.total.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.total.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.total.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.total.more_than_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.total.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.total.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.total.previous.total"
                        value={_get(data, "wages.workers.other_than_permanent.total.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.total.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.total.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.total.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.total.previous.more_than_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.total.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.total.previous.more_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td>Male</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.male.total"
                        value={_get(data, "wages.workers.other_than_permanent.male.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        name="wages.workers.other_than_permanent.male.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.male.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.male.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        name="wages.workers.other_than_permanent.male.more_than_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.male.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.male.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.male.previous.total"
                        value={_get(data, "wages.workers.other_than_permanent.male.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.male.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.male.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.male.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.male.previous.more_than_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.male.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.male.previous.more_percentage", "")}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Female</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.female.total"
                        value={_get(data, "wages.workers.other_than_permanent.female.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        name="wages.workers.other_than_permanent.female.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.female.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.female.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        name="wages.workers.other_than_permanent.female.more_than_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.female.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.female.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.female.previous.total"
                        value={_get(data, "wages.workers.other_than_permanent.female.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.female.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.female.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.female.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.female.previous.more_than_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.female.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.female.previous.more_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td>Other</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.other.total"
                        value={_get(data, "wages.workers.other_than_permanent.other.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        name="wages.workers.other_than_permanent.other.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.other.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.other.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        name="wages.workers.other_than_permanent.other.more_than_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.other.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.other.more_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.other.previous.total"
                        value={_get(data, "wages.workers.other_than_permanent.other.previous.total", "")}
                        onChange={onEmployeeHandleChange} />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.other.previous.equal_to_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.other.previous.equal_to_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.other.previous.equal_percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="wages.workers.other_than_permanent.other.previous.more_than_minimum_wage"
                        value={_get(data, "wages.workers.other_than_permanent.other.previous.more_than_minimum_wage", "")}
                        onChange={onEmployeeHandleChange} />
                      <td>{_get(data, "wages.workers.other_than_permanent.other.previous.more_percentage", "")}</td>
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.minimum_wages_paid?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "minimum_wages_paid" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.minimum_wages_paid?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "minimum_wages_paid" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "minimum_wages_paid" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">3. Details of remuneration/ salary/ wages, in the following format :</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th rowSpan="2"></th>
                      <th colSpan="2" className="text-align">Male</th>
                      <th colSpan="2" className="text-align">Female</th>
                      <th colSpan="2" className="text-align">Other</th>
                    </tr>
                    <tr>
                      <th className="text-align">Number</th>
                      <th className="text-align">Median remuneration/ salary/ wages of respective category</th>
                      <th className="text-align">Number</th>
                      <th className="text-align">Median remuneration/ salary/ wages of respective category </th>
                      <th className="text-align">Number</th>
                      <th className="text-align">Median remuneration/ salary/ wages of respective category </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-align-left">Board of Directors (BoD)</td>
                      <TableInput
                        name="details_of_remuneration.board_of_directors.male.number"
                        value={_get(data, "details_of_remuneration.board_of_directors.male.number", "")}
                        type="number"
                        onChange={onHandleChange} />
                      <TableInput
                        name="details_of_remuneration.board_of_directors.male.renumeration"
                        value={_get(data, "details_of_remuneration.board_of_directors.male.renumeration", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.board_of_directors.female.number"
                        value={_get(data, "details_of_remuneration.board_of_directors.female.number", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.board_of_directors.female.renumeration"
                        value={_get(data, "details_of_remuneration.board_of_directors.female.renumeration", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.board_of_directors.other.number"
                        value={_get(data, "details_of_remuneration.board_of_directors.other.number", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.board_of_directors.other.renumeration"
                        value={_get(data, "details_of_remuneration.board_of_directors.other.renumeration", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td className="text-align-left">Key Managerial Personnel</td>
                      <TableInput
                        name="details_of_remuneration.key_managerial_personnel.male.number"
                        value={_get(data, "details_of_remuneration.key_managerial_personnel.male.number", "")}
                        type="number"
                        onChange={onHandleChange} />
                      <TableInput
                        name="details_of_remuneration.key_managerial_personnel.male.renumeration"
                        value={_get(data, "details_of_remuneration.key_managerial_personnel.male.renumeration", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.key_managerial_personnel.female.number"
                        value={_get(data, "details_of_remuneration.key_managerial_personnel.female.number", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.key_managerial_personnel.female.renumeration"
                        value={_get(data, "details_of_remuneration.key_managerial_personnel.female.renumeration", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.key_managerial_personnel.other.number"
                        value={_get(data, "details_of_remuneration.key_managerial_personnel.other.number", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.key_managerial_personnel.other.renumeration"
                        value={_get(data, "details_of_remuneration.key_managerial_personnel.other.renumeration", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td className="text-align-left">Employees other than BoD and KMP</td>
                      <TableInput
                        name="details_of_remuneration.employess_other_than_bod_kmp.male.number"
                        value={_get(data, "details_of_remuneration.employess_other_than_bod_kmp.male.number", "")}
                        type="number"
                        onChange={onHandleChange} />
                      <TableInput
                        name="details_of_remuneration.employess_other_than_bod_kmp.male.renumeration"
                        value={_get(data, "details_of_remuneration.employess_other_than_bod_kmp.male.renumeration", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.employess_other_than_bod_kmp.female.number"
                        value={_get(data, "details_of_remuneration.employess_other_than_bod_kmp.female.number", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.employess_other_than_bod_kmp.female.renumeration"
                        value={_get(data, "details_of_remuneration.employess_other_than_bod_kmp.female.renumeration", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.employess_other_than_bod_kmp.other.number"
                        value={_get(data, "details_of_remuneration.employess_other_than_bod_kmp.other.number", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.employess_other_than_bod_kmp.other.renumeration"
                        value={_get(data, "details_of_remuneration.employess_other_than_bod_kmp.other.renumeration", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td className="text-align-left">Workers</td>
                      <TableInput
                        name="details_of_remuneration.workers.male.number"
                        value={_get(data, "details_of_remuneration.workers.male.number", "")}
                        type="number"
                        onChange={onHandleChange} />
                      <TableInput
                        name="details_of_remuneration.workers.male.renumeration"
                        value={_get(data, "details_of_remuneration.workers.male.renumeration", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.workers.female.number"
                        value={_get(data, "details_of_remuneration.workers.female.number", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.workers.female.renumeration"
                        value={_get(data, "details_of_remuneration.workers.female.renumeration", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.workers.other.number"
                        value={_get(data, "details_of_remuneration.workers.other.number", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="details_of_remuneration.workers.other.renumeration"
                        value={_get(data, "details_of_remuneration.workers.other.renumeration", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.details_of_remuneration?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "details_of_remuneration" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.details_of_remuneration?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "details_of_remuneration" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "details_of_remuneration" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">4. Individual/ Committee) responsible for addressing human rights impacts or issues caused or contributed to by the business? (Yes/No)</p>
                <RadioButton id="radio_124button" className="dis-right ml-10"
                  name="do_you_have_a_focal_point"
                  value={_get(data, "do_you_have_a_focal_point", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">5. Describe the internal mechanisms in place to redress grievances related to human rights issues.</p>
                <TextArea
                  name="describe_the_internal_mechanisms"
                  value={_get(data, "describe_the_internal_mechanisms", "")}
                  onChange={onHandleChange} />

                <p className="mt-2">6. Number of Complaints on the following made by employees and workers:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th rowSpan="2"></th>
                      <th colSpan="3" className="text-center">FY  (Current Financial Year)</th>
                      <th colSpan="3" className="text-center">FY  (Previous Financial Year)</th>
                    </tr>
                    <tr>
                      <th className="text-align">Filed during the year</th>
                      <th className="text-align">Pending resolution at the end of year </th>
                      <th className="text-align">Remarks</th>
                      <th className="text-align">Filed during the year</th>
                      <th className="text-align">Pending resolution at the end of year </th>
                      <th className="text-align">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sexual Harassment</td>
                      <TableInput
                        name="human_rights_complaints.sexual_harrasment.no_received"
                        value={_get(data, "human_rights_complaints.sexual_harrasment.no_received", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="human_rights_complaints.sexual_harrasment.no_resolved"
                        value={_get(data, "human_rights_complaints.sexual_harrasment.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableTextArea
                        name="human_rights_complaints.sexual_harrasment.remarks"
                        value={_get(data, "human_rights_complaints.sexual_harrasment.remarks", "")}
                        onChange={onHandleChange}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                      />
                      <TableInput
                        isDisabled={true}
                        name="human_rights_complaints.sexual_harrasment.previous.no_received"
                        value={_get(data, "human_rights_complaints.sexual_harrasment.previous.no_received", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        name="human_rights_complaints.sexual_harrasment.previous.no_resolved"
                        value={_get(data, "human_rights_complaints.sexual_harrasment.previous.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableTextArea
                        isDisabled={true}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="human_rights_complaints.sexual_harrasment.previous.remarks"
                        value={_get(data, "human_rights_complaints.sexual_harrasment.previous.remarks", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>Discrimination at workplace</td>
                      <TableInput
                        name="human_rights_complaints.discrimination_at_workplace.no_received"
                        value={_get(data, "human_rights_complaints.discrimination_at_workplace.no_received", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="human_rights_complaints.discrimination_at_workplace.no_resolved"
                        value={_get(data, "human_rights_complaints.discrimination_at_workplace.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="human_rights_complaints.discrimination_at_workplace.remarks"
                        value={_get(data, "human_rights_complaints.discrimination_at_workplace.remarks", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        name="human_rights_complaints.discrimination_at_workplace.previous.no_received"
                        value={_get(data, "human_rights_complaints.discrimination_at_workplace.previous.no_received", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        name="human_rights_complaints.discrimination_at_workplace.previous.no_resolved"
                        value={_get(data, "human_rights_complaints.discrimination_at_workplace.previous.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableTextArea
                        isDisabled={true}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="human_rights_complaints.discrimination_at_workplace.previous.remarks"
                        value={_get(data, "human_rights_complaints.discrimination_at_workplace.previous.remarks", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>Child Labour</td>
                      <TableInput
                        name="human_rights_complaints.child_labour.no_received"
                        value={_get(data, "human_rights_complaints.child_labour.no_received", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="human_rights_complaints.child_labour.no_resolved"
                        value={_get(data, "human_rights_complaints.child_labour.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="human_rights_complaints.child_labour.remarks"
                        value={_get(data, "human_rights_complaints.child_labour.remarks", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        name="human_rights_complaints.child_labour.previous.no_received"
                        value={_get(data, "human_rights_complaints.child_labour.previous.no_received", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        name="human_rights_complaints.child_labour.previous.no_resolved"
                        value={_get(data, "human_rights_complaints.child_labour.previous.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableTextArea
                        isDisabled={true}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="human_rights_complaints.child_labour.previous.remarks"
                        value={_get(data, "human_rights_complaints.child_labour.previous.remarks", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>Forced Labour/Involuntary Labour</td>
                      <TableInput
                        name="human_rights_complaints.forced_or_involuntary_labour.no_received"
                        value={_get(data, "human_rights_complaints.forced_or_involuntary_labour.no_received", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="human_rights_complaints.forced_or_involuntary_labour.no_resolved"
                        value={_get(data, "human_rights_complaints.forced_or_involuntary_labour.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="human_rights_complaints.forced_or_involuntary_labour.remarks"
                        value={_get(data, "human_rights_complaints.forced_or_involuntary_labour.remarks", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        name="human_rights_complaints.forced_or_involuntary_labour.previous.no_received"
                        value={_get(data, "human_rights_complaints.forced_or_involuntary_labour.previous.no_received", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        name="human_rights_complaints.forced_or_involuntary_labour.previous.no_resolved"
                        value={_get(data, "human_rights_complaints.forced_or_involuntary_labour.previous.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableTextArea
                        isDisabled={true}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="human_rights_complaints.forced_or_involuntary_labour.previous.remarks"
                        value={_get(data, "human_rights_complaints.forced_or_involuntary_labour.previous.remarks", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>Wages</td>
                      <TableInput
                        name="human_rights_complaints.wages.no_received"
                        value={_get(data, "human_rights_complaints.wages.no_received", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="human_rights_complaints.wages.no_resolved"
                        value={_get(data, "human_rights_complaints.wages.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="human_rights_complaints.wages.remarks"
                        value={_get(data, "human_rights_complaints.wages.remarks", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        name="human_rights_complaints.wages.previous.no_received"
                        value={_get(data, "human_rights_complaints.wages.previous.no_received", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        name="human_rights_complaints.wages.previous.no_resolved"
                        value={_get(data, "human_rights_complaints.wages.previous.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableTextArea
                        isDisabled={true}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="human_rights_complaints.wages.previous.remarks"
                        value={_get(data, "human_rights_complaints.wages.previous.remarks", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>Other human rights related issues </td>
                      <TableInput
                        name="human_rights_complaints.any_other.no_received"
                        value={_get(data, "human_rights_complaints.any_other.no_received", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        name="human_rights_complaints.any_other.no_resolved"
                        value={_get(data, "human_rights_complaints.any_other.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="human_rights_complaints.any_other.remarks"
                        value={_get(data, "human_rights_complaints.any_other.remarks", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        name="human_rights_complaints.any_other.previous.no_received"
                        value={_get(data, "human_rights_complaints.any_other.previous.no_received", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="human_rights_complaints.any_other.previous.no_resolved"
                        value={_get(data, "human_rights_complaints.any_other.previous.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}
                      />
                      <TableTextArea
                        isDisabled={true}
                        name="human_rights_complaints.any_other.previous.remarks"
                        value={_get(data, "human_rights_complaints.any_other.previous.remarks", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.complaints_on_the?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "complaints_on_the" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.complaints_on_the?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "complaints_on_the" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "complaints_on_the" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">7. Mechanisms to prevent adverse consequences to the complainant in discrimination and harassment cases.</p>
                <TextArea
                  name="discrimination_and_harassment_cases"
                  value={_get(data, "discrimination_and_harassment_cases", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">8. Do human rights requirements form part of your business agreements and contracts? (Yes/No) </p>
                <RadioButton id="radio_125button" className="dis-right ml-10"
                  name="do_human_rights_requirements"
                  value={_get(data, "do_human_rights_requirements", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">9. Assessments for the year:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th></th>
                      <th className="text-align">% of your plants and offices that were assessed (by entity or statutory authorities or third parties)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Child labour</td>
                      <TableInput
                        type="number"
                        name="assessments_of_the_year.child_labour_percentage"
                        value={_get(data, "assessments_of_the_year.child_labour_percentage", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>Forced/involuntary labour</td>
                      <TableInput
                        type="number"
                        name="assessments_of_the_year.forced_percentage"
                        value={_get(data, "assessments_of_the_year.forced_percentage", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>Sexual harassmen</td>
                      <TableInput
                        type="number"
                        name="assessments_of_the_year.sexual_harassmen_percentage"
                        value={_get(data, "assessments_of_the_year.sexual_harassmen_percentage", "")}
                        onChange={onHandleChange} />
                    </tr>
                    <tr>
                      <td>Discrimination at workplace</td>
                      <TableInput
                        type="number"
                        name="assessments_of_the_year.workplace_percentage"
                        value={_get(data, "assessments_of_the_year.workplace_percentage", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>Wages</td>
                      <TableInput
                        type="number"
                        name="assessments_of_the_year.wages_percentage"
                        value={_get(data, "assessments_of_the_year.wages_percentage", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>Others – please specify</td>
                      <TableInput
                        type="text"
                        name="assessments_of_the_year.others_percentage"
                        value={_get(data, "assessments_of_the_year.others_percentage", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.assessments_for?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "assessments_for" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.assessments_for?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "assessments_for" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "assessments_for" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">10.  Provide details of any corrective actions taken or underway to address significant risks / concerns arising from the assessments at Question 9 above.</p>
                <TextArea
                  name="assessments_at_question_9_above"
                  value={_get(data, "assessments_at_question_9_above", "")}
                  onChange={onHandleChange}
                />
                <p className="txt-decoration mt-2"><b>Leadership Indicators</b></p>
                <p className="mt-2">1. Details of a business process being modified / introduced as a result of addressing human rights grievances/complaints.</p>
                <TextArea
                  name="addressing_human_rights_grievances_complaints"
                  value={_get(data, "addressing_human_rights_grievances_complaints", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">2. Details of the scope and coverage of any Human rights due-diligence conducted.</p>
                <TextArea
                  name="human_rights_due_diligence_conducted"
                  value={_get(data, "human_rights_due_diligence_conducted", "")}
                  onChange={onHandleChange}
                />

                <p className="top-9">
                3. Is the premise/office of the entity accessible to differently abled visitors, as per the requirements of the Rights of Persons with Disabilities Act, 2016?</p>
                <TextArea
                  name="rights_of_persons_with_disabilities_act"
                  value={_get(data, "rights_of_persons_with_disabilities_act", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">4. Details on assessment of value chain partners:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th></th>
                      <th>% of value chain partners (by value of business done with such partners) that were assessed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sexual Harassment</td>
                      <TableInput
                        type="number"
                        name="assessment_of_value_chain_partners.sexual_percentage"
                        value={_get(data, "assessment_of_value_chain_partners.sexual_percentage", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>Discrimination at workplace</td>
                      <TableInput
                        type="number"
                        name="assessment_of_value_chain_partners.workplace_percentage"
                        value={_get(data, "assessment_of_value_chain_partners.workplace_percentage", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>Child Labour</td>
                      <TableInput
                        type="number"
                        name="assessment_of_value_chain_partners.child_labour_percentage"
                        value={_get(data, "assessment_of_value_chain_partners.child_labour_percentage", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>Forced Labour/Involuntary Labour</td>
                      <TableInput
                        type="number"
                        name="assessment_of_value_chain_partners.forced_percentage"
                        value={_get(data, "assessment_of_value_chain_partners.forced_percentage", "")}
                        onChange={onHandleChange} />
                    </tr>
                    <tr>
                      <td>Wages</td>
                      <TableInput
                        type="number"
                        name="assessment_of_value_chain_partners.wages_percentage"
                        value={_get(data, "assessment_of_value_chain_partners.wages_percentage", "")}
                        onChange={onHandleChange} />
                    </tr>
                    <tr>
                      <td>Others – please specify</td>
                      <TableInput
                        type="text"
                        name="assessment_of_value_chain_partners.others_percentage"
                        value={_get(data, "assessment_of_value_chain_partners.others_percentage", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.details_on_assessment_of_value?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "details_on_assessment_of_value" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.details_on_assessment_of_value?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "details_on_assessment_of_value" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "details_on_assessment_of_value" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">5. Provide details of any corrective actions taken or underway to address significant risks / concerns arising from the assessments at Question 4 above.</p>
                <TextArea
                  name="assessments_at_question_4_above"
                  value={_get(data, "assessments_at_question_4_above", "")}
                  onChange={onHandleChange}
                />

                <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <button type="button" className="btn btn-primary w-md "
                      onClick={() => onSectionChange(12)}>Previous</button>{" "}
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                    <button type="button" className="btn btn-primary w-md "
                      onClick={() => onSectionChange(14)}>Next</button>
                  </div>
                </div>

              </form>

            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionC?.principle_5,
    previousData: state.NewReportReducer?.sebiForm?.previousYearData?.sectionC?.principle_5,
    employeeData: state.NewReportReducer?.sebiForm?.currentYear?.sectionA?.workforce_data,
    prefillData: state.NewReportReducer?.sebiForm?.sebiPrefil,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.principle_5,
    filesData: state.NewReportReducer?.draftFiles?.principle_5
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(Principle5);
