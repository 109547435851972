/*eslint-disable*/ 
import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, Col, Container, Label, Row, Modal, ListGroupItem, ListGroup } from "reactstrap";
// import axios from "axios";
import * as yup from "yup";
import SimpleBar from "simplebar-react";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { API_URLS } from "common/constants";
import { AxiosGet, AxiosPost } from "common/axiosService";

const SUPPORTED_FORMATES = [
  "text/csv", ".csv", "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];
const validationSchema = yup.object({
  typeOfData: yup.string().required("Please Select Any Data Type"),
  locationId: yup.string().required("Please Select Any Location"),
  file: yup
    .mixed() .nullable() .required("Please Add Data File") .test( "type", "Please Upload only CSV File",
      values => !values || (values && SUPPORTED_FORMATES.includes(values?.type))
    )
});

const BulkUpload = () => {
  document.title = "Bulk Upload | GreenAlytics";
  const [items, setItems] = useState([]);
  const [loader, setLoader] = useState(false);
  const [uploaded, setUploaded] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [dataPointName, setDataPointName] = useState("");
  const [timeperiod, setTimeperiod] = useState({
    year: "",
    time: "",
    template: ""
  });

  const [categoryarray, setCategoryArray] = useState([]);
  const [modal_center, setmodal_center] = useState(false);

  const fileRef = useRef();
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const data = { typeOfData: "", locationId: "", file: "" };

  function tog_center () {
    setmodal_center(!modal_center);
  }

  const session = JSON.parse(localStorage.getItem("authUser"));
  const companyId = session.userData.user.companyId
    ? session.userData.user.companyId._id
    : "";

  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/locations/${companyId}`);
        if (response && response.statusCode === 200) {
          setLocationList(response.data);
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, []);

  const handleTimePeriod = e => {
    const { name, value } = e.target;
    setTimeperiod({
      ...timeperiod, [name]: value
    });
  };

  const handleChange = e => {
    const { checked, value } = e.currentTarget;
    setCategoryArray(prev => checked ? [...prev, value] : prev.filter(val => val !== value) );
  };

  const selectDataPoint = async (e) => {
    try {
      const response = await AxiosGet(`${apipath}/get-all-category/?typeOfData=${e.target.value}`, {} );
      if (response && response.statusCode === 200) {
        setItems(response.data);
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
    setDataPointName(e.target.value);
  };

  const years = Array.from(
    { length: 13 },
    (_, i) => new Date().getFullYear() - i
  );

  return (
    <React.Fragment>
      <Modal
        isOpen={modal_center}
        toggle={() => { tog_center(); } }
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">File Uploaded</h5>
          <button
            type="button"
            className="close"
            aria-label="Close"
            data-dismiss="modal"
            onClick={() => { setmodal_center(false); } }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>Your File Uploaded Successfully.</p>
        </div>
      </Modal>

      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col lg="5">
              <Card>
                <CardBody>
                  <div className="main-category-box mb-4">
                    <div>
                      <h4 className="card-title">Download Template</h4>
                    </div>
                  </div>
                  <div>
                    <Label>Select CSV Template</Label>
                    <select
                      name="template"
                      className="form-select mb-3"
                      value={timeperiod.template}
                      onChange={e => handleTimePeriod(e)}
                    >
                      <option value="">Select</option>
                      <option value="fulltemplate">Full Template</option>
                      <option value="Custom">Custom Template</option>
                    </select>
                    <Label>Select Year</Label>
                    <select
                      name="year"
                      className="form-select mb-3"
                      value={timeperiod.year}
                      onChange={e => handleTimePeriod(e)}
                    >
                      <option value="">Select</option>
                      {years.map(option => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </select>

                    <Label>Select Time Period</Label>
                    <select
                      name="time"
                      value={timeperiod.time}
                      className="form-select mb-3"
                      onChange={e => handleTimePeriod(e)}
                    >
                      <option value="">Select</option>
                      <option value="1">Quater 1st</option>
                      <option value="2">Quater 2nd</option>
                      <option value="3">Quater 3rd</option>
                      <option value="4">Quater 4th</option>
                      <option value="5">First Half</option>
                      <option value="6">Second Half</option>
                      <option value="7">Full Year</option>
                    </select>

                    <Label>Select Data Type</Label>
                    <select
                      name="typeOfData"
                      id="selectdatatype"
                      className="form-select mb-4"
                      disabled={timeperiod.year && timeperiod.time ? false : true}
                      onChange={e => selectDataPoint(e)}
                    >
                      <option value="1">Select</option>
                      <option value="Environmental">Environmental</option>
                      <option value="Social">Social</option>
                      <option value="Governance">Governance</option>
                      <option value="Greenhouse Gas">Greenhouse Gas</option>
                      <option value="BRSR">BRSR</option>
                    </select>
                  </div>

                  {
                    timeperiod.year && timeperiod.time && timeperiod.template?.length > 0 ?
                      timeperiod.template === "fulltemplate" ?
                        (<>
                          <SimpleBar style={{ maxHeight: "300px" }}>
                            <div>
                              <ListGroup>
                                {items.map(category => (
                                  <ListGroupItem key={category._id}>
                                    {category.categoryName}
                                  </ListGroupItem>
                                ))}
                              </ListGroup>
                            </div>
                          </SimpleBar>

                          {items.length > 0 ?
                            <button
                              className="btn btn-primary mt-3"
                              type="button"
                              onClick={() => {
                                const token = localStorage.getItem("accessToken");
                                const select_box = document.getElementById("selectdatatype");
                                let a = document.createElement("a");
                                a.href = `${API_URLS.DOWNLOAD_CSV}/${token}/[]/${dataPointName}/${timeperiod.year}/${timeperiod.time}`;
                                a.download;
                                a.click();
                                a.remove();
                                select_box.selectedIndex = 0;
                                setDataPointName("");
                                setItems([]);
                                setTimeperiod({
                                  year: "",
                                  time: "",
                                  template: ""
                                });
                                setTimeout(() => {
                                  toast.success("File Downloaded Succesfully", { autoClose: 2000 });
                                }, 2000);
                              }}
                            >
                          Download Template
                            </button>
                            :
                            null
                          }

                        </>)
                        :
                        (<>
                          <SimpleBar style={{ maxHeight: "500px" }}>
                            <div>
                              <ListGroup>
                                {items.map(category => (
                                  <ListGroupItem key={category._id}>
                                    <span>
                                      <input
                                        type="checkbox"
                                        value={`${category._id}`}
                                        onChange={e => handleChange(e)}
                                      />
                                    </span>{" "}
                                    {category.categoryName}
                                  </ListGroupItem>
                                ))}
                              </ListGroup>
                            </div>
                          </SimpleBar>

                          {items.length > 0 ?
                            <button
                              type="button"
                              className="btn btn-primary  mt-3"
                              onClick={() => {
                                const token = localStorage.getItem("accessToken");

                                let a = document.createElement("a");
                                const select_box = document.getElementById("selectdatatype");
                                a.href = `${API_URLS.DOWNLOAD_CSV}/${token}/${JSON.stringify( categoryarray )}/${dataPointName}/${timeperiod.year}/${timeperiod.time}`;
                                a.download;
                                a.click();
                                a.remove();
                                select_box.selectedIndex = 0;
                                setCategoryArray([]);
                                setItems([]);
                                setDataPointName("");
                                setTimeperiod({
                                  year: "",
                                  time: "",
                                  template: ""
                                });
                                setTimeout(() => {
                                  toast.success("File Downloaded Succesfully", { autoClose: 2000 }); }, 2000);
                              }}
                            >
                            Download Template
                            </button>
                            :
                            null

                          }

                        </> )
                      :
                      null
                  }

                </CardBody>
              </Card>
            </Col>
            <Col lg="7">
              <Card>
                <CardBody>
                  <div className="main-category-box mb-4">
                    <div>
                      <h4 className="card-title">Upload Data</h4>
                    </div>
                  </div>

                  <Formik
                    validationSchema={validationSchema}
                    initialValues={data}
                    onSubmit={ async (values, actions) => {
                      setLoader(prev => !prev);
                      const formData = new FormData();
                      formData.append("bulkupload", values.file);
                      formData.append("typeOfData", JSON.stringify([values.typeOfData]));
                      if(values.locationId === "c"){
                        formData.append("locationId", "");
                        formData.append("isConsolidatedData", true);
                      }else{
                        formData.append("locationId", values.locationId);
                      }
                      try {
                        const response = await AxiosPost(`${apipath}/csv-to-json`, formData);
                        if(response && response.statusCode === 200){
                          tog_center();
                          setUploaded(null);
                          setLoader(prev => !prev);
                          fileRef.current.value = "";
                          actions.resetForm();
                        }
                      } catch (error) {
                        actions.resetForm();
                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                      }
                    }}
                  >
                    {({ setFieldValue }) => (
                      <Form>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label>Select Data Type</Label>
                              <Field
                                as="select"
                                name="typeOfData"
                                className="form-select"
                              >
                                <option value="">Select</option>
                                <option value="Environmental">
                                  Environmental
                                </option>
                                <option value="Social">Social</option>
                                <option value="Greenhouse Gas">Greenhouse Gas</option>
                                <option value="Governance">Governance</option>
                                <option value="BRSR">BRSR</option>
                              </Field>
                              <ErrorMessage
                                name="typeOfData"
                                component="div"
                                className="red"
                              />
                            </div>
                            <div className="mb-3">
                              <Label>Select Location :</Label>
                              <Field
                                as="select"
                                name="locationId"
                                className="form-select"
                              >
                                <option value="">Select</option>
                                <option value="c">Consolidate</option>
                                {locationList.length > 0 && locationList.filter(opt => opt.isActive).map(item => (
                                  <option key={item._id} value={item._id}>
                                    {item.city} - {item.addressType}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="locationId"
                                component="div"
                                className="red"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label>File Upload</Label>
                              <input
                                type="file"
                                accept=".csv,text/csv"
                                className="form-control"
                                onChange={event => { setFieldValue("file", event.target.files[0]); }}
                                ref={fileRef}
                              />
                              <ErrorMessage
                                name="file"
                                component="div"
                                className="red"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            {uploaded && (
                              <div className="progress mb-3 mt-2">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  aria-valuenow={uploaded}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: `${uploaded}%` }}
                                >
                                  {`${uploaded}%`}
                                </div>
                              </div>
                            )}
                          </Col>
                        </Row>

                        <Row className="mt-4">
                          <Col lg="6">
                            <div className="mb-3">
                              {loader
                                ? (
                                  <button
                                    color="primary"
                                    className="btn btn-primary "
                                    type="button"
                                  >
                                  Uploading.....
                                  </button>
                                )
                                : (
                                  <button
                                    color="primary"
                                    className="btn btn-primary "
                                    type="submit"
                                  >
                                  Upload Data
                                  </button>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BulkUpload;
