import React from "react";
import { Col } from "reactstrap";
import { useAsyncDebounce } from "react-table";

const UsersFilter = ({ onClickFilterCallback, defaultKeyword, count }) => {
  const [keyword, setKeyword] = React.useState(defaultKeyword);

  const onChange = useAsyncDebounce(value => {
    onClickFilterCallback(value);
  }, 500);

  return (
    <Col sm={3}>
      <div className="search-box d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
                Search this table
            </span>
            <input
              value={keyword}
              onChange={e => {
                setKeyword(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              placeholder={`${count} records...`}
              style={{ borderRadius: "0.2rem", padding: "0.42rem" }}
            />
          </label>
          {/* <i className="bx bx-search-alt search-icon"></i> */}
        </div>
      </div>
    </Col>
  );
};

export default UsersFilter;
