import axios from "axios";
import { API_URLS } from "common/constants";
import { get, post } from "./api_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => getLoggedInUser() !== null;
// Register Method
const postRegister = async data => axios
  .post(API_URLS.COMPANY_DETAILS, data)
  .then(response => {
    if (response.status >= 200 || response.status <= 299) return response.data;
    throw response.data;
  })
  .catch(err => {
    // alert(err)
    let message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
      case 404:
        message = "Sorry! the page you are looking for could not be found";
        break;
      case 500:
        message =
              "Sorry! something went wrong, please contact our support team";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = err[1];
        break;
      }
    }
    throw message;
  });

// postForgetPwd
const postForgetPwd = async data => axios
  .post(API_URLS.FORGOT_PASSWORD, data)
  .then(response => {
    if (response.status >= 200 || response.status <= 299) return response.data;
    throw response.data;
  })
  .catch(err => {
    var message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
      case 404:
        message = "Sorry! the page you are looking for could not be found";
        break;
      case 500:
        message =
              "Sorry! something went wrong, please contact our support team";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = err[1];
        break;
      }
    }
    throw message;
  });

// RESEND VERIFY LINK
const postResendVerifyLink = async data => axios
  .post(API_URLS.RESEND_VERIFY_LINK, data)
  .then(response => {
    if (response.status >= 200 || response.status <= 299) return response.data;
    throw response.data;
  })
  .catch(err => {
    // alert(err)
    let message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
      case 404:
        message = "Sorry! the page you are looking for could not be found";
        break;
      case 500:
        message =
              "Sorry! something went wrong, please contact our support team";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = err[1];
        break;
      }
    }
    throw message;
  });

// get adminusers
export const getAdminusers = () => get(API_URLS.ADMIN_USERS);

// add CUSTOMER
export const addNewAdminuser = adminuser =>
  post(API_URLS.ADMIN_ADD_USERS, adminuser);

// update CUSTOMER
export const updateAdminuser = adminuser =>
  post(`${API_URLS.ADMIN_UPDATE_USERS}/${adminuser._id}`, {
    name: adminuser.name,
    email: adminuser.email,
    role: adminuser.role,
    locationId: adminuser.locationId
  });

// delete CUSTOMER
export const deleteAdminuser = adminuser =>
  post(`${API_URLS.ADMIN_DELETE_USERS}/${adminuser._id}`, {});

export {
  getLoggedInUser,
  isUserAuthenticated,
  postRegister,
  postResendVerifyLink,
  postForgetPwd
};
