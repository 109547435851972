import {
  RESEND_VERIFY_LINK,
  RESEND_VERIFY_LINK_FAILED,
  RESEND_VERIFY_LINK_RESET,
  RESEND_VERIFY_LINK_SUCCESS
} from "./actionTypes";

export const resendVerifyLink = data => ({
  type: RESEND_VERIFY_LINK,
  payload: { data }
});

export const resendVerifyLinkSuccess = data => ({
  type: RESEND_VERIFY_LINK_SUCCESS,
  payload: { data }
});
export const resendVerifyLinkFailed = error => ({
  type: RESEND_VERIFY_LINK_FAILED,
  payload: error
});
export const resendVerifyLinkReset = data => ({
  type: RESEND_VERIFY_LINK_RESET,
  payload: { data }
});
