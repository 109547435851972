import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Card, Col, DropdownToggle, DropdownMenu, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import Input from "components/Inputs/Input";
import FormUpload from "../Modalfileupload";
import TextArea from "components/Inputs/TextArea";
import TableInput from "components/Inputs/TableInput";
import { AxiosGet, AxiosPost } from "common/axiosService";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const Operation = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionA.operation;
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, onSectionChange, oldData, getFile, filesData } = props;

  const [filedatachange, setFileDataChange] = useState(true);
  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);
  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionA", subSection: "operation", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect(() => {
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionA",
        "subSection": "operation"
      });
    }
  }, [newReport, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionA",
      "subSection": "operation"
    });
  }, [filedatachange]);

  const empCounting = (currentObj) => {
    const nationalplants = Number(_get(currentObj, "national_plants", 0));
    const internationalplants = Number(_get(currentObj, "international_plants", 0));
    const nationaloffices = Number(_get(currentObj, "national_offices", 0));
    const internationaloffices = Number(_get(currentObj, "international_offices", 0));
    const total = nationalplants + nationaloffices;
    const total2 = internationalplants + internationaloffices;
    _set(data, "location.national_total", total);
    _set(data, "location.international_total", total2);
  };

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, operation: { ...data } });
    setData({ ...data });
  };

  const onHandleChangeloc = (e) => {
    const keys = e.target.name.split(".");
    const lastKey = keys.pop();
    const currentObj = keys.reduce((data, key) => data[key] = data[key] || {}, data);
    currentObj[lastKey] = e.target.value;
    empCounting(currentObj);
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, operation: { ...data } });
    setData({ ...data });
  };
  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionA",
        "subSection": "operation",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionA",
          "subSection": "operation"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  return (
    <React.Fragment>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className=" d-sm-flex align-items-center justify-content-between"
                    style={{ borderBottom: "1px solid #ccc", paddingBottom: "15px", marginBottom: "30px" }}>
                    <h4 className="mb-sm-0 font-size-18">Operations</h4> </div>
                </div>
              </div>
              <form
                onChange={onHandleChange}
                onSubmit={(e) => {
                  e.preventDefault();
                  _set(sebiCurrentData, "sectionA.operation", data);
                  updateSebiData(sebiCurrentData);
                  draftSebiData({ ...draftReport, operation: false });
                }}>
                <p><b>16. Number of locations where plants and/or operations/offices of the entity are situated:</b></p>
                <div className="table-responsive">
                  <table className="table table-bordered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th width="25%">Location</th>
                        <th className="text-align">Number of plants</th>
                        <th className="text-align">Number of offices</th>
                        <th className="text-align">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className="text-align-left">National</th>
                        <TableInput
                          type="number"
                          placeholder="Number of plants"
                          name="location.national_plants"
                          value={_get(data, "location.national_plants", 0)}
                          onChange={onHandleChangeloc}
                        />
                        <TableInput
                          type="number"
                          placeholder="Number of offices"
                          name="location.national_offices"
                          value={_get(data, "location.national_offices", "")}
                          onChange={onHandleChangeloc}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="location.national_total"
                          value={_get(data, "location.national_total", "")}
                          onChange={onHandleChangeloc}
                        />
                      </tr>
                      <tr>
                        <th className="text-align-left">International</th>
                        <TableInput
                          type="number"
                          placeholder="Number of plants"
                          name="location.international_plants"
                          value={_get(data, "location.international_plants", "")}
                          onChange={onHandleChangeloc}
                        />
                        <TableInput
                          type="number"
                          placeholder="Number of offices"
                          name="location.international_offices"
                          value={_get(data, "location.international_offices", "")}
                          onChange={onHandleChangeloc}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="location.international_total"
                          value={_get(data, "location.international_total", "")}
                          onChange={onHandleChangeloc}
                        />
                      </tr>
                    </tbody>
                  </table>
                </div>

                <Row className="mt-2 align-items-center">
                  {filesData?.number_of_locations?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && res.statusCode === 200 && res.data[0]) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "number_of_locations" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "csv"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);
                  })}
                  {filesData?.number_of_locations?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "number_of_locations" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "number_of_locations" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <div className="row mt-4">
                  <div className="col-lg-12">
                    <p><b>17. Markets served by the entity:</b></p>
                    <p>A. Number of locations</p>
                    <div className="table-responsive">
                      <table className="table table-bordered mb-0">
                        <thead className="table-light">
                          <tr>
                            <th width="75%">Locations</th>
                            <th className="text-align">Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className="text-align-left">National (No. of States) </th>
                            <TableInput
                              type="number"
                              placeholder="Number"
                              name="market_served.national"
                              value={_get(data, "market_served.national", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                          <tr>
                            <th className="text-align-left">International (No. of Countries) </th>
                            <TableInput
                              type="number"
                              placeholder="Number"
                              name="market_served.international"
                              value={_get(data, "market_served.international", "")}
                              onChange={onHandleChange}
                            />
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p className="mt-4">B. What is the contribution of exports as a percentage of the total turnover of the entity? </p>
                    <div style={{ maxWidth: "300px" }}>
                      <Input
                        type="number"
                        placeholder="Enter %"
                        name="what_is_the_contribution_of_exports"
                        value={_get(data, "what_is_the_contribution_of_exports", "")}
                        onChange={onHandleChange}
                      />
                    </div>
                    <p className="mt-4">C. A brief on types of customers</p>
                    <TextArea
                      name="brief_types_of_customers"
                      value={_get(data, "brief_types_of_customers", "")}
                      onChange={onHandleChange}
                    />
                  </div>
                </div>

                <Row className="mt-2 align-items-center">
                  {filesData?.markets_served_by_the_entity?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && res.statusCode === 200 && res.data[0]) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "markets_served_by_the_entity" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "csv"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}
                  {filesData?.markets_served_by_the_entity?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "markets_served_by_the_entity" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "markets_served_by_the_entity" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <button type="button" className="btn btn-primary w-md "
                      onClick={() => onSectionChange(2)}>Previous</button>{" "}
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                    <button type="button" className="btn btn-primary w-md "
                      onClick={() => onSectionChange(4)}>Next</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>;
    </React.Fragment>
  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionA?.operation,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.operation,
    filesData: state.NewReportReducer?.draftFiles?.operation
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(Operation);