import React from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Dots } from "react-activity";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Modal, Card, Col, DropdownToggle, DropdownMenu, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import ModalSection from "../ModalSection";
import { API_URLS } from "common/constants";
import Input from "components/Inputs/Input";
import FormUpload from "../Modalfileupload";
import TextArea from "components/Inputs/TextArea";
import TableInput from "components/Inputs/TableInput";
import RadioButton from "components/Inputs/RadioButton";
import { AxiosGet, AxiosPost } from "common/axiosService";
import TableTextArea from "components/Inputs/TableTextArea";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const Principle9 = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionC.principle_9;
  const userData = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")) : "";
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, prefillData, previousData, oldData, onSectionChange, getFile, filesData } = props;
  const [pdfbtn, setPdfBtn] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [modalApproval, setModalApproval] = useState(false);
  const [error, setError] = useState({ email: false, name: false });
  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [modalData, setModalData] = useState({ name: "", value: "", type: "", title: "" });
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);

  const [filedatachange, setFileDataChange] = useState(true);

  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionC", subSection: "principle_9", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  useEffect(() =>{
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_9"
      });
    }
  }, [newReport, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionC",
      "subSection": "principle_9"
    });
  }, [filedatachange]);

  useEffect(() =>{
    if(prefillData){
      ["data_privacy", "advertising", "cyber_security", "delivery_of_essential_services", "restrictive_trade_practices", "unfair_trade_practices", "other"].forEach(item => {
        ["no_resolved", "no_received"].forEach(subItem => {
          const setData = _get(data, `customer_complaints.${item}.${subItem}`, "") ?
            _get(data, `customer_complaints.${item}.${subItem}`, "")
            : _get(prefillData, `customer_complaints.${item}.${subItem}`, "");
          _set(data, `customer_complaints.${item}.${subItem}`, setData);
        });
      });
    }
    if (previousData) {
      ["data_privacy", "advertising", "cyber_security", "delivery_of_essential_services", "restrictive_trade_practices", "unfair_trade_practices", "other"].forEach(item =>{
        _set(data, `customer_complaints.${item}.previous.no_received`, _get(previousData, `customer_complaints.${item}.no_received`, ""));
        _set(data, `customer_complaints.${item}.previous.no_resolved`, _get(previousData, `customer_complaints.${item}.no_resolved`, ""));
        _set(data, `customer_complaints.${item}.previous.remarks`, _get(previousData, `customer_complaints.${item}.remarks`, ""));
      });
    }
  }, [prefillData, previousData]);

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, principle_9: data });
    setData({ ...data });
  };

  function tog_center () {
    setmodal_center(!modal_center);
  }
  const toggleForApproval = () => setModalApproval(!modalApproval);
  const oninputFocus = (e, title, name1) => {
    if(!modal_center){
      e.target.blur();
    }
    setModalData({
      title: title,
      name: name1,
      value: _get(data, name1, "")
    });
    tog_center();
  };

  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_9",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionC",
          "subSection": "principle_9"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Please Enter Valid Email.";
    }
    if (!values.name) {
      errors.name = "Name is Required";
    }
    setError({ name: errors.name, email: errors.email });
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      name: ""
    },
    validate,
    onSubmit: async (values) => {
      const response = await AxiosPost(API_URLS.SEND_REPORT_FOR_APPROVAL, {
        email: values?.email,
        reviewerName: values?.name,
        senderName: _get(userData, "userData.user.name", ""),
        year: newReport?.currentYear?.year
      });
      if (response && response.statusCode === 200) {
        formik.setValues({ name: "", email: "" });
        setError({ name: false, email: false });
        toggleForApproval();
        toast.success(response.message);
      }
    }
  });
  return (
    <React.Fragment>
      <Modal className="center-modal"
        size="lg" isOpen={modal_center}
        toggle={() => { tog_center(); }}
        centered
        autoFocus={false}
        backdrop={"static"}
      >
        <ModalSection modalData={modalData} setModalData={setModalData} state={data} setState={setData} setmodal_center={setmodal_center}/>
        <div className="modal-footer">
          <button
            onClick={(e) => {
              setmodal_center(false);
              e.target.blur();
              setModalData({ name: "", value: "", type: "", title: "" });
            }}
            className="btn btn-primary"
          >Update</button>
        </div>
      </Modal>
      <Modal className="center-modal"
        size="lg"
        isOpen={modalApproval}
        toggle={() => { toggleForApproval(); }}
        centered
        autoFocus={false}
        backdrop={"static"}
      >
        <div className="modal-content">
          <form>
            <div className="modal-body">
              <Col xl={6} sm={4} >
                <Card className="shadow-none border">
                  <div >
                    <div className="avatar-xs me-3 mb-3">
                      <div className="avatar-title bg-transparent rounded">
                        <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i>
                      </div>
                    </div>
                    <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>BRSR-Report{newReport?.currentYear?.year}.pdf</h5>
                  </div>
                </Card>
              </Col>
              <Row>
                <Col xl={6} sm={4} >
                  <label htmlFor="email">Email</label>
                  <input
                    className={error.email ? "form-control is-invalid" : "form-control"}
                    type="email"
                    name="email"
                    id="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {error.email && (
                    <span className="invalid-msg">{error.email}</span>
                  )}
                </Col>
                <Col xl={6} sm={4} >
                  <label htmlFor="name">Name</label>
                  <input
                    className={error.name ? "form-control is-invalid" : "form-control"}
                    type="text"
                    name="name"
                    id="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  {error.name && (
                    <span className="invalid-msg">{error.name}</span>
                  )}
                </Col>
              </Row>
              <p className="mt-3"><b>Note</b> :- Generate report before sending otherwise last generated report sended to the client.</p>
            </div>
            <div className="modal-footer">
              <button type="button" disabled={(!error.name && !error.email && error.email !== false && error.name !== false) ? false : true } className="btn btn-primary" onClick={formik.handleSubmit}>
              Send
              </button>
              <button
                onClick={() =>{
                  formik.setValues({ name: "", email: "" });
                  setError({ name: false, email: false });
                  toggleForApproval();
                }}
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
              Close
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <Row>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <form
                onChange={onHandleChange}
                onSubmit={(e) => {
                  e.preventDefault();
                  _set(sebiCurrentData, "sectionC.principle_9", data);
                  updateSebiData(sebiCurrentData);
                  draftSebiData({ ...draftReport, principle_9: false });
                }}>
                <h5 className="mt-2">
                  <b>
                  PRINCIPLE 9 Businesses should engage with and provide value to their consumers in a responsible manner
                  </b>
                </h5>
                <p className="txt-decoration mt-2">
                  <b>Essential Indicators</b>
                </p>

                <p className="mt-2">
                1. Describe the mechanisms in place to receive and respond to consumer complaints and feedback.
                </p>
                <TextArea
                  name="consumer_complaints_and_feedback"
                  value={_get(data, "consumer_complaints_and_feedback", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">
                2. Turnover of products and/ services as a percentage of turnover from all products/service that carry information about:
                </p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th></th>
                      <th className="text-align">
                      As a percentage to total turnover
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-align-left">
                      Environment and social parameters relevent to the product
                      </td>
                      <TableInput
                        type="number"
                        name="products_service_that_carry_information.environmental_and_social"
                        value={_get(data, "products_service_that_carry_information.environmental_and_social", "")}
                        onChange={onHandleChange} />
                    </tr>
                    <tr>
                      <td className="text-align-left">
                      Safe and responsible usage recycling and/or safe disposal
                      </td>
                      <TableInput
                        type="number"
                        name="products_service_that_carry_information.safe_disposal"
                        value={_get(data, "products_service_that_carry_information.safe_disposal", "")}
                        onChange={onHandleChange} />
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.rcentage_of_turnover?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "rcentage_of_turnover" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.rcentage_of_turnover?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "rcentage_of_turnover" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "rcentage_of_turnover" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">
                3. Number of consumer complaints in respect of the following:
                </p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="text-align" rowSpan="2"></th>
                      <th className="text-align" colSpan="2">
                      FY (Current Financial Year)
                      </th>
                      <th className="text-align" rowSpan="2">
                      Remarks
                      </th>
                      <th className="text-align" colSpan="2">
                      FY (Previous Financial Year)
                      </th>
                      <th className="text-align" rowSpan="2">
                      Remarks
                      </th>
                    </tr>
                    <tr>
                      <th width="12%" className="text-align">
                      Received during the year
                      </th>
                      <th width="12%" className="text-align">
                      Pending resolution at end of year
                      </th>
                      <th width="12%" className="text-align">
                      Received during the year
                      </th>
                      <th width="12%" className="text-align">
                      Pending resolution at end of year
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-align-left">Data privacy</td>
                      <TableInput className="text-align"
                        name="customer_complaints.data_privacy.no_received"
                        value={_get(data, "customer_complaints.data_privacy.no_received", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableInput className="text-align"
                        name="customer_complaints.data_privacy.no_resolved"
                        value={_get(data, "customer_complaints.data_privacy.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.data_privacy.remarks"
                        value={_get(data, "customer_complaints.data_privacy.remarks", "")}
                        onChange={onHandleChange}/>
                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.data_privacy.previous.no_received"
                        value={_get(data, "customer_complaints.data_privacy.previous.no_received", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.data_privacy.previous.no_resolved"
                        value={_get(data, "customer_complaints.data_privacy.previous.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableTextArea
                        isDisabled={true}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.data_privacy.previous.remarks"
                        value={_get(data, "customer_complaints.data_privacy.previous.remarks", "")}
                        onChange={onHandleChange} />
                    </tr>

                    <tr>
                      <td className="text-align-left">Advertising</td>
                      <TableInput className="text-align"
                        name="customer_complaints.advertising.no_received"
                        value={_get(data, "customer_complaints.advertising.no_received", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableInput className="text-align"
                        name="customer_complaints.advertising.no_resolved"
                        value={_get(data, "customer_complaints.advertising.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.advertising.remarks"
                        value={_get(data, "customer_complaints.advertising.remarks", "")}
                        onChange={onHandleChange}/>
                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.advertising.previous.no_received"
                        value={_get(data, "customer_complaints.advertising.previous.no_received", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.advertising.previous.no_resolved"
                        value={_get(data, "customer_complaints.advertising.previous.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableTextArea
                        isDisabled={true}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.advertising.previous.remarks"
                        value={_get(data, "customer_complaints.advertising.previous.remarks", "")}
                        onChange={onHandleChange} />
                    </tr>

                    <tr>
                      <td className="text-align-left">Cyber-security</td>
                      <TableInput className="text-align"
                        name="customer_complaints.cyber_security.no_received"
                        value={_get(data, "customer_complaints.cyber_security.no_received", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableInput className="text-align"
                        name="customer_complaints.cyber_security.no_resolved"
                        value={_get(data, "customer_complaints.cyber_security.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.cyber_security.remarks"
                        value={_get(data, "customer_complaints.cyber_security.remarks", "")}
                        onChange={onHandleChange}/>
                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.cyber_security.previous.no_received"
                        value={_get(data, "customer_complaints.cyber_security.previous.no_received", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.cyber_security.previous.no_resolved"
                        value={_get(data, "customer_complaints.cyber_security.previous.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableTextArea
                        isDisabled={true}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.cyber_security.previous.remarks"
                        value={_get(data, "customer_complaints.cyber_security.previous.remarks", "")}
                        onChange={onHandleChange} />
                    </tr>

                    <tr>
                      <td className="text-align-left">
                      Delivery of essential services{" "}
                      </td>
                      <TableInput className="text-align"
                        name="customer_complaints.delivery_of_essential_services.no_received"
                        value={_get(data, "customer_complaints.delivery_of_essential_services.no_received", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableInput className="text-align"
                        name="customer_complaints.delivery_of_essential_services.no_resolved"
                        value={_get(data, "customer_complaints.delivery_of_essential_services.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.delivery_of_essential_services.remarks"
                        value={_get(data, "customer_complaints.delivery_of_essential_services.remarks", "")}
                        onChange={onHandleChange}/>
                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.delivery_of_essential_services.previous.no_received"
                        value={_get(data, "customer_complaints.delivery_of_essential_services.previous.no_received", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.delivery_of_essential_services.previous.no_resolved"
                        value={_get(data, "customer_complaints.delivery_of_essential_services.previous.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableTextArea
                        isDisabled={true}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.delivery_of_essential_services.previous.remarks"
                        value={_get(data, "customer_complaints.delivery_of_essential_services.previous.remarks", "")}
                        onChange={onHandleChange} />
                    </tr>

                    <tr>
                      <td className="text-align-left">
                      Restrictive Trade Practices
                      </td>
                      <TableInput className="text-align"
                        name="customer_complaints.restrictive_trade_practices.no_received"
                        value={_get(data, "customer_complaints.restrictive_trade_practices.no_received", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableInput className="text-align"
                        name="customer_complaints.restrictive_trade_practices.no_resolved"
                        value={_get(data, "customer_complaints.restrictive_trade_practices.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.restrictive_trade_practices.remarks"
                        value={_get(data, "customer_complaints.restrictive_trade_practices.remarks", "")}
                        onChange={onHandleChange}/>
                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.restrictive_trade_practices.previous.no_received"
                        value={_get(data, "customer_complaints.restrictive_trade_practices.previous.no_received", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.restrictive_trade_practices.previous.no_resolved"
                        value={_get(data, "customer_complaints.restrictive_trade_practices.previous.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableTextArea
                        isDisabled={true}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.restrictive_trade_practices.previous.remarks"
                        value={_get(data, "customer_complaints.restrictive_trade_practices.previous.remarks", "")}
                        onChange={onHandleChange} />
                    </tr>

                    <tr>
                      <td className="text-align-left">Unfair Trade Practices</td>
                      <TableInput className="text-align"
                        name="customer_complaints.unfair_trade_practices.no_received"
                        value={_get(data, "customer_complaints.unfair_trade_practices.no_received", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableInput className="text-align"
                        name="customer_complaints.unfair_trade_practices.no_resolved"
                        value={_get(data, "customer_complaints.unfair_trade_practices.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.unfair_trade_practices.remarks"
                        value={_get(data, "customer_complaints.unfair_trade_practices.remarks", "")}
                        onChange={onHandleChange}/>
                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.unfair_trade_practices.previous.no_received"
                        value={_get(data, "customer_complaints.unfair_trade_practices.previous.no_received", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.unfair_trade_practices.previous.no_resolved"
                        value={_get(data, "customer_complaints.unfair_trade_practices.previous.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableTextArea
                        isDisabled={true}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.unfair_trade_practices.previous.remarks"
                        value={_get(data, "customer_complaints.unfair_trade_practices.previous.remarks", "")}
                        onChange={onHandleChange} />
                    </tr>

                    <tr>
                      <td className="text-align-left"> Other</td>
                      <TableInput className="text-align"
                        name="customer_complaints.other.no_received"
                        value={_get(data, "customer_complaints.other.no_received", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableInput className="text-align"
                        name="customer_complaints.other.no_resolved"
                        value={_get(data, "customer_complaints.other.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.other.remarks"
                        value={_get(data, "customer_complaints.other.remarks", "")}
                        onChange={onHandleChange}/>
                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.other.previous.no_received"
                        value={_get(data, "customer_complaints.other.previous.no_received", "")}
                        type="number"
                        onChange={onHandleChange}/>

                      <TableInput className="text-align"
                        isDisabled={true}
                        name="customer_complaints.other.previous.no_resolved"
                        value={_get(data, "customer_complaints.other.previous.no_resolved", "")}
                        type="number"
                        onChange={onHandleChange} />

                      <TableTextArea
                        isDisabled={true}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                        name="customer_complaints.other.previous.remarks"
                        value={_get(data, "customer_complaints.other.previous.remarks", "")}
                        onChange={onHandleChange} />
                    </tr>

                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.consumer_complaints?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "consumer_complaints" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.consumer_complaints?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "consumer_complaints" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "consumer_complaints" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">
                4. Details of instances of product recalls on account of safety issues:
                </p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th></th>
                      <th className="text-align">Number</th>
                      <th className="text-align">Reasons for recall</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-align-left">Voluntarily recalls</td>
                      <TableInput className="text-align"
                        name="account_of_safety_issues.voluntary_recalls.number"
                        value={_get(data, "account_of_safety_issues.voluntary_recalls.number", "")}
                        onChange={onHandleChange} type="number"/>

                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Reasons for recall"}
                        name="account_of_safety_issues.voluntary_recalls.reasons"
                        value={_get(data, "account_of_safety_issues.voluntary_recalls.reasons", "")}
                        onChange={onHandleChange}/>
                    </tr>
                    <tr>
                      <td className="text-align-left">Forced recalls</td>
                      <TableInput className="text-align"
                        name="account_of_safety_issues.forced_recalls.number"
                        value={_get(data, "account_of_safety_issues.forced_recalls.number", "")}
                        onChange={onHandleChange} type="number"/>

                      <TableTextArea
                        onFocus={oninputFocus}
                        title={"Reasons for recall"}
                        name="account_of_safety_issues.forced_recalls.reasons"
                        value={_get(data, "account_of_safety_issues.forced_recalls.reasons", "")}
                        onChange={onHandleChange} />
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.product_recalls?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "product_recalls" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.product_recalls?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "product_recalls" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "product_recalls" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">
                5. Does the entity have a framework/ policy on cyber security and risks related to data privacy? (Yes/No) If available, provide a web-link of the policy.
                </p>
                <RadioButton className="dis-right ml-10" id="radio_74button"
                  name="entity_have_a_framework/policy_on_cyber_security"
                  value={_get(data, "entity_have_a_framework/policy_on_cyber_security", "")}
                  onChange={onHandleChange}
                />
                <TextArea
                  name="weblink"
                  value={_get(data, "weblink", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">
                6. Provide details of any corrective actions taken or underway on issues relating to advertising, and delivery of essential services;
                cyber security and data privacy of customers; re-occurrence of instances of product recalls; penalty / action taken by regulatory authorities on safety of products / services.
                </p>
                <TextArea
                  name="action_taken_by_regulatory_authorities"
                  value={_get(data, "action_taken_by_regulatory_authorities", "")}
                  onChange={onHandleChange}
                />

                <p className="txt-decoration mt-2">
                  <b>Leadership Indicators</b>
                </p>

                <p className="mt-2">
                1. Channels / platforms where information on products and services of the entity can be accessed (provide web link, if available).
                </p>
                <Input
                  name="platforms_where_information_on_products"
                  value={_get(data, "platforms_where_information_on_products", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">
                2. Steps taken to inform and educate consumers about safe and responsible usage of products and/or services
                </p>
                <TextArea
                  name="responsible_usage_of_products"
                  value={_get(data, "responsible_usage_of_products", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">
                3. Mechanisms in place to inform consumers of any risk of disruption/discontinuation of essential services.
                </p>
                <TextArea
                  name="discontinuation_of_essential_services"
                  value={_get(data, "discontinuation_of_essential_services", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">
                4. Does the entity display product information on the product over and above what is mandated as per local laws? (Yes/No/Not Applicable) <br /> If yes, provide details in brief.
                </p>
                <div className="dis-right">
                  <div className="form-check form-check-inline" >
                    <input className="form-check-input" type="radio" value="Yes" id={"radioBtn123"}
                      name="company_display_product_information.value"
                      onClick={onHandleChange}
                      onChange={onHandleChange}
                      checked={_get(data, "company_display_product_information.value", "") === "Yes"}
                    />
                    <label className="form-check-label"> Yes </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" value="No"
                      checked={_get(data, "company_display_product_information.value", "") === "No"} id={"radioBtn123"}
                      name="company_display_product_information.value"
                      onClick={onHandleChange}
                      onChange={onHandleChange}
                    />
                    <label className="form-check-label"> No </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" value="Not Applicable" id={"radioBtn123"}
                      name="company_display_product_information.value"
                      checked={_get(data, "company_display_product_information.value", "") === "Not Applicable"}
                      onClick={onHandleChange}
                      onChange={onHandleChange}
                    />
                    <label className="form-check-label">Not Applicable</label>
                  </div>
                </div>
                <TextArea
                  name="company_display_product_information.details"
                  value={_get(data, "company_display_product_information.details", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">
                Did your entity carry out any survey with regard to consumer satisfaction relating to the major products / services of the entity,
                significant locations of operation of the entity or the entity as a whole? (Yes/No)
                </p>
                <RadioButton className="dis-right" id="radio_75button"
                  name="consumer_satisfaction_relating"
                  value={_get(data, "consumer_satisfaction_relating", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">
                5. Provide the following information relating to data breaches:
                </p>

                <p className="mt-2">
                A. Number of instances of data breaches along-with impact
                </p>
                <Input
                  name="information_relating_to_data_breaches.number_of_instances"
                  value={_get(data, "information_relating_to_data_breaches.number_of_instances", "")}
                  onChange={onHandleChange}
                  type="number"
                />

                <p className="mt-2">
                B. Percentage of data breaches involving personally identifiable information of customers
                </p>
                <Input
                  name="information_relating_to_data_breaches.percentage_of_data_breaches"
                  value={_get(data, "information_relating_to_data_breaches.percentage_of_data_breaches", "")}
                  onChange={onHandleChange}
                  type="number"
                />

                <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <button type="button" className="btn btn-primary w-md "
                      onClick={() => onSectionChange(16)}>Previous</button>{" "}
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                    {pdfbtn ?
                      <button
                        type="button"
                        color="primary"
                        className="btn btn-primary w-md">
                        <Dots size={13} speed={0.6} />
                      </button>
                      :
                      <button
                        type="button"
                        color="primary"
                        id="generateReport"
                        className="btn btn-primary w-md"
                        onClick={ async () => {
                          setPdfBtn(true);
                          try {
                            const userToken = localStorage.getItem("accessToken");
                            const res = await AxiosGet(`${API_URLS.GENERATE_REPORT}/${sebiCurrentData.year}/${userToken}`);
                            if (res && (res.statusCode === 200 && res.data[0])) {
                              let a = document.createElement("a");
                              a.download = `BRSR-Report${sebiCurrentData.year}.pdf`;
                              a.href = `data:application/pdf;base64,${res.data[0]}`;
                              a.click();
                              a.remove();
                              toast.success("PDF Download Succesfully");
                              setPdfBtn(false);
                            } else {
                              toast.error(res.message);
                              return;
                            }
                          } catch (error) {
                            toast.error("Something Went Wrong");
                          }
                        }}
                      >
                         Generate Report
                      </button>
                    }
                    {" "}<button
                      type="button"
                      className="btn btn-primary w-md"
                      onClick={() => {
                        toggleForApproval();
                      }}
                    >Review</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionC?.principle_9,
    previousData: state.NewReportReducer?.sebiForm?.previousYearData?.sectionC?.principle_9,
    prefillData: state.NewReportReducer?.sebiForm?.sebiPrefil,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.principle_9,
    filesData: state.NewReportReducer?.draftFiles?.h_s_a
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(Principle9);
