// @flow
import * as actionTypes from "./actionTypes";
const INIT_STATE = {
  sebiForm: {},
  sebiFormLoading: null,
  sebiFormUpdating: null
};

const ReportReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case actionTypes.APPEND_SEBI_FORM:
    return {
      ...state,
      sebiForm: {
        ...state.sebiForm,
        [action.payload.key]: action.payload.value
      }
    };
  case actionTypes.LOAD_SEBI_FORM_LOADING:
    return {
      ...state,
      sebiFormLoading: action.payload
    };
  case actionTypes.LOAD_SEBI_FORM_SUCCESS:
    if (action.payload) {
      return {
        ...state,
        sebiForm: {
          ...state.sebiForm,
          ...action.payload
        }
      };
    } else {
      return state;
    }
  case actionTypes.UPDATE_SEBI_FORM_LOADING:
    return {
      ...state,
      sebiFormUpdating: action.payload
    };
  default:
    return state;
  }
};

export default ReportReducer;
