import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { isObject } from "lodash";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logo_sm from "../../assets/images/logo-sm.png";

import IsAuth from "./IsAuth";
import { API_URLS } from "common/constants";

const Region = () => {
  //meta title
  document.title = "Region | Greenalytics";

  const history = useHistory();

  const [URL, setURL] = useState(null);
  const [region, setRegion] = useState("US");
  const [isLoading, setIsLoading] = useState(false);
  const [domainTXT, setDomainTXT] = useState(".app.us.greenalytics.in");
  const [isValid, setIsValid] = useState({ valid: "", message: "" });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { personalUrl: "", region: "" },
    validationSchema: Yup.object({
      personalUrl: Yup.string()
        .matches(/^[aA-zZ\s]+$/, "Numbers and special character characters are not allowed.")
        .required("Please Enter Your URL")
    }),
    onSubmit: values => {
      // dispatch(registerUser(values));
      setIsLoading(true);
      registerUserData(values);
      setTimeout(() => {
        setIsLoading(false);
        history.push({
          pathname: "/register-company",
          state: { personalUrl: values.personalUrl + domainTXT }
        });
      }, 2000);
    }
  });

  const { user, registrationError } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading
  }));

  const registerUserData = async () => {
    // alert(JSON.stringify(value))
  };

  const onValueChange = async event => {
    setRegion(event.target.value);
    setDomainTXT(`.app.${event.target.value.toLowerCase()}.greenalytics.in`);
    if (URL != null) {
      // setpersonalUrl(`${URL}.app.${event.target.value.toLowerCase()}.greenalytics.in`)
      const domain = { personalUrl: `${URL}.app.${event.target.value.toLowerCase()}.greenalytics.in` };
      try {
        let res = await fetch(API_URLS.COMPANY_URL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(domain)
        });
        let resJson = await res.json();
        if (resJson.statusCode === 200) {
          setIsValid({ valid: true, message: resJson.message });
        } else {
          setIsValid({ valid: false, message: resJson.message });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleOnChange = async e => {
    // eslint-disable-next-line
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (e.target.name == "personalUrl") {
      if (!format.test(e.target.value)) {
        setURL(e.target.value);
        const domain = {
          personalUrl: e.target.value.trim().toLowerCase() + domainTXT
        };
        try {
          let res = await fetch(API_URLS.COMPANY_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(domain)
          });
          let resJson = await res.json();
          if (resJson.statusCode === 200) {
            setIsValid({
              valid: true,
              message: resJson.message
            });
          } else {
            setIsValid({
              valid: false,
              message: resJson.message
            });
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        setIsValid({
          valid: false,
          message: "not allow numbers and special characters"
        });
        return false;
      }
    }
  };

  const getFirstErrorKey = (object, keys = []) => {
    const firstErrorKey = Object.keys(object)[0];
    if (isObject(object[firstErrorKey])) {
      return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
    }
    return [...keys, firstErrorKey].join(".");
  };

  useEffect(() => {
    if (
      !validation.isValid &&
      validation.submitCount !== 0 &&
      validation.isSubmitting
    ) {
      const firstErrorKey = getFirstErrorKey(validation.errors);
      if (global.window.document.getElementsByName(firstErrorKey).length) {
        global.window.document.getElementsByName(firstErrorKey)[0].focus();
      }
    }
  }, [
    validation.submitCount,
    validation.isValid,
    validation.errors,
    validation.isSubmitting
  ]);
  return (
    <React.Fragment>
      <IsAuth />
      <div className="account-pages my-5 pt-sm-5" id="register">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Region</h5>
                        {/* <p>Get your free Skote account now.</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div
                        className="avatar-md profile-user-wid mb-4"
                        style={{ float: "left" }}
                      >
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo_sm}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2" style={{ clear: "both" }}>
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      onChange={handleOnChange}
                    >
                      {user && user
                        ? (
                          <Alert color="success">
                          Register User Successfully
                          </Alert>
                        )
                        : null}

                      {registrationError && registrationError
                        ? (
                          <Alert color="danger">{registrationError}</Alert>
                        )
                        : null}

                      <div className="mb-2">
                        <Label for="region" className="form-label">
                          Select the data center region to store your
                          Greenalytics specific product data:
                        </Label>
                      </div>

                      <div className="mb-1">
                        <div className="form-check">
                          <Input
                            id="us"
                            name="region"
                            type="radio"
                            onClick={onValueChange}
                            value="US"
                            checked={region === "US" ? true : false}
                          />
                          <Label className="form-check-label" for="us">
                            America (us-east-1), N. Virginia
                          </Label>
                        </div>
                      </div>

                      <div className="mb-1">
                        <div className="form-check">
                          <Input
                            id="ap"
                            name="region"
                            type="radio"
                            onClick={onValueChange}
                            value="AP"
                            checked={region === "AP" ? true : false}
                          />
                          <Label className="form-check-label" for="ap">
                            Asia Pacific (ap-south-1), Mumbai
                          </Label>
                        </div>
                      </div>

                      <div className="mb-1">
                        <div className="form-check">
                          <Input
                            id="eu"
                            name="region"
                            type="radio"
                            onClick={onValueChange}
                            value="EU"
                            checked={region === "EU" ? true : false}
                          />
                          <Label className="form-check-label" for="eu">
                            Europe (eu-west-1), Ireland
                          </Label>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="form-check">
                          <Input
                            id="af"
                            name="region"
                            type="radio"
                            onClick={onValueChange}
                            value="AF"
                            checked={region === "AF" ? true : false}
                          />
                          <Label className="form-check-label" for="af">
                            Africa (af-south-1), Cape Town
                          </Label>
                        </div>
                      </div>

                      <div
                        className="alert alert-primary fade show mt-2"
                        role="alert"
                      >
                        <i className="mdi mdi-alert-circle me-2"></i>
                        Some of your data is automatically stored in the US.
                      </div>

                      <div className="mb-3" style={{ position: "relative" }}>
                        <Label for="personalUrl" className="form-label">
                          Your URL
                        </Label>
                        <Input
                          id="personalUrl"
                          name="personalUrl"
                          type="text"
                          className={`right-place ${isValid.valid === false ? "is-invalid" : ""
                          }`}
                          placeholder="Enter Your URL"
                          valid={isValid.valid}
                          onChange={event => {
                            validation.handleChange(event);
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.personalUrl
                            .trim()
                            .toLowerCase()}
                          invalid={
                            validation.touched.personalUrl &&
                              validation.errors.personalUrl
                              ? true
                              : false
                          }
                          autoComplete="off"
                        />
                        {/* eslint-disable-next-line */}
                        <span url={domainTXT}></span>
                        {validation.touched.personalUrl &&
                          validation.errors.personalUrl
                          ? (
                            <FormFeedback type="invalid">
                              {validation.errors.personalUrl}
                            </FormFeedback>
                          )
                          : null}
                        <div
                          className={
                            isValid.valid === true ? "valid-msg" : "invalid-msg"
                          }
                          name="validate"
                          id="validate2"
                        >
                          {isValid.valid === true
                            ? ""
                            : isValid.message}
                        </div>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                          disabled={
                            isLoading ||
                            !isValid.valid ||
                            validation.errors.personalUrl
                          }
                        >
                          {isLoading
                            ? (
                              <div
                                className="spinner-border text-white"
                                role="status"
                                style={{ width: "1.2rem", height: "1.2rem" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            )
                            : (
                              "Register"
                            )}
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <p className="mb-0">
                          By clicking below, you agree to the Green{" "}
                          <Link to="#" className="text-primary">
                            Terms of Services
                          </Link>{" "}
                          and{" "}
                          <Link to="#" className="text-primary">
                            Privacy Policy
                          </Link>
                          .{" "}
                        </p>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Greenalytics. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Glasier Inc.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Region;
