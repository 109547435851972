/* eslint-disable max-len */
export const GHGFormBuilderPoints = [
  {
    "_id": "63061962fbe841ed875fea04",
    "categoryName": "Scope 1",
    "data": [],
    "isOpen": true,
    "heading": "Scope 1: Direct GHG emissions",
    "discription": "Direct GHG emissions occur from sources that are owned or controlled by the company, for example, emissions from combustion in owned or controlled boilers, furnaces, vehicles, emissions from chemical production in owned or controlled process equipment.",
    "typeOfData": "Direct emissions",
    "subCategory": [
      {
        "categoryName": "Stationary combustion",
        "isOpen": false,
        "data": [
          {
            "formFields": {
              "fuel_quantity": {
                "elementType": "input",
                "elementConfig": {
                  "name": "value",
                  "type": "number",
                  "placeholder": "Enter Quantity"
                },
                "value": "",
                "validation": {
                  "required": false,
                  "error": ""
                },
                "valid": false,
                "touched": false
              },

              "comment": {
                "elementType": "textarea",
                "elementConfig": {
                  "name": "comment",
                  "type": "text",
                  "placeholder": "Add Comment"
                },
                "value": "",
                "validation": {
                  "required": false,
                  "error": ""
                },
                "valid": true,
                "touched": true
              },
              "file": {
                "elementType": "input",
                "elementConfig": {
                  "name": "file",
                  "type": "file",
                  "placeholder": "Upload Supporting Document"
                },
                "value": "",
                "validation": {
                  "required": false,
                  "error": ""
                },
                "valid": true,
                "touched": true
              }
            }
          }
        ],
        "discription": "Includes fuel consumption at a facility to produce electricity, steam, heat, or power.The combustion of fossil fuels by natural gas boilers, diesel generators and other equipment emits carbon dioxide, methane, and nitrous oxide into the atmosphere.",
        "_id": "63062551004faa278f58c0f9"
      },
      {
        "categoryName": "Mobile combustion",
        "isOpen": false,
        "data": [
          {
            "formFields": {

              "fuel_quantity": {
                "elementType": "input",
                "elementConfig": {
                  "type": "number",
                  "placeholder": "Enter Quantity"
                },
                "value": "",
                "validation": {
                  "required": true,
                  "error": ""
                },
                "valid": false,
                "touched": false
              },

              "comment": {
                "elementType": "textarea",
                "elementConfig": {
                  "type": "text",
                  "placeholder": "Add Comment"
                },
                "value": "",
                "validation": {
                  "required": false,
                  "error": ""
                },
                "valid": true,
                "touched": true
              },
              "file": {
                "elementType": "input",
                "elementConfig": {
                  "type": "file",
                  "placeholder": "Upload Supporting Document"
                },
                "value": "",
                "validation": {
                  "required": false,
                  "error": ""
                },
                "valid": true,
                "touched": true
              }
            }
          }
        ],
        "discription": "Mobile combustion: combustion of fuels in transportation devices such as automobiles, trucks, buses, trains, airplanes, boats, ships, barges, vessels, etc.",
        "_id": "63062551004faa278f58c0fa"
      },
      {
        "categoryName": "Process emissions",
        "isOpen": false,
        "data": [
          {
            "formFields": {

              "fuel_quantity": {
                "elementType": "input",
                "elementConfig": {
                  "type": "number",
                  "placeholder": "Enter Unit Quantity"
                },
                "value": "",
                "validation": {
                  "required": true,
                  "error": ""
                },
                "valid": false,
                "touched": false
              },

              "comment": {
                "elementType": "textarea",
                "elementConfig": {
                  "type": "text",
                  "placeholder": "Add Comment"
                },
                "value": "",
                "validation": {
                  "required": false,
                  "error": ""
                },
                "valid": true,
                "touched": true
              },
              "file": {
                "elementType": "input",
                "elementConfig": {
                  "type": "file",
                  "placeholder": "Upload Supporting Document"
                },
                "value": "",
                "validation": {
                  "required": false,
                  "error": ""
                },
                "valid": true,
                "touched": true
              }
            }
          }
        ],
        "discription": "Process emissions: emissions from physical or chemical processes such as CO2 from the calcination step in cement manufacturing, CO2 from catalytic cracking in petrochemical processing, PFC emissions from aluminum smelting, etc.",
        "_id": "63062551004gffaa278f58c0fa"
      },
      {
        "categoryName": "Fugitive emissions",
        "isOpen": false,
        "data": [
          {
            "formFields": {
              "fuel_quantity": {
                "elementType": "input",
                "elementConfig": {
                  "type": "number",
                  "placeholder": "Enter Quantity"
                },
                "value": "",
                "validation": {
                  "required": true,
                  "error": ""
                },
                "valid": false,
                "touched": false
              },

              "comment": {
                "elementType": "textarea",
                "elementConfig": {
                  "type": "text",
                  "placeholder": "Add Comment"
                },
                "value": "",
                "validation": {
                  "required": false,
                  "error": ""
                },
                "valid": true,
                "touched": true
              },
              "file": {
                "elementType": "input",
                "elementConfig": {
                  "type": "file",
                  "placeholder": "Upload Supporting Document"
                },
                "value": "",
                "validation": {
                  "required": false,
                  "error": ""
                },
                "valid": true,
                "touched": true
              }
            }
          }
        ],
        "discription": "Fugitive emissions: intentional and unintentional releases such as equipment leaks from joints, seals, packing, gaskets, as well as fugitive emissions from coal piles, wastewater treatment, pits, cooling towers, gas processing facilities, etc.",
        "_id": "63062551re004faa278f58c0fa"
      }
    ]
  },
  {
    "_id": "63062227795e1d5c75d185f5",
    "categoryName": "Scope 2",
    "isOpen": false,
    "data": [
      {
        "formFields": {
          "fuel_quantity": {
            "elementType": "input",
            "elementConfig": {
              "type": "number",
              "placeholder": "Enter Electricity Usage"
            },
            "value": "",
            "validation": {
              "required": true,
              "error": ""
            },
            "valid": false,
            "touched": false
          },

          "comment": {
            "elementType": "textarea",
            "elementConfig": {
              "type": "text",
              "placeholder": "Add Comment"
            },
            "value": "",
            "validation": {
              "required": false,
              "error": ""
            },
            "valid": true,
            "touched": true
          },
          "file": {
            "elementType": "input",
            "elementConfig": {
              "type": "file",
              "placeholder": "Upload Supporting Document"
            },
            "value": "",
            "validation": {
              "required": false,
              "error": ""
            },
            "valid": true,
            "touched": true
          }
        }
      }
    ],
    "heading": "Scope 2: Electricity indirect GHG emissions",
    "discription": "Scope 2 accounts for GHG emissions from the generation of purchased electricity2 consumed by the company. Purchased electricity is defined as electricity that is purchased or otherwise brought into the organizational boundary of the company. Scope 2 emissions physically occur at the facility where electricity is generated.Companies report the emissions from the generation of purchased electricity that is consumed in its owned or controlled equipment or operations as scope 2",
    "typeOfData": "Electricity usage Indirect emissions",
    "subCategory": []
  },
  {
    "_id": "6306227a795e1d5c75d185fa",
    "categoryName": "Scope 3",
    "data": [],
    "isOpen": false,
    "heading": "Scope 3: Other indirect GHG emissions",
    "discription": "Scope 3 is an optional reporting category that allows for the treatment of all other indirect emissions. Scope 3 emissions are a consequence of the activities of the company, but occur from sources not owned or controlled by the company. Some examples of scope 3 activities are extraction and production of purchased materials; transportation of purchased fuels; and use of sold products and services.",
    "typeOfData": "Other Indirect emissions",
    "subCategory": [
      {
        "categoryName": "Upstream emissions",
        "isOpen": false,
        "subCategory": [
          {
            "categoryName": "Purchased goods and services",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "this category includes all upstream (i.e., cradle-to-gate) emissions from the production of products purchased or acquired by the reporting company in the reporting year. Products include both goods (tangible products) and services (intangible products). ",
            "_id": "630624fc004faa278f58c0e2"
          },
          {
            "categoryName": "Capital goods",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "This category includes all upstream (i.e., cradle-to-gate) emissions from the production of capital goods purchased or acquired by the reporting company in the reporting year. Emissions from the use of capital goods by the reporting company are accounted for in either scope 1 (e.g., for fuel use) or scope 2 (e.g., for electricity use), rather than in scope 3.",
            "_id": "630624fc004faa278f58c0e3"
          },
          {
            "categoryName": "Fuel- and energy-related activities",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "This category includes emissions related to the production of fuels and energy purchased and consumed by the reporting company in the reporting year that are not included in scope 1 or scope 2.",
            "_id": "630624fc004faa278f58c0e4"
          },
          {
            "categoryName": "Upstream transportation and distribution",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "This Category Includes fuel consumption at a facility to produce electricity, steam, heat, or power.The combustion of fossil fuels by natural gas boilers, diesel generators and other equipment emits carbon dioxide, methane, and nitrous oxide into the atmosphere.",
            "_id": "630624fc004faa278f58c0e5"
          },
          {
            "categoryName": "Waste generated in operations",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "This category includes emissions from third-party disposal and treatment of waste generated in the reporting company’s owned or controlled operations in the reporting year. This category includes emissions from disposal of both solid waste and wastewater.",
            "_id": "630624fc004faa278f58c0e6"
          },
          {
            "categoryName": "Business travel",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "This category includes emissions from the transportation of employees for businessrelated activities in vehicles owned or operated by third parties, such as aircraft, trains, buses, and passenger cars.",
            "_id": "630624fc00rerfaa278f58c0e6"
          },
          {
            "categoryName": "Employee commuting",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "This Category Includes fuel consumption at a facility to produce electricity, steam, heat, or power.The combustion of fossil fuels by natural gas boilers, diesel generators and other equipment emits carbon dioxide, methane, and nitrous oxide into the atmosphere.",
            "_id": "630624fc004faera278f58c0e6"
          },
          {
            "categoryName": "Upstream leased assets",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "This category includes emissions from the transportation of employees4 between their homes and their worksites.",
            "_id": "630624fc004freraa278f58c0e6"
          }
        ],
        "data": [],
        "_id": "630624ca0fd04faa278f58c0da"
      },
      {
        "categoryName": "Downstream emissions",
        "isOpen": false,
        "subCategory": [
          {
            "categoryName": "Downstream transportation and distribution",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "This category includes emissions that occur in the reporting year from transportation and distribution of sold products in vehicles and facilities not owned or controlled by the reporting company. ",
            "_id": "630624fc004farya278f58c0e2"
          },
          {
            "categoryName": "Processing of sold products",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "This category includes emissions from processing of sold intermediate products by third parties (e.g., manufacturers) subsequent to sale by the reporting company. Intermediate products are products that require further processing, transformation, or inclusion in another product before use (see box 5.3 of the Scope 3 Standard), and therefore result in emissions from processing subsequent to sale by the reporting company and before use by the end consumer. Emissions from processing should be allocated to the intermediate product.",
            "_id": "630624fc004fatrrta278f58c0e3"
          },
          {
            "categoryName": "Use of sold products",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "This category includes emissions from the use of goods and services sold by the reporting company in the reporting year. A reporting company’s scope 3 emissions from use of sold products include the scope 1 and scope 2 emissions of end users. End users include both consumers and business customers that use final products.",
            "_id": "630624fc004ftyrtaa278f58c0e4"
          },
          {
            "categoryName": "End-of-life treatment of sold products",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "Thiscategory includes emissions from the waste disposal and treatment of products sold by the reporting company (in the reporting year) at the end of their life. This category includes the total expected end-of-life emissions from all products sold in the reporting year. (See section 5.4 of the Scope 3 Standard for more information on the time boundary of scope 3 categories.) ",
            "_id": "630624fc004fatryrta278f58c0e5"
          },
          {
            "categoryName": "Downstream leased assets",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "This category includes emissions from the operation of assets that are owned by the reporting company (acting as lessor) and leased to other entities in the reporting year that are not already included in scope 1 or scope 2. This category is applicable to lessors (i.e., companies that receive payments from lessees). Companies that operate leased assets (i.e., lessees) should refer to category 8 (Upstream leased assets).",
            "_id": "630624fc004tryrfaa278f58c0e6"
          },
          {
            "categoryName": "Franchises",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "This category includes emissions from the operation of franchises not included in scope 1 or scope 2. A franchise is a business operating under a license to sell or distribute another company’s goods or services within a certain location. This category is applicable to franchisors (i.e., companies that grant licenses to other entities to sell or distribute its goods or services in return for payments, such as royalties for the use of trademarks and other services). Franchisors should account for emissions that occur from the operation of franchises (i.e., the scope 1 and scope 2 emissions of franchisees) in this category",
            "_id": "630624fctyr004fereaa278f58c0e6"
          },
          {
            "categoryName": "Investments",
            "isOpen": false,
            "subCategory": [],
            "data": [
              {
                "formFields": {
                  "activity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Activity"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "Unit": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Unit"
                    },
                    "value": "",
                    "validation": {
                      "required": true
                    },
                    "valid": false,
                    "touched": false

                  },

                  "fuel_quantity": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Enter Quantity"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },

                  "emission_factor": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "number",
                      "placeholder": "Emissions Factor"
                    },
                    "value": "",
                    "validation": {
                      "required": true,
                      "error": ""
                    },
                    "valid": false,
                    "touched": false
                  },
                  "comment": {
                    "elementType": "textarea",
                    "elementConfig": {
                      "type": "text",
                      "placeholder": "Add Comment"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  },
                  "file": {
                    "elementType": "input",
                    "elementConfig": {
                      "type": "file",
                      "placeholder": "Upload Supporting Document"
                    },
                    "value": "",
                    "validation": {
                      "required": false,
                      "error": ""
                    },
                    "valid": true,
                    "touched": true
                  }
                }
              }
            ],
            "discription": "This category includes scope 3 emissions associated with the reporting company’s investments in the reporting year, not already included in scope 1 or scope 2. This category is applicable to investors (i.e., companies that make an investment with the objective of making a profit) and companies that provide financial services. This category also applies to investors that are not profit driven (e.g. multilateral development banks), and the same calculation methods should be used. Investments are categorized as a downstream scope 3 category because providing capital or financing is a service provided by the reporting company",
            "_id": "630624fc004ftryaa278ref58c0e6"
          }
        ],
        "data": [],
        "_id": "630624ca004ftrrtaa278f58c0da"
      }
    ]
  }
];
