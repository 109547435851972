import React from "react";
import { forwardRef } from "react";
import { Col, Row, Label, Button } from "reactstrap";

const IPCC = ({
  dropDownItems, value, handleFormData, setDropDownItems, secondDropDownSelect,
  thirdDropDownSelect, setvalue, setCalculate, calculate, totalCalculation
}, fileRef) => (
  <>
    {dropDownItems.activities.length > 0 && dropDownItems.name && <Col lg={6} className="mt-3">
      <Label>{dropDownItems.name}</Label>
      <select
        name="category"
        className="form-select"
        value={value.category}
        onChange={(e) => handleFormData(e)}
      >
        <option value="0">Select {dropDownItems.name}</option>
        {dropDownItems.dataPoints.map((item, index) => (
          <option key={`datapoints${index}`} value={item}>
            {item}
          </option>
        ))}
      </select>
    </Col>}
    {dropDownItems.activities.length > 0 && dropDownItems.subCategoryName && <Col lg={6} className="mt-3">
      <Label>Select {dropDownItems.subCategoryName}</Label>
      <select
        id="subCategory2"
        name="fuletype"
        value={value.fuletype}
        className="form-select"
        onClick={(e) => e.target.value === "" && setDropDownItems({ ...dropDownItems, subCategoryTouched: true })}
        onChange={(e) => secondDropDownSelect(e)}
      >
        <option value="">Select {dropDownItems.subCategoryName}</option>
        {dropDownItems?.fuleTypes.map((item, index) => (
          <option key={`fuleTypes${index}`} value={item.name}>
            {item.name}
          </option>
        ))}
      </select>
      {dropDownItems?.fuleTypes.length === 0 && dropDownItems.subCategoryTouched &&
       <span className="red form-label">Please Select {dropDownItems.categoryName} first</span>}
    </Col>}

    {dropDownItems.activities.length > 0 && dropDownItems.subCategoryName1 && <Col lg={6} className="mt-3">
      <Label>Select {dropDownItems.subCategoryName1}</Label>
      <select
        id="subCategory3"
        name="unit"
        className="form-select"
        onClick={(e) => e.target.value === "0" && setDropDownItems({ ...dropDownItems, subCategory1Touched: true })}
        onChange={(e) => thirdDropDownSelect(e)}
      >
        <option value="0">Select {dropDownItems.subCategoryName1}</option>
        {dropDownItems.units.map((item, index) => (
          <option key={`units${index}`} value={item.name}>
            {item.name}
          </option>
        ))}
      </select>
      {dropDownItems.units.length === 0 && dropDownItems.subCategory1Touched &&
       <span className="red form-label">Please Select {dropDownItems.subCategoryName} first</span>}
    </Col>}
    {
      dropDownItems.formFiled.map((formitem, i) => {
        if(formitem.elementType === "input"){
          if(formitem.type === "file"){
            return (
              <Col className="mt-3" lg={6} key={`formFiled${i}`}>
                <Label>{formitem.placeholder}</Label>
                <input type={formitem.type} name={formitem.name} ref={fileRef}
                  onChange={(e) => setvalue({ ...value, file: e.target.files[0] })}
                  placeholder={formitem.placeholder} className="form-control"></input>
              </Col>);
          }
          else{
            return (
              <React.Fragment key={`formFiled${i}`} >
                <Col className="mt-3" lg={6}>
                  <Label>{formitem.placeholder}</Label>
                  <input type={formitem.type} name={formitem.name}
                    onChange={(e) => handleFormData(e)}
                    onWheel={(e) => e.target.blur()}
                    placeholder={formitem.placeholder}
                    value={value[formitem.name]}
                    className="form-control"></input>
                </Col>
                <Col className="mt-3" lg={6} >
                  <Label>Net Calorific Value (in Terajoules)</Label>
                  <input type="number" name="conversion_factor" ref={fileRef}
                    value={value.conversion_factor}
                    onChange={(e) => handleFormData(e)}
                    onWheel={(e) => e.target.blur()}
                    placeholder="Enter Net calorific value (in Terajoules)" className="form-control"></input>
                </Col>
                {value.fuel_quantity && value.conversion_factor &&<Col className="mt-3" lg={6} >
                  <Label>Amount of fuel combusted (TJ)</Label>
                  <input
                    disabled
                    type="text" name="amount_of_fule_combusted" ref={fileRef}
                    value={Number(value.conversion_factor * value.fuel_quantity).toLocaleString("en-IN")}
                    onWheel={(e) => e.target.blur()}
                    className="form-control"></input>
                </Col>}
              </React.Fragment>
            );
          }
        }
        else if(formitem.elementType === "button"){
          return (
            <React.Fragment key={`formFiled${i}`} >
              {
                (calculate && value.fuel_quantity && value.conversion_factor && value.unit && value.activity && value.fuletype) &&
              <Col lg={12} >
                <div className="table-responsive mt-3">
                  <table className="table table-bordered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th width="32%">Gas</th>
                        <th width="34%">Emissions Factor</th>
                        <th width="34%">GHG Emissions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        dropDownItems?.selectedsubCategoryco2 && dropDownItems?.selectedsubCategoryco2?.map((item, i) => (
                          <tr key={`itemlist${i}`}>
                            <td>{Object.keys(item)[0]}</td>
                            <td>{Number(item[Object.keys(item)[0]])?.toLocaleString("en-IN")} kg {Object.keys(item)[0]} per TJ</td>
                            <td>{Number(value.conversion_factor * value.fuel_quantity * +item[Object.keys(item)[0]]).toLocaleString("en-IN")} kg {Object.keys(item)[0]}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>

                <Label className="mt-3">GWP (Global Warming Potenital)</Label>
                <div className="table-responsive mt-1">
                  <table className="table table-bordered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th width="32%">CO₂</th>
                        <th width="34%">CH₄</th>
                        <th width="34%">N₂O</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>25</td>
                        <td>298</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <Row className="mt-2" style={{ backgroundColor: "#F2F2F5", paddingTop: "10px" }}>
                  <Col lg={7}>
                    <h5>Total GHG emissions( CO2 equivalent) from {dropDownItems.factorName === "Stationary combustion"
                      ? value.category
                      : dropDownItems.scope === "Scope 3" ? value.activity : dropDownItems.selectedCategory}: </h5>
                  </Col>
                  <Col lg={5} style={{ textAlign: "right" }}><h5>{dropDownItems.scope === "Scope 3"
                    ? ( +(value.fuel_quantity) * +(value.conversion_factor) * +(value.emission_factor)).toLocaleString("en-IN")
                    : Number(totalCalculation)?.toLocaleString("en-IN")} Kg</h5></Col>
                </Row>
              </Col>}
              {(value.fuel_quantity && value.conversion_factor && value.unit && value.activity && value.fuletype) &&
          <Row className="mt-3">
            <Col >
              <Button color="primary" onClick={() => setCalculate(true)}>Calculate</Button>
            </Col>
          </Row>}
            </React.Fragment >
          );
        }
        else if(formitem.elementType === "textarea"){
          return (
            <Col className="mt-3" lg={6} key={`formFiled${i}`}>
              <Label>{formitem.placeholder}</Label>
              <textarea name={formitem.name}
                onChange={(e) => handleFormData(e)}
                placeholder={formitem.placeholder} value={value.comment} className="form-control"></textarea>
            </Col>
          );
        }
      })
    }
  </>
);
export default forwardRef(IPCC);