import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => (
  <React.Fragment>
    <footer className="footer">
      <Container fluid={true}>
        <Row>
          <Col sm={6}>{new Date().getFullYear()} © Greenalytics.</Col>
          <Col sm={6}>
            <div className="text-sm-end d-none d-sm-block">
                Designed & Developed by Glasier Inc.
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  </React.Fragment>
);

export default Footer;
