import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";

import IsAuth from "./IsAuth";
import Users from "./RegisterForm/Users";
import Locations from "./RegisterForm/Locations";
import { API_URLS } from "common/constants";
import ConfigureYourProfile from "./RegisterForm/ConfigureYourProfile";
import CompanyProfile from "./RegisterForm/CompanyProfile";

// import images
import logo_lg from "../../assets/images/logo-lg.png";

import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import { AxiosGet, AxiosPost } from "common/axiosService";
import Loading from "components/Common/Loading";

const RegisterForm = props => {
  document.title = "Register Form | Greenalytics";
  const history = useHistory();
  if (props.location.state === undefined ||props.location.state.personalUrl === undefined) {
    history.push("/region");
  }
  let infraloop;
  const [allData, setAllData] = useState({
    personalUrl: props.location.state ? props.location.state.personalUrl : "",
    phoneNumber: "",
    emailNotification: true,
    smsNotification: false,
    cin: "",
    companyName: "",
    yearOfIncorporation: 0,
    corporataeAddress: "",
    currency: "",
    authorizedCapital: "",
    paidUpCapital: "",
    locations: [
      {
        addressType: "",
        address: "",
        country: "",
        state: "",
        city: "",
        pincode: ""
      }
    ],
    users: [
      {
        email: "",
        name: "",
        role: ""
      }
    ]
  });

  const [step, setstep] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false
  });
  const [stepLoading, setstepLoading] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const errors = {};
  const [passedStepsVertical, setPassedStepsVertical] = useState([0]);

  function toggleTabVertical (tab) {
    if (currentStep !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab];
      if (tab >= 0 && tab <= 3) {
        setCurrentStep(tab);
        setPassedStepsVertical(modifiedSteps);
      }
    }
  }

  const handlePrevStep = newData => {
    setAllData(prev => ({ ...prev, ...newData }));
    setCurrentStep(prev => prev - 1);
  };
  const decreaseWaterLevel = async (email) => {
    if (!isLoading) {
      const res = await AxiosGet(`${API_URLS.INFRADONE}?email=${email}`);
      if (!res.data[0]) {
        setIsLoading(false);
        clearInterval(infraloop);
        const user = JSON.parse(localStorage.getItem("authUser")) ? JSON.parse(localStorage.getItem("authUser")).userData.user : "";
        const subDomainName = user?.companyId?.companyName.replace(/[^A-Z]/ig, "").toLowerCase();
        window.location.replace(`https://${subDomainName}.app.ap.greenalytics.io/`);
        // history.push(`http://${user?.companyId?.companyName}/greenalytics.io/`);
      }
    } else {
      clearInterval(infraloop);
    }
  };
  const RegisterCompany = async appendData => {
    if (appendData?.details) {
      delete appendData.details;
    }
    setstepLoading(prev => ({ ...prev, ["step4"]: true }));
    const user = JSON.parse(localStorage.getItem("authUser")) ? JSON.parse(localStorage.getItem("authUser")).userData.user : "";
    const params = appendData;
    if (user) {
      params["currentUserEmail"] = user.email;
    }
    try {
      const response = await AxiosPost(API_URLS.COMPANY_DETAILS, params);
      if (response && response.statusCode === 200) {
        toast.success(response.message, {
          autoClose: 1000
        });
        let authUser = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")):"";
        authUser.userData.user = response.data[0];
        localStorage.setItem("authUser", JSON.stringify(authUser));
        setIsLoading(true);
        infraloop = setInterval(async () => {
          decreaseWaterLevel(user.email);
        }, 5000);
        setTimeout(() => {
          history.push("/");}
        , 1000);
        setstepLoading(prev => ({ ...prev, ["step4"]: false }));
        setstep(prev => ({ ...prev, ["step4"]: true }));
      } else {
        setstep(prev => ({ ...prev, ["step4"]: false }));
        setstepLoading(prev => ({ ...prev, ["step4"]: false }));
        setCurrentStep(0);
      }
    } catch (error) {
      setstep(prev => ({ ...prev, ["step4"]: false }));
      setstepLoading(prev => ({ ...prev, ["step4"]: false }));
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      setCurrentStep(0);
    }
  };
  const handleNextStep = async (newData, final = false) => {
    setAllData(prev => ({ ...prev, ...newData }));
    let appendAllData = { ...newData };
    setstepLoading({ step1: false, step2: false, step3: false, step4: false });

    // if (final === "SKIP") {
    //   RegisterCompany(appendAllData)
    // }
    if (final === "FINAL") {
      RegisterCompany(appendAllData);
    }
    setCurrentStep(prev => prev + 1);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <IsAuth />
      <div className="page-content p-2" id="register">
        <Container fluid={false}>
          <Row>
            <Col lg="12">
              <Card>
                <div className="card-header">
                  <Row className="align-items-center">
                    <Col className="col-12">
                      <div className="p-4 text-center">
                        <img src={logo_lg} height="50" />
                      </div>
                    </Col>
                  </Row>
                </div>

                <CardBody>
                  <h4 className="card-title mb-4">Quick start</h4>
                  <p className="card-title-desc">
                    Welcome to Greenalytics! Thanks for signing up for your free
                    Greenalytics trial.<br></br>
                    We are going to take you through a few minutes setup to
                    enable you to experience the essential features and benefits
                    of Greenalytics.
                  </p>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps w-100 clearfix">
                      <ul>
                        {/* Step 1 Title */}
                        <NavItem
                          className={classnames({
                            current: currentStep === 0
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: currentStep === 0
                            })}
                            onClick={() => {
                              toggleTabVertical(0);
                            }}
                            disabled={!(passedStepsVertical || []).includes(0)}
                          >
                            {step && step.step1
                              ? (
                                <span className="mdi mdi-24px mdi-check number complete-step"></span>
                              )
                              : stepLoading && stepLoading.step1
                                ? (
                                  <div
                                    className="spinner-border text-primary me-3"
                                    role="status"
                                    style={{ width: "1.8rem", height: "1.8rem" }}
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                )
                                : (
                                  <span className="number">1</span>
                                )}

                            <span className="h4 mb-0">
                              Configure your profile
                            </span>
                          </NavLink>
                          {currentStep === 0
                            ? (
                              <TabContent
                                activeTab={currentStep}
                                className="body"
                              >
                                <TabPane tabId={0}>
                                  <ConfigureYourProfile
                                    key={0}
                                    progress={25}
                                    toggleTab={toggleTabVertical}
                                    next={handleNextStep}
                                    data={allData}
                                    errors={errors}
                                    step={step}
                                    setstep={setstep}
                                    stepLoading={stepLoading}
                                    setstepLoading={setstepLoading}
                                  />
                                </TabPane>
                              </TabContent>
                            )
                            : (
                              ""
                            )}
                        </NavItem>

                        {/* Step 2 Title */}
                        <NavItem
                          className={classnames({
                            current: currentStep === 1
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: currentStep === 1
                            })}
                            onClick={() => {
                              toggleTabVertical(1);
                            }}
                            disabled={!(passedStepsVertical || []).includes(1)}
                          >
                            {step && step.step2
                              ? (
                                <span className="mdi mdi-24px mdi-check number complete-step"></span>
                              )
                              : stepLoading && stepLoading.step2
                                ? (
                                  <div
                                    className="spinner-border text-primary me-3"
                                    role="status"
                                    style={{ width: "1.8rem", height: "1.8rem" }}
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                )
                                : (
                                  <span className="number">2</span>
                                )}
                            <span className="h4 mb-0">Company Profile</span>
                          </NavLink>
                          {currentStep === 1
                            ? (
                              <TabContent
                                activeTab={currentStep}
                                className="body"
                              >
                                <TabPane tabId={1}>
                                  <CompanyProfile
                                    key={1}
                                    progress={50}
                                    toggleTab={toggleTabVertical}
                                    next={handleNextStep}
                                    prev={handlePrevStep}
                                    data={allData}
                                    errors={errors}
                                    step={step}
                                    setstep={setstep}
                                    stepLoading={stepLoading}
                                    setstepLoading={setstepLoading}
                                  />
                                </TabPane>
                              </TabContent>
                            )
                            : (
                              ""
                            )}
                        </NavItem>

                        {/* Step 3 Title */}
                        <NavItem
                          className={classnames({
                            current: currentStep === 2
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: currentStep === 2
                            })}
                            onClick={() => {
                              toggleTabVertical(2);
                            }}
                            disabled={!(passedStepsVertical || []).includes(2)}
                          >
                            {step && step.step3
                              ? (
                                <span className="mdi mdi-24px mdi-check number complete-step"></span>
                              )
                              : stepLoading && stepLoading.step3
                                ? (
                                  <div
                                    className="spinner-border text-primary me-3"
                                    role="status"
                                    style={{ width: "1.8rem", height: "1.8rem" }}
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                )
                                : (
                                  <span className="number">3</span>
                                )}
                            <span className="h4 mb-0">Locations</span>
                          </NavLink>
                          {currentStep === 2
                            ? (
                              <TabContent
                                activeTab={currentStep}
                                className="body"
                              >
                                <TabPane tabId={2}>
                                  <Locations
                                    key={2}
                                    progress={75}
                                    toggleTab={toggleTabVertical}
                                    next={handleNextStep}
                                    prev={handlePrevStep}
                                    data={allData}
                                    errors={errors}
                                    step={step}
                                    setstep={setstep}
                                    stepLoading={stepLoading}
                                    setstepLoading={setstepLoading}
                                  />
                                </TabPane>
                              </TabContent>
                            )
                            : (
                              ""
                            )}
                        </NavItem>

                        {/* Step 4 Title */}
                        <NavItem
                          className={classnames({
                            current: currentStep === 3
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: currentStep === 3
                            })}
                            onClick={() => {
                              toggleTabVertical(3);
                            }}
                            disabled={!(passedStepsVertical || []).includes(3)}
                          >
                            {step && step.step4
                              ? (
                                <span className="mdi mdi-24px mdi-check number complete-step"></span>
                              )
                              : stepLoading && stepLoading.step4
                                ? (
                                  <div
                                    className="spinner-border text-primary me-3"
                                    role="status"
                                    style={{ width: "1.8rem", height: "1.8rem" }}
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                )
                                : (
                                  <span className="number">4</span>
                                )}
                            <span className="h4 mb-0">Setup</span>
                          </NavLink>
                          {/* All Step Content in Loop */}
                          {currentStep === 3
                            ? (
                              <TabContent
                                activeTab={currentStep}
                                className="body"
                              >
                                <TabPane tabId={3}>
                                  <Users
                                    key={3}
                                    progress={100}
                                    toggleTab={toggleTabVertical}
                                    next={handleNextStep}
                                    prev={handlePrevStep}
                                    data={allData}
                                    errors={errors}
                                    step={step}
                                    setstep={setstep}
                                    stepLoading={stepLoading}
                                    setstepLoading={setstepLoading}
                                  />
                                </TabPane>
                              </TabContent>
                            )
                            : (
                              ""
                            )}
                        </NavItem>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

RegisterForm.propTypes = {
  company: PropTypes.any,
  message: PropTypes.any,
  registrationError: PropTypes.any,
  progress: PropTypes.any,
  loading: PropTypes.any
};

const mapStatetoProps = state => {
  const { company, message, registrationError, progress, loading } =
    state.Account;
  return { company, message, registrationError, progress, loading };
};

export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(RegisterForm)));
