import React from "react";
import { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { Card, CardBody } from "reactstrap";
import { AxiosGet } from "common/axiosService";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Activity = () => {
  const apipath = process.env.REACT_APP_SUPERADMIN_API_PATH;
  const [activity, setactivity] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/super-admin-activity-log`);
        if (response && response.statusCode === 200) {
          setactivity(response.data.reverse());
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="d-flex">
            <div className="me-2">
              <h5 className="card-title mb-4">Activity</h5>
            </div>
          </div>
          <SimpleBar style={{ maxHeight: "183px" }}>
            <div className="mt-2">
              <ul className="verti-timeline list-unstyled">
                {activity.length > 0 &&
                  activity?.map((item, i) => (
                    <React.Fragment key={`eventactionDone${i}`}>
                      <li className="event-list active">
                        <div className="event-timeline-dot">
                          <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right"></i>
                        </div>
                        <div className="d-flex">
                          <div className="me-3">
                            <h5 className="font-size-14">
                              {dateFormat(`${item.createdAt}`, "mmm dS")}
                              <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                            </h5>
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              {item.actionDone}
                            </div>
                          </div>
                        </div>
                      </li>
                    </React.Fragment>
                  ))
                }
              </ul>
            </div>
          </SimpleBar>
          <div className="text-center mt-4">
            <Link to="/superadmin/logs" className="btn btn-primary  btn-sm">
            View More <i className="mdi mdi-arrow-right ms-1"></i>
            </Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default Activity;
