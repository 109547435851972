import React, { useState } from "react";
import classnames from "classnames";
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";

//import component
import IsAuth from "pages/Authentication/IsAuth";
import CanvasJSReact from "../../Admin/Analytics/mines/canvasjs/canvasjs.react";

const Index = () => {
  const [activeTab1, setactiveTab1] = useState("5");

  var CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };
  const options = [
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "title": "liters",
        "gridThickness": 0,
        "titleFontSize": 14
      },
      data: [
        {
          "level": "Level",
          "levelNo": 2,
          "type": "column",
          "cursor": "pointer",
          "color": "#3c7c44",
          "legendText": " Block",
          "showInLegend": true,
          "dataPoints": [
            {
              "label": "Ahmedabad",
              "name": "1_2018_Water_Block",
              "y": 108.29999999999998,
              "x": 0
            },
            {
              "label": "Surat",
              "name": "1_2019_Water_Block",
              "y": 615.6,
              "x": 1
            },
            {
              "label": "Patna",
              "name": "1_2020_Water_Block",
              "y": 361,
              "x": 2
            },
            {
              "label": "Chandigadh",
              "name": "1_2021_Water_Block",
              "y": 175.75,
              "x": 3
            },
            {
              "label": "Noida",
              "name": "1_2022_Water_Block",
              "y": 16.15,
              "x": 4
            }
          ]
        },
        {
          "level": "Level",
          "levelNo": 2,
          "type": "column",
          "cursor": "pointer",
          "color": "#d2df64",
          "legendText": " Rubbles",
          "showInLegend": true,
          "dataPoints": [
            {
              "label": "Ahmedabad",
              "name": "1_2018_Water_Rubbles",
              "y": 5.699999999999999,
              "x": 0
            },
            {
              "label": "Surat",
              "name": "1_2019_Water_Rubbles",
              "y": 32.400000000000006,
              "x": 1
            },
            {
              "label": "Patna",
              "name": "1_2020_Water_Rubbles",
              "y": 19,
              "x": 2
            },
            {
              "label": "Chandigadh",
              "name": "1_2021_Water_Rubbles",
              "y": 9.250000000000002,
              "x": 3
            },
            {
              "label": "Noida",
              "name": "1_2022_Water_Rubbles",
              "y": 0.85,
              "x": 4
            }
          ]
        }
      ],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "Water",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    },
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "title": "ton",
        "gridThickness": 0,
        "titleFontSize": 14
      },
      data: [
        {
          "level": "Level",
          "levelNo": 2,
          "type": "column",
          "cursor": "pointer",
          "color": "#3c7c44",
          "legendText": " Scope 1",
          "showInLegend": true,
          "dataPoints": [
            {
              "label": "Ahmedabad",
              "name": "1_2018_CO2 Emission_Scope 1",
              "y": 4733.476,
              "x": 0
            },
            {
              "label": "Surat",
              "name": "1_2019_CO2 Emission_Scope 1",
              "y": 57322.658800000005,
              "x": 1
            },
            {
              "label": "Patna",
              "name": "1_2020_CO2 Emission_Scope 1",
              "y": 55307.626000000004,
              "x": 2
            },
            {
              "label": "Chandigadh",
              "name": "1_2021_CO2 Emission_Scope 1",
              "y": 133528.996,
              "x": 3
            },
            {
              "label": "Noida",
              "name": "1_2022_CO2 Emission_Scope 1",
              "y": 7756.03,
              "x": 4
            }
          ]
        },
        {
          "level": "Level",
          "levelNo": 2,
          "type": "column",
          "cursor": "pointer",
          "color": "#d2df64",
          "legendText": " Scope 2",
          "showInLegend": true,
          "dataPoints": [
            {
              "label": "Ahmedabad",
              "name": "1_2018_CO2 Emission_Scope 2",
              "y": 2935.6000000000004,
              "x": 0
            },
            {
              "label": "Surat",
              "name": "1_2019_CO2 Emission_Scope 2",
              "y": 10755.74,
              "x": 1
            },
            {
              "label": "Patna",
              "name": "1_2020_CO2 Emission_Scope 2",
              "y": 9604.800000000003,
              "x": 2
            },
            {
              "label": "Chandigadh",
              "name": "1_2021_CO2 Emission_Scope 2",
              "y": 8490.400000000001,
              "x": 3
            },
            {
              "label": "Noida",
              "name": "1_2022_CO2 Emission_Scope 2",
              "y": 1328.8,
              "x": 4
            }
          ]
        }
      ],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "CO2 Emission",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    },
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "title": "liters",
        "gridThickness": 0,
        "titleFontSize": 14
      },
      data: [
        {
          "level": "Level",
          "levelNo": 2,
          "type": "column",
          "cursor": "pointer",
          "color": "#3c7c44",
          "legendText": " Ground",
          "showInLegend": true,
          "dataPoints": [
            {
              "label": "Ahmedabad",
              "name": "1_2018_Water Consumption_Ground",
              "y": 132,
              "x": 0
            },
            {
              "label": "Surat",
              "name": "1_2019_Water Consumption_Ground",
              "y": 722,
              "x": 1
            },
            {
              "label": "Patna",
              "name": "1_2020_Water Consumption_Ground",
              "y": 353,
              "x": 2
            },
            {
              "label": "Chandigadh",
              "name": "1_2021_Water Consumption_Ground",
              "y": 159,
              "x": 3
            },
            {
              "label": "Noida",
              "name": "1_2022_Water Consumption_Ground",
              "y": 13,
              "x": 4
            }
          ]
        },
        {
          "level": "Level",
          "levelNo": 2,
          "type": "column",
          "cursor": "pointer",
          "color": "#d2df64",
          "legendText": " Recycled",
          "showInLegend": true,
          "dataPoints": [
            {
              "label": "Ahmedabad",
              "name": "1_2018_Water Consumption_Recycled",
              "y": 32,
              "x": 0
            },
            {
              "label": "Surat",
              "name": "1_2019_Water Consumption_Recycled",
              "y": 100,
              "x": 1
            },
            {
              "label": "Patna",
              "name": "1_2020_Water Consumption_Recycled",
              "y": 97,
              "x": 2
            },
            {
              "label": "Chandigadh",
              "name": "1_2021_Water Consumption_Recycled",
              "y": 48,
              "x": 3
            },
            {
              "label": "Noida",
              "name": "1_2022_Water Consumption_Recycled",
              "y": 5,
              "x": 4
            }
          ]
        }
      ],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "Water Consumption Locations",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    },
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "title": "Kg",
        "gridThickness": 0,
        "titleFontSize": 14
      },
      data: [
        {
          "level": "Level",
          "levelNo": 2,
          "type": "column",
          "cursor": "pointer",
          "color": "#3c7c44",
          "legendText": "Industrial Waste ",
          "showInLegend": true,
          "dataPoints": [
            {
              "label": "Ahmedabad",
              "name": "1_2018_Industrial Waste_",
              "y": 27,
              "x": 0
            },
            {
              "label": "Surat",
              "name": "1_2019_Industrial Waste_",
              "y": 171.5,
              "x": 1
            },
            {
              "label": "Patna",
              "name": "1_2020_Industrial Waste_",
              "y": 132,
              "x": 2
            },
            {
              "label": "Chandigadh",
              "name": "1_2021_Industrial Waste_",
              "y": 177,
              "x": 3
            },
            {
              "label": "Noida",
              "name": "1_2022_Industrial Waste_",
              "y": 2,
              "x": 4
            }
          ]
        }
      ],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "Produce Industrial Waste",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    },
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "title": "Kw",
        "gridThickness": 0,
        "titleFontSize": 14
      },
      data: [
        {
          "level": "Level",
          "levelNo": 2,
          "type": "column",
          "cursor": "pointer",
          "color": "#3c7c44",
          "legendText": " Block",
          "showInLegend": true,
          "dataPoints": [
            {
              "label": "Ahmedabad",
              "name": "1_2018_Electricity_Block",
              "y": 3043.0000000000005,
              "x": 0
            },
            {
              "label": "Surat",
              "name": "1_2019_Electricity_Block",
              "y": 11326.249999999998,
              "x": 1
            },
            {
              "label": "Patna",
              "name": "1_2020_Electricity_Block",
              "y": 10205.1,
              "x": 2
            },
            {
              "label": "Chandigadh",
              "name": "1_2021_Electricity_Block",
              "y": 9021.05,
              "x": 3
            },
            {
              "label": "Noida",
              "name": "1_2022_Electricity_Block",
              "y": 1411.85,
              "x": 4
            }
          ]
        },
        {
          "level": "Level",
          "levelNo": 2,
          "type": "column",
          "cursor": "pointer",
          "color": "#d2df64",
          "legendText": " Rubbles",
          "showInLegend": true,
          "dataPoints": [
            {
              "label": "Ahmedabad",
              "name": "1_2018_Electricity_Rubbles",
              "y": 537,
              "x": 0
            },
            {
              "label": "Surat",
              "name": "1_2019_Electricity_Rubbles",
              "y": 1998.7500000000002,
              "x": 1
            },
            {
              "label": "Patna",
              "name": "1_2020_Electricity_Rubbles",
              "y": 1800.8999999999999,
              "x": 2
            },
            {
              "label": "Chandigadh",
              "name": "1_2021_Electricity_Rubbles",
              "y": 1591.9499999999998,
              "x": 3
            },
            {
              "label": "Noida",
              "name": "1_2022_Electricity_Rubbles",
              "y": 249.15,
              "x": 4
            }
          ]
        }
      ],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "Electricity Consumption Locations",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    },
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "title": "liters",
        "gridThickness": 0,
        "titleFontSize": 14
      },
      data: [
        {
          "level": "Level",
          "levelNo": 2,
          "type": "column",
          "cursor": "pointer",
          "color": "#3c7c44",
          "legendText": " Block",
          "showInLegend": true,
          "dataPoints": [
            {
              "label": "Ahmedabad",
              "name": "1_2018_Fuel_Block",
              "y": 1074,
              "x": 0
            },
            {
              "label": "Surat",
              "name": "1_2019_Fuel_Block",
              "y": 13006.199999999999,
              "x": 1
            },
            {
              "label": "Patna",
              "name": "1_2020_Fuel_Block",
              "y": 12549,
              "x": 2
            },
            {
              "label": "Chandigadh",
              "name": "1_2021_Fuel_Block",
              "y": 30297,
              "x": 3
            },
            {
              "label": "Noida",
              "name": "1_2022_Fuel_Block",
              "y": 1759.8,
              "x": 4
            }
          ]
        },
        {
          "level": "Level",
          "levelNo": 2,
          "type": "column",
          "cursor": "pointer",
          "color": "#d2df64",
          "legendText": " Rubbles",
          "showInLegend": true,
          "dataPoints": [
            {
              "label": "Ahmedabad",
              "name": "1_2018_Fuel_Rubbles",
              "y": 716,
              "x": 0
            },
            {
              "label": "Surat",
              "name": "1_2019_Fuel_Rubbles",
              "y": 8670.800000000001,
              "x": 1
            },
            {
              "label": "Patna",
              "name": "1_2020_Fuel_Rubbles",
              "y": 8366,
              "x": 2
            },
            {
              "label": "Chandigadh",
              "name": "1_2021_Fuel_Rubbles",
              "y": 20197.280000000002,
              "x": 3
            },
            {
              "label": "Noida",
              "name": "1_2022_Fuel_Rubbles",
              "y": 1173.2,
              "x": 4
            }
          ]
        }
      ],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "Fuel Consumption Locations",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    },
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "title": "Kw",
        "gridThickness": 0,
        "titleFontSize": 14,
        minimum: 0
      },
      data: [
        {
          "level": "Level",
          "levelNo": 2,
          "type": "column",
          "cursor": "pointer",
          "color": "#3c7c44",
          "legendText": " Block",
          "showInLegend": true,
          "dataPoints": [
            {
              "label": "Ahmedabad",
              "name": "1_2018_Electricity per M.ton_Block",
              "y": 0.094276988,
              "x": 0
            },
            {
              "label": "Surat",
              "name": "1_2019_Electricity per M.ton_Block",
              "y": 0.38388400400000006,
              "x": 1
            },
            {
              "label": "Patna",
              "name": "1_2020_Electricity per M.ton_Block",
              "y": 0.032318705,
              "x": 2
            },
            {
              "label": "Chandigadh",
              "name": "1_2021_Electricity per M.ton_Block",
              "x": 3
            },
            {
              "label": "Noida",
              "name": "1_2022_Electricity per M.ton_Block",
              "x": 4
            }
          ]
        },
        {
          "level": "Level",
          "levelNo": 2,
          "type": "column",
          "cursor": "pointer",
          "color": "#d2df64",
          "legendText": " Rubbles",
          "showInLegend": true,
          "dataPoints": [
            {
              "label": "Ahmedabad",
              "name": "1_2018_Electricity per M.ton_Rubbles",
              "y": 15.248097727,
              "x": 0
            },
            {
              "label": "Surat",
              "name": "1_2019_Electricity per M.ton_Rubbles",
              "y": 160.031411239,
              "x": 1
            },
            {
              "label": "Patna",
              "name": "1_2020_Electricity per M.ton_Rubbles",
              "y": 259.100813321,
              "x": 2
            },
            {
              "label": "Chandigadh",
              "name": "1_2021_Electricity per M.ton_Rubbles",
              "y": 361.531818284,
              "x": 3
            },
            {
              "label": "Noida",
              "name": "1_2022_Electricity per M.ton_Rubbles",
              "y": 15.735,
              "x": 4
            }
          ]
        }
      ],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "Electricity per M.ton",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    }

  ];
  const socialOptions = [
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "interval": 1,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "title": "Total Employee",
        "gridThickness": 0,
        "titleFontSize": 14
      },
      data: [
        {
          "level": "Level",
          "levelNo": 3,
          "type": "line",
          "cursor": "pointer",
          "color": "#366702",
          "dataPoints": [
            {
              "chartFooter": "Jan",
              "value": 455.46,
              "name": "2_2019_Jan_Production_Rubbles",
              "label": "Jan",
              "y": 455.46,
              "x": 0,
              "legendText": "Jan"
            },
            {
              "chartFooter": "Feb",
              "value": 0,
              "name": "2_2019_Feb_Production_Rubbles",
              "label": "Feb",
              "y": 0,
              "x": 1,
              "legendText": "Feb"
            },
            {
              "chartFooter": "Mar",
              "value": 1319.9,
              "name": "2_2019_Mar_Production_Rubbles",
              "label": "Mar",
              "y": 1319.9,
              "x": 2,
              "legendText": "Mar"
            },
            {
              "chartFooter": "Apr",
              "value": 2092.29,
              "name": "2_2019_Apr_Production_Rubbles",
              "label": "Apr",
              "y": 2092.29,
              "x": 3,
              "legendText": "Apr"
            },
            {
              "chartFooter": "May",
              "value": 2265.59,
              "name": "2_2019_May_Production_Rubbles",
              "label": "May",
              "y": 2265.59,
              "x": 4,
              "legendText": "May"
            },
            {
              "chartFooter": "Jun",
              "value": 2009.19,
              "name": "2_2019_Jun_Production_Rubbles",
              "label": "Jun",
              "y": 2009.19,
              "x": 5,
              "legendText": "Jun"
            },
            {
              "chartFooter": "Jul",
              "value": 2860.51,
              "name": "2_2019_Jul_Production_Rubbles",
              "label": "Jul",
              "y": 2860.51,
              "x": 6,
              "legendText": "Jul"
            },
            {
              "chartFooter": "Aug",
              "value": 1955.73,
              "name": "2_2019_Aug_Production_Rubbles",
              "label": "Aug",
              "y": 1955.73,
              "x": 7,
              "legendText": "Aug"
            },
            {
              "chartFooter": "Sep",
              "value": 970.74,
              "name": "2_2019_Sep_Production_Rubbles",
              "label": "Sep",
              "y": 970.74,
              "x": 8,
              "legendText": "Sep"
            },
            {
              "chartFooter": "Oct",
              "value": 652.32,
              "name": "2_2019_Oct_Production_Rubbles",
              "label": "Oct",
              "y": 652.32,
              "x": 9,
              "legendText": "Oct"
            },
            {
              "chartFooter": "Nov",
              "value": 1879.63,
              "name": "2_2019_Nov_Production_Rubbles",
              "label": "Nov",
              "y": 1879.63,
              "x": 10,
              "legendText": "Nov"
            },
            {
              "chartFooter": "Dec",
              "value": 502.96,
              "name": "2_2019_Dec_Production_Rubbles",
              "label": "Dec",
              "y": 502.96,
              "x": 11,
              "legendText": "Dec"
            }
          ]
        }
      ],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "Total Employees Hired",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    },
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "title": "ton",
        "gridThickness": 0,
        "titleFontSize": 14
      },
      data: [{
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}%",
        showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}%",
        dataPoints: [
          { y: 30, label: "Male", color: "#3c7c44" },
          { y: 60, label: "Female", color: "#d2df64" },
          { y: 10, label: "Other", color: "#80d18e" }
        ]
      }],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "Employees",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    },
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "title": "liters",
        "gridThickness": 0,
        "titleFontSize": 14
      },
      data: [{
        type: "doughnut",
        showInLegend: true,
        indexLabel: "{name}: {y}%",
        yValueFormatString: "#,###",
        dataPoints: [
          { name: "Permanent", y: 55, color: "#366702" },
          { name: "Temporary", y: 15, color: "#d2df64" },
          { name: "Contract", y: 30, color: "#80d18e" }
        ]
      }],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "Type of Employee",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    },
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "gridThickness": 0,
        "titleFontSize": 14
      },
      data: [
        {
          type: "area",
          name: "Male",
          color: "#366702",
          showInLegend: true,
          xValueFormatString: "MMM YYYY",
          yValueFormatString: "#,##0.##",
          dataPoints: [
            { x: new Date("2017- 06- 01"), y: 10 },
            { x: new Date("2017- 07- 01"), y: 30 },
            { x: new Date("2017- 08- 01"), y: 20 },
            { x: new Date("2017- 09- 01"), y: 60 },
            { x: new Date("2017- 10- 01"), y: 65 },
            { x: new Date("2017- 11- 01"), y: 40 },
            { x: new Date("2017- 12- 01"), y: 20 }
          ]
        },
        {
          type: "area",
          name: "Female",
          color: "#d2df64",
          showInLegend: true,
          xValueFormatString: "MMM YYYY",
          yValueFormatString: "#,##0.##",
          dataPoints: [
            { x: new Date("2017- 06- 01"), y: 5 },
            { x: new Date("2017- 07- 01"), y: 25 },
            { x: new Date("2017- 08- 01"), y: 10 },
            { x: new Date("2017- 09- 01"), y: 50 },
            { x: new Date("2017- 10- 01"), y: 40 },
            { x: new Date("2017- 11- 01"), y: 30 },
            { x: new Date("2017- 12- 01"), y: 10 }
          ]
        }
      ],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "Work Days Lost",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    }
  ];
  const governanceOptions = [
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "title": "ton",
        "gridThickness": 0,
        "titleFontSize": 14
      },
      data: [{
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}%",
        showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}%",
        dataPoints: [
          { y: 30, label: "Penulty", color: "#3c7c44" },
          { y: 60, label: "Punishment", color: "#d2df64" },
          { y: 10, label: "Componingg", color: "#80d18e" }
        ]
      }],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "Details Of Fine",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    },
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "title": "ton",
        "gridThickness": 0,
        "titleFontSize": 14
      },
      data: [{
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}",
        showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}",
        dataPoints: [
          { y: 10, label: "CEO", color: "#3c7c44" },
          { y: 5, label: "CTO", color: "#d2df64" },
          { y: 20, label: "Directors", color: "#80d18e" }
        ]
      }],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "KPI",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    },
    {
      axisX: {
        "labelWrap": true,
        "labelFontColor": "#366702",
        "labelAngle": 0,
        "labelFontSize": 14,
        "showLabel": false,
        "fontWeight": "600",
        "fontFamily": "Poppins"
      },
      axisY: {
        "title": "ton",
        "gridThickness": 0,
        "titleFontSize": 14
      },
      data: [{
        type: "doughnut",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}%",
        showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}%",
        dataPoints: [
          { y: 30, label: "PF", color: "#3c7c44" },
          { y: 60, label: "Gratuity", color: "#d2df64" },
          { y: 10, label: "ESI", color: "#80d18e" }
        ]
      }],
      theme: "light2",
      animationEnabled: true,
      title: {
        "text": "Statutory Dues",
        "fontSize": "16",
        "fontFamily": "Poppins",
        "fontWeight": "600",
        "fontColor": "#3c7c44"
      }
    }
  ];
    //meta title
  document.title = "Admin Dashboard | Greenalytics";

  return (
    <React.Fragment>
      <IsAuth />
      <div className="page-content">
        <Row style={{ marginTop: "-25px", marginLeft: "-10px" }}>
          <Nav pills className="navtab-bg nav-justified" style={{ backgroundColor: "#cdd7d0", paddingRight: "0px" }}>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", color: activeTab1 === "5" ? "#FFFFFF" : "#000000" }}
                className={classnames({
                  active: activeTab1 === "5"
                })}
                onClick={() => {
                  toggle1("5");
                }}
              >
                Environmental
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", color: activeTab1 === "6" ? "#FFFFFF" : "#000000" }}
                className={classnames({
                  active: activeTab1 === "6"
                })}
                onClick={() => {
                  toggle1("6");
                }}
              >
                Social
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", color: activeTab1 === "7" ? "#FFFFFF" : "#000000" }}
                className={classnames({
                  active: activeTab1 === "7"
                })}
                onClick={() => {
                  toggle1("7");
                }}
              >
                Governance
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab1} className="p-3 text-muted">
            <TabPane tabId="5">
              {/* <Row style={{ maxWidth: "1000px" }}>
                <Col lg={4}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Total Post</p>
                          <h5 className="mb-0">120</h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="bx bxs-book-bookmark"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Total Post</p>
                          <h5 className="mb-0">120</h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="bx bxs-book-bookmark"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}
              <Card className="mini-stats-wid">
                <CardBody>
                  <Row className="mt-5">
                    {
                      options.map((item, index) => (<Col key={`chart${index}`} lg={4} style={{ paddingTop: "20px" }}>
                        <Card style={{
                          paddingTop: "25px",
                          paddingBottom: "14px",
                          paddingLeft: "14px",
                          borderRadius: 8
                        }} className={classnames({ "shadow-chart": true })}>
                          <CanvasJSChart options={item}
                            /* onRef={ref => this.chart = ref} */
                          />
                        </Card>
                      </Col>))
                    }
                  </Row>
                </CardBody>
              </Card>
            </TabPane>
            <TabPane tabId="6">
              <Row style={{ minWidth: "1100px", maxWidth: "1200px" }}>
                <Col lg={4}>
                  <Card className="mini-stats-wid">
                    <CardHeader>
                      <div className="me-3">
                        <p className="text-muted mb-2">Skill Development Training to Employees</p>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3" style={{ display: "flex", alignItems: "center" }}>
                          <h5 className="mb-0">82 Hours</h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="bx bxs-book-bookmark"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card className="mini-stats-wid">
                    <CardHeader>
                      <div className="me-3">
                        <p className="text-muted mb-2">Health & Safety Training to Employees</p>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3" style={{ display: "flex", alignItems: "center" }}>
                          <h5 className="mb-0">76 Hours</h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="bx bxs-book-bookmark"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Row>
                    {
                      socialOptions.map((item, index) => (<Col key={`chart${index}`} lg={4} style={{ paddingTop: "20px" }}>
                        <Card style={{
                          paddingTop: "25px",
                          paddingBottom: "14px",
                          paddingLeft: "14px",
                          borderRadius: 8
                        }} className={classnames({ "shadow-chart": true })}>
                          <CanvasJSChart options={item}
                            /* onRef={ref => this.chart = ref} */
                          />
                        </Card>
                      </Col>))
                    }
                  </Row>
                </CardBody>
              </Card>
            </TabPane>
            <TabPane tabId="7">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Row>
                    {
                      governanceOptions.map((item, index) => (<Col key={`chart${index}`} lg={4} style={{ paddingTop: "20px" }}>
                        <Card style={{
                          paddingTop: "25px",
                          paddingBottom: "14px",
                          paddingLeft: "14px",
                          borderRadius: 8
                        }} className={classnames({ "shadow-chart": true })}>
                          <CanvasJSChart options={item}
                            /* onRef={ref => this.chart = ref} */
                          />
                        </Card>
                      </Col>))
                    }
                  </Row>
                </CardBody>
              </Card>
            </TabPane>
          </TabContent>
        </Row>
      </div>
    </React.Fragment >
  );
};

export default Index;
