
export const ROLES = {
  SUPERADMIN: "SuperAdmin",
  ADMIN: "Admin",
  USER: "User",
  STAKEHOLDERS: "Stakeholder"
};

export const API_URLS = {
  // AUTH
  LOGIN: `${process.env.REACT_APP_API_PATH}/login`,
  INFRADONE: `${process.env.REACT_APP_API_PATH}/isinfraDone`,
  REGISTER: `${process.env.REACT_APP_API_PATH}/register`,
  COMPANY_DETAILS: `${process.env.REACT_APP_API_PATH}/company-details`,
  RESEND_VERIFY_LINK: `${process.env.REACT_APP_API_PATH}/resend-verification-link`,
  COMPANY_URL: `${process.env.REACT_APP_API_PATH}/company-url`,
  RESET_PASSWORD: `${process.env.REACT_APP_API_PATH}/reset-password`,
  FORGOT_PASSWORD: `${process.env.REACT_APP_API_PATH}/forgot-password`,

  // ADMIN
  ADMIN_ADD_USERS: `${process.env.REACT_APP_ADMIN_API_PATH}/add-user`,
  ADMIN_FIND_USER: `${process.env.REACT_APP_ADMIN_API_PATH}/find-user-by-id`,
  ADMIN_DELETE_USERS: `${process.env.REACT_APP_ADMIN_API_PATH}/hard-delete-user`,
  ADMIN_UPDATE_USERS: `${process.env.REACT_APP_ADMIN_API_PATH}/update-user`,
  ADMIN_USERS: `${process.env.REACT_APP_ADMIN_API_PATH}/users`,
  GET_ALL_LOCATIONS: `${process.env.REACT_APP_ADMIN_API_PATH}/locations`,
  SUPPORTING_DOC: `${process.env.REACT_APP_ADMIN_API_PATH}/supportingDocList`,
  DOWNLOAD_SUPPORTING_DOC: `${process.env.REACT_APP_ADMIN_API_PATH}/download-brsr-uploaded-document`,
  GET_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/get-chart-data`,
  ADD_BUSINESS_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/add-business-details`,
  GET_BUSINESS_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/get-business-details`,
  GET_INITIALREPORT_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/get-initial-json`,
  GET_ALL_CATEGORY: `${process.env.REACT_APP_ADMIN_API_PATH}/get-all-category`,
  GET_SCOPE_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/get-carbon-scope-wise-total`,
  GET_SCOPE_ALL_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/get-carbon-scope-wise-consolidated-chart-data`,
  GET_SCOPE_MONTH_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/carbon-month-scope-wise-chart`,
  GET_SCOPE_LOOP_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/carbon-month-scope-wise-tree-chart`,
  GET_ALL_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/get-consolidated-chart-data`,
  GET_CARBON_ALL_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/get-staged-carbon-chart-data`,
  GET_GREENHOUSE_ALL_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/consolidated-total-of-greenhouse-year-wise`,
  GET_GREENHOUSE_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/total-of-greenhouse-year-wise`,
  GET_CARBONINTENSITY_ALL_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/get-consolidate-carbon-intensity-chart-data`,
  GET_CARBONINTENSITY_ALL_SUB_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/get-consolidate-carbon-intensity-month-wise-chart-data`,
  GET_CARBONINTENSITY_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/get-carbon-intensity-chart-data`,
  GET_CARBONINTENSITY_SUB_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/get-carbon-intensity-month-wise-chart-data`,
  GET_GREENHOUSE_ALL_SUB_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/consolidated-total-of-greenhouse-month-wise`,
  GET_GREENHOUSE_SUB_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/total-of-greenhouse-month-wise`,
  GET_CARBON_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/location-wise-staged-carbon-chart-data`,
  GET_SUB_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/response-after-level-1`,
  GET_CARBON_SUB_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/carbon-chart-response-after-level1`,
  GET_PREDICT_CARBON_SUB_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/get-carbon-prediction-chart-data-after-level1`,
  GET_PREDICT_SUB_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/prediction-response-after-level-1`,
  GET_SUB_ALL_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/consolidated-response-after-level-1`,
  GET_SUB_ALL_CHART_DATA_SCOPEWISE: `${process.env.REACT_APP_ADMIN_API_PATH}/scope-wise-consolidated-chart-response-after-level1`,
  GET_SUB_ALL_PREDICT_CHART_DATA: `${process.env.REACT_APP_ADMIN_API_PATH}/consolidated-prediction-response-after-level-1`,
  ADD_SEBI: `${process.env.REACT_APP_ADMIN_API_PATH}/add-sebi`,
  SEBI_DETAILS: `${process.env.REACT_APP_ADMIN_API_PATH}/sebi-details`,
  GENERATE_REPORT: `${process.env.REACT_APP_ADMIN_API_PATH}/generate-sebi-pdf`,
  SEND_REPORT_FOR_APPROVAL: `${process.env.REACT_APP_ADMIN_API_PATH}/send-mail-for-report-review`,
  VERIFY_REPORT_APPROVAL_TOKEN: `${process.env.REACT_APP_ADMIN_API_PATH}/decode-report-review-token`,
  APPROVE_REPORT: `${process.env.REACT_APP_ADMIN_API_PATH}/update-report-status`,
  DOWNLOAD_CSV: `${process.env.REACT_APP_ADMIN_API_PATH}/download-csv-template`,
  GET_USER_COUNT: `${process.env.REACT_APP_ADMIN_API_PATH}/get-total-users`,
  RESEND_USER_VERIFY_LINK: `${process.env.REACT_APP_ADMIN_API_PATH}/resend-verification-link`,
  PREDICTIVE_CHART: `${process.env.REACT_APP_ADMIN_API_PATH}/get-prediction-chart-data`,
  PREDICTIVE_CARBON_CHART: `${process.env.REACT_APP_ADMIN_API_PATH}/get-prediction-carbon-chart-data`,
  CARBON_CHART: `${process.env.REACT_APP_ADMIN_API_PATH}/get-carbon-chart-data`,
  ALL_PREDICTIVE_CHART: `${process.env.REACT_APP_ADMIN_API_PATH}/get-consolidated-prediction-chart-data`,
  GET_FILE: `${process.env.REACT_APP_ADMIN_API_PATH}/get-stored-document`
};

export const REFERENCE = {
  LABEL_MAX_WIDTH: 5,
  MINES: ["2.98", "2.57", "2.42", "1.75", "1.56", "1.07"],
  LITERS: "Liters"
};

export const COLORS = {
  ACCENT_1: "#006600",
  ACCENT_2: "#9BBC53",
  ACCENT_3: "#D3DB63",
  ACCENT_4: "#3f7e44"
};

export const THEME = {
  h3: { fontSize: 18 },
  p: { fontSize: 15 }
};
