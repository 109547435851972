import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import ResendVerifySaga from "./auth/ResendVerifyLink/saga";
import AdminUsersSaga from "./admin/users/saga";

export default function* rootSaga () {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ResendVerifySaga),
    fork(AdminUsersSaga)
  ]);
}
