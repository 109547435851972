import React, { useEffect } from "react";
import * as Yup from "yup";
import classnames from "classnames";
import { Formik, Form } from "formik";
import PhoneInput from "react-phone-input-2";
import { withRouter } from "react-router-dom";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";

// step one function
const ConfigureYourProfile = props => {

  useEffect(() => {
    props.setstepLoading(prev => ({ ...prev, ["step1"]: false }));
  }, [1]);

  const handleSubmit = async values => {
    // var appendData = {...props.data, values}
    props.setstepLoading(prev => ({ ...prev, ["step1"]: true }));
    setTimeout(() => {
      props.setstep(prev => ({ ...prev, ["step1"]: true }));
      props.next(values);
      props.toggleTab(1, 25);
      props.setstepLoading(prev => ({ ...prev, ["step1"]: false }));
    }, 500);
  };

  const handleChange = () => {};
  // step one validation schema
  const ConfigureYourProfileValidationSchema = Yup.object({
    smsNotification: Yup.boolean(),
    phoneNumber: Yup.string().when("smsNotification", {
      is: true,
      then: Yup.string().min(10).required("Please Enter Phone Number")
    })
  });

  return (
    <div className="content w-100 clearfix mt-0">
      {/* <TabContent activeTab={0} className="body">
        <TabPane tabId={0}> */}
      <Formik
        validationSchema={ConfigureYourProfileValidationSchema}
        initialValues={props.data}
        onSubmit={handleSubmit}
        onChange={handleChange}
      >
        {({ values, errors, touched, setFieldValue }) => (
          // step one form
          <Form>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="phoneNumber">
                    <b>Phone Number</b>
                  </Label>
                  <p>
                    Enter the phone number that you want to get Greenalytics
                    notifications to.
                  </p>
                  <Input type="hidden" name="phoneNumber" />
                  <PhoneInput
                    // country={'in'}
                    inputExtraProps={{
                      name: "phoneNumber",
                      required: true,
                      autoFocus: true
                    }}
                    className={classnames(
                      "form-control",
                      touched.phoneNumber && errors.phoneNumber
                        ? "is-invalid"
                        : ""
                    )}
                    value={values.phoneNumber}
                    onChange={val =>
                      setFieldValue("phoneNumber", "+" + val)
                    }
                  />
                  {touched.phoneNumber && errors.phoneNumber
                    ? (
                      <FormFeedback type="invalid">
                        {errors.phoneNumber}
                      </FormFeedback>
                    )
                    : null}
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col lg="12">
                <div className="mb-3">
                  <Label for="notifi">
                    <b>Send test notifications</b>
                  </Label>
                  <div
                    className="alert alert-primary fade show mt-2"
                    role="alert"
                  >
                    <i className="mdi mdi-alert-circle me-2"></i>
                    Please enter a phone number in the previous step to test
                    SMS and voice notifications.
                  </div>
                  <p>
                    Greenalytics uses multiple notification channels (SMS,
                    mobile push, phone call or email) to ensure that you
                    don&lsquo;t miss any critical alerts.
                  </p>
                  <div className="form-check-inline">
                    <Input
                      name="emailNotification"
                      type="checkbox"
                      id="email"
                      className="form-check-input me-2"
                      onClick={e =>
                        setFieldValue("emailNotification", e.target.checked)
                      }
                      defaultChecked={values.emailNotification}
                    />
                    <Label className="form-check-label" for="email">
                      E-mail
                    </Label>
                  </div>

                  <div className="form-check-inline">
                    <Input
                      type="checkbox"
                      name="smsNotification"
                      id="sms"
                      className="form-check-input me-2"
                      onClick={e =>
                        setFieldValue("smsNotification", e.target.checked)
                      }
                      defaultChecked={values.smsNotification}
                    />
                    <Label className="form-check-label" for="sms">
                      SMS
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-3 next-prev-btn">
              <Col>
                {props.step && props.step.step1
                  ? (
                    <button
                      className="btn btn-primary btn-block"
                      type="button"
                      onClick={() => props.next(values)}
                      disabled={props.stepLoading.step1}
                    >
                    Next
                    </button>
                  )
                  : (
                    <button
                      className="btn btn-primary btn-block"
                      type="submit"
                      disabled={props.stepLoading.step1}
                    >
                      {props.stepLoading.step1
                        ? (
                          <div
                            className="spinner-border text-white"
                            role="status"
                            style={{ width: "1rem", height: "1rem" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )
                        : (
                          "Next"
                        )}
                    </button>
                  )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {/* </TabPane>
      </TabContent> */}
    </div>
  );
};

export default withRouter(ConfigureYourProfile);
