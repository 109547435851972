/*eslint-disable*/
import React from "react";
import classnames from "classnames";
import { Icon } from "@iconify/react";
import { Card, Label } from "reactstrap";
import { toast } from "react-toastify";
import Switch from "react-switch";

import CanvasJSReact from "./canvasjs/canvasjs.react";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
import { API_URLS } from "common/constants";
import { AxiosGet } from "common/axiosService";

const drilldownedChartOptions = {
  animationEnabled: true,
  theme: "light2",
  exportEnabled: true,
  axisX: {
    labelWrap: true,
    // labelFontColor: "#366702",
    labelAngle: 0,
    showLabel: false,
    lineThickness: 2,
    fontWeight: "600",
    fontFamily: "Poppins",
    labelFontSize: 14,
    stripLines: [
      {
        value: 0,
        thickness: 0
      }
    ]
  },
  axisY: {
    valueFormatString: "#,##,###",
    title: "kg CO₂e",
    minimum: 0,
    gridThickness: 0,
    lineThickness: 2,
    includeZero: false,
    // labelFontColor: "#366702",
    titleFontSize: 14,
    fontFamily: "Poppins",
    fontWeight: "600"
  },
  data: []
};

class TestChart extends React.Component {
  constructor (props) {
    super(props);
    this.chartDrilldownHandler = this.chartDrilldownHandler.bind(this);
    this.initializeChart = this.initializeChart.bind(this);
    this.options = {};
    this.options2 = {};
    this.pathName = [];
    this.newVSReturningVisitorsOptions = { ...drilldownedChartOptions };
    this.mainCategoryName = props.mainCategory ? props.mainCategory : props.heading;
    this.back1 = props.back1;
    this.items = props.items;
    this.analyticsType = props.analyticsType;
    this.authorizedUser = localStorage.getItem("authUser") &&
      JSON.parse(localStorage.getItem("authUser"));
    this.title = props.heading;
    this.previousLevel = "";
    this.previousName = [];
    this.currentLevelData = [];
    this.state = {
      checked: !!this.props.predictiveChart,
      isLoading: false,
      locationId: props.locationId,
      value: props.chartType ? props.chartType : "column",
      year: props.predictiveYears ? props.predictiveYears : 2,
      graphData: [],
      selectedChart: ""
    };
  }
  change = event => {
    this.setState({ value: event.target.value });
    if (this.props.currentChart) {
      this.chartDrilldownHandler(
        this.props.currentChart.e,
        this.props.currentChart.dataPoints,
        event.target.value
      );
    } else {
      this.initializeChart(event.target.value);
    }
  }

  chartDrilldownHandler (e, dataPoints, chartType, isBack) {
    if (dataPoints.length > 0) {
      this.setState({ selectedChart: e });
      let newPreviousLevel = [...this.previousName];
      if (isBack) {
        newPreviousLevel.pop();
      } else {
        newPreviousLevel.push(e.dataPoint.name);
      }
      newPreviousLevel = Array.from(new Set(newPreviousLevel));
      this.previousName = newPreviousLevel;
      this.previousLevel = e.dataSeries.level + e.dataSeries.levelNo;
      const chart = this.chart;
      chart.options = this.newVSReturningVisitorsOptions;
      chart.options.axisX.stripLines[0].value = 0;
      chart.options.axisX.stripLines[0].thickness= 0;
      const updatedData = dataPoints.map((item) => {
        item.label = item.chartFooter ? item.chartFooter : item.month ? item.month : item.scope ? item.scope : "";
        item.ylabel = item.total ? Number(item.total).toLocaleString("en-IN") : Number(item.y).toLocaleString("en-IN");
        // item.legendText = item.chartFooter ? item.chartFooter : item.month ? item.month : item.scope ? item.scope : "";
        return item;
      });
      const data = [{
        click: this.fetchApiData,
        level: "Level",
        levelNo: parseInt(e.dataSeries.levelNo + 1),
        type: chartType ? chartType : this.state.value,
        cursor: "pointer",
        color: e.dataSeries.color,
        showInLegend: false,
        toolTipContent: "<span style=color:#366702;>{label}</span>: <span style=color:#000;>{ylabel}</span>",
        // legendText: e.dataSeries.legendText,
        dataPoints: updatedData
      }];
      chart.options = {
        ...chart.options,
        data: data,
        axisX: {
          labelWrap: true,
          // labelFontColor: "#366702",
          labelAngle: 0,
          showLabel: false,
          fontWeight: "600",
          lineThickness: 2,
          fontFamily: "Poppins",
          labelFontSize: 14,
          title: this.pathName.length > 0 ? this.pathName.join(" > ") : "",
          stripLines: [
            {
              value: 0,
              thickness: 0
            }
          ]
        },
        title: {
          // text: e.dataSeries.legendText,
          fontSize: "16",
          fontFamily: "Poppins",
          fontWeight: "600",
          fontColor: e.dataSeries.color
        }
      };
      if (updatedData.length !== 1) {
        chart.options.axisX.interval = 1;
      }
      const back = document.querySelector(`#${this.back1}`);
      back.classList.remove("invisible");
      if (document.querySelector(`#${this.back1}zoomButton`)) {
        document.querySelector(`#${this.back1}zoomButton`).classList.remove("zoomButton");
        document.querySelector(`#${this.back1}zoomButton`).classList.add("zoomBackButton");
      }
      chart.render();
    }
  }

  fetchApiData = async e => {
    try {
      this.setState({ isLoading: true });
      let response;
      if (e.dataPoint.isPredictedValue) {
        response = await AxiosGet(`${API_URLS.GET_PREDICT_CARBON_SUB_CHART_DATA}/${e.dataPoint.name}`);
      } else {
        response = await AxiosGet(this.state.locationId ? `${API_URLS.GET_CARBON_SUB_CHART_DATA}/${this.state.locationId}/${e.dataPoint.name}`
        : `${API_URLS.GET_SUB_ALL_CHART_DATA_SCOPEWISE}/${e.dataPoint.name}`);
      }
      if (response && (response.statusCode === 200 && response.data[0]) ) {
        const dataPoints = response.data[0]?.dataPoints.map((item, index) => {
          item.label = item.chartFooter ? item.chartFooter : "0";
          item.y = Number(item.value);
          item.x = index;
          return item;
        });
        this.props.onChartSegmentClick( e, dataPoints, this.props.heading, this.previousLevel, this.props.mainCategory ? this.props.mainCategory : this.mainCategoryName );
        // this.props.onChartSegmentClick( e, dataPoints, this.props.heading, this.previousLevel );
        const arr = [...this.pathName, e.dataPoint.label];
        this.pathName = [...new Set(arr)];
        this.chartDrilldownHandler(e, dataPoints, this.state.value);
      }
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  }

  componentDidMount () {
    this.initializeChart();
  }

  initializeChart = chartType => {
    this.previousLevel = "";
    this.previousName = "";
    const chart = this.chart;
    chart.options = this.newVSReturningVisitorsOptions;
    const colors = [ "#3f7e44", "#d2df64", "#4c9f38", "#99b951", "#4d9953", "#6bc355", "#2d5e21", "#51aa3c", "#86a442", "#86a442", "#aebe27", "#88941f"];
    const predictColors = ["#88941f", "#aebe27", "#86a442", "#86a442", "#51aa3c", "#2d5e21", "#6bc355", "#4d9953", "#99b951", "#4c9f38", "#d2df64", "#3f7e44"];
    chart.options = {
      ...chart.options,
      axisY: {
        valueFormatString: "#,##,###",
        title: "kg CO₂e",
        gridThickness: 0,
        minimum: 0,
        lineThickness: 2,
        titleFontSize: 14,
        stripLines: [
          {
            value: 0,
            thickness: 0
          }
        ]
      },
      title: {
        text: this.props.heading,
        fontSize: "16",
        fontFamily: "Poppins",
        fontWeight: "600",
        fontColor: "#3c7c44"
      }
    };
    let data;
    if (this.props.predictChartData) {
      const predictData = Object.entries(this.props.predictChartData["Level1"]).map(([d, predictValue], predictIndex) => {
        const value = predictValue;
        value["dataValue"] = d;
        value["dataPoints"] = predictValue.dataPoints.map(valueItem => ({
          label: valueItem.label ? valueItem.label : valueItem.year ? valueItem.year : valueItem.chartFooter,
          name: valueItem.name,
          isPredictedValue: true,
          y: valueItem.total ? valueItem.total : valueItem.y,
          ylabel: valueItem.total ? Number(valueItem.total).toLocaleString("en-IN") : Number(valueItem.y).toLocaleString("en-IN"),
          x: valueItem.x,
          color: predictColors[predictIndex]
        }));
        return value;
      });
      data = Object.entries(this.props.items["Level1"]).map(
        ([mainValue, value], i) => {
          let pointData = value.dataPoints.map((valueItem) => ({
            label: valueItem.year ? valueItem.year : valueItem.chartFooter,
            name: valueItem.name,
            y: valueItem.total ? valueItem.total : valueItem.y,
            ylabel: valueItem.total ? Number(valueItem.total).toLocaleString("en-IN") : Number(valueItem.y).toLocaleString("en-IN"),
            x: valueItem.x
          }));
          if (predictData.some(predict=> predict.dataValue === mainValue)) {
            pointData = [...pointData, ...predictData.find(predict=> predict.dataValue === mainValue).dataPoints];
          }
          if (pointData.length !== 1) {
            chart.options.axisX.interval = 1;
          }
          return {
            click: this.fetchApiData,
            level: "Level",
            levelNo: parseInt(2),
            type: chartType
              ? chartType
              : (this.analyticsType && this.previousLevel === "")
                ? "column"
                : this.state.value,
            cursor: "pointer",
            color: colors[i],
            toolTipContent: "<span style=color:#366702;>{label}</span>: <span style=color:#000;>{ylabel}</span>",
            // legendText: (value.scope && value.title)
            //   ? value.scope.replace(/\s/g, "") === value.title.replace(/\s/g, "")
            //     ? value.title
            //     : value.title.replace(value.scope, "")
            //   : value.title ? value.title : value.scope,
            showInLegend: false,
            dataPoints: pointData
          };
        }
      );
    } else {
      data = Object.entries(this.props.items["Level1"]).map(
        ([, value], i) => {
          const dataPoints = value.dataPoints.map((valueItem) => ({
            label: valueItem.year ? valueItem.year : valueItem.chartFooter,
            name: valueItem.name,
            y: valueItem.total ? valueItem.total : valueItem.y,
            ylabel: valueItem.total ? Number(valueItem.total).toLocaleString("en-IN") : Number(valueItem.y).toLocaleString("en-IN"),
            x: valueItem.x
          }));
          if (this.props.heading) {
            this.mainCategoryName = this.props.heading;
          }
          if (dataPoints.length !== 1) {
            chart.options.axisX.interval = 1;
          } else {
            chart.options.axisX.interval = 0;
          }
          return {
            click: this.fetchApiData,
            level: "Level",
            levelNo: parseInt(2),
            type: chartType
              ? chartType
              : (this.analyticsType && this.previousLevel === "")
                ? "column"
                : this.state.value,
            cursor: "pointer",
            color: colors[i],
            toolTipContent: "<span style=color:#366702;>{label}</span>: <span style=color:#000;>{ylabel}</span>",
            // legendText: (value.scope && value.title)
            //   ? value.scope.replace(/\s/g, "") === value.title.replace(/\s/g, "")
            //     ? value.title
            //     : value.title.replace(value.scope, "")
            //   : value.title ? value.title : value.scope,
            showInLegend: false,
            dataPoints: dataPoints
          };
        }
      );
    }
    chart.options.data = data;
    if (this.props.predictChartData && this.props.standAloneChart) {
      let lineValue = 0;
      chart.options.data[0].dataPoints.forEach((element, i) => {
        if (!element.isPredictedValue) {
          lineValue = i;
        }
      });
      chart.options.axisX.stripLines[0].value = lineValue + 0.5;
      chart.options.axisX.stripLines[0].thickness= 3;
    } else {
      chart.options.axisX.stripLines[0].value = 0;
      chart.options.axisX.stripLines[0].thickness= 0;
    }
    chart.render();
    const toolbars = document.getElementsByClassName("canvasjs-chart-toolbar");
    Object.keys(toolbars).forEach(key => {
      if (toolbars[key]) {
        toolbars[key].style.right = this.props.standAloneChart ? "0px" : "-4%";
      }
    });
    document.querySelector(`#${this.back1}`).classList.add("invisible");
    if (document.querySelector(`#${this.back1}zoomButton`)) {
      document.querySelector(`#${this.back1}zoomButton`).classList.add("zoomButton");
      document.querySelector(`#${this.back1}zoomButton`).classList.remove("zoomBackButton");
    }
  }

  backToParentChart = () => {
    if (this.previousName && this.previousName.length > 1) {
      this.pathName && this.pathName.pop();
      const data = this.props.items[`Level${this.previousName.length - 1}Data`];
      this.chartDrilldownHandler( data.e, data.dataPoints, this.state.value, true );
    } else {
      this.pathName = [];
      this.initializeChart();
    }
    this.props.onChartSegmentClick( "", "", this.props.heading, this.previousLevel );
  }

  handleTimeline = async e => {
    this.setState({ year: e.target.value });
    this.props.onFullScreenPredictButtonClick(true, "Level1-" + this.mine, this.state.value, this.categoryName, +e.target.value,
      this.props.mainCategory ? this.props.mainCategory : this.mainCategoryName);
  };
  handleChange = (value) => {
    this.setState({ checked: value });
    if (this.props.standAloneChart) {
      this.props.onFullScreenPredictButtonClick(value, "Level1-" + this.mine, this.state.value, this.categoryName,
        this.state.year, this.props.mainCategory ? this.props.mainCategory : this.mainCategoryName);
    } else {
      if (this.props.currentChart) {
        const params = { Level1: { data: { title: this.props.currentChart.e.dataSeries.legendText, dataPoints: this.props.currentChart.dataPoints } } };
        this.props.onPredictiveToggle(
          this.props.heading, params, this.state.value, value, this.mine, this.categoryName, this.state.year,
          this.props.mainCategory ? this.props.mainCategory : this.mainCategoryName, this.previousName
        );
      } else {
        this.props.onPredictiveToggle( this.props.heading, this.props.items, this.state.value, value, this.mine, this.categoryName, this.state.year,
          this.props.mainCategory ? this.props.mainCategory : this.mainCategoryName, this.previousName
        );
      }
    }
  };
  render () {
    const buttonStyle = { fontSize: "22px", position: "absolute", top: "11px", right: this.props?.standAloneChart ? "20px" : "-0.5%", cursor: "pointer" };
    const zoomStyle = { fontSize: "22px", position: "absolute", top: "12px", right: "12.5%", cursor: "pointer" };
    return (
      <Card
        className={classnames({ "shadow-chart": !this.props?.standAloneChart })}
        style={{
          paddingTop: "25px",
          paddingBottom: "14px",
          paddingLeft: "14px",
          borderRadius: 8,
          pointerEvents: this.state.isLoading ? "none" : "auto"
        }}
        key={this.props.index}
      >
        {this.props.items["Level1"]?.length == 0
          ? <div style={{ position: "absolute", top: "50%", left: "calc(50% - 30px)" }} >
          No data
          </div>
          : <CanvasJSChart
            options={this.options}
            onRef={ref => {
              this.chart = ref;
            }}
            containerProps={
              this.props.standAloneChart
                ? { width: "100%", position: "relative" }
                : { width: "90%", position: "relative" }
            }
          />}
        <button
          id={this.back1}
          className="btn invisible"
          style={buttonStyle}
          onClick={this.backToParentChart}
        >
          <Icon icon="bx:bx-arrow-back" />
        </button>
        {/* {!this.props.standAloneChart && (
          <button
            className="btn"
            style={zoomStyle}
            onClick={() => {
              if (this.props.currentChart) {
                const params = {
                  Level1: {
                    data: {
                      title: this.props.currentChart.e.dataSeries.legendText,
                      dataPoints: this.props.currentChart.dataPoints
                    }
                  }
                };
                this.props.onChartSegmentClick("", "", this.props.heading);
                this.props.onFullScreenButtonClick(
                  this.props.currentChart.e.dataSeries.legendText, params, this.state.value, this.previousName,
                  this.props.mainCategory ? this.props.mainCategory : this.mainCategoryName
                );
                // this.props.onFullScreenButtonClick( this.props.currentChart.e.dataSeries.legendText, params, this.state.value );
              } else {
                this.props.onFullScreenButtonClick(
                  this.props.heading, this.props.items, this.state.value, this.previousName,
                  this.props.mainCategory ? this.props.mainCategory : this.mainCategoryName
                );
                // this.props.onFullScreenButtonClick( this.props.heading, this.props.items, this.state.value );
              }
            }}
          >
            <Icon icon="bx:bx-fullscreen" />
          </button>
        )} */}
        <br />
        {this.state.checked
          ? <div className="mb-1 predictive_actions me-3">
            <label style={{ fontSize: "12px" }}>
              Chart type :
              <select
                className="inline-block form-select"
                value={this.state.value}
                onChange={this.change}
              >
                <option value="area">area</option>
                <option value="line">line</option>
                <option value="column">column</option>
                <option value="bar">bar</option>
                <option value="spline">spline</option>
              </select>
            </label>
            <label style={{ fontSize: "12px" }}>
              Predictive timeline :
              <select
                className="inline-block form-select"
                value={this.state.year}
                onChange={this.handleTimeline}
              >
                <option value={2}>2 Years</option>
                <option value={3}>3 Years</option>
                <option value={5}>5 Years</option>
                <option value={10}>10 Years</option>
              </select>
            </label>
            <label style={{ fontSize: "12px" }}>
              Predictive model :
              <select
                className="inline-block form-select"
                style={{
                  width: "43%",
                  display: "inline-block",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  marginLeft: "5px"
                }}
              >
                <option>Auto Regressive</option>
                <option>Moving Average</option>
                <option>Auto Regressive Moving Average</option>
                <option>
                  Seasonal Autoregressive Integrated Moving Average
                </option>
              </select>
            </label>
          </div>
          : <div className="mb-1 predictive_actions me-3">
            <Label style={{ fontSize: "12px" }}>Chart type :</Label>
            {this.props.analyticsType === "governance"
              ? <select
                className="inline-block form-select"
                value={this.state.value}
                onChange={this.change}
              >
                <option value="line">Line</option>
                <option value="column">Column</option>
              </select>
              : this.props.analyticsType === "social"
                ? <select
                  className="inline-block form-select"
                  value={this.state.value}
                  onChange={this.change}
                >
                  <option value="line">Line</option>
                  <option value="column">Column</option>
                  {this.previousLevel !== "" && (
                    <option value="doughnut">Doughnut</option>
                  )}
                  {this.previousLevel !== "" && <option value="pie">Pie</option>}
                </select>
                : <select
                  className="inline-block form-select"
                  value={this.state.value}
                  onChange={this.change}
                >
                  <option value="column">Column</option>
                  <option value="bar">Bar</option>
                </select>}
            {/* {this.props.alyticsType !== "Carbon Footprint" && <label className="mt-1 d-flex align-items-center">
              {" "}
              <span style={{ verticalAlign: "top", fontSize: "14px" }}>
                Predictive analytics :{" "}
              </span>
              <Switch
                height={17}
                width={35}
                className="react-switch ms-1"
                onChange={value => this.handleChange(value, false)}
                checked={this.state.checked}
              />
            </label>} */}
          </div>}
      </Card>
    );
  }
}

export default TestChart;