import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  FORGET_PASSWORD_RESET
} from "./actionTypes";

const initialState = {
  email: null,
  forgetSuccessMsg: null,
  forgetError: null,
  loading: null
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
  case FORGET_PASSWORD:
    state = {
      ...state,
      email: action.payload.user.email,
      forgetSuccessMsg: null,
      forgetError: null,
      loading: true
    };
    break;
  case FORGET_PASSWORD_SUCCESS:
    state = {
      ...state,
      forgetSuccessMsg: action.payload,
      loading: false
    };
    break;
  case FORGET_PASSWORD_ERROR:
    state = { ...state, email: null, forgetError: action.payload, loading: false };
    break;
  case FORGET_PASSWORD_RESET:
    state = { ...state, email: null, forgetError: null, forgetSuccessMsg: null, loading: false };
    break;
  default:
    state = { ...state };
    break;
  }
  return state;
};

export default forgetPassword;
