import React from "react";
import { Spinner } from "reactstrap";

const Loading = () => (
  <div
    style={{
      position: "fixed",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 9999,
      backgroundColor: "rgba(0, 0, 0, 0.3)"
    }}
  >
    <Spinner color="primary" />
  </div>
);

export default Loading;
