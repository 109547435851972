import {
  REGISTER_COMPANY_SUCCESSFUL,
  REGISTER_COMPANY_FAILED,
  REGISTER_COMPANY,
  REGISTER_PROGRESS,
  RESET_REGISTRATION
} from "./actionTypes";

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  company: null,
  progress: 0
};

const Account = (state = initialState, action) => {
  switch (action.type) {
  case REGISTER_COMPANY:
    state = {
      ...state,
      loading: true,
      registrationError: null
    };
    break;
  case REGISTER_COMPANY_SUCCESSFUL:
    state = {
      ...state,
      loading: false,
      company: action.payload.message,
      registrationError: null
    };
    break;
  case REGISTER_COMPANY_FAILED:
    state = {
      ...state,
      company: null,
      loading: false,
      registrationError: action.payload.message,
      progress: 0
    };
    break;
  case REGISTER_PROGRESS:
    state = {
      ...state,
      progress: action.payload
    };
    break;
  case RESET_REGISTRATION:
    state = {
      ...state,
      loading: false,
      registrationError: null,
      company: null
    };
    break;
  default:
    state = { ...state };
    break;
  }
  return state;
};

export default Account;
