import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, Row, Input, Label, Spinner, Nav, NavItem, NavLink, TabContent, TabPane, BreadcrumbItem, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import Sidebar from "./Sidebar";
import Loading from "components/Common/Loading";
import DynamicFormGenerator from "./DynamicFormGenerator";
import { AxiosGet, AxiosPost } from "common/axiosService";
import classnames from "classnames";
import dateFormat from "dateformat";
import { Dots } from "react-activity";
import ReactApexChart from "react-apexcharts";
import _ from "lodash";

function UiTabsAccordions () {
  document.title = "Manual Entry | GreenAlytics";
  const years = Array.from( { length: 20 }, (_, i) => new Date().getFullYear() - i );
  const [customIconActiveTab, setcustomIconActiveTab] = useState("1");
  const [trandscharts, setTrandsCharts] = useState([]);
  const [bredcrumsItems, setBredcrumsItems] = useState([]);
  const [isDataToggle, setIsDataToggle] = useState(false);

  const [items, setItems] = useState([]);
  const [showloader, setShowLoader] = useState({ main: false, activity: false, monthbtn: false });
  const [monthbtn, setMonthbtn] = useState( false );
  const [filterditems, setFilterdItems] = useState();
  const [filterdAllitems, setFilterdAllItems] = useState();
  const [history, setHistory] = useState();
  const [resultobject, setResultObject] = useState();

  const [locationList, setLocationList] = useState([]);
  const [data, setData] = useState( { locationId: "", year: "", month: "" } );
  const [showformdata, setShowFormData] = useState( { selectCategory: "", mainCategory: "", allData: [] } );
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const session = JSON.parse(localStorage.getItem("authUser"));
  const companyId = session.userData.user.companyId ? session.userData.user.companyId._id : "";
  const options = {
    chart: {
      toolbar: {
        show: true, offsetX: 0, offsetY: 0,
        tools: {
          download: true, selection: true, zoom: false, zoomin: true, zoomout: true, pan: false,
          reset: true | "<img src=\"/static/icons/reset.png\" width=\"20\">", customIcons: []
        }
      },
      id: "line"
    },
    yaxis: {
      axisBorder: {
        show: true
      }
    },
    dataLabels: { enabled: !1 },
    stroke: { curve: "straight", width: 2 },
    markers: { size: 0, style: "hollow" },
    colors: ["#3f7e44"],
    tooltip: { x: { show: true } }
  };
  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/get-all-category`, {});
        if (response && response.statusCode === 200) {
          let alldataaaaa;
          let selectedCategoryyyyy;
          function mapRecursiveData (data) {
            data?.map((subItem, subIndex) => {
              if (subIndex === 0) {
                subItem.isOpen = true;
                alldataaaaa = subItem?.data;
                selectedCategoryyyyy = subItem;
                if (subItem?.subCategory?.length > 0) {
                  mapRecursiveData(subItem?.subCategory);
                }
              } else {
                subItem.isOpen = false;
              }
            });
          }
          const filterData = response?.data?.map((item, index) => {
            if (index === 0) {
              item.isOpen = true;
              alldataaaaa = item?.data;
              if (item?.subCategory?.length > 0) {
                mapRecursiveData(item?.subCategory);
              }
            } else {
              item.isOpen = false;
            }
            return item;
          });
          if (response.data[0]?._id === selectedCategoryyyyy?._id) {
            setShowFormData({ ...showformdata, mainCategory: "", selectCategory: selectedCategoryyyyy, allData: alldataaaaa });
          } else {
            setShowFormData({ ...showformdata, mainCategory: response.data[0], selectCategory: selectedCategoryyyyy, allData: alldataaaaa });
          }
          setItems(filterData);
          setShowLoader({ ...showloader, main: true });
        } else {
          setShowLoader({ ...showloader, main: true });
        }
      } catch (error) {
        setShowLoader({ ...showloader, main: true });
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/locations/${companyId}`, {});
        if (response && response.statusCode === 200) {
          setLocationList(response.data);
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, []);

  useEffect(() => {
    let bredcrumList = [];
    let alldataaaaa;
    let selectedCategoryyyyy;
    let mainCategoryyyy;
    const dataItems = filterditems ? filterditems : items;
    dataItems.forEach(item => {
      if (item?.isOpen) {
        alldataaaaa = item?.data;
        selectedCategoryyyyy = item;
        mainCategoryyyy = item;
        bredcrumList = [...bredcrumList, item.categoryName];
        setBredcrumsItems(bredcrumList);
        if (item?.subCategory.length > 0) {
          recursiveFunc(item?.subCategory, bredcrumList, alldataaaaa, selectedCategoryyyyy, mainCategoryyyy);
        }
      }
    });
    if (bredcrumsItems.length === 1) {
      if (mainCategoryyyy?._id === selectedCategoryyyyy?._id) {
        setShowFormData({ ...showformdata, mainCategory: "", selectCategory: selectedCategoryyyyy, allData: alldataaaaa });
      } else {
        setShowFormData({ ...showformdata, mainCategory: mainCategoryyyy, selectCategory: selectedCategoryyyyy, allData: alldataaaaa });
      }
    }
  }, [items.length, showformdata?.selectCategory, showformdata?.mainCategory, isDataToggle]);

  const getHistory = async (result, test) => {
    const isEmpty = Object.values(test).every(value => !!value);
    if(isEmpty){
      try {
        const response = await AxiosPost(`${apipath}/manual-upload-history-logs`, { ...result, ...test });
        if (response && response.statusCode === 200) {
          setShowLoader({ ...showloader, activity: true });
          setHistory(response.data[0]);
        } else {
          setShowLoader({ ...showloader, activity: true });
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    }
  };

  const getcharts = async (result, test) => {
    const trends = Object.values(test).every(value => !!value);
    const params = { ...result, ...test };
    delete params.month;
    if(trends){
      try {
        const response = await AxiosPost(`${apipath}/manual-upload-chart-data`, params);
        if (response && response.statusCode === 200) {
          setTrandsCharts(response.data[0]?.result);
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let test = { ...data, [name]: value };
    setData({ ...data, [name]: value });
    let result = { category: showformdata.mainCategory.categoryName };
    if (showformdata.allData.length > 0) {
      if (showformdata.mainCategory) {
        function mapData (data, childData, index, parentCategory) {
          if (data._id === childData._id) {
            result[`subCategory${index}`] = data.categoryName;
            if (index === 1) {
              return;
            } else {
              if (parentCategory) {
                showformdata.mainCategory.subCategory?.map(d => {
                  mapData(d, parentCategory, 1);
                });
              }
            }
          } else {
            data.subCategory?.map(d => {
              mapData(d, childData, index + 1, data);
            });
          }
        }
        const category = showformdata.selectCategory;
        showformdata.mainCategory.subCategory.map(d => mapData(d, category, 1));
      } else {
        result.category = showformdata.selectCategory.categoryName;
      }
      // blank value for clear form;
      _.set(showformdata.allData[0], "formFields.value.value", "");
      _.set(showformdata.allData[0], "formFields.comment.value", "");
    }
    getHistory(result, test);
    delete test["month"];
    if(name !== "month"){
      getcharts(result, test);
    }
    setResultObject(result);
  };

  let timerID;

  async function main () {
    let name = document.getElementById("category").value;
    let res = await filterBy(filterdAllitems ? filterdAllitems : items, name);
    if (res.length > 0) {
      function mapRecursiveData (data) {
        data?.map((subItem, subIndex) => {
          if (subIndex === 0) {
            subItem.isOpen = true;
            if (subItem?.subCategory?.length > 0) {
              mapRecursiveData(subItem?.subCategory);
            }
          } else {
            subItem.isOpen = false;
          }
        });
      }
      const filterData = res?.map((item, index) => {
        if (index === 0) {
          item.isOpen = true;
          if (item?.subCategory?.length > 0) {
            mapRecursiveData(item?.subCategory);
          }
        } else {
          item.isOpen = false;
        }
        return item;
      });
      setIsDataToggle(prev => !prev);
      setFilterdItems(filterData);
    } else {
      if (name) {
        setFilterdItems([]);
      } else {
        setFilterdItems();
      }
    }
  }

  function filterBy (arr, query) {
    return query
      ? arr.reduce((acc, item) => {
        if (item.subCategory?.length) {
          const filtered = filterBy(item.subCategory, query);
          if (filtered.length)
            return [...acc, { ...item, subCategory: filtered }];
        }

        const { ...itemWithoutChildren } = item;
        return item.categoryName?.toLowerCase().includes(query.toLowerCase())
          ? [...acc, itemWithoutChildren]
          : acc;
      }, [])
      : arr;
  }

  function debounce (func, time) {
    if (timerID) {
      clearTimeout(timerID);
    }

    timerID = setTimeout(function () {
      func();
    }, time);
  }

  const formResult = async (form) => {
    let result = {
      Category: showformdata.mainCategory.categoryName
    };

    const manualFormData = new FormData();
    if (showformdata.allData.length > 0) {
      if (showformdata.mainCategory) {
        function mapData (data, childData, index, parentCategory) {
          if (data._id === childData._id) {
            manualFormData.append(`subCategory${index}`, data.categoryName);
            result[`subCategory${index}`] = data.categoryName;
            if (index === 1) {
              return;
            } else {
              if (parentCategory) {
                showformdata.mainCategory.subCategory?.map(d => {
                  mapData(d, parentCategory, 1);
                });
              }
            }
          } else {
            data.subCategory?.map(d => {
              mapData(d, childData, index + 1, data);
            });
          }
        }
        const category = showformdata.selectCategory;
        showformdata.mainCategory.subCategory.map(d => mapData(d, category, 1));
      } else {
        result.Category = showformdata.selectCategory.categoryName;
      }
    }
    manualFormData.append("category", result.Category);
    if (form.comment ||(history && history?.prefilledValue?.dataPoints?.comment)) {
      manualFormData.append(
        "dataPoints",
        JSON.stringify({
          ReadingValue: {
            value: history ? history?.prefilledValue?.dataPoints?.ReadingValue?.value : form.value,
            units: history ? history?.prefilledValue?.dataPoints?.ReadingValue?.units : form.unit
          },
          comment: history ? history?.prefilledValue?.dataPoints?.comment : form.comment
        })
      );
    } else {
      manualFormData.append(
        "dataPoints",
        JSON.stringify({
          ReadingValue: {
            value: history ? history?.prefilledValue?.dataPoints?.ReadingValue?.value : form.value,
            units: history ? history?.prefilledValue?.dataPoints?.ReadingValue?.units : form.unit
          }
        })
      );
    }

    if(data.locationId === "c"){
      manualFormData.append("locationId", "");
      manualFormData.append("isConsolidatedData", true);
    }else{
      manualFormData.append("locationId", data.locationId);
    }
    manualFormData.append("year", data.year);
    manualFormData.append("month", data.month);
    manualFormData.append("categoryId", showformdata.mainCategory._id);
    form.file && manualFormData.append("file", form.file);

    if (showformdata.mainCategory) {
      manualFormData.append("typeOfData", JSON.stringify(showformdata.mainCategory.typeOfData));
    } else {
      if (showformdata.selectCategory) {
        manualFormData.append("typeOfData", JSON.stringify(showformdata.selectCategory.typeOfData));
      }
    }
    try {
      const response = await AxiosPost(`${apipath}/manuall-upload-data`, manualFormData);
      if (response && response.statusCode === 200) {
        toast.success(response.message, { autoClose: 2000 });
        getHistory(resultobject, data);
        getcharts(resultobject, data);
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
    // setData({ locationId: "", year: "", month: "" } );
  };

  const selectDataPoint = async (e) => {
    try {
      const response = await AxiosGet(e.target.value === "All"
        ? `${apipath}/get-all-category`
        : `${apipath}/get-all-category/?typeOfData=${e.target.value}`, {} );

      if (response && response.statusCode === 200) {
        function mapRecursiveData (data) {
          data?.map((subItem, subIndex) => {
            if (subIndex === 0) {
              subItem.isOpen = true;
              if (subItem?.subCategory?.length > 0) {
                mapRecursiveData(subItem?.subCategory);
              }
            }
          });
        }
        const filterData = response.data?.map((item, index) => {
          if (index === 0) {
            item.isOpen = true;
            if (item?.subCategory?.length > 0) {
              mapRecursiveData(item?.subCategory);
            }
          }
          return item;
        });
        let bredcrumList = [];
        let alldataaaaa;
        let selectedCategoryyyyy;
        let mainCategoryyyy;
        const dataItems = filterData;
        dataItems.forEach(item => {
          if (item?.isOpen) {
            alldataaaaa = item?.data;
            selectedCategoryyyyy = item;
            mainCategoryyyy = item;
            bredcrumList = [...bredcrumList, item.categoryName];
            setBredcrumsItems(bredcrumList);
            if (item?.subCategory.length > 0) {
              recursiveFunc(item?.subCategory, bredcrumList, alldataaaaa, selectedCategoryyyyy, mainCategoryyyy);
            }
          }
        });
        if (bredcrumsItems.length === 1) {
          if (mainCategoryyyy?._id === selectedCategoryyyyy?._id) {
            setShowFormData({ ...showformdata, mainCategory: "", selectCategory: selectedCategoryyyyy, allData: alldataaaaa });
          } else {
            setShowFormData({ ...showformdata, mainCategory: mainCategoryyyy, selectCategory: selectedCategoryyyyy, allData: alldataaaaa });
          }
        }
        setFilterdItems(filterData);
        setFilterdAllItems(filterData);
      }
    } catch (error) {
      setShowLoader({ ...showloader, main: true });
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab);
    }
  };

  const series = [ { name: "values", data: trandscharts } ];

  const recursiveFunc = (data, bredcrumList, alldataaaaa, selectedCategoryyyyy, mainCategoryyyy) => {
    data.forEach(item => {
      if (item?.isOpen) {
        alldataaaaa = item?.data;
        selectedCategoryyyyy = item;
        bredcrumList = [...bredcrumList, item.categoryName];
        if (item?.subCategory.length > 0) {
          setBredcrumsItems(bredcrumList);
          setShowFormData({ selectCategory: selectedCategoryyyyy, mainCategory: mainCategoryyyy, allData: alldataaaaa });
          recursiveFunc(item?.subCategory, bredcrumList, alldataaaaa, selectedCategoryyyyy, mainCategoryyyy);
        } else {
          setBredcrumsItems(bredcrumList);
          setShowFormData({ selectCategory: selectedCategoryyyyy, mainCategory: mainCategoryyyy, allData: alldataaaaa });
        }
      }
    });
  };

  const imagename = history?.prefilledValue?.dataPoints?.file;
  let ext = imagename?.slice((imagename?.lastIndexOf(".") - 1 >>> 0) + 2);
  let name = imagename?.split("-")[0];

  return showloader.main
    ? (
      <React.Fragment>
        <div className="page-content sticky-head-section">
          <Container fluid={true}>
            <Row>
              <Col lg={5}>
                <Card id="Border-card">
                  <CardBody>
                    <div className="mb-3">
                      <Label>Select Data Type</Label>
                      <select
                        name="typeOfData"
                        className="form-select"
                        onChange={e => selectDataPoint(e)}
                      >
                        <option value="All">All</option>
                        <option value="Environmental">Environmental</option>
                        <option value="Social">Social</option>
                        <option value="Governance">Governance</option>
                        <option value="Greenhouse Gas">Greenhouse Gas</option>
                      </select>
                    </div>
                    <div className="positionRelative">
                      <Input
                        id="category"
                        type="text"
                        placeholder="Search data points..."
                        className="formControl search-input"
                        onChange={() => debounce(main, 1000)}
                      ></Input>
                    </div>

                    {filterditems
                      ? (
                        filterditems.length > 0 ?
                          <Sidebar
                            onFormDataShow={(e) => {
                              if (!e.mainCategory) {
                                function mapRecursiveData (data) {
                                  data?.map(subItem => {
                                    if (subItem.isOpen) {
                                      subItem.isOpen = false;
                                      if (subItem?.subCategory?.length > 0) {
                                        mapRecursiveData(subItem?.subCategory);
                                      }
                                    }
                                  });
                                }
                                filterditems?.map((item) => {
                                  if (item?._id === e?.selectCategory?._id) {
                                    item.isOpen = !item.isOpen;
                                  } else {
                                    mapRecursiveData(item?.subCategory);
                                    item.isOpen = false;
                                  }
                                  return item;
                                });
                              } else {
                                function mapRecursiveData (data) {
                                  data?.forEach(subItem => {
                                    if (subItem._id === e?.selectCategory?._id) {
                                      data.map(item => {
                                        if (item?._id === subItem?._id) {
                                          item.isOpen = !item.isOpen;
                                        } else {
                                          item.isOpen = false;
                                        }
                                      });
                                    } else {
                                      if (subItem?.subCategory?.length > 0) {
                                        mapRecursiveData(subItem?.subCategory);
                                      }
                                    }
                                  });
                                }
                                filterditems?.map((item) => {
                                  if (item?._id === e?.mainCategory?._id) {
                                    mapRecursiveData(item?.subCategory);
                                  }
                                  return item;
                                });
                              }
                              setShowFormData({ ...showformdata, allData: [] });
                              setTimeout(() => {
                                setShowFormData({ ...showformdata, selectCategory: e.selectCategory, mainCategory: e.mainCategory, allData: e.allData });
                                setIsDataToggle(prev=> !prev);
                              }, 100);
                            }}
                            items={filterditems}
                            setData={setData}
                            setShowLoader={setShowLoader}
                            showloader={showloader}
                            toggleIconCustom={toggleIconCustom}
                          />
                          : <div>No Data</div>
                      )
                      : (
                        <Sidebar
                          onFormDataShow={(e) => {
                            if (!e.mainCategory) {
                              function mapRecursiveData (data) {
                                data?.map(subItem => {
                                  if (subItem.isOpen) {
                                    subItem.isOpen = false;
                                    if (subItem?.subCategory?.length > 0) {
                                      mapRecursiveData(subItem?.subCategory);
                                    }
                                  }
                                });
                              }
                              items?.map((item) => {
                                if (item?._id === e?.selectCategory?._id) {
                                  item.isOpen = !item.isOpen;
                                } else {
                                  mapRecursiveData(item?.subCategory);
                                  item.isOpen = false;
                                }
                                return item;
                              });
                            } else {
                              function mapRecursiveData (data) {
                                data?.forEach(subItem => {
                                  if (subItem._id === e?.selectCategory?._id) {
                                    data.map(item => {
                                      if (item?._id === subItem?._id) {
                                        item.isOpen = !item.isOpen;
                                      } else {
                                        item.isOpen = false;
                                      }
                                    });
                                  } else {
                                    if (subItem?.subCategory?.length > 0) {
                                      mapRecursiveData(subItem?.subCategory);
                                    }
                                  }
                                });
                              }
                              items?.map((item) => {
                                if (item?._id === e?.mainCategory?._id) {
                                  mapRecursiveData(item?.subCategory);
                                }
                                return item;
                              });
                            }
                            setShowFormData({ ...showformdata, allData: [] });
                            setTimeout(() => {
                              setShowFormData({
                                ...showformdata,
                                selectCategory: e.selectCategory, mainCategory: e.mainCategory, allData: e.allData
                              });
                              setIsDataToggle(prev=> !prev);
                            }, 100);
                          }}
                          items={items}
                          setData={setData}
                          setShowLoader={setShowLoader}
                          showloader={showloader}
                          toggleIconCustom={toggleIconCustom}
                        />
                      )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={7} >
                <div className="is-sticky">
                  <Row>
                    <Col xs="12">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <div className="page-title-left">
                          <ol className="breadcrumb m-0">
                            { bredcrumsItems.map(item => <BreadcrumbItem key={item}>
                              <label>{item}</label>
                            </BreadcrumbItem>)}
                          </ol>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {
                      showformdata.selectCategory && showformdata.selectCategory.subCategory.length > 0
                        ? <Card> <CardBody><div>Please Go till the last Drilldown</div></CardBody></Card> :
                        <Card>
                          <CardBody>
                            <Row className="border-bottom">
                              <Col lg={6}>
                                <div>
                                  <Label>Select Location</Label>
                                  <select
                                    name="locationId"
                                    className="form-select"
                                    value={data.locationId}
                                    onChange={e => handleChange(e)}
                                  >
                                    <option selected value="">Select</option>
                                    <option value="c">Consolidate</option>
                                    {locationList.length === 0
                                      ? <option disabled> <Spinner /> </option>
                                      : locationList.filter(opt => opt.isActive).map(item => (
                                        <option
                                          style={{ textTransForm: "uppercase" }}
                                          key={item._id}
                                          value={item._id}
                                        >
                                          {item.city} - {item.addressType}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </Col>

                              <Col lg={3}>
                                <div className="form-group">
                                  <Label>Select Year</Label>
                                  <select
                                    name="year"
                                    disabled={data.locationId.length > 0 ? false : true }
                                    className="form-select"
                                    value={data.year}
                                    onChange={e => handleChange(e)}
                                  >
                                    <option value="">Select</option>
                                    {years.map(option => (
                                      <option key={option} value={option}>{option}</option>
                                    ))}
                                  </select>
                                </div>
                              </Col>

                              <Col lg={3}>
                                <div className="form-group">
                                  <Label>Select Month</Label>
                                  <select
                                    name="month"
                                    className="form-select"
                                    disabled={(data.year.length > 0 ? false : true) || monthbtn}
                                    value={data.month}
                                    onChange={e => handleChange(e)}
                                  >
                                    <option value="">Select</option>
                                    <option value="Jan">January </option>
                                    <option value="Feb">February </option>
                                    <option value="Mar">March </option>
                                    <option value="Apr">April </option>
                                    <option value="May">May </option>
                                    <option value="Jun">June </option>
                                    <option value="Jul">July </option>
                                    <option value="Aug">August </option>
                                    <option value="Sep">September </option>
                                    <option value="Oct">October </option>
                                    <option value="Nov">November </option>
                                    <option value="Dec">December </option>
                                  </select>
                                </div>
                              </Col>
                            </Row>

                          </CardBody>
                        </Card>
                    }
                  </Row>
                  <Row>
                    {data.month.length > 0 && (
                      <>
                        {
                          showformdata?.allData?.length > 0 ?
                            <Card>
                              <CardBody>
                                <Nav className="nav-tabs-custom nav-justified">
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        active: customIconActiveTab === "1"
                                      })}
                                      onClick={() => {
                                        toggleIconCustom("1");
                                        setMonthbtn(false);
                                      }}>
                                      <h6 className="d-none d-sm-block"><b>Data Entry</b></h6>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        active: customIconActiveTab === "2"
                                      })}
                                      onClick={() => {
                                        toggleIconCustom("2");
                                        setMonthbtn(false);
                                      }}>
                                      <h6 className="d-none d-sm-block"><b>History</b></h6>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        active: customIconActiveTab === "3"
                                      })}
                                      onClick={() => {
                                        toggleIconCustom("3");
                                        setMonthbtn(prev => !prev);
                                      }}>
                                      <h6 className="d-none d-sm-block"><b>Trends</b></h6>
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                                <TabContent
                                  activeTab={customIconActiveTab}
                                  className="text-muted mt-3"
                                >
                                  <TabPane tabId="1">
                                    {showformdata.allData.map((dataPoint, index) => (
                                      <div key={index} className="mb-4" >
                                        <DynamicFormGenerator
                                          buttonDisable={ data.locationId !== "" && data.month !== "" && data.year !== "" ? false : true }
                                          formFields={dataPoint.formFields}
                                          onFormSubmit={form => formResult(form)}
                                          prefillData={history?.prefilledValue}
                                          onDataChange={(id, value) => {
                                            if (id === "comment") {
                                              _.set(history, `prefilledValue.dataPoints.${id}`, value);
                                            } else {
                                              _.set(history, `prefilledValue.dataPoints.ReadingValue.${id}`, value);
                                            }
                                          }}
                                        />
                                      </div>
                                    ))}
                                    {imagename && <Col xl={3} sm={4} className="mt-2">
                                      <Card className="shadow-none border">
                                        <div className="p-2 d-flex justify-content-between">
                                          <div className="bg-transparent rounded">
                                            {ext === "csv"
                                              ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                              : ext === "png"
                                                ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                                : ext === "pdf"
                                                  ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                                  <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                            }
                                            <span className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</span>
                                          </div>
                                          <UncontrolledDropdown direction="right">
                                            <DropdownToggle
                                              href="#"
                                              className="btn nav-btn"
                                              tag="i"
                                            >
                                              <i className="bx bx-dots-vertical-rounded" />
                                            </DropdownToggle>

                                            <DropdownMenu>
                                              <button type="button" className="dropdown-item"
                                                onClick={ async () => {
                                                  try {
                                                    const res = await AxiosGet(`${apipath}/download-stored-manual-upload-file/${imagename}`);
                                                    if (res && (res.statusCode === 200 && res.data[0])) {
                                                      let a = document.createElement("a");
                                                      a.download = `${name}.${ext}`;
                                                      a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                      a.click();
                                                      a.remove();
                                                      toast.success("Download Succesfully");
                                                    }
                                                  } catch (error) {
                                                    toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                                  }
                                                }}
                                              >Download</button>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        </div>
                                      </Card>
                                    </Col>}
                                  </TabPane>

                                  <TabPane tabId="2">
                                    {showloader.activity ?
                                      <div className="table-responsive h-315 mt-3">
                                        {history?.logs?.length > 0 ?
                                          <table className="table mb-0">
                                            <thead className="table-light">
                                              <tr>
                                                <th width="10%">Sr. No.</th>
                                                <th width="20%">Date Of Modification</th>
                                                <th width="20%">Time</th>
                                                <th>Activity</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {history?.logs?.map((item, i) => ( <React.Fragment key={`itemlist${i}`}>
                                                <tr>
                                                  <td>{i+1}.</td>
                                                  <td>{dateFormat(`${item.createdAt}`, "d mmm, yyyy")}</td>
                                                  <td>{dateFormat(`${item.createdAt}`, "hh:MM TT")}</td>
                                                  <td>{item.actionDone}</td>
                                                </tr>
                                              </React.Fragment>))}

                                            </tbody>
                                          </table>
                                          :
                                          <div>No Activity Found</div>
                                        }
                                      </div> :
                                      <div className="react-activity">
                                        <Dots color="#3C7C44" size={20} speed={0.6} />
                                      </div>
                                    }
                                  </TabPane>

                                  <TabPane tabId="3">
                                    {trandscharts?.length > 0 ?
                                      <ReactApexChart
                                        options={options}
                                        series={series}
                                        type="line"
                                        height="380"
                                      />
                                      :
                                      <div className="table-responsive h-315 mt-3">
                                        <div>No Data Found</div>
                                      </div>
                                    }

                                  </TabPane>
                                </TabContent>
                              </CardBody>
                            </Card>
                            :
                            <Card>
                              <CardBody>
                                <div>
                                No Data Found
                                </div>
                              </CardBody>
                            </Card>
                        }
                      </>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
    : (
      <Loading />
    );
}

export default UiTabsAccordions;
