import React from "react";
import { Card, CardBody } from "reactstrap";

function TapVisitors () {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="d-flex flex-wrap">
            <div className="ms-2">
              <h5 className="card-title mb-3">Top Users</h5>
            </div>
          </div>
          <div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <div className="py-2">
                  <h5 className="font-size-14">
                    California <span className="float-end">78%</span>
                  </h5>
                  <div className="progress animated-progess progress-sm">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "78%" }}
                      aria-valuenow="78"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="py-2">
                  <h5 className="font-size-14">
                    Nevada <span className="float-end">69%</span>
                  </h5>
                  <div className="progress animated-progess progress-sm">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "69%" }}
                      aria-valuenow="69"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="py-2">
                  <h5 className="font-size-14">
                    Texas <span className="float-end">61%</span>
                  </h5>
                  <div className="progress animated-progess progress-sm">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "61%" }}
                      aria-valuenow="61"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default TapVisitors;
