import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import SidebarMainItem from "./MainCategory";

function Sidebar (props) {
  const [categoryList, setCategoryList] = useState([]);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [currentlySelectedStage, setCurrentlySelectedStage] = useState(0);
  const [stageChange, setStageChange] = useState({
    0: false, 1: false, 2: false, 3: false, 4: false,
    5: false, 6: false, 7: false, 8: false, 9: false
  });
  /* ==============>>>>>>>>  Toggle on  Main Category Open and Close  <<<<<<<<============== */
  useEffect(() => {
    setCategoryList(props?.items?.map(item => { item.isToggle = false; return item; }));
  }, [props.items]);

  useEffect(() => {
    if (!categoryList?.some(item => item.isToggle) && Object.values(stageChange).some(item => item)) {
      props.onCategorySubmit();
    }
  }, [categoryList?.some(item => item.isToggle)]);

  useEffect(() => {
    isCategoryOpen && onStatusChange();
  }, [isCategoryOpen]);

  /* ==============>>>>>>>>  On Category Status Update  <<<<<<<<============== */
  const onStatusChange = data => {
    setCategoryList(
      categoryList.map(item => {
        if (data && item._id === data._id) {
          item.isOpen = !item.isOpen;
        } else {
          item.isOpen = false;
        }
        return item;
      })
    );
    if (!data) {
      setIsCategoryOpen(false);
    }
  };

  /* ==============>>>>>>>>  Toggle on Category Open and Close  <<<<<<<<============== */
  const onToggleChange = (data) => {
    setCategoryList(
      categoryList.map(item => {
        if (data && item._id === data._id) {
          item.isToggle = !item.isToggle;
        } else {
          item.isToggle = false;
        }
        return item;
      })
    );
  };
  return (
    <div className="accordion-item">
      <List disablePadding dense>
        {categoryList?.map((sidebarItem, index) => (
          <React.Fragment key={`${sidebarItem.categoryName}${index}`}>
            <SidebarMainItem
              onUpdateCategory={props.onUpdateCategory}
              selectedStage={currentlySelectedStage}
              onStageSelect={setCurrentlySelectedStage}
              currentStage={1}
              stage={stageChange}
              onStageChange={setStageChange}
              onCategoryToggle={onToggleChange}
              onCategoryOpen={setIsCategoryOpen}
              isMainCategoryOpen={isCategoryOpen}
              onNewCategoryAdd={props.onCategorySubmit}
              onChangeCreateForm={props.toggleCreateForm}
              statusChange={onStatusChange}
              item={sidebarItem}
              changetext={props.changetext}
              setChangeText={props.setChangeText}
            />
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

export default Sidebar;
