import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Formik, Field, FieldArray } from "formik";
import { Card, Col, DropdownToggle, DropdownMenu, Modal, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import ModalSection from "../ModalSection";
import FormUpload from "../Modalfileupload";
import { AxiosPost, AxiosGet } from "common/axiosService";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const ProductServices = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionA.products_services;
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, onSectionChange, oldData, getFile, filesData } = props;
  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);

  const [filedatachange, setFileDataChange] = useState(true);

  const [modal_center, setmodal_center] = useState(false);
  const [modalData, setModalData] = useState({ name: "", value: "", type: "", title: "" });
  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionA", subSection: "products_services", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect (() => {
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }

    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionA",
        "subSection": "products_services"
      });
    }
  }, [newReport, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionA",
      "subSection": "products_services"
    });
  }, [filedatachange]);

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, products_services: data });
    setData({ ...data });
  };

  function tog_center () {
    setmodal_center(!modal_center);
  }
  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionA",
        "subSection": "products_services",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionA",
          "subSection": "products_services"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  return (
    <React.Fragment>
      <Modal
        className="center-modal"
        size="lg" isOpen={modal_center}
        toggle={() => { tog_center(); }}
        centered
        autoFocus={false}
        backdrop={"static"}
      >
        <ModalSection modalData={modalData} setModalData={setModalData} state={data} setmodal_center={setmodal_center}/>
        <div className="modal-footer">
          <button
            onClick={(e) => {
              setmodal_center(false);
              e.target.blur();
              setModalData({ name: "", value: "", type: "", title: "" });
            }}
            className="btn btn-primary"
          >Update</button>
        </div>
      </Modal>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <Row>
        <div className="col-xl-12">
          <div className="card posifix">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className=" d-sm-flex align-items-center justify-content-between"
                    style={{ borderBottom: "1px solid #ccc", paddingBottom: "15px", marginBottom: "30px" }}>
                    <h4 className="mb-sm-0 font-size-18">Products/ Services</h4> </div>
                </div>
              </div>
              <Formik
                initialValues={data}
              >
                {({ values }) => (
                  <form
                    onChange={onHandleChange}
                    onSubmit={(e) => {
                      e.preventDefault();
                      _set(sebiCurrentData, "sectionA.products_services", data);
                      updateSebiData(sebiCurrentData);
                      draftSebiData({ ...draftReport, products_services: false });
                    }}>
                    <div className="row">
                      <div className="col-lg-12">
                        <p><b>14. Details of business activities (accounting for 90% of the turnover):</b> </p>
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0">
                            <FieldArray name="details_of_business_activities">
                              {({ remove, push }) => (
                                <>
                                  <thead className="table-light">
                                    <tr>
                                      <th width="4%">Sr. No.</th>
                                      <th className="text-align" width="37%">Description of Main Activity</th>
                                      <th className="text-align">Description of Business Activity</th>
                                      <th className="text-align" width="20%">% of Turnover of the entity</th>
                                      <th width="3%" onClick={() => {
                                        push({ main_activity: "", business_activity: "", turnover: "" });
                                        setData({
                                          ...data, details_of_business_activities: [
                                            ...data.details_of_business_activities,
                                            { main_activity: "", business_activity: "", turnover: "" }
                                          ]
                                        });
                                        draftSebiData({
                                          ...draftReport, products_services: {
                                            ...data, details_of_business_activities: [
                                              ...data.details_of_business_activities,
                                              { main_activity: "", business_activity: "", turnover: "" }
                                            ]
                                          }
                                        });
                                      }}>
                                        <i className="bx bxs-plus-circle mdi-add-btn"/></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {values.details_of_business_activities?.length > 0 &&
                                   values.details_of_business_activities?.map((friend, index) => (
                                     <tr key={index}>
                                       <td className="text-align">{index+1}.</td>
                                       <td>
                                         <Field
                                           as="textarea"
                                           className="form-control"
                                           placeholder="Description of Main Activity"
                                           name={`details_of_business_activities[${index}].main_activity`}
                                           value={data.details_of_business_activities[index].main_activity}
                                           onFocus={(e) => {
                                             if(!modal_center){ e.target.blur(); }
                                             setModalData({
                                               title: "Description of Main Activity",
                                               name: `details_of_business_activities[${index}].main_activity`,
                                               value: _get(data, `details_of_business_activities[${index}].main_activity`, "")
                                             });
                                             tog_center();
                                           }}
                                         />
                                       </td>
                                       <td>
                                         <Field
                                           as="textarea"
                                           className="form-control"
                                           placeholder="Description of Business Activity"
                                           name={`details_of_business_activities[${index}].business_activity`}
                                           value={data.details_of_business_activities[index].business_activity}
                                           onFocus={(e) => {
                                             if(!modal_center){ e.target.blur(); }
                                             setModalData({
                                               title: "Description of Business Activity",
                                               name: `details_of_business_activities[${index}].business_activity`,
                                               value: _get(data, `details_of_business_activities[${index}].business_activity`, "")
                                             });
                                             tog_center();
                                           }}
                                         />
                                       </td>
                                       <td className="text-align">
                                         <Field
                                           type="number"
                                           onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                           placeholder="% of Turnover of the entity"
                                           className="form-control"
                                           onWheel={(e) => e.target.blur()}
                                           name={`details_of_business_activities[${index}].turnover`}
                                         />
                                       </td>
                                       {values.details_of_business_activities?.length > 1 &&
                                        <td
                                          className="text-align"
                                          onClick={() => {
                                            remove(index);
                                            const newr = data.details_of_business_activities.filter((item, i) => index !==i);
                                            draftSebiData({ ...draftReport, products_services: { ...data, details_of_business_activities: newr } });
                                            setData({ ...data, details_of_business_activities: newr });
                                          }
                                          }><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                     </tr>
                                   ))}
                                  </tbody>
                                </>
                              )}
                            </FieldArray>
                          </table>
                        </div>
                      </div>
                    </div>

                    <Row className="mt-2 align-items-center">
                      {filesData?.details_of_business_activities?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left" className="mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item" onClick={() => handleDeleteFile(item, "details_of_business_activities" )}>Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "csv"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);
                      })}
                      {filesData?.details_of_business_activities?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "details_of_business_activities" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "details_of_business_activities" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-12">
                        <p><b>15. Products/Services sold by the entity (accounting for 90% of the entity’s Turnover):</b> </p>
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0">
                            <FieldArray name="products_sold">
                              {({ remove, push }) => (
                                <>
                                  <thead className="table-light">
                                    <tr>
                                      <th width="4%">Sr. No.</th>
                                      <th className="text-align" width="37%">Product/Service</th>
                                      <th className="text-align">NIC Code</th>
                                      <th className="text-align" width="20%">% of total Turnover contributed</th>
                                      <th width="3%" onClick={() => {
                                        push({ product: "", nic_code: "", turnover: "" });
                                        setData({
                                          ...data, products_sold: [
                                            ...data.products_sold,
                                            { product: "", nic_code: "", turnover: "" }
                                          ]
                                        });
                                        draftSebiData({
                                          ...draftReport, products_services: {
                                            ...data, products_sold: [
                                              ...data.products_sold,
                                              { product: "", nic_code: "", turnover: "" }
                                            ]
                                          }
                                        });
                                      }}>
                                        <i className="bx bxs-plus-circle mdi-add-btn"/></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {values.products_sold?.length > 0 &&
                                   values.products_sold.map((friend, index) => (
                                     <tr key={index}>
                                       <td className="text-align">{index+1}.</td>
                                       <td>
                                         <Field
                                           type="text"
                                           name={`products_sold[${index}].product`}
                                           placeholder="Product/Service"
                                           className="form-control"
                                         />
                                       </td>
                                       <td>
                                         <Field
                                           name={`products_sold[${index}].nic_code`}
                                           placeholder="NIC Code"
                                           type="text"
                                           className="form-control"
                                         />
                                       </td>
                                       <td>
                                         <Field
                                           name={`products_sold[${index}].turnover`}
                                           placeholder="% of total Turnover contributed"
                                           type="number"
                                           onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                           className="form-control"
                                           onWheel={(e) => e.target.blur()}
                                         />
                                       </td>
                                       {values.products_sold?.length > 1 && <td
                                         className="text-align" onClick={() => {
                                           remove(index);
                                           const newr = data.products_sold.filter((item, i) => index !==i);
                                           draftSebiData({ ...draftReport, products_services: { ...data, products_sold: newr } });
                                           setData({ ...data, products_sold: newr });
                                         }}>
                                         <i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                     </tr>
                                   ))}
                                  </tbody>
                                </>
                              )}
                            </FieldArray>
                          </table>
                        </div>
                      </div>
                    </div>

                    <Row className="mt-2 align-items-center">
                      {filesData?.products_sold?.map((item, index) => {
                        let ext = item?.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item?.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left" className="mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item" onClick={() => handleDeleteFile(item, "products_sold" )}>Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                {/* <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext && ext === "csv"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }

                                  </div>
                                </div> */}
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);
                      })}
                      {filesData?.products_sold?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "products_sold" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "products_sold" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => onSectionChange(1)}>Previous</button>{" "}
                      </div>
                      <div>
                        <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => onSectionChange(3)}>Next</button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionA?.products_services,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.products_services,
    filesData: state.NewReportReducer?.draftFiles?.products_services
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(ProductServices);