import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Formik, FieldArray, Field } from "formik";
import { Modal, Card, Col, DropdownToggle, DropdownMenu, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import ModalSection from "../ModalSection";
import FormUpload from "../Modalfileupload";
import Input from "components/Inputs/Input";
import { AxiosGet, AxiosPost } from "common/axiosService";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const Principle7 = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionC.principle_7;
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, onSectionChange, oldData, getFile, filesData } = props;

  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);

  const [filedatachange, setFileDataChange] = useState(true);

  const [modal_center, setmodal_center] = useState(false);
  const [modalData, setModalData] = useState({ name: "", value: "", type: "", title: "" });

  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionC", subSection: "principle_7", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect(() =>{
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_7"
      });
    }
  }, [newReport, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionC",
      "subSection": "principle_7"
    });
  }, [filedatachange]);

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, principle_7: data });
    setData({ ...data });
  };

  function tog_center () {
    setmodal_center(!modal_center);
  }

  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_7",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionC",
          "subSection": "principle_7"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  return (
    <React.Fragment>
      <Modal className="center-modal"
        size="lg" isOpen={modal_center}
        toggle={() => { tog_center(); }}
        centered
        autoFocus={false}
        backdrop={"static"}
      >
        <ModalSection modalData={modalData} setModalData={setModalData} state={data} setmodal_center={setmodal_center}/>
        <div className="modal-footer">
          <button
            onClick={(e) => {
              setmodal_center(false);
              e.target.blur();
              setModalData({ name: "", value: "", type: "", title: "" });
            }}
            className="btn btn-primary"
          >Update</button>
        </div>
      </Modal>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <Row>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h5 className="mt-2"><b>PRINCIPLE 7 Businesses, when engaging in influencing public and regulatory policy, should do so in a manner that is responsible and transparent</b> </h5>
              <p className="txt-decoration mt-2"><b>Essential Indicators</b></p>
              <Formik
                initialValues={data}
              >
                {({ values, setFieldValue }) => (
                  <form
                    onChange={onHandleChange}
                    onSubmit={(e) => {e.preventDefault();
                      _set(sebiCurrentData, "sectionC.principle_7", data);
                      updateSebiData(sebiCurrentData);
                      draftSebiData({ ...draftReport, principle_7: false });
                    }}>
                    <p>1. A. Number of affiliations with trade and industry chambers/ associations.</p>
                    <div style={{ maxWidth: "300px" }}>
                      <Input
                        name="number_of_affiliations_with_trade"
                        value={_get(data, "number_of_affiliations_with_trade", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                    </div>

                    <p className="mt-2">B. List the top 10 trade and industry chambers/ associations (determined based on the total members of such body) the entity is a member of/ affiliated to.</p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="top_10_trade_and_industry">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th width="4%" className="text-align">Sr. No.</th>
                                <th className="text-align">Name of the trade and industry chambers/ associations</th>
                                <th className="text-align" width="25%">Reach of trade and industry chambers/ associations (State/National) </th>
                                <th width="3%" onClick={() => {
                                  push({ name: "", trade_and_industry_chambers: "" });
                                  setData({
                                    ...data, top_10_trade_and_industry: [
                                      ...data.top_10_trade_and_industry,
                                      { name: "", trade_and_industry_chambers: "" }
                                    ]
                                  });
                                  draftSebiData({
                                    ...draftReport, principle_7: {
                                      ...data, top_10_trade_and_industry: [
                                        ...data.top_10_trade_and_industry,
                                        { name: "", trade_and_industry_chambers: "" }
                                      ]
                                    }
                                  });
                                }}><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.top_10_trade_and_industry?.length > 0 && values?.top_10_trade_and_industry?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">{index+1}.</td>
                                  <td className="text-align">
                                    <Field
                                      name={`top_10_trade_and_industry[${index}].name`}
                                      placeholder="Name"
                                      type="text"
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      as="select" className="form-select"
                                      name={`top_10_trade_and_industry[${index}].trade_and_industry_chambers`}
                                      value={values?.top_10_trade_and_industry[index]?.trade_and_industry_chambers}
                                    >
                                      <option value="">Select Option</option>
                                      <option value="State">State</option>
                                      <option value="National">National</option>
                                    </Field>
                                  </td>
                                  {values?.top_10_trade_and_industry?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.top_10_trade_and_industry.filter((item, i) => index !==i);
                                    draftSebiData({ ...draftReport, principle_7: { ...data, top_10_trade_and_industry: newr } });
                                    setData({ ...data, top_10_trade_and_industry: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.top_10_trade?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "top_10_trade" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.top_10_trade?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "top_10_trade" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "top_10_trade" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">2. Provide details of corrective action taken or underway on any issues related to anti- competitive conduct by
                    the entity, based on adverse orders from regulatory authorities.</p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="anti_competitive_conduct">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th className="text-align">Name of authority</th>
                                <th className="text-align">Brief the case</th>
                                <th className="text-align">Corrective action taken</th>
                                <th width="3%" onClick={() => {
                                  push({ name_of_authority: "", brife: "", action_taken: "" });
                                  setData({
                                    ...data, anti_competitive_conduct: [
                                      ...data.anti_competitive_conduct,
                                      { name_of_authority: "", brife: "", action_taken: "" }
                                    ]
                                  });
                                  draftSebiData({
                                    ...draftReport, principle_7: {
                                      ...data, anti_competitive_conduct: [
                                        ...data.anti_competitive_conduct,
                                        { name_of_authority: "", brife: "", action_taken: "" }
                                      ]
                                    }
                                  });
                                }}><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.anti_competitive_conduct?.length > 0 && values?.anti_competitive_conduct?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">
                                    <Field
                                      name={`anti_competitive_conduct[${index}].name_of_authority`}
                                      placeholder="Name of authority"
                                      as="textarea"
                                      className="form-control"
                                      value={data.anti_competitive_conduct[index]?.name_of_authority}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Name of authority",
                                          name: `anti_competitive_conduct[${index}].name_of_authority`,
                                          value: _get(data, `anti_competitive_conduct[${index}].name_of_authority`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`anti_competitive_conduct[${index}].brife`}
                                      placeholder="Brief the case"
                                      as="textarea"
                                      className="form-control"
                                      value={data.anti_competitive_conduct[index]?.brife}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Brief the case",
                                          name: `anti_competitive_conduct[${index}].brife`,
                                          value: _get(data, `anti_competitive_conduct[${index}].brife`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`anti_competitive_conduct[${index}].action_taken`}
                                      placeholder="Action taken"
                                      as="textarea"
                                      className="form-control"
                                      value={data.anti_competitive_conduct[index]?.action_taken}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Corrective action taken",
                                          name: `anti_competitive_conduct[${index}].action_taken`,
                                          value: _get(data, `anti_competitive_conduct[${index}].action_taken`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  {values?.anti_competitive_conduct?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.anti_competitive_conduct.filter((item, i) => index !==i);
                                    draftSebiData({ ...draftReport, principle_7: { ...data, anti_competitive_conduct: newr } });
                                    setData({ ...data, anti_competitive_conduct: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.anti_competitive?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "anti_competitive" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.anti_competitive?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "anti_competitive" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "anti_competitive" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="txt-decoration mt-2"><b>Leadership Indicators</b></p>

                    <p>1. Details of public policy positions advocated by the entity:</p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="public_policy_positions">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th width="4%" className="text-align">Sr. No.</th>
                                <th className="text-align">Public policy advocated </th>
                                <th className="text-align">Method resorted for such advocacy </th>
                                <th width="15%" className="text-align">Whether information available in public domain ?</th>
                                <th width="15%" className="text-align">Frequency of review by Board</th>
                                <th width="15%" className="text-align">Web link, if available</th>
                                <th width="3%" onClick={() => {
                                  push({ advocated: "", method_resorted: "", public_domain: "", frequency: "", weblink: "" });
                                  setData({
                                    ...data, public_policy_positions: [
                                      ...data.public_policy_positions,
                                      { advocated: "", method_resorted: "", public_domain: "", frequency: "", weblink: "" }
                                    ]
                                  });
                                  draftSebiData({
                                    ...draftReport, principle_7: {
                                      ...data, public_policy_positions: [
                                        ...data.public_policy_positions,
                                        { advocated: "", method_resorted: "", public_domain: "", frequency: "", weblink: "" }
                                      ]
                                    }
                                  });
                                }}><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.public_policy_positions?.length > 0 && values?.public_policy_positions?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">{index+1}.</td>
                                  <td className="text-align">
                                    <Field
                                      name={`public_policy_positions[${index}].advocated`}
                                      placeholder="Public policy advocated"
                                      type="text"
                                      className="form-control"
                                      value={data.public_policy_positions[index]?.advocated}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Public policy advocated",
                                          name: `public_policy_positions[${index}].advocated`,
                                          value: _get(data, `public_policy_positions[${index}].advocated`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`public_policy_positions[${index}].method_resorted`}
                                      placeholder="Method resorted for such advocacy"
                                      type="text"
                                      className="form-control"
                                      value={data.public_policy_positions[index]?.method_resorted}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Method resorted for such advocacy",
                                          name: `public_policy_positions[${index}].method_resorted`,
                                          value: _get(data, `public_policy_positions[${index}].method_resorted`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td className="text-align" role="group" aria-labelledby="my-radio-group">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="Yes"
                                        name={`public_policy_positions[${index}].public_domain`}
                                        id={`radioBtn377${index}`}
                                        onClick={(e) => {
                                          setFieldValue(`public_policy_positions[${index}].public_domain`, e.target.value );
                                          onHandleChange(e);
                                        }}
                                        onChange={() => {}}
                                        checked={values?.public_policy_positions[index]?.public_domain === "Yes"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn377${index}`}>Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="No"
                                        name={`public_policy_positions[${index}].public_domain`}
                                        id={`radioBtn378${index}`}
                                        onClick={(e) => {setFieldValue(`public_policy_positions[${index}].public_domain`, e.target.value );
                                          onHandleChange(e);
                                        }}
                                        onChange={() => {}}
                                        checked={values?.public_policy_positions[index]?.public_domain === "No"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn378${index}`}>No</label>
                                    </div>
                                  </td>
                                  <td>
                                    <Field
                                      as="select" className="form-select"
                                      name={`public_policy_positions[${index}].frequency`}
                                      value={values?.public_policy_positions[index]?.frequency}
                                    >
                                      <option value="">Select Option</option>
                                      <option value="Annually">Annually</option>
                                      <option value="Half yearly">Half yearly</option>
                                      <option value="Quarterly">Quarterly</option>
                                      <option value="Others">Others</option>
                                    </Field>
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`public_policy_positions[${index}].weblink`}
                                      placeholder="Weblink"
                                      type="text"
                                      className="form-control"
                                      value={data.public_policy_positions[index]?.weblink}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Web link, if available",
                                          name: `public_policy_positions[${index}].weblink`,
                                          value: _get(data, `public_policy_positions[${index}].weblink`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  {values?.public_policy_positions?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.public_policy_positions.filter((item, i) => index !==i);
                                    draftSebiData({ ...draftReport, principle_7: { ...data, public_policy_positions: newr } });
                                    setData({ ...data, public_policy_positions: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.public_policy_positions?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "public_policy_positions" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.public_policy_positions?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "public_policy_positions" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "public_policy_positions" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => onSectionChange(14)}>Previous</button>{" "}
                      </div>
                      <div>
                        <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => onSectionChange(16)}>Next</button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionC?.principle_7,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.principle_7,
    filesData: state.NewReportReducer?.draftFiles?.principle_7
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(Principle7);
