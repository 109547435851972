import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AxiosGet } from "common/axiosService";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
const CardUser = () => {
  const [companies, setCompanies] = useState({});

  const apipath = process.env.REACT_APP_SUPERADMIN_API_PATH;

  const mainData = companies.company?.Level1.datapoints.map((item) => ({
    "label": `${item.year}`,
    "value": item.count,
    "link": `newchart-xml-${item.year}`
  }));

  const level2Data = companies.company?.Level2.map((item) => ({
    "id": `${Object.keys(item)[0]}`,
    "linkedchart": {
      "chart": {
        "caption": `${Object.keys(item)} Added Users`,
        // "subcaption": "Last year",
        // "numberprefix": "$",
        "theme": "fusion",
        "rotateValues": "0",
        "palettecolors": "#3f7e44, #d2df64, #4c9f38, #99b951, #4d9953, #6bc355, #2d5e21, #51aa3c, #86a442, #aebe27, #88941f",
        "showValues": "1",
        "placeValuesInside": "1"
      },
      "data": Object.values(item)[0].map((item2) => ({
        "label": `${item2.month}`,
        "value": item2.count
      }))
    }
  }));

  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/total-company`);
        if (response && response.statusCode === 200) {
          setCompanies(response.data[0]);
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, []);

  const chartConfigs = {
    type: "column2d",
    width: "100%",
    height: "100%",
    dataFormat: "json",
    dataSource: {
      "chart": {
        "caption": "All Users",
        "subcaption": "Click on a column to see details",
        "xaxisname": "Years",
        "yaxisname": "Number",
        // "numberprefix": "$",
        "theme": "fusion",
        "color": "black",
        "rotateValues": "0",
        "palettecolors": "#3f7e44",
        "showPrintMenuItem": true,
        "showValues": "1",
        "placeValuesInside": "1"
      },
      "data": mainData,
      "linkeddata": level2Data
    }
  };

  // Trigerred when chart is rendered.
  // Configures the linked charts.
  const alterChart = (chart) => {
    chart.configureLink(
      {
        type: "column2d",
        width: "100%",
        height: "100%",
        class: "col-lg-8",
        overlayButton: {
          message: "Back",
          fontColor: "white",
          bgColor: "#3f7e44",
          borderColor: "#3f7e44"
        }
      },
      0
    );
  };
  return (
    <React.Fragment>
      <Col xl={8}>
        <Row>
          <Col lg={4}>
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Total Company</p>
                    <h5 className="mb-0">120</h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bxs-book-bookmark"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card className="blog-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Users</p>
                    <h5 className="mb-0">423</h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bxs-note"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="blog-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Report Genrated</p>
                    <h5 className="mb-0">170</h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bxs-message-square-dots"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              <h5 className="card-title me-2">Users</h5>
            </div>

            <Row style={{ height: "450px" }}>
              <Col lg={12}>
                <ReactFC {...chartConfigs} onRender={alterChart} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default CardUser;
