import { AxiosPost } from "common/axiosService";
import { API_URLS } from "common/constants";
import React from "react";
import { toast } from "react-toastify";

const UserName = cell => cell.cell ? cell.cell.row.original.name : "";

const PhoneEmail = cell => cell.cell
  ? cell.cell.row.original.email.replace(
    /(\w{2})[\w.-]+@([\w.]+\w)/,
    "$1*****@$2"
  )
  : "";
const Role = cell => cell.cell ? cell.cell.row.original.role : "";
const resendVerificationLink = async (id) => {
  document.getElementById(`resendLink${id}`).disabled = true;
  try {
    const res = await AxiosPost(API_URLS.RESEND_USER_VERIFY_LINK, { id });
    toast.success(res.message);
  } catch (error) {
    toast.error(error, { autoClose: 2000 });
  }
  setTimeout(() => {
    document.getElementById(`resendLink${id}`).disabled = false;
  }, 1000);
};
const Status = cell => cell.cell
  ? (
    cell.cell.row.original.status === "Invited"
      ? (
        <h5>
          <span className="badge  bg-warning text-dark">Not Verified</span>
          <button type="button" id={`resendLink${cell.cell.row.original._id}`} className="btn btn-link" onClick={async () => resendVerificationLink(cell.cell.row.original._id) }> Resend Link </button>
        </h5>
      )
      : (
        <h5>
          <span className="badge bg-success">Verified</span>
        </h5>
      )
  )
  : (
    ""
  );

export { UserName, PhoneEmail, Role, Status };
