import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { State } from "country-state-city";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Formik, FieldArray, Field } from "formik";
import { Modal, Card, Col, DropdownToggle, DropdownMenu, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import ModalSection from "../ModalSection";
import Input from "components/Inputs/Input";
import FormUpload from "../Modalfileupload";
import TextArea from "components/Inputs/TextArea";
import TableInput from "components/Inputs/TableInput";
import RadioButton from "components/Inputs/RadioButton";
import { AxiosGet, AxiosPost } from "common/axiosService";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const Principle8 = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionC.principle_8;
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, onSectionChange, previousData, oldData, getFile, filesData } = props;

  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);

  const [filedatachange, setFileDataChange] = useState(true);

  const [modal_center, setmodal_center] = useState(false);
  const [modalData, setModalData] = useState({ name: "", value: "", type: "", title: "" });

  const [details, setDetails] = useState([{ state: "", city: "" }]);

  const [fileData, setFileData] = useState({ year: newReport.currentYear.year, section: "sectionC", subSection: "principle_8", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  const keyify = (obj, data, prefix = "") =>
    Object.keys(obj).reduce((res, el) => {
      if( Array.isArray(obj[el]) ) {
        const h = obj[el].map((element, index) => {
          if (Object.keys(element).length > 0) {
            return Object.keys(element).map((name) => _set(data, prefix + el + "[" + index + "]" + name, ""));
          }
        }).flat(1);
        return [...res, ...h];
      } else if( typeof obj[el] === "object" && obj[el] !== null ) {
        return [...res, ...keyify(obj[el], data, prefix + el + ".")];
      }
      return [...res, _set(data, prefix + el, "")];
    }, []);
  useEffect(() =>{
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (previousData) {
      _set(data, "sourced_from_suppliers.sourced_from_msmes.previous", _get(previousData, "sourced_from_suppliers.sourced_from_msmes.current", "" ));
      _set(data, "sourced_from_suppliers.neighbouring_districts.previous", _get(previousData, "sourced_from_suppliers.neighbouring_districts.current", "" ));
    }
    if (oldData === undefined && draftData === false && previousData === undefined) {
      keyify(initialState, initialState);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_8"
      });
    }
  }, [newReport, previousData, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionC",
      "subSection": "principle_8"
    });
  }, [filedatachange]);

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, principle_8: data });
    setData({ ...data });
  };

  function tog_center () {
    setmodal_center(!modal_center);
  }

  const getIosnamestate = (item, mainIndex) =>{
    const index = item.target.selectedIndex;
    const el = item.target.childNodes[index];
    const option = el.getAttribute("id");
    const temp = details?.map((value, i) => {
      if (mainIndex === i ) {
        value.state = option;
      }
      return value;
    });
    setDetails(temp);
  };

  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_8",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionC",
          "subSection": "principle_8"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  return (
    <React.Fragment>
      <Modal className="center-modal"
        size="lg" isOpen={modal_center}
        toggle={() => { tog_center(); }}
        centered
        autoFocus={false}
        backdrop={"static"}
      >
        <ModalSection modalData={modalData} setModalData={setModalData} state={data} setmodal_center={setmodal_center}/>
        <div className="modal-footer">
          <button
            onClick={(e) => {
              setmodal_center(false);
              e.target.blur();
              setModalData({ name: "", value: "", type: "", title: "" });
            }}
            className="btn btn-primary"
          >Update</button>
        </div>
      </Modal>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <Row>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h5 className="mt-2">
                <b>
                  PRINCIPLE 8 Businesses should promote inclusive growth and equitable development
                </b>
              </h5>
              <p className="txt-decoration mt-2">
                <b>Essential Indicators</b>
              </p>
              <Formik initialValues={data}>
                {({ values, setFieldValue }) => (
                  <form
                    onChange={onHandleChange}
                    onSubmit={(e) => {e.preventDefault();
                      _set(sebiCurrentData, "sectionC.principle_8", data);
                      updateSebiData(sebiCurrentData);
                      draftSebiData({ ...draftReport, principle_8: false });
                    }}>
                    <p>1. Details of Social Impact Assessments (SIA) of projects undertaken by the entity based on applicable laws, in the current financial year.</p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="social_impact_assessments">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th className="text-align">Name and brief details of project</th>
                                <th className="text-align">SIA Notification No. </th>
                                <th className="text-align" width="8%">Date Of Notification</th>
                                <th className="text-align" width="15%">Whether conducted by independent external agency</th>
                                <th className="text-align" width="13%">Results communicated in public domain</th>
                                <th className="text-align">Relevant Web link</th>
                                <th width="3%" onClick={() => {
                                  push({
                                    details_of_projects: "", sia: "", date: "", whether_conducted: "", results: "", weblink: ""
                                  });
                                  setData({
                                    ...data, social_impact_assessments: [
                                      ...data.social_impact_assessments,
                                      {
                                        details_of_projects: "", sia: "", date: "", whether_conducted: "", results: "", weblink: ""
                                      }
                                    ]
                                  });
                                  draftSebiData({
                                    ...draftReport, principle_8: {
                                      ...data, social_impact_assessments: [
                                        ...data.social_impact_assessments,
                                        {
                                          details_of_projects: "", sia: "", date: "", whether_conducted: "", results: "", weblink: ""
                                        }
                                      ]
                                    }
                                  });
                                }}><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.social_impact_assessments?.length > 0 && values?.social_impact_assessments?.map((friend, index) => (
                                <tr key={index}>
                                  <td>
                                    <Field
                                      as="textarea"
                                      name={`social_impact_assessments[${index}].details_of_projects`}
                                      placeholder="Name"
                                      className="form-control"
                                      value={data?.social_impact_assessments[index]?.details_of_projects}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Name and brief details of project",
                                          name: `social_impact_assessments[${index}].details_of_projects`,
                                          value: _get(data, `social_impact_assessments[${index}].details_of_projects`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      className="form-control"
                                      name={`social_impact_assessments[${index}].sia`}
                                      placeholder="SIA Notification No."
                                      type="text"
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      className="form-control"
                                      name={`social_impact_assessments[${index}].date`}
                                      type="date"
                                    />
                                  </td>
                                  <td className="text-align" role="group" aria-labelledby="my-radio-group">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="Yes"
                                        name={`social_impact_assessments[${index}].whether_conducted`}
                                        id={`radioBtn379${index}`}
                                        onClick={(e) => {setFieldValue(`social_impact_assessments[${index}].whether_conducted`, e.target.value );
                                          onHandleChange(e);
                                        }}
                                        onChange={() => {}}
                                        checked={values?.social_impact_assessments[index]?.whether_conducted === "Yes"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn379${index}`}>Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="No"
                                        name={`social_impact_assessments[${index}].whether_conducted`}
                                        id={`radioBtn380${index}`}
                                        onClick={(e) => {setFieldValue(`social_impact_assessments[${index}].whether_conducted`, e.target.value );
                                          onHandleChange(e);
                                        }}
                                        onChange={() => {}}
                                        checked={values?.social_impact_assessments[index]?.whether_conducted === "No"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn380${index}`}>No</label>
                                    </div>
                                  </td>
                                  <td className="text-align" role="group" aria-labelledby="my-radio-group">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="Yes"
                                        name={`social_impact_assessments[${index}].results`}
                                        id={`radioBtn381${index}`}
                                        onClick={(e) => {setFieldValue(`social_impact_assessments[${index}].results`, e.target.value );
                                          onHandleChange(e);
                                        }}
                                        onChange={() => {}}
                                        checked={values?.social_impact_assessments[index]?.results === "Yes"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn381${index}`}>Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="No"
                                        name={`social_impact_assessments[${index}].results`}
                                        id={`radioBtn382${index}`}
                                        onClick={(e) => {setFieldValue(`social_impact_assessments[${index}].results`, e.target.value );
                                          onHandleChange(e);
                                        }}
                                        onChange={() => {}}
                                        checked={values?.social_impact_assessments[index]?.results === "No"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn382${index}`}>No</label>
                                    </div>
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      className="form-control"
                                      name={`social_impact_assessments[${index}].weblink`}
                                      placeholder="Weblink"
                                      type="text"
                                      value={data.social_impact_assessments[index]?.weblink}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Relevant Web link",
                                          name: `social_impact_assessments[${index}].weblink`,
                                          value: _get(data, `social_impact_assessments[${index}].weblink`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  {values?.social_impact_assessments?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.social_impact_assessments.filter((item, i) => index !==i);
                                    draftSebiData({ ...draftReport, principle_8: { ...data, social_impact_assessments: newr } });
                                    setData({ ...data, social_impact_assessments: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.social_impact_assessments?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "social_impact_assessments" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.social_impact_assessments?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "social_impact_assessments" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "social_impact_assessments" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">
                      2. Provide information on project(s) for which ongoing Rehabilitation and Resettlement (R&R) is being undertaken by your entity, in the following format:
                    </p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="r_and_r">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th width="4%" className="text-align">Sr. No.</th>
                                <th className="text-align">Name of Project for which R&R is ongoing</th>
                                <th className="text-align">State</th>
                                <th className="text-align">District</th>
                                <th width="12%" className="text-align">No. of Project Affected Families (PAFs)</th>
                                <th width="10%" className="text-align">% of PAFs covered by R&R</th>
                                <th width="15%" className="text-align">Amounts No. paid to PAFs in the FY (In INR)</th>
                                <th width="3%" onClick={() => {
                                  push({
                                    name_of_project: "", state: "", district: "", project_affected: "", pafs: "", amount: ""
                                  });
                                  setData({
                                    ...data, r_and_r: [
                                      ...data.r_and_r,
                                      {
                                        name_of_project: "", state: "", district: "", project_affected: "", pafs: "", amount: ""
                                      }
                                    ]
                                  });
                                  draftSebiData({
                                    ...draftReport, principle_8: {
                                      ...data, r_and_r: [
                                        ...data.r_and_r,
                                        {
                                          name_of_project: "", state: "", district: "", project_affected: "", pafs: "", amount: ""
                                        }
                                      ]
                                    }
                                  });
                                  setDetails([...details, { state: "", city: "" }]);
                                }}><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.r_and_r?.length > 0 && values?.r_and_r?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">{index+1}.</td>
                                  <td className="text-align">
                                    <Field
                                      name={`r_and_r[${index}].name_of_project`}
                                      placeholder="Name of Project"
                                      type="text"
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      as="select" className="form-select"
                                      name={`r_and_r[${index}].state`}
                                      value={data.r_and_r[index]?.state}
                                      onChange={(val) => {
                                        getIosnamestate(val, index);
                                      }}
                                    >
                                      <option value="">Select State</option>
                                      {
                                        State.getStatesOfCountry("IN")?.map((item, i) => (<option id={item.isoCode} value={item.name} key={i}>
                                          {item.name}
                                        </option>))}
                                    </Field>
                                  </td>
                                  <td>
                                    <Field
                                      placeholder="District"
                                      name={`r_and_r[${index}].district`}
                                      value={data.r_and_r[index]?.district}
                                      className="form-control"
                                    >
                                    </Field>
                                  </td>
                                  <td>
                                    <Field
                                      name={`r_and_r[${index}].project_affected`}
                                      placeholder="Project Affected Families"
                                      type="number"
                                      onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                      onWheel={(e) => e.target.blur()}
                                      className="form-control"
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`r_and_r[${index}].pafs`}
                                      placeholder="% of PAFs"
                                      type="number"
                                      onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                      onWheel={(e) => e.target.blur()}
                                      className="form-control"
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`r_and_r[${index}].amount`}
                                      placeholder="Amounts"
                                      type="number"
                                      onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                      onWheel={(e) => e.target.blur()}
                                      className="form-control"
                                    />
                                  </td>
                                  {values?.r_and_r?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.r_and_r.filter((item, i) => index !==i);
                                    draftSebiData({ ...draftReport, principle_8: { ...data, r_and_r: newr } });
                                    setData({ ...data, r_and_r: newr });
                                    const newr1 = details.filter((item, i) => index !==i);
                                    setDetails(newr1);
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.rehabilitation?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "rehabilitation" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.rehabilitation?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "rehabilitation" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "rehabilitation" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">3. Describe the mechanisms to receive and redress grievances of the community.</p>
                    <TextArea
                      name="receive_and_redress_grievances"
                      value={_get(data, "receive_and_redress_grievances", "")}
                      onChange={onHandleChange}
                    />

                    <p className="mt-2">4. Percentage of input material (inputs to total inputs by value) sourced from suppliers:</p>
                    <table className="table table-bordered mb-0">
                      <thead className="table-light">
                        <tr>
                          <th></th>
                          <th className="text-align">FY (Current Financial Year)</th>
                          <th className="text-align">FY (Previous Financial Year)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-align-left">
                      Directly sourced from MSMEs/ small producers
                          </td>
                          <TableInput
                            name="sourced_from_suppliers.sourced_from_msmes.current"
                            value={_get(data, "sourced_from_suppliers.sourced_from_msmes.current", "")}
                            onChange={onHandleChange}
                            type="number"
                          />
                          <TableInput
                            isDisabled={true}
                            name="sourced_from_suppliers.sourced_from_msmes.previous"
                            value={_get(data, "sourced_from_suppliers.sourced_from_msmes.previous", "")}
                            onChange={onHandleChange}
                            type="number"
                          />
                        </tr>
                        <tr>
                          <td className="text-align-left">
                      Sourced directly from within the district and neighbouring
                      districts
                          </td>
                          <TableInput
                            name="sourced_from_suppliers.neighbouring_districts.current"
                            value={_get(data, "sourced_from_suppliers.neighbouring_districts.current", "")}
                            onChange={onHandleChange}
                            type="number"
                          />
                          <TableInput
                            isDisabled={true}
                            name="sourced_from_suppliers.neighbouring_districts.previous"
                            value={_get(data, "sourced_from_suppliers.neighbouring_districts.previous", "")}
                            onChange={onHandleChange}
                            type="number"
                          />
                        </tr>
                      </tbody>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.sourced_from_suppliers?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "sourced_from_suppliers" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.sourced_from_suppliers?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "sourced_from_suppliers" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "sourced_from_suppliers" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="txt-decoration mt-2"><b>Leadership Indicators</b></p>
                    <p>
                      1. Provide details of actions taken to mitigate any negative social impacts identified in the Social Impact Assessments (Reference: Question 1 of Essential Indicators above):
                    </p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="details_of_actions_taken_to_mitigate">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th className="text-align">Details of negative social impact identified</th>
                                <th className="text-align">Corrective action taken</th>
                                <th width="3%" onClick={() => {
                                  push({ negative_social: "", action_taken: "" });
                                  setData({
                                    ...data, details_of_actions_taken_to_mitigate: [
                                      ...data.details_of_actions_taken_to_mitigate,
                                      { negative_social: "", action_taken: "" }
                                    ]
                                  });
                                  draftSebiData({
                                    ...draftReport, principle_8: {
                                      ...data, details_of_actions_taken_to_mitigate: [
                                        ...data.details_of_actions_taken_to_mitigate,
                                        { negative_social: "", action_taken: "" }
                                      ]
                                    }
                                  });
                                }}><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.details_of_actions_taken_to_mitigate?.length > 0 && values?.details_of_actions_taken_to_mitigate?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">
                                    <Field
                                      as="textarea"
                                      className="form-control"
                                      placeholder="Details of negative social impact identified"
                                      name={`details_of_actions_taken_to_mitigate[${index}].negative_social`}
                                      value={data.details_of_actions_taken_to_mitigate[index]?.negative_social}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Details of negative social impact identified",
                                          name: `details_of_actions_taken_to_mitigate[${index}].negative_social`,
                                          value: _get(data, `details_of_actions_taken_to_mitigate[${index}].negative_social`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      as="textarea"
                                      className="form-control"
                                      placeholder="Action taken"
                                      name={`details_of_actions_taken_to_mitigate[${index}].action_taken`}
                                      value={data.details_of_actions_taken_to_mitigate[index]?.action_taken}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Corrective action taken",
                                          name: `details_of_actions_taken_to_mitigate[${index}].action_taken`,
                                          value: _get(data, `details_of_actions_taken_to_mitigate[${index}].action_taken`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  {values?.details_of_actions_taken_to_mitigate?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.details_of_actions_taken_to_mitigate.filter((item, i) => index !==i);
                                    draftSebiData({ ...draftReport, principle_8: { ...data, details_of_actions_taken_to_mitigate: newr } });
                                    setData({ ...data, details_of_actions_taken_to_mitigate: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.mitigate_any_negative?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "mitigate_any_negative" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.mitigate_any_negative?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "mitigate_any_negative" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "mitigate_any_negative" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">
                    2. Provide the following information on CSR projects undertaken by your entity in designated aspirational districts as identified by government bodies:
                    </p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="identified_by_government_bodies">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th width="4%" className="text-align">Sr. No.</th>
                                <th width="31%" className="text-align">State</th>
                                <th width="30%" className="text-align">Aspirational District</th>
                                <th width="30%" className="text-align">Amount spent (In INR)</th>
                                <th width="3%" onClick={() => {
                                  push({ state: "", district: "", amount: "" });
                                  setData({
                                    ...data, identified_by_government_bodies: [
                                      ...data.identified_by_government_bodies,
                                      { state: "", district: "", amount: "" }
                                    ]
                                  });
                                  draftSebiData({
                                    ...draftReport, principle_8: {
                                      ...data, identified_by_government_bodies: [
                                        ...data.identified_by_government_bodies,
                                        { state: "", district: "", amount: "" }
                                      ]
                                    }
                                  });
                                }}><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.identified_by_government_bodies?.length > 0 && values?.identified_by_government_bodies?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">{index+1}.</td>
                                  <td>
                                    <Field
                                      as="select" className="form-select"
                                      name={`identified_by_government_bodies[${index}].state`}
                                      value={data?.identified_by_government_bodies[index]?.state}
                                      onChange={(val) => {
                                        getIosnamestate(val, index);
                                      }}
                                    >
                                      <option value="">Select State</option>
                                      {
                                        State.getStatesOfCountry("IN")?.map((item, i) => (<option id={item.isoCode} value={item.name} key={i}>
                                          {item.name}
                                        </option>))}
                                    </Field>
                                  </td>
                                  <td>
                                    <Field
                                      placeholder="Aspirational District"
                                      className="form-control"
                                      name={`identified_by_government_bodies[${index}].district`}
                                      value={data?.identified_by_government_bodies[index]?.district}
                                    >
                                    </Field>
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`identified_by_government_bodies[${index}].amount`}
                                      placeholder="Amount spent"
                                      type="number"
                                      onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                      onWheel={(e) => e.target.blur()}
                                      className="form-control"
                                    />
                                  </td>
                                  {values?.identified_by_government_bodies?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.identified_by_government_bodies.filter((item, i) => index !==i);
                                    draftSebiData({ ...draftReport, principle_8: { ...data, identified_by_government_bodies: newr } });
                                    setData({ ...data, identified_by_government_bodies: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.aspirational_districts?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "aspirational_districts" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.aspirational_districts?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "aspirational_districts" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "aspirational_districts" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">3. (A) Do you have a preferential procurement policy where you give preference to purchase from
                    suppliers comprising marginalized /vulnerable groups? (Yes/No)</p>
                    <RadioButton className="dis-right ml-10" id="radio_69button"
                      name="preferential_procurement_policy"
                      value={_get(data, "preferential_procurement_policy", "")}
                      onChange={onHandleChange}
                    />

                    <p className="mt-2">(B) From which marginalized /vulnerable groups do you procure?</p>
                    <TextArea
                      name="which_marginal_vulnerable_groups"
                      value={_get(data, "which_marginal_vulnerable_groups", "")}
                      onChange={onHandleChange}
                    />

                    <p className="mt-2">(C) What percentage of total procurement (by value) does it constitute?</p>
                    <div style={{ maxWidth: "300px" }}>
                      <Input
                        name="percentage_of_total_procurement"
                        value={_get(data, "percentage_of_total_procurement", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                    </div>

                    <p className="mt-2">
                    4. Details of the benefits derived and shared from the intellectual properties owned or acquired by your entity (in the current financial year), based on traditional knowledge:
                    </p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="benefits_derived_and_shared">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th width="4%" className="text-align">Sr. No.</th>
                                <th width="30%" className="text-align">Intellectual Property based on traditional knowledge</th>
                                <th className="text-align">Owned/ Acquired</th>
                                <th className="text-align">Benefit shared</th>
                                <th className="text-align">Basis of calculating benefit share</th>
                                <th width="3%" onClick={() => {
                                  push({ intellectual_property: "", owned: "", benefit: "", calculating_benefit: "" });
                                  setData({
                                    ...data, benefits_derived_and_shared: [
                                      ...data.benefits_derived_and_shared,
                                      { intellectual_property: "", owned: "", benefit: "", calculating_benefit: "" }
                                    ]
                                  });
                                  draftSebiData({
                                    ...draftReport, principle_8: {
                                      ...data, benefits_derived_and_shared: [
                                        ...data.benefits_derived_and_shared,
                                        { intellectual_property: "", owned: "", benefit: "", calculating_benefit: "" }
                                      ]
                                    }
                                  });
                                }}><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.benefits_derived_and_shared?.length > 0 && values?.benefits_derived_and_shared?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">{index+1}.</td>
                                  <td className="text-align">
                                    <Field
                                      name={`benefits_derived_and_shared[${index}].intellectual_property`}
                                      placeholder="Intellectual Property "
                                      type="text"
                                      className="form-control"
                                    />
                                  </td>
                                  <td className="text-align" role="group" aria-labelledby="my-radio-group">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="Yes"
                                        name={`benefits_derived_and_shared[${index}].owned`}
                                        id={`radioBtn383${index}`}
                                        onClick={(e) => {setFieldValue(`benefits_derived_and_shared[${index}].owned`, e.target.value );
                                          onHandleChange(e);
                                        }}
                                        onChange={() => {}}
                                        checked={values?.benefits_derived_and_shared[index]?.owned === "Yes"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn383${index}`}>Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="No"
                                        name={`benefits_derived_and_shared[${index}].owned`}
                                        id={`radioBtn384${index}`}
                                        onClick={(e) => {setFieldValue(`benefits_derived_and_shared[${index}].owned`, e.target.value );
                                          onHandleChange(e);
                                        }}
                                        onChange={() => {}}
                                        checked={values?.benefits_derived_and_shared[index]?.owned === "No"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn384${index}`}>No</label>
                                    </div>
                                  </td>
                                  <td className="text-align" role="group" aria-labelledby="my-radio-group">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="Yes"
                                        name={`benefits_derived_and_shared[${index}].benefit`}
                                        id={`radioBtn385${index}`}
                                        onClick={(e) => {setFieldValue(`benefits_derived_and_shared[${index}].benefit`, e.target.value );
                                          onHandleChange(e);
                                        }}
                                        onChange={() => {}}
                                        checked={values?.benefits_derived_and_shared[index]?.benefit === "Yes"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn385${index}`}>Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="No"
                                        name={`benefits_derived_and_shared[${index}].benefit`}
                                        id={`radioBtn386${index}`}
                                        onClick={(e) => {setFieldValue(`benefits_derived_and_shared[${index}].benefit`, e.target.value );
                                          onHandleChange(e);
                                        }}
                                        onChange={() => {}}
                                        checked={values?.benefits_derived_and_shared[index]?.benefit === "No"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn386${index}`}>No</label>
                                    </div>
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`benefits_derived_and_shared[${index}].calculating_benefit`}
                                      placeholder="Basis of calculating benefit share"
                                      type="text"
                                      className="form-control"
                                    />
                                  </td>
                                  {values?.benefits_derived_and_shared?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.benefits_derived_and_shared.filter((item, i) => index !==i);
                                    draftSebiData({ ...draftReport, principle_8: { ...data, benefits_derived_and_shared: newr } });
                                    setData({ ...data, benefits_derived_and_shared: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.properties_owned_or_acquired?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "properties_owned_or_acquired" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.properties_owned_or_acquired?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "properties_owned_or_acquired" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "properties_owned_or_acquired" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">
                    5. Details of corrective actions taken or underway, based on any adverse order in intellectual property related disputes wherein usage of traditional knowledge is involved.
                    </p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="intellectual_property_related_disputes">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th className="text-align">Name of authority</th>
                                <th className="text-align">Brief the case</th>
                                <th className="text-align">Corrective action taken</th>
                                <th width="3%" onClick={() => {
                                  push({ name_of_authority: "", brife: "", action: "" });
                                  setData({
                                    ...data, intellectual_property_related_disputes: [
                                      ...data.intellectual_property_related_disputes,
                                      { name_of_authority: "", brife: "", action: "" }
                                    ]
                                  });
                                  draftSebiData({
                                    ...draftReport, principle_8: {
                                      ...data, intellectual_property_related_disputes: [
                                        ...data.intellectual_property_related_disputes,
                                        { name_of_authority: "", brife: "", action: "" }
                                      ]
                                    }
                                  });
                                }}><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.intellectual_property_related_disputes?.length > 0 && values?.intellectual_property_related_disputes?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">
                                    <Field
                                      name={`intellectual_property_related_disputes[${index}].name_of_authority`}
                                      placeholder="Name of authority"
                                      as="textarea"
                                      className="form-control"
                                      value={data.intellectual_property_related_disputes[index]?.name_of_authority}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Name of authority",
                                          name: `intellectual_property_related_disputes[${index}].name_of_authority`,
                                          value: _get(data, `intellectual_property_related_disputes[${index}].name_of_authority`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`intellectual_property_related_disputes[${index}].brife`}
                                      placeholder="Brief the case"
                                      as="textarea"
                                      className="form-control"
                                      value={data.intellectual_property_related_disputes[index]?.brife}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Brief the case",
                                          name: `intellectual_property_related_disputes[${index}].brife`,
                                          value: _get(data, `intellectual_property_related_disputes[${index}].brife`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`intellectual_property_related_disputes[${index}].action`}
                                      placeholder="Action taken"
                                      as="textarea"
                                      className="form-control"
                                      value={data.intellectual_property_related_disputes[index]?.action}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Corrective action taken",
                                          name: `intellectual_property_related_disputes[${index}].action`,
                                          value: _get(data, `intellectual_property_related_disputes[${index}].action`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  {values?.intellectual_property_related_disputes?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.intellectual_property_related_disputes.filter((item, i) => index !==i);
                                    draftSebiData({ ...draftReport, principle_8: { ...data, intellectual_property_related_disputes: newr } });
                                    setData({ ...data, intellectual_property_related_disputes: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.traditional_knowledge_is_involved?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "traditional_knowledge_is_involved" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.traditional_knowledge_is_involved?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "traditional_knowledge_is_involved" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "traditional_knowledge_is_involved" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">6. Details of beneficiaries of CSR Projects:</p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="beneficiaries_of_csr_projects">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th width="4%" className="text-align">Sr. No.</th>
                                <th className="text-align">CSR Project</th>
                                <th width="25%" className="text-align">No. of persons benefitted from CSR Projects</th>
                                <th width="25%" className="text-align">% of beneficiaries from vulnerable and marginalized groups</th>
                                <th width="3%" onClick={() => {
                                  push({ project: "", persons_benefitted: "", marginalized_groups: "" });
                                  setData({
                                    ...data, beneficiaries_of_csr_projects: [
                                      ...data.beneficiaries_of_csr_projects,
                                      { project: "", persons_benefitted: "", marginalized_groups: "" }
                                    ]
                                  });
                                  draftSebiData({
                                    ...draftReport, principle_8: {
                                      ...data, beneficiaries_of_csr_projects: [
                                        ...data.beneficiaries_of_csr_projects,
                                        { project: "", persons_benefitted: "", marginalized_groups: "" }
                                      ]
                                    }
                                  });
                                }}><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.beneficiaries_of_csr_projects?.length > 0 && values?.beneficiaries_of_csr_projects?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">{index+1}.</td>
                                  <td className="text-align">
                                    <Field
                                      name={`beneficiaries_of_csr_projects[${index}].project`}
                                      placeholder="CSR Project"
                                      as="textarea"
                                      className="form-control"
                                      value={data.beneficiaries_of_csr_projects[index]?.project}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "CSR Project",
                                          name: `beneficiaries_of_csr_projects[${index}].project`,
                                          value: _get(data, `beneficiaries_of_csr_projects[${index}].project`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`beneficiaries_of_csr_projects[${index}].persons_benefitted`}
                                      placeholder="No. of persons benefitted"
                                      type="number"
                                      onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                      onWheel={(e) => e.target.blur()}
                                      className="form-control"
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`beneficiaries_of_csr_projects[${index}].marginalized_groups`}
                                      placeholder="% of beneficiaries "
                                      type="number"
                                      onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                      onWheel={(e) => e.target.blur()}
                                      className="form-control"
                                    />
                                  </td>
                                  {values?.beneficiaries_of_csr_projects?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.beneficiaries_of_csr_projects.filter((item, i) => index !==i);
                                    draftSebiData({ ...draftReport, principle_8: { ...data, beneficiaries_of_csr_projects: newr } });
                                    setData({ ...data, beneficiaries_of_csr_projects: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.beneficiaries_of_csr_Projects?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "beneficiaries_of_csr_Projects" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.beneficiaries_of_csr_Projects?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "beneficiaries_of_csr_Projects" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "beneficiaries_of_csr_Projects" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => onSectionChange(15)}>Previous</button>{" "}
                      </div>
                      <div>
                        <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => onSectionChange(17)}>Next</button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionC?.principle_8,
    previousData: state.NewReportReducer?.sebiForm?.previousYearData?.sectionC?.principle_8,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.principle_8,
    filesData: state.NewReportReducer?.draftFiles?.principle_8
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(Principle8);
