/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import { ROLES } from "common/constants";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password
    });

    if (response.status) {
      localStorage.setItem("authUser", JSON.stringify(response));
      const session = JSON.parse(localStorage.getItem("authUser"));
      yield put(loginSuccess(response));
      {
        session.userData.user.role === ROLES.SUPERADMIN
          ? history.push("/superadmin/dashboard")
          : history.push("/admin/dashboard");
      }
      return response;
    } else {
      yield put(apiError(response));
      return response;
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}
function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
