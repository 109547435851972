import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Formik, FieldArray, Field } from "formik";
import { Modal, Card, Col, DropdownToggle, DropdownMenu, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import ModalSection from "../ModalSection";
import Input from "components/Inputs/Input";
import FormUpload from "../Modalfileupload";
import TextArea from "components/Inputs/TextArea";
import TableInput from "components/Inputs/TableInput";
import RadioButton from "components/Inputs/RadioButton";
import { AxiosGet, AxiosPost } from "common/axiosService";
import TableTextArea from "components/Inputs/TableTextArea";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const Principle1 = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionC.principle_1;
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, prefillData, onSectionChange, previousData, oldData, onSectionToggle, getFile, filesData } = props;

  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);

  const [filedatachange, setFileDataChange] = useState(true);

  const [modal_center, setmodal_center] = useState(false);
  const [modalData, setModalData] = useState({ name: "", value: "", type: "", title: "" });

  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionC", subSection: "principle_1", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect(() => {
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_1"
      });
    }
  }, [newReport, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionC",
      "subSection": "principle_1"
    });
  }, [filedatachange]);

  useEffect(() => {
    if (prefillData?.trainings?.total_number_of_trainings_held) {
      _set(data, "trainings.training_and_awareness.board_of_directors.number", _get(prefillData, "trainings.total_number_of_trainings_held.board_of_directors", ""));
      _set(data, "trainings.training_and_awareness.employees_other_than_bod_and_kmps.number", _get(prefillData, "trainings.total_number_of_trainings_held.employees_other_than_bod_and_kmps", ""));
      _set(data, "trainings.training_and_awareness.key_managerial_personnel.number", _get(prefillData, "trainings.total_number_of_trainings_held.key_managerial_personnel", ""));
      _set(data, "trainings.training_and_awareness.workers.number", _get(prefillData, "trainings.total_number_of_trainings_held.workers", ""));
    }
    if (prefillData?.complaints?.conflict_of_interest) {
      _set(data, "complaints_with_regard.interest_of_the_directors.number", _get(prefillData, "complaints.conflict_of_interest.bod.no_received", ""));
      _set(data, "complaints_with_regard.interest_of_the_kmps.number", _get(prefillData, "complaints.conflict_of_interest.kmp.no_received", ""));
    }

    if (prefillData?.disciplinary_action) {
      _set(data, "disciplinary_action.directors.current", _get(prefillData, "disciplinary_action.no_of_disciplinary_action_against.bod", ""));
      _set(data, "disciplinary_action.kmps.current", _get(prefillData, "disciplinary_action.no_of_disciplinary_action_against.kmp", ""));
      _set(data, "disciplinary_action.employees.current", _get(prefillData, "disciplinary_action.no_of_disciplinary_action_against.employees", ""));
      _set(data, "disciplinary_action.workers.current", _get(prefillData, "disciplinary_action.no_of_disciplinary_action_against.workers", ""));
    }

    if (previousData) {
      ["directors", "employees", "kmps", "workers"].forEach(item => {
        _set(data, `disciplinary_action.${item}.previous`, _get(previousData, `disciplinary_action.${item}.current`, ""));
      });
      ["interest_of_the_directors", "interest_of_the_kmps"].forEach(item => {
        _set(data, `complaints_with_regard.${item}.previous.number`, _get(previousData, `complaints_with_regard.${item}.number`, ""));
        _set(data, `complaints_with_regard.${item}.previous.remarks`, _get(previousData, `complaints_with_regard.${item}.remarks`, ""));
      });

    }
  }, [prefillData, previousData]);

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, principle_1: data });
    setData({ ...data });
  };

  function tog_center () {
    setmodal_center(!modal_center);
  }

  const oninputFocus = (e, title, name1) => {
    if(!modal_center){
      e.target.blur();
    }
    setModalData({
      title: title,
      name: name1,
      value: _get(data, name1, "")
    });
    tog_center();
  };
  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_1",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionC",
          "subSection": "principle_1"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  return (
    <React.Fragment>
      <Modal className="center-modal"
        size="lg" isOpen={modal_center}
        toggle={() => { tog_center(); }}
        centered
        autoFocus={false}
        backdrop={"static"}
      >
        <ModalSection modalData={modalData} setModalData={setModalData} state={data} setmodal_center={setmodal_center}/>

        <div className="modal-footer">
          <button
            onClick={(e) => {
              setmodal_center(false);
              e.target.blur();
              setModalData({ name: "", value: "", type: "", title: "" });
            }}
            className="btn btn-primary"
          >Update</button>
        </div>
      </Modal>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <Row>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <p className="bordr-bottom pb-3">This section is aimed at helping entities demonstrate their performance in integrating the Principles and Core Elements with
              key processes and decisions. The information sought is categorized as “Essential” and “Leadership”. While the essential indicators are expected to be disclosed
              by every entity that is mandated to file this report, the leadership indicators may be voluntarily disclosed by entities which aspire to progress to a higher
              level in their quest to be socially, environmentally and ethically responsible. </p>
              <h5><b>PRINCIPLE 1 Businesses should conduct and govern themselves with integrity, and in a manner that is Ethical, Transparent and Accountable.</b> </h5>
              <p className="txt-decoration mt-2"><b>Essential Indicators</b></p>

              <Formik initialValues={data}>
                {({ values }) => (
                  <form
                    onChange={onHandleChange}
                    onSubmit={(e) => {
                      e.preventDefault();
                      _set(sebiCurrentData, "sectionC.principle_1", data);
                      updateSebiData(sebiCurrentData);
                      draftSebiData({ ...draftReport, principle_1: false });
                    }}>
                    <p>1. Percentage coverage by training and awareness programmes on any of the Principles during the financial year: </p>
                    <table className="table table-bordered mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className="text-align" width="14%">Segment</th>
                          <th className="text-align" width="18%">Total number of training and awareness programmes held</th>
                          <th className="text-align">Topics / principles covered under the training and its impact </th>
                          <th className="text-align" width="18%">%age of persons in respective category covered by the awareness programmes </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-align-left">Board of Directors</td>
                          <TableInput
                            name="trainings.training_and_awareness.board_of_directors.number"
                            value={_get(data, "trainings.training_and_awareness.board_of_directors.number", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            name="trainings.training_and_awareness.board_of_directors.topics"
                            value={_get(data, "trainings.training_and_awareness.board_of_directors.topics", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Topics / principles covered under the training and its impact"}
                          />

                          <TableInput
                            name="trainings.training_and_awareness.board_of_directors.percentage_of_respective_category_covered_by_the_awareness_programmes"
                            value={_get(data, "trainings.training_and_awareness.board_of_directors.percentage_of_respective_category_covered_by_the_awareness_programmes", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                        </tr>
                        <tr>
                          <td className="text-align-left">Key Managerial Personnel</td>
                          <TableInput
                            name="trainings.training_and_awareness.key_managerial_personnel.number"
                            value={_get(data, "trainings.training_and_awareness.key_managerial_personnel.number", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            name="trainings.training_and_awareness.key_managerial_personnel.topics"
                            value={_get(data, "trainings.training_and_awareness.key_managerial_personnel.topics", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Topics / principles covered under the training and its impact"}
                          />

                          <TableInput
                            name="trainings.training_and_awareness.key_managerial_personnel.percentage_of_respective_category_covered_by_the_awareness_programmes"
                            value={_get(data, "trainings.training_and_awareness.key_managerial_personnel.percentage_of_respective_category_covered_by_the_awareness_programmes", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                        </tr>
                        <tr>
                          <td className="text-align-left">Employees other than BOD and KMPs</td>
                          <TableInput
                            name="trainings.training_and_awareness.employees_other_than_bod_and_kmps.number"
                            value={_get(data, "trainings.training_and_awareness.employees_other_than_bod_and_kmps.number", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            name="trainings.training_and_awareness.employees_other_than_bod_and_kmps.topics"
                            value={_get(data, "trainings.training_and_awareness.employees_other_than_bod_and_kmps.topics", "")}
                            onChange={onHandleChange}
                            onFocus={oninputFocus}
                            title={"Topics / principles covered under the training and its impact"}className="text-align"
                          />

                          <TableInput
                            name="trainings.training_and_awareness.employees_other_than_bod_and_kmps.percentage_of_respective_category_covered_by_the_awareness_programmes"
                            value={_get(data, "trainings.training_and_awareness.employees_other_than_bod_and_kmps.percentage_of_respective_category_covered_by_the_awareness_programmes", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                        </tr>
                        <tr>
                          <td className="text-align-left">Workers</td>
                          <TableInput
                            name="trainings.training_and_awareness.workers.number"
                            value={_get(data, "trainings.training_and_awareness.workers.number", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            name="trainings.training_and_awareness.workers.topics"
                            value={_get(data, "trainings.training_and_awareness.workers.topics", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Topics / principles covered under the training and its impact"}
                          />

                          <TableInput
                            name="trainings.training_and_awareness.workers.percentage_of_respective_category_covered_by_the_awareness_programmes"
                            value={_get(data, "trainings.training_and_awareness.workers.percentage_of_respective_category_covered_by_the_awareness_programmes", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                        </tr>
                      </tbody>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.training_and_awareness?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "training_and_awareness" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.training_and_awareness?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "training_and_awareness" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "training_and_awareness" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">2. Details of fines / penalties /punishment/ award/ compounding fees/ settlement amount paid in proceedings
                    (by the entity or by directors / KMPs) with regulators/ law enforcement agencies/ judicial institutions, in the financial year,
                     in the following format (Note: the entity shall make disclosures on the basis of materiality as specified in Regulation 30 of
                      SEBI (Listing Obligations and Disclosure Obligations) Regulations, 2015 and as disclosed on the entity’s website):</p>
                    <p className="txt-decoration"><b>Monetary</b></p>
                    <table className="table table-bordered mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className="text-align" width="14%"></th>
                          <th className="text-align" width="16%">NGRBC Principle</th>
                          <th className="text-align" width="17%">Name of the regulatory/ enforcement agencies/ judicial institutions</th>
                          <th className="text-align" width="15%">Amount (In INR)</th>
                          <th className="text-align" width="30%">Brief of the Case</th>
                          <th className="text-align" >Has an appeal been preferred?</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-align-left">Penalty/ Fine</td>
                          <TableInput
                            name="monetary.penalty_fine.ngrbc"
                            value={_get(data, "monetary.penalty_fine.ngrbc", "")}
                            onChange={onHandleChange}
                            className="text-align" />

                          <TableInput
                            name="monetary.penalty_fine.name_of_the_regulatory"
                            value={_get(data, "monetary.penalty_fine.name_of_the_regulatory", "")}
                            onChange={onHandleChange}
                            className="text-align" />

                          <TableInput
                            name="monetary.penalty_fine.amount"
                            value={_get(data, "monetary.penalty_fine.amount", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableTextArea
                            name="monetary.penalty_fine.brief"
                            value={_get(data, "monetary.penalty_fine.brief", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Brief of the Case"}
                          />

                          <td className="text-align">
                            <RadioButton
                              name="monetary.penalty_fine.has_an_appeal"
                              value={_get(data, "monetary.penalty_fine.has_an_appeal", "")}
                              onChange={onHandleChange}
                              id="radio_95button" />

                          </td>
                        </tr>
                        <tr>
                          <td className="text-align-left">Settlement</td>
                          <TableInput
                            name="monetary.settlement.ngrbc"
                            value={_get(data, "monetary.settlement.ngrbc", "")}
                            onChange={onHandleChange}
                            className="text-align" />

                          <TableInput
                            name="monetary.settlement.name_of_the_regulatory"
                            value={_get(data, "monetary.settlement.name_of_the_regulatory", "")}
                            onChange={onHandleChange}
                            className="text-align" />

                          <TableInput
                            name="monetary.settlement.amount"
                            value={_get(data, "monetary.settlement.amount", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableTextArea
                            name="monetary.settlement.brief"
                            value={_get(data, "monetary.settlement.brief", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Brief of the Case"}
                          />

                          <td className="text-align">
                            <RadioButton
                              name="monetary.settlement.has_an_appeal"
                              value={_get(data, "monetary.settlement.has_an_appeal", "")}
                              onChange={onHandleChange}
                              id="radio_96button" />

                          </td>
                        </tr>
                        <tr>
                          <td className="text-align-left">Employees other than BOD and KMPs</td>
                          <TableInput
                            name="monetary.employees_other_than_bod_and_kmps.ngrbc"
                            value={_get(data, "monetary.employees_other_than_bod_and_kmps.ngrbc", "")}
                            onChange={onHandleChange}
                            className="text-align" />

                          <TableInput
                            name="monetary.employees_other_than_bod_and_kmps.name_of_the_regulatory"
                            value={_get(data, "monetary.employees_other_than_bod_and_kmps.name_of_the_regulatory", "")}
                            onChange={onHandleChange}
                            className="text-align" />

                          <TableInput
                            name="monetary.employees_other_than_bod_and_kmps.amount"
                            value={_get(data, "monetary.employees_other_than_bod_and_kmps.amount", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableTextArea
                            name="monetary.employees_other_than_bod_and_kmps.brief"
                            value={_get(data, "monetary.employees_other_than_bod_and_kmps.brief", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Brief of the Case"}
                          />

                          <td className="text-align">
                            <RadioButton
                              name="monetary.employees_other_than_bod_and_kmps.has_an_appeal"
                              value={_get(data, "monetary.employees_other_than_bod_and_kmps.has_an_appeal", "")}
                              onChange={onHandleChange}
                              id="radio_97button" />

                          </td>
                        </tr>
                        <tr>
                          <td className="text-align-left">Compounding fee</td>
                          <TableInput
                            name="monetary.compounding_fee.ngrbc"
                            value={_get(data, "monetary.compounding_fee.ngrbc", "")}
                            onChange={onHandleChange}
                            className="text-align" />

                          <TableInput
                            name="monetary.compounding_fee.name_of_the_regulatory"
                            value={_get(data, "monetary.compounding_fee.name_of_the_regulatory", "")}
                            onChange={onHandleChange}
                            className="text-align" />

                          <TableInput
                            name="monetary.compounding_fee.amount"
                            value={_get(data, "monetary.compounding_fee.amount", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableTextArea
                            name="monetary.compounding_fee.brief"
                            value={_get(data, "monetary.compounding_fee.brief", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Brief of the Case"}
                          />

                          <td className="text-align">
                            <RadioButton
                              name="monetary.compounding_fee.has_an_appeal"
                              value={_get(data, "monetary.compounding_fee.has_an_appeal", "")}
                              onChange={onHandleChange}
                              id="radio_98button" />

                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.details_of_fines_penalties?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "details_of_fines_penalties" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.details_of_fines_penalties?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "details_of_fines_penalties" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "details_of_fines_penalties" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="txt-decoration mt-2"><b>Non-Monetary</b></p>
                    <table className="table table-bordered mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className="text-align" width="14%"></th>
                          <th className="text-align" width="21%">NGRBC Principle </th>
                          <th className="text-align" width="21%">Name of the regulatory/ enforcement agencies/ judicial institutions </th>
                          <th className="text-align" width="36%">Brief of the Case</th>
                          <th className="text-align">Has an appeal been preferred?</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-align-left">Imprisonment</td>
                          <TableInput
                            name="nonmonetary.imprisonment.ngrbc"
                            value={_get(data, "nonmonetary.imprisonment.ngrbc", "")}
                            onChange={onHandleChange}
                            className="text-align" />

                          <TableInput
                            name="nonmonetary.imprisonment.name_of_the_regulatory"
                            value={_get(data, "nonmonetary.imprisonment.name_of_the_regulatory", "")}
                            onChange={onHandleChange}
                            className="text-align" />

                          <TableTextArea
                            name="nonmonetary.imprisonment.brief"
                            value={_get(data, "nonmonetary.imprisonment.brief", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Brief of the Case"}
                          />

                          <td className="text-align">
                            <RadioButton
                              name="nonmonetary.imprisonment.has_an_appeal"
                              value={_get(data, "nonmonetary.imprisonment.has_an_appeal", "")}
                              onChange={onHandleChange}
                              id="radio_99button" />

                          </td>
                        </tr>
                        <tr>
                          <td className="text-align-left">Punishment</td>
                          <TableInput
                            name="nonmonetary.punishment.ngrbc"
                            value={_get(data, "nonmonetary.punishment.ngrbc", "")}
                            onChange={onHandleChange}
                            className="text-align" />

                          <TableInput
                            name="nonmonetary.punishment.name_of_the_regulatory"
                            value={_get(data, "nonmonetary.punishment.name_of_the_regulatory", "")}
                            onChange={onHandleChange}
                            className="text-align" />

                          <TableTextArea
                            name="nonmonetary.punishment.brief"
                            value={_get(data, "nonmonetary.punishment.brief", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Brief of the Case"}
                          />

                          <td className="text-align">
                            <RadioButton
                              name="nonmonetary.punishment.has_an_appeal"
                              value={_get(data, "nonmonetary.punishment.has_an_appeal", "")}
                              onChange={onHandleChange}
                              id="radio_92button" />

                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.non_monetary?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "non_monetary" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.non_monetary?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "non_monetary" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "non_monetary" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">3. Of the instances disclosed in Question 2 above, details of the Appeal/ Revision preferred in cases
                    where monetary or non-monetary action has been appealed.</p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="appeal_revision_preferred_in_cases">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th className="text-align" width="55%">Case Details</th>
                                <th className="text-align">Name of the regulatory/ enforcement agencies/ judicial institutions</th>
                                <th width="3%" onClick={() => {
                                  push({
                                    case_details: "",
                                    name_of_the_regulatory: ""
                                  });
                                  setData({
                                    ...data, appeal_revision_preferred_in_cases: [
                                      ...data.appeal_revision_preferred_in_cases,
                                      {
                                        case_details: "",
                                        name_of_the_regulatory: ""
                                      }
                                    ]
                                  });
                                }
                                }><i className="bx bxs-plus-circle mdi-add-btn" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.appeal_revision_preferred_in_cases?.length > 0 && values?.appeal_revision_preferred_in_cases?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">
                                    <Field
                                      as="textarea"
                                      name={`appeal_revision_preferred_in_cases[${index}].case_details`}
                                      placeholder="Case Details"
                                      className="form-control"
                                      value={data.appeal_revision_preferred_in_cases[index].case_details}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Case Details",
                                          name: `appeal_revision_preferred_in_cases[${index}].case_details`,
                                          value: _get(data, `appeal_revision_preferred_in_cases[${index}].case_details`, "")
                                        });
                                        tog_center();
                                      }}
                                    />

                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`appeal_revision_preferred_in_cases[${index}].name_of_the_regulatory`}
                                      placeholder="Name"
                                      type="text"
                                      className="form-control"
                                    />

                                  </td>
                                  {values?.appeal_revision_preferred_in_cases?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.appeal_revision_preferred_in_cases.filter((item, i) => index !== i);
                                    draftSebiData({ ...draftReport, principle_1: { ...data, appeal_revision_preferred_in_cases: newr } });
                                    setData({ ...data, appeal_revision_preferred_in_cases: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn" />
                                  </td>}
                                </tr>
                              ))}
                            </tbody>
                          </>

                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.appeal_Revision_preferred?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "appeal_Revision_preferred" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.appeal_Revision_preferred?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "appeal_Revision_preferred" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "appeal_Revision_preferred" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">4. Does the entity have an anti-corruption or anti-bribery policy? If yes, provide details in brief and if provide a web-link to the policy.</p>
                    <RadioButton
                      name="anti_corruption_or_anti_bribery_policy.have_an_anti_corruption"
                      value={_get(data, "anti_corruption_or_anti_bribery_policy.have_an_anti_corruption", "")}
                      onChange={onHandleChange}
                      id="radio_93button" />

                    <TextArea
                      name="anti_corruption_or_anti_bribery_policy.brief"
                      value={_get(data, "anti_corruption_or_anti_bribery_policy.brief", "")}
                      onChange={onHandleChange}
                      className="text-align" />

                    <div style={{ maxWidth: "300px" }} className="mt-1">
                      <Input
                        name="anti_corruption_or_anti_bribery_policy.weblink"
                        value={_get(data, "anti_corruption_or_anti_bribery_policy.weblink", "")}
                        onChange={onHandleChange}
                        className="text-align" />

                    </div>
                    <p className="mt-2">5. Number of Directors/KMPs/employees/workers against whom disciplinary action was taken by any
                    law enforcement agency for the charges of bribery/ corruption:</p>
                    <table className="table table-bordered mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className="text-align" width="32%"></th>
                          <th className="text-align">FY (Current Financial Year)</th>
                          <th className="text-align">FY (Previous Financial Year)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-align-left">Director</td>
                          <TableInput
                            name="disciplinary_action.directors.current"
                            value={_get(data, "disciplinary_action.directors.current", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableInput
                            isDisabled={true}
                            name="disciplinary_action.directors.previous"
                            value={_get(data, "disciplinary_action.directors.previous", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                        </tr>
                        <tr>
                          <td className="text-align-left">KMPs</td>
                          <TableInput
                            name="disciplinary_action.kmps.current"
                            value={_get(data, "disciplinary_action.kmps.current", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableInput
                            isDisabled={true}
                            name="disciplinary_action.kmps.previous"
                            value={_get(data, "disciplinary_action.kmps.previous", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                        </tr>
                        <tr>
                          <td className="text-align-left">Employees</td>
                          <TableInput
                            name="disciplinary_action.employees.current"
                            value={_get(data, "disciplinary_action.employees.current", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableInput
                            isDisabled={true}
                            name="disciplinary_action.employees.previous"
                            value={_get(data, "disciplinary_action.employees.previous", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                        </tr>
                        <tr>
                          <td className="text-align-left">Workers</td>
                          <TableInput
                            name="disciplinary_action.workers.current"
                            value={_get(data, "disciplinary_action.workers.current", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableInput
                            isDisabled={true}
                            name="disciplinary_action.workers.previous"
                            value={_get(data, "disciplinary_action.workers.previous", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                        </tr>
                      </tbody>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.number_of_directors?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "number_of_directors" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.number_of_directors?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "number_of_directors" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "number_of_directors" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">6. Details of complaints with regard to conflict of interest:</p>
                    <table className="table table-bordered mb-0">
                      <thead className="table-light">
                        <tr>
                          <th width="32%" rowSpan="2"></th>
                          <th className="text-align" colSpan="2">FY (Current Financial Year)</th>
                          <th className="text-align" colSpan="2">FY (Previous Financial Year) </th>
                        </tr>
                        <tr>
                          <th className="text-align">Number</th>
                          <th className="text-align">Remarks</th>
                          <th className="text-align">Number</th>
                          <th className="text-align">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-align-left">Number	of complaints received in relation to issues of Conflict of Interest of the Directors </td>
                          <TableInput
                            name="complaints_with_regard.interest_of_the_directors.number"
                            value={_get(data, "complaints_with_regard.interest_of_the_directors.number", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableTextArea
                            name="complaints_with_regard.interest_of_the_directors.remarks"
                            value={_get(data, "complaints_with_regard.interest_of_the_directors.remarks", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Remarks"}
                          />

                          <TableInput
                            isDisabled={true}
                            name="complaints_with_regard.interest_of_the_directors.previous.number"
                            value={_get(data, "complaints_with_regard.interest_of_the_directors.previous.number", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableTextArea
                            isDisabled={true}
                            name="complaints_with_regard.interest_of_the_directors.previous.remarks"
                            value={_get(data, "complaints_with_regard.interest_of_the_directors.previous.remarks", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Remarks"}
                          />

                        </tr>
                        <tr>
                          <td className="text-align-left">Number	of complaints received in relation to issues of Conflict of Interest of the KMPs </td>
                          <TableInput
                            name="complaints_with_regard.interest_of_the_kmps.number"
                            value={_get(data, "complaints_with_regard.interest_of_the_kmps.number", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableTextArea
                            name="complaints_with_regard.interest_of_the_kmps.remarks"
                            value={_get(data, "complaints_with_regard.interest_of_the_kmps.remarks", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Remarks"}
                          />

                          <TableInput
                            isDisabled={true}
                            name="complaints_with_regard.interest_of_the_kmps.previous.number"
                            value={_get(data, "complaints_with_regard.interest_of_the_kmps.previous.number", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableTextArea
                            isDisabled={true}
                            name="complaints_with_regard.interest_of_the_kmps.previous.remarks"
                            value={_get(data, "complaints_with_regard.interest_of_the_kmps.previous.remarks", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Remarks"}
                          />

                        </tr>
                      </tbody>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.details_of_complaints?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "details_of_complaints" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.details_of_complaints?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "details_of_complaints" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "details_of_complaints" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">7. Provide details of any corrective action taken or underway on issues related to fines / penalties / action taken by regulators/
                    law enforcement cagencies/ judicial institutions, on cases of corruption and conflicts of interest.</p>
                    <TextArea
                      name="corruption_and_conflicts_of_interest"
                      value={_get(data, "corruption_and_conflicts_of_interest", "")}
                      onChange={onHandleChange}
                      className="text-align" />

                    <p className="txt-decoration mt-2"><b>Leadership Indicators</b></p>
                    <p>1.	Awareness programmes conducted for value chain partners on any of the Principles during the financial year:</p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="awareness_programmes_conducted">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th className="text-align" width="25%">Total number of Awareness programmes held</th>
                                <th className="text-align">Topics/ principles covered under the training </th>
                                <th className="text-align" width="25%">%age of value chain partners covered ( by value of business done with such partners ) under the awareness programmes</th>
                                <th width="3%" onClick={() => {
                                  push({
                                    number: "",
                                    topics: "",
                                    percentage: ""
                                  });
                                  setData({
                                    ...data, awareness_programmes_conducted: [
                                      ...data.awareness_programmes_conducted,
                                      {
                                        number: "",
                                        topics: "",
                                        percentage: ""
                                      }
                                    ]
                                  });
                                }
                                }><i className="bx bxs-plus-circle mdi-add-btn" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.awareness_programmes_conducted?.length > 0 && values?.awareness_programmes_conducted?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">
                                    <Field
                                      name={`awareness_programmes_conducted[${index}].number`}
                                      placeholder="Total number"
                                      type="number"
                                      onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                      onWheel={(e) => e.target.blur()}
                                      className="form-control" />

                                  </td>

                                  <td className="text-align">
                                    <Field
                                      as="textarea"
                                      name={`awareness_programmes_conducted[${index}].topics`}
                                      placeholder="Topics/ principles"
                                      className="form-control"
                                      value={data.awareness_programmes_conducted[index].topics}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Topics/ principles covered under the training",
                                          name: `awareness_programmes_conducted[${index}].topics`,
                                          value: _get(data, `awareness_programmes_conducted[${index}].topics`, "")
                                        });
                                        tog_center();
                                      }}
                                    />

                                  </td>
                                  <td className="text-align">
                                    <Field
                                      name={`awareness_programmes_conducted[${index}].percentage`}
                                      placeholder="% of value chain partners"
                                      type="number"
                                      onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                      onWheel={(e) => e.target.blur()}
                                      className="form-control" />

                                  </td>
                                  {values?.awareness_programmes_conducted?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.awareness_programmes_conducted.filter((item, i) => index !== i);
                                    draftSebiData({ ...draftReport, principle_1: { ...data, awareness_programmes_conducted: newr } });
                                    setData({ ...data, awareness_programmes_conducted: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn" />
                                  </td>}
                                </tr>
                              ))}
                            </tbody>
                          </>

                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.awareness_programmes_conducted?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "awareness_programmes_conducted" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.awareness_programmes_conducted?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "awareness_programmes_conducted" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "awareness_programmes_conducted" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">2. Does the entity have processes in place to avoid/ manage conflict of interests involving members of
                    the Board? (Yes/No) If Yes, provide details of the same</p>
                    <RadioButton
                      name="avoid_manage_conflict_of_interests.entity_have"
                      value={_get(data, "avoid_manage_conflict_of_interests.entity_have", "")}
                      onChange={onHandleChange}
                      id="radio_94button" />

                    <TextArea
                      name="avoid_manage_conflict_of_interests.details"
                      value={_get(data, "avoid_manage_conflict_of_interests.details", "")}
                      onChange={onHandleChange}
                    />

                    <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => {
                            onSectionChange(8);
                            onSectionToggle("sectionB", 8);
                          }}>Previous</button>
                      </div>
                      <div>
                        <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => onSectionChange(10)}>Next</button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>

  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionC?.principle_1,
    previousData: state.NewReportReducer?.sebiForm?.previousYearData?.sectionC?.principle_1,
    prefillData: state.NewReportReducer?.sebiForm?.sebiPrefil,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.principle_1,
    filesData: state.NewReportReducer?.draftFiles?.principle_1
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(Principle1);
