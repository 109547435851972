/*eslint-disable*/
import React from "react";
import dateFormat from "dateformat";
import { Dots } from "react-activity";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { AxiosGet } from "common/axiosService";
import { Card, CardBody, Col, Table, Button } from "reactstrap";

const ActivityBRSR = ({ loading, setLoading, toast }) => {
  const [savedraft, setSavedraft] = useState([]);
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;

  /*getting data on mounting fase*/
  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/get-all-sebi-data`);
        if (response && response.statusCode === 200) {
          setSavedraft(response.data);
          setLoading((loading) => ({
            ...loading, brsrtimeline: true
          }));
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, []);
  const asand = savedraft && savedraft.filter((list) => list.isReportComplete);

  return (
    <React.Fragment>
      <Col lg={6}>
        <Card>
          <CardBody>
            <div className="title">
              <h5>Completed Reports</h5>
            </div>
            {loading.brsrtimeline ?
              <div className="table-responsive h-315 mt-3">
                <Table className="table table-nowrap align-middle table-hover mb-0">
                  <tbody>
                    {
                      asand.length >= 1
                        ? asand.map((file, i) => {
                          if (i > 4) {
                            return;
                          }
                        return (
                          <tr key={"_file_" + i}>
                            <td>
                              <h5 className="font-size-14 mb-1">
                                <Link to="#" className="text-dark">BRSR REPORT-{file.year}</Link>
                              </h5>
                              <p className="text-muted mb-0">Last Update : {dateFormat(`${file.modifiedAt}`, "mmm dS yyyy, h:MM TT")}</p>
                            </td>
                            <td width="3%" className="text-align">
                              {file.status === "Approve" && <h4> <span className="badge bg-success">Approved</span></h4>}
                              {file.status === "Pending" && <h4> <span className="badge bg-warning">Sent for Review</span> </h4>}
                              {file.status === "Reject" && <h4> <span className="badge bg-danger">Rejected</span> </h4>}
                            </td>
                            <td width="3%">
                              <Link to={`/admin/report/brsr/${file.year}`} className="text-success p-1">
                                <Button color="light" className="btn btn-soft-success">Edit</Button>
                              </Link>
                            </td>
                            <td width="3%" className="text-align">
                              <Link to={`/admin/supportingDoc/${file.year}`}>
                              <i className="dripicons-paperclip"></i>
                              </Link>
                            </td>
                            <td width="3%" className="text-align">
                              <a className="text-dark" onClick={async () => {
                                const userToken = localStorage.getItem("accessToken");
                                try {
                                  const response = await AxiosGet(`${apipath}/download-brsr-pdf/${file.filePath}/${userToken}`);
                                  if (response && response.statusCode === 200) {
                                    let a = document.createElement("a");
                                    a.download = `BRSR-Report${file.year}.pdf`;
                                    a.href = `data:application/pdf;base64,${response.data[0]}`;
                                    a.click();
                                    a.remove();
                                  }
                                } catch (error) {
                                  toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                }
                              }}>
                                <i className="bx bx-download h3 m-0" />
                              </a>
                            </td>
                          </tr>
                        )}
                        ):
                        <h4 className="react-activity">You have not generated any reports yet</h4>
                    }

                  </tbody>
                </Table>
                {asand.length >= 1 && 
                <div className="user-btn">
                <Link to={"/admin/completed-reports"} className="add-location-btn">
                  <button type="button" className="btn btn-primary waves-effect waves-light text-right">View More</button>
                </Link>
            </div>
                
                }
              </div>
              :
              <div className="react-activity">
                <Dots color="#3C7C44" size={20} speed={0.6} />
              </div>
            }

          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};
export default ActivityBRSR;
