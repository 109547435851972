import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import SidebarContent from "./SidebarContent";

// Images
import logo_lg from "../../assets/images/logo-lg.png";
import logo_sm from "../../assets/images/logo-sm.png";

const Sidebar = props => {
  const session = JSON.parse(localStorage.getItem("authUser"))?.userData?.user?.role;
  const role = session.toLowerCase();
  return(
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to={`/${role}/dashboard`} className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo_sm} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logo_lg} alt="" height="45" />
            </span>
          </Link>

          {/* <Link to="/" className="logo logo-light">
          <span className="logo-sm">
            <img src={logo_sm} alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src={logo_lg} alt="" height="45" />
          </span>
        </Link> */}
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );};

Sidebar.propTypes = {
  type: PropTypes.string
};

const mapStatetoProps = state => ({
  layout: state.Layout
});

export default connect(mapStatetoProps, {})(withRouter(withTranslation()(Sidebar)));
