import React, { Component } from "react";
import classnames from "classnames";
import { Card } from "reactstrap";
import CanvasJSReact from "./canvasjs/canvasjs.react";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class App extends Component {
  constructor (props) {
    console.log("==========>propsconsolidate", props);
    super(props);
    this.options = {};
    this.initializeChart = this.initializeChart.bind(this);
    this.toggleDataSeries = this.toggleDataSeries.bind(this);
  }
  toggleDataSeries (e){
    if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else{
      e.dataSeries.visible = true;
    }
    this.chart.render();
  }

  componentDidMount () {
    this.initializeChart(this.props.items);
  }

  initializeChart = (chartData) => {
    const colors = [ "#3f7e44", "#d2df64", "#4c9f38", "#99b951", "#4d9953", "#6bc355", "#2d5e21", "#51aa3c", "#86a442", "#86a442", "#aebe27", "#88941f"];
    const dataPoints = chartData.map((item, index) => {
      item.type= "stackedColumn",
      item.color= colors[index],
      item.showInLegend= true,
      item.dataPoints = item.dataPoints.map((subItem) => {
        subItem.label = subItem.year;
        subItem.y = subItem.total;
        return subItem;
      });
      return item;
    });
    const chart = this.chart;
    chart.options = {
      animationEnabled: true,
      exportEnabled: true,
      title: {
        fontFamily: "verdana"
      },
      axisY: {
        title: "kg CO2 e",
        valueFormatString: "#,##,###",
        gridThickness: 0,
        includeZero: false,
        lineThickness: 1,
        // labelFontColor: "#366702",
        titleFontSize: 14,
        fontFamily: "Poppins",
        fontWeight: "600"
      },
      axisX: {
        labelWrap: true,
        // labelFontColor: "#366702",
        labelAngle: 0,
        labelFontSize: 14,
        showLabel: false,
        fontWeight: "600",
        fontFamily: "Poppins"
      },
      toolTip: {
        shared: true,
        reversed: true
      },
      legend: {
        reversed: true,
        fontSize: 14,
        cursor: "pointer",
        itemclick: this.toggleDataSeries
      },
      data: dataPoints
    };
    chart.render();
  }
  render () {
    return (
      <Card
        className={classnames({ "shadow-chart": !this.props?.standAloneChart })}
        style={{
          paddingTop: "25px",
          paddingBottom: "14px",
          paddingLeft: "14px",
          borderRadius: 8
        }}
        key={this.props.index}
      >
        <CanvasJSChart
          options={this.options}
          onRef={ref => {
            this.chart = ref;
          }}
        />
        {/* <div className="mb-1 predictive_actions">
          <Label style={{ fontSize: "12px" }}>Chart type :</Label>
          <select
            className="inline-block form-select"
            onChange={this.change}
          >
            <option value="column">Column</option>
            <option value="bar">Bar</option>
          </select>
        </div> */}
      </Card>
    );
  }
}
export default App;