import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  RESEND_VERIFY_LINK
} from "./actionTypes";
import { resendVerifyLinkSuccess, resendVerifyLinkFailed } from "./actions";

import {
  postResendVerifyLink
} from "../../../helpers/backend_helper";

function* resendVerifyLink ({ payload: { data } }) {
  try {
    const response = yield call(postResendVerifyLink, { email: data });

    if(response.status === false){
      yield put(resendVerifyLinkFailed(response));
      return response;
    }
    yield put(resendVerifyLinkSuccess(response));
  } catch (error) {
    yield put(resendVerifyLinkFailed(error));
  }
}

export function* watchResendVerifyLink () {
  yield takeEvery(RESEND_VERIFY_LINK, resendVerifyLink);
}

function* resendVerifySaga () {
  yield all([fork(watchResendVerifyLink)]);
}

export default resendVerifySaga;
