import React from "react";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";

import Brsrjson from "../Sebidata.json";
import TableInput from "components/Inputs/TableInput";
import { updateSebiData, draftSebiData } from "../../../../store/newReport/actions";

const CompanyDetails = (props) => {
  const initialState = Brsrjson.sectionA.company_details;
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, onSectionChange, oldData } = props;

  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData ? draftData ? oldData : oldData : initialState);

  useEffect(() => {
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (oldData) {
      setData(oldData);
    }
  }, [newReport, oldData]);

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, company_details: data });
    setData({ ...data });
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card posifix">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="d-sm-flex align-items-center justify-content-between"
                  style={{ borderBottom: "1px solid #ccc", paddingBottom: "15px", marginBottom: "30px" }}>
                  <h4 className="mb-sm-0 font-size-18">I.	Details of the listed entity</h4> </div>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                _set(sebiCurrentData, "sectionA.company_details", data);
                updateSebiData(sebiCurrentData);
                draftSebiData({ ...draftReport, company_details: false });
              }}>
              <table className="table table-bordered mb-0">
                <tbody>
                  <tr>
                    <td className="text-align" width="3%">1.</td>
                    <td className="text-align-left">Corporate Identity Number (CIN) of the Listed Entity</td>
                    <TableInput
                      onChange={onHandleChange}
                      name="cin"
                      value={_get(data, "cin", "")}
                    />
                  </tr>
                  <tr>
                    <td className="text-align">2.</td>
                    <td className="text-align-left">Name of the Listed Entity</td>
                    <TableInput
                      onChange={onHandleChange}
                      name="name_of_company"
                      value={_get(data, "name_of_company", "")}
                    />
                  </tr>
                  <tr>
                    <td className="text-align">3.</td>
                    <td className="text-align-left">Year of incorporation</td>
                    <TableInput
                      onChange={onHandleChange}
                      name="year_of_incorporation"
                      value={_get(data, "year_of_incorporation", "")}
                    />
                  </tr>
                  <tr>
                    <td className="text-align">4.</td>
                    <td className="text-align-left">Registered office address</td>
                    <TableInput
                      onChange={onHandleChange}
                      name="registered_office"
                      value={_get(data, "registered_office", "")}
                    />
                  </tr>
                  <tr>
                    <td className="text-align">5.</td>
                    <td className="text-align-left">Corporate address</td>
                    <TableInput
                      onChange={onHandleChange}
                      name="corporate_address"
                      value={_get(data, "corporate_address", "")}
                    />
                  </tr>
                  <tr>
                    <td className="text-align">6.</td>
                    <td className="text-align-left">E-mail</td>
                    <TableInput
                      onChange={onHandleChange}
                      name="email"
                      value={_get(data, "email", "")}
                    />
                  </tr>
                  <tr>
                    <td className="text-align">7.</td>
                    <td className="text-align-left">Telephone</td>
                    <TableInput
                      onChange={onHandleChange}
                      name="telephone"
                      value={_get(data, "telephone", "")}
                    />
                  </tr>
                  <tr>
                    <td className="text-align">8.</td>
                    <td className="text-align-left">Website</td>
                    <TableInput
                      onChange={onHandleChange}
                      name="website"
                      value={_get(data, "website", "")}
                    />
                  </tr>
                  <tr>
                    <td className="text-align">9.</td>
                    <td className="text-align-left">Financial year for which reporting is being done </td>
                    <TableInput
                      onChange={onHandleChange}
                      name="financial_year"
                      value={_get(data, "financial_year", "")}
                    />
                  </tr>
                  <tr>
                    <td className="text-align">10.</td>
                    <td className="text-align-left">Name of the Stock Exchange(s) where shares are listed</td>
                    <TableInput
                      onChange={onHandleChange}
                      name="name_of_the_stock_exchange"
                      value={_get(data, "name_of_the_stock_exchange", "")}
                    />
                  </tr>
                  <tr>
                    <td className="text-align">11.</td>
                    <td className="text-align-left">Paid-up Capital</td>
                    <TableInput
                      onChange={onHandleChange}
                      name="paid_up_capital"
                      value={_get(data, "paid_up_capital", "")}
                    />
                  </tr>
                  <tr>
                    <td className="text-align">12.</td>
                    <td className="text-align-left">Name and contact details (telephone, email address) of the person who may be contacted in case of any queried on the BRSR report</td>
                    <TableInput
                      onChange={onHandleChange}
                      name="name_and_contact_details"
                      value={_get(data, "name_and_contact_details", "")}
                    />
                  </tr>
                  <tr>
                    <td className="text-align">13.</td>
                    <td className="text-align-left" width="67%">Reporting boundary - Are the disclosures under this report made on a
                      standalone basis (i.e. only for the entity) or on a consolidated basis (i.e. for the entity
                      and all the entities which form a part of its consolidated financial statements, taken together).</td>

                    <td>
                      <select
                        onChange={onHandleChange}
                        name="reporting_boundary"
                        value={_get(data, "reporting_boundary", "")}
                        className="form-select"
                      >
                        <option value="">Select Option</option>
                        <option value="Standalone basis">Standalone basis</option>
                        <option value="Consolidated basis">Consolidated basis</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <div className="mt-3 mb-3">
                  <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                  <button type="button" className="btn btn-primary w-md "
                    onClick={() => onSectionChange(2)}>Next</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionA?.company_details,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.company_details
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData };

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);