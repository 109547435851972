/* eslint-disable */
"use strict";
function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}

var React = require("react");
var React__default = _interopDefault(React);
var PropTypes = _interopDefault(require("prop-types"));

var classCallCheck = function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
};

var createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) { descriptor.writable = true; }
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

var defineProperty = function (obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
};

var _extends =
  Object.assign ||
  function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

var inherits = function (subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError(
      "Super expression must either be null or a function, not " +
      typeof superClass
    );
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
};

var possibleConstructorReturn = function (self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }

  return call && (typeof call === "object" || typeof call === "function")
    ? call
    : self;
};

var toConsumableArray = function (arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++)
      arr2[i] = arr[i];

    return arr2;
  } else {
    return Array.from(arr);
  }
};

var updateObject = function updateObject(oldObject, updatedProperties) {
  return _extends({}, oldObject, updatedProperties);
};

var checkValidity = function checkValidity(name, value, rules) {
  var isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
    isValid ? (rules.error = "") : (rules.error = name + " is required");
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
    isValid
      ? (rules.error = "")
      : value.trim() === ""
        ? (rules.error = name + " is required")
        : (rules.error =
          name +
          " should be be atleast " +
          rules.minLength +
          " characters long");
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
    isValid
      ? (rules.error = "")
      : value.trim() === ""
        ? (rules.error = name + " is required")
        : (rules.error =
          name + " should be be atmost " + rules.maxLength + " characters long");
  }

  if (rules.isEmail) {
    var pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;

    isValid
      ? (rules.error = "")
      : value.trim() === ""
        ? (rules.error = name + " is required")
        : (rules.error = name + " is invalid");
  }
  if (rules.isPan) {
    var _pattern = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    isValid = _pattern.test(value) && isValid;
    isValid
      ? (rules.error = "")
      : value.trim() === ""
        ? (rules.error = name + " is required")
        : (rules.error = name + " is invalid");
  }

  if (rules.rejex) {
    var _pattern2 = new RegExp(rules.rejex);
    isValid = _pattern2.test(value) && isValid;
    isValid
      ? (rules.error = "")
      : value.trim() === ""
        ? (rules.error = name + " is required")
        : (rules.error = name + " is invalid");
  }

  if (rules.isNumeric) {
    var _pattern3 = /^((?!(0))[0-9]*)$/;
    isValid = _pattern3.test(value) && isValid;
    isValid
      ? (rules.error = "")
      : value.trim() === ""
        ? (rules.error = name + " is required")
        : value.length > rules.maxLength && _pattern3.test(value)
          ? (rules.error =
            name + " should be be atmost " + rules.maxLength + " characters long")
          : value.length < rules.minLength && _pattern3.test(value)
            ? (rules.error =
              name +
              " should be be atleast " +
              rules.minLength +
              " characters long")
            : (rules.error =
              name + " should be only numeric and first character can not be zero");
  }
  return isValid;
};

var input = function input(props) {
  var inputElement = null;
  switch (props.elementType) {
    case "input":
      inputElement = React__default.createElement(
        "div",
        { className: "col-6 mt-3" },
        React__default.createElement(
          "label",
          { className: "form-label" },
          props.elementConfig.placeholder
        ),
        React__default.createElement(
          "input",
          _extends({}, props, props.elementConfig, {
            value: props.value,
            onChange: props.changed,
            className: "form-control",
            accept: ".png, .jpg, .jpeg,.pdf"
          })
        ),
        props.elementConfig.type === "file" &&
        React__default.createElement(
          "span",
          { className: "red form-label" },
          "Maximum Size 1 Mb"
        )
      );
      break;
    case "span":
      inputElement = React__default.createElement(
        "div",
        { className: "col-6 mt-3" },
        React__default.createElement(
          "label",
          { className: "form-label" },
          props.elementConfig.placeholder
        ),
        React__default.createElement(
          "input",
          _extends({}, props, props.elementConfig, {
            disabled: true,
            value: props.value,
            onChange: props.changed,
            className: "form-control hidden"
          })
        )
      );

      break;

    case "textarea":
      inputElement = React__default.createElement(
        "div",
        { className: "col-6 mt-3" },
        React__default.createElement(
          "label",
          { className: "form-label" },
          props.elementConfig.placeholder
        ),
        React__default.createElement(
          "textarea",
          _extends({}, props, props.elementConfig, {
            value: props.value,
            onChange: props.changed,
            className: "form-control"
          })
        )
      );
      break;

    default:
      inputElement = React__default.createElement(
        "input",
        _extends(
          {
            props: props
          },
          props.elementConfig,
          {
            onChange: props.changed
          }
        )
      );
  }

  return React__default.createElement(
    React__default.Fragment,
    null,
    inputElement,
    props.invalid && props.shouldvalidate && props.touched
    // &&
    // React__default.createElement("span", props, props.shouldvalidate.error)
  );
};

var button = function button(props) {
  return React__default.createElement(
    "div",
    { className: "mt-4" },
    null,
    React__default.createElement(
      "button",
      _extends({}, props, {
        disabled: props.disabled,
        onClick: props.clicked,
        className: "btn btn-primary"
      }),
      props.children
    )
  );
};

var DynamicFormGenerator = (function (_Component) {
  inherits(DynamicFormGenerator, _Component);

  function DynamicFormGenerator(props) {
    classCallCheck(this, DynamicFormGenerator);

    var _this = possibleConstructorReturn(
      this,
      (
        DynamicFormGenerator.__proto__ ||
        Object.getPrototypeOf(DynamicFormGenerator)
      ).call(this, props)
    );

    _this.state = {
      formIsValid: false
    };

    _this.formHandler = function (event) {
      event.preventDefault();

      var formData = {};
      let formClearData = _this.state.formFields;
      var _loop = function _loop(formElementIdentifier) {
        _this.state.formFields[formElementIdentifier];
        formData[formElementIdentifier] = _this.state.formFields[formElementIdentifier].value;
        if ( _this.state.formFields[formElementIdentifier].elementType !== "span" ) {
          formClearData[`${formElementIdentifier}`].value = "";
        }
        if (
          _this.state.formFields[formElementIdentifier].elementType === "input" &&
          _this.state.formFields[formElementIdentifier].elementConfig.type === "number"
        ) {
          if (_this.state.formFields[formElementIdentifier].value) {
            formData[formElementIdentifier] = Number( _this.state.formFields[formElementIdentifier].value );
          }
        }
        if ( _this.state.formFields[formElementIdentifier].elementType === "checkbox" ) {
          _this.state.formFields[formElementIdentifier].selectedCheckbox = [];
          _this.state.formFields[
            formElementIdentifier
          ].elementConfig?.options.forEach(function (element) {
            if (element.checked) {
              _this.state.formFields[
                formElementIdentifier
              ].selectedCheckbox.push(element.value);
            }
          });
          formData[formElementIdentifier] =
            _this.state.formFields[formElementIdentifier].selectedCheckbox;
        }

        if (_this.state.formFields[formElementIdentifier].selectedFileObj) {
          formData[formElementIdentifier] =
            _this.state.formFields[formElementIdentifier].selectedFileObj;
        }
      };

      for (var formElementIdentifier in _this.state.formFields) {
        _loop(formElementIdentifier);
      }

      _this.props.onFormSubmit(formData);
      _this.setState({
        formFields: formClearData,
        formIsValid: false
      });
    };

    _this.inputChangedHandler = function ( elementType, placeholder, event, inputIdentifier) {
      event.persist();
      var value = event.target.value;
      if (event.target.files) {
        _this.state.formFields[inputIdentifier].selectedFileObj =
        event.target.files[0];
        _this.props.formFields[inputIdentifier].selectedFileObj =
          event.target.files[0];
      }
      _this.props.formFields[inputIdentifier].value = value;
      var updatedFormElement = updateObject(
        _this.state.formFields[inputIdentifier],
        {
          value: value,
          valid: checkValidity(
            placeholder,
            value,
            _this.state.formFields[inputIdentifier].validation
          ),
          touched: true
        }
      );
      var updatedFormFields = updateObject(
        _this.state.formFields,
        defineProperty({}, inputIdentifier, updatedFormElement)
      );
      if (elementType === "checkbox") {
        var copyArray = [].concat(
          toConsumableArray(
            _this.state.formFields[inputIdentifier].elementConfig.options
          )
        );
        var modifiedArray = copyArray.map(function (option) {
          if (value === option.value) {
            option.checked = !option.checked;
          }
        });
        var _updatedFormElement = updateObject(
          _this.state.formFields[inputIdentifier].elementConfig.options,
          {
            options: modifiedArray
          }
        );
        var _updatedFormFields = updateObject(
          _this.state.formFields,
          defineProperty({}, inputIdentifier, _updatedFormElement)
        );
      }
      var formIsValid = true;
      for (var _inputIdentifier in updatedFormFields) {
        formIsValid = updatedFormFields[_inputIdentifier].valid && formIsValid;
      }
      _this.setState({
        formFields: updatedFormFields,
        formIsValid: formIsValid
      });
    };

    return _this;
  }

  createClass(DynamicFormGenerator, [
    {
      key: "componentDidMount",
      value: function componentDidMount() {
        var formFields = this.props.formFields;
        this.setState({ formFields: formFields });
      }
    },
    {
      key: "render",
      value: function render() {
        var _this2 = this;
        const formFields =  this.props.formFields;
        // const formFields =  this.state.formFields;
        var onPrifillDataChange =  this.props?.onDataChange;
        var prefillDataaaaa = this.props?.prefillData;
        var formElementsArray = [];
        for (var key in formFields) {
          formElementsArray.push({
            id: key,
            config: formFields[key]
          });
        }
        var form = React__default.createElement(
          "form",
          { onSubmit: this.formHandler },
          React__default.createElement(
            "div",
            { className: "row" },
            formElementsArray.map(function (formElement) {
              if(prefillDataaaaa && formElement.id === "unit"){
                return React__default.createElement(input, {
                  key: formElement.id,
                  elementType: formElement.config.elementType,
                  elementConfig: formElement.config.elementConfig,
                  value: prefillDataaaaa.dataPoints.ReadingValue.units,
                  invalid: !formElement.config.valid,
                  shouldvalidate: formElement.config.validation,
                  touched: formElement.config.touched,
                  changed: function changed(event) {
                    onPrifillDataChange(formElement.id, event.target.value);
                    return _this2.inputChangedHandler(
                      formElement.config.elementType,
                      formElement.config.elementConfig.placeholder,
                      event,
                      formElement.id
                    );
                  },
                }); 
              } else if(prefillDataaaaa && formElement.id === "value"){
                return React__default.createElement(input, {
                  key: formElement.id,
                  elementType: formElement.config.elementType,
                  elementConfig: formElement.config.elementConfig,
                  value: prefillDataaaaa.dataPoints.ReadingValue.value,
                  invalid: !formElement.config.valid,
                  shouldvalidate: formElement.config.validation,
                  touched: formElement.config.touched,
                  changed: function changed(event) {
                    onPrifillDataChange(formElement.id, event.target.value);
                    return _this2.inputChangedHandler(
                      "text",
                      "Select Unit",
                      event,
                      "value"
                    );
                  },
                }); 
              } else if(prefillDataaaaa && formElement.id === "comment"){
                return React__default.createElement(input, {
                  key: formElement.id,
                  elementType: formElement.config.elementType,
                  elementConfig: formElement.config.elementConfig,
                  value: prefillDataaaaa.dataPoints.comment ? prefillDataaaaa.dataPoints.comment: "",
                  invalid: !formElement.config.valid,
                  shouldvalidate: formElement.config.validation,
                  touched: formElement.config.touched,
                  changed: function changed(event) {
                    onPrifillDataChange(formElement.id, event.target.value);
                    return _this2.inputChangedHandler(
                     "textaera",
                     "Add Comment",
                      event,
                     "comment"
                    );
                  },
                }); 
              } else{
                return React__default.createElement(input, {
                  key: formElement.id,
                  elementType: formElement.config.elementType,
                  elementConfig: formElement.config.elementConfig,
                  value: formElement.config.value,
                  invalid: !formElement.config.valid,
                  shouldvalidate: formElement.config.validation,
                  touched: formElement.config.touched,
                  changed: function changed(event) {
                    return _this2.inputChangedHandler(
                      formElement.config.elementType,
                      formElement.config.elementConfig.placeholder,
                      event,
                      formElement.id
                    );
                  },
                });
              }
            })
          ),
          React__default.createElement(
            "div",
            { className: "row" },
            React__default.createElement(
              button,
              {
                disabled: this.props.buttonDisable
                  ? this.props.buttonDisable
                  : !this.state.formIsValid
              },
              (prefillDataaaaa && (prefillDataaaaa.dataPoints.ReadingValue.value || prefillDataaaaa.dataPoints.ReadingValue.unit ||  prefillDataaaaa.dataPoints.comment) ) ?  "Update" :"Submit"
            )
          )
        );

        return React__default.createElement("div", { className: "row" }, form);
      }
    }
  ]);
  return DynamicFormGenerator;
})(React.Component);

DynamicFormGenerator.propTypes = {
  text: PropTypes.object
};

module.exports = DynamicFormGenerator;
