import React, { useMemo } from "react";
import { AxiosGet } from "common/axiosService";
import { Action, Date, Role, Name } from "./CryptoCol";
// import { toast } from "react-toastify";
import IsRoleAuth from "pages/Authentication/IsRoleAuth";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// import TableContainer from "components/Common/TableContainer";
import Breadcrumbs from "components/Common/Breadcrumb";
// import { Bounce } from "react-activity";
import PokemonTable from "./PokemonTable";

const Logs = () => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;

  // const [dataloading, setDataLoading] = useState(false);

  const fetchPokemonData = async (page, pageSize, pageFilter, pageSortBy) => {
    let paramStr = "";
    if( pageFilter.trim().length > 1 ) {
      paramStr = `&search=${pageFilter}`;
    }
    if( pageSortBy.length > 0 ) {
      const sortParams = pageSortBy[0];
      const sortyByDir = sortParams.desc ? "desc" : "asc";
      paramStr = `${paramStr}&sortby=${sortParams.id}&direction=${sortyByDir}`;
    }
    try {
      const response = await AxiosGet(`${apipath}/get-all-logs?page=${page}&limit=${pageSize}${paramStr}`);
      if (response && response.statusCode === 200) {
        // setDataLoading(true);
        return response.data[0];
      } else {
        // setDataLoading(true);
        return;
      }
    } catch (e) {
      throw new Error(`API error:${e?.message}`);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const response = await AxiosGet(`${apipath}/get-all-logs`);
  //       if (response && response.statusCode === 200) {
  //         setUserData(response.data);
  //         setDataLoading(true);
  //       } else {
  //         setDataLoading(true);
  //         return;
  //       }
  //     } catch (error) {
  //       toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
  //     }
  //   })();
  // }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No",
        accessor: "",
        width: 50,
        minWidth: 60,
        filterable: true,
        Cell: (row) => <div>{Number(row.row.id) + 1}</div>
      },
      {
        Header: "Date",
        accessor: "modifiedAt",
        filterable: true,
        width: 120,
        minWidth: 100,
        Cell: cellProps => <Date {...cellProps} />
      },
      {
        Header: "Role",
        accessor: "performerId.role",
        filterable: true,
        width: 100,
        minWidth: 100,
        Cell: cellProps => <Role {...cellProps} />
      },
      {
        Header: "Email",
        accessor: "performerId.email",
        filterable: true,
        width: 100,
        Cell: cellProps => <Name {...cellProps} />
      },
      {
        Header: "Action",
        accessor: "actionDone",
        filterable: true,
        width: 500,
        Cell: cellProps => <Action {...cellProps} />
      }
    ],
    []
  );

  return (
    <React.Fragment>
      <IsRoleAuth />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Greenalytics" breadcrumbItem="All Activity" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PokemonTable columns={columns} fetchPokemonData={fetchPokemonData} isAddCustList={false} isActiveMessage={true}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
  // : <div className="react-activity" style={{ height: "100vh" }}>
  //   <Bounce color="#3C7C44" size={30} speed={0.6} />
  // </div>;
};

export default Logs;
