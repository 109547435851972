import React from "react";
import { Redirect } from "react-router-dom";

import { ROLES } from "common/constants";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
// import EmailRegister from "../pages/Authentication/RegisterForm/EmailRegister";

// Dashboard
import Report from "components/Report";
import Social from "pages/Admin/Social/index";
import Index from "pages/Admin/Analytics/index";
import Region from "pages/Authentication/Region";
import UserList from "pages/Admin/Users/UserList";
import Governance from "pages/Admin/Governance/index";
import UserDashboard from "../pages/User/Dashboard/index";
import BulkUpload from "pages/Admin/UploadData/BulkUpload";
import AdminDashboard from "../pages/Admin/Dashboard/index";
import ReportSEBI from "pages/Admin/Brsr/modal";
import RegisterForm from "pages/Authentication/RegisterForm";
import ResetPassword from "pages/Authentication/ResetPassword";
import CompanyProfile from "pages/Admin/CompanyProfile/Profile";
import LocationsList from "pages/Admin/Locations/LocationsList";
import SuperAdminUserList from "pages/SuperAdmin/AllUsers/Users";
import SuperDashboard from "../pages/SuperAdmin/Dashboard/index";
import EmailVerify from "pages/Authentication/RegisterForm/EmailVerify";
import LinkExpired from "pages/Authentication/RegisterForm/LinkExpired";
import StakeholderDashboard from "../pages/Stakeholder/Dashboard/index";
import CompaniesList from "pages/SuperAdmin/AllCompanies/CompaniesList";
import CompanyDetails from "pages/SuperAdmin/CompanyDetails/CompanyDetails";
import DataInsertingForm from "../pages/SuperAdmin/Reports/CustomReport/Customreport";
import UiTabsAccordions from "pages/Admin/UploadData/SingleUpload/SingleUpload";
import CarbonFootprint from "pages/Admin/Carbon/SingleUpload/SingleUpload";
import Carbon from "pages/Admin/Carbon/index";
import BusinessInformation from "pages/Admin/BusinessInformation";
import SupportingDoc from "pages/Admin/SupportingDoc";
import Logs from "pages/Admin/Log/Logs";
import SuperadminLogs from "pages/SuperAdmin/Log/Logs";
import CompletedReports from "pages/Admin/TimeLineLog/Logs";
import DraftReports from "pages/Admin/TimeLineLog/Draft";
import CarbonDashboard from "pages/Admin/CarbonDashboard/index";
import ReportForm from "pages/Admin/Brsr/index";
import Billing from "pages/Admin/Billing/Billing";
import Integrations from "pages/Admin/Integrations/Integrations";
import YourCompany from "pages/Admin/CompanyProfile/YourCompany";
// import example from "pages/Admin/Brsr/index";

const superAdminRoutes = [
  { path: "/superadmin/dashboard", component: SuperDashboard },
  { path: "/superadmin/company/company-list", component: CompaniesList },
  { path: "/superadmin/company/view-company-details/:id", component: CompanyDetails },
  { path: "/superadmin/report", component: DataInsertingForm },
  { path: "/superadmin/users", component: SuperAdminUserList },
  { path: "/superadmin/logs", component: SuperadminLogs },
  { path: "/", exact: true, component: () => <Redirect to="/superadmin/dashboard" /> }
];

const adminRoutes = [
  { path: "/admin/dashboard", component: AdminDashboard },
  { path: "/admin/logs", component: Logs },
  { path: "/admin/users", component: UserList },
  { path: "/admin/locations", component: LocationsList },
  { path: "/admin/profile", component: CompanyProfile },
  { path: "/admin/reports", component: Report },
  { path: "/admin/analytics", component: Index },
  { path: "/admin/businessInformation", component: BusinessInformation },
  { path: "/admin/supportingDoc/:directyear", component: SupportingDoc },
  { path: "/admin/completed-reports", component: CompletedReports },
  { path: "/admin/draft", component: DraftReports },
  { path: "/admin/report", component: ReportSEBI },
  { path: "/admin/report/brsr/:year", component: ReportForm },
  { path: "/admin/upload-data/bulk-upload", component: BulkUpload },
  { path: "/admin/upload-data/single-upload", component: UiTabsAccordions },
  { path: "/admin/carbonDashboard", component: CarbonDashboard },
  { path: "/admin/carbon-footprint", component: CarbonFootprint },
  { path: "/admin/carbon-footprint/dashboard", component: Carbon },
  { path: "/admin/billing", component: Billing },
  { path: "/admin/integrations", component: Integrations },
  { path: "/admin/yourcompany", component: YourCompany },
  { path: "/", exact: true, component: () => <Redirect to="/admin/dashboard" /> }
];

const userRoutes = [
  { path: "/user/dashboard", component: UserDashboard },
  { path: "/user/profile", component: CompanyProfile },
  { path: "/user/reports", component: Report },
  { path: "/user/report/brsr", component: ReportSEBI },
  { path: "/user/upload-data/bulk-upload", component: BulkUpload },
  { path: "/user/upload-data/single-upload", component: UiTabsAccordions },
  { path: "/", exact: true, component: () => <Redirect to="/user/dashboard" /> }
];

const stakeholdersRoutes = [
  { path: "/stakeholder/dashboard", component: StakeholderDashboard },
  { path: "/stakeholder/users", component: UserList },
  { path: "/stakeholder/locations", component: LocationsList },
  { path: "/stakeholder/profile", component: CompanyProfile },
  { path: "/stakeholder/reports", component: Report },
  { path: "/stakeholder/analytics/environmental", component: Index },
  { path: "/stakeholder/analytics/social", component: Social },
  { path: "/stakeholder/analytics/governance", component: Governance },
  { path: "/stakeholder/report/sebi", component: ReportSEBI },
  { path: "/", exact: true, component: () => <Redirect to="/stakeholder/dashboard" /> }
];

const authProtectedRoutes = [
  {
    path: "/",
    exact: true,
    component: () =>
      JSON.parse(localStorage.getItem("authUser")).userData.user.role === ROLES.SUPERADMIN
        ? ( <Redirect to="/superadmin/dashboard" /> )
        : JSON.parse(localStorage.getItem("authUser")).userData.user.companyId === undefined
          ? ( <Redirect to="/login" /> )
          : JSON.parse(localStorage.getItem("authUser")).userData.user.role === ROLES.ADMIN
            ? ( <Redirect to="/admin/dashboard" /> )
            : JSON.parse(localStorage.getItem("authUser")).userData.user.role === ROLES.USER
              ? (<Redirect to="/user/dashboard" />)
              : JSON.parse(localStorage.getItem("authUser")).userData.user.role === ROLES.STAKEHOLDERS
                ? ( <Redirect to="/stakeholder/dashboard" /> )
                : (<Redirect to="/login" />)
  }
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:token", component: ResetPassword },
  // { path: "/register", component: EmailRegister },
  { path: "/register-company", component: RegisterForm },
  { path: "/email-verified/", component: EmailVerify },
  { path: "/link-expired/", component: LinkExpired },
  { path: "/region/", component: Region }
];

export {
  superAdminRoutes,
  adminRoutes,
  userRoutes,
  stakeholdersRoutes,
  publicRoutes,
  authProtectedRoutes
};
