import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Card, Col, DropdownToggle, DropdownMenu, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import Input from "components/Inputs/Input";
import FormUpload from "../Modalfileupload";
import RadioButton from "components/Inputs/RadioButton";
import { AxiosGet, AxiosPost } from "common/axiosService";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const CSRDetails = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionA.csr_details;
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, onSectionChange, oldData, getFile, filesData } = props;

  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);
  const [filedatachange, setFileDataChange] = useState(true);

  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionA", subSection: "csr_details", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect(() => {
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionA",
        "subSection": "csr_details"
      });
    }
  }, [newReport, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionA",
      "subSection": "csr_details"
    });
  }, [filedatachange]);

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, csr_details: data });
    setData({ ...data });
  };

  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionA",
        "subSection": "csr_details",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionA",
          "subSection": "csr_details"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  return (
    <React.Fragment>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className=" d-sm-flex align-items-center justify-content-between"
                    style={{ borderBottom: "1px solid #ccc", paddingBottom: "15px", marginBottom: "30px" }}>
                    <h4 className="mb-sm-0 font-size-18">CSR File</h4> </div>
                </div>
              </div>
              <form
                onChange={onHandleChange}
                onSubmit={(e) => {
                  e.preventDefault();
                  _set(sebiCurrentData, "sectionA.csr_details", data);
                  updateSebiData(sebiCurrentData);
                  draftSebiData({ ...draftReport, csr_details: false });
                }}>
                <div className="dis-flex mt-1">
                  <p> 22. (i) Whether CSR is applicable as per section 135 of Companies Act, 2013 </p>
                  <RadioButton
                    name="csr_is_applicable"
                    value={_get(data, "csr_is_applicable", "")}
                    onChange={onHandleChange}
                    className="dis-center" id="radio_1button"
                  />
                </div>
                <div className="dis-flex mt-1">
                  <p className="top-9">(ii) Turnover (in Rs. Crores)</p>
                  <div className="dis-right">
                    <Input
                      type="number"
                      name="turnover"
                      value={_get(data, "turnover", "")}
                      onChange={onHandleChange}
                    />
                  </div>
                </div>
                <div className="dis-flex mt-2">
                  <p className="top-9">(iii) Net worth (in Rs. Crores)</p>
                  <div className="dis-right">
                    <Input
                      type="number"
                      name="networth"
                      value={_get(data, "networth", "")}
                      onChange={onHandleChange}
                    />
                  </div>
                </div>

                <Row className="mt-2 align-items-center">
                  {filesData?.csr_file?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <>
                        <Col xl={2} sm={4} key={index}>
                          <Card className="shadow-none border">
                            <div >
                              <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                <UncontrolledDropdown direction="left mt-2">
                                  <DropdownToggle
                                    color="white"
                                    className="btn btn-link text-muted mt-n2"
                                  >
                                    <i className="mdi mdi-dots-vertical"></i>
                                  </DropdownToggle>

                                  <DropdownMenu>
                                    <button type="button" className="dropdown-item"
                                      onClick={ async () => {
                                        try {
                                          const userToken = localStorage.getItem("accessToken");
                                          const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                          if (res && res.statusCode === 200 && res.data[0]) {
                                            let a = document.createElement("a");
                                            a.download = `${name}.${ext}`;
                                            a.href = `data:application/${ext};base64,${res.data[0]}`;
                                            a.click();
                                            a.remove();
                                            toast.success("Download Succesfully");
                                          }
                                        } catch (error) {
                                          toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                        }
                                      }}
                                    >Download</button>
                                    <button type="button" className="dropdown-item"
                                      onClick={() => handleDeleteFile(item, "csr_file" )}
                                    >Delete</button>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                              <div className="avatar-xs me-3 mb-3">
                                <div className="avatar-title bg-transparent rounded">
                                  {ext === "sheet"
                                    ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                    : ext === "png"
                                      ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                      : ext === "pdf"
                                        ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                        <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                  }
                                </div>
                              </div>
                              <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                            </div>
                          </Card>
                        </Col>
                      </>
                    );

                  })}

                  {filesData?.csr_file?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "csr_file" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "csr_file" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <button type="button" className="btn btn-primary w-md "
                      onClick={() => onSectionChange(5)}>Previous</button>{" "}
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                    <button type="button" className="btn btn-primary w-md "
                      onClick={() => onSectionChange(7)}>Next</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>;
    </React.Fragment>
  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionA?.csr_details,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.csr_details,
    filesData: state.NewReportReducer?.draftFiles?.csr_details
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(CSRDetails);