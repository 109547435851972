import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { get as isObject } from "lodash";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, CardBody, Card, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logo_sm from "../../assets/images/logo-sm.png";

import { API_URLS } from "common/constants";

const ResetPassword = () => {
  //meta title
  document.title = "Reset Password | Greenalytics";

  const history = useHistory();
  const { token } = new useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [eyeIcon, seteyeIcon] = useState({ newPassword: "", confirmPassword: "" });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { newPassword: "", confirmPassword: "" },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, "Password must have atleast 8 characters.")
        .matches(/^(?=.*[a-z])/, "Must contain atleast one lowercase characters.")
        .matches(/^(?=.*[A-Z])/, "Must contain atleast one uppercase characters.")
        .matches(/^(?=.*[0-9])/, "Must contain atleast one number")
        .matches(/^(?=.*[!@#%&])/, "Must contain atleast one special characters.")
        .required("Please Enter Your Password"),
      confirmPassword: Yup.string().when("newPassword", (newPassword, field) =>
        newPassword ? field.required().oneOf([Yup.ref("newPassword")]) : field
      )
    }),

    onSubmit: (values, actions) => {
      resetPasswordData(values);
      setTimeout(() => {
        actions.resetForm();
      }, 1000);
    }
  });

  const passToggleEye = val => {
    let el = document.getElementById(val);
    if (el.type === "password") {
      el.type = "text";
      seteyeIcon(prev => ({
        ...prev,
        [val]: "-off"
      }));
    } else {
      el.type = "password";
      seteyeIcon(prev => ({
        ...prev,
        [val]: ""
      }));
    }
  };

  const resetPasswordData = async value => {
    setIsLoading(true);
    try {
      let res = await axios.post(`${API_URLS.RESET_PASSWORD}/${token}`, value);
      let resJson = await res.data;
      if (resJson.statusCode === 200) {
        setTimeout(() => {
          setIsLoading(false);
          Swal.fire({
            html: `<div class='d-flex align-items-center'>
            <h5 class='m-0 text-primary'>${resJson.message}</h5>
             </div>`,
            padding: "2em",
            color: "#3c7c44",
            showConfirmButton: false,
            showCloseButton: true,
            closeButtonHtml: "<i class='mdi mdi-24px mdi-close-circle-outline text-dark'></i>"
          }).then(() => {
            history.push("/login");
          });
        }, 100);
      } else {
        setTimeout(() => {
          setIsLoading(false);
          Swal.fire({
            html: `<div class='d-flex align-items-center'>
            <h5 class='m-0 text-danger'>${resJson.message}</h5> </div>`,
            padding: "2em",
            color: "#c35555",
            showConfirmButton: false,
            showCloseButton: true,
            closeButtonHtml: "<i class='mdi mdi-24px mdi-close-circle-outline text-dark'></i>"
          });
        }, 100);
      }
    } catch (err) {
      console.error(err);
      setTimeout(() => {
        setIsLoading(false);
        Swal.fire({
          html: `<div class='d-flex align-items-center'>
            <h5 class='m-0 text-danger'>Something want wrong! Please Try again..</h5> </div>`,
          padding: "2em",
          color: "#c35555",
          showConfirmButton: false,
          showCloseButton: true,
          closeButtonHtml: "<i class='mdi mdi-24px mdi-close-circle-outline text-dark'></i>",
          timer: 3000
        });
      }, 100);
    }
  };
  const getFirstErrorKey = (object, keys = []) => {
    const firstErrorKey = Object.keys(object)[0];
    if (isObject(object[firstErrorKey])) {
      return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
    }
    return [...keys, firstErrorKey].join(".");
  };

  useEffect(() => {
    if (!validation.isValid && validation.submitCount !== 0 &&validation.isSubmitting) {
      const firstErrorKey = getFirstErrorKey(validation.errors);
      if (global.window.document.getElementsByName(firstErrorKey).length) {
        global.window.document.getElementsByName(firstErrorKey)[0].focus();
      }
    }
    if (!token || token === null) {
      window.location.replace("/login");
    }
  }, [validation.submitCount, validation.isValid, validation.errors, validation.isSubmitting]);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5" id="register">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Reset Password</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img
                        src={profileImg}
                        alt="pro-img"
                        className="img-fluid"
                      />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div
                        className="avatar-md profile-user-wid mb-4"
                        style={{ float: "left" }}
                      >
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo_sm}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2" style={{ clear: "both" }}>
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3 position-relative">
                        <Label for="newPassword" className="form-label">
                          New Password
                        </Label>
                        <Input
                          id="newPassword"
                          name="newPassword"
                          className="form-control password"
                          placeholder="Enter Password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.newPassword || ""}
                          invalid={
                            validation.touched.newPassword &&
                              validation.errors.newPassword
                              ? true
                              : false
                          }
                        />
                        <span onClick={() => passToggleEye("newPassword")}>
                          <i
                            className={`mdi mdi-eye${eyeIcon.newPassword}`}
                          ></i>
                        </span>
                        {validation.touched.newPassword &&
                          validation.errors.newPassword
                          ? (
                            <FormFeedback type="invalid">
                              {validation.errors.newPassword}
                            </FormFeedback>
                          )
                          : null}
                      </div>

                      <div className="mb-3 position-relative">
                        <Label for="confirmPassword" className="form-label">
                          Confirm Password
                        </Label>
                        <Input
                          id="confirmPassword"
                          name="confirmPassword"
                          className="form-control password"
                          placeholder="Enter Confirm Password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword &&
                              validation.errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        <span onClick={() => passToggleEye("confirmPassword")}>
                          <i
                            className={`mdi mdi-eye${eyeIcon.confirmPassword}`}
                          ></i>
                        </span>
                        {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword
                          ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirmPassword}
                            </FormFeedback>
                          )
                          : null}
                      </div>

                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading
                            ? (
                              <div
                                className="spinner-border text-white"
                                role="status"
                                style={{ width: "1.2rem", height: "1.2rem" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            )
                            : (
                              "Send"
                            )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Greenalytics. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Glasier Inc.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
