import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import increasing from "../../../assets/images/analytics.png";
import carbon from "../../../assets/images/carbon-footprint.png";
import business from "../../../assets/images/business-report-white.png";
import { AxiosGet } from "common/axiosService";
import { useState } from "react";
import { get } from "lodash";

const Cards = ({ toast }) => {
  const [count, setCount] = useState({});

  const apipath = process.env.REACT_APP_ADMIN_API_PATH;

  /*getting data on mounting fase*/
  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/get-brsr-and-carbon-count`);
        if (response && response.statusCode === 200) {
          setCount(response.data[0]);
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, []);

  return(
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Row>
            <Col xl={4}>
              <div className="table-box">
                <div className="card-table">
                  <div className="contain-box-top ">
                    <h1>BRSR</h1>
                    <div className="img-box">
                      <img src={business}/>
                    </div>
                  </div>
                  <div className="contain-box-top mt-15">
                    <h3>{get(count, "brsrTotalReport", 0).toLocaleString("en-IN")}</h3>
                    <Link to="/admin/report">
                      <button type="button"
                        className="btn btn-primary waves-effect waves-light">
                            Generate BRSR <i className="bx bx-right-arrow-alt"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4}>
              <div className="table-box">
                <div className="card-table box-second">
                  <div className="contain-box-top">
                    <h1>Analytics</h1>
                    <div className="img-box">
                      <img src={increasing}/>
                    </div>
                  </div>
                  <div className="contain-box-top mt-15">
                    <h3>0</h3>
                    <a href="#">
                      <button type="button"
                        className="btn btn-primary waves-effect waves-light">
                        View Analytics <i className="bx bx-right-arrow-alt"></i>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4}>
              <div className="table-box">
                <div className="card-table box-third">
                  <div className="contain-box-top">
                    <h1>Carbon Footprint</h1>
                    <div className="img-box">
                      <img src={carbon}/>
                    </div>
                  </div>
                  <div className="contain-box-top mt-15">
                    <h3>{get(count, "carbonEmmissionTotal", 0).toLocaleString("en-IN")} kg CO2e</h3>
                    <Link to="/admin/carbonDashboard">
                      <button type="button"
                        className="btn btn-primary waves-effect waves-light">
                        View Dashboard<i className="bx bx-right-arrow-alt"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Cards;
