/*eslint-disable*/
import React from "react";
import { Dots } from "react-activity";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { Card, Col, Row, CardBody } from "reactstrap";
import { AxiosGet } from "common/axiosService";
import activeuser from "../../../assets/images/active-user.png";

const ActiveUsers = ({ loading, setLoading, toast }) => {
  const [series, setSeries] = useState([]);
  const [lables, setLables] = useState([]);
  const [activeUsers, setActiveUsers] = useState(0);
  let [totalUsers, setTotalUsers] = useState(0);


  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  /*getting data on mounting fase*/
  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/get-total-users`);
        if (response && response.statusCode === 200) {
          const countData = response.data[0];
          setActiveUsers(response.data[0].activeUsers);
          setLoading((loading) => ({
            ...loading, user: true
          }));
          const data = {
            series: [],
            lables: []
          };
          if (countData.admin > 0) {
            totalUsers += countData.admin;
            data.series.push({ "value": countData.admin, "name": "Admin" });
            data.lables = [...data.lables, "Admin"];
          }
          if (countData.stakeholders > 0) {
            totalUsers += countData.stakeholders;
            data.series.push({ "value": countData.stakeholders, "name": "Stakeholders" });
            data.lables = [...data.lables, "Stakeholders"];
          }
          if (countData.users > 0) {
            totalUsers += countData.users;
            data.series.push({ "value": countData.users, "name": "Users" });

            data.lables = [...data.lables, "Users"];
          }
          setTotalUsers(totalUsers);
          setSeries(data.series);
          setLables(data.lables);
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, []);


  const options2 = {
    title : {
      text: `Active Users - ${activeUsers}`,
      subtext: `Invited Users - ${totalUsers - activeUsers}`,
      x:'center',
      textStyle: {
        color: "#3d7d42",
      },
      subtextStyle: {
        fontWeight:"bold",
        fontSize: "20px",
        color: "#3d7d42"
      }
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)"
    },
    // legend: {
    //   bottom: 2,
    //   left: 'center',
    //   data: lables
    // },
    legend: {
      orient: "vertical",
      left: "left",
      data: lables,
      textStyle: {
        size: "20px",
        color: ["#74788d"]
      }
    },
    color: ["#3f7e44", "#d2df64", "#4c9f38", "#99b951", "#4d9953", "#6bc355"],
    series: [
      {
        name: "Total Users",
        type: "pie",
        radius: "70%",
        center: ["50%", "60%"],
        data: series,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)"
          }
        }
      }
    ]
  };

  return(
    <React.Fragment>
      <Col md={12} xl={7} className="mt-4 mb-4">
        <Card className="fixheight">
          <CardBody className="active-box">
            <div className="title">
              <h5>Active users</h5>
            </div>
            <Row>
              <Col md={12} xl={4} className="location-box laptopmedia">
                <img src={activeuser}/>
              </Col>
              <Col md={12} xl={8} className="active-chart">
                {loading.user
                  ? <> <div id="pie-chart" className="e-charts">
                    <ReactEcharts option={options2} />
                  </div>
                   </>
                  :
                  <div className="react-activity">
                    <Dots color="#3C7C44" size={20} speed={0.6} />
                  </div>}
              </Col>
            </Row>
                {loading.user
                  ? <>
                  <div className="user-btn user-btn-active">
                    <Link to="/admin/users">
                      <button type="button" className="btn btn-primary waves-effect waves-light ">
                            Invite user
                      </button>
                    </Link>
                  </div> </>
                  :
                  <></>}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ActiveUsers;
