import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";

const CardUser = () => (
  <React.Fragment>
    <Col xl={12}>
      <Row className="mb-5">
        <Col lg={4}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Total Admin</p>
                  <h5 className="mb-0">10</h5>
                </div>

                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className="bx bxs-book-bookmark"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col lg={4}>
          <Card className="blog-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Total Stakeholder</p>
                  <h5 className="mb-0">5</h5>
                </div>

                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className="bx bxs-note"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="blog-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Total Users</p>
                  <h5 className="mb-0">20</h5>
                </div>

                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className="bx bxs-message-square-dots"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

      </Row>

    </Col>
  </React.Fragment>
);

CardUser.propTypes = {
  options: PropTypes.any,
  series: PropTypes.any
};

export default CardUser;
