import React, { useState } from "react";
// import Select from "react-select";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { API_URLS } from "common/constants";
import Loading from "components/Common/Loading";
import CarbonChart from "../Carbon/mines/TestChart";
import IsRoleAuth from "pages/Authentication/IsRoleAuth";
import Select from "react-select";
import { AxiosGet } from "common/axiosService";
import { useEffect } from "react";
import Consolidate from "../Carbon/mines/consolidate";

const Index = () => {
  document.title = "Carbon Footprint | Greenalytics";
  const authorizedUser = localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser"));

  const [loading, setloading] = useState(true);
  const [chartList, setChartList] = useState();
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [carbonAllChartData, setCarbonAllChartData] = useState([]);
  const [fullChart, setFullChart] = useState({
    showPredictiveChart: false,
    activeStandAloneChartTitle: "",
    selectedChart: [],
    chartType: ""
  });

  useEffect(() => {
    getAllLocation();
  }, []);

  const getAllLocation = async () => {
    try {
      const response = await AxiosGet(`${API_URLS.GET_ALL_LOCATIONS}/${authorizedUser.userData.user?.companyId?._id}`);
      if (response && response.statusCode === 200) {
        if (response.data.length > 0) {
          let locations = response.data.map((item) => {
            item.value = item._id;
            item.label = `${item.city} - ${item.addressType}`;
            return item;
          });
          locations.unshift({ value: "", label: "All", city: "All" });
          setSelectedLocation({ value: "", label: "All", city: "All" });
          setLocationList(locations);
          fetchCarbonAllChartData();
        } else {
          setloading(false);
          toast.success("Please Add Location First", { autoClose: 2000 });
        }
      } else {
        setloading(false);
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  const fetchCarbonAllChartData = async () => {
    const response = await AxiosGet(`${API_URLS.GET_CARBON_ALL_CHART_DATA}`);
    if (response && response.statusCode === 200) {
      setCarbonAllChartData(response.data);
      setloading(false);
    }
  };

  const fullScreenViewClick = (title, data, chartType) => {
    setFullChart({
      activeStandAloneChartTitle: title,
      showPredictiveChart: !fullChart.showPredictiveChart,
      selectedChart: data,
      chartType: chartType
    });
  };

  const getCarbonChartData = async (locationId) => {
    if (authorizedUser) {
      setloading(true);
      try {
        const response = await AxiosGet(`${API_URLS.CARBON_CHART}?locationId=${locationId}`);
        if (response && response.statusCode === 200) {
          setChartList(response.data[0]);
          setloading(false);
        } else {
          setloading(false);
        }
      } catch (error) {
        setloading(false);
        toast.error("No Data Found Something Went Wrong", { toastId: "nodata" });
      }
    }
  };

  const changeLocation = (e) => {
    setFullChart({
      ...fullChart,
      activeStandAloneChartTitle: "", showPredictiveChart: false, selectedChart: [], predictedChart: "", chartType: "", predictiveYears: 2
    });
    if (e._id) {
      getCarbonChartData(e._id);
    } else {
      setChartList();
    }
    setSelectedLocation(e);
  };

  const changeChartListData = (e, dataPoints, chartHeading, previousLevel, mainCategory) => {
    Object.entries(Object.values(chartList)[0]).forEach((item) => {
      if (item[0] === chartHeading) {
        let chartData = Object.values(chartList)[0];
        let oldData = Object.values(chartList)[0][`${chartHeading}`];
        oldData["currentChart"] = e ? { dataPoints, e, mainCategory } : undefined;
        if (!previousLevel || previousLevel === "") {
          oldData["Level1Data"] = e ? { dataPoints, e, mainCategory } : undefined;
        } else {
          oldData[`${previousLevel}Data`] = e ? { dataPoints, e } : undefined;
        }
        if (fullChart.showPredictiveChart) {
          setFullChart({ ...fullChart, selectedChart: oldData });
        }
        chartData[chartHeading] = oldData;
        const params = {};
        params[Object.keys(chartList)[0]] = chartData;
        setChartList(params);
      }
    });
  };

  return (
    <React.Fragment>
      <IsRoleAuth />
      <div className="page-content">
        <Container fluid>
          <Row>
            <div id="area-chart-1" dir="ltr">
              <Card>
                <CardBody>
                  <Row className="mb-3 is-sticky fixed align-items-center m-0">
                    <Col lg="2">
                      <Label>Select Location</Label>
                      <Select
                        value={selectedLocation}
                        options={locationList}
                        onChange={changeLocation}
                      />
                    </Col>
                  </Row>
                  <Row className="marginTop">
                    {loading
                      ? (
                        <Loading />
                      )
                      : fullChart.showPredictiveChart
                        ? (
                          <>
                            <i
                              className="bx bx-left-arrow"
                              style={{ cursor: "pointer", fontSize: "18px" }}
                              onClick={fullScreenViewClick}
                            />
                            <CarbonChart
                              items={fullChart.selectedChart}
                              alyticsType={"All"}
                              heading={fullChart.activeStandAloneChartTitle}
                              back1="back1"
                              back2="back2"
                              mainCategory={fullChart.mainCategory}
                              standAloneChart={true}
                              predictiveChart={fullChart.isPredictive}
                              locationId={selectedLocation._id}
                              currentChart={fullChart.selectedChart && fullChart.selectedChart.currentChart}
                              predictChartData={fullChart.predictedChart}
                              onChartSegmentClick={changeChartListData}
                              onFullScreenButtonClick={fullScreenViewClick}
                              // onFullScreenPredictButtonClick={onFullScreenPredictiveToggle}
                              chartType={fullChart.chartType}
                              predictiveYears={fullChart.predictiveYears}
                            />
                          </>
                        )
                        : (
                          <Row>
                            {carbonAllChartData.length > 0 && <Col xl="4" lg="6" md="12">
                              <Consolidate items={carbonAllChartData} />
                            </Col>}
                            {chartList && Object.entries(Object.values(chartList)[0]).map(
                              (item, i) => (
                                <Col key={`Carbon footprint${i}`} xl="4" lg="6" md="12">
                                  <CarbonChart
                                    index={i}
                                    items={item[1]}
                                    heading={item[0]}
                                    back1={"back1" + i}
                                    back2="back2"
                                    locationId={selectedLocation._id}
                                    currentChart={item[1].currentChart}
                                    onChartSegmentClick={changeChartListData}
                                    standAloneChart={false}
                                    onFullScreenButtonClick={fullScreenViewClick}
                                    chartType={fullChart.chartType}
                                    // onPredictiveToggle={onPredictToggle}
                                  />
                                </Col>
                              )
                            )}
                          </Row>
                        )
                    }
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;
