import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

//Import images
import verificationImg from "../assets/images/verification-img.png";
// import { options } from "common/data/projects"

class Report extends Component {
  constructor (props) {
    super(props);
    this.state = {
      modal: false,
      activeTab: 1,
      selectedFiles: [],
      passedSteps: [1]
    };
    // this.togglemodal.bind(this)
    // this.toggleTab.bind(this)
    // this.handleAcceptedFiles.bind(this)
  }

  // togglemodal = () => {
  //     this.setState(prevState => ({
  //         modal: !prevState.modal,
  //     }))
  // }

  // toggleTab(tab) {
  //     if (this.state.activeTab !== tab) {
  //         if (tab >= 1 && tab <= 3) {
  //             var modifiedSteps = [...this.state.passedSteps, tab];
  //             this.setState({
  //                 activeTab: tab,
  //                 passedSteps: modifiedSteps
  //             })
  //         }
  //     }
  // }

  // handleAcceptedFiles = files => {
  //     files.map(file =>
  //         Object.assign(file, {
  //             preview: URL.createObjectURL(file),
  //             formattedSize: this.formatBytes(file.size),
  //         })
  //     )

  //     this.setState({ selectedFiles: files })
  // }

  /**
   * Formats the size
   */
  // formatBytes = (bytes, decimals = 2) => {
  //     if (bytes === 0) return "0 Bytes"
  //     const k = 1024
  //     const dm = decimals < 0 ? 0 : decimals
  //     const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  //     const i = Math.floor(Math.log(bytes) / Math.log(k))
  //     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  // }

  render () {
    const heding = {
      textAlign: "center",
      fontWeight: "600",
      marginBottom: "50px",
      fontSize: "35px"
    };
    const reportTile = {
      lineHeight: "30px"
    };
    const imgFilter = {
      filter: "hue-rotate(260deg)"
    };
    //meta title
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Create Report" /> */}

            <Row className="justify-content-center mt-lg-5">
              <Col xl="12">
                <h2 className="text-primary" style={heding}>
                  {" "}
                  How would you like to get started ?{" "}
                </h2>
              </Col>

              <Col xl="4" sm="12">
                <Card>
                  <CardBody>
                    <div className="text-center">
                      <Row className="justify-content-center mt-2 mb-2">
                        <Col sm="6" xs="8">
                          <div>
                            <img
                              src={verificationImg}
                              alt=""
                              className="img-fluid"
                              style={imgFilter}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col lg="10">
                          <h4
                            className="mt-2 font-weight-semibold text-primary"
                            style={reportTile}
                          >
                            SEBI Report
                          </h4>
                          <p className="text-muted mt-3">
                            Browse through our library of frameworks including
                            GRI, SASB, TCFD and more...
                          </p>
                          <div className="mt-4">
                            <Link
                              to="/admin/report/sebi"
                              className="btn btn-outline-primary w-lg"
                              style={{ fontSize: "15px" }}
                            >
                              <span>Select</span>
                              <i className="bx bx-right-arrow-alt font-size-18  align-middle ms-5" />
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="4" sm="12">
                <Card>
                  <CardBody>
                    <div className="text-center">
                      <Row className="justify-content-center mt-2 mb-2">
                        <Col sm="6" xs="8">
                          <div>
                            <img
                              src={verificationImg}
                              alt=""
                              className="img-fluid"
                              style={imgFilter}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col lg="10">
                          <h4
                            className="mt-2 font-weight-semibold text-primary"
                            style={reportTile}
                          >
                            GRI Report
                          </h4>
                          <p className="text-muted mt-3">
                            Browse through our library of frameworks including
                            GRI, SASB, TCFD and more...
                          </p>

                          <div className="mt-4">
                            {/* button triggers modal */}
                            <Link
                              to="/admin/report/gri/keypoint"
                              className="btn btn-outline-primary w-lg"
                              style={{ fontSize: "15px" }}
                            >
                              <span>Select</span>
                              <i className="bx bx-right-arrow-alt font-size-18  align-middle ms-5" />
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="4" sm="12">
                <Card>
                  <CardBody>
                    <div className="text-center">
                      <Row className="justify-content-center mt-2 mb-2">
                        <Col sm="6" xs="8">
                          <div>
                            <img
                              src={verificationImg}
                              alt=""
                              className="img-fluid"
                              style={imgFilter}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col lg="10">
                          <h4
                            className="mt-2 font-weight-semibold text-primary"
                            style={reportTile}
                          >
                            Customize Report
                          </h4>
                          <p className="text-muted mt-3">
                            Browse through our library of frameworks including
                            GRI, SASB, TCFD and more...
                          </p>

                          <div className="mt-4">
                            {/* button triggers modal */}
                            <Link
                              to="/keypoint"
                              className="btn btn-outline-primary w-lg"
                              style={{ fontSize: "15px" }}
                            >
                              <span>Select</span>
                              <i className="bx bx-right-arrow-alt font-size-18  align-middle ms-5" />
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Report;
