import {
  GET_ADMIN_USERS,
  GET_ADMIN_USERS_FAIL,
  GET_ADMIN_USERS_SUCCESS,
  ADD_NEW_ADMIN_USER,
  ADD_ADMIN_USER_SUCCESS,
  ADD_ADMIN_USER_FAIL,
  UPDATE_ADMIN_USER,
  UPDATE_ADMIN_USER_SUCCESS,
  UPDATE_ADMIN_USER_FAIL,
  DELETE_ADMIN_USER,
  DELETE_ADMIN_USER_SUCCESS,
  DELETE_ADMIN_USER_FAIL
} from "./actionTypes";

export const getAdminusers = () => ({
  type: GET_ADMIN_USERS
});

export const getAdminusersSuccess = adminusers => (
  {
    type: GET_ADMIN_USERS_SUCCESS,
    payload: adminusers
  });

export const getAdminusersFail = error => ({
  type: GET_ADMIN_USERS_FAIL,
  payload: error
});

export const addNewAdminuser = adminuser => ({
  type: ADD_NEW_ADMIN_USER,
  payload: adminuser
});

export const addAdminuserSuccess = adminuser => ({
  type: ADD_ADMIN_USER_SUCCESS,
  payload: adminuser
});

export const addAdminuserFail = error => ({
  type: ADD_ADMIN_USER_FAIL,
  payload: error
});

export const updateAdminuser = adminuser => ({
  type: UPDATE_ADMIN_USER,
  payload: adminuser
});

export const updateAdminuserSuccess = adminuser => ({
  type: UPDATE_ADMIN_USER_SUCCESS,
  payload: adminuser
});

export const updateAdminuserFail = error => ({
  type: UPDATE_ADMIN_USER_FAIL,
  payload: error
});

export const deleteAdminuser = adminuser => ({
  type: DELETE_ADMIN_USER,
  payload: adminuser
});

export const deleteAdminuserSuccess = adminuser => ({
  type: DELETE_ADMIN_USER_SUCCESS,
  payload: adminuser
});

export const deleteAdminuserFail = error => ({
  type: DELETE_ADMIN_USER_FAIL,
  payload: error
});
