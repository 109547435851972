import {
  RESEND_VERIFY_LINK,
  RESEND_VERIFY_LINK_FAILED,
  RESEND_VERIFY_LINK_RESET,
  RESEND_VERIFY_LINK_SUCCESS
} from "./actionTypes";

const initialState = {
  email: null,
  error: null,
  success: null,
  loading: false
};
const ResendVerifyLink = (state = initialState, action) => {
  switch (action.type) {
  case RESEND_VERIFY_LINK:
    state = {
      ...state,
      loading: true,
      email: action.payload.data
    };
    break;
  case RESEND_VERIFY_LINK_SUCCESS:
    state = {
      ...state,
      loading: false,
      success: action.payload.message
    };
    break;
  case RESEND_VERIFY_LINK_FAILED:
    state = {
      ...state,
      loading: false,
      error: action.payload.message
    };
    break;
  case RESEND_VERIFY_LINK_RESET:
    state = {
      ...state,
      loading: false,
      error: null,
      success: null,
      email: null
    };
    break;
  default:
    state = { ...state };
    break;
  }
  return state;
};

export default ResendVerifyLink;
