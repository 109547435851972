import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { ROLES } from "common/constants";
import _ from "lodash";

const ProfileMenu = props => {
  const { isProfileUpdated } = props;
  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState("");
  const [proImg, setProImg] = useState(null);
  const session = JSON.parse(localStorage.getItem("authUser"));
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const session = JSON.parse(localStorage.getItem("authUser"));
      // setusername("Super Admin")
      setusername(session.userData.user.name);
      setProImg(session.userData.user.profilePicture);

      if(!session.userData.user.profilePicture || session.userData.user.profilePicture !== "null" || session.userData.user.profilePicture !== "undefined"){
        setTimeout(() => {
          const companyName = document.getElementById("Username") && document.getElementById("Username")?.textContent;
          const intials = companyName
            ? companyName
              .split(" ")
              .map(name => name[0])
              .join("")
              .toUpperCase()
            : "";
          what();
          function what (){
            if (document.getElementById("profileImage")) {
              document.getElementById("profileImage").innerHTML = intials;
            }
          }
        }, 50);}
    }
  }, [session, props.success, isProfileUpdated]);

  let userrole = session && session.userData.user.role.toLowerCase();

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
        style={{ zIndex: "1111" }}
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <div style={{
            display: "flex",
            justifyContent: "spaceBetween",
            alignItems: "center"
          }}>
            <div>
              {
                (proImg && proImg !== null && proImg !== "null" )
                  ?(
                    <>
                      {session && (session.userData.user.role === ROLES.ADMIN || session.userData.user.role === ROLES.USER || session.userData.user.role === ROLES.STAKEHOLDERS) && (
                        <Link>
                          <img crossOrigin="anonymous" className="rounded-circle header-profile-user user-avatar-img p-0" src={process.env.REACT_APP_IMG_PATH+proImg} />
                        </Link>
                      )}
                    </>)
                  : username ?
                    (
                      <>
                        {session && (session.userData.user.role === ROLES.ADMIN || session.userData.user.role === ROLES.USER || session.userData.user.role === ROLES.STAKEHOLDERS) && (
                          <Link>
                            <span
                              className="rounded-circle header-profile-user bg-primary text-white user-avatar-img"
                              id="profileImage"
                            ></span>
                          </Link>
                        )}
                      </>)
                    : ""
              }
            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "spaceBetween",
              alignItems: "flex-start"
            }}>
              {session && (session.userData.user.role === ROLES.ADMIN || session.userData.user.role === ROLES.USER || session.userData.user.role === ROLES.STAKEHOLDERS)
                ? (
                  <>
                    <div>
                      <span id="Username" className="leftMar">{username}</span>
                    </div>
                    <div>
                      <span className="d-none d-xl-inline-block ms-2 me-1" id="companyName">
                        {_.get(session, "userData.user.companyId.companyName", "")}
                      </span>
                      <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                    </div>
                  </>
                )
                :
                <>
                  <div>
                    <span className="d-none d-xl-inline-block ms-2 me-1" id="companyName">
                      {session.userData.user.role}
                    </span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                  </div>
                </>
              }
            </div>
          </div>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          {session && session.userData.user.role === ROLES.ADMIN || session.userData.user.role === ROLES.USER || session.userData.user.role === ROLES.STAKEHOLDERS
            ? (
              <>
                <Link to={`/${userrole}/profile`} className="dropdown-item">
                  <i className="bx bx-user font-size-16 align-middle me-1" />
                  {props.t("Profile")}{" "}
                </Link>
                <div className="dropdown-divider" />
              </>
            )
            : null}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  const { isProfileUpdated } = state.profileUpdate;
  return { error, success, isProfileUpdated };
};

// export default withRouter(
//   connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
// );
export default connect(mapStatetoProps, {})(withRouter(withTranslation()(ProfileMenu)));
