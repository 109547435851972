export const Country = [
  { "countryCode": "AED", "symbol": "د.إ" },
  { "countryCode": "AFN", "symbol": "؋" },
  { "countryCode": "ALL", "symbol": "L" },
  { "countryCode": "AMD", "symbol": "֏" },
  { "countryCode": "ANG", "symbol": "ƒ" },
  { "countryCode": "AOA", "symbol": "Kz" },
  { "countryCode": "ARS", "symbol": "$" },
  { "countryCode": "AUD", "symbol": "$" },
  { "countryCode": "AWG", "symbol": "ƒ" },
  { "countryCode": "AZN", "symbol": "₼" },
  { "countryCode": "BAM", "symbol": "KM" },
  { "countryCode": "BBD", "symbol": "$" },
  { "countryCode": "BDT", "symbol": "৳" },
  { "countryCode": "BGN", "symbol": "лв" },
  { "countryCode": "BHD", "symbol": ".د.ب" },
  { "countryCode": "BIF", "symbol": "FBu" },
  { "countryCode": "BMD", "symbol": "$" },
  { "countryCode": "BND", "symbol": "$" },
  { "countryCode": "BOB", "symbol": "$b" },
  { "countryCode": "BOV", "symbol": "BOV" },
  { "countryCode": "BRL", "symbol": "R$" },
  { "countryCode": "BSD", "symbol": "$" },
  { "countryCode": "BTC", "symbol": "₿" },
  { "countryCode": "BTN", "symbol": "Nu." },
  { "countryCode": "BWP", "symbol": "P" },
  { "countryCode": "BYN", "symbol": "Br" },
  { "countryCode": "BYR", "symbol": "Br" },
  { "countryCode": "BZD", "symbol": "BZ$" },
  { "countryCode": "CAD", "symbol": "$" },
  { "countryCode": "CDF", "symbol": "FC" },
  { "countryCode": "CHE", "symbol": "CHE" },
  { "countryCode": "CHF", "symbol": "CHF" },
  { "countryCode": "CHW", "symbol": "CHW" },
  { "countryCode": "CLF", "symbol": "CLF" },
  { "countryCode": "CLP", "symbol": "$" },
  { "countryCode": "CNH", "symbol": "¥" },
  { "countryCode": "CNY", "symbol": "¥" },
  { "countryCode": "COP", "symbol": "$" },
  { "countryCode": "COU", "symbol": "COU" },
  { "countryCode": "CRC", "symbol": "₡" },
  { "countryCode": "CUC", "symbol": "$" },
  { "countryCode": "CUP", "symbol": "₱" },
  { "countryCode": "CVE", "symbol": "$" },
  { "countryCode": "CZK", "symbol": "Kč" },
  { "countryCode": "DJF", "symbol": "Fdj" },
  { "countryCode": "DKK", "symbol": "kr" },
  { "countryCode": "DOP", "symbol": "RD$" },
  { "countryCode": "DZD", "symbol": "دج" },
  { "countryCode": "EEK", "symbol": "kr" },
  { "countryCode": "EGP", "symbol": "£" },
  { "countryCode": "ERN", "symbol": "Nfk" },
  { "countryCode": "ETB", "symbol": "Br" },
  { "countryCode": "ETH", "symbol": "Ξ" },
  { "countryCode": "EUR", "symbol": "€" },
  { "countryCode": "FJD", "symbol": "$" },
  { "countryCode": "FKP", "symbol": "£" },
  { "countryCode": "GBP", "symbol": "£" },
  { "countryCode": "GEL", "symbol": "₾" },
  { "countryCode": "GGP", "symbol": "£" },
  { "countryCode": "GHC", "symbol": "₵" },
  { "countryCode": "GHS", "symbol": "GH₵" },
  { "countryCode": "GIP", "symbol": "£" },
  { "countryCode": "GMD", "symbol": "D" },
  { "countryCode": "GNF", "symbol": "FG" },
  { "countryCode": "GTQ", "symbol": "Q" },
  { "countryCode": "GYD", "symbol": "$" },
  { "countryCode": "HKD", "symbol": "$" },
  { "countryCode": "HNL", "symbol": "L" },
  { "countryCode": "HRK", "symbol": "kn" },
  { "countryCode": "HTG", "symbol": "G" },
  { "countryCode": "HUF", "symbol": "Ft" },
  { "countryCode": "IDR", "symbol": "Rp" },
  { "countryCode": "ILS", "symbol": "₪" },
  { "countryCode": "IMP", "symbol": "£" },
  { "countryCode": "INR", "symbol": "₹" },
  { "countryCode": "IQD", "symbol": "ع.د" },
  { "countryCode": "IRR", "symbol": "﷼" },
  { "countryCode": "ISK", "symbol": "kr" },
  { "countryCode": "JEP", "symbol": "£" },
  { "countryCode": "JMD", "symbol": "J$" },
  { "countryCode": "JOD", "symbol": "JD" },
  { "countryCode": "JPY", "symbol": "¥" },
  { "countryCode": "KES", "symbol": "KSh" },
  { "countryCode": "KGS", "symbol": "лв" },
  { "countryCode": "KHR", "symbol": "៛" },
  { "countryCode": "KMF", "symbol": "CF" },
  { "countryCode": "KPW", "symbol": "₩" },
  { "countryCode": "KRW", "symbol": "₩" },
  { "countryCode": "KWD", "symbol": "KD" },
  { "countryCode": "KYD", "symbol": "$" },
  { "countryCode": "KZT", "symbol": "₸" },
  { "countryCode": "LAK", "symbol": "₭" },
  { "countryCode": "LBP", "symbol": "£" },
  { "countryCode": "LKR", "symbol": "₨" },
  { "countryCode": "LRD", "symbol": "$" },
  { "countryCode": "LSL", "symbol": "M" },
  { "countryCode": "LTC", "symbol": "Ł" },
  { "countryCode": "LTL", "symbol": "Lt" },
  { "countryCode": "LVL", "symbol": "Ls" },
  { "countryCode": "LYD", "symbol": "LD" },
  { "countryCode": "MAD", "symbol": "MAD" },
  { "countryCode": "MDL", "symbol": "lei" },
  { "countryCode": "MGA", "symbol": "Ar" },
  { "countryCode": "MKD", "symbol": "ден" },
  { "countryCode": "MMK", "symbol": "K" },
  { "countryCode": "MNT", "symbol": "₮" },
  { "countryCode": "MOP", "symbol": "MOP$" },
  { "countryCode": "MRO", "symbol": "UM" },
  { "countryCode": "MRU", "symbol": "UM" },
  { "countryCode": "MUR", "symbol": "₨" },
  { "countryCode": "MVR", "symbol": "Rf" },
  { "countryCode": "MWK", "symbol": "MK" },
  { "countryCode": "MXN", "symbol": "$" },
  { "countryCode": "MXV", "symbol": "MXV" },
  { "countryCode": "MYR", "symbol": "RM" },
  { "countryCode": "MZN", "symbol": "MT" },
  { "countryCode": "NAD", "symbol": "$" },
  { "countryCode": "NGN", "symbol": "₦" },
  { "countryCode": "NIO", "symbol": "C$" },
  { "countryCode": "NOK", "symbol": "kr" },
  { "countryCode": "NPR", "symbol": "₨" },
  { "countryCode": "NZD", "symbol": "$" },
  { "countryCode": "OMR", "symbol": "﷼" },
  { "countryCode": "PAB", "symbol": "B/." },
  { "countryCode": "PEN", "symbol": "S/." },
  { "countryCode": "PGK", "symbol": "K" },
  { "countryCode": "PHP", "symbol": "₱" },
  { "countryCode": "PKR", "symbol": "₨" },
  { "countryCode": "PLN", "symbol": "zł" },
  { "countryCode": "PYG", "symbol": "Gs" },
  { "countryCode": "QAR", "symbol": "﷼" },
  { "countryCode": "RMB", "symbol": "￥" },
  { "countryCode": "RON", "symbol": "lei" },
  { "countryCode": "RSD", "symbol": "Дин." },
  { "countryCode": "RUB", "symbol": "₽" },
  { "countryCode": "RWF", "symbol": "R₣" },
  { "countryCode": "SAR", "symbol": "﷼" },
  { "countryCode": "SBD", "symbol": "$" },
  { "countryCode": "SCR", "symbol": "₨" },
  { "countryCode": "SDG", "symbol": "ج.س." },
  { "countryCode": "SEK", "symbol": "kr" },
  { "countryCode": "SGD", "symbol": "S$" },
  { "countryCode": "SHP", "symbol": "£" },
  { "countryCode": "SLL", "symbol": "Le" },
  { "countryCode": "SOS", "symbol": "S" },
  { "countryCode": "SRD", "symbol": "$" },
  { "countryCode": "SSP", "symbol": "£" },
  { "countryCode": "STD", "symbol": "Db" },
  { "countryCode": "STN", "symbol": "Db" },
  { "countryCode": "SVC", "symbol": "$" },
  { "countryCode": "SYP", "symbol": "£" },
  { "countryCode": "SZL", "symbol": "E" },
  { "countryCode": "THB", "symbol": "฿" },
  { "countryCode": "TJS", "symbol": "SM" },
  { "countryCode": "TMT", "symbol": "T" },
  { "countryCode": "TND", "symbol": "د.ت" },
  { "countryCode": "TOP", "symbol": "T$" },
  { "countryCode": "TRL", "symbol": "₤" },
  { "countryCode": "TRY", "symbol": "₺" },
  { "countryCode": "TTD", "symbol": "TT$" },
  { "countryCode": "TVD", "symbol": "$" },
  { "countryCode": "TWD", "symbol": "NT$" },
  { "countryCode": "TZS", "symbol": "TSh" },
  { "countryCode": "UAH", "symbol": "₴" },
  { "countryCode": "UGX", "symbol": "USh" },
  { "countryCode": "USD", "symbol": "$" },
  { "countryCode": "UYI", "symbol": "UYI" },
  { "countryCode": "UYU", "symbol": "$U" },
  { "countryCode": "UYW", "symbol": "UYW" },
  { "countryCode": "UZS", "symbol": "лв" },
  { "countryCode": "VEF", "symbol": "Bs" },
  { "countryCode": "VES", "symbol": "Bs.S" },
  { "countryCode": "VND", "symbol": "₫" },
  { "countryCode": "VUV", "symbol": "VT" },
  { "countryCode": "WST", "symbol": "WS$" },
  { "countryCode": "XAF", "symbol": "FCFA" },
  { "countryCode": "XBT", "symbol": "Ƀ" },
  { "countryCode": "XCD", "symbol": "$" },
  { "countryCode": "XOF", "symbol": "CFA" },
  { "countryCode": "XPF", "symbol": "₣" },
  { "countryCode": "XSU", "symbol": "Sucre" },
  { "countryCode": "XUA", "symbol": "XUA" },
  { "countryCode": "YER", "symbol": "﷼" },
  { "countryCode": "ZAR", "symbol": "R" },
  { "countryCode": "ZMW", "symbol": "ZK" },
  { "countryCode": "ZWD", "symbol": "Z$" },
  { "countryCode": "ZWL", "symbol": "$" }
];