import React from "react";

export const Filter = ({ column }) => (
  <div style={{ marginTop: 5 }}>
    {column.canFilter && column.render("Filter")}
  </div>
);

export const DefaultColumnFilter = () => undefined;

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};
