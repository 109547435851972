import React from "react";
import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap";

function SebiModal (props) {
  const { showModal, setShowModal, onCloseClick, onSubmitClick, sectionTitleName } = props;
  return (
    <Modal
      isOpen={showModal}
      role="dialog"
      autoFocus={true}
      centered={true}
      tabIndex="-1"
      data-toggle="modal"
      backdrop={"static"}
      toggle={() => {
        setShowModal(!showModal);
      }}
    >
      <ModalHeader
        className="text-center"
      >
        <h4 className="modal-title" >SEBI Report</h4>
      </ModalHeader>
      <div className="modal-body">
        <div className="text-center pb-2">
            You have unsaved changes in the section <span style={{ color: "#3c7c44" }}>{sectionTitleName}.</span>
        </div>
        <div className="text-center">
            Do you want to save or discard them?
        </div>
      </div>
      <ModalFooter className="justify-content-end">
        <Button onClick={onCloseClick} type="button"> Discard </Button>
        <Button
          type="button"
          color="primary"
          onClick={onSubmitClick}
        >
            Save as Draft
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default SebiModal;