import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_ADMIN_USERS,
  ADD_NEW_ADMIN_USER,
  DELETE_ADMIN_USER,
  UPDATE_ADMIN_USER
} from "./actionTypes";
import {
  getAdminusersFail,
  getAdminusersSuccess,
  addAdminuserFail,
  addAdminuserSuccess,
  updateAdminuserSuccess,
  updateAdminuserFail,
  deleteAdminuserSuccess,
  deleteAdminuserFail
} from "./actions";

//Include Both Helper File with needed methods
import {
  getAdminusers,
  addNewAdminuser,
  updateAdminuser,
  deleteAdminuser
} from "helpers/backend_helper";

function* fetchAdminusers () {
  try {
    const response = yield call(getAdminusers);
    yield put(getAdminusersSuccess(response));
  } catch (error) {
    yield put(getAdminusersFail(error));
  }
}

function* onUpdateAdminuser ({ payload: adminuser }) {
  try {
    const response = yield call(updateAdminuser, adminuser);
    yield put(updateAdminuserSuccess(response));
  } catch (error) {
    yield put(updateAdminuserFail(error));
  }
}

function* onDeleteAdminuser ({ payload: adminuser }) {
  try {
    const response = yield call(deleteAdminuser, adminuser);
    yield put(deleteAdminuserSuccess(response));
  } catch (error) {
    yield put(deleteAdminuserFail(error));
  }
}

function* onAddNewAdminuser ({ payload: adminuser }) {
  try {
    const response = yield call(addNewAdminuser, adminuser);
    if (response.statusCode === 409) {
      yield put(addAdminuserFail(response.message));
    } else {
      yield put(addAdminuserSuccess(response));
    }
  } catch (error) {
    yield put(addAdminuserFail(error));
  }
}

function* adminUsersSaga () {
  yield takeEvery(GET_ADMIN_USERS, fetchAdminusers);
  yield takeEvery(ADD_NEW_ADMIN_USER, onAddNewAdminuser);
  yield takeEvery(UPDATE_ADMIN_USER, onUpdateAdminuser);
  yield takeEvery(DELETE_ADMIN_USER, onDeleteAdminuser);
}

export default adminUsersSaga;
