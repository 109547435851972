import React from "react";
import { useState } from "react";
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import CardPricing from "./card-pricing";
import footprint from "./icon-3.png";
import file from "./icon-1.png";
import analysis from "./icon-2.png";

const pricings = [
  {
    id: 1,
    title: "REPORTS",
    modalHeading: "REPORTS",
    img: file,
    price: "100",
    duration: "Per month",
    link: "",
    features: [
      { title: "Create ESG Report with ease" },
      { title: "BRSR Report" },
      { title: "Carbon Footprint Calculation (Will be available soon)" },
      { title: "GRI Report (Will be available soon )" },
      { title: "Unlimited Reports" },
      { title: "Bulk Upload via Excel" },
      { title: "Access to integrations" }
    ]
  },
  {
    id: 2,
    title: "ANALYTICS",
    modalHeading: "ANALYTICS",
    img: analysis,
    price: "150",
    duration: "Per month",
    link: "",
    features: [
      { title: "Create ESG Report with ease" },
      { title: "BRSR Report" },
      { title: "Carbon Footprint Calculation (Will be available soon)" },
      { title: "GRI Report (Will be available soon )" },
      { title: "Unlimited Reports" },
      { title: "Bulk Upload via Excel" },
      { title: "Access to integrations" },
      { title2: "Access To Dashboards" },
      { title: "Environmental" },
      { title: "Social" },
      { title: "Governance" },
      { title: "Carbon Footprint" },
      { title2: "Access To Predicitive Analysis & Dashboard" },
      { title: "Environmental" },
      { title: "Social" },
      { title: "Governance" }
    ],
    title2: "Access To Dashboards",
    title3: "Access To Predicitive Analysis & Dashboard"
  },
  {
    id: 3,
    title: "CARBON FOOTPRINT",
    modalHeading: "CARBON FOOTPRINT",
    img: footprint,
    price: "200",
    duration: "Per month",
    link: "",
    features: [
      { title: "Ready to use Carboon Footprint Module" },
      { title: "Carbon Footprint Calculations" },
      { title: "Access to Carbon Footprint Dashboards & Analytics" },
      { title: "Access to Predictive Module of Analytics" }
    ]
  }
];
const pricings2 = [
  {
    id: 1,
    title: "REPORTS",
    modalHeading: "REPORTS",
    img: file,
    price: "200",
    duration: "Per month",
    link: "",
    features: [
      { title: "Create ESG Report with ease" },
      { title: "BRSR Report" },
      { title: "Carbon Footprint Calculation (Will be available soon)" },
      { title: "GRI Report (Will be available soon )" },
      { title: "Unlimited Reports" },
      { title: "Bulk Upload via Excel" },
      { title: "Access to integrations" }
    ]
  },
  {
    id: 2,
    title: "ANALYTICS",
    modalHeading: "ANALYTICS",
    img: analysis,
    price: "500",
    duration: "Per month",
    link: "",
    features: [
      { title: "Create ESG Report with ease" },
      { title: "BRSR Report" },
      { title: "Carbon Footprint Calculation (Will be available soon)" },
      { title: "GRI Report (Will be available soon )" },
      { title: "Unlimited Reports" },
      { title: "Bulk Upload via Excel" },
      { title: "Access to integrations" },
      { title2: "Access To Dashboards" },
      { title: "Environmental" },
      { title: "Social" },
      { title: "Governance" },
      { title: "Carbon Footprint" },
      { title2: "Access To Predicitive Analysis & Dashboard" },
      { title: "Environmental" },
      { title: "Social" },
      { title: "Governance" }
    ],
    title2: "Access To Dashboards",
    title3: "Access To Predicitive Analysis & Dashboard"
  },
  {
    id: 3,
    title: "CARBON FOOTPRINT",
    modalHeading: "CARBON FOOTPRINT",
    img: footprint,
    price: "600",
    duration: "Per month",
    link: "",
    features: [
      { title: "Ready to use Carboon Footprint Module" },
      { title: "Carbon Footprint Calculations" },
      { title: "Access to Carbon Footprint Dashboards & Analytics" },
      { title: "Access to Predictive Module of Analytics" }
    ]
  }
];

const Billing = () => {
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content billing-model">
        <Container fluid>
          <Row>
            <Col lg={12} className="price-tab">
              <Nav tabs className="nav-tabs-custom nav-justified">
                <NavItem >
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1"
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <h5 className="d-none d-sm-block">Shared Infrastructure</h5>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2"
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <h5 className="d-none d-sm-block">Dedicated Infrastructure</h5>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col lg={12}>
              <TabContent
                activeTab={customActiveTab}
                className="p-3 text-muted"
              >
                <TabPane tabId="1">
                  <Row>
                    {pricings.map((pricing, key) => (
                      <CardPricing pricing={pricing} key={"_pricing_" + key}/>
                    ))}
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    {pricings2.map((pricing2, key) => (
                      <CardPricing pricing={pricing2} key={"_pricing_" + key} />
                    ))}
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Billing;
