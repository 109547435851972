
import React, { useEffect, useState, useMemo } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import { Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Form, Button } from "reactstrap";

//Import Breadcrumb
import { ROLES } from "common/constants";

//Import from other Component
import Loading from "components/Common/Loading";
import IsAuth from "pages/Authentication/IsAuth";
// import Breadcrumbs from "components/Common/Breadcrumb";
import { AxiosGet, AxiosPost } from "common/axiosService";
import { UserName, PhoneEmail, Role, Status } from "./UsersCol";
import DeleteModal from "../../../components/Common/DeleteModal";
import TableContainer from "../../../components/Common/TableContainer";
import Select from "react-select";

const UserList = () => {
  //meta title
  document.title = "Users | Greenalytics";
  const [modal, setModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [adminuser, setAdminuser] = useState(null);
  const [locationsList, setLocationsList] = useState([]);
  const [showloader, setShowLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [adminuserList, setAdminuserList] = useState([]);
  const [isItemsChanged, setIsItemsChanged] = useState(false);
  const [data, setData] = useState( { users: [ { email: "", name: "", role: "", locationList: [] } ] } );
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;

  const stepFourValidationSchema = Yup.object({
    users: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .email("Please Enter Valid Email")
          .required("Please Enter User Email"),
        name: Yup.string()
          .required("Please Enter Name")
          .min(3, "Minimum 3 characters required"),
        role: Yup.string().required("Please Enter User Role")
      })
    )
  });

  const handleAdminuserClick = arg => {
    const adminuser = arg;
    setAdminuser(arg);
    setData( { users: [ { email: adminuser.email, name: adminuser.name, role: adminuser.role, locationList: adminuser.locationList } ] } );
    setIsEdit(true);
    toggle();
  };

  const handleAdminuserClicks = () => {
    setData( { users: [ { email: "", name: "", role: "", locationList: [] } ] } );
    setIsEdit(false);
    toggle();
  };

  // Customber Column
  const columns = useMemo(
    () => [
      { Header: "Username", accessor: "name", filterable: true, Cell: cellProps => <UserName {...cellProps} /> },
      { Header: "Phone / Email", accessor: "email", filterable: true, Cell: cellProps => <PhoneEmail {...cellProps} /> },
      { Header: "Role", accessor: "role", filterable: true, Cell: cellProps => <Role {...cellProps} /> },
      { Header: "Status", accessor: "status", filterable: true, Cell: cellProps => <Status {...cellProps} /> },
      {
        Header: "Action",
        Cell: cellProps => (
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="d-flex gap-3"
          >
            <Link
              to="#"
              className="text-success"
              onClick={() => {
                const adminuserData = cellProps.row.original;
                handleAdminuserClick(adminuserData);
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>

            {cellProps.row.original.status !== "Invited" && (
              <div className="form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitch2"
                  checked={ cellProps.row.original.status === "Active" ? true : false }
                  onClick={e => { editGeneral(e.target, cellProps.row.original); } }
                  onChange={e => { editGeneral(e, cellProps.row.original); } }
                />
              </div>
            )}

            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const adminuserData = cellProps.row.original;
                onClickDelete(adminuserData);
              }}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Suspend
              </UncontrolledTooltip>
            </Link>
          </div>
        )
      }
    ],
    []
  );
  const viewOnlycolumns = useMemo(
    () => [
      { Header: "Username", accessor: "name", filterable: true, Cell: cellProps => <UserName {...cellProps} /> },
      { Header: "Phone / Email", accessor: "email", filterable: true, Cell: cellProps => <PhoneEmail {...cellProps} /> },
      { Header: "Role", accessor: "role", filterable: true, Cell: cellProps => <Role {...cellProps} /> },
      { Header: "Status", accessor: "status", filterable: true, Cell: cellProps => <Status {...cellProps} /> }
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  /*------------------------------------- */

  //Get all SuperAdminusers list

  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/users`, {} );
        if (response && response.statusCode === 200) {
          setAdminuserList(response.data);
          setShowLoader(true);
        } else {
          setShowLoader(true);
          return;
        }
      } catch (error) {
        setShowLoader(true);
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, [isItemsChanged]);
  const getLocationList = async () =>{
    try {
      const session = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")) : {};
      const companyId = session.userData.user.companyId
        ? session.userData.user.companyId._id
        : "";
      const response = await AxiosGet(`${apipath}/locations/${companyId}`);
      if (response && response.statusCode === 200) {
        const listData = response.data.filter(opt => opt.isActive).map((item) =>{
          item.value = item._id;
          item.label = `${item.city} - ${item.addressType}`;
          return item;
        });
        setLocationsList(listData);
        setShowLoader(true);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(true);
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  useEffect(() => {
    getLocationList();
  }, []);
  /*------------------------------------- */

  /*------------------------------------- */
  // Add new user

  const handleSubmit = async (values) => {
    try {
      const response = await AxiosPost(`${apipath}/add-user`, values );
      if (response && response.statusCode === 200) {
        toggle();
        setIsItemsChanged(prev => !prev);
        toast.success(`${response.message}`, {
          autoClose: 2000
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
    setIsDisabled(false);
  };

  /*------------------------------------- */

  /*------------------------------------- */

  // Update a user data

  const handleUpdateSubmit = async (values) => {
    const params = {
      ...adminuser,
      name: values.users[0].name,
      email: values.users[0].email,
      role: values.users[0].role,
      locationList: values.users[0].locationList
    };

    try {
      const response = await AxiosPost(`${apipath}/update-user/${adminuser._id}`, params );
      if (response && response.statusCode === 200) {
        toggle();
        setIsItemsChanged(!isItemsChanged);
        toast.success(`${response.message}`, {
          autoClose: 2000
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
    setIsDisabled(false);
  };

  /*------------------------------------- */

  /*------------------------------------- */
  // Update user status

  function editGeneral (el, user) {
    let customtext;
    if (!el.checked) {
      customtext = "Are you sure you want to Activate this user";
    } else {
      customtext = "Are you sure you want to Deactivate this user";
    }
    Swal.fire({
      text: customtext,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
      // className: "styleTitle",
      customClass: {
        container: "sweet-alert-container",
        popup: "sweet-alert-popup",
        title: "sweet-alert-title",
        icon: "sweet-alert-icon",
        actions: "sweet-alert-confirmbutton",
        confirmButton: "swal2-confirm"
      }
    })
      .then(result => {
        if (result.isConfirmed) {
          changeStatus(user);
        }
      })
      .catch(err => console.log(err));
  }

  const changeStatus = async (user) => {
    const params = {
      _id: user._id,
      status: user?.status === "Active" ? "Deactivated" : "Active"
    };
    try {
      const response = await AxiosPost(`${apipath}/delete-user`, params );
      if (response && response.statusCode === 200) {
        setIsItemsChanged(prev => !prev);
        toast.success(`${response.message}`, {
          autoClose: 2000
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  /*------------------------------------- */

  /*------------------------------------- */

  //delete Adminuser

  const onClickDelete = adminuser => {
    setAdminuser(adminuser);
    setDeleteModal(true);
  };

  const handleDeleteAdminuser = async () => {
    setDeleteModal(false);
    try {
      const response = await AxiosPost(`${apipath}/hard-delete-user/${adminuser._id}`, {});
      if (response && response.statusCode === 200) {
        setIsItemsChanged(prev => !prev);
        toast.success(`${response.message}`, {
          autoClose: 2000
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });

    }
  };

  /*------------------------------------- */

  /*------------------------------------- */

  return showloader
    ? (
      <React.Fragment>
        <IsAuth />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteAdminuser}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <Container fluid>
            {/* <Breadcrumbs title="Greenalytics" breadcrumbItem="Adminusers" /> */}
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      customPageSize={10}
                      data={adminuserList}
                      isGlobalFilter={true}
                      isCustomPageSize={true}
                      className="custom-header-css"
                      handleAdminuserClick={handleAdminuserClicks}
                      isAddCustList={
                        JSON.parse(localStorage.getItem("authUser")) &&
                      JSON.parse(localStorage.getItem("authUser")).userData.user.role === ROLES.ADMIN
                          ? true
                          : false
                      }
                      columns={
                        JSON.parse(localStorage.getItem("authUser")) &&
                        JSON.parse(localStorage.getItem("authUser")).userData.user.role === ROLES.ADMIN
                          ? columns
                          : viewOnlycolumns
                      }
                    />

                    <Modal isOpen={modal} toggle={toggle} className="modal-xl">
                      <ModalHeader toggle={toggle} tag="h4">
                        {isEdit ? "Edit User" : "Add User"}
                      </ModalHeader>
                      <ModalBody>
                        <Formik
                          validationSchema={stepFourValidationSchema}
                          initialValues={data}
                          onSubmit={handleSubmit}
                        >
                          {({ values, errors, touched, setValues, setErrors, setTouched }) => (
                            <Form>
                              <FieldArray name="users">
                                {({ remove, push }) => (
                                  <>
                                    <Row>
                                      <Col xs="12">
                                        {data.users.length > 0 &&
                                        data.users.map((user, index) => {
                                          const userErrors = (errors.users?.length && errors?.users[index]) || {};
                                          const userTouched = (touched.users?.length && touched.users[index]) || {};
                                          return (
                                            <div
                                              className="position-relative shadow border mb-3 p-3 d-block"
                                              key={index + 1}
                                              // eslint-disable-next-line
                                              count={index + 1}
                                            >
                                              <div data-repeater-list="group-a">
                                                <Row data-repeater-item>
                                                  <Col lg="3" className="">
                                                    <Field
                                                      type="email"
                                                      id={`users[${index}]`}
                                                      placeholder="User E-mail"
                                                      name={`users[${index}].email`}
                                                      className={ "form-control" + (userErrors.email && userTouched.email ? " is-invalid" : "") }
                                                    />
                                                    <ErrorMessage
                                                      name={`users[${index}].email`}
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </Col>

                                                  <Col lg="3" className="">
                                                    <Field
                                                      type="text"
                                                      name={`users[${index}].name`}
                                                      className={ "form-control" + (userErrors.name && userTouched.name ? " is-invalid" : "") }
                                                      placeholder="Name"
                                                    />
                                                    <ErrorMessage
                                                      name={`users[${index}].name`}
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </Col>

                                                  <Col lg="3" className="">
                                                    <Field
                                                      as="select"
                                                      name={`users[${index}].role`}
                                                      className={ "form-select" + (userErrors.role && userTouched.role ? " is-invalid" : "") }
                                                    >
                                                      <option value="">
                                                        Select Role
                                                      </option>
                                                      <option value="Stakeholder">
                                                        Stakeholder
                                                      </option>
                                                      <option value="Admin">
                                                        Admin
                                                      </option>
                                                      <option value="User">
                                                        User
                                                      </option>
                                                    </Field>

                                                    <ErrorMessage
                                                      name={`users[${index}].role`}
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </Col>
                                                  <Col lg="3">
                                                    <Select
                                                      name={`users[${index}].locationList`}
                                                      className="zIndex"
                                                      value={locationsList.filter(obj => user.locationList?.includes(obj.value))}
                                                      options={locationsList}
                                                      style={{ zIndex: "5" }}
                                                      onChange={(e) => {
                                                        setData({
                                                          users: data.users.map((item, i) => {
                                                            if (i===index) {
                                                              item.locationList = Array.isArray(e) ? e.map(x => x.value) : [];
                                                            }
                                                            return item;
                                                          })
                                                        });
                                                        setValues({
                                                          users: values.users.map((item, i) => {
                                                            if (i===index) {
                                                              item.locationList = Array.isArray(e) ? e.map(x => x.value) : [];
                                                            }
                                                            return item;
                                                          })
                                                        });
                                                      }}
                                                      isMulti={true}
                                                    />
                                                  </Col>

                                                  {data.users.length > 1
                                                    ? (
                                                      <Col
                                                        lg="1"
                                                        className="close-btn mt-3"
                                                      >
                                                        <i
                                                          className="mdi mdi-close-box mdi-close-btn"
                                                          onClick={() => {
                                                            setData({ users: data.users.filter((d, dataIndex ) => dataIndex !== index) } );
                                                            remove(index);
                                                          }}
                                                        />
                                                      </Col>
                                                    )
                                                    : (
                                                      ""
                                                    )}
                                                </Row>
                                              </div>
                                            </div>
                                          );
                                        })}
                                        {!isEdit && (
                                          <Button
                                            color="default"
                                            className="mt-3 mt-lg-0 btn btn-outline-primary btn-sm"
                                            onClick={() => {
                                              setData({ users: [ ...data.users, { email: "", name: "", role: "", locationList: [] } ] } );
                                              push({ email: "", name: "", role: "", locationList: [] } );
                                            }}
                                          >
                                          + Add more{" "}
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>

                                    <Row className="mt-3 next-prev-btn">
                                      <Col>
                                        <button
                                          className="btn btn-primary btn-block"
                                          type="button"
                                          disabled={isDisabled}
                                          onClick={() => {
                                            setIsDisabled(true);
                                            setErrors(errors);
                                            const filterTouched = data.users.map(() => ({ email: true, name: true, role: true }));
                                            setTouched({ users: filterTouched });
                                            const checkError = values.users.some(item => ( item.email === "" || item.name === "" || item.role === "" ));
                                            if (!checkError && !errors?.users) {
                                              if (isEdit) {
                                                handleUpdateSubmit(values);
                                              } else {
                                                handleSubmit(values);
                                              }
                                            } else {
                                              setIsDisabled(false);
                                            }
                                          }}
                                        >
                                        Submit
                                        </button>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </FieldArray>
                            </Form>
                          )}
                        </Formik>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
    : (
      <Loading />
    );
};

export default UserList;
