
import axios from "axios";
import { toast } from "react-toastify";

const instance = axios.create({ baseURL: process.env.REACT_APP_API_PATH });

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  const token = localStorage.getItem("accessToken");
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(response => {
  if(response.data && response.data.statusCode === 200) {
    return response.data;
  } else {
    if (response.data && response.data.statusCode === 401) {
      setTimeout(() => {
        window.location.replace("/login");
        window.localStorage.removeItem("authUser");
        window.localStorage.removeItem("accessToken");
      }, 1000);
    } else if (response.data && response.data.statusCode >= 500) {
      toast.error("Internal Server Error.", { toastId: "error", autoClose: 2000 });
      setTimeout(() => {
        window.location.replace("/login");
        window.localStorage.removeItem("authUser");
        window.localStorage.removeItem("accessToken");
      }, 1000);
    } else {
      toast.error(response.data.message, {
        autoClose: 2000
      });
    }
  }
},
function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

// Below code is only for understanding. 
// instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default instance;