import React from "react";

function RadioButton (props) {
  const { name="inlineRadioOptions", className="dis-right", id, value, onChange, disabled } = props;

  return (
    <div className={className}>
      <div className="form-check form-check-inline" >
        <input
          className="form-check-input"
          type="radio"
          disabled={disabled}
          name={name}
          onChange={() => {}}
          onClick={onChange}
          checked={ value === "Yes" }
          value="Yes"
          id={ "yes" + id}
        />
        <label className="form-check-label" htmlFor={ "yes" + id} > Yes </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          disabled={disabled}
          onChange={() => {}}
          onClick={onChange}
          checked={ value === "No" }
          value="No"
          name={name}
          id={ "no" + id }
        />
        <label className="form-check-label" htmlFor={ "no" + id } > No </label>
      </div>
    </div>
  );
}

export default RadioButton;