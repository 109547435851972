import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import classnames from "classnames";
import { get as _get } from "lodash";
import { connect } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { Field, Form, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, FormFeedback, Input, Label, Row } from "reactstrap";

import CurrencyFormat from "react-currency-format";
import { AxiosGet, AxiosPost } from "common/axiosService";
import IsAuth from "pages/Authentication/IsAuth";
import { Country } from "pages/Authentication/RegisterForm/Country";

// import profile1 from "../../../assets/images/users/avatar-5.jpg";
import { useRef } from "react";
import { isProfileUpdated } from "store/actions";
import { useDispatch } from "react-redux";

const Profile = () => {
  const dispatch = useDispatch();
  document.title = "Profile | Greenalytics";
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const path = process.env.REACT_APP_API_PATH;
  const profileUpdate = JSON.parse(localStorage.getItem("authUser"));
  const session = JSON.parse(localStorage.getItem("authUser"))?.userData.user.companyId;
  const profile = JSON.parse(localStorage.getItem("authUser"))?.userData.user;
  const userid = profile._id;

  const [proImg, setProImg] = useState(null);
  const [username, setusername] = useState("");
  const [comloading, setComloading] = useState(false);
  const [userloading, setUserloading] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [isItemsChanged, setIsItemsChanged] = useState(false);

  const data = { name: _get(profile, "name", ""), email: _get(profile, "email", ""), mobileNumber: _get(profile, "mobileNumber", ""), profilePicture: null };

  const userDetails = JSON.parse(localStorage.getItem("authUser"));
  const userRole = userDetails?.userData.user.role;

  const months = [{ value: 1, label: "January" }, { value: 2, label: "February" }, { value: 3, label: "March" }, { value: 4, label: "April" }, { value: 5, label: "May" },
    { value: 6, label: "June" }, { value: 7, label: "July" }, { value: 8, label: "August" }, { value: 9, label: "September" },
    { value: 10, label: "October" }, { value: 11, label: "November" }, { value: 12, label: "December" } ];

  const allData = {
    phoneNumber: _get(session, "phoneNumber", ""),
    emailNotification: _get(session, "emailNotification", false),
    smsNotification: _get(session, "smsNotification", false),
    financialStartMonth: _get(session, "financialStartMonth", 1),
    cin: _get(session, "cin", ""),
    companyName: _get(session, "companyName", ""),
    yearOfIncorporation: _get(session, "yearOfIncorporation", ""),
    corporataeAddress: _get(session, "corporataeAddress", ""),
    currency: _get(session, "currency", ""),
    authorizedCapital: _get(session, "authorizedCapital", ""),
    paidUpCapital: _get(session, "paidUpCapital", "")
  };

  const ValidationSchema = Yup.object().shape({
    smsNotification: Yup.boolean(),
    phoneNumber: Yup.string().when("smsNotification", {
      is: true,
      then: Yup.string().min(10).required("Please Enter Phone Number")
    }),
    companyName: Yup.string().required("Please Enter Company Name"),
    financialStartMonth: Yup.number().required("Please Select Financial Start Month"),
    currency: Yup.string().when(["authorizedCapital", "paidUpCapital"], {
      is: (authorizedCapital, paidUpCapital) => authorizedCapital || paidUpCapital,
      then: Yup.string().required("Please Select Currency Type" )
    }),
    authorizedCapital: Yup.string().when(["paidUpCapital", "currency"], {
      is: (paidUpCapital, currency) => !paidUpCapital && currency,
      then: Yup.string().required("Please Add Amount")
    }),
    paidUpCapital: Yup.string().when(["authorizedCapital", "currency"], {
      is: (authorizedCapital, currency) => !authorizedCapital && currency,
      then: Yup.string().required("Please Add Amount")
    })
  }, [["authorizedCapital", "paidUpCapital"],
    ["paidUpCapital", "currency"],
    ["authorizedCapital", "currency"] ]);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const session = JSON.parse(localStorage.getItem("authUser"));
      setusername(session.userData.user.name);
      setProImg(session.userData.user.profilePicture);

      if(!session.userData.user.profilePicture || session.userData.user.profilePicture !== "null" || session.userData.user.profilePicture !== "undefined"){
        setTimeout(() => {
          const intials = username
            .split(" ")
            .map(name => name[0])
            .join("")
            .toUpperCase();
          if (document.getElementById("textimg")) {
            document.getElementById("textimg").innerHTML = intials;
          }
        }, 50);}
    }
  }, [profileUpdate]);

  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosPost(`${apipath}/find-company-by-id/${session._id}`, {});
        if (response && response.statusCode === 200) {
          const authUser = JSON.parse(localStorage.getItem("authUser"));
          authUser.userData.user.companyId = response.data[0];
          localStorage.setItem("authUser", JSON.stringify(authUser));
          // setPreview(authUser.userData.user.profilePicture);
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, [isItemsChanged]);

  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/find-user-by-id/${userid}`, {} );
        if (response && response.statusCode === 200) {
          dispatch(isProfileUpdated());
          setProfileImage(process.env.REACT_APP_IMG_PATH+response.data[0].profilePicture);
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, [isItemsChanged]);

  const updateCompanyProfile = async (values) => {
    let param = { ...values, oldData: allData };
    setComloading(true);
    if(profile.role === "Admin"){
      const response = await AxiosPost(`${apipath}/update-company-profile/${session._id}`, param);
      try {
        if (response && response.statusCode === 200) {
          setIsItemsChanged(prev => !prev);
          toast.success(`${response.message}`, {
            autoClose: 2000
          });
          setComloading(false);
        } else {
          setComloading(false);
          return;
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    }
  };

  const fileRef = useRef();
  const updateUserProfile = async (values) => {
    setUserloading(true);
    const formData = new FormData();
    if (values.profilePicture && values.profilePicture !== null) {
      formData.append("profilePicture", values.profilePicture);
    }
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("mobileNumber", values.mobileNumber);
    const response = await AxiosPost(`${path}/update-profile/${profile._id}`, formData);
    try {
      if (response && response.statusCode === 200) {
        setIsItemsChanged(prev => !prev);
        toast.success(`${response.message}`, {
          autoClose: 2000
        });
        setUserloading(false);
      } else {
        setUserloading(false);
        return;
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }

  };

  return (
    <React.Fragment>
      <IsAuth />
      <div className="page-content" id="register">
        <Container fluid={true}>
          <Card>
            <CardBody>
              <h3 className="mb-4">Profile</h3>
              <Row>
                <Col lg="4">
                  <Formik
                    initialValues={data}
                    onSubmit={(values) => updateUserProfile(values)}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <Col xs={12}>
                          <div className="tab-box">
                            <span className="tab-heading h4 mb-0">
                            Configure your profile
                            </span>
                            <div className="content w-100 clearfix mt-0">
                              <Row>
                                <Col lg="12" >
                                  <div className="proImgMain" >
                                    {
                                      (proImg && proImg !== null && proImg !== "null" ) ?
                                        <img
                                          crossOrigin="anonymous"
                                          src={profileImage}
                                          alt=""
                                          className="p-0 img-thumbnail rounded-circle"
                                          style={{
                                            width: "8.5rem",
                                            height: " 8.5rem",
                                            objectFit: "contain",
                                            objectPosition: "center"
                                          }}
                                        />
                                        : username ?
                                          values.profilePicture !== null ?
                                            <img
                                              crossOrigin="anonymous"
                                              src={profileImage}
                                              alt=""
                                              className="p-0 img-thumbnail rounded-circle"
                                              style={{
                                                width: "8.5rem",
                                                height: " 8.5rem",
                                                objectFit: "contain",
                                                objectPosition: "center"
                                              }}
                                            /> :
                                            <span
                                              className="proAvatarName rounded-circle bg-primary text-white"
                                              id="textimg"
                                            ></span>
                                          : values.profilePicture !== null ?
                                            <img
                                              crossOrigin="anonymous"
                                              src={profileImage}
                                              alt=""
                                              className="p-0 img-thumbnail rounded-circle"
                                              style={{
                                                width: "8.5rem",
                                                height: " 8.5rem",
                                                objectFit: "contain",
                                                objectPosition: "center"
                                              }}
                                            /> :
                                            <span
                                              className="proAvatarName rounded-circle bg-primary text-white"
                                              id="textimg"
                                            ></span>
                                    }
                                    <label className="proImgLabel" onClick={() => {
                                      fileRef.current.click();
                                    }}><i className="bx bx-image-add"></i>
                                    </label>

                                    <input
                                      id="profilePicture"
                                      accept=".png, .jpg, .jpeg"
                                      hidden
                                      type="file"
                                      //= value={values.profilePicture}
                                      onChange={(event) => {
                                        const reader = new FileReader();
                                        reader.readAsDataURL(event.target.files[0]);
                                        reader.onload = () => {
                                          setProfileImage(reader.result);
                                          // setPreview(reader.result);
                                        };
                                        setFieldValue("profilePicture", event.target.files[0]);
                                      }}
                                      ref={fileRef}
                                    />
                                  </div>
                                  <h5 className="font-size-20 text-truncate text-center" style={{ marginTop: "1rem" }}>
                                    {profile.name ? profile.name : ""}
                                  </h5>
                                  <p className="text-muted mb-0 text-truncate text-center">
                                    {profile.role}
                                  </p>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col lg="12">
                                  <div className="mb-2">
                                    <Label for="Name">Name</Label>
                                    <Field id="name" name="name" className="form-control" placeholder="Enter Your Name" type="text"
                                      value={values.name}/>
                                  </div>
                                </Col>
                                <Col lg="12">
                                  <div className="mb-2">
                                    <Label for="email">Email</Label>
                                    <Field id="email" name="email" className="form-control" placeholder="Enter Your Email" type="email"
                                      value={values.email}/>
                                  </div>
                                </Col>
                                <Col lg="12">
                                  <div className="mb-2">
                                    <Label for="mobileNumber">Phone No.</Label>
                                    <Field id="mobileNumber" name="mobileNumber" className="form-control" placeholder="Enter Your Phone Number"
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      value={values.mobileNumber}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mt-2">
                                  <button
                                    className="btn btn-primary btn-block" type="submit"
                                    disabled={userloading}
                                  >
                                    {userloading
                                      ? (
                                        <div>
                                          <div
                                            className="spinner-border text-white" role="status"
                                            style={{ width: "1rem", height: "1rem" }}>
                                            <span className="sr-only">Loading...</span>
                                          </div>{" "}Update</div>
                                      )
                                      : (
                                        "Update"
                                      )}
                                  </button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Form>
                    )}
                  </Formik>
                </Col>
                <Col lg="8">
                  <Formik
                    validationSchema={ValidationSchema}
                    initialValues={allData}
                    onSubmit={(values) => updateCompanyProfile(values)}>
                    {({ values, errors, touched, setFieldValue }) => (
                      // step two form
                      <Form>
                        <Col xs={12}>
                          <div className="tab-box">
                            <span className="tab-heading h4 mb-0">Company Details</span>
                            <div className="content w-100 clearfix mt-0">
                              <Row className="mt-3">
                                <Col lg="12">
                                  <Label for="phoneNumber"><b>Phone Number</b></Label>
                                  <p>Enter the phone number that you want to get GreenAlytics notifications to.</p>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Input type="hidden" name="phoneNumber"/>
                                    <PhoneInput
                                      // country={'in'}
                                      disabled={userRole !== "Admin" ? true : false}
                                      inputExtraProps={{ name: "phoneNumber", required: true, autoFocus: true }}
                                      className={classnames( "form-control", touched.phoneNumber && errors.phoneNumber ? "is-invalid" : "" )}
                                      value={values.phoneNumber}
                                      onChange={val => setFieldValue("phoneNumber", "+" + val)}
                                    />
                                    {touched.phoneNumber && errors.phoneNumber && <FormFeedback type="invalid"> {errors.phoneNumber} </FormFeedback> }
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <p>Greenalytics uses multiple notification channels (SMS, mobile push, phone call or email) to ensure that you don&lsquo;t miss any critical alerts.</p>

                                    <div className="form-check-inline">
                                      <Input
                                        name="emailNotification" type="checkbox" id="email" className="form-check-input me-2"
                                        onClick={e => setFieldValue("emailNotification", e.target.checked)}
                                        defaultChecked={values.emailNotification}/>

                                      <Label className="form-check-label"for="email">E-mail</Label>
                                    </div>

                                    <div className="form-check-inline">
                                      <Input type="checkbox" name="smsNotification" id="sms" className="form-check-input me-2"
                                        onClick={e =>setFieldValue("smsNotification", e.target.checked)}
                                        defaultChecked={values.smsNotification}/>

                                      <Label className="form-check-label"for="sms">SMS</Label>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row className="mb-3 mt-3">
                                <Col lg="6">
                                  <div className="mb-2">
                                    <Label for="cin">
                                    Corporation Identity Number(CIN) of the Company
                                    </Label>
                                    <Field
                                      type="text" id="cin" name="cin" disabled={userRole !== "Admin" ? true : false}
                                      placeholder="Enter Company CIN"
                                      className={classnames("form-control", touched.cin && errors.cin? "is-invalid": "")}
                                    />
                                    {touched.cin && errors.cin
                                      ? (<FormFeedback type="invalid">{errors.cin}</FormFeedback>)
                                      : null}
                                  </div>
                                </Col>

                                <Col lg="6">
                                  <div className="mb-2">
                                    <Label for="companyName">
                                      Name of the Company*
                                    </Label>
                                    <Field
                                      id="companyName"
                                      name="companyName"
                                      disabled={userRole !== "Admin" ? true : false}
                                      className={classnames(
                                        "form-control",
                                        touched.companyName &&
                                                    errors.companyName
                                          ? "is-invalid"
                                          : ""
                                      )}
                                      placeholder="Enter Company Name*"
                                      type="text"
                                    />
                                    {touched.companyName &&
                                                errors.companyName
                                      ? (
                                        <FormFeedback type="invalid">
                                          {errors.companyName}
                                        </FormFeedback>
                                      )
                                      : null}
                                  </div>
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col lg="6">
                                  <div className="mb-2">
                                    <Label for="yearOfIncorporation">
                                                Year of Incorporation
                                    </Label>
                                    <Field
                                      id="yearOfIncorporation"
                                      name="yearOfIncorporation"
                                      disabled={userRole !== "Admin" ? true : false}
                                      className={classnames(
                                        "form-control",
                                        touched.yearOfIncorporation &&
                                                    errors.yearOfIncorporation
                                          ? "is-invalid"
                                          : ""
                                      )}
                                      placeholder="Enter Year of Incorporation"
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                    />
                                    {touched.yearOfIncorporation &&
                                                errors.yearOfIncorporation
                                      ? (
                                        <FormFeedback type="invalid">
                                          {errors.yearOfIncorporation}
                                        </FormFeedback>
                                      )
                                      : null}
                                  </div>
                                </Col>

                                <Col lg="6">
                                  <div className="mb-2">
                                    <Label for="corporataeAddress">
                                       Corporate Address
                                    </Label>
                                    <Field
                                      id="corporataeAddress"
                                      name="corporataeAddress"
                                      disabled={userRole !== "Admin" ? true : false}
                                      className={classnames(
                                        "form-control",
                                        touched.corporataeAddress &&
                                                    errors.corporataeAddress
                                          ? "is-invalid"
                                          : ""
                                      )}
                                      placeholder="Enter Corporate Address"
                                      type="text"
                                    />
                                    {touched.corporataeAddress &&
                                                errors.corporataeAddress
                                      ? (
                                        <FormFeedback type="invalid">
                                          {errors.corporataeAddress}
                                        </FormFeedback>
                                      )
                                      : null}
                                  </div>
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col lg="6">
                                  <Row>
                                    <Col lg="4">
                                      <div className="mb-2">
                                        <Label for="currency">Currency</Label>
                                        <Field
                                          id="currency"
                                          as="select"
                                          name="currency"
                                          disabled={userRole !== "Admin" ? true : false}
                                          className={classnames("form-select", errors.currency ? "is-invalid" : "" )}
                                          value={values.currency}
                                        >
                                          <option defaultValue="">Select Currency</option>
                                          {Country.map((item, i) =>
                                            <option key={i} value={`${item.countryCode}, ${item.symbol}`}>{item.countryCode}, {item.symbol}</option>
                                          )}
                                        </Field>
                                        {errors.currency
                                          ? (
                                            <FormFeedback type="invalid">
                                              {errors.currency}
                                            </FormFeedback>
                                          )
                                          : null}
                                      </div>
                                    </Col>

                                    <Col lg="8">
                                      <div className="mb-2">
                                        <Label for="authorizedCapital">
                                                    Authorized Capital
                                        </Label>
                                        <CurrencyFormat
                                          id="authorizedCapital"
                                          name="authorizedCapital"
                                          disabled={userRole !== "Admin" ? true : false}
                                          className={classnames( "form-control", touched.authorizedCapital &&
                                                    errors.authorizedCapital
                                            ? "is-invalid"
                                            : ""
                                          )}
                                          placeholder="Enter Authorized Capital"
                                          type="text"
                                          value={values.authorizedCapital}
                                          onValueChange={(value) => {
                                            const { formattedValue } = value;
                                            setFieldValue("authorizedCapital", formattedValue);
                                          }}
                                          thousandSeparator={true}
                                          thousandSpacing={"2s"}
                                        />
                                        {touched.authorizedCapital &&
                                                    errors.authorizedCapital
                                          ? (
                                            <FormFeedback type="invalid">
                                              {errors.authorizedCapital}
                                            </FormFeedback>
                                          )
                                          : null}
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col lg="6">
                                  <div className="mb-2">
                                    <Label for="paidUpCapital">
                                      PaidUp Capital{" "}
                                    </Label>
                                    <CurrencyFormat
                                      id="paidUpCapital"
                                      name="paidUpCapital"
                                      disabled={userRole !== "Admin" ? true : false}
                                      className={classnames( "form-control",
                                        touched.paidUpCapital && errors.paidUpCapital
                                          ? "is-invalid"
                                          : ""
                                      )}
                                      placeholder="Enter PaidUp Capital"
                                      type="text"
                                      thousandSeparator={true}
                                      thousandSpacing={"2s"}
                                      value={values.paidUpCapital}
                                      onValueChange={(value) => {
                                        const { formattedValue } = value;
                                        setFieldValue("paidUpCapital", formattedValue);
                                      }}
                                    />
                                    {touched.paidUpCapital &&
                                                errors.paidUpCapital
                                      ? (
                                        <FormFeedback type="invalid">
                                          {errors.paidUpCapital}
                                        </FormFeedback>
                                      )
                                      : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col lg="6">
                                  <div className="mb-2">
                                    <Label for="financialStartMonth"> Select Financial Start Month </Label>
                                    <Field
                                      id="financialStartMonth"
                                      name="financialStartMonth"
                                      disabled={userRole !== "Admin" ? true : false}
                                      className={classnames( "form-control form-select", touched.financialStartMonth && errors.financialStartMonth ? "is-invalid" : "" )}
                                      as="select"
                                      onWheel={(e) => e.target.blur()}
                                    >
                                      <option disabled value="">Select</option>
                                      {months.map(month => <option key={month.value} value={month.value}>{month.label}</option>)}
                                    </Field>
                                    {touched.financialStartMonth && errors.financialStartMonth && <FormFeedback type="invalid"> {errors.financialStartMonth}</FormFeedback>}
                                  </div>
                                </Col>
                              </Row>

                              {userRole === "Admin" && <Col>
                                <button
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                  disabled={comloading}
                                >
                                  {comloading
                                    ? (
                                      <div>
                                        <div
                                          className="spinner-border text-white"
                                          role="status"
                                          style={{ width: "1rem", height: "1rem" }}
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>{" "}
                                        Update
                                      </div>
                                    )
                                    : (
                                      "Update"
                                    )}
                                </button>
                              </Col>
                              }
                            </div>
                          </div>
                        </Col>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

Profile.propTypes = {
  company: PropTypes.any,
  message: PropTypes.any,
  registrationError: PropTypes.any,
  progress: PropTypes.any,
  loading: PropTypes.any
};

const mapStatetoProps = state => {
  const { company, message, registrationError, progress, loading } =
    state.Account;
  return { company, message, registrationError, progress, loading };
};

export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Profile)));
