import React, { useEffect, useState } from "react";
import _, { get as _get, set as _set } from "lodash";
import { connect } from "react-redux";
import HSA from "./SectionA/HSA";
import TDC from "./SectionA/TDC";
import MPD from "./SectionB/MPD";
import Employees from "./SectionA/Employees";
import Operation from "./SectionA/Operation";
import Principle1 from "./SectionC/Principle1";
import Principle2 from "./SectionC/Principle2";
import Principle3 from "./SectionC/Principle3";
import Principle4 from "./SectionC/Principle4";
import Principle5 from "./SectionC/Principle5";
import Principle6 from "./SectionC/Principle6";
import Principle7 from "./SectionC/Principle7";
import Principle8 from "./SectionC/Principle8";
import Principle9 from "./SectionC/Principle9";
import CSRDetails from "./SectionA/CSRDetails";
import CompanyDetails from "./SectionA/CompanyDetails";
import ProductServices from "./SectionA/ProductServices";
import { addSebiData, getSebiForm, updateSebiData, draftSebiData } from "../../../store/newReport/actions";

import "../../../assets/css/app.min.css";
import SebiModal from "components/Common/sebiModal";
import sebiInitialData from "./Sebidata.json";
// import sebiInitialBlankData from "./SebiInitialData.json";
import Loading from "components/Common/Loading";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { AxiosGet } from "common/axiosService";
import { API_URLS } from "common/constants";
import { toast } from "react-toastify";
import moment from "moment";

function Dashboard (props) {
  const { getSebiForm, updateSebiData, draftSebiData, newReport, draftReport, addSebiData } = props;
  const user = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")) : {};
  const [year, setYear] = useState(props.match.params.year);
  const [selctedyear, setSelectedyear] = useState(`${props.match.params.year}-${(Number(moment(props.match.params.year, "YYYY").format("YY")) + 1).toString().slice(-2).padStart(2, "0")}`);
  const history = useHistory();
  const [activeSection, setActiveSection] = useState("sectionA");
  const [activeSubSection, setActiveSubSection] = useState(1);
  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [businessDetails, setBusinessDetails] = useState();
  const [sebiInitialBlankData, setSebiInitialBlankData] = useState({});
  const [loading, setLoading] = useState(false);
  const [previousSubSection, setPreviousSubSection] = useState({ name: "", sectionName: "", draftSectionName: "", subSectionName: "" });
  const [showDraftModal, setShowDraftModal] = useState(false);
  const [completedSubSection, setCompletedSubSection] = useState({
    company_details: true, products_services: false,
    operation: false, workforce_data: false, h_s_a: false,
    csr_details: false, t_and_d: false, MPD_disclosure: false,
    principle_1: false, principle_2: false, principle_3: false, principle_4: false,
    principle_5: false, principle_6: false, principle_7: false, principle_8: false, principle_9: false
  });
  const [subSection, setSubSection] = useState({
    company_details: { total: 0, completed: 0 }, products_services: { total: 0, completed: 0 },
    operation: { total: 0, completed: 0 }, workforce_data: { total: 0, completed: 0 }, h_s_a: { total: 0, completed: 0 },
    csr_details: { total: 0, completed: 0 }, t_and_d: { total: 0, completed: 0 }, MPD_disclosure: { total: 0, completed: 0 },
    principle_1: { total: 0, completed: 0 }, principle_2: { total: 0, completed: 0 }, principle_3: { total: 0, completed: 0 },
    principle_4: { total: 0, completed: 0 }, principle_5: { total: 0, completed: 0 }, principle_6: { total: 0, completed: 0 },
    principle_7: { total: 0, completed: 0 }, principle_8: { total: 0, completed: 0 }, principle_9: { total: 0, completed: 0 }
  });
  useEffect(() => {
    setLoading(true);
    setCompletedSubSection({ ...completedSubSection, company_details: true });
    getSebiForm(props.match.params).then(() => {
      setLoading(false);
    });
  }, []);

  const keyify = (obj, data, prefix = "") =>
    Object.keys(obj).reduce((res, el) => {
      if( Array.isArray(obj[el]) ) {
        const h = obj[el].map((element, index) => {
          if (Object.keys(element).length > 0) {
            return Object.keys(element).map((name) => _get(data, prefix + el + "[" + index + "]" + name, ""));
          }
        }).flat(1);
        return [...res, ...h];
      } else if( typeof obj[el] === "object" && obj[el] !== null ) {
        return [...res, ...keyify(obj[el], data, prefix + el + ".")];
      }
      return [...res, _get(data, prefix + el, "")];
    }, []);

  useEffect(() => {
    getInitialReportData();
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
      console.log("newReport.currentYear===>", newReport.currentYear);
      const sebiData = {
        ...sebiInitialData,
        sectionA: _.merge(sebiInitialBlankData?.sectionA, newReport?.currentYear?.sectionA),
        sectionB: _.merge(sebiInitialBlankData?.sectionB, newReport?.currentYear?.sectionB ),
        sectionC: _.merge(sebiInitialBlankData?.sectionC, newReport?.currentYear?.sectionC)
      };
      const totalFields1 = keyify(_get(sebiData, "sectionA.company_details", {}), _get(sebiData, "sectionA.company_details", {}));
      const completedFields1 = totalFields1.filter(i => i !== "");
      const totalFields2 = keyify(_get(sebiData, "sectionA.products_services", {}), _get(sebiData, "sectionA.products_services", {}));
      const completedFields2 = totalFields2.filter(i => i !== "");
      const totalFields3 = keyify(_get(sebiData, "sectionA.operation", {}), _get(sebiData, "sectionA.operation", {}));
      const completedFields3 = totalFields3.filter(i => i !== "");
      const totalFields4 = keyify(_get(sebiData, "sectionA.workforce_data", {}), _get(sebiData, "sectionA.workforce_data", {}));
      const completedFields4 = totalFields4.filter(i => i !== "");
      const totalFields5 = keyify(_get(sebiData, "sectionA.h_s_a", {}), _get(sebiData, "sectionA.h_s_a", {}));
      const completedFields5 = totalFields5.filter(i => i !== "");
      const totalFields6 = keyify(_get(sebiData, "sectionA.csr_details", {}), _get(sebiData, "sectionA.csr_details", {}));
      const completedFields6 = totalFields6.filter(i => i !== "");
      const totalFields7 = keyify(_get(sebiData, "sectionA.t_and_d", {}), _get(sebiData, "sectionA.t_and_d", {}));
      const completedFields7 = totalFields7.filter(i => i !== "");
      const totalFields8 = keyify(_get(sebiData, "sectionB", {}), _get(sebiData, "sectionB", {}));
      const completedFields8 = totalFields8.filter(i => i !== "");
      const totalFields9 = keyify(_get(sebiData, "sectionC.principle_1", {}), _get(sebiData, "sectionC.principle_1", {}));
      const completedFields9 = totalFields9.filter(i => i !== "");
      const totalFields10 = keyify(_get(sebiData, "sectionC.principle_2", {}), _get(sebiData, "sectionC.principle_2", {}));
      const completedFields10 = totalFields10.filter(i => i !== "");
      const totalFields11 = keyify(_get(sebiData, "sectionC.principle_3", {}), _get(sebiData, "sectionC.principle_3", {}));
      const completedFields11 = totalFields11.filter(i => i !== "");
      const totalFields12 = keyify(_get(sebiData, "sectionC.principle_4", {}), _get(sebiData, "sectionC.principle_4", {}));
      const completedFields12 = totalFields12.filter(i => i !== "");
      const totalFields13 = keyify(_get(sebiData, "sectionC.principle_5", {}), _get(sebiData, "sectionC.principle_5", {}));
      const completedFields13 = totalFields13.filter(i => i !== "");
      const totalFields14 = keyify(_get(sebiData, "sectionC.principle_6", {}), _get(sebiData, "sectionC.principle_6", {}));
      const completedFields14 = totalFields14.filter(i => i !== "");
      const totalFields15 = keyify(_get(sebiData, "sectionC.principle_7", {}), _get(sebiData, "sectionC.principle_7", {}));
      const completedFields15 = totalFields15.filter(i => i !== "");
      const totalFields16 = keyify(_get(sebiData, "sectionC.principle_8", {}), _get(sebiData, "sectionC.principle_8", {}));
      const completedFields16 = totalFields16.filter(i => i !== "");
      const totalFields17 = keyify(_get(sebiData, "sectionC.principle_9", {}), _get(sebiData, "sectionC.principle_9", {}));
      const completedFields17 = totalFields17.filter(i => i !== "");
      setSubSection({
        company_details: { total: totalFields1.length, completed: completedFields1.length },
        products_services: { total: totalFields2.length, completed: completedFields2.length },
        operation: { total: totalFields3.length, completed: completedFields3.length },
        workforce_data: { total: totalFields4.length, completed: completedFields4.length },
        h_s_a: { total: totalFields5.length, completed: completedFields5.length },
        csr_details: { total: totalFields6.length, completed: completedFields6.length },
        t_and_d: { total: totalFields7.length, completed: completedFields7.length },
        MPD_disclosure: { total: totalFields8.length, completed: completedFields8.length },
        principle_1: { total: totalFields9.length, completed: completedFields9 .length },
        principle_2: { total: totalFields10.length, completed: completedFields10.length },
        principle_3: { total: totalFields11.length, completed: completedFields11.length },
        principle_4: { total: totalFields12.length, completed: completedFields12.length },
        principle_5: { total: totalFields13.length, completed: completedFields13.length },
        principle_6: { total: totalFields14.length, completed: completedFields14.length },
        principle_7: { total: totalFields15.length, completed: completedFields15.length },
        principle_8: { total: totalFields16.length, completed: completedFields16.length },
        principle_9: { total: totalFields17.length, completed: completedFields17.length }
      });
    }
  }, [newReport]);

  const onSectionToggle = (name, number) => {
    if (draftReport[Object.keys(completedSubSection)[activeSubSection-1]]) {
      setShowDraftModal(true);
      saveDraftData();
    }
    if(name === "sectionA" && number === 7){
      setActiveSection(name);
      setActiveSubSection(7);
      return;
    }
    if (name === "sectionA") setActiveSubSection(1);
    if (name === "sectionB") setActiveSubSection(8);
    if (name === "sectionC") setActiveSubSection(9);
    setActiveSection(name);
  };
  const getBusinessDetails = async (selectedYear) => {
    try {
      const response = await AxiosGet(`${API_URLS.GET_BUSINESS_DATA}/${selectedYear}`);
      if (response && response.statusCode === 200) {
        setBusinessDetails(response.data[0]);
        // disclosures_related_to_water.current.total_water_consumption
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  const getInitialReportData = async () => {
    try {
      const response = await AxiosGet(API_URLS.GET_INITIALREPORT_DATA);
      if (response && response.statusCode === 200) {
        setSebiInitialBlankData(() => response.data[0]);
        // disclosures_related_to_water.current.total_water_consumption
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  useEffect(() => {
    getBusinessDetails(year);
  }, [year]);
  const saveDraftData = () => {
    let sectionName;
    if (activeSubSection === 1) {
      sectionName = "Company Details";
      _set(sebiCurrentData, "sectionA.company_details", draftReport.company_details);
    }
    if (activeSubSection === 2) {
      sectionName = "Products/Services";
      _set(sebiCurrentData, "sectionA.products_services", draftReport.products_services);
    }
    if (activeSubSection === 3) {
      sectionName = "Operations";
      _set(sebiCurrentData, "sectionA.operation", draftReport.operation);
    }
    if (activeSubSection === 4) {
      sectionName = "Employees";
      _set(sebiCurrentData, "sectionA.workforce_data", draftReport.workforce_data);
    }
    if (activeSubSection === 5) {
      sectionName = "Holding, Subsidiary and Associate Companies (including joint ventures)";
      _set(sebiCurrentData, "sectionA.h_s_a", draftReport.h_s_a);
    }
    if (activeSubSection === 6) {
      sectionName = "CSR Details";
      _set(sebiCurrentData, "sectionA.csr_details", draftReport.csr_details);
    }
    if (activeSubSection === 7) {
      sectionName = "Transparency and Disclosures Compliances";
      _set(sebiCurrentData, "sectionA.t_and_d", draftReport.t_and_d);
    }
    if (activeSubSection === 8) {
      sectionName = "Managements and Process Disclosures";
      _set(sebiCurrentData, "sectionB.MPD_disclosure", draftReport.MPD_disclosure);
    }
    if (activeSubSection === 9) {
      sectionName = "Principle 1";
      _set(sebiCurrentData, "sectionA.principle_1", draftReport.principle_1);
    }
    if (activeSubSection === 10) {
      sectionName = "Principle 2";
      _set(sebiCurrentData, "sectionA.principle_2", draftReport.principle_2);
    }
    if (activeSubSection === 11) {
      sectionName = "Principle 3";
      _set(sebiCurrentData, "sectionA.principle_3", draftReport.principle_3);
    }
    if (activeSubSection === 12) {
      sectionName = "Principle 4";
      _set(sebiCurrentData, "sectionA.principle_4", draftReport.principle_4);
    }
    if (activeSubSection === 13) {
      sectionName = "Principle 5";
      _set(sebiCurrentData, "sectionA.principle_5", draftReport.principle_5);
    }
    if (activeSubSection === 14) {
      sectionName = "Principle 6";
      _set(sebiCurrentData, "sectionA.principle_6", draftReport.principle_6);
    }
    if (activeSubSection === 15) {
      sectionName = "Principle 7";
      _set(sebiCurrentData, "sectionA.principle_7", draftReport.principle_7);
    }
    if (activeSubSection === 16) {
      sectionName = "Principle 8";
      _set(sebiCurrentData, "sectionA.principle_8", draftReport.principle_8);
    }
    if (activeSubSection === 17) {
      sectionName = "Principle 9";
      _set(sebiCurrentData, "sectionA.principle_9", draftReport.principle_9);
    }
    setPreviousSubSection({
      ...previousSubSection, name: sectionName,
      sectionName: activeSection, subSectionName: Object.keys(completedSubSection)[activeSubSection-1]
    });
  };

  const onSubSectionToggle = (number) => {
    if (draftReport[Object.keys(completedSubSection)[activeSubSection-1]]) {
      setShowDraftModal(true);
      saveDraftData();
    }
    setActiveSubSection(number);
  };

  const years = Array.from(
    { length: 25 },
    (_, i) => `${new Date().getFullYear() - i}-${(Number(new Date().getFullYear().toString().slice(-2)) -i + 1).toString().padStart(2, "0")}`
  );
  const handleYearChange = (e) => {
    setSelectedyear(e.target.value);
    setSebiInitialBlankData();
    const year = e.target.value.split("-")[0];
    setLoading(true);
    setYear(e.target.value.split("-")[0]);
    addSebiData({
      year: year,
      sectionA: {
        company_details: {
          cin: _get(user, "userData.user.companyId.cin", ""),
          name_of_company: _get(user, "userData.user.companyId.companyName", ""),
          year_of_incorporation: _get(user, "userData.user.companyId.yearOfIncorporation", ""),
          corporate_address: _get(user, "userData.user.companyId.corporataeAddress", ""),
          email: _get(user, "userData.user.email", ""),
          telephone: _get(user, "userData.user.mobileNumber", ""),
          website: _get(user, "userData.user.companyId.personalUrl", ""),
          financial_year: year,
          name_of_the_stock_exchange: "",
          paid_up_capital: _get(user, "userData.user.companyId.paidUpCapital", ""),
          name_and_contact_details: "",
          reporting_boundary: ""
        }
      },
      isCreateData: true
    }).then(() => {
      draftSebiData();
      setActiveSection("sectionA");
      setActiveSubSection(1);
      getSebiForm({ year }).then(() => {
        history.push(`/admin/report/brsr/${year}`);
        setLoading(false);
      });
    });
  };
  return (
    <div className="page-content sticky-head-section">
      <SebiModal
        showModal= {showDraftModal}
        setShowModal= {setShowDraftModal}
        onCloseClick= {() => {
          setShowDraftModal(false);
          setPreviousSubSection({ ...previousSubSection, sectionName: "", subSectionName: "", draftSectionName: "" });
        }}
        onSubmitClick= {() => {
          updateSebiData({
            ...sebiCurrentData,
            [previousSubSection.sectionName]: {
              ...sebiCurrentData[previousSubSection.sectionName],
              [previousSubSection.subSectionName]: draftReport[previousSubSection.subSectionName]
            }
          });
          draftSebiData({ ...draftReport, [previousSubSection.subSectionName]: false });
          setPreviousSubSection({ ...previousSubSection, sectionName: "", subSectionName: "", draftSectionName: "" });
          setShowDraftModal(false);
        }}
        sectionTitleName= {previousSubSection.name}
      />
      { loading && <Loading /> }
      <div className="container-fluid">
        <div className="card fixposi">
          <div className="card-body">
            <Row>
              <Col lg={8}>
                <h4 className="card-title mb-4">
                      Business Responsibility and Sustainability Report for the financial year {sebiCurrentData ?
                    sebiCurrentData.year
                    : "" }
                </h4>
              </Col>
              <Col
                lg={4}
                className="right-85"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "right"
                }}
              >
                <Col lg={6} >
                  <select
                    className="form-select"
                    defaultValue={selctedyear}
                    onChange={e => handleYearChange(e)}
                  >
                    <option disabled value="">Select Year</option>
                    {years.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </Col>
              </Col>
            </Row>
            <div className="row">
              <div className="col-lg-12 tab-report">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className={activeSection === "sectionA" ? "nav-link active" : "nav-link"} id="sectionA" data-bs-toggle="tab" data-bs-target="#sectionA-pane"
                      type="button" role="tab" aria-controls="sectionA-pane" aria-selected="true"
                      onClick={() => onSectionToggle("sectionA")}
                    >Section A</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={activeSection === "sectionB" ? "nav-link active" : "nav-link"} id="sectionB" data-bs-toggle="tab" data-bs-target="#sectionB-pane" type="button"
                      role="tab" aria-controls="sectionB-pane" aria-selected="false"
                      onClick={() => onSectionToggle("sectionB")}
                    >Section B</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={activeSection === "sectionC" ? "nav-link active" : "nav-link"} id="sectionC" data-bs-toggle="tab" data-bs-target="#sectionC-pane" type="button"
                      role="tab" aria-controls="sectionC-pane" aria-selected="false"
                      onClick={() => onSectionToggle("sectionC")}
                    >Section C</button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className={activeSection === "sectionA" ? "tab-pane fade show active" : "tab-pane fade show"} id="sectionA" role="tabpanel" aria-labelledby="sectionA" tabIndex="0">
                    <div className="px-0 pt-4 pb-0 mt-3 mb-3">
                      <ul className="step1s1">
                        <li className={activeSubSection === 1 ? "step1 step1--complete step1--process step1--active" : "step1 step1--incomplete step1--active"}
                          onClick={() => onSubSectionToggle(1)}
                        >
                          {subSection.company_details.completed === subSection.company_details.total
                            ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Company Details</span>
                          <span className="step1__label2">[{subSection.company_details.completed} / {subSection.company_details.total}]</span>
                        </li>

                        <li className={activeSubSection === 2
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 2 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(2)}
                        >
                          {subSection.products_services.completed === subSection.products_services.total
                            ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Products/Services</span>
                          <span className="step1__label2">[{subSection.products_services.completed} / {subSection.products_services.total}]</span>
                        </li>

                        <li className={activeSubSection === 3
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 3 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(3)}
                        >
                          {subSection.operation.completed === subSection.operation.total ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Operations</span>
                          <span className="step1__label2">[{subSection.operation.completed} / {subSection.operation.total}]</span>
                        </li>

                        <li className={activeSubSection === 4
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 4 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(4)}
                        >
                          {subSection.workforce_data.completed === subSection.workforce_data.total ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Employees</span>
                          <span className="step1__label2">[{subSection.workforce_data.completed} / {subSection.workforce_data.total}]</span>
                        </li>

                        <li className={activeSubSection === 5
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 5 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(5)}
                        >
                          {subSection.h_s_a.completed === subSection.h_s_a.total ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Holding, Subsidiary and Associate Companies (including joint ventures)</span>
                          <span className="step1__label2">[{subSection.h_s_a.completed} / {subSection.h_s_a.total}]</span>
                        </li>

                        <li className={activeSubSection === 6
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 6 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(6)}
                        >
                          {subSection.csr_details.completed === subSection.csr_details.total ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">CSR Details</span>
                          <span className="step1__label2">[{subSection.csr_details.completed} / {subSection.csr_details.total}]</span>
                        </li>

                        <li className={activeSubSection === 7
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 7 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(7)}
                        >
                          {subSection.t_and_d.completed === subSection.t_and_d.total ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Transparency and  Compliances</span>
                          <span className="step1__label2">[{subSection.t_and_d.completed} / {subSection.t_and_d.total}]</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={activeSection === "sectionB" ? "tab-pane fade show active" : "tab-pane fade show"} id="sectionB-pane" role="tabpanel" aria-labelledby="sectionB" tabIndex="0">
                    <div className="px-0 pt-4 pb-0 mt-3 mb-3">
                      <ul className="step1s1">
                        <li className={activeSubSection === 8
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 8 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(8)}
                        >
                          {subSection.MPD_disclosure.completed === subSection.MPD_disclosure.total
                            ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">MPD Disclosure</span>
                          <span className="step1__label2">[{subSection.MPD_disclosure.completed} / {subSection.MPD_disclosure.total}]</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={activeSection === "sectionC" ? "tab-pane fade show active" : "tab-pane fade show"} id="sectionC-pane" role="tabpanel" aria-labelledby="sectionC" tabIndex="0">
                    <div className="px-0 pt-4 pb-0 mt-3 mb-3">
                      <ul className="step1s1">
                        <li className={activeSubSection === 9 ? "step1 step1--complete step1--process step1--active" : "step1 step1--incomplete step1--active"}
                          onClick={() => onSubSectionToggle(9)}
                        >
                          {subSection.principle_1.completed === subSection.principle_1.total
                            ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Principle 1</span>
                          <span className="step1__label2">[{subSection.principle_1.completed} / {subSection.principle_1.total}]</span>
                        </li>

                        <li className={activeSubSection === 10
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 10 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(10)}
                        >
                          {subSection.principle_2.completed === subSection.principle_2.total
                            ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Principle 2</span>
                          <span className="step1__label2">[{subSection.principle_2.completed} / {subSection.principle_2.total}]</span>
                        </li>

                        <li className={activeSubSection === 11
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 11 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(11)}
                        >
                          {subSection.principle_3.completed === subSection.principle_3.total
                            ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Principle 3</span>
                          <span className="step1__label2">[{subSection.principle_3.completed} / {subSection.principle_3.total}]</span>
                        </li>

                        <li className={activeSubSection === 12
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 12 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(12)}
                        >
                          {subSection.principle_4.completed === subSection.principle_4.total
                            ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Principle 4</span>
                          <span className="step1__label2">[{subSection.principle_4.completed} / {subSection.principle_4.total}]</span>
                        </li>

                        <li className={activeSubSection === 13
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 13 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(13)}
                        >
                          {subSection.principle_5.completed === subSection.principle_5.total
                            ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Principle 5</span>
                          <span className="step1__label2">[{subSection.principle_5.completed} / {subSection.principle_5.total}]</span>
                        </li>

                        <li className={activeSubSection === 14
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 14 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(14)}
                        >
                          {subSection.principle_6.completed === subSection.principle_6.total
                            ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Principle 6</span>
                          <span className="step1__label2">[{subSection.principle_6.completed} / {subSection.principle_6.total}]</span>
                        </li>

                        <li className={activeSubSection === 15
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 15 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(15)}
                        >
                          {subSection.principle_7.completed === subSection.principle_7.total
                            ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Principle 7</span>
                          <span className="step1__label2">[{subSection.principle_7.completed} / {subSection.principle_7.total}]</span>
                        </li>

                        <li className={activeSubSection === 16
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 16 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(16)}
                        >
                          {subSection.principle_8.completed === subSection.principle_8.total
                            ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Principle 8</span>
                          <span className="step1__label2">[{subSection.principle_8.completed} / {subSection.principle_8.total}]</span>
                        </li>

                        <li className={activeSubSection === 17
                          ? "step1 step1--complete step1--process step1--active"
                          : activeSubSection >= 17 ?
                            "step1 step1--complete inactive step1--active"
                            :"step1 step1--incomplete step1--active"}
                        onClick={() => onSubSectionToggle(17)}
                        >
                          {subSection.principle_9.completed === subSection.principle_9.total
                            ?
                            <span className="step1__icon step__done"><i className="bx bx-check"></i></span>
                            : <span className="step1__icon"></span>}
                          <span className="step1__label">Principle 9</span>
                          <span className="step1__label2">[{subSection.principle_9.completed} / {subSection.principle_9.total}]</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {activeSection === "sectionA" && <h3 className="mb-4 head"><b>SECTION A : GENERAL DISCLOSURES</b></h3>}
        {activeSection === "sectionB" && <h3 className="mb-4"><b>SECTION B : MANAGEMENTS AND PROCESS DISCLOSURES</b></h3>}
        {activeSection === "sectionC" && <h3 className="mb-4"><b>SECTION C: PRINCIPLE WISE PERFORMANCE DISCLOSURE</b></h3>}
        {activeSubSection === 1 && <CompanyDetails
          onSectionChange={onSubSectionToggle}
        />}
        {activeSubSection === 2 && <ProductServices
          onSectionChange={onSubSectionToggle}
        />}
        {activeSubSection === 3 && <Operation
          onSectionChange={onSubSectionToggle}
        />}
        {activeSubSection === 4 && <Employees
          onSectionChange={onSubSectionToggle}
        />}
        {activeSubSection === 5 && <HSA
          onSectionChange={onSubSectionToggle}
        />}
        {activeSubSection === 6 && <CSRDetails
          onSectionChange={onSubSectionToggle}
        />}
        {activeSubSection === 7 && <TDC
          onSectionChange={onSubSectionToggle}
          onSectionToggle={onSectionToggle}
        />}
        {activeSubSection === 8 && <MPD
          onSectionChange={onSubSectionToggle}
          onSectionToggle={onSectionToggle}
        />}
        {activeSubSection === 9 && <Principle1
          onSectionChange={onSubSectionToggle}
          onSectionToggle={onSectionToggle}
        />}
        {activeSubSection === 10 && <Principle2
          onSectionChange={onSubSectionToggle}
        />}
        {activeSubSection === 11 && <Principle3
          onSectionChange={onSubSectionToggle}
        />}
        {activeSubSection === 12 && <Principle4
          onSectionChange={onSubSectionToggle}
        />}
        {activeSubSection === 13 && <Principle5
          onSectionChange={onSubSectionToggle}
        />}
        {activeSubSection === 14 && <Principle6
          businessInformation={businessDetails}
          onSectionChange={onSubSectionToggle}
        />}
        {activeSubSection === 15 && <Principle7
          onSectionChange={onSubSectionToggle}
        />}
        {activeSubSection === 16 && <Principle8
          onSectionChange={onSubSectionToggle}
        />}
        {activeSubSection === 17 && <Principle9
          onSectionChange={onSubSectionToggle}
        />}
      </div>
    </div>
  );
}

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    draftReport: state.NewReportReducer.draftSebiForm
  };
}
const mapDispatchToProps = { addSebiData, getSebiForm, draftSebiData, updateSebiData };

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);