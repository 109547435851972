import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, Row, Input, Label, Spinner, Button, TabContent, TabPane } from "reactstrap";
import { GHGFormBuilderPoints } from "../ghgfiles";
import ghgDataPoints from "../ghgDataPoints.json";
import Sidebar from "./Sidebar";
import Loading from "components/Common/Loading";
import { AxiosGet, AxiosPost } from "common/axiosService";
import US from "../US";
import IPCC from "../IPCC";
import { isArray } from "lodash";

function CarbonFootprint () {
  document.title = "Carbon Footprint | GreenAlytics";
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const session = JSON.parse(localStorage.getItem("authUser"));
  const companyId = session.userData.user.companyId ? session.userData.user.companyId._id : "";
  const [items, setItems] = useState([]);
  const [calculate, setCalculate] = useState(false);
  const [submitbtn, setsubmitbtn] = useState(false);
  const [showloader, setShowLoader] = useState(false);
  const [filterditems, setFilterdItems] = useState();
  const [locationList, setLocationList] = useState([]);

  const [customIconActiveTab, setcustomIconActiveTab] = useState("UK");
  const [showformdata, setShowFormData] = useState( { selectCategory: "", mainCategory: "", allData: [] } );
  const [dropDownItems, setDropDownItems] = useState({
    scope: "", factorName: "", categoryName: "", subCategoryName: "", subCategoryName1: "", subCategoryTouched: false,
    subCategory1Touched: false, activities: [], fuleTypes: [], units: [], selectedCategory: "", selectedsubCategory: "",
    selectedsubCategory1: "", selectedsubCategoryco2: "", name: "", dataPoints: [], formFiled: [], factorName1: ""
  });
  const [value, setvalue]= useState({
    locationId: "", year: "", month: "", category: "", activity: "", fuletype: "", conversion_factor: "", unit: "", fuel_quantity: "", emission_factor: "", comment: "", file: ""
  });
  /*using use effect for getting locations for loacation dropdown*/
  useEffect(() => {
    setcustomIconActiveTab("UK"),
    (async () => {
      let alldataaaaa;
      let selectedCategoryyyyy;
      function mapRecursiveData (data) {
        data?.map((subItem, subIndex) => {
          if (subIndex === 0) {
            subItem.isOpen = true;
            alldataaaaa = subItem?.data;
            selectedCategoryyyyy = subItem;
            if (subItem?.subCategory?.length > 0) {
              mapRecursiveData(subItem?.subCategory);
            }
          } else {
            subItem.isOpen = false;
          }
        });
      }
      const filterData = GHGFormBuilderPoints?.map((item, index) => {
        if (index === 0) {
          item.isOpen = true;
          alldataaaaa = item?.data;
          if (item?.subCategory?.length > 0) {
            mapRecursiveData(item?.subCategory);
          }
        } else {
          item.isOpen = false;
        }
        return item;
      });
      if (GHGFormBuilderPoints[0]?._id === selectedCategoryyyyy?._id) {
        setShowFormData({ ...showformdata, mainCategory: "", selectCategory: selectedCategoryyyyy, allData: alldataaaaa });
      } else {
        setShowFormData({ ...showformdata, mainCategory: GHGFormBuilderPoints[0], selectCategory: selectedCategoryyyyy, allData: alldataaaaa });
      }

      const selectedItem = ghgDataPoints.UK.find(i => i.factorName == selectedCategoryyyyy.categoryName);

      if (selectedItem && selectedItem.activities) {
        setDropDownItems({
          scope: selectedCategoryyyyy.categoryName,
          factorName: selectedCategoryyyyy.categoryName,
          categoryName: selectedItem.categoryName, subCategoryName: selectedItem.subCategoryName,
          subCategoryName1: selectedItem.subCategoryName1, activities: selectedItem.activities, fuleTypes: [], units: [],
          selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: selectedItem.name,
          dataPoints: selectedItem.dataPoints ? selectedItem.dataPoints : [], selectedsubCategoryco2: "",
          formFiled: selectedItem.formFiled ? selectedItem.formFiled : []
        });
        setvalue({ locationId: "", year: "", month: "", category: "", activity: "", unit: "", fuel_quantity: "", emission_factor: "", comment: "", file: "" });
        setCalculate(false);
      } else {
        setDropDownItems({
          scope: "", factorName: "", categoryName: "", subCategoryName: "",
          subCategoryName1: "", activities: [], fuleTypes: [], units: [], selectedsubCategoryco2: "",
          selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: "",
          dataPoints: [], factorName1: ""
        });
      }
      setItems(filterData );
      try {
        const response = await AxiosGet(`${apipath}/locations/${companyId}`, {});
        if (response && response.statusCode === 200) {
          setLocationList(response.data);
          setShowLoader(true);
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, []);
  /*function for tabs*/
  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab);
    }
  };

  /*getting years array for year dropdown*/
  const years = Array.from(
    { length: 20 },
    (_, i) => new Date().getFullYear() - i
  );

  /*code for onChange function*/
  const handleFormData = (e) => {
    setvalue({ ...value, [e.target.name]: e.target.value });
  };

  /*code for get json from json file for dropdown*/
  const Jsondata = (str) => {
    setDropDownItems({ ...dropDownItems, activities: [] });
    if (str === "UK") {
      const selectedItem = ghgDataPoints.UK.find(i => i.factorName == showformdata.selectCategory.categoryName);
      if (selectedItem && selectedItem.activities) {
        setTimeout(() => {
          setDropDownItems({
            scope: showformdata.mainCategory.categoryName,
            factorName: showformdata.selectCategory.categoryName,
            categoryName: selectedItem.categoryName, subCategoryName: selectedItem.subCategoryName,
            subCategoryName1: selectedItem.subCategoryName1, activities: selectedItem.activities, fuleTypes: [], units: [],
            selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: selectedItem.name,
            dataPoints: selectedItem.dataPoints ? selectedItem.dataPoints : [], selectedsubCategoryco2: "",
            formFiled: selectedItem.formFiled ? selectedItem.formFiled : []
          });
        }, 10);
        setvalue({ locationId: "", year: "", month: "", category: "", activity: "", unit: "", fuel_quantity: "", emission_factor: "", comment: "", file: "" });
        setCalculate(false);
      }else {
        setDropDownItems({
          scope: "", factorName: "", categoryName: "", subCategoryName: "",
          subCategoryName1: "", activities: [], fuleTypes: [], units: [], selectedsubCategoryco2: "",
          selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: "",
          dataPoints: [], factorName1: ""
        });
      }
    }
    if(str === "US"){
      const selectedItem = ghgDataPoints.US.find(i => i.factorName == showformdata.selectCategory.categoryName);
      if (selectedItem && selectedItem.activities) {
        setTimeout(() => {
          setDropDownItems({
            scope: showformdata.selectCategory.categoryName,
            factorName: showformdata.selectCategory.categoryName,
            categoryName: selectedItem.categoryName, subCategoryName: selectedItem.subCategoryName,
            subCategoryName1: selectedItem.subCategoryName1, activities: selectedItem.activities, fuleTypes: [], units: [],
            selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: selectedItem.name,
            dataPoints: selectedItem.dataPoints ? selectedItem.dataPoints : [], selectedsubCategoryco2: "",
            formFiled: selectedItem.formFiled ? selectedItem.formFiled : []
          });
        }, 10);
        setvalue({ locationId: "", year: "", month: "", category: "", activity: "", unit: "", fuel_quantity: "", emission_factor: "", comment: "", file: "" });
        setCalculate(false);
      } else {
        setDropDownItems({
          scope: "", factorName: "", categoryName: "", subCategoryName: "",
          subCategoryName1: "", activities: [], fuleTypes: [], units: [], selectedsubCategoryco2: "",
          selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: "",
          dataPoints: [], factorName1: ""
        });
      }
    }
    if(str === "IPCC"){
      const selectedItem = ghgDataPoints.IPCC.find(i => i.factorName == showformdata.selectCategory.categoryName);
      if (selectedItem && selectedItem.activities) {
        setTimeout(() => {
          setDropDownItems({
            scope: showformdata.mainCategory.categoryName,
            factorName: showformdata.selectCategory.categoryName,
            categoryName: selectedItem.categoryName, subCategoryName: selectedItem.subCategoryName,
            subCategoryName1: selectedItem.subCategoryName1, activities: selectedItem.activities, fuleTypes: [], units: [],
            selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: selectedItem.name,
            dataPoints: selectedItem.dataPoints ? selectedItem.dataPoints : [], selectedsubCategoryco2: "",
            formFiled: selectedItem.formFiled ? selectedItem.formFiled : []
          });
        }, 10);
        setvalue({ locationId: "", year: "", month: "", category: "", activity: "", unit: "", fuel_quantity: "", emission_factor: "", comment: "", file: "" });
        setCalculate(false);
      } else {
        setDropDownItems({
          scope: "", factorName: "", categoryName: "", subCategoryName: "",
          subCategoryName1: "", activities: [], fuleTypes: [], units: [], selectedsubCategoryco2: "",
          selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: "",
          dataPoints: [], factorName1: ""
        });
      }
    }

  };
  /*code for search bar*/
  let timerID;
  async function main () {
    // setcustomIconActiveTab("UK");
    let name = document.getElementById("category").value;
    let res = await filterBy(items, name);
    if (res.length > 0) {
      let alldataaaaa;
      let selectedCategoryyyyy;
      let mainCategoryyyy;
      function mapRecursiveData (data) {
        data?.map((subItem, subIndex) => {
          if (subIndex === 0) {
            subItem.isOpen = true;
            alldataaaaa = subItem?.data;
            selectedCategoryyyyy = subItem;
            if (subItem?.subCategory?.length > 0) {
              mapRecursiveData(subItem?.subCategory);
            }
          } else {
            subItem.isOpen = false;
          }
        });
      }
      const filterData = res?.map((item, index) => {
        if (index === 0) {
          item.isOpen = true;
          alldataaaaa = item?.data;
          selectedCategoryyyyy = item;
          mainCategoryyyy = item;
          if (item?.subCategory?.length > 0) {
            mapRecursiveData(item?.subCategory);
          }
        } else {
          item.isOpen = false;
        }
        return item;
      });
      if (mainCategoryyyy?._id === selectedCategoryyyyy?._id) {
        mainCategoryyyy = "";
        setShowFormData({ ...showformdata, mainCategory: "", selectCategory: selectedCategoryyyyy, allData: alldataaaaa });
      } else {
        setShowFormData({ ...showformdata, mainCategory: mainCategoryyyy, selectCategory: selectedCategoryyyyy, allData: alldataaaaa });
      }
      setFilterdItems(filterData);
      const selectedItem = ghgDataPoints.UK.find(i => i.factorName == selectedCategoryyyyy.categoryName);
      if (selectedItem) {
        if (selectedItem && selectedItem.activities) {
          setDropDownItems({
            scope: mainCategoryyyy.categoryName ? mainCategoryyyy.categoryName : selectedCategoryyyyy.categoryName,
            factorName: selectedCategoryyyyy.categoryName,
            categoryName: selectedItem.categoryName, subCategoryName: selectedItem.subCategoryName,
            subCategoryName1: selectedItem.subCategoryName1, activities: selectedItem.activities, fuleTypes: [], units: [],
            selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: selectedItem.name,
            dataPoints: selectedItem.dataPoints ? selectedItem.dataPoints : [], selectedsubCategoryco2: "",
            formFiled: selectedItem.formFiled ? selectedItem.formFiled : []
          });
          setvalue({ locationId: "", year: "", month: "", category: "", activity: "", unit: "", fuel_quantity: "", emission_factor: "", comment: "", file: "" });
          setCalculate(false);
        } else{
          setDropDownItems({
            scope: mainCategoryyyy.categoryName ? mainCategoryyyy.categoryName : selectedCategoryyyyy.categoryName,
            factorName: selectedCategoryyyyy.categoryName,
            categoryName: selectedItem.categoryName, subCategoryName: selectedItem.subCategoryName,
            subCategoryName1: selectedItem.subCategoryName1, activities: [], fuleTypes: [], units: [],
            selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: selectedItem.name,
            dataPoints: selectedItem.dataPoints ? selectedItem.dataPoints : [], selectedsubCategoryco2: "",
            formFiled: selectedItem.formFiled ? selectedItem.formFiled : [], factorName1: selectedItem.factorName1 ? selectedItem.factorName1 : ""
          });
          setvalue({ locationId: "", year: "", month: "", category: "", activity: "", unit: "", fuel_quantity: "", emission_factor: "", comment: "", file: "" });
          setCalculate(false);
        }
      } else {
        setDropDownItems({
          scope: "", factorName: "", categoryName: "", subCategoryName: "",
          subCategoryName1: "", activities: [], fuleTypes: [], units: [], selectedsubCategoryco2: "",
          selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: "",
          dataPoints: [], factorName1: ""
        });
      }
    } else {
      if (name) {
        setFilterdItems([]);
      } else {
        setFilterdItems();
      }
    }
  }
  function filterBy (arr, query) {
    return query
      ? arr.reduce((acc, item) => {
        if (item.subCategory?.length) {
          const filtered = filterBy(item.subCategory, query);
          if (filtered.length)
            return [...acc, { ...item, subCategory: filtered }];
        }
        const { ...itemWithoutChildren } = item;
        return item.categoryName?.toLowerCase().includes(query.toLowerCase())
          ? [...acc, itemWithoutChildren]
          : acc;
      }, [])
      : arr;
  }
  function debounce (func, time) {
    if (timerID) {
      clearTimeout(timerID);
    }
    timerID = setTimeout(function () {
      func();
    }, time);
  }

  /*code for first dropdown*/
  const firstDropDownSelect= (e) => {
    const data = dropDownItems.activities;
    setDropDownItems({ ...dropDownItems, subCategory1Touched: false, subCategoryTouched: false, fuleTypes: [], units: [] });
    if (e.target.value) {
      const selectedItem = data.find(i=> i.name === e.target.value);
      setvalue({ ...value, [e.target.name]: e.target.value, fuletype: "", unit: "" });
      setTimeout(() => {
        setDropDownItems({
          ...dropDownItems, subCategory1Touched: false, subCategoryTouched: false, fuleTypes: selectedItem.fuleTypes,
          units: [], selectedCategory: e.target.value, selectedsubCategory: "", selectedsubCategory1: "", selectedsubCategoryco2: ""
        });
      }, 10);
    }
  };

  /*code for second dropdown*/
  const secondDropDownSelect= (e) => {
    if (e.target.value !== "0") {
      const data = dropDownItems.fuleTypes;
      const selectedItem = data.find(i=> i.name === e.target.value);
      setvalue({ ...value, [e.target.name]: e.target.value, unit: "" });
      if(dropDownItems.scope === "Scope 2"){
        setDropDownItems({ ...dropDownItems, selectedsubCategory1: e.target.value, selectedsubCategoryco2: selectedItem.value });
      } else{
        setDropDownItems({ ...dropDownItems, selectedsubCategoryco2: "", subCategory1Touched: false, units: [], selectedsubCategory: e.target.value, selectedsubCategory1: "" });
        setTimeout(() => {
          setDropDownItems({ ...dropDownItems, selectedsubCategoryco2: "", subCategory1Touched: false, units: selectedItem.units, selectedsubCategory: e.target.value, selectedsubCategory1: "" });
        }, 10);
      }
    } else {
      setDropDownItems({ ...dropDownItems, selectedsubCategoryco2: "", subCategory1Touched: false, units: [], selectedsubCategory: e.target.value, selectedsubCategory1: "" });
    }
  };

  /*code for third dropdown*/
  const thirdDropDownSelect= (e) => {
    const co2Value = dropDownItems.units.find(item => item.name === e.target.value);
    setvalue({ ...value, [e.target.name]: e.target.value });
    setDropDownItems({ ...dropDownItems, selectedsubCategory1: e.target.value, selectedsubCategoryco2: co2Value.value });
  };

  /*after calculation all values total*/
  let totalCalculation = 0;
  if (customIconActiveTab === "UK") {
    totalCalculation = ( +(value.fuel_quantity) * +(dropDownItems.selectedsubCategoryco2));
  }
  if (customIconActiveTab === "US") {
    dropDownItems?.selectedsubCategoryco2 && isArray(dropDownItems?.selectedsubCategoryco2) && dropDownItems?.selectedsubCategoryco2?.forEach((item, i) => {
      if(i == 1){
        totalCalculation += ((+value.fuel_quantity) * +(item[Object.keys(item)[0]]) * 25);
      }
      else if(i == 2){
        totalCalculation += ((+value.fuel_quantity) * +(item[Object.keys(item)[0]]) * 298);
      }
      else{
        totalCalculation += ((+value.fuel_quantity) * +(item[Object.keys(item)[0]]));
      }
    });
  }
  if (customIconActiveTab === "IPCC") {
    dropDownItems?.selectedsubCategoryco2 && isArray(dropDownItems?.selectedsubCategoryco2) && dropDownItems?.selectedsubCategoryco2?.forEach((item, i) => {
      // totalCalculation += ((+value.fuel_quantity) * +(item[Object.keys(item)[0]]));
      if(i == 1){
        totalCalculation += ((+value.fuel_quantity) * +(value.conversion_factor) * +(item[Object.keys(item)[0]]) * 25);
      }
      else if(i == 2){
        totalCalculation += ((+value.fuel_quantity) * +(value.conversion_factor) * +(item[Object.keys(item)[0]]) * 298);
      }
      else{
        totalCalculation += ((+value.fuel_quantity) * +(value.conversion_factor) * +(item[Object.keys(item)[0]]));
      }
    });
  }
  /*code for post request*/
  const formResult = async (e) => {
    e.preventDefault();
    setsubmitbtn(true);
    const manualFormData = new FormData();
    manualFormData.append("locationId", value.locationId);
    manualFormData.append("year", value.year);
    manualFormData.append("month", value.month);
    manualFormData.append("scope", showformdata.mainCategory.categoryName ? showformdata.mainCategory.categoryName : showformdata.selectCategory.categoryName);
    manualFormData.append("category", customIconActiveTab === "UK"
      ? dropDownItems?.factorName === "Stationary combustion" ? value.category : dropDownItems.scope === "Scope 3" ? value.activity : dropDownItems.selectedCategory
      : dropDownItems?.factorName === "Stationary combustion"
        ? dropDownItems.selectedCategory
        : dropDownItems.scope === "Scope 3" ? value.activity: dropDownItems.selectedCategory );
    manualFormData.append("subCategory1", dropDownItems.scope === "Scope 3" ? value.activity : dropDownItems.selectedCategory);
    manualFormData.append("factorName", dropDownItems.factorName);
    manualFormData.append("factorName1", dropDownItems.scope === "Scope 3" ? dropDownItems.factorName1 : dropDownItems.factorName);
    if(dropDownItems.selectedsubCategory1){
      manualFormData.append("subCategory2", dropDownItems.selectedsubCategory);
      manualFormData.append("units", dropDownItems.selectedsubCategory1);
    } else{
      manualFormData.append("units", value.unit ? value.unit : dropDownItems.selectedsubCategory);
    }
    manualFormData.append("value", showformdata.mainCategory.categoryName === "Scope 3"
      ? ( +(value.fuel_quantity) * +(value.emission_factor))
      : totalCalculation);
    manualFormData.append("fuel_quantity", value.fuel_quantity);
    manualFormData.append("emission_factor", dropDownItems.scope === "Scope 3"
      ? JSON.stringify([{ "CO₂": value.emission_factor }])
      : customIconActiveTab === "UK" ?
        JSON.stringify([{ "CO₂": dropDownItems.selectedsubCategoryco2 } ])
        :JSON.stringify(dropDownItems.selectedsubCategoryco2));
    manualFormData.append("comment", value.comment);
    value.file && manualFormData.append("file", value.file);

    try {
      const response = await AxiosPost(`${apipath}/save-carbon-data`, manualFormData);
      if (response && response.statusCode === 200) {
        toast.success(response.message, { autoClose: 2000 });
        setvalue({ locationId: "", year: "", month: "", category: "", fuletype: "", activity: "", unit: "", fuel_quantity: "", emission_factor: "", comment: "", file: "" });
        fileRef.current.value = "";
        setDropDownItems({
          ...dropDownItems, fuleTypes: [], units: [], subCategoryTouched: false, subCategory1Touched: false, selectedCategory: "",
          selectedsubCategory: "", selectedsubCategory1: "", selectedsubCategoryco2: ""
        });
        setCalculate(false);
        setsubmitbtn(false);
      } else {
        setCalculate(false);
        setsubmitbtn(false);
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
    document.getElementById("subCategory1").selectedIndex = 0;
    document.getElementById("subCategory2").selectedIndex = 0;
    document.getElementById("subCategory3").selectedIndex = 0;
    const activities = dropDownItems.activities;
    setDropDownItems({
      ...dropDownItems, activities: [], fuleTypes: [], units: [], subCategoryTouched: false, subCategory1Touched: false, selectedCategory: "",
      selectedsubCategory: "", selectedsubCategory1: "", selectedsubCategoryco2: ""
    });
    setTimeout(() => {
      setDropDownItems({
        ...dropDownItems, activities: activities, fuleTypes: customIconActiveTab === "IPCC" ? dropDownItems.fuleTypes : [], units: [],
        subCategoryTouched: false, subCategory1Touched: false, selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", selectedsubCategoryco2: ""
      });
    }, 10);
    // setDropDownItems({
    //   ...dropDownItems, activities: activities, fuleTypes: customIconActiveTab === "IPCC" ? dropDownItems.fuleTypes : [], units: [],
    //   subCategoryTouched: false, subCategory1Touched: false, selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", selectedsubCategoryco2: ""
    // });
    setvalue({
      locationId: "", conversion_factor: "", year: "", month: "", category: "", activity: customIconActiveTab === "IPCC" ? value.activity : "",
      unit: "", fuel_quantity: "", emission_factor: "", comment: "", fuletype: "", file: ""
    });
    setCalculate(false);
  };
  /*use ref for formatting file imput after post request*/
  const fileRef = useRef();

  return showloader
    ? (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Col lg={4}>
                <Card id="Border-card">
                  <CardBody>
                    {/* <div className={showformdata?.selectCategory?.categoryName !== "Stationary combustion" ? "plusiconfreez mb-3" : "mb-3"}> */}
                    <div className="Stationary combustion mb-3">
                      <Label>Select Reporting Framework</Label>
                      <select
                        name="typeOfData"
                        className="Stationary combustion form-select"
                        // className={showformdata?.selectCategory?.categoryName !== "Stationary combustion" ? "plusiconfreez form-select" : "form-select"}
                        value={customIconActiveTab}
                        onChange={e => {
                          Jsondata(e.target.value);
                          toggleIconCustom(e.target.value);
                        }}
                      >
                        <option value="UK">UK</option>
                        <option value="US">US</option>
                        <option value="IPCC">IPCC</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <Label>Select Version</Label>
                      <select
                        name="typeOfData"
                        className="form-select"
                      >
                        {customIconActiveTab === "IPCC" && <option value="2006">2006</option>}
                        {customIconActiveTab === "UK" && <option value="2022">2022</option>}
                        {customIconActiveTab === "US" && <option value="April-2022">April - 2022</option>}
                      </select>
                    </div>
                    {dropDownItems.categoryName && customIconActiveTab === "IPCC" && showformdata?.selectCategory?.categoryName === "Stationary combustion" &&
                    <div className="mb-3">
                      <Label>Select {dropDownItems.categoryName}</Label>
                      <select
                        id="subCategory1"
                        name="activity"
                        value={value.activity}
                        className="form-select"
                        onChange={(e) => firstDropDownSelect(e)}
                      >
                        <option value="">Select {dropDownItems.categoryName}</option>
                        {showformdata?.selectCategory?.categoryName === "Stationary combustion" && dropDownItems?.activities.map((item, index) => (
                          <option key={`activity${index}`} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>}
                    <div className="positionRelative">
                      <Input
                        id="category"
                        type="text"
                        placeholder="Search data points..."
                        className="formControl search-input"
                        onChange={() => debounce(main, 1000)}
                      ></Input>
                    </div>
                    {filterditems
                      ? (
                        filterditems.length > 0 ?
                          <div id="Border-card">
                            <div>
                              <Sidebar
                                onFormDataShow={(e) => {
                                  // setcustomIconActiveTab("UK");
                                  setShowFormData({ ...showformdata, allData: [] });
                                  let alldataaaaa;
                                  let selectedCategoryyyyy;
                                  let mainCategoryyyy;
                                  if (!e.mainCategory) {
                                    function mapRecursiveData (data) {
                                      const categorySelected = selectedCategoryyyyy ? selectedCategoryyyyy : e?.selectCategory;
                                      data?.map(subItem => {
                                        if (subItem?._id === categorySelected?._id) {
                                          subItem.isOpen = true;
                                          if (subItem?.subCategory?.length > 0) {
                                            alldataaaaa =subItem.subCategory[0]?.data;
                                            selectedCategoryyyyy = subItem.subCategory[0];
                                            mapRecursiveData(subItem?.subCategory);
                                          }
                                        } else {
                                          subItem.isOpen = false;
                                        }
                                      });
                                    }
                                    filterditems?.map((item) => {
                                      const categorySelected = selectedCategoryyyyy ? selectedCategoryyyyy : e?.selectCategory;
                                      if (item?._id === categorySelected?._id) {
                                        mainCategoryyyy = item;
                                        item.isOpen = true;
                                        if (item?.subCategory?.length > 0) {
                                          alldataaaaa =item.subCategory[0]?.data;
                                          selectedCategoryyyyy = item.subCategory[0];
                                          mapRecursiveData(item?.subCategory);
                                        }
                                      } else {
                                        mapRecursiveData(item?.subCategory);
                                        item.isOpen = false;
                                      }
                                      return item;
                                    });
                                  } else {
                                    mainCategoryyyy = e?.mainCategory;
                                    function mapRecursiveData (data) {
                                      const categorySelected = selectedCategoryyyyy ? selectedCategoryyyyy : e?.selectCategory;
                                      data && data?.forEach(subItem => {
                                        if (subItem._id === categorySelected?._id) {
                                          data.map(item => {
                                            if (item?._id === categorySelected?._id) {
                                              const isAnyOpen = data.some(i => i.isOpen);
                                              if (isAnyOpen) { item.isOpen = true; } else { item.isOpen = !item.isOpen; }
                                              if (item?.subCategory?.length > 0) {
                                                alldataaaaa =item.subCategory[0]?.data;
                                                selectedCategoryyyyy = item.subCategory[0];
                                                mapRecursiveData(item?.subCategory);
                                              }
                                            } else { item.isOpen = false; }
                                          });
                                        } else {
                                          if (subItem?.subCategory?.length > 0) { mapRecursiveData(subItem?.subCategory); }
                                        }
                                      });
                                    }
                                    filterditems?.map((item) => {
                                      if (item?._id === e?.mainCategory?._id) { mapRecursiveData(item?.subCategory); }
                                      return item;
                                    });
                                  }
                                  const selectedCategoryData = selectedCategoryyyyy ? selectedCategoryyyyy : e.selectCategory;
                                  if (mainCategoryyyy?._id === selectedCategoryData?._id) {
                                    mainCategoryyyy = "";
                                  }
                                  if (selectedCategoryData) {
                                    const selectedItem = ghgDataPoints[customIconActiveTab].find(i => i.factorName == selectedCategoryData.categoryName);
                                    if (selectedItem) {
                                      if (selectedItem && selectedItem.activities) {
                                        setDropDownItems({
                                          scope: mainCategoryyyy.categoryName ? mainCategoryyyy.categoryName : selectedCategoryData.categoryName,
                                          factorName: selectedCategoryData.categoryName,
                                          categoryName: selectedItem.categoryName, subCategoryName: selectedItem.subCategoryName,
                                          subCategoryName1: selectedItem.subCategoryName1, activities: selectedItem.activities, fuleTypes: [], units: [],
                                          selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: selectedItem.name,
                                          dataPoints: selectedItem.dataPoints ? selectedItem.dataPoints : [], selectedsubCategoryco2: "",
                                          formFiled: selectedItem.formFiled ? selectedItem.formFiled : []
                                        });
                                        setvalue({
                                          locationId: "", year: "", month: "", category: "", activity: "", unit: "", fuel_quantity: "",
                                          fuletype: "", emission_factor: "", comment: "", file: ""
                                        });
                                        setCalculate(false);
                                      } else{
                                        setDropDownItems({
                                          scope: mainCategoryyyy.categoryName ? mainCategoryyyy.categoryName : selectedCategoryData.categoryName,
                                          factorName: selectedCategoryData.categoryName,
                                          categoryName: selectedItem.categoryName, subCategoryName: selectedItem.subCategoryName,
                                          subCategoryName1: selectedItem.subCategoryName1, activities: [], fuleTypes: [], units: [],
                                          selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: selectedItem.name,
                                          dataPoints: selectedItem.dataPoints ? selectedItem.dataPoints : [], selectedsubCategoryco2: "",
                                          formFiled: selectedItem.formFiled ? selectedItem.formFiled : [], factorName1: selectedItem.factorName1 ? selectedItem.factorName1 : ""
                                        });
                                        setvalue({
                                          locationId: "", year: "", month: "", category: "", activity: "", unit: "", fuel_quantity: "",
                                          fuletype: "", emission_factor: "", comment: "", file: ""
                                        });
                                        setCalculate(false);
                                      }
                                    } else {
                                      setDropDownItems({
                                        scope: "", factorName: "", categoryName: "", subCategoryName: "", subCategoryName1: "",
                                        activities: [], fuleTypes: [], units: [], selectedsubCategoryco2: "", selectedCategory: "",
                                        selectedsubCategory: "", selectedsubCategory1: "", name: "", dataPoints: [], factorName1: ""
                                      });
                                    }
                                  }
                                  if (selectedCategoryyyyy) {
                                    setTimeout(() => {
                                      setShowFormData({ ...showformdata, selectCategory: selectedCategoryyyyy, mainCategory: mainCategoryyyy, allData: alldataaaaa });
                                    }, 100);
                                  } else {
                                    setTimeout(() => {
                                      setShowFormData({ ...showformdata, selectCategory: e.selectCategory, mainCategory: mainCategoryyyy, allData: e.allData });
                                    }, 100);
                                  }
                                }}
                                items={filterditems}
                              />
                            </div>
                          </div>
                          : <div>No Data</div>
                      )
                      : (
                        <div className="scroll" id="Border-card">
                          <div>
                            <Sidebar
                              onFormDataShow={(e) => {
                                // setcustomIconActiveTab("UK");
                                setShowFormData({ ...showformdata, allData: [] });
                                let alldataaaaa;
                                let selectedCategoryyyyy;
                                let mainCategoryyyy;
                                if (!e.mainCategory) {
                                  function mapRecursiveData (data) {
                                    const categorySelected = selectedCategoryyyyy ? selectedCategoryyyyy : e?.selectCategory;
                                    data?.map(subItem => {
                                      if (subItem?._id === categorySelected?._id) {
                                        subItem.isOpen = true;
                                        if (subItem?.subCategory?.length > 0) {
                                          alldataaaaa =subItem.subCategory[0]?.data;
                                          selectedCategoryyyyy = subItem.subCategory[0];
                                          mapRecursiveData(subItem?.subCategory);
                                        }
                                      } else { subItem.isOpen = false; }
                                    });
                                  }
                                  items?.map((item) => {
                                    const categorySelected = selectedCategoryyyyy ? selectedCategoryyyyy : e?.selectCategory;
                                    if (item?._id === categorySelected?._id) {
                                      mainCategoryyyy = item;
                                      item.isOpen = true;
                                      if (item?.subCategory?.length > 0) {
                                        alldataaaaa =item.subCategory[0]?.data;
                                        selectedCategoryyyyy = item.subCategory[0];
                                        mapRecursiveData(item?.subCategory);
                                      }
                                    } else {
                                      mapRecursiveData(item?.subCategory);
                                      item.isOpen = false;
                                    }
                                    return item;
                                  });
                                } else {
                                  mainCategoryyyy = e.mainCategory;
                                  function mapRecursiveData (data) {
                                    const categorySelected = selectedCategoryyyyy ? selectedCategoryyyyy : e?.selectCategory;
                                    data?.forEach(subItem => {
                                      if (subItem._id === categorySelected?._id) {
                                        data.map(item => {
                                          if (item?._id === categorySelected?._id) {
                                            const isAnyOpen = data.some(i => i.isOpen);
                                            if (isAnyOpen) { item.isOpen = true; } else { item.isOpen = !item.isOpen; }
                                            if (item?.subCategory?.length > 0) {
                                              alldataaaaa =item.subCategory[0]?.data;
                                              selectedCategoryyyyy = item.subCategory[0];
                                              mapRecursiveData(item?.subCategory);
                                            }
                                          } else { item.isOpen = false; }
                                        });
                                      } else {
                                        if (subItem?.subCategory?.length > 0) { mapRecursiveData(subItem?.subCategory); }
                                      }
                                    });
                                  }
                                  items?.map((item) => {
                                    if (item?._id === e?.mainCategory?._id) { mapRecursiveData(item?.subCategory); }
                                    return item;
                                  });
                                }
                                const selectedCategoryData = selectedCategoryyyyy ? selectedCategoryyyyy : e.selectCategory;
                                if (mainCategoryyyy?._id === selectedCategoryData?._id) {
                                  mainCategoryyyy = "";
                                }
                                if (selectedCategoryData) {
                                  const selectedItem = ghgDataPoints[customIconActiveTab].find(i => i.factorName == selectedCategoryData.categoryName);
                                  if (selectedItem) {
                                    if (selectedItem && selectedItem.activities) {
                                      setDropDownItems({
                                        scope: mainCategoryyyy.categoryName ? mainCategoryyyy.categoryName : selectedCategoryData.categoryName,
                                        factorName: selectedCategoryData.categoryName,
                                        categoryName: selectedItem.categoryName, subCategoryName: selectedItem.subCategoryName,
                                        subCategoryName1: selectedItem.subCategoryName1, activities: selectedItem.activities, fuleTypes: [], units: [],
                                        selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: selectedItem.name,
                                        dataPoints: selectedItem.dataPoints ? selectedItem.dataPoints : [], selectedsubCategoryco2: "",
                                        formFiled: selectedItem.formFiled ? selectedItem.formFiled : []
                                      });
                                      setvalue({
                                        locationId: "", year: "", month: "", category: "", activity: "", unit: "", fuel_quantity: "",
                                        fuletype: "", emission_factor: "", comment: "", file: ""
                                      });
                                      setCalculate(false);
                                    }
                                    else{
                                      setDropDownItems({
                                        scope: mainCategoryyyy.categoryName ? mainCategoryyyy.categoryName : selectedCategoryData.categoryName,
                                        factorName: selectedCategoryData.categoryName,
                                        categoryName: selectedItem.categoryName, subCategoryName: selectedItem.subCategoryName,
                                        subCategoryName1: selectedItem.subCategoryName1, activities: [], fuleTypes: [], units: [],
                                        selectedCategory: "", selectedsubCategory: "", selectedsubCategory1: "", name: selectedItem.name,
                                        dataPoints: selectedItem.dataPoints ? selectedItem.dataPoints : [], selectedsubCategoryco2: "",
                                        formFiled: selectedItem.formFiled ? selectedItem.formFiled : [], factorName1: selectedItem.factorName1 ? selectedItem.factorName1 : ""
                                      });
                                      setvalue({
                                        locationId: "", year: "", month: "", category: "", activity: "", unit: "", fuel_quantity: "",
                                        fuletype: "", emission_factor: "", comment: "", file: ""
                                      });
                                      setCalculate(false);
                                    }
                                  }
                                  else {
                                    setDropDownItems({
                                      scope: "", factorName: "", categoryName: "", subCategoryName: "", subCategoryName1: "",
                                      activities: [], fuleTypes: [], units: [], selectedsubCategoryco2: "", selectedCategory: "",
                                      selectedsubCategory: "", selectedsubCategory1: "", name: "", dataPoints: [], factorName1: ""
                                    });
                                  }
                                }
                                if (selectedCategoryyyyy) {
                                  setTimeout(() => {
                                    setShowFormData({
                                      ...showformdata,
                                      selectCategory: selectedCategoryyyyy, mainCategory: mainCategoryyyy, allData: alldataaaaa
                                    });
                                  }, 100);
                                } else {
                                  setTimeout(() => {
                                    setShowFormData({
                                      ...showformdata,
                                      selectCategory: e.selectCategory, mainCategory: mainCategoryyyy, allData: e.allData
                                    });
                                  }, 100);
                                }
                              }}
                              items={items}
                            />
                          </div>
                        </div>
                      )}
                  </CardBody>
                </Card>
              </Col>
              {showformdata.allData.length > 0 && (
                <Col lg={8}>
                  <Card>
                    <CardBody>
                      {/* <Nav className={showformdata?.selectCategory?.categoryName === "Stationary combustion" ? "nav-tabs-custom nav-justified" : "nav-tabs-custom nav-justified plusiconfreez"}>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({ active: customIconActiveTab === "UK" })}
                            onClick={() => {
                              Jsondata("UK");
                              toggleIconCustom("UK");
                            }}>
                            <h6 className="d-none d-sm-block"><b>UK</b></h6>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customIconActiveTab === "US"
                            })}
                            onClick={() => {
                              Jsondata("US");
                              toggleIconCustom("US");
                            }}>
                            <h6 className="d-none d-sm-block"><b>US</b></h6>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customIconActiveTab === "IPCC"
                            })}
                            onClick={() => {
                              toggleIconCustom("IPCC");
                              Jsondata("IPCC");
                            }}>
                            <h6 className="d-none d-sm-block"><b>IPCC</b></h6>
                          </NavLink>
                        </NavItem>
                      </Nav> */}
                      <TabContent
                        activeTab={customIconActiveTab}
                        className="text-muted mt-3"
                      >
                        <TabPane tabId="UK">
                          { showformdata.mainCategory &&
                          <Row>
                            <h3 className="carbonform_heading">{(showformdata.mainCategory?.heading)}</h3>
                            <p className="carbonform_discription">{showformdata.mainCategory?.discription}</p>
                            <hr />
                          </Row>}
                          <Row>
                            <h3 className="carbonform_heading">{showformdata?.mainCategory ? showformdata.selectCategory.categoryName : showformdata.selectCategory.heading}</h3>
                            <p className="carbonform_discription">{showformdata.selectCategory.discription}</p>
                          </Row>
                          <hr />
                          <form onSubmit={(e) => formResult(e)}>
                            <Row className="mt-3">
                              <Col lg={6}>
                                <div>
                                  <Label>Select Location</Label>
                                  <select
                                    name="locationId"
                                    className="form-select"
                                    value={value.locationId}
                                    onChange={e => handleFormData(e)}
                                  >
                                    <option value="">Select</option>
                                    {locationList.length === 0
                                      ? <option disabled> <Spinner /> </option>
                                      : locationList.filter(opt => opt.isActive).map((item, index) => (
                                        <option
                                          style={{ textTransForm: "uppercase" }}
                                          key={`location${index}`}
                                          value={item._id}
                                        >
                                          {item.city} - {item.addressType}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="form-group">
                                  <Label>Select Year</Label>
                                  <select
                                    name="year"
                                    className="form-select"
                                    value={value.year}
                                    onChange={e => handleFormData(e)}
                                  >
                                    <option value="">Select</option>
                                    {years.map((option, i) => (
                                      <option key={`year${i}`} value={option}>{option}</option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              <Col lg={6} className="mt-3">
                                <div className="form-group">
                                  <Label>Select Month</Label>
                                  <select
                                    name="month"
                                    className="form-select"
                                    value={value.month}
                                    onChange={e => handleFormData(e)}>
                                    <option value="">Select</option>
                                    <option value="Jan">January </option>
                                    <option value="Feb">February </option>
                                    <option value="Mar">March </option>
                                    <option value="Apr">April </option>
                                    <option value="May">May </option>
                                    <option value="Jun">June </option>
                                    <option value="Jul">July </option>
                                    <option value="Aug">August </option>
                                    <option value="Sep">September </option>
                                    <option value="Oct">October </option>
                                    <option value="Nov">November </option>
                                    <option value="Dec">December </option>
                                  </select>
                                </div>
                              </Col>
                              {dropDownItems.activities.length > 0 && (
                                <>
                                  {dropDownItems.name && <Col lg={6} className="mt-3">
                                    <Label>{dropDownItems.name}</Label>
                                    <select
                                      name="category"
                                      className="form-select"
                                      value={value.category}
                                      onChange={(e) => handleFormData(e)}
                                    >
                                      <option value="0">Select {dropDownItems.name}</option>
                                      {dropDownItems.dataPoints.map((item, index) => (
                                        <option key={`datapoints${index}`} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </select>
                                  </Col>}
                                  {dropDownItems.categoryName && <Col lg={6} className="mt-3">
                                    <Label>Select {dropDownItems.categoryName}</Label>
                                    <select
                                      id="subCategory1"
                                      name="activity"
                                      className="form-select"
                                      onChange={(e) => firstDropDownSelect(e)}
                                    >
                                      <option value="0">Select {dropDownItems.categoryName}</option>
                                      {dropDownItems.activities.map((item, index) => (
                                        <option key={`activity${index}`} value={item.name}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </Col>}
                                  {dropDownItems.subCategoryName && <Col lg={6} className="mt-3">
                                    <Label>Select {dropDownItems.subCategoryName}</Label>
                                    <select
                                      id="subCategory2"
                                      name="fuletype"
                                      className="form-select"
                                      onClick={(e) => e.target.value === "0" && setDropDownItems({ ...dropDownItems, subCategoryTouched: true })}
                                      onChange={(e) => secondDropDownSelect(e)}
                                    >
                                      <option value="0">Select {dropDownItems.subCategoryName}</option>
                                      {dropDownItems.fuleTypes.map((item, index) => (
                                        <option key={`fuleTypes${index}`} value={item.name}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                    {dropDownItems.fuleTypes.length === 0 && dropDownItems.subCategoryTouched &&
                                     <span className="red form-label">Please Select {dropDownItems.categoryName} first</span>}
                                  </Col>}

                                  {dropDownItems.subCategoryName1 && <Col lg={6} className="mt-3">
                                    <Label>Select {dropDownItems.subCategoryName1}</Label>
                                    <select
                                      id="subCategory3"
                                      name="unit"
                                      className="form-select"
                                      onClick={(e) => e.target.value === "0" && setDropDownItems({ ...dropDownItems, subCategory1Touched: true })}
                                      onChange={(e) => thirdDropDownSelect(e)}
                                    >
                                      <option value="0">Select {dropDownItems.subCategoryName1}</option>
                                      {dropDownItems.units.map((item, index) => (
                                        <option key={`units${index}`} value={item.name}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                    {dropDownItems.units.length === 0 && dropDownItems.subCategory1Touched &&
                                     <span className="red form-label">Please Select {dropDownItems.subCategoryName} first</span>}
                                  </Col>}

                                  {(dropDownItems.subCategoryName1 || dropDownItems.subCategoryName) && <Col lg={6} className="mt-3">
                                    <Label>Select Emission Factor</Label>
                                    <input placeholder="CO₂ Emission Factor" value={dropDownItems.selectedsubCategoryco2} disabled className="form-control"></input>
                                  </Col>}
                                </>
                              )}
                              {
                                dropDownItems.formFiled.map((formitem, i) => {
                                  if(formitem.elementType === "input"){
                                    if(formitem.type === "file"){
                                      return (
                                        <Col className="mt-3" lg={6} key={`formFiled${i}`}>
                                          <Label>{formitem.placeholder}</Label>
                                          <input type={formitem.type} name={formitem.name} ref={fileRef}
                                            onChange={(e) => setvalue({ ...value, file: e.target.files[0] })}
                                            placeholder={formitem.placeholder} className="form-control"></input>
                                        </Col>);
                                    }
                                    else{
                                      return (
                                        <Col className="mt-3" lg={6} key={`formFiled${i}`}>
                                          <Label>{formitem.placeholder}</Label>
                                          <input type={formitem.type} name={formitem.name}
                                            onChange={(e) => handleFormData(e)}
                                            onWheel={(e) => e.target.blur()}
                                            placeholder={formitem.placeholder} value={value[formitem.name]} className="form-control"></input>
                                        </Col>);
                                    }
                                  }
                                  else if(formitem.elementType === "button"){
                                    return (
                                      <React.Fragment key={`formFiled${i}`} >
                                        {((calculate && value.fuel_quantity && value.emission_factor && value.unit && value.activity) || (calculate && value.fuel_quantity &&
                                    dropDownItems.selectedsubCategoryco2 &&
                                     dropDownItems.selectedsubCategory1) ) &&
                                      <Col lg={12} >
                                        <Row className="mt-2" style={{ backgroundColor: "#F2F2F5", paddingTop: "10px" }}>
                                          <Col lg={7}>
                                            <h5>Total GHG emissions (CO2 equivalent) from {dropDownItems.factorName === "Stationary combustion"
                                              ? value.category
                                              : dropDownItems.scope === "Scope 3" ? value.activity : dropDownItems.selectedCategory}: </h5>
                                          </Col>
                                          <Col lg={5} style={{ textAlign: "right" }}><h5>{dropDownItems.scope === "Scope 3"
                                            ? Number( +(value.fuel_quantity) * +(value.emission_factor)).toLocaleString("en-IN")
                                            : Number( +(value.fuel_quantity) * +(dropDownItems.selectedsubCategoryco2)).toLocaleString("en-IN")} Kg</h5></Col>
                                        </Row>
                                      </Col>}
                                        {((value.fuel_quantity && value.emission_factor && value.unit && value.activity) ||
                                    (value.fuel_quantity && dropDownItems.selectedsubCategoryco2 && dropDownItems.selectedsubCategory1)) &&
                                    <Row className="mt-3">
                                      <Col >
                                        <Button color="primary" onClick={() => setCalculate(true)}>Calculate</Button>
                                      </Col>
                                    </Row>}
                                      </React.Fragment >
                                    );
                                  }
                                  else if(formitem.elementType === "textarea"){
                                    return (
                                      <Col className="mt-3" lg={6} key={`formFiled${i}`}>
                                        <Label>{formitem.placeholder}</Label>
                                        <textarea name={formitem.name}
                                          onChange={(e) => handleFormData(e)}
                                          placeholder={formitem.placeholder} value={value.comment} className="form-control"></textarea>
                                      </Col>
                                    );
                                  }
                                })
                              }
                            </Row>
                            <Row>
                              {submitbtn ?
                                <Col>
                                  <button className="btn btn-primary mt-4">Uploading...</button>
                                </Col>
                                : <Col>
                                  <button disabled={((value.locationId && value.month && value.year && value.fuel_quantity
                              && value.emission_factor && value.unit && value.activity) || (value.locationId && value.month
                               && value.year &&value.fuel_quantity &&
                                    dropDownItems.selectedsubCategoryco2 &&
                                     dropDownItems.selectedsubCategory1) )
                                    ? false
                                    : true} className="btn btn-primary mt-4" type="submit">Submit</button>
                                </Col>}
                            </Row>
                          </form>
                        </TabPane>

                        <TabPane tabId="US">
                          {
                            showformdata?.selectCategory?.categoryName === "Stationary combustion"
                              ?
                              <>
                                { showformdata.mainCategory && <Row>
                                  <h3 className="carbonform_heading">{(showformdata.mainCategory?.heading)}</h3>
                                  <p className="carbonform_discription">{showformdata.mainCategory?.discription}</p>
                                  <hr />
                                </Row>}
                                <Row>
                                  <h3 className="carbonform_heading">{showformdata?.mainCategory ? showformdata.selectCategory.categoryName : showformdata.selectCategory.heading}</h3>
                                  <p className="carbonform_discription">{showformdata.selectCategory.discription}</p>
                                </Row>
                                <hr />
                                <form onSubmit={(e) => formResult(e)}>
                                  <Row>
                                    <Col lg={6}>
                                      <div>
                                        <Label>Select Location</Label>
                                        <select
                                          name="locationId"
                                          className="form-select"
                                          value={value.locationId}
                                          onChange={e => handleFormData(e)}
                                        >
                                          <option value="">Select</option>
                                          {locationList.length === 0
                                            ? <option disabled><Spinner /></option>
                                            : locationList.filter(opt => opt.isActive).map((item, index) => (
                                              <option
                                                style={{ textTransForm: "uppercase" }}
                                                key={`location${index}`}
                                                value={item._id}
                                              >
                                                {item.city} - {item.addressType}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="form-group">
                                        <Label>Select Year</Label>
                                        <select
                                          name="year"
                                          className="form-select"
                                          value={value.year}
                                          onChange={e => handleFormData(e)}
                                        >
                                          <option value="">Select</option>
                                          {years.map((option, i) => (
                                            <option key={`year${i}`} value={option}>{option}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </Col>
                                    <Col lg={6} className="mt-3">
                                      <div className="form-group">
                                        <Label>Select Month</Label>
                                        <select
                                          name="month"
                                          className="form-select"
                                          value={value.month}
                                          onChange={e => handleFormData(e)}>
                                          <option value="">Select</option>
                                          <option value="Jan">January </option>
                                          <option value="Feb">February </option>
                                          <option value="Mar">March </option>
                                          <option value="Apr">April </option>
                                          <option value="May">May </option>
                                          <option value="Jun">June </option>
                                          <option value="Jul">July </option>
                                          <option value="Aug">August </option>
                                          <option value="Sep">September </option>
                                          <option value="Oct">October </option>
                                          <option value="Nov">November </option>
                                          <option value="Dec">December </option>
                                        </select>
                                      </div>
                                    </Col>
                                    {customIconActiveTab === "US" && <US dropDownItems={dropDownItems} value={value} handleFormData={handleFormData} firstDropDownSelect={firstDropDownSelect}
                                      setDropDownItems={setDropDownItems} secondDropDownSelect={secondDropDownSelect}
                                      thirdDropDownSelect={thirdDropDownSelect} setvalue={setvalue} setCalculate={setCalculate} calculate={calculate}
                                      totalCalculation={totalCalculation} ref={fileRef}
                                    />}
                                  </Row>

                                  <Row>
                                    {submitbtn ?
                                      <Col>
                                        <button className="btn btn-primary mt-4">Uploading...</button>
                                      </Col>
                                      : <Col>
                                        <button disabled={((value.locationId && value.month && value.year && value.fuel_quantity && value.emission_factor
                                         && value.unit && value.activity) || (value.locationId && value.month && value.year &&value.fuel_quantity
                                           && dropDownItems.selectedsubCategoryco2 && dropDownItems.selectedsubCategory1) )
                                          ? false
                                          : true}
                                        className="btn btn-primary mt-4" type="submit">Submit</button>
                                      </Col>}
                                  </Row>
                                </form>
                              </>
                              :
                              <div style={{ fontSize: "20px", fontWeight: "400" }}>
                                We are adding emissions library for IPCC & US for Scope 2 and other categories in Scope 1. <br/> It will be ready shortly.
                              </div>
                          }
                        </TabPane>

                        <TabPane tabId="IPCC">
                          {
                            showformdata?.selectCategory?.categoryName === "Stationary combustion"
                              ?
                              <>
                                { showformdata.mainCategory && <Row>
                                  <h3 className="carbonform_heading">{(showformdata.mainCategory?.heading)}</h3>
                                  <p className="carbonform_discription">{showformdata.mainCategory?.discription}</p>
                                  <hr />
                                </Row> }
                                <Row>
                                  <h3 className="carbonform_heading">{showformdata?.mainCategory ? showformdata.selectCategory.categoryName : showformdata.selectCategory.heading}</h3>
                                  <p className="carbonform_discription">{showformdata.selectCategory.discription}</p>
                                </Row>
                                <hr />
                                <form onSubmit={(e) => formResult(e)}>
                                  <Row>
                                    {/* {dropDownItems.categoryName && <Col lg={6}>
                                <Label>Select {dropDownItems.categoryName}</Label>
                                <select
                                  id="subCategory1"
                                  name="activity"
                                  className="form-select"
                                  onChange={(e) => firstDropDownSelect(e)}
                                >
                                  <option value="0">Select {dropDownItems.categoryName}</option>
                                  {dropDownItems.activities.map((item, index) => (
                                    <option key={`activity${index}`} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </Col>} */}
                                    <Col lg={6} >
                                      <div>
                                        <Label>Select Location</Label>
                                        <select
                                          name="locationId"
                                          className="form-select"
                                          value={value.locationId}
                                          onChange={e => handleFormData(e)}
                                        >
                                          <option value="">Select</option>
                                          {locationList.length === 0
                                            ? <option disabled><Spinner /></option>
                                            : locationList.filter(opt => opt.isActive).map((item, index) => (
                                              <option
                                                style={{ textTransForm: "uppercase" }}
                                                key={`location${index}`}
                                                value={item._id}
                                              >
                                                {item.city} - {item.addressType}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </Col>
                                    <Col lg={6} >
                                      <div className="form-group">
                                        <Label>Select Year</Label>
                                        <select
                                          name="year"
                                          className="form-select"
                                          value={value.year}
                                          onChange={e => handleFormData(e)}
                                        >
                                          <option value="">Select</option>
                                          {years.map((option, i) => (
                                            <option key={`year${i}`} value={option}>{option}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </Col>
                                    <Col lg={6} className="mt-3">
                                      <div className="form-group">
                                        <Label>Select Month</Label>
                                        <select
                                          name="month"
                                          className="form-select"
                                          value={value.month}
                                          onChange={e => handleFormData(e)}>
                                          <option value="">Select</option>
                                          <option value="Jan">January </option>
                                          <option value="Feb">February </option>
                                          <option value="Mar">March </option>
                                          <option value="Apr">April </option>
                                          <option value="May">May </option>
                                          <option value="Jun">June </option>
                                          <option value="Jul">July </option>
                                          <option value="Aug">August </option>
                                          <option value="Sep">September </option>
                                          <option value="Oct">October </option>
                                          <option value="Nov">November </option>
                                          <option value="Dec">December </option>
                                        </select>
                                      </div>
                                    </Col>
                                    {customIconActiveTab === "IPCC" && <IPCC handleFormData={handleFormData} dropDownItems={dropDownItems} value={value} firstDropDownSelect={firstDropDownSelect}
                                      setDropDownItems={setDropDownItems} secondDropDownSelect={secondDropDownSelect}
                                      thirdDropDownSelect={thirdDropDownSelect} setvalue={setvalue} setCalculate={setCalculate} calculate={calculate}
                                      totalCalculation={totalCalculation}
                                    />}
                                  </Row>
                                  <Row>
                                    {submitbtn ?
                                      <Col> <button className="btn btn-primary mt-4">Uploading...</button> </Col>
                                      : <Col>
                                        <button disabled={((value.locationId && value.month && value.year && value.fuel_quantity
                                         && value.emission_factor && value.unit && value.activity) || (value.locationId && value.month && value.year &&value.fuel_quantity &&
                                         dropDownItems.selectedsubCategoryco2 && dropDownItems.selectedsubCategory1) )
                                          ? false
                                          : true} className="btn btn-primary mt-4" type="submit">Submit</button>
                                      </Col>}
                                  </Row>
                                </form>
                              </>
                              :
                              <div style={{ fontSize: "20px", fontWeight: "400" }}>
                              We are adding emissions library for IPCC & US for Scope 2 and other categories in Scope 1. <br/> It will be ready shortly.
                              </div>
                          }
                        </TabPane>

                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
    : (
      <Loading />
    );
}
export default CarbonFootprint;