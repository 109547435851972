/**
 * add ADMIN USER
 */
export const GET_ADMIN_USERS = "GET_ADMIN_USERS";
export const GET_ADMIN_USERS_SUCCESS = "GET_ADMIN_USERS_SUCCESS";
export const GET_ADMIN_USERS_FAIL = "GET_ADMIN_USERS_FAIL";

/**
 * add ADMIN USER
 */
export const ADD_NEW_ADMIN_USER = "ADD_NEW_ADMIN_USER";
export const ADD_ADMIN_USER_SUCCESS = "ADD_ADMIN_USER_SUCCESS";
export const ADD_ADMIN_USER_FAIL = "ADD_ADMIN_USER_FAIL";

/**
 * Edit ADMIN USER
 */
export const UPDATE_ADMIN_USER = "UPDATE_ADMIN_USER";
export const UPDATE_ADMIN_USER_SUCCESS = "UPDATE_ADMIN_USER_SUCCESS";
export const UPDATE_ADMIN_USER_FAIL = "UPDATE_ADMIN_USER_FAIL";

/**
 * Delete ADMIN USER
 */
export const DELETE_ADMIN_USER = "DELETE_ADMIN_USER";
export const DELETE_ADMIN_USER_SUCCESS = "DELETE_ADMIN_USER_SUCCESS";
export const DELETE_ADMIN_USER_FAIL = "DELETE_ADMIN_USER_FAIL";
