import React, { useState, useEffect, useMemo } from "react";
import { AxiosGet } from "common/axiosService";
import { Action, Date, Role, Name } from "./CryptoCol";
import { toast } from "react-toastify";
import IsRoleAuth from "pages/Authentication/IsRoleAuth";
import { Card, CardBody, Container, Row } from "reactstrap";
import TableContainer from "components/Common/TableContainer";
import Breadcrumbs from "components/Common/Breadcrumb";

const SuperadminLogs = () => {
  const apipath = process.env.REACT_APP_SUPERADMIN_API_PATH;

  const [userdata, setUserData] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/super-admin-activity-log`);
        if (response && response.statusCode === 200) {
          setUserData(response.data);
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, []);

  const data = userdata.length > 0 ? userdata.reverse() : [];
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "",
        filterable: true,
        Cell: (row) => <div>{Number(row.row.id) + 1}</div>
      },
      {
        Header: "Date",
        accessor: "modifiedAt",
        filterable: true,
        Cell: cellProps => <Date {...cellProps} />
      },
      {
        Header: "Role",
        accessor: "performerId.role",
        filterable: true,
        Cell: cellProps => <Role {...cellProps} />
      },
      {
        Header: "Email",
        accessor: "performerId.email",
        filterable: true,
        Cell: cellProps => <Name {...cellProps} />
      },
      {
        Header: "Action",
        accessor: "actionDone",
        filterable: true,
        Cell: cellProps => <Action {...cellProps} />
      }
    ],
    []
  );
  return (
    <React.Fragment>
      <IsRoleAuth />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Greenalytics" breadcrumbItem="All Activity" />
          <Row>
            <Card>
              <CardBody>
                <div id="overview-chart" dir="ltr">
                  <TableContainer
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isCustomPageSize={true}
                    isActiveMessage={true}
                    customPageSize={13}
                  />
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SuperadminLogs;
