import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import Select from "react-select";
import SimpleBar from "simplebar-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Card, CardBody, Col, Container, Row, Input, CardTitle, Form, Label, FormFeedback } from "reactstrap";

import Sidebar from "./Sidebar";
import { AxiosGet, AxiosPost } from "common/axiosService";

const Customreport = () => {
  const apiUrl = process.env.REACT_APP_SUPERADMIN_API_PATH;

  const [changetext, setChangeText] = useState(true);

  const [items, setItems] = useState([]);
  const [isEditForm, setIsEditForm] = useState(false);
  const [showinputbox, setShowInputBox] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isItemsChanged, setIsItemsChanged] = useState(false);
  const [isCategoryAdded, setIsCategoryAdded] = useState(false);
  const [text, setText] = useState({ categoryName: "", typeOfData: [] });
  const [selectedCategory, setSelectedCategory] = useState({ category: "", mainCategory: "" });
  const [fieldValues, setFieldValues] = useState({ fieldtype: "", fieldname: "", placeholder: "", requireField: "", unit: "" });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: fieldValues,
    validationSchema: Yup.object({
      fieldtype: Yup.string().required("Please Enter Field Type"),
      fieldname: Yup.string().required("Please Enter Field Name"),
      placeholder: Yup.string().required("Please Enter PlaceHolder"),
      requireField: Yup.string().required("Please Enter Is Required Field"),
      unit: Yup.string().required("Please Enter Is Unit")
    }),
    onSubmit: async (values, action) => {
      handleFieldDataSubmit([values], action);
    }
  });

  useEffect(() => {
    selectedCategory?.category.data &&
      selectedCategory?.category.data.length > 0 && isEditForm && setFieldValues(selectedCategory.category.data[0].values[0]);
  }, [ selectedCategory.category.data, selectedCategory.category.data && selectedCategory.category.data.length ]);

  useEffect(() => {
    getAllCategory();
  }, [isItemsChanged, isCategoryAdded]);

  /* ==============>>>>>>>>  Category List API Call  <<<<<<<<============== */
  const getAllCategory = async () => {
    try {
      const response = await AxiosGet(`${apiUrl}/get-all-category`);
      setItems(response?.data);
      setIsCategoryAdded(false);
      setShowCreateForm(false);
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  const handleChange = (e) => {
    if (e.target) {
      setText({ ...text, categoryName: e.target.value });
    } else {
      setText({ ...text, typeOfData: Array.isArray(e) ? e.map(x => x.value) : [] });
    }
  };

  /* ==============>>>>>>>>  Multiple SubField Add In Form Builder  <<<<<<<<============== */
  // const handleAddRowNested = (index, key) => {
  //   setFieldListSchema({ ...fieldListSchema, [`subfielddata${key + 1}`]: Yup.string().required("Please Enter Sub Field") });
  //   setFieldListData({
  //     users: fieldListData?.users.map((item, fieldIndex) => {
  //       if (index === fieldIndex) {
  //         item.subFields.push({ id: item.subFields.length + 1 });
  //       }
  //       return item;
  //     })
  //   });
  // };

  /* ==============>>>>>>>>  Remove SubField From the Form Builder  <<<<<<<<============== */
  // const handleRemoveRow = (id, index) => {
  //   setFieldListData({
  //     users: fieldListData.users.map((item, fieldIndex) => {
  //       if (index === fieldIndex) {
  //         item.subFields.pop();
  //       }
  //       return item;
  //     })
  //   });
  // };

  /* ==============>>>>>>>>  New Category Add API call  <<<<<<<<============== */
  const handleSubmit = async e => {
    e.preventDefault();
    if (!text.categoryName.length || !text.typeOfData.length) {
      if (!text.typeOfData.length) {
        toast.error("Please Select Data Type");
        return;
      } else {
        toast.error("Please Enter Category Name");
        return;
      }
    }
    try {
      await AxiosPost(`${apiUrl}/category`, { ...text, isOpen: false });
      setIsItemsChanged(!isItemsChanged);
      toast.success("Category Created Successfully.", {
        autoClose: 1000
      });
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
    setText({ categoryName: "", typeOfData: "" });
    setShowInputBox(!showinputbox);
  };

  /* ==============>>>>>>>>  Form Builder OnFormSubmit  <<<<<<<<============== */
  const handleFieldDataSubmit =async (fieldData, action) => {
    let formParams = { formFields: {}, values: fieldData, unit: fieldData[0] ? fieldData[0].unit : "" };
    fieldData.forEach((item) => {
      if (item.unit) {
        formParams.formFields = {
          unit: {
            elementType: "span",
            elementConfig: {
              type: "text",
              placeholder: "Select Unit",
              unit: true
            },
            value: item.unit.replace(/\s/g, ""),
            validation: {
              required: false,
              error: ""
            },
            valid: true,
            touched: true
          }
        };
      }
      // if (item.fieldtype === "input" || item.fieldtype === "date") {
      formParams.formFields = {
        ...formParams.formFields,
        value: {
          elementType: item.fieldtype,
          elementConfig: {
            type: item.fieldtype === "input" ? "number" : "date",
            placeholder: item.placeholder
          },
          value: "",
          validation: {
            required: item.requireField === "true" ? true : false,
            error: ""
          },
          valid: false,
          touched: false
        }
      };
      // } else {
      //   let data = fieldListData.users.filter(
      //     (field, fieldIndex) => fieldIndex === index
      //   )
      //   const optionArray = data[0].subFields.map((subItem, subIndex) => {
      //     if (item[`subfielddata${subIndex}`]) {
      //       return {
      //         value: item[`subfielddata${subIndex}`],
      //         displayValue: item[`subfielddata${subIndex}`].replace(/\s/g, ""),
      //       }
      //     }
      //   }).filter(item => item !== undefined);

      //   formParams.formFields = {
      //     ...formParams.formFields,
      //     [`${item.fieldname.replace(/\s/g, "")}`]: {
      //       elementType: item.fieldtype,
      //       elementConfig: {
      //         placeholder: item.placeholder,
      //         options: optionArray,
      //       },
      //       value: "",
      //       validation: {
      //         required: item.requireField === "true" ? true : false,
      //       },
      //       valid: false,
      //     },
      //   }
      // }
      formParams.formFields = {
        ...formParams.formFields,
        comment: {
          elementType: "textarea",
          elementConfig: {
            type: "text",
            placeholder: "Add Comment"
          },
          value: "",
          validation: {
            required: false,
            error: ""
          },
          valid: true,
          touched: true
        },
        file: {
          elementType: "input",
          elementConfig: {
            type: "file",
            placeholder: "Upload"
          },
          value: "",
          selectedFileObj: {},
          validation: {
            required: false,
            error: ""
          },
          valid: true,
          touched: true
        }
      };
    });
    if (selectedCategory.mainCategory) {
      const params = { mainCategoryId: selectedCategory.mainCategory._id };
      const updateSubCategory = (data, childData) => {
        if (data._id === childData._id) {
          let subCategoryArray = data.subCategory.map(item => {
            item.isOpen = false;
            return item;
          });
          if (subCategoryArray.length > 0) {
            const subCategoryData = subCategoryArray.map(item => {
              if (item._id === childData._id) {
                childData.isOpen = false;
                return childData;
              } else {
                item.isOpen = false;
                return item;
              }
            });
            return subCategoryData;
          } else {
            return subCategoryArray;
          }
        } else {
          return data.subCategory.map(subItem => mapData(subItem, childData));
        }
      };
      function mapData (data, childData) {
        const result = {
          ...data, // copy all data
          isOpen: false,
          subCategory: updateSubCategory(data, childData)
        };
        return result;
      }
      const category = selectedCategory.category;
      category["data"] = [formParams];
      const mainParams = selectedCategory.mainCategory.subCategory.map(d =>
        mapData(d, category)
      );
      params["subCategory"] = mainParams;
      params["typeOfData"] = selectedCategory.mainCategory.typeOfData;
      try {
        const response = await AxiosPost(`${apiUrl}/update-category-category`, params);

        if(response && response.statusCode === 200){
          getAllCategory();
          toast.success("Form Created Successfully.", {
            autoClose: 1000
          });
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    } else {
      const params = { _id: selectedCategory.category._id };
      params["data"] = [formParams];
      params["categoryName"] = selectedCategory.category.categoryName;
      params["isActive"] = selectedCategory.category.isActive;
      params["typeOfData"] = selectedCategory.category.typeOfData;
      try {
        const response = await AxiosPost(`${apiUrl}/update-main-category`, params);
        if (response && response.statusCode === 200) {
          getAllCategory();
          toast.success("Form Created Successfully.", {
            autoClose: 1000
          });
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    }
    action.resetForm();
    // setUserData(fieldData);
  };

  const showInputbox = () => {
    setText({ categoryName: "", typeOfData: "" });
    setShowInputBox(!showinputbox);
  };

  const openCreateForm = (data, isEdit) => {
    setShowCreateForm(data);
    if (data) {
      if (!isEdit) {
        setFieldValues({ fieldtype: "", fieldname: "", placeholder: "", requireField: "", unit: "" });
      } else {
        setIsEditForm(true);
      }
    } else {
      setFieldValues({ fieldtype: "", fieldname: "", placeholder: "", requireField: "", unit: "" });
    }
  };
  const optionsforcsv = [
    { value: "Environmental", label: "Environmental" },
    { value: "Greenhouse Gas", label: "Greenhouse Gas" },
    { value: "Social", label: "Social" },
    { value: "Governance", label: "Governance" },
    { value: "BRSR", label: "BRSR" }
  ];
  return (
    <div>
      <div>
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Col lg={5}>
                <Card>
                  <SimpleBar style={{ maxHeight: "800px" }}>
                    <CardBody>
                      {showinputbox ?
                        <div className="mb-3">
                          <form onSubmit={e => handleSubmit(e)}>
                            <div lg={3} className="mb-3">
                              <Label>Select Data Type</Label>
                              <Select
                                value={optionsforcsv.filter(obj => text.typeOfData.includes(obj.value))}
                                options={optionsforcsv}
                                onChange={handleChange}
                                isMulti={true}
                              />
                            </div>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Enter Category....."
                              value={text.categoryName}
                              name="categoryName"
                              onChange={e => handleChange(e)}
                            />
                            <div className="add-category-input-box">
                              <button type="submit" className="btn btn-primary"> Save </button>
                              <button color="link" className="btn btn-primary add-category-btn" onClick={showInputbox}> Cancel </button>
                            </div>
                          </form>
                        </div>
                        :
                        <div className="box-data">
                          <div onClick={() => setShowInputBox(!showinputbox)} className="box-add ">
                            <i className="dripicons-plus" />
                            <label>Add More Category</label>
                          </div>
                        </div>
                      }
                      <hr />
                      <div>
                        <div>
                          <Sidebar
                            onUpdateCategory={setSelectedCategory}
                            onCategorySubmit={() => setIsCategoryAdded(true)}
                            toggleCreateForm={openCreateForm}
                            items={items}
                            setChangeText={setChangeText}
                            changetext={changetext}
                            onCategoryAdd={() => setShowInputBox(!showinputbox)}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </SimpleBar>
                </Card>
              </Col>
              {showCreateForm && (
                <Col lg={7}>
                  <Card>
                    <CardBody>
                      <CardTitle>Example</CardTitle>
                      <Form onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                      }}>
                        <Row>
                          <Col className="mt-3" lg="6">
                            <Label>Select Field Type</Label>
                            <Input
                              type="select"
                              name="fieldtype"
                              className="form-select form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.fieldtype || ""}
                              invalid={ validation.touched.fieldtype && validation.errors.fieldtype ? true : false }
                            >
                              <option value="" typeof="select"> Select Field Type{" "} </option>
                              <option value="input"> {" "} Input </option>
                            </Input>
                            {validation.touched.fieldtype && validation.errors.fieldtype && <FormFeedback type="invalid"> {validation.errors.fieldtype} </FormFeedback>}
                          </Col>
                          <Col className="mt-3" lg="6">
                            <Label>Select Field Required</Label>
                            <Input
                              type="select"
                              name="requireField"
                              className="form-select form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.requireField || ""}
                              invalid={ validation.touched.requireField && validation.errors.requireField ? true : false }
                            >
                              <option value="">Is Field Required</option>
                              <option value="true"> Yes</option>
                              <option value="false"> {" "} No</option>
                            </Input>
                            {validation.touched.requireField && validation.errors.requireField && <FormFeedback type="invalid"> {validation.errors.requireField} </FormFeedback>}
                          </Col>
                          <Col className="mt-3" lg="6">
                            <Label>Enter Field Name</Label>
                            <Input
                              type="text"
                              name="fieldname"
                              className="form-control"
                              placeholder="Enter Field Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.fieldname || ""}
                              invalid={ validation.touched.fieldname && validation.errors.fieldname ? true : false }
                            />
                            {validation.touched.fieldname && validation.errors.fieldname && <FormFeedback type="invalid"> {validation.errors.fieldname} </FormFeedback>}
                          </Col>
                          <Col className="mt-3" lg="6">
                            <Label>Enter Placeholder Name</Label>
                            <Input
                              type="text"
                              name="placeholder"
                              className="form-control"
                              placeholder="Enter Field Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.placeholder || ""}
                              invalid={ validation.touched.placeholder && validation.errors.placeholder ? true : false }
                            />
                            {validation.touched.placeholder && validation.errors.placeholder && <FormFeedback type="invalid"> {validation.errors.placeholder} </FormFeedback>}
                          </Col>
                          <Col className="mt-3" lg="6">
                            <Label>Enter Unit Type</Label>
                            <Input
                              type="text"
                              name="unit"
                              className="form-control"
                              placeholder="Enter Field Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.unit || ""}
                              invalid={ validation.touched.unit && validation.errors.unit ? true : false }
                            />
                            {validation.touched.unit && validation.errors.unit && <FormFeedback type="invalid"> {validation.errors.unit} </FormFeedback>}
                          </Col>
                        </Row>
                        <Row className="mt-3 next-prev-btn">
                          <Col>
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={!validation.isValid}
                            >{changetext ? "Submit" : "Update" }</button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Customreport;
