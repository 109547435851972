import React from "react";
import { Col } from "reactstrap";

import Activity from "./Activity";
import TapVisitors from "./TapVisitors";

const Settings = () => (
  <React.Fragment>
    <Col xl={4}>
      <TapVisitors />
      <Activity />
    </Col>
  </React.Fragment>
);

export default Settings;
