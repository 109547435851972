import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Modal, Card, Col, DropdownToggle, DropdownMenu, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import ModalSection from "../ModalSection";
import FormUpload from "../Modalfileupload";
import Input from "components/Inputs/Input";
import TextArea from "components/Inputs/TextArea";
import TableInput from "components/Inputs/TableInput";
import RadioButton from "components/Inputs/RadioButton";
import { AxiosGet, AxiosPost } from "common/axiosService";
import TableTextArea from "components/Inputs/TableTextArea";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const Principle3 = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionC.principle_3;
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, employeeData, prefillData, onSectionChange, previousData, oldData, getFile, filesData } = props;

  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);

  const [filedatachange, setFileDataChange] = useState(true);

  const [modal_center, setmodal_center] = useState(false);
  const [modalData, setModalData] = useState({ name: "", value: "", type: "", title: "" });

  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionC", subSection: "principle_3", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect(() =>{
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_3"
      });
    }
  }, [newReport, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionC",
      "subSection": "principle_3"
    });
  }, [filedatachange]);

  const empPrefillCounting = (currentObj) => {
    ["workers", "employees", "differently_abled_employees", "differently_abled_workers"].forEach(item => {
      let totalMale = 0;
      let totalFemale = 0;
      let totalOther = 0;
      ["other_than_permanent", "permanent"].forEach((subItem)=>{
        const male = Number(_get(currentObj, `${item}.${subItem}.male`, 0));
        const female = Number(_get(currentObj, `${item}.${subItem}.female`, 0));
        const other = Number(_get(currentObj, `${item}.${subItem}.other`, 0));
        totalMale += male;
        totalFemale += female;
        totalOther += other;
        const total = male + female + other;
        _set(employeeData, `${item}.${subItem}.total`, total);
        _set(employeeData, `${item}.${subItem}.malepercentage`, _calculatePercentage(male, total));
        _set(employeeData, `${item}.${subItem}.femalepercentage`, _calculatePercentage(female, total));
        _set(employeeData, `${item}.${subItem}.otherpercentage`, _calculatePercentage(other, total));
      });
      const permanent = Number(_get(currentObj, `${item}.permanent.total`, 0));
      const otherThanPermanent = Number(_get(currentObj, `${item}.other_than_permanent.total`, 0));
      _set(employeeData, `${item}.total.total`, permanent+otherThanPermanent);
      _set(employeeData, `${item}.total.male`, totalMale);
      _set(employeeData, `${item}.total.female`, totalFemale);
      _set(employeeData, `${item}.total.other`, totalOther);
      _set(employeeData, `${item}.total.malepercentage`, _calculatePercentage(totalMale, permanent+otherThanPermanent));
      _set(employeeData, `${item}.total.femalepercentage`, _calculatePercentage(totalFemale, permanent+otherThanPermanent));
      _set(employeeData, `${item}.total.otherpercentage`, _calculatePercentage(totalOther, permanent+otherThanPermanent));
    });
  };
  const employeePrifillData = () => {
    if (prefillData?.benefits?.permanent_employees|| prefillData?.benefits?.other_than_permanent_employees) {
      ["permanent", "other_than_permanent"].forEach(item => {
        ["male", "female", "other" ].forEach(subItem => {
          ["health_insurance", "accident_insurance", "maternity_benefits", "day_care_facilities", "paternity_benefits"].forEach(subInnerItem => {
            const percentage = subInnerItem.split("_")[0];
            const itemValue = _get(prefillData?.benefits, `${item}_employees.${subInnerItem}.${subItem}`, "" );
            const male = _get(prefillData?.benefits, `${item}_employees.${subInnerItem}.male`, 0 );
            const female = _get(prefillData?.benefits, `${item}_employees.${subInnerItem}.female`, 0 );
            const other = _get(prefillData?.benefits, `${item}_employees.${subInnerItem}.other`, 0 );
            _set(data, `well_being_of_employees.${item}.${subItem}.${subInnerItem}`, itemValue);
            _set(data, `well_being_of_employees.${item}.total.${subInnerItem}`, male+female+other);

            _set(data, percentage === "day" ? `well_being_of_employees.${item}.${subItem}.${percentage}care_percentage`: `well_being_of_employees.${item}.${subItem}.${percentage}_percentage`,
              _calculatePercentage(itemValue, _get(data, `well_being_of_employees.${item}.${subItem}.total`, 0 )));
            _set(data, percentage === "day" ? `well_being_of_employees.${item}.total.${percentage}care_percentage`: `well_being_of_employees.${item}.total.${percentage}_percentage`,
              _calculatePercentage(male+female+other, _get(data, `well_being_of_employees.${item}.total.total`, 0 )));
          });
        });
      });
    }
    if(prefillData?.benefits?.permanent_workers|| prefillData?.benefits?.other_than_permanent_workers) {
      ["permanent", "other_than_permanent"].forEach(item => {
        ["male", "female", "other" ].forEach(subItem => {
          ["health_insurance", "accident_insurance", "maternity_benefits", "day_care_facilities", "paternity_benefits"].forEach(subInnerItem => {
            const percentage = subInnerItem.split("_")[0];
            const itemValue = _get(prefillData?.benefits, `${item}_workers.${subInnerItem}.${subItem}`, "" );
            const male = _get(prefillData?.benefits, `${item}_workers.${subInnerItem}.male`, 0 );
            const female = _get(prefillData?.benefits, `${item}_workers.${subInnerItem}.female`, 0 );
            const other = _get(prefillData?.benefits, `${item}_workers.${subInnerItem}.other`, 0 );
            _set(data, `well_being_of_workers.${item}.${subItem}.${subInnerItem}`, itemValue);
            _set(data, `well_being_of_workers.${item}.total.${subInnerItem}`, male+female+other);

            _set(data, percentage === "day" ? `well_being_of_workers.${item}.${subItem}.${percentage}care_percentage`: `well_being_of_workers.${item}.${subItem}.${percentage}_percentage`,
              _calculatePercentage(itemValue, _get(data, `well_being_of_workers.${item}.${subItem}.total`, 0 )));
            _set(data, percentage === "day" ? `well_being_of_workers.${item}.total.${percentage}care_percentage`: `well_being_of_workers.${item}.total.${percentage}_percentage`,
              _calculatePercentage(male+female+other, _get(data, `well_being_of_workers.${item}.total.total`, 0 )));
          });
        });
      });
    }
    if(prefillData?.trainings?.health_and_safety_training || prefillData?.trainings?.skill_upgradation) {
      ["employees", "workers"].forEach(item => {
        ["male", "female", "other" ].forEach(subItem => {
          ["health_and_safety_training", "skill_upgradation"].forEach(subInnerItem => {
            const percentage = subInnerItem.split("_")[0];
            const itemValue = _get(prefillData?.trainings, `${subInnerItem}.${item}.${subItem}`, "" );
            const male = _get(prefillData?.trainings, `${subInnerItem}.${item}.male`, 0 );
            const female = _get(prefillData?.trainings, `${subInnerItem}.${item}.female`, 0 );
            const other = _get(prefillData?.trainings, `${subInnerItem}.${item}.other`, 0 );
            _set(data, `trainings.${item}.${subItem}.${subInnerItem}`, itemValue);
            _set(data, `trainings.${item}.total.${subInnerItem}`, male+female+other);

            _set(data, `trainings.${item}.${subItem}.${percentage}_percentage`,
              _calculatePercentage(itemValue, _get(data, `trainings.${item}.${subItem}.total`, 0 )));
            _set(data, `trainings.${item}.total.${percentage}_percentage`,
              _calculatePercentage(male+female+other, _get(data, `trainings.${item}.total.total`, 0 )));
          });
        });
      });
    }
  };

  useEffect(() => {
    if (employeeData) {
      empPrefillCounting(employeeData);
      ["other_than_permanent", "permanent"].forEach(item => {
        ["male", "female", "other", "total"].forEach(subItem => {
          _set(data, `well_being_of_employees.${item}.${subItem}.total`, _get(employeeData?.employees, `${item}.${subItem}`, "" ));
          _set(data, `well_being_of_workers.${item}.${subItem}.total`, _get(employeeData?.employees, `${item}.${subItem}`, "" ));
        });
      });
      ["workers", "employees"].forEach(item => {
        ["male", "female", "other", "total"].forEach(subItem => {
          _set(data, `membership_of_unions.permanent_${item}.${subItem}.total`, _get(employeeData[item], `permanent.${subItem}`, "" ));
          _set(data, `membership_of_unions.permanent_${item}.${subItem}.no_of_employees`,
            _get(data, `membership_of_unions.permanent_${item}.${subItem}.no_of_employees`, "")
              ? Number(_get(data, `membership_of_unions.permanent_${item}.${subItem}.no_of_employees`, ""))
              : Number(_get(prefillData, `membership_of_unions.permanent_${item}.${subItem}.no_of_employees_workers_who_are_part_of_association_or_union`, "" )));
          _set(data, `membership_of_unions.permanent_${item}.${subItem}.percentage`,
            _calculatePercentage(_get(data, `membership_of_unions.permanent_${item}.${subItem}.no_of_employees`, ""), _get(data, `membership_of_unions.permanent_${item}.${subItem}.total`, "")));
          _set(data, `trainings.${item}.${subItem}.total`, _get(employeeData[item], `total.${subItem}`, "" ));

          _set(data, `trainings.${item}.${subItem}.health_and_safety_training`,
            _get(data, `trainings.${item}.${subItem}.health_and_safety_training`, "")
              ? Number(_get(data, `trainings.${item}.${subItem}.health_and_safety_training`, ""))
              : Number(_get(prefillData, `trainings.${item}.${subItem}.health_and_safety`, "" )));
          const healthPercentage = _calculatePercentage(_get(data, `trainings.${item}.${subItem}.health_and_safety_training`, "" ), _get(data, `trainings.${item}.${subItem}.total`, "" ));
          _set(data, `trainings.${item}.${subItem}.health_percentage`, healthPercentage);
          _set(data, `trainings.${item}.${subItem}.skill_upgradation`,
            _get(data, `trainings.${item}.${subItem}.skill_upgradation`, "")
              ? Number(_get(data, `trainings.${item}.${subItem}.skill_upgradation`, ""))
              : Number(_get(prefillData, `trainings.${item}.${subItem}.skill_upgradation`, "" )));
          const skillPercentage = _calculatePercentage(_get(data, `trainings.${item}.${subItem}.skill_upgradation`, "" ), _get(data, `trainings.${item}.${subItem}.total`, "" ));
          _set(data, `trainings.${item}.${subItem}.skill_percentage`, skillPercentage);
          _set(data, `performance_and_career_development.${item}.${subItem}.total`, _get(employeeData[item], `total.${subItem}`, "" ));
        });
        const maleHealth = _get(data, `trainings.${item}.male.health_and_safety_training`, 0 );
        const femaleHealth = _get(data, `trainings.${item}.female.health_and_safety_training`, 0 );
        const otherHealth = _get(data, `trainings.${item}.other.health_and_safety_training`, 0 );
        const malemu = _get(data, `membership_of_unions.permanent_${item}.male.no_of_employees`, 0 );
        const femalemu = _get(data, `membership_of_unions.permanent_${item}.female.no_of_employees`, 0 );
        const othermu = _get(data, `membership_of_unions.permanent_${item}.other.no_of_employees`, 0 );
        const maleSkill = _get(data, `trainings.${item}.male.skill_upgradation`, 0 );
        const femaleSkill = _get(data, `trainings.${item}.female.skill_upgradation`, 0 );
        const otherSkill = _get(data, `trainings.${item}.other.skill_upgradation`, 0 );
        _set(data, `membership_of_unions.permanent_${item}.total.no_of_employees`, malemu + femalemu + othermu);
        _set(data, `membership_of_unions.permanent_${item}.total.percentage`,
          _calculatePercentage( _get(data, `membership_of_unions.permanent_${item}.total.no_of_employees`, 0), _get(data, `membership_of_unions.permanent_${item}.total.total`, 0)));
        _set(data, `trainings.${item}.total.health_and_safety_training`, maleHealth + femaleHealth + otherHealth);
        _set(data, `trainings.${item}.total.health_percentage`,
          _calculatePercentage(_get(data, `trainings.${item}.total.health_and_safety_training`, 0), _get(data, `trainings.${item}.total.total`, 0)));
        _set(data, `trainings.${item}.total.skill_upgradation`, maleSkill + femaleSkill + otherSkill);
        _set(data, `trainings.${item}.total.skill_percentage`, _calculatePercentage(_get(data, `trainings.${item}.total.skill_upgradation`, 0), _get(data, `trainings.${item}.total.total`, 0)));
      });
      employeePrifillData();
    } else {
      employeePrifillData();
    }
    if (prefillData?.complaints) {
      ["on_working_coditions", "on_health__safety"].forEach(item => {
        ["no_received", "no_resolved"].forEach(subItem => {
          const setData = _get(data, `complaints.${item}.${subItem}`, "")
            ? _get(data, `complaints.${item}.${subItem}`, "")
            : (_get(prefillData, `complaints.${item}.employees.${subItem}`, "") + _get(prefillData, `complaints.${item}.workers.${subItem}`, ""));
          _set(data, `complaints.${item}.${subItem}`, setData);
        });
      });
    }
    if (previousData) {
      _set(data, "retirement_benefits.previous", _get(previousData, "retirement_benefits.current", undefined));
      _set(data, "safety_related_incidents.previous", _get(previousData, "safety_related_incidents.current", undefined));
      ["workers", "employees"].forEach(item => {
        ["male", "female", "other", "total"].forEach(subItem => {
          const membershipprevious = _get(previousData, `membership_of_unions.permanent_${item}.${subItem}`, "" );
          const trainingsprevious = _get(previousData, `trainings.${item}.${subItem}`, "" );
          const pcdprevious = _get(previousData, `performance_and_career_development.${item}.${subItem}`, "" );
          if (membershipprevious?.previous) {
            delete membershipprevious.previous;
          }
          if (trainingsprevious?.previous) {
            delete trainingsprevious.previous;
          }
          if (pcdprevious?.previous) {
            delete pcdprevious.previous;
          }
          _set(data, `membership_of_unions.permanent_${item}.${subItem}.previous`, membershipprevious);
          _set(data, `trainings.${item}.${subItem}.previous`, trainingsprevious);
          _set(data, `performance_and_career_development.${item}.${subItem}.previous`, pcdprevious);
        });
      });

      ["on_working_coditions", "on_health__safety"].forEach(item =>{
        _set(data, `complaints.${item}.previous.no_received`, _get(previousData, `complaints.${item}.no_received`, ""));
        _set(data, `complaints.${item}.previous.no_resolved`,
          _get(previousData, `complaints.${item}.no_resolved`, ""));
        _set(data, `complaints.${item}.previous.remarks`, _get(previousData, `complaints.${item}.remarks`, ""));
      });

      ["employees", "workers"].forEach(item =>{
        _set(data, `placed_in_suitable_employment.${item}.affected.previous`, _get(previousData, `placed_in_suitable_employment.${item}.affected.current`, ""));
        _set(data, `placed_in_suitable_employment.${item}.rehabilitated.previous`, _get(previousData, `placed_in_suitable_employment.${item}.rehabilitated.current`, ""));
      });
    }
  }, [employeeData, prefillData, previousData]);

  const _calculatePercentage = (a, b) => {
    if (!a || !b || +a == 0 || +b == 0) return "0%";
    return `${((+a / +b) * 100).toFixed(1)}%`;
  };

  const onMembershipHandleChange = (e) => {
    const keys = e.target.name.split(".");
    const lastKey = keys.pop();
    const currentObj = keys.reduce((data, key) => data[key] = data[key] || {}, data);
    currentObj[lastKey] = e.target.value;
    currentObj["percentage"] = _calculatePercentage(e.target.value, currentObj["total"]);
    ["male", "female", "other"].forEach(i => {
      if (keys.includes(i)) {
        const newKeys = e.target.name.split(".");
        newKeys.splice(newKeys.indexOf(i), 1, "male");
        const male = Number(_get(data, newKeys.join("."), 0));
        newKeys.splice(newKeys.indexOf("male"), 1, "female");
        const female = Number(_get(data, newKeys.join("."), 0));
        newKeys.splice(newKeys.indexOf("female"), 1, "other");
        const other = Number(_get(data, newKeys.join("."), 0));
        newKeys.splice(newKeys.indexOf("other"), 1, "total");
        _set(data, newKeys.join("."), male+female+other);
        newKeys.splice(-1, 1, "total");
        const totalName = newKeys.join(".");
        newKeys.splice(-1, 1, "percentage");
        _set(data, newKeys.join("."), _calculatePercentage(male+female+other, _get(data, totalName, 0)));
      }
    });
    setData({ ...data });
  };

  const onEmployeeHandleChange = (e) => {
    const keys = e.target.name.split(".");
    const lastKey = keys.pop();
    const currentObj = keys.reduce((data, key) => data[key] = data[key] || {}, data);
    currentObj[lastKey] = e.target.value;
    const onlyKey = lastKey.split("_")[0];
    if (lastKey.split("_").length > 1) {
      currentObj[`${onlyKey}_percentage`] =_calculatePercentage(e.target.value, currentObj.total);
    } else {
      currentObj["percentage"] =_calculatePercentage(e.target.value, currentObj.total);
    }
    ["male", "female", "other"].forEach(i => {
      if (keys.includes(i)) {
        const newKeys = e.target.name.split(".");
        newKeys.splice(newKeys.indexOf(i), 1, "male");
        const male = Number(_get(data, newKeys.join("."), 0));
        newKeys.splice(newKeys.indexOf("male"), 1, "female");
        const female = Number(_get(data, newKeys.join("."), 0));
        newKeys.splice(newKeys.indexOf("female"), 1, "other");
        const other = Number(_get(data, newKeys.join("."), 0));
        newKeys.splice(newKeys.indexOf("other"), 1, "total");
        _set(data, newKeys.join("."), male+female+other);
        newKeys.splice(-1, 1, "total");
        const totalName = newKeys.join(".");
        if (lastKey.split("_").length > 1) {
          newKeys.splice(-1, 1, `${onlyKey}_percentage`);
        } else {
          newKeys.splice(-1, 1, "percentage");
        }
        _set(data, newKeys.join("."), _calculatePercentage(male+female+other, _get(data, totalName, 0)));
      }
    });
    draftSebiData({ ...draftReport, principle_3: data });
    setData({ ...data });
  };
  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, principle_3: data });
    setData({ ...data });
  };

  function tog_center () {
    setmodal_center(!modal_center);
  }

  const oninputFocus = (e, title, name1) => {
    if(!modal_center){
      e.target.blur();
    }
    setModalData({
      title: title,
      name: name1,
      value: _get(data, name1, "")
    });
    tog_center();
  };

  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_3",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionC",
          "subSection": "principle_3"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  return (
    <React.Fragment>
      <Modal className="center-modal"
        size="lg" isOpen={modal_center}
        toggle={() => { tog_center(); }}
        centered
        autoFocus={false}
        backdrop={"static"}
      >
        <ModalSection modalData={modalData} setModalData={setModalData} state={data} setmodal_center={setmodal_center}/>
        <div className="modal-footer">
          <button
            onClick={(e) => {
              setmodal_center(false);
              e.target.blur();
              setModalData({ name: "", value: "", type: "", title: "" });
            }}
            className="btn btn-primary"
          >Update</button>
        </div>
      </Modal>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <Row>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <form
                onSubmit={(e) => {e.preventDefault();
                  _set(sebiCurrentData, "sectionC.principle_3", data);
                  updateSebiData(sebiCurrentData);
                  draftSebiData({ ...draftReport, principle_3: false });
                }}>
                <h5 className="mt-2"><b>PRINCIPLE 3 Businesses should provide goods and services in a manner that is sustainable and safe</b> </h5>
                <p className="txt-decoration mt-2"><b>Essential Indicators</b></p>
                <p>1. A. Details of measures for the well-being of employees:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th rowSpan="3">Category</th>
                      <th rowSpan="3">Total (A)</th>
                      <th colSpan="11" className="text-center">% of Employee covered by</th>
                    </tr>
                    <tr>
                      <th colSpan="2" className="text-center">Health insurance</th>
                      <th colSpan="2" className="text-center">Accident insurance</th>
                      <th colSpan="2" className="text-center">Maternity insurance</th>
                      <th colSpan="2" className="text-center">Paternity insurance</th>
                      <th colSpan="2" className="text-center">Day Care facilities</th>
                    </tr>
                    <tr>
                      <th>Number (B)</th>
                      <th width="7%" className="text-align">% (B/A)</th>
                      <th className="text-align">Number (C)</th>
                      <th width="7%" className="text-align">% (C/A)</th>
                      <th className="text-align">Number (D)</th>
                      <th width="7%" className="text-align">% (D/A)</th>
                      <th className="text-align">Number (E)</th>
                      <th width="7%" className="text-align">% (E/A)</th>
                      <th className="text-align">Number (F)</th>
                      <th width="7%" className="text-align">% (F/A)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th colSpan="12" className="text-center">Permanent employees</th>
                    </tr>
                    <tr>
                      <td className="text-align-left">Male</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.permanent.male.total"
                        value={_get(data, "well_being_of_employees.permanent.male.total", "")}
                        className="text-align" type="number"
                      />
                      <TableInput
                        name="well_being_of_employees.permanent.male.health_insurance"
                        value={_get(data, "well_being_of_employees.permanent.male.health_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.male.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.male.accident_insurance"
                        value={_get(data, "well_being_of_employees.permanent.male.accident_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.male.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.male.maternity_benefits"
                        value={_get(data, "well_being_of_employees.permanent.male.maternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.male.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.male.paternity_benefits"
                        value={_get(data, "well_being_of_employees.permanent.male.paternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.male.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.male.day_care_facilities"
                        value={_get(data, "well_being_of_employees.permanent.male.day_care_facilities", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.male.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Female</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.permanent.female.total"
                        value={_get(data, "well_being_of_employees.permanent.female.total", "")}
                        className="text-align" type="number"
                      />
                      <TableInput
                        name="well_being_of_employees.permanent.female.health_insurance"
                        value={_get(data, "well_being_of_employees.permanent.female.health_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.female.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.female.accident_insurance"
                        value={_get(data, "well_being_of_employees.permanent.female.accident_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.female.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.female.maternity_benefits"
                        value={_get(data, "well_being_of_employees.permanent.female.maternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.female.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.female.paternity_benefits"
                        value={_get(data, "well_being_of_employees.permanent.female.paternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.female.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.female.day_care_facilities"
                        value={_get(data, "well_being_of_employees.permanent.female.day_care_facilities", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.female.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Other</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.permanent.other.total"
                        value={_get(data, "well_being_of_employees.permanent.other.total", "")}
                        className="text-align" type="number"
                      />
                      <TableInput
                        name="well_being_of_employees.permanent.other.health_insurance"
                        value={_get(data, "well_being_of_employees.permanent.other.health_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.other.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.other.accident_insurance"
                        value={_get(data, "well_being_of_employees.permanent.other.accident_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.other.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.other.maternity_benefits"
                        value={_get(data, "well_being_of_employees.permanent.other.maternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.other.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.other.paternity_benefits"
                        value={_get(data, "well_being_of_employees.permanent.other.paternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.other.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.other.day_care_facilities"
                        value={_get(data, "well_being_of_employees.permanent.other.day_care_facilities", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.other.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <th className="text-align-left">Total</th>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.permanent.total.total"
                        value={_get(data, "well_being_of_employees.permanent.total.total", "")}
                        className="text-align" type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.permanent.total.health_insurance"
                        value={_get(data, "well_being_of_employees.permanent.total.health_insurance", "")}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.total.health_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.permanent.total.accident_insurance"
                        value={_get(data, "well_being_of_employees.permanent.total.accident_insurance", "")}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.total.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.total.maternity_benefits"
                        value={_get(data, "well_being_of_employees.permanent.total.maternity_benefits", "")}
                        isDisabled={true}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.total.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.total.paternity_benefits"
                        value={_get(data, "well_being_of_employees.permanent.total.paternity_benefits", "")}
                        isDisabled={true}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.total.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.permanent.total.day_care_facilities"
                        value={_get(data, "well_being_of_employees.permanent.total.day_care_facilities", "")}
                        isDisabled={true}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.permanent.total.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <th colSpan="12" className="text-center">Other than Permanent employees</th>
                    </tr>
                    <tr>
                      <td className="text-align-left">Male</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.other_than_permanent.male.total"
                        value={_get(data, "well_being_of_employees.other_than_permanent.male.total", "")}
                        className="text-align" type="number"
                      />
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.male.health_insurance"
                        value={_get(data, "well_being_of_employees.other_than_permanent.male.health_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.male.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.male.accident_insurance"
                        value={_get(data, "well_being_of_employees.other_than_permanent.male.accident_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.male.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.male.maternity_benefits"
                        value={_get(data, "well_being_of_employees.other_than_permanent.male.maternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.male.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.male.paternity_benefits"
                        value={_get(data, "well_being_of_employees.other_than_permanent.male.paternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.male.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.male.day_care_facilities"
                        value={_get(data, "well_being_of_employees.other_than_permanent.male.day_care_facilities", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.male.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Female</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.other_than_permanent.female.total"
                        value={_get(data, "well_being_of_employees.other_than_permanent.female.total", "")}
                        className="text-align" type="number"
                      />
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.female.health_insurance"
                        value={_get(data, "well_being_of_employees.other_than_permanent.female.health_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.female.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.female.accident_insurance"
                        value={_get(data, "well_being_of_employees.other_than_permanent.female.accident_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.female.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.female.maternity_benefits"
                        value={_get(data, "well_being_of_employees.other_than_permanent.female.maternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.female.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.female.paternity_benefits"
                        value={_get(data, "well_being_of_employees.other_than_permanent.female.paternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.female.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.female.day_care_facilities"
                        value={_get(data, "well_being_of_employees.other_than_permanent.female.day_care_facilities", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.female.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Other</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.other_than_permanent.other.total"
                        value={_get(data, "well_being_of_employees.other_than_permanent.other.total", "")}
                        className="text-align" type="number"
                      />
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.other.health_insurance"
                        value={_get(data, "well_being_of_employees.other_than_permanent.other.health_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.other.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.other.accident_insurance"
                        value={_get(data, "well_being_of_employees.other_than_permanent.other.accident_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.other.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.other.maternity_benefits"
                        value={_get(data, "well_being_of_employees.other_than_permanent.other.maternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.other.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.other.paternity_benefits"
                        value={_get(data, "well_being_of_employees.other_than_permanent.other.paternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.other.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_employees.other_than_permanent.other.day_care_facilities"
                        value={_get(data, "well_being_of_employees.other_than_permanent.other.day_care_facilities", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.other.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <th className="text-align-left">Total</th>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.other_than_permanent.total.total"
                        value={_get(data, "well_being_of_employees.other_than_permanent.total.total", "")}
                        className="text-align"
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.other_than_permanent.total.health_insurance"
                        value={_get(data, "well_being_of_employees.other_than_permanent.total.health_insurance", "")}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.total.health_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.other_than_permanent.total.accident_insurance"
                        value={_get(data, "well_being_of_employees.other_than_permanent.total.accident_insurance", "")}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.total.accident_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.other_than_permanent.total.maternity_benefits"
                        value={_get(data, "well_being_of_employees.other_than_permanent.total.maternity_benefits", "")}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.total.maternity_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.other_than_permanent.total.paternity_benefits"
                        value={_get(data, "well_being_of_employees.other_than_permanent.total.paternity_benefits", "")}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.total.paternity_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_employees.other_than_permanent.total.day_care_facilities"
                        value={_get(data, "well_being_of_employees.other_than_permanent.total.day_care_facilities", "")}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_employees.other_than_permanent.total.daycare_percentage", "")}</td>
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.wellbeing_of_employee?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "wellbeing_of_employee" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.wellbeing_of_employee?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "wellbeing_of_employee" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "wellbeing_of_employee" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">B. Details of measures for the well-being of workers:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th rowSpan="3">Category</th>
                      <th rowSpan="3">Total (A)</th>
                      <th colSpan="11" className="text-center">% of Workers covered by</th>
                    </tr>
                    <tr>
                      <th colSpan="2" className="text-center">Health insurance</th>
                      <th colSpan="2" className="text-center">Accident insurance</th>
                      <th colSpan="2" className="text-center">Maternity insurance</th>
                      <th colSpan="2" className="text-center">Paternity insurance</th>
                      <th colSpan="2" className="text-center">Day Care facilities</th>
                    </tr>
                    <tr>
                      <th>Number (B)</th>
                      <th width="7%" className="text-align">% (B/A)</th>
                      <th className="text-align">Number (C)</th>
                      <th width="7%" className="text-align">% (C/A)</th>
                      <th className="text-align">Number (D)</th>
                      <th width="7%" className="text-align">% (D/A)</th>
                      <th className="text-align">Number (E)</th>
                      <th width="7%" className="text-align">% (E/A)</th>
                      <th className="text-align">Number (F)</th>
                      <th width="7%" className="text-align">% (F/A)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th colSpan="12" className="text-center">Permanent Workers</th>
                    </tr>
                    <tr>
                      <td className="text-align-left">Male</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_workers.permanent.male.total"
                        value={_get(data, "well_being_of_workers.permanent.male.total", "")}
                        className="text-align" type="number"
                      />
                      <TableInput
                        name="well_being_of_workers.permanent.male.health_insurance"
                        value={_get(data, "well_being_of_workers.permanent.male.health_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.male.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.male.accident_insurance"
                        value={_get(data, "well_being_of_workers.permanent.male.accident_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.male.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.male.maternity_benefits"
                        value={_get(data, "well_being_of_workers.permanent.male.maternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.male.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.male.paternity_benefits"
                        value={_get(data, "well_being_of_workers.permanent.male.paternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.male.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.male.day_care_facilities"
                        value={_get(data, "well_being_of_workers.permanent.male.day_care_facilities", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.male.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Female</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_workers.permanent.female.total"
                        value={_get(data, "well_being_of_workers.permanent.female.total", "")}
                        className="text-align" type="number"
                      />
                      <TableInput
                        name="well_being_of_workers.permanent.female.health_insurance"
                        value={_get(data, "well_being_of_workers.permanent.female.health_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.female.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.female.accident_insurance"
                        value={_get(data, "well_being_of_workers.permanent.female.accident_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.female.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.female.maternity_benefits"
                        value={_get(data, "well_being_of_workers.permanent.female.maternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.female.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.female.paternity_benefits"
                        value={_get(data, "well_being_of_workers.permanent.female.paternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.female.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.female.day_care_facilities"
                        value={_get(data, "well_being_of_workers.permanent.female.day_care_facilities", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.female.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <th className="text-align-left">Other</th>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_workers.permanent.other.total"
                        value={_get(data, "well_being_of_workers.permanent.other.total", "")}
                        className="text-align" type="number"
                      />
                      <TableInput
                        name="well_being_of_workers.permanent.other.health_insurance"
                        value={_get(data, "well_being_of_workers.permanent.other.health_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.other.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.other.accident_insurance"
                        value={_get(data, "well_being_of_workers.permanent.other.accident_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.other.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.other.maternity_benefits"
                        value={_get(data, "well_being_of_workers.permanent.other.maternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.other.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.other.paternity_benefits"
                        value={_get(data, "well_being_of_workers.permanent.other.paternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.other.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.other.day_care_facilities"
                        value={_get(data, "well_being_of_workers.permanent.other.day_care_facilities", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.other.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <th className="text-align-left">Total</th>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_workers.permanent.total.total"
                        value={_get(data, "well_being_of_workers.permanent.total.total", "")}
                        className="text-align"
                        type="number"
                      />
                      <TableInput
                        name="well_being_of_workers.permanent.total.health_insurance"
                        value={_get(data, "well_being_of_workers.permanent.total.health_insurance", "")}
                        isDisabled={true}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.total.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.total.accident_insurance"
                        value={_get(data, "well_being_of_workers.permanent.total.accident_insurance", "")}
                        isDisabled={true}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.total.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.total.maternity_benefits"
                        value={_get(data, "well_being_of_workers.permanent.total.maternity_benefits", "")}
                        isDisabled={true}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.total.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.total.paternity_benefits"
                        value={_get(data, "well_being_of_workers.permanent.total.paternity_benefits", "")}
                        isDisabled={true}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.total.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.permanent.total.day_care_facilities"
                        value={_get(data, "well_being_of_workers.permanent.total.day_care_facilities", "")}
                        isDisabled={true}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.permanent.total.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <th colSpan="12" className="text-center">Other than Permanent Workers</th>
                    </tr>
                    <tr>
                      <td className="text-align-left">Male</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_workers.other_than_permanent.male.total"
                        value={_get(data, "well_being_of_workers.other_than_permanent.male.total", "")}
                        className="text-align" type="number"
                      />
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.male.health_insurance"
                        value={_get(data, "well_being_of_workers.other_than_permanent.male.health_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.male.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.male.accident_insurance"
                        value={_get(data, "well_being_of_workers.other_than_permanent.male.accident_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.male.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.male.maternity_benefits"
                        value={_get(data, "well_being_of_workers.other_than_permanent.male.maternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.male.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.male.paternity_benefits"
                        value={_get(data, "well_being_of_workers.other_than_permanent.male.paternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.male.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.male.day_care_facilities"
                        value={_get(data, "well_being_of_workers.other_than_permanent.male.day_care_facilities", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.male.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Female</td>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_workers.other_than_permanent.female.total"
                        value={_get(data, "well_being_of_workers.other_than_permanent.female.total", "")}
                        className="text-align" type="number"
                      />
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.female.health_insurance"
                        value={_get(data, "well_being_of_workers.other_than_permanent.female.health_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.female.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.female.accident_insurance"
                        value={_get(data, "well_being_of_workers.other_than_permanent.female.accident_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.female.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.female.maternity_benefits"
                        value={_get(data, "well_being_of_workers.other_than_permanent.female.maternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.female.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.female.paternity_benefits"
                        value={_get(data, "well_being_of_workers.other_than_permanent.female.paternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.female.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.female.day_care_facilities"
                        value={_get(data, "well_being_of_workers.other_than_permanent.female.day_care_facilities", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.female.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <th className="text-align-left">Other</th>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_workers.other_than_permanent.other.total"
                        value={_get(data, "well_being_of_workers.other_than_permanent.other.total", "")}
                        className="text-align" type="number"
                      />
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.other.health_insurance"
                        value={_get(data, "well_being_of_workers.other_than_permanent.other.health_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.other.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.other.accident_insurance"
                        value={_get(data, "well_being_of_workers.other_than_permanent.other.accident_insurance", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.other.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.other.maternity_benefits"
                        value={_get(data, "well_being_of_workers.other_than_permanent.other.maternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.other.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.other.paternity_benefits"
                        value={_get(data, "well_being_of_workers.other_than_permanent.other.paternity_benefits", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.other.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.other.day_care_facilities"
                        value={_get(data, "well_being_of_workers.other_than_permanent.other.day_care_facilities", "")}
                        onChange={onEmployeeHandleChange}
                        className="text-align" type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.other.daycare_percentage", "")}</td>
                    </tr>
                    <tr>
                      <th className="text-align-left">Total</th>
                      <TableInput
                        isDisabled={true}
                        name="well_being_of_workers.other_than_permanent.total.total"
                        value={_get(data, "well_being_of_workers.other_than_permanent.total.total", "")}
                        className="text-align"
                        type="number"
                      />
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.total.health_insurance"
                        value={_get(data, "well_being_of_workers.other_than_permanent.total.health_insurance", "")}
                        isDisabled={true}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.total.health_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.total.accident_insurance"
                        value={_get(data, "well_being_of_workers.other_than_permanent.total.accident_insurance", "")}
                        isDisabled={true}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.total.accident_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.total.maternity_benefits"
                        value={_get(data, "well_being_of_workers.other_than_permanent.total.maternity_benefits", "")}
                        isDisabled={true}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.total.maternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.total.paternity_benefits"
                        value={_get(data, "well_being_of_workers.other_than_permanent.total.paternity_benefits", "")}
                        isDisabled={true}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.total.paternity_percentage", "")}</td>
                      <TableInput
                        name="well_being_of_workers.other_than_permanent.total.day_care_facilities"
                        value={_get(data, "well_being_of_workers.other_than_permanent.total.day_care_facilities", "")}
                        isDisabled={true}
                        className="text-align"
                        type="number"
                      />
                      <td className="text-align">{_get(data, "well_being_of_workers.other_than_permanent.total.daycare_percentage", "")}</td>
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.wellbeing_of_workers?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "wellbeing_of_workers" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.wellbeing_of_workers?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "wellbeing_of_workers" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "wellbeing_of_workers" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">2. Details of retirement benefits, for Current FY and Previous Financial Year.</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th width="12%" rowSpan="2">Benefits</th>
                      <th colSpan="3" className="text-center">FY (Current Financial Year) </th>
                      <th colSpan="3" className="text-center">FY  (Previous Financial Year)</th>
                    </tr>
                    <tr>
                      <th>No. of employees covered as a % of total employees </th>
                      <th>No.of workers covered as a % of total workers </th>
                      <th width="12%">Deducted and deposited withthe authority</th>
                      <th>No. of employees covered as a % of total employees </th>
                      <th>No.of workers covered as a % of total workers </th>
                      <th width="12%">Deducted and deposited with the authority</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-align-left">PF</td>
                      <TableInput
                        type="number"
                        name="retirement_benefits.current.pf.employees"
                        value={_get(data, "retirement_benefits.current.pf.employees", "")}
                        onChange={onHandleChange} />

                      <TableInput
                        type="number"
                        name="retirement_benefits.current.pf.workers"
                        value={_get(data, "retirement_benefits.current.pf.workers", "")}
                        onChange={onHandleChange} />

                      <td>
                        <RadioButton id="radio_313button" className="dis-right"
                          type="number"
                          name="retirement_benefits.current.pf.deducted_and_deposited"
                          value={_get(data, "retirement_benefits.current.pf.deducted_and_deposited", "")}
                          onChange={onHandleChange}
                        />
                      </td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="retirement_benefits.previous.pf.workers"
                        value={_get(data, "retirement_benefits.previous.pf.workers", "")}
                        onChange={onHandleChange} />
                      <TableInput name="retirement_benefits.previous.pf.employees"
                        type="number"
                        isDisabled={true}
                        value={_get(data, "retirement_benefits.previous.pf.employees", "")}
                        onChange={onHandleChange} />

                      <td>
                        <RadioButton id="radio_106button" className="dis-right"
                          disabled={true}
                          name="retirement_benefits.previous.pf.deducted_and_deposited"
                          value={_get(data, "retirement_benefits.previous.pf.deducted_and_deposited", "")}
                          onChange={onHandleChange} />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Gratuity</td>
                      <TableInput
                        type="number"
                        name="retirement_benefits.current.gratuity.employees"
                        value={_get(data, "retirement_benefits.current.gratuity.employees", "")}
                        onChange={onHandleChange} />

                      <TableInput
                        type="number"
                        name="retirement_benefits.current.gratuity.workers"
                        value={_get(data, "retirement_benefits.current.gratuity.workers", "")}
                        onChange={onHandleChange} />

                      <td>
                        <RadioButton id="radio_314button" className="dis-right"
                          name="retirement_benefits.current.gratuity.deducted_and_deposited"
                          value={_get(data, "retirement_benefits.current.gratuity.deducted_and_deposited", "")}
                          onChange={onHandleChange}
                        />
                      </td>
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="retirement_benefits.previous.gratuity.employees"
                        value={_get(data, "retirement_benefits.previous.gratuity.employees", "")}
                        onChange={onHandleChange} />
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="retirement_benefits.previous.gratuity.workers"
                        value={_get(data, "retirement_benefits.previous.gratuity.workers", "")}
                        onChange={onHandleChange} />

                      <td>
                        <RadioButton id="radio_106button" className="dis-right"
                          disabled={true}
                          name="retirement_benefits.previous.gratuity.deducted_and_deposited"
                          value={_get(data, "retirement_benefits.previous.gratuity.deducted_and_deposited", "")}
                          onChange={onHandleChange} />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-align-left">ESI</td>
                      <TableInput
                        type="number"
                        name="retirement_benefits.current.esi.employees"
                        value={_get(data, "retirement_benefits.current.esi.employees", "")}
                        onChange={onHandleChange} />

                      <TableInput
                        type="number"
                        name="retirement_benefits.current.esi.workers"
                        value={_get(data, "retirement_benefits.current.esi.workers", "")}
                        onChange={onHandleChange} />

                      <td>
                        <RadioButton id="radio_315button" className="dis-right"
                          name="retirement_benefits.current.esi.deducted_and_deposited"
                          value={_get(data, "retirement_benefits.current.esi.deducted_and_deposited", "")}
                          onChange={onHandleChange}
                        />
                      </td>
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="retirement_benefits.previous.esi.employees"
                        value={_get(data, "retirement_benefits.previous.esi.employees", "")}
                        onChange={onHandleChange} />
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="retirement_benefits.previous.esi.workers"
                        value={_get(data, "retirement_benefits.previous.esi.workers", "")}
                        onChange={onHandleChange} />

                      <td>
                        <RadioButton id="radio_106button" className="dis-right"
                          disabled={true}
                          name="retirement_benefits.previous.esi.deducted_and_deposited"
                          value={_get(data, "retirement_benefits.previous.esi.deducted_and_deposited", "")}
                          onChange={onHandleChange} />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Others – please specify </td>
                      <TableInput
                        type="number"
                        name="retirement_benefits.current.others.employees"
                        value={_get(data, "retirement_benefits.current.others.employees", "")}
                        onChange={onHandleChange} />

                      <TableInput
                        type="number"
                        name="retirement_benefits.current.others.workers"
                        value={_get(data, "retirement_benefits.current.others.workers", "")}
                        onChange={onHandleChange} />

                      <td>
                        <RadioButton id="radio_316button" className="dis-right"
                          name="retirement_benefits.current.others.deducted_and_deposited"
                          value={_get(data, "retirement_benefits.current.others.deducted_and_deposited", "")}
                          onChange={onHandleChange}
                        />
                      </td>
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="retirement_benefits.previous.others.employees"
                        value={_get(data, "retirement_benefits.previous.others.employees", "")}
                        onChange={onHandleChange} />
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="retirement_benefits.previous.others.workers"
                        value={_get(data, "retirement_benefits.previous.others.workers", "")}
                        onChange={onHandleChange} />

                      <td>
                        <RadioButton id="radio_106button" className="dis-right"
                          disabled={true}
                          name="retirement_benefits.previous.others.deducted_and_deposited"
                          value={_get(data, "retirement_benefits.previous.others.deducted_and_deposited", "")}
                          onChange={onHandleChange} />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.retirement_benefits?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "retirement_benefits" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.retirement_benefits?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "retirement_benefits" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "retirement_benefits" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2"><b>3. Accessibility of workplaces</b></p>
                <p>Are the premises / offices of the entity accessible to differently abled employees and workers, as per the requirements
                of the Rights of Persons with Disabilities Act, 2016?</p>
                <RadioButton id="radio_317button" className="dis-right"
                  name="are_the_premises"
                  value={_get(data, "are_the_premises", "")}
                  onChange={onHandleChange}
                />
                <p className="mt-1">If not, whether any steps are being taken by the entity in this regard.</p>
                <TextArea
                  name="steps_are_being_taken"
                  value={_get(data, "steps_are_being_taken", "")}
                  onChange={onHandleChange}
                />
                <p className="mt-2">4. Does the entity have an equal opportunity policy as per the Rights of Persons with Disabilities Act, 2016? If so, provide a web-link to the policy. </p>
                <RadioButton id="radio_318button" className="dis-right"
                  name="rights_of_persons_with_disabilities"
                  value={_get(data, "rights_of_persons_with_disabilities", "")}
                  onChange={onHandleChange}
                />
                <Input
                  name="persons_with_disabilities_weblink"
                  value={_get(data, "persons_with_disabilities_weblink", "")}
                  onChange={onHandleChange} />

                <p className="mt-2">5. Return to work and Retention rates of permanent employees and workers that took parental leave.</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th width="15%" rowSpan="2">Gender</th>
                      <th colSpan="2" className="text-center">Permanent employees</th>
                      <th colSpan="2" className="text-center">Permanent workers</th>
                    </tr>
                    <tr>
                      <th className="text-align">Return to work rate</th>
                      <th className="text-align">Retention rate</th>
                      <th className="text-align">Return to work rate</th>
                      <th className="text-align">Retention rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-align-left">Male</td>
                      <TableInput
                        name="retention_rates.male.employees.work_rate"
                        value={_get(data, "retention_rates.male.employees.work_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                      <TableInput
                        name="retention_rates.male.employees.retention_rate"
                        value={_get(data, "retention_rates.male.employees.retention_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                      <TableInput
                        name="retention_rates.male.workers.work_rate"
                        value={_get(data, "retention_rates.male.workers.work_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                      <TableInput
                        name="retention_rates.male.workers.retention_rate"
                        value={_get(data, "retention_rates.male.workers.retention_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                    </tr>
                    <tr>
                      <td className="text-align-left">Female</td>
                      <TableInput
                        name="retention_rates.female.employees.work_rate"
                        value={_get(data, "retention_rates.female.employees.work_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                      <TableInput
                        name="retention_rates.female.employees.retention_rate"
                        value={_get(data, "retention_rates.female.employees.retention_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                      <TableInput
                        name="retention_rates.female.workers.work_rate"
                        value={_get(data, "retention_rates.female.workers.work_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                      <TableInput
                        name="retention_rates.female.workers.retention_rate"
                        value={_get(data, "retention_rates.female.workers.retention_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                    </tr>
                    <tr>
                      <td className="text-align-left">Other</td>
                      <TableInput
                        name="retention_rates.other.employees.work_rate"
                        value={_get(data, "retention_rates.other.employees.work_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                      <TableInput
                        name="retention_rates.other.employees.retention_rate"
                        value={_get(data, "retention_rates.other.employees.retention_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                      <TableInput
                        name="retention_rates.other.workers.work_rate"
                        value={_get(data, "retention_rates.other.workers.work_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                      <TableInput
                        name="retention_rates.other.workers.retention_rate"
                        value={_get(data, "retention_rates.other.workers.retention_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                    </tr>
                    <tr>
                      <th className="text-align-left">Total</th>
                      <TableInput
                        name="retention_rates.total.employees.work_rate"
                        value={_get(data, "retention_rates.total.employees.work_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                      <TableInput
                        name="retention_rates.total.employees.retention_rate"
                        value={_get(data, "retention_rates.total.employees.retention_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                      <TableInput
                        name="retention_rates.total.workers.work_rate"
                        value={_get(data, "retention_rates.total.workers.work_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                      <TableInput
                        name="retention_rates.total.workers.retention_rate"
                        value={_get(data, "retention_rates.total.workers.retention_rate", "")}
                        onChange={onHandleChange}
                        type="number"
                      />
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.return_to_work?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "return_to_work" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.return_to_work?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "return_to_work" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "return_to_work" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">6. Is there a mechanism available to receive and redress grievances for the following categories of employees
                and worker? If yes, give details of the mechanism in brief.</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th width="25%"></th>
                      <th className="text-align">Yes/ No <i>(If Yes, then give details of mechanism in brief)</i></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-align-left">Permanent Workers</td>
                      <td>
                        <RadioButton id="radio_321button" className="dis-right"
                          name="receive_and_redress_grievances.workers"
                          value={_get(data, "receive_and_redress_grievances.workers", "")}
                          onChange={onHandleChange}
                        />
                        <TextArea
                          name="receive_and_redress_grievances.workers_value"
                          value={_get(data, "receive_and_redress_grievances.workers_value", "")}
                          onChange={onHandleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Other than Permanent Workers</td>
                      <td>
                        <RadioButton id="radio_322button" className="dis-right"
                          name="receive_and_redress_grievances.other_than_workers"
                          value={_get(data, "receive_and_redress_grievances.other_than_workers", "")}
                          onChange={onHandleChange}
                        />
                        <TextArea
                          name="receive_and_redress_grievances.other_than_workers_value"
                          value={_get(data, "receive_and_redress_grievances.other_than_workers_value", "")}
                          onChange={onHandleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Permanent Employees</td>
                      <td>
                        <RadioButton id="radio_323button" className="dis-right"
                          name="receive_and_redress_grievances.employees"
                          value={_get(data, "receive_and_redress_grievances.employees", "")}
                          onChange={onHandleChange}
                        />
                        <TextArea
                          name="receive_and_redress_grievances.employees_value"
                          value={_get(data, "receive_and_redress_grievances.employees_value", "")}
                          onChange={onHandleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Other than Permanent Employees</td>
                      <td>
                        <RadioButton id="radio_324button" className="dis-right"
                          name="receive_and_redress_grievances.other_than_employees"
                          value={_get(data, "receive_and_redress_grievances.other_than_employees", "")}
                          onChange={onHandleChange}
                        />
                        <TextArea
                          name="receive_and_redress_grievances.other_than_employees_value"
                          value={_get(data, "receive_and_redress_grievances.other_than_employees_value", "")}
                          onChange={onHandleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.receive_and_redress?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "receive_and_redress" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.receive_and_redress?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "receive_and_redress" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "receive_and_redress" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">7. Membership of employees and worker in association(s) or Unions recognised by the listed entity:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th width="15%" rowSpan="2">Category</th>
                      <th colSpan="3" className="text-center">FY (Current Financial Year) </th>
                      <th colSpan="3" className="text-center">FY (Previous Financial Year) </th>
                    </tr>
                    <tr>
                      <th>Total employees / workersin respective category (A) </th>
                      <th>No. of employees / workersin respective category, who are partof association(s)or Union (B) </th>
                      <th width="8%">% (B/A)</th>
                      <th>Total employees / workers in respective category (C) </th>
                      <th>No.of employees/ workersin respective category, who arepartof association(s) or Union (D) </th>
                      <th width="8%">% (D/C) </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-align-left"><b>Total Permanent Employee</b></td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_employees.total.total"
                        value={_get(data, "membership_of_unions.permanent_employees.total.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_employees.total.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_employees.total.no_of_employees", "")}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_employees.total.percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_employees.total.previous.total"
                        value={_get(data, "membership_of_unions.permanent_employees.total.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_employees.total.previous.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_employees.total.previous.no_of_employees", "")}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_employees.total.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Male</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_employees.male.total"
                        value={_get(data, "membership_of_unions.permanent_employees.male.total", "")}
                        type="number"
                      />
                      <TableInput
                        name="membership_of_unions.permanent_employees.male.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_employees.male.no_of_employees", "")}
                        onChange={onMembershipHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_employees.male.percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_employees.male.previous.total"
                        value={_get(data, "membership_of_unions.permanent_employees.male.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_employees.male.previous.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_employees.male.previous.no_of_employees", "")}
                        onChange={onMembershipHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_employees.male.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Female</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_employees.female.total"
                        value={_get(data, "membership_of_unions.permanent_employees.female.total", "")}
                        type="number"
                      />
                      <TableInput
                        name="membership_of_unions.permanent_employees.female.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_employees.female.no_of_employees", "")}
                        onChange={onMembershipHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_employees.female.percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_employees.female.previous.total"
                        value={_get(data, "membership_of_unions.permanent_employees.female.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_employees.female.previous.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_employees.female.previous.no_of_employees", "")}
                        onChange={onMembershipHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_employees.female.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Other</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_employees.other.total"
                        value={_get(data, "membership_of_unions.permanent_employees.other.total", "")}
                        type="number"
                      />
                      <TableInput
                        name="membership_of_unions.permanent_employees.other.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_employees.other.no_of_employees", "")}
                        onChange={onMembershipHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_employees.other.percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_employees.other.previous.total"
                        value={_get(data, "membership_of_unions.permanent_employees.other.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_employees.other.previous.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_employees.other.previous.no_of_employees", "")}
                        onChange={onMembershipHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_employees.other.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left"><b>Total Permanent Workers</b></td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_workers.total.total"
                        value={_get(data, "membership_of_unions.permanent_workers.total.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_workers.total.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_workers.total.no_of_employees", "")}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_workers.total.percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_workers.total.previous.total"
                        value={_get(data, "membership_of_unions.permanent_workers.total.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_workers.total.previous.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_workers.total.previous.no_of_employees", "")}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_workers.total.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Male</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_workers.male.total"
                        value={_get(data, "membership_of_unions.permanent_workers.male.total", "")}
                        type="number"
                      />
                      <TableInput
                        name="membership_of_unions.permanent_workers.male.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_workers.male.no_of_employees", "")}
                        onChange={onMembershipHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_workers.male.percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_workers.male.previous.total"
                        value={_get(data, "membership_of_unions.permanent_workers.male.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_workers.male.previous.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_workers.male.previous.no_of_employees", "")}
                        onChange={onMembershipHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_workers.male.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Female</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_workers.female.total"
                        value={_get(data, "membership_of_unions.permanent_workers.female.total", "")}
                        type="number"
                      />
                      <TableInput
                        name="membership_of_unions.permanent_workers.female.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_workers.female.no_of_employees", "")}
                        onChange={onMembershipHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_workers.female.percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_workers.female.previous.total"
                        value={_get(data, "membership_of_unions.permanent_workers.female.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_workers.female.previous.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_workers.female.previous.no_of_employees", "")}
                        onChange={onMembershipHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_workers.female.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Other</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_workers.other.total"
                        value={_get(data, "membership_of_unions.permanent_workers.other.total", "")}
                        type="number"
                      />
                      <TableInput
                        name="membership_of_unions.permanent_workers.other.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_workers.other.no_of_employees", "")}
                        onChange={onMembershipHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_workers.other.percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_workers.other.previous.total"
                        value={_get(data, "membership_of_unions.permanent_workers.other.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="membership_of_unions.permanent_workers.other.previous.no_of_employees"
                        value={_get(data, "membership_of_unions.permanent_workers.other.previous.no_of_employees", "")}
                        onChange={onMembershipHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "membership_of_unions.permanent_workers.other.previous.percentage", "")}</td>
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.membership_of_employees?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "membership_of_employees" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.membership_of_employees?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "membership_of_employees" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "membership_of_employees" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">8. Details of training given to employees and workers:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th rowSpan="3">Category</th>
                      <th colSpan="5" className="text-center">FY (Current Financial Year) </th>
                      <th colSpan="5" className="text-center">FY (Previous Financial Year) </th>
                    </tr>
                    <tr>
                      <th rowSpan="2" className="text-center">Total (A)</th>
                      <th colSpan="2" className="text-align">On Health and Safety measures</th>
                      <th colSpan="2" className="text-align">On skill upgradation</th>
                      <th rowSpan="2" className="text-center">Total (D)</th>
                      <th colSpan="2" className="text-align">On Health and Safety measures</th>
                      <th colSpan="2" className="text-align">On skill upgradation</th>
                    </tr>
                    <tr>
                      <th className="text-align">No. (B)</th>
                      <th className="text-align" width="7%">% (B/A)</th>
                      <th className="text-align">No. (C)</th>
                      <th className="text-align" width="7%">% (C/A)</th>
                      <th className="text-align">No. (E)</th>
                      <th className="text-align" width="7%">% (E/D)</th>
                      <th className="text-align">No. (F)</th>
                      <th className="text-align" width="7%">% (F/D)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th colSpan="12" className="text-center">Employees</th>
                    </tr>
                    <tr>
                      <td className="text-align-left">Male</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.male.total"
                        value={_get(data, "trainings.employees.male.total", "")}
                        type="number"
                      />
                      <TableInput
                        name="trainings.employees.male.health_and_safety_training"
                        value={_get(data, "trainings.employees.male.health_and_safety_training", "")}
                        onChange={onEmployeeHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "trainings.employees.male.health_percentage", "")}</td>
                      <TableInput
                        name="trainings.employees.male.skill_upgradation"
                        value={_get(data, "trainings.employees.male.skill_upgradation", "")}
                        onChange={onEmployeeHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "trainings.employees.male.skill_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.male.previous.total"
                        value={_get(data, "trainings.employees.male.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.male.previous.health_and_safety_training"
                        value={_get(data, "trainings.employees.male.previous.health_and_safety_training", "")}
                        type="number"
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "trainings.employees.male.previous.health_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.male.previous.skill_upgradation"
                        value={_get(data, "trainings.employees.male.previous.skill_upgradation", "")}
                        type="number"
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "trainings.employees.male.previous.skill_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Female</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.female.total"
                        value={_get(data, "trainings.employees.female.total", "")}
                        type="number"
                      />
                      <TableInput
                        name="trainings.employees.female.health_and_safety_training"
                        value={_get(data, "trainings.employees.female.health_and_safety_training", "")}
                        onChange={onEmployeeHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "trainings.employees.female.health_percentage", "")}</td>
                      <TableInput
                        name="trainings.employees.female.skill_upgradation"
                        value={_get(data, "trainings.employees.female.skill_upgradation", "")}
                        onChange={onEmployeeHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "trainings.employees.female.skill_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.female.previous.total"
                        value={_get(data, "trainings.employees.female.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.female.previous.health_and_safety_training"
                        value={_get(data, "trainings.employees.female.previous.health_and_safety_training", "")}
                        type="number"
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "trainings.employees.female.previous.health_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.female.previous.skill_upgradation"
                        value={_get(data, "trainings.employees.female.previous.skill_upgradation", "")}
                        type="number"
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "trainings.employees.female.previous.skill_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Other</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.other.total"
                        value={_get(data, "trainings.employees.other.total", "")}
                        type="number"
                      />
                      <TableInput
                        name="trainings.employees.other.health_and_safety_training"
                        value={_get(data, "trainings.employees.other.health_and_safety_training", "")}
                        onChange={onEmployeeHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "trainings.employees.other.health_percentage", "")}</td>
                      <TableInput
                        name="trainings.employees.other.skill_upgradation"
                        value={_get(data, "trainings.employees.other.skill_upgradation", "")}
                        onChange={onEmployeeHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "trainings.employees.other.skill_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.other.previous.total"
                        value={_get(data, "trainings.employees.other.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.other.previous.health_and_safety_training"
                        value={_get(data, "trainings.employees.other.previous.health_and_safety_training", "")}
                        type="number"
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "trainings.employees.other.previous.health_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.other.previous.skill_upgradation"
                        value={_get(data, "trainings.employees.other.previous.skill_upgradation", "")}
                        type="number"
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "trainings.employees.other.previous.skill_percentage", "")}</td>
                    </tr>
                    <tr>
                      <th className="text-align-left">Total</th>
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.total.total"
                        value={_get(data, "trainings.employees.total.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.total.health_and_safety_training"
                        value={_get(data, "trainings.employees.total.health_and_safety_training", "")}
                        type="number"
                      />
                      <td>{_get(data, "trainings.employees.total.health_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.total.skill_upgradation"
                        value={_get(data, "trainings.employees.total.skill_upgradation", "")}
                        type="number"
                      />
                      <td>{_get(data, "trainings.employees.total.skill_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.total.previous.total"
                        value={_get(data, "trainings.employees.total.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.total.previous.health_and_safety_training"
                        value={_get(data, "trainings.employees.total.previous.health_and_safety_training", "")}
                        type="number"
                      />
                      <td>{_get(data, "trainings.employees.total.previous.health_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.employees.total.previous.skill_upgradation"
                        value={_get(data, "trainings.employees.total.previous.skill_upgradation", "")}
                        type="number"
                      />
                      <td>{_get(data, "trainings.employees.total.previous.skill_percentage", "")}</td>
                    </tr>
                    <tr>
                      <th colSpan="12" className="text-center">Workers</th>
                    </tr>
                    <tr>
                      <td className="text-align-left">Male</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.male.total"
                        value={_get(data, "trainings.workers.male.total", "")}
                        type="number"
                      />
                      <TableInput
                        name="trainings.workers.male.health_and_safety_training"
                        value={_get(data, "trainings.workers.male.health_and_safety_training", "")}
                        onChange={onEmployeeHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "trainings.workers.male.health_percentage", "")}</td>
                      <TableInput
                        name="trainings.workers.male.skill_upgradation"
                        value={_get(data, "trainings.workers.male.skill_upgradation", "")}
                        onChange={onEmployeeHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "trainings.workers.male.skill_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.male.previous.total"
                        value={_get(data, "trainings.workers.male.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.male.previous.health_and_safety_training"
                        value={_get(data, "trainings.workers.male.previous.health_and_safety_training", "")}
                        type="number"
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "trainings.workers.male.previous.health_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.male.previous.skill_upgradation"
                        value={_get(data, "trainings.workers.male.previous.skill_upgradation", "")}
                        type="number"
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "trainings.workers.male.previous.skill_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Female</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.female.total"
                        value={_get(data, "trainings.workers.female.total", "")}
                        type="number"
                      />
                      <TableInput
                        name="trainings.workers.female.health_and_safety_training"
                        value={_get(data, "trainings.workers.female.health_and_safety_training", "")}
                        onChange={onEmployeeHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "trainings.workers.female.health_percentage", "")}</td>
                      <TableInput
                        name="trainings.workers.female.skill_upgradation"
                        value={_get(data, "trainings.workers.female.skill_upgradation", "")}
                        onChange={onEmployeeHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "trainings.workers.female.skill_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.female.previous.total"
                        value={_get(data, "trainings.workers.female.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.female.previous.health_and_safety_training"
                        value={_get(data, "trainings.workers.female.previous.health_and_safety_training", "")}
                        type="number"
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "trainings.workers.female.previous.health_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.female.previous.skill_upgradation"
                        value={_get(data, "trainings.workers.female.previous.skill_upgradation", "")}
                        type="number"
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "trainings.workers.female.previous.skill_percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Other</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.other.total"
                        value={_get(data, "trainings.workers.other.total", "")}
                        type="number"
                      />
                      <TableInput
                        name="trainings.workers.other.health_and_safety_training"
                        value={_get(data, "trainings.workers.other.health_and_safety_training", "")}
                        onChange={onEmployeeHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "trainings.workers.other.health_percentage", "")}</td>
                      <TableInput
                        name="trainings.workers.other.skill_upgradation"
                        value={_get(data, "trainings.workers.other.skill_upgradation", "")}
                        onChange={onEmployeeHandleChange}
                        type="number"
                      />
                      <td>{_get(data, "trainings.workers.other.skill_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.other.previous.total"
                        value={_get(data, "trainings.workers.other.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.other.previous.health_and_safety_training"
                        value={_get(data, "trainings.workers.other.previous.health_and_safety_training", "")}
                        type="number"
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "trainings.workers.other.previous.health_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.other.previous.skill_upgradation"
                        value={_get(data, "trainings.workers.other.previous.skill_upgradation", "")}
                        type="number"
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "trainings.workers.other.previous.skill_percentage", "")}</td>
                    </tr>
                    <tr>
                      <th className="text-align-left">Total</th>
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.total.total"
                        value={_get(data, "trainings.workers.total.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.total.health_and_safety_training"
                        value={_get(data, "trainings.workers.total.health_and_safety_training", "")}
                        type="number"
                      />
                      <td>{_get(data, "trainings.workers.total.health_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.total.skill_upgradation"
                        value={_get(data, "trainings.workers.total.skill_upgradation", "")}
                        type="number"
                      />
                      <td>{_get(data, "trainings.workers.total.skill_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.total.previous.total"
                        value={_get(data, "trainings.workers.total.previous.total", "")}
                        type="number"
                      />
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.total.previous.health_and_safety_training"
                        value={_get(data, "trainings.workers.total.previous.health_and_safety_training", "")}
                        type="number"
                      />
                      <td>{_get(data, "trainings.workers.total.previous.health_percentage", "")}</td>
                      <TableInput
                        isDisabled={true}
                        name="trainings.workers.total.previous.skill_upgradation"
                        value={_get(data, "trainings.workers.total.previous.skill_upgradation", "")}
                        type="number"
                      />
                      <td>{_get(data, "trainings.workers.total.previous.skill_percentage", "")}</td>
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.training_given_to_employees?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "training_given_to_employees" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.training_given_to_employees?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "training_given_to_employees" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "training_given_to_employees" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">9. Details of performance and career development reviews of employees and worker:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th width="15%" rowSpan="2">Category</th>
                      <th colSpan="3" className="text-center">FY (Current Financial Year) </th>
                      <th colSpan="3" className="text-center">FY (Previous Financial Year) </th>
                    </tr>
                    <tr>
                      <th>Total (A)</th>
                      <th>No. (B)</th>
                      <th width="8%">% (B/A)</th>
                      <th>Total (C)</th>
                      <th>No. (D)</th>
                      <th width="8%">% (D/C)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th colSpan="12" className="text-center">Employees</th>
                    </tr>
                    <tr>
                      <td className="text-align-left">Male</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.employees.male.total"
                        value={_get(data, "performance_and_career_development.employees.male.total", "")}
                      />
                      <TableInput
                        type="number"
                        name="performance_and_career_development.employees.male.number"
                        value={_get(data, "performance_and_career_development.employees.male.number", "")}
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "performance_and_career_development.employees.male.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.employees.male.previous.total"
                        value={_get(data, "performance_and_career_development.employees.male.previous.total", "")}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.employees.male.previous.number"
                        value={_get(data, "performance_and_career_development.employees.male.previous.number", "")}
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "performance_and_career_development.employees.male.previous.percentage", "")}</td>

                    </tr>
                    <tr>
                      <td className="text-align-left">Female</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.employees.female.total"
                        value={_get(data, "performance_and_career_development.employees.female.total", "")}
                      />
                      <TableInput
                        type="number"
                        name="performance_and_career_development.employees.female.number"
                        value={_get(data, "performance_and_career_development.employees.female.number", "")}
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "performance_and_career_development.employees.female.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.employees.female.previous.total"
                        value={_get(data, "performance_and_career_development.employees.female.previous.total", "")}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.employees.female.previous.number"
                        value={_get(data, "performance_and_career_development.employees.female.previous.number", "")}
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "performance_and_career_development.employees.female.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Other</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.employees.other.total"
                        value={_get(data, "performance_and_career_development.employees.other.total", "")}
                      />
                      <TableInput
                        type="number"
                        name="performance_and_career_development.employees.other.number"
                        value={_get(data, "performance_and_career_development.employees.other.number", "")}
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "performance_and_career_development.employees.other.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.employees.other.previous.total"
                        value={_get(data, "performance_and_career_development.employees.other.previous.total", "")}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.employees.other.previous.number"
                        value={_get(data, "performance_and_career_development.employees.other.previous.number", "")}
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "performance_and_career_development.employees.other.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <th className="text-align-left">Total</th>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.employees.total.total"
                        value={_get(data, "performance_and_career_development.employees.total.total", "")}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.employees.total.number"
                        value={_get(data, "performance_and_career_development.employees.total.number", "")}
                      />
                      <td>{_get(data, "performance_and_career_development.employees.total.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.employees.total.previous.total"
                        value={_get(data, "performance_and_career_development.employees.total.previous.total", "")}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.employees.total.previous.number"
                        value={_get(data, "performance_and_career_development.employees.total.previous.number", "")}
                      />
                      <td>{_get(data, "performance_and_career_development.employees.total.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <th colSpan="12" className="text-center">Workers</th>
                    </tr>
                    <tr>
                      <td className="text-align-left">Male</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.workers.male.total"
                        value={_get(data, "performance_and_career_development.workers.male.total", "")}
                      />
                      <TableInput
                        type="number"
                        name="performance_and_career_development.workers.male.number"
                        value={_get(data, "performance_and_career_development.workers.male.number", "")}
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "performance_and_career_development.workers.male.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.workers.male.previous.total"
                        value={_get(data, "performance_and_career_development.workers.male.previous.total", "")}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.workers.male.previous.number"
                        value={_get(data, "performance_and_career_development.workers.male.previous.number", "")}
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "performance_and_career_development.workers.male.previous.percentage", "")}</td>

                    </tr>
                    <tr>
                      <td className="text-align-left">Female</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.workers.female.total"
                        value={_get(data, "performance_and_career_development.workers.female.total", "")}
                      />
                      <TableInput
                        type="number"
                        name="performance_and_career_development.workers.female.number"
                        value={_get(data, "performance_and_career_development.workers.female.number", "")}
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "performance_and_career_development.workers.female.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.workers.female.previous.total"
                        value={_get(data, "performance_and_career_development.workers.female.previous.total", "")}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.workers.female.previous.number"
                        value={_get(data, "performance_and_career_development.workers.female.previous.number", "")}
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "performance_and_career_development.workers.female.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <td className="text-align-left">Other</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.workers.other.total"
                        value={_get(data, "performance_and_career_development.workers.other.total", "")}
                      />
                      <TableInput
                        type="number"
                        name="performance_and_career_development.workers.other.number"
                        value={_get(data, "performance_and_career_development.workers.other.number", "")}
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "performance_and_career_development.workers.other.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.workers.other.previous.total"
                        value={_get(data, "performance_and_career_development.workers.other.previous.total", "")}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.workers.other.previous.number"
                        value={_get(data, "performance_and_career_development.workers.other.previous.number", "")}
                        onChange={onEmployeeHandleChange}
                      />
                      <td>{_get(data, "performance_and_career_development.workers.other.previous.percentage", "")}</td>
                    </tr>
                    <tr>
                      <th className="text-align-left">Total</th>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.workers.total.total"
                        value={_get(data, "performance_and_career_development.workers.total.total", "")}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.workers.total.number"
                        value={_get(data, "performance_and_career_development.workers.total.number", "")}
                      />
                      <td>{_get(data, "performance_and_career_development.workers.total.percentage", "")}</td>
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.workers.total.previous.total"
                        value={_get(data, "performance_and_career_development.workers.total.previous.total", "")}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="performance_and_career_development.workers.total.previous.number"
                        value={_get(data, "performance_and_career_development.workers.total.previous.number", "")}
                      />
                      <td>{_get(data, "performance_and_career_development.workers.total.previous.percentage", "")}</td>
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.performance_and_career?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "performance_and_career" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.performance_and_career?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "performance_and_career" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "performance_and_career" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2"><b>10. Health and safety management system:</b></p>
                <p>A. Whether an occupational health and safety management system has been implemented by the entity? (Yes/ No) <br /> If yes, the coverage such system?</p>
                <RadioButton id="radio_119button" className="dis-right"
                  name="health_and_safety_management.occupational_health_and_safety_management"
                  value={_get(data, "health_and_safety_management.occupational_health_and_safety_management", "")}
                  onChange={onHandleChange}
                />
                <TextArea
                  name="health_and_safety_management.coverage_such_system"
                  value={_get(data, "health_and_safety_management.coverage_such_system", "")}
                  onChange={onHandleChange}
                />
                <p className="mt-2">B. What are the processes used to identify work-related hazards and assess risks on a routine and non-routine basis by the entity?</p>
                <TextArea
                  name="health_and_safety_management.routine_and_nonroutine"
                  value={_get(data, "health_and_safety_management.routine_and_nonroutine", "")}
                  onChange={onHandleChange}
                />
                <p className="mt-2">C.Whether you have processes for workers to report the work related hazards and to remove themselves from such risks. (Y/N)?</p>
                <RadioButton id="radio_1205button" className="dis-right"
                  name="health_and_safety_management.report_the_work_related_hazards"
                  value={_get(data, "health_and_safety_management.report_the_work_related_hazards", "")}
                  onChange={onHandleChange}
                />
                <p className="mt-2">D. Do the employees/ worker of the entity have access to non-occupational medical and healthcare services? (Yes/ No)</p>
                <RadioButton id="radio_121button" className="dis-right"
                  name="health_and_safety_management.nonoccupational_medical"
                  value={_get(data, "health_and_safety_management.nonoccupational_medical", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">11. Details of safety related incidents, in the following format:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Safety Incident/Number</th>
                      <th width="15%">Category</th>
                      <th>FY (Current Financial Year) </th>
                      <th>FY (Previous Financial Year) </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-align-left" rowSpan="2">Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)</td>
                      <td className="text-align">Employees</td>
                      <TableInput
                        type="number"
                        name="safety_related_incidents.current.lost_time.employee"
                        value={_get(data, "safety_related_incidents.current.lost_time.employee", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="safety_related_incidents.previous.lost_time.employee"
                        value={_get(data, "safety_related_incidents.previous.lost_time.employee", "")}
                        onChange={onHandleChange} />
                    </tr>
                    <tr>
                      <td className="text-align">Workers</td>
                      <TableInput
                        type="number"
                        name="safety_related_incidents.current.lost_time.workers"
                        value={_get(data, "safety_related_incidents.current.lost_time.workers", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="safety_related_incidents.previous.lost_time.workers"
                        value={_get(data, "safety_related_incidents.previous.lost_time.workers", "")}
                        onChange={onHandleChange} />
                    </tr>
                    <tr>
                      <td rowSpan="2" className="text-align-left">Total recordable work-related injuries</td>
                      <td className="text-align">Employees</td>
                      <TableInput
                        type="number"
                        name="safety_related_incidents.current.recordable_work_related.employee"
                        value={_get(data, "safety_related_incidents.current.recordable_work_related.employee", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="safety_related_incidents.previous.recordable_work_related.employee"
                        value={_get(data, "safety_related_incidents.previous.recordable_work_related.employee", "")}
                        onChange={onHandleChange} />
                    </tr>
                    <tr>
                      <td className="text-align">Workers</td>
                      <TableInput
                        type="number"
                        name="safety_related_incidents.current.recordable_work_related.workers"
                        value={_get(data, "safety_related_incidents.current.recordable_work_related.workers", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="safety_related_incidents.previous.recordable_work_related.workers"
                        value={_get(data, "safety_related_incidents.previous.recordable_work_related.workers", "")}
                        onChange={onHandleChange} />
                    </tr>
                    <tr>
                      <td rowSpan="2" className="text-align-left">No. of fatalities</td>
                      <td className="text-align">Employees</td>
                      <TableInput
                        type="number"
                        name="safety_related_incidents.current.fatalities.employee"
                        value={_get(data, "safety_related_incidents.current.fatalities.employee", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="safety_related_incidents.previous.fatalities.employee"
                        value={_get(data, "safety_related_incidents.previous.fatalities.employee", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td className="text-align">Workers</td>
                      <TableInput
                        type="number"
                        name="safety_related_incidents.current.fatalities.workers"
                        value={_get(data, "safety_related_incidents.current.fatalities.workers", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="safety_related_incidents.previous.fatalities.workers"
                        value={_get(data, "safety_related_incidents.previous.fatalities.workers", "")}
                        onChange={onHandleChange} />
                    </tr>
                    <tr>
                      <td rowSpan="2" className="text-align-left">High consequence work-related injury or ill-health (excluding fatalities)</td>
                      <td className="text-align">Employees</td>
                      <TableInput
                        type="number"
                        name="safety_related_incidents.current.high_consequence.employee"
                        value={_get(data, "safety_related_incidents.current.high_consequence.employee", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="safety_related_incidents.previous.high_consequence.employee"
                        value={_get(data, "safety_related_incidents.previous.high_consequence.employee", "")}
                        onChange={onHandleChange} />
                    </tr>
                    <tr>
                      <td className="text-align">Workers</td>
                      <TableInput
                        type="number"
                        name="safety_related_incidents.current.high_consequence.workers"
                        value={_get(data, "safety_related_incidents.current.high_consequence.workers", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="safety_related_incidents.previous.high_consequence.workers"
                        value={_get(data, "safety_related_incidents.previous.high_consequence.workers", "")}
                        onChange={onHandleChange} />
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.safety_related_incidents?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "safety_related_incidents" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.safety_related_incidents?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "safety_related_incidents" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "safety_related_incidents" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">12. Describe the measures taken by the entity to ensure a safe and healthy work place.</p>
                <TextArea
                  name="ensure_a_safe_and_healthy_work_place"
                  value={_get(data, "ensure_a_safe_and_healthy_work_place", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">13. Number of Complaints on the following made by employees and workers:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th width="15%"></th>
                      <th colSpan="3" className="text-center">FY  (Current Financial Year) </th>
                      <th colSpan="3" className="text-center">FY  (Previous Financial Year)</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th width="10%">Filed during the year</th>
                      <th width="15%">Pending resolution at the end of year</th>
                      <th className="text-align">Remarks</th>
                      <th width="10%">Filed during the year</th>
                      <th width="15%">Pending resolution at the end of year</th>
                      <th className="text-align">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-align-left">Working Conditions</td>
                      <TableInput
                        type="number"
                        className="text-align"
                        name="complaints.on_working_coditions.no_received"
                        value={_get(data, "complaints.on_working_coditions.no_received", "")}
                        onChange={onHandleChange}
                      />

                      <TableInput
                        type="number"
                        className="text-align"
                        name="complaints.on_working_coditions.no_resolved"
                        value={_get(data, "complaints.on_working_coditions.no_resolved", "")}
                        onChange={onHandleChange} />

                      <TableTextArea className="text-align"
                        name="complaints.on_working_coditions.remarks"
                        value={_get(data, "complaints.on_working_coditions.remarks", "")}
                        onChange={onHandleChange}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                      />

                      <TableInput className="text-align"
                        isDisabled={true}
                        type="number"
                        name="complaints.on_working_coditions.previous.no_received"
                        value={_get(data, "complaints.on_working_coditions.previous.no_received", "")}
                        onChange={onHandleChange}
                      />

                      <TableInput className="text-align"
                        isDisabled={true}
                        type="number"
                        name="complaints.on_working_coditions.previous.no_resolved"
                        value={_get(data, "complaints.on_working_coditions.previous.no_resolved", "")}
                        onChange={onHandleChange}
                      />

                      <TableTextArea className="text-align"
                        isDisabled={true}
                        name="complaints.on_working_coditions.previous.remarks"
                        value={_get(data, "complaints.on_working_coditions.previous.remarks", "")}
                        onChange={onHandleChange}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                      />

                    </tr>
                    <tr>
                      <td className="text-align-left">Health& Safety</td>
                      <TableInput
                        type="number"
                        className="text-align"
                        name="complaints.on_health__safety.no_received"
                        value={_get(data, "complaints.on_health__safety.no_received", "")}
                        onChange={onHandleChange}
                      />

                      <TableInput
                        type="number"
                        className="text-align"
                        name="complaints.on_health__safety.no_resolved"
                        value={_get(data, "complaints.on_health__safety.no_resolved", "")}
                        onChange={onHandleChange} />

                      <TableTextArea className="text-align"
                        name="complaints.on_health__safety.remarks"
                        value={_get(data, "complaints.on_health__safety.remarks", "")}
                        onChange={onHandleChange}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                      />

                      <TableInput className="text-align"
                        isDisabled={true}
                        type="number"
                        name="complaints.on_health__safety.previous.no_received"
                        value={_get(data, "complaints.on_health__safety.previous.no_received", "")}
                        onChange={onHandleChange}
                      />

                      <TableInput className="text-align"
                        isDisabled={true}
                        type="number"
                        name="complaints.on_health__safety.previous.no_resolved"
                        value={_get(data, "complaints.on_health__safety.previous.no_resolved", "")}
                        onChange={onHandleChange}
                      />

                      <TableTextArea className="text-align"
                        isDisabled={true}
                        name="complaints.on_health__safety.previous.remarks"
                        value={_get(data, "complaints.on_health__safety.previous.remarks", "")}
                        onChange={onHandleChange}
                        onFocus={oninputFocus}
                        title={"Remarks"}
                      />

                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.number_of_complaints?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "number_of_complaints" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.number_of_complaints?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "number_of_complaints" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "number_of_complaints" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">14.	Assessments for the year::</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th></th>
                      <th className="text-align">% of your plants and offices that were assessed (by entity or statutory authorities or third parties)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-align-left">Health and safety practices</td>
                      <TableInput
                        type="number"
                        name="assessments_for_the_year.health_and_safety_practices_percentage"
                        value={_get(data, "assessments_for_the_year.health_and_safety_practices_percentage", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td className="text-align-left">Working Conditions</td>
                      <TableInput
                        type="number"
                        name="assessments_for_the_year.working_conditions_percentage"
                        value={_get(data, "assessments_for_the_year.working_conditions_percentage", "")}
                        onChange={onHandleChange} />
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.assessments?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "assessments" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.assessments?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "assessments" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "assessments" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">15.Provide details of any corrective action taken or underway to address safety-related incidents (if any)
                and on significant risks / concerns arising from assessments of health & safety practices and working conditions.</p>
                <TextArea
                  name="address_safety_related_incidents"
                  value={_get(data, "address_safety_related_incidents", "")}
                  onChange={onHandleChange} />

                <p className="txt-decoration mt-2"><b>Leadership Indicators</b></p>

                <p>1.Does the entity extend any life insurance or any compensatory package in the event of death of</p>
                <div className="dis-flex">
                  <p>(A) Employees (Y/N).</p>
                  <RadioButton id="radio_122button" className="dis-right"
                    name="event_of_death.employee"
                    value={_get(data, "event_of_death.employee", "")}
                    onChange={onHandleChange}
                  />
                </div>
                <div className="dis-flex">
                  <p>(B) Workers (Y/N).</p>
                  <RadioButton id="radio_325button" className="dis-right"
                    name="event_of_death.workers"
                    value={_get(data, "event_of_death.workers", "")}
                    onChange={onHandleChange}
                  />
                </div>

                <p className="mt-2">2.Provide the measures undertaken by the entity to ensure that statutory dues have been deducted and deposited by the value chain partners.</p>
                <TextArea
                  name="deposited_by_the_value_chain_partners"
                  value={_get(data, "deposited_by_the_value_chain_partners", "")}
                  onChange={onHandleChange} />

                <p className="mt-2">3.Provide the number of employees / workers having suffered high consequence work- related injury / ill-health
                / fatalities (as reported in Q11 of Essential Indicators above), who have been are rehabilitated and placed in suitable employment or
                whose family members have been placed in suitable employment:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th width="16%" rowSpan="2"></th>
                      <th width="42%" colSpan="2" className="text-center">Total no.of affected employees/ workers</th>
                      <th width="42%" colSpan="2" className="text-center">No. of employees/workers that are rehabilitated and placed in suitable employment or
                      whose family members have been placed in suitable employment</th>
                    </tr>
                    <tr>
                      <th className="text-center">FY (Current Financial Year)</th>
                      <th className="text-center">FY (Previous Financial Year)</th>
                      <th className="text-center">FY (Current Financial Year)</th>
                      <th className="text-center">FY (Previous Financial Year)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-align-left">Employees</td>
                      <TableInput
                        type="number"
                        name="placed_in_suitable_employment.employees.affected.current"
                        value={_get(data, "placed_in_suitable_employment.employees.affected.current", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="placed_in_suitable_employment.employees.affected.previous"
                        value={_get(data, "placed_in_suitable_employment.employees.affected.previous", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        type="number"
                        name="placed_in_suitable_employment.employees.rehabilitated.current"
                        value={_get(data, "placed_in_suitable_employment.employees.rehabilitated.current", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        isDisabled={true}
                        type="number"
                        name="placed_in_suitable_employment.employees.rehabilitated.previous"
                        value={_get(data, "placed_in_suitable_employment.employees.rehabilitated.previous", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td className="text-align-left">Workers</td>
                      <TableInput
                        type="number"
                        name="placed_in_suitable_employment.workers.affected.current"
                        value={_get(data, "placed_in_suitable_employment.workers.affected.current", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="placed_in_suitable_employment.workers.affected.previous"
                        value={_get(data, "placed_in_suitable_employment.workers.affected.previous", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        type="number"
                        name="placed_in_suitable_employment.workers.rehabilitated.current"
                        value={_get(data, "placed_in_suitable_employment.workers.rehabilitated.current", "")}
                        onChange={onHandleChange}
                      />
                      <TableInput
                        type="number"
                        isDisabled={true}
                        name="placed_in_suitable_employment.workers.rehabilitated.previous"
                        value={_get(data, "placed_in_suitable_employment.workers.rehabilitated.previous", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.suffered_high_consequence?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "suffered_high_consequence" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.suffered_high_consequence?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "suffered_high_consequence" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "suffered_high_consequence" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">4.Does the entity provide transition assistance programs to facilitate continued employability and the management of career
                endings resulting from retirement or termination of employment? (Yes/ No)</p>
                <RadioButton id="radio_326button"
                  name="termination_of_employment"
                  value={_get(data, "termination_of_employment", "")}
                  onChange={onHandleChange}
                />

                <p className="mt-2">5.Details on assessment of value chain partners:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th></th>
                      <th className="text-align">% of value chain partners (by value of business done with such partners) that were assessed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-align-left">Health and safety practices</td>
                      <TableInput
                        type="number"
                        name="assessment_of_value_chain_partners.health_and_safety_percentage"
                        value={_get(data, "assessment_of_value_chain_partners.health_and_safety_percentage", "")}
                        onChange={onHandleChange} />
                    </tr>
                    <tr>
                      <td className="text-align-left">Working Conditions</td>
                      <TableInput
                        type="number"
                        name="assessment_of_value_chain_partners.working_conditions_percentage"
                        value={_get(data, "assessment_of_value_chain_partners.working_conditions_percentage", "")}
                        onChange={onHandleChange} />
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.value_chain_partners?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "value_chain_partners" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.value_chain_partners?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "value_chain_partners" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "value_chain_partners" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">6.Provide details of any corrective actions taken or underway to address significant risks / concerns arising
                from assessments of health and safety practices and working conditions of value chain partners.</p>
                <TextArea
                  name="significant_risks_concerns_arising_from_assessments"
                  value={_get(data, "significant_risks_concerns_arising_from_assessments", "")}
                  onChange={onHandleChange}
                />

                <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <button type="button" className="btn btn-primary w-md "
                      onClick={() => onSectionChange(10)}>Previous</button>{" "}
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                    <button type="button" className="btn btn-primary w-md "
                      onClick={() => onSectionChange(12)}>Next</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionC?.principle_3,
    previousData: state.NewReportReducer?.sebiForm?.previousYearData?.sectionC?.principle_3,
    employeeData: state.NewReportReducer?.sebiForm?.currentYear?.sectionA?.workforce_data,
    prefillData: state.NewReportReducer?.sebiForm?.sebiPrefil,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.principle_3,
    filesData: state.NewReportReducer?.draftFiles?.principle_3
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(Principle3);
