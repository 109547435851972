import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Formik, FieldArray, Field } from "formik";
import { Modal, Card, Col, DropdownToggle, DropdownMenu, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import ModalSection from "../ModalSection";
import Input from "components/Inputs/Input";
import FormUpload from "../Modalfileupload";
import TextArea from "components/Inputs/TextArea";
import TableInput from "components/Inputs/TableInput";
import RadioButton from "components/Inputs/RadioButton";
import { AxiosGet, AxiosPost } from "common/axiosService";
import TableTextArea from "components/Inputs/TableTextArea";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const Principle2 = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionC.principle_2;
  const { draftSebiData, draftData, updateSebiData, newReport, prefillData, draftReport, onSectionChange, previousData, oldData, getFile, filesData } = props;

  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);

  const [filedatachange, setFileDataChange] = useState(true);

  const [modal_center, setmodal_center] = useState(false);
  const [modalData, setModalData] = useState({ name: "", value: "", type: "", title: "" });

  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionC", subSection: "principle_2", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect(() =>{
    if (newReport) {
      setSebiCurrentData(newReport?.currentYear);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_2"
      });
    }
  }, [newReport, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionC",
      "subSection": "principle_2"
    });
  }, [filedatachange]);

  useEffect(() =>{
    if (prefillData?.waste_management) {
      _set(data, "waste_management", prefillData?.waste_management);
    }
    if (previousData) {
      _set(data, "percentage_of_r&d.capex.previous", _get(previousData, "percentage_of_r&d.capex.current", ""));
      _set(data, "percentage_of_r&d.r_d.previous", _get(previousData, "percentage_of_r&d.r_d.current", ""));
      ["plastics", "ewaste", "hazardous_waste", "other_waste"].forEach(item =>{
        _set(data, `waste_management.${item}.previous.recycled`, _get(previousData, `waste_management.${item}.recycled`, ""));
        _set(data, `waste_management.${item}.previous.reused`, _get(previousData, `waste_management.${item}.reused`, ""));
        _set(data, `waste_management.${item}.previous.safely_disposed`, _get(previousData, `waste_management.${item}.safely_disposed`, ""));
      });
      if (previousData?.recycled_or_reused_input_material) {
        previousData?.recycled_or_reused_input_material.forEach((item, index) => {
          if (oldData?.recycled_or_reused_input_material && oldData?.recycled_or_reused_input_material?.length > 0) {
            oldData?.recycled_or_reused_input_material.map((oldItem, oldIndex) =>{
              if (_get(previousData, `recycled_or_reused_input_material[${index}].input_material`, "") === _get(oldData, `recycled_or_reused_input_material[${index}].input_material`, "")) {
                _set(data, `recycled_or_reused_input_material[${oldIndex}].previous`, _get(previousData, `recycled_or_reused_input_material[${oldIndex}].current`, ""));
              }
            });
            if (!oldData?.recycled_or_reused_input_material?.some(item => item.input_material === _get(previousData, `recycled_or_reused_input_material[${index}].input_material`, ""))) {
              _set(data, `recycled_or_reused_input_material[${oldData?.recycled_or_reused_input_material?.length + index}]`, {
                input_material: _get(previousData, `recycled_or_reused_input_material[${index}].input_material`, ""),
                previous: _get(previousData, `recycled_or_reused_input_material[${index}].current`, ""),
                current: ""
              });
            }
          } else {
            _set(data, `recycled_or_reused_input_material[${index}].input_material`, _get(previousData, `recycled_or_reused_input_material[${index}].input_material`, ""));
            _set(data, `recycled_or_reused_input_material[${index}].previous`, _get(previousData, `recycled_or_reused_input_material[${index}].current`, ""));
          }
        });
      }
    }
  }, [prefillData, previousData]);

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, principle_2: data });
    setData({ ...data });
  };

  function tog_center () {
    setmodal_center(!modal_center);
  }

  const oninputFocus = (e, title, name1) => {
    if(!modal_center){
      e.target.blur();
    }
    setModalData({
      title: title,
      name: name1,
      value: _get(data, name1, "")
    });
    tog_center();
  };

  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionC",
        "subSection": "principle_2",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionC",
          "subSection": "principle_2"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  return (
    <React.Fragment>
      <Modal className="center-modal"
        size="lg" isOpen={modal_center}
        toggle={() => { tog_center(); }}
        centered
        autoFocus={false}
        backdrop={"static"}
      >
        <ModalSection modalData={modalData} setModalData={setModalData} state={data} setmodal_center={setmodal_center}/>
        <div className="modal-footer">
          <button
            onClick={(e) => {
              setmodal_center(false);
              e.target.blur();
              setModalData({ name: "", value: "", type: "", title: "" });
            }}
            className="btn btn-primary"
          >Update</button>
        </div>
      </Modal>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <Row>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h5><b>PRINCIPLE 2 Businesses should provide goods and services in a manner that is sustainable and safe</b> </h5>
              <p className="txt-decoration mt-2"><b>Essential Indicators</b></p>
              <Formik initialValues={data}>
                {({ values, setFieldValue }) => (
                  <form
                    onChange={onHandleChange}
                    onSubmit={(e) => {e.preventDefault();
                      _set(sebiCurrentData, "sectionC.principle_2", data);
                      updateSebiData(sebiCurrentData);
                      draftSebiData({ ...draftReport, principle_2: false });
                    }}>
                    <p>1. Percentage of R&D and capital expenditure (capex) investments in specific technologies to improve the environmental and
                      social impacts of product and processes to total R&D and capex investments made by the entity, respectively.</p>
                    <table className="table table-bordered mb-0">
                      <thead className="table-light">
                        <tr>
                          <th width="15%"></th>
                          <th className="text-align" width="20%">Current Financial Year</th>
                          <th className="text-align" width="20%">Previous Financial Year</th>
                          <th className="text-align">Details of improvements in environmental and social impacts</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-align-left">R & D</td>

                          <TableInput
                            name="percentage_of_r&d.r_d.current"
                            value={_get(data, "percentage_of_r&d.r_d.current", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableInput
                            isDisabled={true}
                            name="percentage_of_r&d.r_d.previous"
                            value={_get(data, "percentage_of_r&d.r_d.previous", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>

                          <TableTextArea
                            name="percentage_of_r&d.r_d.details"
                            value={_get(data, "percentage_of_r&d.r_d.details", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Details of improvements in environmental and social impacts"}
                          />
                        </tr>
                        <tr>
                          <td className="text-align-left">Capex</td>
                          <TableInput
                            name="percentage_of_r&d.capex.current"
                            value={_get(data, "percentage_of_r&d.capex.current", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>
                          <TableInput
                            isDisabled={true}
                            name="percentage_of_r&d.capex.previous"
                            value={_get(data, "percentage_of_r&d.capex.previous", "")}
                            onChange={onHandleChange}
                            className="text-align" type="number"/>
                          <TableTextArea
                            name="percentage_of_r&d.capex.details"
                            value={_get(data, "percentage_of_r&d.capex.details", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            onFocus={oninputFocus}
                            title={"Details of improvements in environmental and social impacts"}
                          />
                        </tr>
                      </tbody>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.percentage_of_r_and_d?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "percentage_of_r_and_d" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.percentage_of_r_and_d?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "percentage_of_r_and_d" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "percentage_of_r_and_d" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2"><b>2. A.</b> Does the entity have procedures in place for sustainable sourcing? (Yes/No)</p>
                    <RadioButton
                      name="have_procedures_in_place"
                      value={_get(data, "have_procedures_in_place", "")}
                      onChange={onHandleChange}
                      id="radio_104button" />
                    <p className="mt-2"><b>B.</b> If yes, what percentage of inputs were sourced sustainably?</p>
                    <Input
                      type="number"
                      name="what_percentage_of_inputs"
                      value={_get(data, "what_percentage_of_inputs", "")}
                      onChange={onHandleChange}

                    />
                    <p className="mt-2">3. Describe the processes in place to safely reclaim your products for reusing, recycling and disposing at the end of life, for</p>
                    <table className="table table-bordered mb-0">
                      <tbody>
                        <tr>
                          <td width="23%" className="text-align-left">(A) Plastics (including packaging) </td>
                          <TableTextArea
                            name="safely_reclaim_your_products.plastics"
                            value={_get(data, "safely_reclaim_your_products.plastics", "")}
                            onChange={onHandleChange}
                            onFocus={oninputFocus}
                            title={"Describe"}
                          />
                        </tr>
                        <tr>
                          <td className="text-align-left">(B) E-waste</td>
                          <TableTextArea
                            name="safely_reclaim_your_products.ewaste"
                            value={_get(data, "safely_reclaim_your_products.ewaste", "")}
                            onChange={onHandleChange}
                            onFocus={oninputFocus}
                            title={"Describe"}
                          />
                        </tr>
                        <tr>
                          <td className="text-align-left">(C) Hazardous waste</td>
                          <TableTextArea
                            name="safely_reclaim_your_products.hazardous_waste"
                            value={_get(data, "safely_reclaim_your_products.hazardous_waste", "")}
                            onChange={onHandleChange}
                            onFocus={oninputFocus}
                            title={"Describe"}
                          />
                        </tr>
                        <tr>
                          <td className="text-align-left">(D) Other waste.</td>
                          <TableTextArea
                            name="safely_reclaim_your_products.other_waste"
                            value={_get(data, "safely_reclaim_your_products.other_waste", "")}
                            onChange={onHandleChange}
                            onFocus={oninputFocus}
                            title={"Describe"}
                          />
                        </tr>
                      </tbody>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.place_to_safely?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "place_to_safely" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.place_to_safely?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "place_to_safely" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "place_to_safely" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">4. Whether Extended Producer Responsibility (EPR) is applicable to the entity’s activities (Yes / No).</p>
                    <RadioButton
                      name="extended_producer_responsibility.applicable_to_the_entity_activities"
                      value={_get(data, "extended_producer_responsibility.applicable_to_the_entity_activities", "")}
                      onChange={onHandleChange}
                      id="radio_100button" />

                    <p className="mt-1">If yes, whether the waste collection plan is in line with the Extended Producer Responsibility (EPR) plan
                     submitted to Pollution Control Boards? If not, provide steps taken to address the same.</p>
                    <TextArea
                      name="extended_producer_responsibility.collection_plan_and_action_taken"
                      value={_get(data, "extended_producer_responsibility.collection_plan_and_action_taken", "")}
                      onChange={onHandleChange}
                    />

                    <p className="txt-decoration mt-2"><b>Leadership Indicators</b></p>
                    <p>1. Has the entity conducted Life Cycle Perspective / Assessments (LCA) for any of its products (for manufacturing industry)
                      or for its services (for service industry)? If yes, provide details in following format?</p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="conducted_life_cycle_perspective">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th className="text-align">NIC Code</th>
                                <th className="text-align">Name of Product /Service </th>
                                <th className="text-align" width="12%">% of total Turnover contributed</th>
                                <th className="text-align" width="19%">Boundary for which the Life Cycle Perspective / Assessment was conducted</th>
                                <th className="text-align" width="15%">Whether conducted by independent external agency</th>
                                <th className="text-align" width="20%">Results communicated in public domain. If yes, provide the web-link. </th>
                                <th width="3%" onClick={() => {
                                  push({
                                    nic: "", name_of_product: "", percentage: "", assessment_was_conducted: "",
                                    external_agency: "", public_domain: "", weblink: ""
                                  });
                                  setData({
                                    ...data, conducted_life_cycle_perspective: [
                                      ...data.conducted_life_cycle_perspective,
                                      {
                                        nic: "", name_of_product: "", percentage: "", assessment_was_conducted: "",
                                        external_agency: "", public_domain: "", weblink: ""
                                      }
                                    ]
                                  });
                                }
                                }><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.conducted_life_cycle_perspective?.length > 0 && values?.conducted_life_cycle_perspective?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">
                                    <Field
                                      name={`conducted_life_cycle_perspective[${index}].nic`}
                                      placeholder="NIC"
                                      type="text"
                                      className="form-control"
                                    />
                                  </td>

                                  <td className="text-align">
                                    <Field
                                      name={`conducted_life_cycle_perspective[${index}].name_of_product`}
                                      placeholder="Name Of Product"
                                      type="text"
                                      className="form-control"
                                    />
                                  </td>

                                  <td className="text-align">
                                    <Field
                                      name={`conducted_life_cycle_perspective[${index}].percentage`}
                                      placeholder="Percentage"
                                      type="number"
                                      onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                      onWheel={(e) => e.target.blur()}
                                      className="form-control"
                                    />
                                  </td>

                                  <td className="text-align" role="group" aria-labelledby="my-radio-group">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="Yes"
                                        name={`conducted_life_cycle_perspective[${index}].assessment_was_conducted`}
                                        id={`radioBtn303[${index}]`}
                                        onClick={(e) => {
                                          onHandleChange(e);
                                          setFieldValue(`conducted_life_cycle_perspective[${index}].assessment_was_conducted`, e.target.value );
                                        }}
                                        onChange={() => {}}
                                        checked={_get(values, `conducted_life_cycle_perspective[${index}].assessment_was_conducted`, "") === "Yes"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn303[${index}]`}>Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="No"
                                        name={`conducted_life_cycle_perspective[${index}].assessment_was_conducted`}
                                        id={`radioBtn310[${index}]`}
                                        onClick={(e) => {
                                          onHandleChange(e);
                                          setFieldValue(`conducted_life_cycle_perspective[${index}].assessment_was_conducted`, e.target.value );
                                        }}
                                        onChange={() => {}}
                                        checked={_get(values, `conducted_life_cycle_perspective[${index}].assessment_was_conducted`, "") === "No"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn310[${index}]`}>No</label>
                                    </div>
                                  </td>

                                  <td className="text-align" role="group" aria-labelledby="my-radio-group">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="Yes"
                                        name={`conducted_life_cycle_perspective[${index}].external_agency`}
                                        id={`radioBtn304[${index}]`}
                                        onClick={(e) => {
                                          onHandleChange(e);
                                          setFieldValue(`conducted_life_cycle_perspective[${index}].external_agency`, e.target.value );
                                        }}
                                        onChange={() => {}}
                                        checked={_get(values, `conducted_life_cycle_perspective[${index}].external_agency`, "") === "Yes"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn304[${index}]`}>Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="No"
                                        name={`conducted_life_cycle_perspective[${index}].external_agency`}
                                        id={`radioBtn311[${index}]`}
                                        onClick={(e) => {
                                          onHandleChange(e);
                                          setFieldValue(`conducted_life_cycle_perspective[${index}].external_agency`, e.target.value );
                                        }}
                                        onChange={() => {}}
                                        checked={_get(values, `conducted_life_cycle_perspective[${index}].external_agency`, "") === "No"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn311[${index}]`}>No</label>
                                    </div>
                                  </td>

                                  <td className="text-align" role="group" aria-labelledby="my-radio-group">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="Yes"
                                        name={`conducted_life_cycle_perspective[${index}].public_domain`}
                                        id={`radioBtn305[${index}]`}
                                        onClick={(e) => {
                                          onHandleChange(e);
                                          setFieldValue(`conducted_life_cycle_perspective[${index}].public_domain`, e.target.value );
                                        }}
                                        onChange={() => {}}
                                        checked={_get(values, `conducted_life_cycle_perspective[${index}].public_domain`, "") === "Yes"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn305[${index}]`}>Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="No"
                                        name={`conducted_life_cycle_perspective[${index}].public_domain`}
                                        id={`radioBtn312[${index}]`}
                                        onClick={(e) => {
                                          onHandleChange(e);
                                          setFieldValue(`conducted_life_cycle_perspective[${index}].public_domain`, e.target.value );
                                        }}
                                        onChange={() => {}}
                                        checked={_get(values, `conducted_life_cycle_perspective[${index}].public_domain`, "") === "No"}
                                      />
                                      <label className="form-check-label" htmlFor={`radioBtn312[${index}]`}>No</label>
                                    </div>
                                    <Field
                                      name={`conducted_life_cycle_perspective[${index}].weblink`}
                                      placeholder="Weblink"
                                      type="text"
                                      className="form-control"
                                    />
                                  </td>

                                  {values?.conducted_life_cycle_perspective?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.conducted_life_cycle_perspective.filter((item, i) => index !== i);
                                    draftSebiData({ ...draftReport, principle_2: { ...data, conducted_life_cycle_perspective: newr } });
                                    setData({ ...data, conducted_life_cycle_perspective: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.life_cycle_perspective?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "life_cycle_perspective" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.life_cycle_perspective?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "life_cycle_perspective" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "life_cycle_perspective" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">2. If there are any significant social or environmental concerns and/or risks arising from production
                or disposal of your products / services, as identified in the Life Cycle Perspective / Assessments (LCA) or through any other means,
                 briefly describe the same along-with action taken to mitigate the same.</p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="risks_arising_from_production_or_disposal">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th width="20%" className="text-align">Name of Product / Service</th>
                                <th className="text-align">Description of the risk / concern</th>
                                <th className="text-align">Action Taken</th>
                                <th width="3%" onClick={() => {
                                  push( { name_of_product: "", description: "", action: "" });
                                  setData({
                                    ...data, risks_arising_from_production_or_disposal: [
                                      ...data.risks_arising_from_production_or_disposal,
                                      { name_of_product: "", description: "", action: "" }
                                    ]
                                  });
                                }}><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.risks_arising_from_production_or_disposal?.length > 0 && values?.risks_arising_from_production_or_disposal?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">
                                    <Field
                                      name={`risks_arising_from_production_or_disposal[${index}].name_of_product`}
                                      placeholder="Name of Product / Service"
                                      type="text"
                                      className="form-control"
                                      value={data.risks_arising_from_production_or_disposal[index].name_of_product}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Name of Product / Service",
                                          name: `risks_arising_from_production_or_disposal[${index}].name_of_product`,
                                          value: _get(data, `risks_arising_from_production_or_disposal[${index}].name_of_product`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      as="textarea"
                                      name={`risks_arising_from_production_or_disposal[${index}].description`}
                                      placeholder="Description of the risk / concern"
                                      className="form-control"
                                      value={data.risks_arising_from_production_or_disposal[index].description}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Description of the risk / concern",
                                          name: `risks_arising_from_production_or_disposal[${index}].description`,
                                          value: _get(data, `risks_arising_from_production_or_disposal[${index}].description`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td className="text-align">
                                    <Field
                                      as="textarea"
                                      name={`risks_arising_from_production_or_disposal[${index}].action`}
                                      placeholder="Action Taken"
                                      className="form-control"
                                      value={data.risks_arising_from_production_or_disposal[index].action}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Action Taken",
                                          name: `risks_arising_from_production_or_disposal[${index}].action`,
                                          value: _get(data, `risks_arising_from_production_or_disposal[${index}].action`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  {values?.risks_arising_from_production_or_disposal?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.risks_arising_from_production_or_disposal.filter((item, i) => index !== i);
                                    draftSebiData({ ...draftReport, principle_2: { ...data, risks_arising_from_production_or_disposal: newr } });
                                    setData({ ...data, risks_arising_from_production_or_disposal: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.significant_social?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "significant_social" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.significant_social?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "significant_social" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "significant_social" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">3. Percentage of recycled or reused input material to total material (by value) used in production
                (for manufacturing industry) or providing services (for service industry).</p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="recycled_or_reused_input_material">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th className="text-align" rowSpan="2">Indicate input material</th>
                                <th className="text-align" colSpan="3">Recycled or re-used input material to total material</th>
                              </tr>
                              <tr>
                                <th className="text-align">FY (Current Financial  Year)</th>
                                <th className="text-align">FY (Previous Financial  Year)</th>
                                <th width="3%" onClick={() => {
                                  push( { input_material: "", current: "", previous: "" });
                                  setData({
                                    ...data, recycled_or_reused_input_material: [
                                      ...data.recycled_or_reused_input_material,
                                      { input_material: "", current: "", previous: "" }
                                    ]
                                  });
                                }}><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.recycled_or_reused_input_material?.length > 0 && values?.recycled_or_reused_input_material?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">
                                    <Field
                                      name={`recycled_or_reused_input_material[${index}].input_material`}
                                      placeholder="Indicate input material"
                                      type="text"
                                      className="form-control"/>
                                  </td>

                                  <td className="text-align">
                                    <Field
                                      name={`recycled_or_reused_input_material[${index}].current`}
                                      placeholder="Current year"
                                      type="number"
                                      onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                      onWheel={(e) => e.target.blur()}
                                      className="form-control" />
                                  </td>

                                  <td className="text-align">
                                    <Field
                                      disabled={true}
                                      name={`recycled_or_reused_input_material[${index}].previous`}
                                      placeholder="Previous year"
                                      type="number"
                                      onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                      onWheel={(e) => e.target.blur()}
                                      className="form-control" />
                                  </td>

                                  {values?.recycled_or_reused_input_material?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.recycled_or_reused_input_material.filter((item, i) => index !== i);
                                    draftSebiData({ ...draftReport, principle_2: { ...data, recycled_or_reused_input_material: newr } });
                                    setData({ ...data, recycled_or_reused_input_material: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.recycled_or_reused?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "recycled_or_reused" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.recycled_or_reused?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "recycled_or_reused" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "recycled_or_reused" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">4. Of the products and packaging reclaimed at end of life of products, amount (in metric tonnes) reused,
                     recycled, and safely disposed, as per the following format:</p>
                    <table className="table table-bordered mb-0">
                      <thead className="table-light">
                        <tr>
                          <th rowSpan="2"></th>
                          <th className="text-align" colSpan="3">FY (Current Financial  Year)</th>
                          <th className="text-align" colSpan="3">FY (Previous Financial  Year)</th>
                        </tr>
                        <tr>
                          <th className="text-align">Re-used</th>
                          <th className="text-align">Recycled</th>
                          <th className="text-align">Safely Disposed</th>
                          <th className="text-align">Re-used</th>
                          <th className="text-align">Recycled</th>
                          <th className="text-align">Safely Disposed</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-align-left">Plastic (including packaging)</td>
                          <TableInput
                            name="waste_management.plastics.reused"
                            value={_get(data, "waste_management.plastics.reused", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            name="waste_management.plastics.recycled"
                            value={_get(data, "waste_management.plastics.recycled", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            name="waste_management.plastics.safely_disposed"
                            value={_get(data, "waste_management.plastics.safely_disposed", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            isDisabled={true}
                            name="waste_management.plastics.previous.reused"
                            value={_get(data, "waste_management.plastics.previous.reused", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            isDisabled={true}
                            name="waste_management.plastics.previous.recycled"
                            value={_get(data, "waste_management.plastics.previous.recycled", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            isDisabled={true}
                            name="waste_management.plastics.previous.safely_disposed"
                            value={_get(data, "waste_management.plastics.previous.safely_disposed", "")}
                            onChange={onHandleChange} type="number"/>
                        </tr>
                        <tr>
                          <td className="text-align-left">E-waste</td>
                          <TableInput
                            name="waste_management.ewaste.reused"
                            value={_get(data, "waste_management.ewaste.reused", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            name="waste_management.ewaste.recycled"
                            value={_get(data, "waste_management.ewaste.recycled", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            name="waste_management.ewaste.safely_disposed"
                            value={_get(data, "waste_management.ewaste.safely_disposed", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            isDisabled={true}
                            name="waste_management.ewaste.previous.reused"
                            value={_get(data, "waste_management.ewaste.previous.reused", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            isDisabled={true}
                            name="waste_management.ewaste.previous.recycled"
                            value={_get(data, "waste_management.ewaste.previous.recycled", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            isDisabled={true}
                            name="waste_management.ewaste.previous.safely_disposed"
                            value={_get(data, "waste_management.ewaste.previous.safely_disposed", "")}
                            onChange={onHandleChange} type="number"/>
                        </tr>
                        <tr>
                          <td className="text-align-left">Hazardous waste</td>
                          <TableInput
                            name="waste_management.hazardous_waste.reused"
                            value={_get(data, "waste_management.hazardous_waste.reused", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            name="waste_management.hazardous_waste.recycled"
                            value={_get(data, "waste_management.hazardous_waste.recycled", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            name="waste_management.hazardous_waste.safely_disposed"
                            value={_get(data, "waste_management.hazardous_waste.safely_disposed", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            isDisabled={true}
                            name="waste_management.hazardous_waste.previous.reused"
                            value={_get(data, "waste_management.hazardous_waste.previous.reused", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            isDisabled={true}
                            name="waste_management.hazardous_waste.previous.recycled"
                            value={_get(data, "waste_management.hazardous_waste.previous.recycled", "")}
                            onChange={onHandleChange} type="number"/>
                          <TableInput
                            isDisabled={true}
                            name="waste_management.hazardous_waste.previous.safely_disposed"
                            value={_get(data, "waste_management.hazardous_waste.previous.safely_disposed", "")}
                            onChange={onHandleChange} type="number"/>
                        </tr>
                        <tr>
                          <td className="text-align-left">Other waste</td>
                          <TableInput
                            name="waste_management.other_waste.reused"
                            value={_get(data, "waste_management.other_waste.reused", "")}
                            onChange={onHandleChange} />
                          <TableInput
                            name="waste_management.other_waste.recycled"
                            value={_get(data, "waste_management.other_waste.recycled", "")}
                            onChange={onHandleChange} />
                          <TableInput
                            name="waste_management.other_waste.safely_disposed"
                            value={_get(data, "waste_management.other_waste.safely_disposed", "")}
                            onChange={onHandleChange} />
                          <TableInput
                            isDisabled={true}
                            name="waste_management.other_waste.previous.reused"
                            value={_get(data, "waste_management.other_waste.previous.reused", "")}
                            onChange={onHandleChange} />
                          <TableInput
                            isDisabled={true}
                            name="waste_management.other_waste.previous.recycled"
                            value={_get(data, "waste_management.other_waste.previous.recycled", "")}
                            onChange={onHandleChange} />
                          <TableInput
                            isDisabled={true}
                            name="waste_management.other_waste.previous.safely_disposed"
                            value={_get(data, "waste_management.other_waste.previous.safely_disposed", "")}
                            onChange={onHandleChange} />
                        </tr>
                      </tbody>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.products_and_packaging?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "products_and_packaging" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.products_and_packaging?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "products_and_packaging" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "products_and_packaging" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2">5. Reclaimed products and their packaging materials (as percentage of products sold) for each product category.</p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="reclaimed_products">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th width="60%" className="text-align">Indicate product category</th>
                                <th className="text-align">Reclaimed products and their packaging materials as % of total products sold in respective category </th>
                                <th width="3%" onClick={() => {
                                  push( { category: "", percentage: "" });
                                  setData({
                                    ...data, reclaimed_products: [
                                      ...data.reclaimed_products,
                                      { category: "", percentage: "" }
                                    ]
                                  });
                                }}><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.reclaimed_products?.length > 0 && values?.reclaimed_products?.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">
                                    <Field
                                      name={`reclaimed_products[${index}].category`}
                                      placeholder="Indicate product category"
                                      type="text"
                                      className="form-control"/>
                                  </td>

                                  <td className="text-align">
                                    <Field
                                      name={`reclaimed_products[${index}].percentage`}
                                      placeholder="% of total products sold in respective category"
                                      type="number"
                                      onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }}
                                      onWheel={(e) => e.target.blur()}
                                      className="form-control"/>
                                  </td>

                                  {values?.reclaimed_products?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.reclaimed_products.filter((item, i) => index !== i);
                                    draftSebiData({ ...draftReport, principle_2: { ...data, reclaimed_products: newr } });
                                    setData({ ...data, reclaimed_products: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.reclaimed_products?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && (res.statusCode === 200 && res.data[0])) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "reclaimed_products" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}

                      {filesData?.businesses_demonstrate?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "reclaimed_products" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "reclaimed_products" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => onSectionChange(9)}>Previous</button>{" "}
                      </div>
                      <div>
                        <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => onSectionChange(11)}>Next</button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>

  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionC?.principle_2,
    previousData: state.NewReportReducer?.sebiForm?.previousYearData?.sectionC?.principle_2,
    prefillData: state.NewReportReducer?.sebiForm?.sebiPrefil,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.principle_2,
    filesData: state.NewReportReducer?.draftFiles?.principle_2
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(Principle2);