import React from "react";
import { withRouter } from "react-router-dom";
import { Field, FieldArray, Formik, Form } from "formik";
import { Col, Row, Button, Label, TabContent, TabPane, UncontrolledTooltip, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import classnames from "classnames";

// step four function

const Uservalidation = Yup.object({
  users: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().when(["name", "role"], {
        is: (name, role) => name || role,
        then: Yup.string().required("Please Enter Email" )
      }),

      name: Yup.string().when(["email", "role"], {
        is: (email, role) => email || role,
        then: Yup.string().required("Please Enter Name")
      }),

      role: Yup.string().when(["email", "name"], {
        is: (email, name) => email || name,
        then: Yup.string().required("Please Select Role")
      })
    }, [
      ["name", "role"],
      ["email", "role"],
      ["email", "name"]
    ])
  )
});

const Users = props => {
  const removeEmptyValue = (values, pop) => {
    values.users.map((user) => {
      if (user.email == "" && user.name == "" && user.role == "") {
        pop(user.email, user.name, user.role);
      }
    });
  };

  const handleSubmit = async values => {
    props.setstepLoading(prev => ({
      ...prev,
      ["step4"]: true
    }));
    setTimeout(() => {
      props.next(values, "FINAL");
      props.toggleTab(3, 75);
    }, 600);
  };

  return (
    <div className="content w-100 clearfix mt-0">
      <TabContent activeTab={3} className="body">
        <TabPane tabId={3}>
          <Row>
            <Col lg="12">
              <div className="mb-3">
                <p>
                  Greenalytics is organize around teams that typically
                  reflect your organization, Each team can set up their
                  own escalation, on-call schedules and routing rules.
                  Below, please set up your team to experience these
                  features of Greenalytics
                </p>
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-2">
                <Label for="team_name">
                  <b>
                  Enter your team&apos;s name & add members to your team
                  </b>
                </Label>
                <p>
                  Specify a name for your team, i.e. SRE_Team,
                  Database_Team, ...
                </p>
                <p>
                  <span className="tooltip-stack">
                    <i className="dripicons-question" id={"admin"}>
                      <UncontrolledTooltip
                        placement="top"
                        text="left"
                        target={"admin"}
                      >
                              admin:
                      </UncontrolledTooltip>
                    </i>{" "}
                          Admin
                  </span>
                  <span className="tooltip-stack">
                    <i className="dripicons-question" id={"Stakeholder"}>
                      <UncontrolledTooltip
                        placement="right"
                        target={"Stakeholder"}
                      >
                              Stakeholder:
                      </UncontrolledTooltip>
                    </i>
                          Stakeholder
                  </span>
                  <span id={"user"} className="tooltip-stack">
                    <i className="dripicons-question" /> User
                    <UncontrolledTooltip
                      placement="right"
                      target={"user"}
                    >
                            user:
                    </UncontrolledTooltip>
                  </span>
                </p>
              </div>
            </Col>
          </Row>
          <Formik
            validationSchema={Uservalidation}
            initialValues={props.data}
            onSubmit={handleSubmit}
          >
            {({ values, errors }) => (
              <Form>
                <FieldArray name="users">
                  {({ remove, push, pop }) => (
                    <>
                      <Row>
                        <Col xs="12">
                          {values.users.length > 0 &&
                            values.users.map((user, index) => {
                              const userErrors = (errors.users?.length && errors.users[index]) || {};
                              return(
                                <div
                                  className="position-relative shadow border mb-3 p-3 d-block"
                                  key={index + 1}
                                  // eslint-disable-next-line
                                  count={index + 1}
                                >
                                  <div data-repeater-list="group-a">
                                    <Row data-repeater-item>
                                      <Col lg="4" className="">
                                        <Field
                                          id="email"
                                          type="email"
                                          name={`users[${index}].email`}
                                          placeholder="User E-mail"
                                          className={classnames(
                                            "form-control",
                                            userErrors.email
                                              ? "is-invalid"
                                              : ""
                                          )}
                                        />
                                        {userErrors.email && <FormFeedback type="invalid">
                                          {userErrors.email}
                                        </FormFeedback>
                                        }
                                      </Col>

                                      <Col lg="4" className="">
                                        <Field
                                          type="text"
                                          name={`users[${index}].name`}
                                          className={classnames(
                                            "form-control",
                                            userErrors.name
                                              ? "is-invalid"
                                              : ""
                                          )}
                                          placeholder="Name"
                                        />
                                        {userErrors.name && <FormFeedback type="invalid">
                                          {userErrors.name}
                                        </FormFeedback>
                                        }
                                      </Col>

                                      <Col lg="4" className="">
                                        <Field
                                          as="select"
                                          name={`users[${index}].role`}
                                          className={classnames(
                                            "form-select",
                                            userErrors.role
                                              ? "is-invalid"
                                              : ""
                                          )}
                                        >
                                          <option value="">Select Role</option>
                                          <option value="Stakeholder">
                                            Stakeholder
                                          </option>
                                          <option value="Admin">Admin</option>
                                          <option value="User">User</option>
                                        </Field>

                                        {userErrors.role && <FormFeedback type="invalid">
                                          {userErrors.role}
                                        </FormFeedback>}
                                      </Col>
                                      {values.users.length > 1 && <Col lg="1" className="close-btn">
                                        <i
                                          className="mdi mdi-close-box mdi-close-btn"
                                          onClick={() => remove(index)}
                                        />
                                      </Col>}
                                    </Row>
                                  </div>
                                </div>
                              );
                            })}
                          <Button
                            onClick={() =>
                              push({ email: "", name: "", role: "" })
                            }
                            color="default"
                            className="mt-3 mt-lg-0 btn btn-outline-primary btn-sm"
                          >
                            + Add More User{" "}
                          </Button>{" "}
                        </Col>
                      </Row>

                      <Row className="mt-3 next-prev-btn">
                        <Col>
                          <button
                            className="btn btn-primary btn-block"
                            type="button"
                            onClick={() => {
                              removeEmptyValue(values, pop);
                              setTimeout(() => {
                                props.next(values, "FINAL");
                              }, 500);
                            }}
                          >
                            Skip
                          </button>{" "}
                          <button
                            className="btn btn-primary btn-block"
                            type="button"
                            onClick={() => {
                              props.prev(values);
                            }}
                            disabled={props.stepLoading.step4}
                          >
                            Prev
                          </button>{" "}
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            onClick={() => {
                              setTimeout(() => {
                                removeEmptyValue(values, pop);
                              }, 500);
                            }}
                            disabled={props.stepLoading.step4}
                          >
                            {props.stepLoading.step4
                              ? (
                                <div
                                  className="spinner-border text-white"
                                  role="status"
                                  style={{ width: "1rem", height: "1rem" }}
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )
                              : (
                                "Submit"
                              )}
                          </button>
                        </Col>
                      </Row>
                    </>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withRouter(Users);
