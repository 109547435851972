import React from "react";
import { useState } from "react";
// import { Link } from "react-router-dom";
import { Card, CardBody, Col, Modal } from "reactstrap";

const CardPricing = ({ pricing }) => {
  const [modal_center, setmodal_center] = useState(false);

  function removeBodyCss () {
    document.body.classList.add("no_padding");
  }
  function tog_center () {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  return(
    <React.Fragment>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{pricing.modalHeading}</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          </p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary"
            onClick={() =>
              setmodal_center(false)
            }>Close</button>

          <button type="button" className="btn btn-primary">Save changes</button>
        </div>
      </Modal>
      <Col xl="4" md="6">
        <Card className="plan-box">
          <CardBody className="p-4">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h5>{pricing.title}</h5>
                <p className="text-muted">{}</p>
              </div>
              <div className="ms-3">
                <img className="h1 text-primary" width={40} src={pricing.img}/>
              </div>
            </div>
            <div className="py-4">
              <h2>
                <sup>
                  <small>$</small>
                </sup>{" "}
                {pricing.price}/{" "}
                <span className="font-size-13">{pricing.duration}</span>
              </h2>
            </div>
            <div className="text-center wrapper">
              <button
                className="btn btn-primary btn-sm m-0"
                onClick={() => {
                  tog_center();
                }}
              >
                Sign up Now
              </button>
            </div>

            <div className="plan-features mt-5">
              {pricing.features.map((feature, key) => (
                <p key={"_feature_" + key}>
                  { feature.title ?
                    <i className="bx bx-checkbox-square text-primary me-2" />
                    : <h6><b>{feature.title2}</b></h6>
                  }
                  {feature.title}
                </p>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );};

export default CardPricing;
