import React from "react";
import { Badge } from "reactstrap";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import { AiOutlinePlus, AiOutlineLine } from "react-icons/ai";

function SidebarItem ({ mainitem, setshowformdata, item, ...rest }) {
  // const [collapsed, setCollapsed] = React.useState(true);
  const { categoryName, subCategory, data, isOpen, isNewAdded } = item;
  // function toggleCollapse () {
  //   setCollapsed(prevValue => !prevValue);
  // }

  // function onClick () {
  //   if (Array.isArray(subCategory)) {
  //     toggleCollapse();
  //   }
  // }

  let expandIcon;

  if (Array.isArray(subCategory) && subCategory.length) {
    expandIcon = isOpen
      ? (
        <AiOutlineLine className="icon-size" />
      )
      : (
        <AiOutlinePlus className="icon-size" />
      );
  }

  return (
    <>
      <ListItem
        onClick={() => {
          if (item._id === mainitem._id) {
            setshowformdata({
              selectCategory: item,
              mainCategory: "",
              allData: data.length > 0 ? data : []
            });
          } else {
            setshowformdata({
              selectCategory: item,
              mainCategory: mainitem,
              allData: data.length > 0 ? data : []
            });
          }
        }}
        id="listitem-pading-0"
        className={!isOpen ? "sidebar-item" : "sidebar-item list-active"}
        {...rest}
      >
        <div
          id="listitem-pading"
          className="sidebar-item-content"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            cursor: "pointer"
          }}
        >
          <div className="sidebar-item-text text-capital">{categoryName} {isNewAdded && <span className="badge rounded-pill bg-success ">
              New
          </span>}</div>

          <div>
            <Badge>{subCategory && subCategory.length ? subCategory.length : ""}</Badge>
            {expandIcon}
          </div>
        </div>
      </ListItem>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {Array.isArray(subCategory)
          ? (
            <List disablePadding dense style={{ marginLeft: "10px" }}>
              {subCategory.map((subItem, index) => (
                <React.Fragment key={`${subItem.name}${index}`}>
                  <SidebarItem
                    mainitem={mainitem}
                    setshowformdata={setshowformdata}
                    item={subItem}
                  />
                </React.Fragment>
              ))}
            </List>
          )
          : null}
      </Collapse>
    </>
  );
}

function Sidebar ({ items, onFormDataShow }) {
  return (
    <div className="sidebar">
      <List disablePadding dense>
        {items.map((sidebarItem, index) => (
          <React.Fragment key={`${sidebarItem.name}${index}`}>
            <SidebarItem
              mainitem={sidebarItem}
              item={sidebarItem}
              setshowformdata={onFormDataShow}
            />
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

export default Sidebar;
