// @flow
import * as actionTypes from "./actionTypes";
const INIT_STATE = {
  sebiForm: {},
  draftSebiForm: {
    company_details: false, products_services: false,
    operation: false, workforce_data: false, h_s_a: false,
    csr_details: false, t_and_d: false, MPD_disclosure: false,
    principle_1: false, principle_2: false, principle_3: false, principle_4: false,
    principle_5: false, principle_6: false, principle_7: false, principle_8: false, principle_9: false
  },

  draftFiles: {
    products_services: false,
    operation: false, workforce_data: false, h_s_a: false,
    csr_details: false, t_and_d: false, MPD_disclosure: false,
    principle_1: false, principle_2: false, principle_3: false, principle_4: false,
    principle_5: false, principle_6: false, principle_7: false, principle_8: false, principle_9: false
  },
  sebiFormLoading: null
};

const ReportReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case actionTypes.LOADING_SEBI_FORM:
    return {
      ...state,
      sebiFormLoading: action.payload
    };
  case actionTypes.DRAFT_SEBI_FORM:
    return {
      ...state,
      sebiFormLoading: false,
      draftSebiForm: action.payload ? action.payload : INIT_STATE?.draftSebiForm
    };
  case actionTypes.ADD_SEBI_FORM:
    return {
      ...state,
      sebiFormLoading: false,
      sebiForm: {
        ...state.sebiForm,
        ...action.payload
      }
    };
  case actionTypes.UPDATE_SEBI_FORM:
    return {
      ...state,
      sebiFormLoading: false,
      sebiForm: {
        ...state.sebiForm,
        ...action.payload
      }
    };
  case actionTypes.GET_SEBI_FORM:
    return {
      sebiFormLoading: false,
      draftSebiForm: INIT_STATE?.draftSebiForm,
      sebiForm: action.payload
    };
  case actionTypes.GET_FILES:
    return {
      ...state,
      sebiFormLoading: false,
      draftFiles: action.payload ? { ...state.draftFiles, ...action.payload } : INIT_STATE?.draftFiles
    };
  default:
    return state;
  }
};

export default ReportReducer;
