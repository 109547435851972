import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import MetisMenu from "metismenujs";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { ROLES } from "common/constants";

const SidebarContent = props => {

  const ref = useRef();
  const session = JSON.parse(localStorage.getItem("authUser"));

  // const currentName = useLocation();

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName && pathName.includes("/admin/report") && items[i].pathname === "/admin/report") {
          matchingMenuItem = items[i];
          break;
        } else if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement (item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown (item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" className="sidebar-menu-position">
          <ul className="metismenu list-unstyled" id="side-menu">
            {session.userData.user.role === ROLES.SUPERADMIN && (
              <>
                <li>
                  <Link to="/superadmin/dashboard">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("The Lobby")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/superadmin/company/company-list">
                    <i className="bx bxs-school"></i>
                    <span>{props.t("Company List")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/superadmin/users">
                    <i className="bx bx-file"></i>
                    <span>{props.t("Users")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/superadmin/logs">
                    <i className="bx bx-analyse"></i>
                    <span>{props.t("Logs")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/superadmin/report">
                    <i className="bx bx-file"></i>
                    <span>{props.t("Matrix")}</span>
                  </Link>
                </li>
              </>
            )}
            {session.userData.user.role === ROLES.ADMIN && (
              <>
                <li>
                  <Link to="/admin/dashboard">
                    <i className="bx bx-store"></i>
                    <span>{props.t("The Lobby")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/#" className="has-arrow" >
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Your Company")}</span>
                  </Link>

                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/yourcompany">
                        {props.t("Company Details")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/locations">
                        {props.t("Add Location")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/users">
                        {props.t("Invite Users")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/billing">
                        {props.t("Billings")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/logs">
                        <span>{props.t("Logs")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li className={currentName.pathname.includes("admin/report/brsr") && "mm-active"}>
                  <Link to="/#" id="report" className={currentName.pathname.includes("admin/report/brsr") ? "has-arrow mm-active" : "has-arrow"} >
                    <i className="bx bxs-report"></i>
                    <span>{props.t("Reports")}</span>
                  </Link>

                  <ul className="sub-menu">
                    <li id="brsr" className={currentName.pathname.includes("admin/report/brsr") ? "mm-active" : ""}>
                      <Link to="/admin/report">
                        {props.t("BRSR")}
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li >
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-report"></i>
                    <span>{props.t("Reports")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/report" >
                        {props.t("BRSR")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/admin/analytics">
                    <i className="bx bx-line-chart"></i>
                    <span>{props.t("Analytics")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="mdi mdi-tree"></i>
                    <span>{props.t("Carbon Footprint")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/carbonDashboard">
                        {props.t("Dashboard")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/carbon-footprint">
                        {props.t("Emissions' Calculations")}
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-upload"></i>
                    <span>{props.t("Enter ESG Data")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/upload-data/bulk-upload">
                        {props.t("Bulk Upload Using CSV")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/upload-data/single-upload">
                        {props.t("Manual Entry")}
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-upload"></i>
                    <span>{props.t("Enter Data")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/businessInformation">
                        {props.t("Business Information")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/#">
                        {props.t("ESG Data")}
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/admin/upload-data/bulk-upload">
                            {props.t("Bulk Upload Using CSV")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/admin/upload-data/single-upload">
                            {props.t("Manual Entry")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/admin/integrations">
                    <i className="bx bx-cog"></i>
                    <span>{props.t("Integrations")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-download"></i>
                    <span>{props.t("My Reports")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/completed-reports">
                        <span>{props.t("Completed Reports ")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/draft">
                        <span>{props.t("Draft Reports")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={`/admin/supportingDoc/${new Date().getFullYear()}`}>
                    <i className="dripicons-paperclip"></i>
                    <span>{props.t("Supporting Documents")}</span>
                  </Link>
                </li>
              </>
            )}
            {session.userData.user.role === ROLES.STAKEHOLDERS && (
              <>
                <li>
                  <Link to="/stakeholder/dashboard">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/stakeholder/users">
                    <i className="bx bx-user"></i>
                    <span>{props.t("Users")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/stakeholder/locations">
                    <i className="bx bx-map"></i>
                    <span>{props.t("Locations")}</span>
                  </Link>
                </li>

                <li>
                  <a className="has-arrow">
                    <i className="bx bx-line-chart"></i>
                    <span>{props.t("Analytics")}</span>
                  </a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/stakeholder/analytics/environmental">
                        {props.t("Environmental data")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/stakeholder/analytics/social">
                        {props.t("Social data")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/stakeholder/analytics/governance">
                        {props.t("Governance Data")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <a className="has-arrow">
                    <i className="bx bxs-report"></i>
                    <span>{props.t("Reports")}</span>
                  </a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/stakeholder/report/sebi">
                        {props.t("SEBI Report")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/stakeholder/reports/gri-report">
                        {props.t("GRI Report")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/stakeholder/reports/custom-report">
                        {props.t("Customize Report")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="#">
                    <i className="bx bx-cog"></i>
                    <span>{props.t("Support")}</span>
                  </Link>
                </li>
              </>
            )}
            {session.userData.user.role === ROLES.USER && (
              <>
                <li>
                  <Link to="/user/dashboard">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>

                <li>
                  <Link to={"/#"} className="has-arrow">
                    <i className="bx bxs-report"></i>
                    <span>{props.t("Reports")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/user/report/sebi">
                        {props.t("SEBI Report")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/user/reports/gri-report">
                        {props.t("GRI Report")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/user/reports/custom-report">
                        {props.t("Customize Report")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={"/#"} className="has-arrow">
                    <i className="bx bx-upload"></i>
                    <span>{props.t("Upload Data")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/user/upload-data/bulk-upload">
                        {props.t("Bulk Upload")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/user/upload-data/single-upload">
                        {props.t("Manual Entry")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#">
                    <i className="bx bx-cog"></i>
                    <span>{props.t("Support")}</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};

export default withRouter(withTranslation()(SidebarContent));
