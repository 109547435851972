import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTable, usePagination, useSortBy } from "react-table";
import { Button, Col, Input, Row, Table } from "reactstrap";
import UsersFilter from "./Filter";
import Sorting from "./Sorting";

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: null,
  queryPageFilter: "",
  queryPageSortBy: []
};

const PAGE_CHANGED = "PAGE_CHANGED";
const PAGE_SIZE_CHANGED = "PAGE_SIZE_CHANGED";
const PAGE_SORT_CHANGED = "PAGE_SORT_CHANGED";
const PAGE_FILTER_CHANGED = "PAGE_FILTER_CHANGED";
const TOTAL_COUNT_CHANGED = "TOTAL_COUNT_CHANGED";

const reducer = (state, { type, payload }) => {
  switch (type) {
  case PAGE_CHANGED:
    return {
      ...state,
      queryPageIndex: payload
    };
  case PAGE_SIZE_CHANGED:
    return {
      ...state,
      queryPageSize: payload
    };
  case PAGE_SORT_CHANGED:
    return {
      ...state,
      queryPageSortBy: payload
    };
  case PAGE_FILTER_CHANGED:
    return {
      ...state,
      queryPageFilter: payload
    };
  case TOTAL_COUNT_CHANGED:
    return {
      ...state,
      totalCount: payload
    };
  default:
    throw new Error(`Unhandled action type: ${type}`);
  }
};

function PokemonTable ({
  columns, fetchPokemonData, customPageSizeOptions, isAddNewLocation, handleAdminuserClick, isAddCustList,
  isLocationMessage, isActiveMessage
}) {

  const [keyword, setKeyword] = useState("");
  const [useFilter, setUseFilter] = useState(false);
  const onClickFilterCallback = ( filter ) => {
    if(filter.trim() === "") {
      setKeyword("");
      return;
    }
    if(filter === keyword) {
      alert("No change in search");
      return;
    }
    setUseFilter(true);
    setKeyword(filter);
  };

  const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy }, dispatch] =
    React.useReducer(reducer, initialState);

  const { isLoading, error, data, isSuccess } = useQuery(
    ["pokemons", queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy],
    () => fetchPokemonData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy }
  } = useTable(
    {
      columns,
      data: isSuccess ? data.result : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy
      },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]);

  useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
    gotoPage(0);
  }, [sortBy, gotoPage]);

  useEffect(() => {
    if ( useFilter ) {
      dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
      gotoPage(0);
    }
  }, [keyword, gotoPage, useFilter]);

  useEffect(() => {
    if (data?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.count
      });
    }
  }, [data?.count]);

  if (error) {
    return <p>Error</p>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Fragment>
      {isSuccess
        ? (
          <>
            <Row className="mb-2">
              <Col md={customPageSizeOptions ? 2 : 1}>
                <select
                  className="form-select"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                    </option>
                  ))}
                </select>
              </Col>

              <UsersFilter onClickFilterCallback={onClickFilterCallback} defaultKeyword={keyword} count={data?.count} />

              {isAddCustList && (
                <Col sm="7">
                  <div className="text-sm-end">
                    <Button type="button" color="success" className="btn-rounded mb-2 me-2" onClick={handleAdminuserClick}>
                      <i className="mdi mdi-plus me-1"/>
                      {isAddNewLocation ? "New Location" : "New Users"}
                    </Button>
                  </div>
                </Col>
              )}
            </Row>

            <div className="table-responsive react-table">
              {page.length > 0 ?
                (
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-light table-nowrap">
                      {headerGroups.map((headerGroup) => (
                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th key={column.id} {...column.getHeaderProps({
                              style: {
                                minWidth: column.minWidth,
                                width: column.width
                              }
                            })}>
                              <div {...column.getSortByToggleProps()}>
                                {column.render("Header")} { <Sorting column={column} />}
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr key={row.getRowProps().key} {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td key={cell.id} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) :
                (
                  <>
                    {isLocationMessage
                      ? (
                        <div className="tableEmpty">
                          <h3>No Locations Found</h3>
                        </div>
                      )
                      : isActiveMessage
                        ? (
                          <div className="tableEmpty">
                            <h3>No Activity Found</h3>
                          </div>
                        )
                        : (
                          <div className="tableEmpty">
                            <h1>No User Found</h1>
                          </div>
                        )
                    }
                  </>
                )}
            </div>

            {page.length > 0
              ? (
                <Row className="justify-content-md-end justify-content-center align-items-center">
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button color="primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{"<<"}</Button>
                      <Button color="primary" onClick={() => previousPage()} disabled={!canPreviousPage}>{"<"}</Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto d-none d-md-block">
                    <span>
                Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                  </Col>
                  <Col className="col-md-auto">
                    <span>
                      <Input
                        type="number"
                        value={pageIndex + 1}
                        onChange={(e) => {
                          const page = e.target.value ? Number(e.target.value) - 1 : 0;
                          gotoPage(page);
                        }}
                        style={{ width: "100px" }}
                      />
                    </span>{" "}
                  </Col>
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button color="primary" onClick={() => nextPage()} disabled={!canNextPage}>{">"}</Button>
                      <Button color="primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{">>"}</Button>
                    </div>
                  </Col>
                </Row>
              )
              : (
                ""
              )}
          </>
        )
        : null}
    </Fragment>
  );
}

export default PokemonTable;
