import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect, useRef } from "react";
import { get as _get, set as _set } from "lodash";
import { Card, Col, DropdownToggle, DropdownMenu, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import FormUpload from "../Modalfileupload";
import TableInput from "components/Inputs/TableInput";
import { AxiosGet, AxiosPost } from "common/axiosService";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const useScroll = () => {
  const elRef = useRef(null);
  // const executeScroll = () => elRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  const executeScroll = () => elRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

  return [executeScroll, elRef];
};

const Employees = (props) => {

  const [executeScroll, elRef] = useScroll();
  useEffect(executeScroll, []);

  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionA.workforce_data;
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, onSectionChange, previousData, oldData, getFile, filesData } = props;

  const [filedatachange, setFileDataChange] = useState(true);
  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData ? draftData : oldData ? oldData : initialState);
  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionA", subSection: "workforce_data", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect(() => {
    if (newReport) {
      if (oldData) {
        empPrefillCounting(oldData);
      }
      setSebiCurrentData(newReport.currentYear);
    }
    if (previousData) {
      ["male", "female", "other", "total"].forEach(item => {
        const employees = `turnover_rate_for_permanent_employees_and_workers.permanent_employees.previous.${item}`;
        const workers = `turnover_rate_for_permanent_employees_and_workers.permanent_workers.previous.${item}`;

        _set(data, employees, _get(previousData, `turnover_rate_for_permanent_employees_and_workers.permanent_employees.${item}`, ""));
        _set(data, workers, _get(previousData, `turnover_rate_for_permanent_employees_and_workers.permanent_workers.${item}`, ""));

      });
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionA",
        "subSection": "workforce_data"
      });
    }
  }, [newReport, previousData, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionA",
      "subSection": "workforce_data"
    });
  }, [filedatachange]);

  const empCounting = (keys, currentObj) => {
    const male = Number(_get(currentObj, "male", 0));
    const female = Number(_get(currentObj, "female", 0));
    const other = Number(_get(currentObj, "other", 0));
    const total = male + female + other;
    const malePercentage = _calculatePercentage(male, total);
    const femalePercentage = _calculatePercentage(female, total);
    const otherPercentage = _calculatePercentage(other, total);
    _set(currentObj, "malepercentage", malePercentage);
    _set(currentObj, "femalepercentage", femalePercentage);
    _set(currentObj, "otherpercentage", otherPercentage);
    _set(currentObj, "total", total);
    const objPermanent = [...keys, "permanent"].reduce((data, key) => data[key] = data[key] || {}, data);
    const objOtherThan = [...keys, "other_than_permanent"].reduce((data, key) => data[key] = data[key] || {}, data);
    const objTotal = [...keys, "total"].reduce((data, key) => data[key] = data[key] || {}, data);
    const mainMale = Number(_get(objPermanent, "male", 0)) + Number(_get(objOtherThan, "male", 0));
    const mainFemale = Number(_get(objPermanent, "female", 0)) + Number(_get(objOtherThan, "female", 0));
    const mainOther = Number(_get(objPermanent, "other", 0)) + Number(_get(objOtherThan, "other", 0));
    const mainTotal = Number(_get(objPermanent, "total", 0)) + Number(_get(objOtherThan, "total", 0));
    objTotal["male"] = mainMale;
    objTotal["female"] = mainFemale;
    objTotal["other"] = mainOther;
    objTotal["total"] = mainTotal;
    _set(objTotal, "malepercentage", _calculatePercentage(mainMale, mainTotal));
    _set(objTotal, "femalepercentage", _calculatePercentage(mainFemale, mainTotal));
    _set(objTotal, "otherpercentage", _calculatePercentage(mainOther, mainTotal));
  };
  const empPrefillCounting = (currentObj) => {
    ["workers", "employees", "differently_abled_employees", "differently_abled_workers"].forEach(item => {
      let totalMale = 0;
      let totalFemale = 0;
      let totalOther = 0;
      ["other_than_permanent", "permanent"].forEach((subItem) => {
        const male = Number(_get(currentObj, `${item}.${subItem}.male`, 0));
        const female = Number(_get(currentObj, `${item}.${subItem}.female`, 0));
        const other = Number(_get(currentObj, `${item}.${subItem}.other`, 0));
        totalMale += male;
        totalFemale += female;
        totalOther += other;
        const total = male + female + other;
        _set(data, `${item}.${subItem}.total`, total);
        _set(data, `${item}.${subItem}.malepercentage`, _calculatePercentage(male, total));
        _set(data, `${item}.${subItem}.femalepercentage`, _calculatePercentage(female, total));
        _set(data, `${item}.${subItem}.otherpercentage`, _calculatePercentage(other, total));
      });

      const permanent = Number(_get(currentObj, `${item}.permanent.total`, 0));
      const otherThanPermanent = Number(_get(currentObj, `${item}.other_than_permanent.total`, 0));
      _set(data, `${item}.total.total`, permanent + otherThanPermanent);
      _set(data, `${item}.total.male`, totalMale);
      _set(data, `${item}.total.female`, totalFemale);
      _set(data, `${item}.total.other`, totalOther);
      _set(data, `${item}.total.malepercentage`, _calculatePercentage(totalMale, permanent + otherThanPermanent));
      _set(data, `${item}.total.femalepercentage`, _calculatePercentage(totalFemale, permanent + otherThanPermanent));
      _set(data, `${item}.total.otherpercentage`, _calculatePercentage(totalOther, permanent + otherThanPermanent));
    });
  };

  const _calculatePercentage = (a, b) => {
    if (!a || !b || +a == 0 || +b == 0) return "0%";
    return `${((+a / +b) * 100).toFixed(1)}%`;
  };

  const onHandleChange = (e) => {
    const keys = e.target.name.split(".");
    const lastKey = keys.pop();
    const currentObj = keys.reduce((data, key) => data[key] = data[key] || {}, data);
    currentObj[lastKey] = e.target.value;
    keys.pop();
    empCounting(keys, currentObj);
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, workforce_data: data });
    setData({ ...data });
  };

  const womenHandlechange = (e) => {
    _set(data, e.target.name, e.target.value);
    const keys = e.target.name.split(".");
    keys.pop();
    _set(data, [...keys, "percentage"].join("."),
      _calculatePercentage(_get(data, [...keys, "female"].join("."), 0), _get(data, [...keys, "total"].join("."), 0)));
    draftSebiData({ ...draftReport, workforce_data: { ...data } });
    setData({ ...data });
  };

  const turnOverHandlechange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, workforce_data: { ...data } });
    setData({ ...data });
  };

  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionA",
        "subSection": "workforce_data",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionA",
          "subSection": "workforce_data"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  return (
    <React.Fragment>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <div className="row" ref={elRef}>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="d-sm-flex align-items-center justify-content-between"
                    style={{ borderBottom: "1px solid #ccc", paddingBottom: "15px", marginBottom: "30px" }}>
                    <h4 className="mb-sm-0 font-size-18">Employees</h4> </div>
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  _set(sebiCurrentData, "sectionA.workforce_data", data);
                  updateSebiData(sebiCurrentData);
                  draftSebiData({ ...draftReport, workforce_data: false });
                }}>
                <p><b>18. Details as at the end of Financial Year:</b></p>
                <p>A. Employees and workers (including differently abled):</p>

                <div className="table-responsive">
                  <table className="table table-bordered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th rowSpan="2" width="4%">Sr. No.</th>
                        <th rowSpan="2" width="17%">Particulars</th>
                        <th rowSpan="2" className="text-center">Total (A)</th>
                        <th colSpan="2" className="text-center">Male (%)</th>
                        <th colSpan="2" className="text-center">Female (%)</th>
                        <th colSpan="2" className="text-center">Other (%)</th>
                      </tr>
                      <tr>
                        <th className="text-center">No.(B)</th>
                        <th width="8%" className="text-center">% (B/A)</th>
                        <th className="text-center">No.(C)</th>
                        <th width="8%" className="text-center">% (C/A)</th>
                        <th className="text-center">No.(D)</th>
                        <th width="8%" className="text-center">% (D/A)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th colSpan="9" className="text-center text-underline">EMPLOYEES </th>
                      </tr>
                      <tr>
                        <td className="text-align">1.</td>
                        <td className="text-align-left">Permanent (D)</td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="employees.permanent.total"
                          value={_get(data, "employees.permanent.total", "")}
                          onChange={onHandleChange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="employees.permanent.male"
                          value={_get(data, "employees.permanent.male", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "employees.permanent.malepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="employees.permanent.female"
                          value={_get(data, "employees.permanent.female", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "employees.permanent.femalepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="employees.permanent.other"
                          value={_get(data, "employees.permanent.other", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "employees.permanent.otherpercentage", "")}</td>
                      </tr>
                      <tr>
                        <td className="text-align">2.</td>
                        <td className="text-align-left">Other than Permanent (E)</td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="employees.other_than_permanent.total"
                          value={_get(data, "employees.other_than_permanent.total", "")}
                          onChange={onHandleChange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="employees.other_than_permanent.male"
                          value={_get(data, "employees.other_than_permanent.male", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "employees.other_than_permanent.malepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="employees.other_than_permanent.female"
                          value={_get(data, "employees.other_than_permanent.female", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "employees.other_than_permanent.femalepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="employees.other_than_permanent.other"
                          value={_get(data, "employees.other_than_permanent.other", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "employees.other_than_permanent.otherpercentage", "")}</td>
                      </tr>
                      <tr>
                        <td className="text-align">3.</td>
                        <td className="text-align-left"><b>Total employees (D + E)</b></td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="employees.total.total"
                          value={_get(data, "employees.total.total", "")}
                          onChange={onHandleChange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total Male"
                          name="employees.total.male"
                          value={_get(data, "employees.total.male", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "employees.total.malepercentage", "")}</td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total Female"
                          name="employees.total.female"
                          value={_get(data, "employees.total.female", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "employees.total.femalepercentage", "")}</td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total Other"
                          name="employees.total.other"
                          value={_get(data, "employees.total.other", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "employees.total.otherpercentage", "")}</td>
                      </tr>
                      <tr>
                        <th colSpan="9" className="text-center text-underline">WORKERS</th>
                      </tr>
                      <tr>
                        <td className="text-align">4.</td>
                        <td className="text-align-left">Permanent (F)</td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="workers.permanent.total"
                          value={_get(data, "workers.permanent.total", "")}
                          onChange={onHandleChange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="workers.permanent.male"
                          value={_get(data, "workers.permanent.male", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "workers.permanent.malepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="workers.permanent.female"
                          value={_get(data, "workers.permanent.female", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "workers.permanent.femalepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="workers.permanent.other"
                          value={_get(data, "workers.permanent.other", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "workers.permanent.otherpercentage", "")}</td>
                      </tr>
                      <tr>
                        <td className="text-align">5.</td>
                        <td className="text-align-left">Other than Permanent (G) </td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="workers.other_than_permanent.total"
                          value={_get(data, "workers.other_than_permanent.total", "")}
                          onChange={onHandleChange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="workers.other_than_permanent.male"
                          value={_get(data, "workers.other_than_permanent.male", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "workers.other_than_permanent.malepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="workers.other_than_permanent.female"
                          value={_get(data, "workers.other_than_permanent.female", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "workers.other_than_permanent.femalepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="workers.other_than_permanent.other"
                          value={_get(data, "workers.other_than_permanent.other", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "workers.other_than_permanent.otherpercentage", "")}</td>
                      </tr>
                      <tr>
                        <td className="text-align">6.</td>
                        <td className="text-align-left"><b>Total workers (F + G)</b> </td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="workers.total.total"
                          value={_get(data, "workers.total.total", "")}
                          onChange={onHandleChange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total Male"
                          name="workers.total.male"
                          value={_get(data, "workers.total.male", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "workers.total.malepercentage", "")}</td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total Female"
                          name="workers.total.female"
                          value={_get(data, "workers.total.female", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "workers.total.femalepercentage", "")}</td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total Other"
                          name="workers.total.other"
                          value={_get(data, "workers.total.other", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "workers.total.otherpercentage", "")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <Row className="mt-2 align-items-center">
                  {filesData?.employees_and_workers?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && res.statusCode === 200 && res.data[0]) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "employees_and_workers" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}
                  {filesData?.employees_and_workers?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "employees_and_workers" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "employees_and_workers" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mb-3 mt-2">B. Differently abled Employees and workers: </p>
                <div className="table-responsive">
                  <table className="table table-bordered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th rowSpan="2" width="4%">Sr. No.</th>
                        <th rowSpan="2" width="17%">Particulars</th>
                        <th rowSpan="2" className="text-center">Total (A)</th>
                        <th colSpan="2" className="text-center">Male (%)</th>
                        <th colSpan="2" className="text-center">Female (%)</th>
                        <th colSpan="2" className="text-center">Other (%)</th>
                      </tr>
                      <tr>
                        <th className="text-center">No.(B)</th>
                        <th width="8%" className="text-center">% (B/A)</th>
                        <th className="text-center">No.(C)</th>
                        <th width="8%" className="text-center">% (C/A)</th>
                        <th className="text-center">No.(D)</th>
                        <th width="8%" className="text-center">% (D/A)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th colSpan="9" className="text-center text-underline">DIFFERENTLY ABLED EMPLOYEES </th>
                      </tr>
                      <tr>
                        <td className="text-align">1.</td>
                        <td className="text-align-left">Permanent (D)</td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="differently_abled_employees.permanent.total"
                          value={_get(data, "differently_abled_employees.permanent.total", "")}
                          onChange={onHandleChange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="differently_abled_employees.permanent.male"
                          value={_get(data, "differently_abled_employees.permanent.male", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_employees.permanent.malepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="differently_abled_employees.permanent.female"
                          value={_get(data, "differently_abled_employees.permanent.female", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_employees.permanent.femalepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="differently_abled_employees.permanent.other"
                          value={_get(data, "differently_abled_employees.permanent.other", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_employees.permanent.otherpercentage", "")}</td>
                      </tr>
                      <tr>
                        <td className="text-align">2.</td>
                        <td className="text-align-left">Other than Permanent (E) </td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="differently_abled_employees.other_than_permanent.total"
                          value={_get(data, "differently_abled_employees.other_than_permanent.total", "")}
                          onChange={onHandleChange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="differently_abled_employees.other_than_permanent.male"
                          value={_get(data, "differently_abled_employees.other_than_permanent.male", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_employees.other_than_permanent.malepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="differently_abled_employees.other_than_permanent.female"
                          value={_get(data, "differently_abled_employees.other_than_permanent.female", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_employees.other_than_permanent.femalepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="differently_abled_employees.other_than_permanent.other"
                          value={_get(data, "differently_abled_employees.other_than_permanent.other", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_employees.other_than_permanent.otherpercentage", "")}</td>
                      </tr>
                      <tr>
                        <td className="text-align">3.</td>
                        <td className="text-align-left"><b>Total differently abled employees (D + E) </b></td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="differently_abled_employees.total.total"
                          value={_get(data, "differently_abled_employees.total.total", "")}
                          onChange={onHandleChange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total Male"
                          name="differently_abled_employees.total.male"
                          value={_get(data, "differently_abled_employees.total.male", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_employees.total.malepercentage", "")}</td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total Female"
                          name="differently_abled_employees.total.female"
                          value={_get(data, "differently_abled_employees.total.female", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_employees.total.femalepercentage", "")}</td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total Other"
                          name="differently_abled_employees.total.other"
                          value={_get(data, "differently_abled_employees.total.other", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_employees.total.otherpercentage", "")}</td>
                      </tr>
                      <tr>
                        <th colSpan="9" className="text-center text-underline">DIFFERENTLY ABLED WORKERS </th>
                      </tr>
                      <tr>
                        <td className="text-align">4.</td>
                        <td className="text-align-left">Permanent (F)</td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="differently_abled_workers.permanent.total"
                          value={_get(data, "differently_abled_workers.permanent.total", "")}
                          onChange={onHandleChange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="differently_abled_workers.permanent.male"
                          value={_get(data, "differently_abled_workers.permanent.male", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_workers.permanent.malepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="differently_abled_workers.permanent.female"
                          value={_get(data, "differently_abled_workers.permanent.female", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_workers.permanent.femalepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="differently_abled_workers.permanent.other"
                          value={_get(data, "differently_abled_workers.permanent.other", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_workers.permanent.otherpercentage", "")}</td>
                      </tr>
                      <tr>
                        <td className="text-align">5.</td>
                        <td className="text-align-left">Other than permanent (G) </td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="differently_abled_workers.other_than_permanent.total"
                          value={_get(data, "differently_abled_workers.other_than_permanent.total", "")}
                          onChange={onHandleChange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="differently_abled_workers.other_than_permanent.male"
                          value={_get(data, "differently_abled_workers.other_than_permanent.male", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_workers.other_than_permanent.malepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="differently_abled_workers.other_than_permanent.female"
                          value={_get(data, "differently_abled_workers.other_than_permanent.female", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_workers.other_than_permanent.femalepercentage", "")}</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="differently_abled_workers.other_than_permanent.other"
                          value={_get(data, "differently_abled_workers.other_than_permanent.other", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_workers.other_than_permanent.otherpercentage", "")}</td>
                      </tr>
                      <tr>
                        <td className="text-align">6.</td>
                        <td className="text-align-left"><b>Total differently abled workers (F + G) </b></td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total"
                          name="differently_abled_workers.total.total"
                          value={_get(data, "differently_abled_workers.total.total", "")}
                          onChange={onHandleChange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total Male"
                          name="differently_abled_workers.total.male"
                          value={_get(data, "differently_abled_workers.total.male", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_workers.total.malepercentage", "")}</td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total Female"
                          name="differently_abled_workers.total.female"
                          value={_get(data, "differently_abled_workers.total.female", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_workers.total.femalepercentage", "")}</td>
                        <TableInput
                          isDisabled={true}
                          type="number"
                          placeholder="Total Other"
                          name="differently_abled_workers.total.other"
                          value={_get(data, "differently_abled_workers.total.other", "")}
                          onChange={onHandleChange}
                        />
                        <td>{_get(data, "differently_abled_workers.total.otherpercentage", "")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <Row className="mt-2 align-items-center">
                  {filesData?.differently_abled?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res.statusCode === 200 && res.data[0]) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        } else {
                                          toast.error(res.message);
                                          return;
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "differently_abled" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}

                  {filesData?.differently_abled?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "differently_abled" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "differently_abled" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mb-3 mt-2"><b>19. Participation/Inclusion/Representation of women</b> </p>
                <div className="table-responsive">
                  <table className="table table-bordered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th rowSpan="2" width="21%"></th>
                        <th rowSpan="2" className="text-center">Total (A)</th>
                        <th colSpan="2" className="text-center">No. and percentage of Females </th>
                      </tr>
                      <tr>
                        <th className="text-center">No. (B)</th>
                        <th className="text-center">% (B / A)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-align-left">Board of Directors</td>
                        <TableInput
                          type="number"
                          placeholder="Total"
                          name="board_of_directors.total"
                          value={_get(data, "board_of_directors.total", "")}
                          onChange={womenHandlechange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="board_of_directors.female"
                          value={_get(data, "board_of_directors.female", "")}
                          onChange={womenHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          name="board_of_directors.percentage"
                          value={_get(data, "board_of_directors.percentage", "")}
                          onChange={womenHandlechange}
                        />
                      </tr>
                      <tr>
                        <td className="text-align-left">Key Management Personnel </td>
                        <TableInput
                          type="number"
                          placeholder="Total"
                          name="key_management_personnel.total"
                          value={_get(data, "key_management_personnel.total", "")}
                          onChange={womenHandlechange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="key_management_personnel.female"
                          value={_get(data, "key_management_personnel.female", "")}
                          onChange={womenHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          name="key_management_personnel.percentage"
                          value={_get(data, "key_management_personnel.percentage", "")}
                          onChange={womenHandlechange}
                        />
                      </tr>
                    </tbody>
                  </table>
                </div>

                <Row className="mt-2 align-items-center">
                  {filesData?.participation_inclusion?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res.statusCode === 200 && res.data[0]) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        } else {
                                          toast.error(res.message);
                                          return;
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "participation_inclusion" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);
                  })}

                  {filesData?.participation_inclusion?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "participation_inclusion" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "participation_inclusion" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mb-3 mt-2">20. Turnover rate for permanent employees and workers(Disclose trends for the past 3 years)</p>
                <div className="table-responsive">
                  <table className="table table-bordered mb-0">
                    <thead className="table-light">
                      <tr>
                        <th rowSpan="2" width="10%"></th>
                        <th colSpan="4" className="text-center">FY <i>(Turnover rate in current FY)</i> </th>
                        <th colSpan="4" className="text-center">FY <i>(Turnover rate in previous FY)</i> </th>
                        <th colSpan="4" className="text-center">FY <i>(Turnover rate in the year prior to theprevious FY)</i> </th>
                      </tr>
                      <tr>
                        <th className="text-center">Male (%)</th>
                        <th className="text-center">Female (%)</th>
                        <th className="text-center">Other (%)</th>
                        <th className="text-center">Total (%)</th>
                        <th className="text-center">Male (%)</th>
                        <th className="text-center">Female (%)</th>
                        <th className="text-center">Other (%)</th>
                        <th className="text-center">Total (%)</th>
                        <th className="text-center">Male (%)</th>
                        <th className="text-center">Female (%)</th>
                        <th className="text-center">Other (%)</th>
                        <th className="text-center">Total (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-align-left">Permanent Employees</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_employees.male"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_employees.male", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_employees.female"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_employees.female", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_employees.other"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_employees.other", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Total"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_employees.total"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_employees.total", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_employees.previous.male"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_employees.previous.male", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_employees.previous.female"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_employees.previous.female", "")}
                          onChange={turnOverHandlechange}
                        />

                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_employees.previous.other"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_employees.previous.other", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_employees.previous.total"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_employees.previous.total", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_employees.prior.male"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_employees.prior.male", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_employees.prior.female"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_employees.prior.female", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_employees.prior.other"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_employees.prior.other", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_employees.prior.total"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_employees.prior.total", "")}
                          onChange={turnOverHandlechange}
                        />
                      </tr>
                      <tr>
                        <td className="text-align-left">Permanent Workers</td>
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_workers.male"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_workers.male", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_workers.female"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_workers.female", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Enter Number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_workers.other"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_workers.other", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          type="number"
                          placeholder="Total"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_workers.total"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_workers.total", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_workers.previous.male"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_workers.previous.male", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_workers.previous.female"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_workers.previous.female", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_workers.previous.other"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_workers.previous.other", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_workers.previous.total"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_workers.previous.total", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_workers.prior.male"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_workers.prior.male", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_workers.prior.female"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_workers.prior.female", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_workers.prior.other"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_workers.prior.other", "")}
                          onChange={turnOverHandlechange}
                        />
                        <TableInput
                          isDisabled={true}
                          type="number"
                          name="turnover_rate_for_permanent_employees_and_workers.permanent_workers.prior.total"
                          value={_get(data, "turnover_rate_for_permanent_employees_and_workers.permanent_workers.prior.total", "")}
                          onChange={turnOverHandlechange}
                        />
                      </tr>
                    </tbody>
                  </table>
                </div>

                <Row className="mt-2 align-items-center">
                  {filesData?.turnover_rate?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res.statusCode === 200 && res.data[0]) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        } else {
                                          toast.error(res.message);
                                          return;
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "turnover_rate" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);
                  })}

                  {filesData?.turnover_rate?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "turnover_rate" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "turnover_rate" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <button type="button" className="btn btn-primary w-md "
                      onClick={() => onSectionChange(3)}>Previous</button>{" "}
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                    <button type="button" className="btn btn-primary w-md "
                      onClick={() => onSectionChange(5)}>Next</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>;
    </React.Fragment>
  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionA?.workforce_data,
    previousData: state.NewReportReducer?.sebiForm?.previousYearData?.sectionA?.workforce_data,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.workforce_data,
    filesData: state.NewReportReducer?.draftFiles?.workforce_data
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(Employees);