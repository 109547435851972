import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Formik, FieldArray, Field } from "formik";
import { Card, Col, DropdownToggle, DropdownMenu, Modal, Row, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import ModalSection from "../ModalSection";
import Input from "components/Inputs/Input";
import FormUpload from "../Modalfileupload";
import TableInput from "components/Inputs/TableInput";
import RadioButton from "components/Inputs/RadioButton";
import { AxiosGet, AxiosPost } from "common/axiosService";
import TableTextArea from "components/Inputs/TableTextArea";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const TDC = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionA.t_and_d;
  const { draftSebiData, draftData, updateSebiData, newReport, draftReport, onSectionChange, oldData, previousData, prefillData, onSectionToggle, filesData, getFile } = props;

  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);

  const [filedatachange, setFileDataChange] = useState(true);

  const [modal_center, setmodal_center] = useState(false);
  const [modalData, setModalData] = useState({ name: "", value: "", type: "", title: "" });
  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionA", subSection: "t_and_d", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect (() => {
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionA",
        "subSection": "t_and_d"
      });
    }
  }, [newReport, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionA",
      "subSection": "t_and_d"
    });
  }, [filedatachange]);

  useEffect (() => {
    if (previousData || prefillData) {
      ["communities", "customers", "employees_and_workers", "investors", "others", "shareholders", "value_chain_partners"].forEach(item => {
        ["number_of_complaints_filed_during_the_year", "number_of_complaints_pending_resolution_at_close_of_the_year", "remarks"].forEach(subItem => {
          if (prefillData?.stakeholder_complaints) {
            _set(data, `stakeholder_complaints.${item}.${subItem}`, _get(prefillData, `stakeholder_complaints.${item}.${subItem}`, ""));
          }
          if (previousData?.stakeholder_complaints) {
            _set(data, `stakeholder_complaints.${item}.previous.${subItem}`, _get(previousData, `stakeholder_complaints.${item}.${subItem}`, ""));
          }
        });
      });
    }
  }, [previousData, prefillData]);

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, t_and_d: { ...data } });
    setData({ ...data });
  };

  function tog_center () {
    setmodal_center(!modal_center);
  }

  const oninputFocus = (e, title, name1) => {
    if(!modal_center){
      e.target.blur();
    }
    setModalData({
      title: title,
      name: name1,
      value: _get(data, name1, "")
    });
    tog_center();
  };

  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionA",
        "subSection": "t_and_d",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionA",
          "subSection": "t_and_d"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };
  return(
    <React.Fragment>
      <Modal className="center-modal"
        size="lg" isOpen={modal_center}
        toggle={() => { tog_center(); }}
        centered
        autoFocus={false}
        backdrop={"static"}
      >
        <ModalSection modalData={modalData} setModalData={setModalData} state={data} setState={setData} setmodal_center={setmodal_center}/>
        <div className="modal-footer">
          <button
            onClick={(e) => {
              setmodal_center(false);
              e.target.blur();
              setModalData({ name: "", value: "", type: "", title: "" });
            }}
            className="btn btn-primary"
          >Update</button>
        </div>
      </Modal>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <Row>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className=" d-sm-flex align-items-center justify-content-between"
                    style={{ borderBottom: "1px solid #ccc", paddingBottom: "15px", marginBottom: "30px" }}>
                    <h4 className="mb-sm-0 font-size-18">Transparency and Disclosures Compliances
                    </h4> </div>
                </div>
              </div>
              <Formik
                initialValues={data}
              >{({ values }) => (
                  <form
                    onChange={onHandleChange}
                    onSubmit={(e) => {
                      e.preventDefault();
                      _set(sebiCurrentData, "sectionA.t_and_d", data);
                      updateSebiData(sebiCurrentData);
                      draftSebiData({ ...draftReport, t_and_d: false });
                    }}>
                    <p>
                      <b>23. Complaints/Grievances on any of the principles (Principles 1 to 9) under the National Guidelines on Responsible Business Conduct:</b>
                    </p>
                    <table className="table table-bordered mb-0">
                      <thead className="table-light">
                        <tr>
                          <th rowSpan="2">Stakeholder group from whom complaint is received </th>
                          <th rowSpan="2">Grievance Redressal Mechanism in Place (Yes/No)
                            <i>(If Yes, then provide web-link for grievance redress policy)</i> </th>
                          <th colSpan="3" className="text-center">FY Current Financial Year </th>
                          <th colSpan="3" className="text-center">FY Previous Financial Year </th>
                        </tr>
                        <tr>
                          <th>Number of complaints filed during the year</th>
                          <th>Number of complaints pending resolution at close of the year </th>
                          <th className="text-align">Remarks</th>
                          <th>Number of complaints filed during the year</th>
                          <th>Number of complaints pending resolution at close of the year </th>
                          <th className="text-align">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-align-left">Communities</td>
                          <td className="text-align">
                            <RadioButton
                              name="stakeholder_complaints.communities.grivance_redressal"
                              value={_get(data, "stakeholder_complaints.communities.grivance_redressal", "")}
                              onChange={onHandleChange}
                              id="radio_85button" className="dis-center"/>

                            <Input
                              name="stakeholder_complaints.communities.weblink"
                              value={_get(data, "stakeholder_complaints.communities.weblink", "")}
                              onChange={onHandleChange}
                            />
                          </td>

                          <TableInput
                            name="stakeholder_complaints.communities.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.communities.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            name="stakeholder_complaints.communities.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.communities.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.communities.remarks"
                            value={_get(data, "stakeholder_complaints.communities.remarks", "")}
                            onChange={onHandleChange}
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.communities.previous.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.communities.previous.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.communities.previous.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.communities.previous.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            isDisabled={true}
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.communities.previous.remarks"
                            value={_get(data, "stakeholder_complaints.communities.previous.remarks", "")}
                            onChange={onHandleChange}
                          />
                        </tr>
                        <tr>
                          <td className="text-align-left">Investors (other than shareholders)</td>
                          <td className="text-align">
                            <RadioButton
                              name="stakeholder_complaints.investors.grivance_redressal"
                              value={_get(data, "stakeholder_complaints.investors.grivance_redressal", "")}
                              onChange={onHandleChange}
                              id="radio_86button" className="dis-center"/>

                            <Input
                              name="stakeholder_complaints.investors.weblink"
                              value={_get(data, "stakeholder_complaints.investors.weblink", "")}
                              onChange={onHandleChange}
                            />
                          </td>

                          <TableInput
                            name="stakeholder_complaints.investors.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.investors.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            name="stakeholder_complaints.investors.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.investors.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.investors.remarks"
                            value={_get(data, "stakeholder_complaints.investors.remarks", "")}
                            onChange={onHandleChange}
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.investors.previous.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.investors.previous.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.investors.previous.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.investors.previous.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            isDisabled={true}
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.investors.previous.remarks"
                            value={_get(data, "stakeholder_complaints.investors.previous.remarks", "")}
                            onChange={onHandleChange}
                          />
                        </tr>
                        <tr>
                          <td className="text-align-left">Shareholders</td>
                          <td className="text-align">
                            <RadioButton
                              name="stakeholder_complaints.shareholders.grivance_redressal"
                              value={_get(data, "stakeholder_complaints.shareholders.grivance_redressal", "")}
                              onChange={onHandleChange}
                              id="radio_87button" className="dis-center"/>

                            <Input
                              name="stakeholder_complaints.shareholders.weblink"
                              value={_get(data, "stakeholder_complaints.shareholders.weblink", "")}
                              onChange={onHandleChange}
                            />
                          </td>

                          <TableInput
                            name="stakeholder_complaints.shareholders.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.shareholders.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            name="stakeholder_complaints.shareholders.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.shareholders.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.shareholders.remarks"
                            value={_get(data, "stakeholder_complaints.shareholders.remarks", "")}
                            onChange={onHandleChange}
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.shareholders.previous.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.shareholders.previous.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.shareholders.previous.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.shareholders.previous.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            isDisabled={true}
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.shareholders.previous.remarks"
                            value={_get(data, "stakeholder_complaints.shareholders.previous.remarks", "")}
                            onChange={onHandleChange}
                          />
                        </tr>
                        <tr>
                          <td className="text-align-left">Employees and workers</td>
                          <td className="text-align">
                            <RadioButton
                              name="stakeholder_complaints.employees_and_workers.grivance_redressal"
                              value={_get(data, "stakeholder_complaints.employees_and_workers.grivance_redressal", "")}
                              onChange={onHandleChange}
                              id="radio_88button" className="dis-center"/>

                            <Input
                              name="stakeholder_complaints.employees_and_workers.weblink"
                              value={_get(data, "stakeholder_complaints.employees_and_workers.weblink", "")}
                              onChange={onHandleChange}
                            />
                          </td>

                          <TableInput
                            name="stakeholder_complaints.employees_and_workers.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.employees_and_workers.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            name="stakeholder_complaints.employees_and_workers.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.employees_and_workers.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.employees_and_workers.remarks"
                            value={_get(data, "stakeholder_complaints.employees_and_workers.remarks", "")}
                            onChange={onHandleChange}
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.employees_and_workers.previous.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.employees_and_workers.previous.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.employees_and_workers.previous.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.employees_and_workers.previous.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            isDisabled={true}
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.employees_and_workers.previous.remarks"
                            value={_get(data, "stakeholder_complaints.employees_and_workers.previous.remarks", "")}
                            onChange={onHandleChange}
                          />
                        </tr>
                        <tr>
                          <td className="text-align-left">Customers</td>
                          <td className="text-align">
                            <RadioButton
                              name="stakeholder_complaints.customers.grivance_redressal"
                              value={_get(data, "stakeholder_complaints.customers.grivance_redressal", "")}
                              onChange={onHandleChange}
                              id="radio_89button" className="dis-center"/>

                            <Input
                              name="stakeholder_complaints.customers.weblink"
                              value={_get(data, "stakeholder_complaints.customers.weblink", "")}
                              onChange={onHandleChange}
                            />
                          </td>

                          <TableInput
                            name="stakeholder_complaints.customers.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.customers.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            name="stakeholder_complaints.customers.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.customers.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.customers.remarks"
                            value={_get(data, "stakeholder_complaints.customers.remarks", "")}
                            onChange={onHandleChange}
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.customers.previous.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.customers.previous.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.customers.previous.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.customers.previous.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            isDisabled={true}
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.customers.previous.remarks"
                            value={_get(data, "stakeholder_complaints.customers.previous.remarks", "")}
                            onChange={onHandleChange}
                          />
                        </tr>
                        <tr>
                          <td className="text-align-left">Value Chain Partners</td>
                          <td className="text-align">
                            <RadioButton
                              name="stakeholder_complaints.value_chain_partners.grivance_redressal"
                              value={_get(data, "stakeholder_complaints.value_chain_partners.grivance_redressal", "")}
                              onChange={onHandleChange}
                              id="radio_90button" className="dis-center"/>

                            <Input
                              name="stakeholder_complaints.value_chain_partners.weblink"
                              value={_get(data, "stakeholder_complaints.value_chain_partners.weblink", "")}
                              onChange={onHandleChange}
                            />
                          </td>

                          <TableInput
                            name="stakeholder_complaints.value_chain_partners.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.value_chain_partners.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            name="stakeholder_complaints.value_chain_partners.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.value_chain_partners.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.value_chain_partners.remarks"
                            value={_get(data, "stakeholder_complaints.value_chain_partners.remarks", "")}
                            onChange={onHandleChange}
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.value_chain_partners.previous.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.value_chain_partners.previous.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.value_chain_partners.previous.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.value_chain_partners.previous.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            isDisabled={true}
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.value_chain_partners.previous.remarks"
                            value={_get(data, "stakeholder_complaints.value_chain_partners.previous.remarks", "")}
                            onChange={onHandleChange}
                          />
                        </tr>
                        <tr>
                          <td className="text-align-left">Other (please specify)</td>
                          <td className="text-align">
                            <RadioButton
                              name="stakeholder_complaints.others.grivance_redressal"
                              value={_get(data, "stakeholder_complaints.others.grivance_redressal", "")}
                              onChange={onHandleChange}
                              id="radio_91button" className="dis-center"/>

                            <Input
                              name="stakeholder_complaints.others.weblink"
                              value={_get(data, "stakeholder_complaints.others.weblink", "")}
                              onChange={onHandleChange}
                            />
                          </td>

                          <TableInput
                            name="stakeholder_complaints.others.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.others.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            name="stakeholder_complaints.others.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.others.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.others.remarks"
                            value={_get(data, "stakeholder_complaints.others.remarks", "")}
                            onChange={onHandleChange}
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.others.previous.number_of_complaints_filed_during_the_year"
                            value={_get(data, "stakeholder_complaints.others.previous.number_of_complaints_filed_during_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableInput
                            isDisabled={true}
                            name="stakeholder_complaints.others.previous.number_of_complaints_pending_resolution_at_close_of_the_year"
                            value={_get(data, "stakeholder_complaints.others.previous.number_of_complaints_pending_resolution_at_close_of_the_year", "")}
                            onChange={onHandleChange}
                            className="text-align"
                            type="number"
                          />

                          <TableTextArea
                            isDisabled={true}
                            onFocus={oninputFocus}
                            title={"Remarks"}
                            name="stakeholder_complaints.others.previous.remarks"
                            value={_get(data, "stakeholder_complaints.others.previous.remarks", "")}
                            onChange={onHandleChange}
                          />
                        </tr>
                      </tbody>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.complaints_grievances?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && res.statusCode === 200 && res.data[0]) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong", { autoClose: 2000 });
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "complaints_grievances" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);

                      })}
                      {filesData?.complaints_grievances?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "complaints_grievances" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "complaints_grievances" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <p className="mt-2"><b>24. Overview of the entity’s material responsible business conduct issues</b> </p>
                    <p>Please indicate material responsible business conduct and sustainability issues pertaining to environmental
                    and social matters that present a risk or an opportunity to your business, rationale for identifying the same,
                    approach to adapt or mitigate the risk along-with its financial implications, as per the following format </p>
                    <table className="table table-bordered mb-0">
                      <FieldArray name="business_conduct_issues">
                        {({ remove, push }) => (
                          <>
                            <thead className="table-light">
                              <tr>
                                <th width="4%" className="text-align">Sr. No. </th>
                                <th className="text-align">Material issue identified</th>
                                <th className="text-align">Indicate whether risk or opportunity (R/O)</th>
                                <th className="text-align">Rationale for identifying the risk / opportunity</th>
                                <th className="text-align">In case of risk, approach to adapt or mitigate</th>
                                <th width="22%" className="text-align">Financial implications of the risk or opportunity (Indicate positive or negative implications) </th>
                                <th width="3%" onClick={() => {
                                  push({ material_issue: "", risk_or_opportunity: "", rationale_for_identifying: "", adapt_or_mitigate: "", financial_implications: "" });
                                  setData({
                                    ...data, business_conduct_issues: [
                                      ...data.business_conduct_issues,
                                      { material_issue: "", risk_or_opportunity: "", rationale_for_identifying: "", adapt_or_mitigate: "", financial_implications: "" }
                                    ]
                                  });
                                  draftSebiData({
                                    ...draftReport, t_and_d: {
                                      ...data, business_conduct_issues: [
                                        ...data.business_conduct_issues,
                                        { material_issue: "", risk_or_opportunity: "", rationale_for_identifying: "", adapt_or_mitigate: "", financial_implications: "" }
                                      ]
                                    }
                                  });
                                }
                                }><i className="bx bxs-plus-circle mdi-add-btn"/></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.business_conduct_issues?.length > 0 && values.business_conduct_issues.map((friend, index) => (
                                <tr key={index}>
                                  <td className="text-align">{index+1}.</td>
                                  <td>
                                    <Field
                                      name={`business_conduct_issues[${index}].material_issue`}
                                      placeholder="Material issue"
                                      type="text"
                                      className="form-control"
                                      value={data.business_conduct_issues[index].material_issue}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Material issue identified",
                                          name: `business_conduct_issues[${index}].material_issue`,
                                          value: _get(data, `business_conduct_issues[${index}].material_issue`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name={`business_conduct_issues[${index}].risk_or_opportunity`}
                                      placeholder="risk or opportunity "
                                      type="text"
                                      className="form-control"
                                      value={data.business_conduct_issues[index].risk_or_opportunity}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Indicate whether risk or opportunity (R/O)",
                                          name: `business_conduct_issues[${index}].risk_or_opportunity`,
                                          value: _get(data, `business_conduct_issues[${index}].risk_or_opportunity`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name={`business_conduct_issues[${index}].rationale_for_identifying`}
                                      placeholder="Rationale for identifying"
                                      type="text"
                                      className="form-control"
                                      value={data.business_conduct_issues[index].rationale_for_identifying}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Rationale for identifying the risk / opportunity",
                                          name: `business_conduct_issues[${index}].rationale_for_identifying`,
                                          value: _get(data, `business_conduct_issues[${index}].rationale_for_identifying`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name={`business_conduct_issues[${index}].adapt_or_mitigate`}
                                      placeholder="Adapt or mitigate"
                                      type="text"
                                      className="form-control"
                                      value={data.business_conduct_issues[index].adapt_or_mitigate}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "In case of risk, approach to adapt or mitigate",
                                          name: `business_conduct_issues[${index}].adapt_or_mitigate`,
                                          value: _get(data, `business_conduct_issues[${index}].adapt_or_mitigate`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name={`business_conduct_issues[${index}].financial_implications`}
                                      placeholder="Financial implications "
                                      type="text"
                                      className="form-control"
                                      value={data.business_conduct_issues[index].financial_implications}
                                      onFocus={(e) => {
                                        if(!modal_center){ e.target.blur(); }
                                        setModalData({
                                          title: "Financial implications of the risk or opportunity (Indicate positive or negative implications)",
                                          name: `business_conduct_issues[${index}].financial_implications`,
                                          value: _get(data, `business_conduct_issues[${index}].financial_implications`, "")
                                        });
                                        tog_center();
                                      }}
                                    />
                                  </td>
                                  {values.business_conduct_issues?.length > 1 && <td onClick={() => {
                                    remove(index);
                                    const newr = data.business_conduct_issues.filter((item, i) => index !==i);
                                    draftSebiData({ ...draftReport, t_and_d: { ...data, business_conduct_issues: newr } });
                                    setData({ ...data, business_conduct_issues: newr });
                                  }}><i className="bx bxs-x-circle mdi-close-btn"/></td>}
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </FieldArray>
                    </table>

                    <Row className="mt-2 align-items-center">
                      {filesData?.overview_of_the_entity?.map((item, index) => {
                        let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                        let name = item.split("-")[0];
                        return (
                          <Col xl={2} sm={4} key={index}>
                            <Card className="shadow-none border">
                              <div >
                                <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                  <UncontrolledDropdown direction="left mt-2">
                                    <DropdownToggle
                                      color="white"
                                      className="btn btn-link text-muted mt-n2"
                                    >
                                      <i className="mdi mdi-dots-vertical"></i>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <button type="button" className="dropdown-item"
                                        onClick={ async () => {
                                          try {
                                            const userToken = localStorage.getItem("accessToken");
                                            const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                            if (res && res.statusCode === 200 && res.data[0]) {
                                              let a = document.createElement("a");
                                              a.download = `${name}.${ext}`;
                                              a.href = `data:application/${ext};base64,${res.data[0]}`;
                                              a.click();
                                              a.remove();
                                              toast.success("Download Succesfully");
                                            }
                                          } catch (error) {
                                            toast.error("Something Went Wrong");
                                          }
                                        }}
                                      >Download</button>
                                      <button type="button" className="dropdown-item"
                                        onClick={() => handleDeleteFile(item, "overview_of_the_entity" )}
                                      >Delete</button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                                <div className="avatar-xs me-3 mb-3">
                                  <div className="avatar-title bg-transparent rounded">
                                    {ext === "sheet"
                                      ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                      : ext === "png"
                                        ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                        : ext === "pdf"
                                          ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                          <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                    }
                                  </div>
                                </div>
                                <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                              </div>
                            </Card>
                          </Col>);
                      })}

                      {filesData?.overview_of_the_entity?.length > 0 && <Col className="add_doc" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "overview_of_the_entity" });
                      }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                    </Row>

                    <div style={{ maxWidth: "20%" }}>
                      <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                        tog_backdrop();
                        setFileData({ ...fileData, keyName: "overview_of_the_entity" });
                      }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                    </div>

                    <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() => {
                            onSectionChange(6);
                          }}>Previous</button>{" "}
                      </div>
                      <div>
                        <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                        <button type="button" className="btn btn-primary w-md "
                          onClick={() =>{
                            onSectionChange(8);
                            onSectionToggle("sectionB");
                          }}>Next</button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionA?.t_and_d,
    previousData: state.NewReportReducer?.sebiForm?.previousYearData?.sectionA?.t_and_d,
    prefillData: state.NewReportReducer?.sebiForm?.sebiPrefil,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.t_and_d,
    filesData: state.NewReportReducer?.draftFiles?.t_and_d
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(TDC);