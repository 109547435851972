import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_COMPANY, REGISTER_PROGRESS } from "./actionTypes";
import { registerCompanySuccessful, registerCompanyFailed } from "./actions";

import { postRegister } from "../../../helpers/backend_helper";

// Is Company register successfull then direct plot Company in redux.
function* registerCompany ({ payload: { company } }) {
  try {
    const response = yield call(postRegister, company);
    if (response.status === false) {
      yield put(registerCompanyFailed(response));
      localStorage.setItem("progress", 0);
      return response;
    }
    yield put(registerCompanySuccessful(response));
  } catch (error) {
    yield put(registerCompanyFailed(error));
  }
}

function* registerProgress ({ payload: num }) {
  try {
    localStorage.setItem("progress", num);
  } catch (error) {
    localStorage.setItem("progress", num);
    // yield put(registerCompanyFailed(error))
  }
}

export function* watchCompanyRegister () {
  yield takeEvery(REGISTER_COMPANY, registerCompany);
  yield takeEvery(REGISTER_PROGRESS, registerProgress);
}

function* accountSaga () {
  yield all([fork(watchCompanyRegister)]);
}

export default accountSaga;
