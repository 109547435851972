/* eslint-disable */
import React from "react";
import { Dots } from "react-activity";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { AxiosGet } from "common/axiosService";
import locationimg from "../../../assets/images/location-img.png";

function Loactions ({ setLoading, loading, toast }) {
  const [locationsList, setLocationsList] = useState([]);

  const apipath = process.env.REACT_APP_ADMIN_API_PATH;

  /*getting data on mounting fase*/
  useEffect(() => {
    (async () => {
      try {
        const session = JSON.parse(localStorage.getItem("authUser"));
        const companyId = session.userData.user.companyId
          ? session.userData.user.companyId._id
          : "";
        const response = await AxiosGet(`${apipath}/locations/${companyId}`);
        if (response && response.statusCode === 200) {
          setLocationsList(response.data.filter(opt => opt.isActive));
          setLoading((loading) => ({
            ...loading, location: true
          }));
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, []);

  return(
    <React.Fragment>
      <Col md={12} xl={5} className="mt-4 mb-4">
        <Card className="fixheight">
          <CardBody className="location-connect">
            <div className="title">
              <h5>Locations</h5>
            </div>
            <Row>
              <Col md={12} xl={6} className="location-box laptopmedialocation">
                <img src={locationimg}/>
              </Col>
              <Col md={12} xl={6} className="location-list">
                <i className="dripicons-location"></i>
                <p style={{color: "#3d7d42"}}>{locationsList.length}</p>

                {loading.location
                  ? locationsList.length > 0
                    ? <div className="location-name">
                      {locationsList.map((item, index) => {
                        if (index > 5) {
                          return;
                        }
                        return (
                          <React.Fragment key={`location${index}`}>
                            <span>{item.city}</span>
                          </React.Fragment>
                        );
                      })}
                      {locationsList?.length > 5 ? <Link to="/admin/locations">See More...</Link> : null }

                    </div> :
                    <h3 style={{ textAlign: "center" }}><b>Please Add Location</b></h3>
                  :
                  <div className="react-activity">
                    <Dots color="#3C7C44" size={20} speed={0.6} />
                  </div>
                }
              </Col>
            </Row>
            <div className="user-btn">
                <Link to="/admin/locations" className="add-location-btn">
                  <button type="button"
                    className="btn btn-primary waves-effect waves-light text-right">Add Location
                  </button>
                </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}

export default Loactions;
