import React, { useEffect, useState, useMemo } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorMessage, Field, FieldArray, Formik, useFormik } from "formik";
import { Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Form, Button } from "reactstrap";
import { AxiosGet, AxiosPost } from "common/axiosService";

import Loading from "components/Common/Loading";
import Breadcrumbs from "components/Common/Breadcrumb";
import IsRoleAuth from "pages/Authentication/IsRoleAuth";
import { UserName, Email, Role, Status } from "./UsersCol";
import DeleteModal from "../../../components/Common/DeleteModal";
import TableContainer from "../../../components/Common/TableContainer";

import "react-toastify/dist/ReactToastify.css";

const UserList = () => {
  //meta title
  document.title = "Users | Greenalytics";
  const apipath = process.env.REACT_APP_SUPERADMIN_API_PATH;

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showloader, setShowLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [superAdminuser, setSuperAdminuser] = useState(null);
  const [isItemsChanged, setIsItemsChanged] = useState(false);
  const [superAdminuserList, setSuperAdminuserList] = useState([]);
  const [data, setData] = useState({ users: [{ email: "", name: "", role: "" }] });

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: (superAdminuser && superAdminuser.username) || "",
      phone: (superAdminuser && superAdminuser.phone) || "",
      email: (superAdminuser && superAdminuser.email) || "",
      role: (superAdminuser && superAdminuser.role) || ""
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Name"),
      phone: Yup.string().required("Please Enter Your Phone"),
      email: Yup.string().required("Please Enter Your Email"),
      role: Yup.string().required("Please Enter Your User Role")
    }),
    onSubmit: () => {
      validation.resetForm();
      toggle();
    }
  });

  const stepFourValidationSchema = Yup.object({
    users: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .email("Please Enter Valid Email")
          .required("Please Enter User Email"),
        name: Yup.string()
          .required("Please Enter Name")
          .min(3, "Minimum 3 characters required"),
        role: Yup.string().required("Please Enter User Role")
      })
    )
  });

  const handleAdminuserClick = arg => {
    const superAdminuser = arg;
    setSuperAdminuser(arg);
    setData({
      users: [
        {
          email: superAdminuser.email,
          name: superAdminuser.name,
          role: superAdminuser.role
        }]
    });
    setIsEdit(true);
    toggle();
  };

  // Customber Column
  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "name",
        filterable: true,
        Cell: cellProps => <UserName {...cellProps} />
      },
      {
        Header: "Email",
        accessor: "email",

        Cell: cellProps => <Email {...cellProps} />
      },
      {
        Header: "Role",
        accessor: "role",
        filterable: true,
        Cell: cellProps => <Role {...cellProps} />
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => <Status {...cellProps} />
      },
      {
        Header: "Action",
        Cell: cellProps => (
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="d-flex gap-3"
          >
            <Link
              to="#"
              className="text-success"
              onClick={() => {
                const adminuserData = cellProps.row.original;
                handleAdminuserClick(adminuserData);
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            {cellProps.row.original.status !== "Invited" && (
              <div className="form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitch2"
                  checked={
                    cellProps.row.original.status === "Verified"? true: false
                  }
                  onClick={e => {
                    editGeneral(e.target, cellProps.row.original);
                  }}
                  onChange={e => {
                    editGeneral(e.target, cellProps.row.original);
                  }}
                />
              </div>
            )}

            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const adminuserData = cellProps.row.original;
                onClickDelete(adminuserData);
              }}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Suspend
              </UncontrolledTooltip>
            </Link>
          </div>
        )
      }
    ],
    []
  );

  const toggle = () => { setModal(!modal); };

  //Get all SuperAdminusers list
  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/users`);
        if (response && response.statusCode === 200) {
          setSuperAdminuserList(response.data);
          setShowLoader(true);
        } else {
          setShowLoader(true);
        }
      } catch (error) {
        setShowLoader(true);
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, [isItemsChanged]);

  const handleAdminuserClicks = () => {
    setData({ users: [{ email: "", name: "", role: "" }] });
    setIsEdit(false);
    toggle();
  };

  // Add new user
  const handleSubmit =async values => {
    try {
      const response = await AxiosPost(`${apipath}/add-user`, values);
      if (response && response.statusCode === 200) {
        toggle();
        setIsItemsChanged(!isItemsChanged);
        toast.success(`${response.message}`, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  // Update a user data
  const handleUpdateSubmit =async values => {
    const params = {
      ...superAdminuser,
      name: values.users[0].name,
      email: values.users[0].email,
      role: values.users[0].role
    };
    try {
      const response = await AxiosPost(`${apipath}/update-user/${superAdminuser._id}`, params);
      if (response && response.statusCode === 200) {
        toggle();
        setIsItemsChanged(!isItemsChanged);
        toast.success(`${response.message}`, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  // Update user status
  function editGeneral (el, user) {
    Swal.fire({
      text: `Are you sure you want to ${el.checked ? "Deactivate" : "Activate" }  this user`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
      className: "styleTitle",
      customClass: {
        container: "sweet-alert-container",
        popup: "sweet-alert-popup",
        title: "sweet-alert-title",
        icon: "sweet-alert-icon",
        actions: "sweet-alert-confirmbutton",
        confirmButton: "swal2-confirm"
      }
    }).then(result => {
      if (result.isConfirmed) {
        changeStatus(user);
      }
    }).catch(err => console.log(err));
  }

  const changeStatus = async user => {
    const params = {
      _id: user._id,
      status: user?.status === "Verified" ? "Deactivated" : "Verified"
    };
    try {
      const response = await AxiosPost(`${apipath}/user-status-update`, params);
      if (response && response.statusCode === 200) {
        setIsItemsChanged(prev => !prev);
        toast.success(`${response.message}`, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  const onClickDelete = superAdminuser => {
    setSuperAdminuser(superAdminuser);
    setDeleteModal(true);
  };

  const handleDeleteAdminuser = async () => {
    setDeleteModal(false);
    if (superAdminuser._id) {
      try {
        const response = await AxiosPost(`${apipath}/hard-delete-user/${superAdminuser._id}`, {});
        if (response && response.statusCode === 200) {
          setIsItemsChanged(prev => !prev);
          toast.success(`${response.message}`, { autoClose: 2000 });
        }
      } catch (error) {
        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    }
  };

  return showloader ?
    <React.Fragment>
      <IsRoleAuth />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAdminuser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Greenalytics" breadcrumbItem="Superadmin Users" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={superAdminuserList}
                    isGlobalFilter={true}
                    isAddCustList={true}
                    handleAdminuserClick={handleAdminuserClicks}
                    customPageSize={10}
                    isCustomPageSize={true}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle} className="modal-lg">
                    <ModalHeader toggle={toggle} tag="h4">
                      {isEdit ? "Edit User" : "Invite User"}
                    </ModalHeader>
                    <ModalBody>
                      <Formik
                        validationSchema={stepFourValidationSchema}
                        initialValues={data}
                        onSubmit={handleSubmit}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          setErrors,
                          setTouched
                        }) => (
                          // step four form
                          <Form>
                            <FieldArray name="users">
                              {({ remove, push }) => (
                                <>
                                  <Row>
                                    <Col xs="12">
                                      {data.users.length > 0 &&
                                        data.users.map((user, index) => {
                                          const userErrors =
                                            (errors.users?.length &&
                                              errors?.users[index]) ||
                                            {};
                                          const userTouched =
                                            (touched.users?.length &&
                                              touched.users[index]) ||
                                            {};
                                          return (
                                            <div
                                              className="position-relative shadow border mb-3 p-3 d-block"
                                              key={index + 1}
                                              // count={index + 1}
                                            >
                                              <div data-repeater-list="group-a">
                                                <Row data-repeater-item>
                                                  <Col lg="4" className="">
                                                    <Field
                                                      id={`users[${index}]`}
                                                      type="email"
                                                      name={`users[${index}].email`}
                                                      placeholder="User E-mail"
                                                      className={
                                                        "form-control" +
                                                        (userErrors.email &&
                                                          userTouched.email
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name={`users[${index}].email`}
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </Col>

                                                  <Col lg="4" className="">
                                                    <Field
                                                      type="text"
                                                      name={`users[${index}].name`}
                                                      className={
                                                        "form-control" +
                                                        (userErrors.name &&
                                                          userTouched.name
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                      placeholder="Name"
                                                    />
                                                    <ErrorMessage
                                                      name={`users[${index}].name`}
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </Col>

                                                  <Col lg="4" className="">
                                                    <Field
                                                      as="select"
                                                      name={`users[${index}].role`}
                                                      className={
                                                        "form-select" +
                                                        (userErrors.role &&
                                                          userTouched.role
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    >
                                                      <option value="">
                                                        Select Role
                                                      </option>
                                                      <option value="Owner">
                                                        Owner
                                                      </option>
                                                      <option value="Stakeholder">
                                                        Stakeholder
                                                      </option>
                                                      <option value="Admin">
                                                        Admin
                                                      </option>
                                                      <option value="User">
                                                        User
                                                      </option>
                                                    </Field>

                                                    <ErrorMessage
                                                      name={`users[${index}].role`}
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </Col>

                                                  {data.users.length > 1
                                                    ? (
                                                      <Col
                                                        lg="1"
                                                        className="close-btn"
                                                      >
                                                        <i
                                                          className="mdi mdi-close-box mdi-close-btn"
                                                          onClick={() => {
                                                            setData({ users: data.users.filter((d, dataIndex) => dataIndex !== index ) });
                                                            remove(index);
                                                          }} />
                                                      </Col>
                                                    )
                                                    : (
                                                      ""
                                                    )}
                                                </Row>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      {!isEdit && (
                                        <Button
                                          onClick={() => {
                                            setData({
                                              users: [
                                                ...data.users,
                                                {
                                                  email: "",
                                                  name: "",
                                                  role: ""
                                                }
                                              ]
                                            });
                                            push({
                                              email: "",
                                              name: "",
                                              role: ""
                                            });
                                          }}
                                          color="default"
                                          className="mt-3 mt-lg-0 btn btn-outline-primary btn-sm"
                                        >
                                          + Add more{" "}
                                        </Button>
                                      )}{" "}
                                    </Col>
                                  </Row>
                                  <Row className="mt-3 next-prev-btn">
                                    <Col>
                                      <button
                                        className="btn btn-primary btn-block"
                                        type="button"
                                        onClick={() => {
                                          setErrors(errors);
                                          const filterTouched = data.users.map(
                                            () => ({
                                              email: true,
                                              name: true,
                                              role: true
                                            })
                                          );
                                          setTouched({ users: filterTouched });
                                          const checkError = values.users.some(
                                            item => {
                                              if (
                                                item.email === "" ||
                                                item.name === "" ||
                                                item.role === ""
                                              ) {
                                                return true;
                                              } else {
                                                return false;
                                              }
                                            }
                                          );
                                          if (!checkError) {
                                            if (isEdit) {
                                              if (!errors?.users) {
                                                handleUpdateSubmit(values);
                                              }
                                            } else {
                                              if (!errors?.users) {
                                                handleSubmit(values);
                                              }
                                            }
                                          }
                                        }}
                                      >
                                        Submit
                                      </button>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </FieldArray>
                          </Form>
                        )}
                      </Formik>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    : (
      <Loading />
    );
};

export default UserList;
