import React, { useState, useEffect, useMemo } from "react";
import { AxiosGet, AxiosPost } from "common/axiosService";
import { Action, Date, Role, Name } from "./CryptoCol";
import { toast } from "react-toastify";
import IsRoleAuth from "pages/Authentication/IsRoleAuth";
import { Button, Card, CardBody, CardTitle, Col, Container, Row, Table } from "reactstrap";
import TableContainer from "components/Common/TableContainer";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import dateFormat from "dateformat";
import DeleteModal from "components/Common/DeleteModal";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
/*eslint-disable*/

const CompletedReports = () => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;

  const [savedraft, setSavedraft] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sebiyear, setSebiYear] = useState("");
  const [isItemsChanged, setIsItemsChanged] = useState(false);
  const [reportTimeline, setReportTimeline] = useState({});

  const handleDeleteSebiReport = async () => {
    try {
      const response = await AxiosPost(`${apipath}/delete-sebi-data/${sebiyear}`, {});
      if (response && response && response.statusCode === 200) {
        setIsItemsChanged(prev => !prev);
        toast.success(`${response.message}`, {
          autoClose: 2000
        });
      } 
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });

    }
    setDeleteModal(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/specific-timeline-logs/BRSR`);
        if (response && response.statusCode === 200) {
          setReportTimeline(response.data);
        }
      } catch (error) {
       toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
    (async () => {
      try {
        const response = await AxiosGet(`${apipath}/get-all-sebi-data`);
        if (response && response.statusCode === 200) {
          setSavedraft(response.data);
          setLoading(true);
        }
      } catch (error) {
       toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
      }
    })();
  }, [isItemsChanged]);

  const deleteSebiReport = () => {
    setDeleteModal(true);
  };

  const data = reportTimeline.length > 0 ? reportTimeline : [];
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "",
        filterable: true,
        Cell: (row) => <div>{Number(row.row.id) + 1}</div>
      },
      {
        Header: "Date",
        accessor: "modifiedAt",
        filterable: true,
        Cell: cellProps => <Date {...cellProps} />
      },
      {
        Header: "Role",
        accessor: "performerId.role",
        filterable: true,
        Cell: cellProps => <Role {...cellProps} />
      },
      {
        Header: "Email",
        accessor: "performerId.email",
        filterable: true,
        Cell: cellProps => <Name {...cellProps} />
      },
      {
        Header: "Action",
        accessor: "actionDone",
        filterable: true,
        Cell: cellProps => <Action {...cellProps} />
      }
    ],
    []
  );
  const asand = savedraft && savedraft.filter((list) => list.isReportComplete);
  const disand = savedraft && savedraft.filter((list) => !list.isReportComplete);

  return (
    <React.Fragment>
      <IsRoleAuth />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSebiReport}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">Completed Reports</h4>
                    <div>
                      <Link to="/admin/report">
                        <Button color="primary">Create New Report</Button>
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Completed Reports</CardTitle> */}
                  <SimpleBar style={{ minHeight: "250px", maxHeight: "250px" }}>
                    <div className="table-responsive">
                      <Table className="table table-nowrap align-middle table-hover mb-0">
                        <tbody>
                          {loading ?
                            asand.length >= 1
                              ? asand.map((file, i) => (
                                <tr key={"_file_" + i}>
                                  <td width="4%">
                                    <div className="avatar-sm">
                                      <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                                        <i className="bx bxs-file-doc" />
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <h5 className="font-size-14 mb-1">
                                      <Link to="#" className="text-dark">
                                          BRSR REPORT-{file.year}
                                      </Link>
                                    </h5>
                                    <p className="text-muted mb-0">Last Update : {dateFormat(`${file.modifiedAt}`, "mmm dS yyyy, h:MM TT")}</p>
                                  </td>
                                  <td width="3%" className="text-align">
                                    {file.status === "Approve" && <span className="badge bg-success">Approved</span>}
                                    {file.status === "Pending" && <span className="badge bg-warning">Sent for Review</span>}
                                    {file.status === "Reject" && <span className="badge bg-danger">Rejected</span>}
                                  </td>
                                  <td width="3%">
                                    <Link to={`/admin/report/brsr/${file.year}`} className="text-success p-1">
                                      <Button
                                        color="light"
                                        className="btn btn-soft-success"
                                      >
                                      Edit
                                      </Button>
                                    </Link>
                                  </td>
                                  <td width="3%" className="text-align">
                                    <i className="dripicons-paperclip"></i>
                                  </td>
                                  <td width="3%" className="text-align">
                                    <a className="text-dark" onClick={async () => {
                                      const userToken = localStorage.getItem("accessToken");
                                      try {
                                        const response = await AxiosGet(`${apipath}/download-brsr-pdf/${file.filePath}/${userToken}`);
                                        if (response && response.statusCode === 200) {
                                          let a = document.createElement("a");
                                          a.download = `BRSR-Report${file.year}.pdf`;
                                          a.href = `data:application/pdf;base64,${response.data[0]}`;
                                          a.click();
                                          a.remove();
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}>
                                      <i className="bx bx-download h3 m-0" />
                                    </a>
                                  </td>
                                  {/* <td style={{ width: "90px" }}>
                                    <div>
                                      <ul className="list-inline mb-0 font-size-16 next-previous-btn ">
                                        <li className="list-inline-item">

                                        </li>
                                        <li className="list-inline-item" onClick={async () => {
                                          const userToken = localStorage.getItem("accessToken");
                                          try {
                                            const response = await AxiosGet(`${apipath}/download-brsr-pdf/${file.filePath}/${userToken}`);
                                            if (response && response.statusCode === 200) {
                                              let a = document.createElement("a");
                                              a.download = `BRSR-Report${file.year}.pdf`;
                                              a.href = `data:application/pdf;base64,${response.data[0]}`;
                                              a.click();
                                              a.remove();
                                            } else {
                                              toast.error(response.message);
                                              return;
                                            }
                                          } catch (error) {
                                            toast.error("No Data Found Something Went Wrong", { toastId: "nodata" });
                                          }
                                        }}>
                                          <a className="text-dark">
                                            <i className="bx bx-download h3 m-0" />
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td> */}
                                </tr>
                              )):
                              <h4 className="react-activity">You have not generated any reports yet</h4>
                            :
                            <div className="react-activity">
                              <Dots color="#3C7C44" size={20} speed={0.6} />
                            </div>
                          }

                        </tbody>
                      </Table>
                    </div>
                  </SimpleBar>
                </CardBody>
              </Card>
            </Col>

            {/* <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Reports in Draft</CardTitle>
                  <SimpleBar style={{ minHeight: "250px", maxHeight: "250px" }}>
                    <div className="table-responsive" >
                      <Table className="table table-nowrap align-middle table-hover mb-0">
                        <tbody>
                          {loading ?
                            disand.length >= 1
                              ? disand.map((file, i) =>
                              // if(i > 2) return;
                                (
                                  <tr key={"_file_" + i}>
                                    <td>
                                      <h5 className="text-truncate font-size-14 mb-1">
                                        <Link to="#" className="text-dark">
                                      BRSR REPORT-{file.year}
                                        </Link>
                                      </h5>
                                      <p className="text-muted mb-0">Last Update: {dateFormat(`${file.modifiedAt}`, "mmm dS yyyy, h:MM TT")}</p>
                                    </td>
                                    <td style={{ width: "90px" }}>
                                      <div>
                                        <ul className="list-inline mb-0 font-size-16">
                                          <li className="list-inline-item">
                                            <Link to={`/admin/report/brsr/${file.year}`} className="text-success p-1">
                                              <Button
                                                color="light"
                                                className="btn btn-soft-success"
                                              >
                                           Continue
                                              </Button>
                                            </Link>
                                          </li>
                                          <li className="list-inline-item">
                                            <Link to="#" className="text-danger p-1" onClick={() => {
                                              deleteSebiReport(),
                                              setSebiYear(file.year);
                                            }}>
                                              <i className="mdi mdi-delete" />
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              ):
                              <h4 className="react-activity">No Data Found</h4>
                            :
                            <div className="react-activity">
                              <Dots color="#3C7C44" size={20} speed={0.6} />
                            </div>}

                        </tbody>
                      </Table>
                    </div>
                  </SimpleBar>
                </CardBody>

              </Card>
            </Col> */}
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Reports Activity Logs</CardTitle>
                  <div id="overview-chart" dir="ltr">
                    <TableContainer
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isCustomPageSize={true}
                      customPageSize={10}
                      isActiveMessage={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CompletedReports;
