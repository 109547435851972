import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { get as _get, set as _set } from "lodash";
import { Modal, Row, Card, Col, DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap";

import Brsrjson from "../Sebidata.json";
import Input from "components/Inputs/Input";
import FormUpload from "../Modalfileupload";
import ModalSection from ".././ModalSection";
import TextArea from "components/Inputs/TextArea";
import TableInput from "components/Inputs/TableInput";
import RadioButton from "components/Inputs/RadioButton";
import { AxiosGet, AxiosPost } from "common/axiosService";
import TableTextArea from "components/Inputs/TableTextArea";
import { updateSebiData, draftSebiData, getFile } from "../../../../store/newReport/actions";

const MPD = (props) => {
  const apipath = process.env.REACT_APP_ADMIN_API_PATH;
  const initialState = Brsrjson.sectionB;
  const { draftSebiData, draftData, updateSebiData, newReport, onSectionChange, draftReport, oldData, onSectionToggle, filesData, getFile } = props;

  const [filedatachange, setFileDataChange] = useState(true);

  const [sebiCurrentData, setSebiCurrentData] = useState();
  const [data, setData] = useState(draftData? draftData : oldData ? oldData : initialState);

  const [modal_center, setmodal_center] = useState(false);
  const [modalData, setModalData] = useState({ name: "", value: "", type: "", title: "" });
  const [fileData, setFileData] = useState({ year: newReport?.currentYear?.year, section: "sectionB", subSection: "mdp", keyName: "" });
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  useEffect (() => {
    if (newReport) {
      setSebiCurrentData(newReport.currentYear);
    }
    if (!filesData) {
      getFile({
        "year": newReport?.currentYear?.year,
        "section": "sectionB",
        "subSection": "mdp"
      });
    }
  }, [newReport, filesData]);

  useEffect(() => {
    getFile({
      "year": newReport?.currentYear?.year,
      "section": "sectionB",
      "subSection": "mdp"
    });
  }, [filedatachange]);

  const onHandleChange = (e) => {
    _set(data, e.target.name, e.target.value);
    draftSebiData({ ...draftReport, MPD_disclosure: data });
    setData({ ...data });
  };

  function tog_center () {
    setmodal_center(!modal_center);
  }

  const oninputFocus = (e, title, name1) => {
    if(!modal_center){
      e.target.blur();
    }
    setModalData({
      title: title,
      name: name1,
      value: _get(data, name1, "")
    });
    tog_center();
  };

  function tog_backdrop () {
    setmodal_backdrop(!modal_backdrop);
  }

  const handleDeleteFile = async (filename, key) => {
    try {
      const response = await AxiosPost(`${apipath}/remove-stored-document`, {
        "year": newReport?.currentYear?.year,
        "section": "sectionB",
        "subSection": "mdp",
        "fileName": filename,
        "keyName": key
      });
      if(response && response.statusCode === 200){
        getFile({
          "year": newReport?.currentYear?.year,
          "section": "sectionB",
          "subSection": "mdp"
        });
        toast.success("Document Deleted succesfully");

      }
    } catch (error) {
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  return(
    <React.Fragment>
      <Modal className="center-modal"
        size="lg" isOpen={modal_center}
        toggle={() => { tog_center(); }}
        centered
        autoFocus={false}
        backdrop={"static"}
      >
        <ModalSection modalData={modalData} setModalData={setModalData} setState={setData} state={data} setmodal_center={setmodal_center} />
        <div className="modal-footer">
          <button
            onClick={(e) => {
              setmodal_center(false);
              e.target.blur();
              setModalData({ name: "", value: "", type: "", title: "" });
            }}
            className="btn btn-primary"
          >Update</button>
        </div>
      </Modal>
      <FormUpload tog_backdrop={tog_backdrop} modal_backdrop={modal_backdrop} setmodal_backdrop={setmodal_backdrop} fileData={fileData} setFileDataChange={setFileDataChange}/>
      <Row>
        <div className="col-lg-12">
          <div className="card posifix">
            <div className="card-body">
              <p>This section is aimed at helping businesses demonstrate the structures, policies and processes put in place towards adopting the NGRBC Principles and Core Elements. </p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  _set(sebiCurrentData, "sectionB", data);
                  updateSebiData(sebiCurrentData);
                  draftSebiData({ ...draftReport, MPD_disclosure: false });
                }}>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th width="25%">Disclosure Questions </th>
                      <th>P1</th>
                      <th>P2</th>
                      <th>P3</th>
                      <th>P4</th>
                      <th>P5</th>
                      <th>P6</th>
                      <th>P7</th>
                      <th>P8</th>
                      <th>P9</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th colSpan="10">Policy and management processes</th>
                    </tr>
                    <tr>
                      <td>1.A. Whether your entity’s policy/policies cover each principle and its core elements of the NGRBCs. (Yes/No) </td>
                      {[...Array(9).keys()].map((column, i) => (
                        <td width="8%" key={`confirmation_re_policies${i}`}>
                          <RadioButton
                            name={`policy_and_management.policies_cover_each_principle.p${i+1}`}
                            value={_get(data, `policy_and_management.policies_cover_each_principle.p${i+1}`, "")}
                            onChange={onHandleChange}
                            className="sebi-radio"
                            id={`radio_77button${i}`}
                          />
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td>B. Has the policy been approved by the Board? (Yes/No)</td>
                      {[...Array(9).keys()].map((column, i) => (
                        <td width="8%" key={`confirmation_re_policies${i}`}>
                          <RadioButton
                            name={`policy_and_management.approved_by_the_board.p${i+1}`}
                            value={_get(data, `policy_and_management.approved_by_the_board.p${i+1}`, "")}
                            onChange={onHandleChange}
                            className="sebi-radio"
                            id={`radio_78button${i}`}
                          />
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td>C. Web Link of the Policies, if available</td>
                      <td colSpan={9}>
                        <Input
                          name="policy_and_management.weblink.p1"
                          value={_get(data, "policy_and_management.weblink.p1", "")}
                          onChange={onHandleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>2. Whether the entity has translated the policy into procedures. (Yes / No)</td>
                      {[...Array(9).keys()].map((column, i) => (
                        <td width="8%" key={`confirmation_re_policies${i}`}>
                          <RadioButton
                            name={`policy_and_management.entity_has_translated_the_policy.p${i+1}`}
                            value={_get(data, `policy_and_management.entity_has_translated_the_policy.p${i+1}`, "")}
                            onChange={onHandleChange}
                            className="sebi-radio"
                            id={`radio_76button${i}`}
                          />
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td>3. Do the enlisted policies extend to your value chain partners? (Yes/No)</td>
                      {[...Array(9).keys()].map((column, i) => (
                        <td width="8%" key={`confirmation_re_policies${i}`}>
                          <RadioButton
                            name={`policy_and_management.do_the_enlisted_policies_extend.p${i+1}`}
                            value={_get(data, `policy_and_management.do_the_enlisted_policies_extend.p${i+1}`, "")}
                            onChange={onHandleChange}
                            className="sebi-radio"
                            id={`radio_79button${i}`}
                          />
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td colSpan={10}>
                    4. Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council,
                    Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and mapped to each principle.
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={10}>
                        <table className="table table-bordered mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="text-align">P1</th>
                              <th className="text-align">P2</th>
                              <th className="text-align">P3</th>
                              <th className="text-align">P4</th>
                              <th className="text-align">P5</th>
                              <th className="text-align">P6</th>
                              <th className="text-align">P7</th>
                              <th className="text-align">P8</th>
                              <th className="text-align">P9</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {[...Array(9).keys()].map((column, i) => (
                                <TableInput
                                  key={`confirmation_re_policies${i}`}
                                  name={`policy_and_management.national_and_international_codes.p${i+1}`}
                                  value={_get(data, `policy_and_management.national_and_international_codes.p${i+1}`, "")}
                                  onChange={onHandleChange}
                                  onFocus={oninputFocus}
                                  title={`P${i+1}`}
                                />
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </td>

                    </tr>
                    <tr>
                      <td colSpan={10}>5. Specific commitments, goals and targets set by the entity with defined timelines, if any.</td>
                    </tr>
                    <tr>
                      <td colSpan={10}>
                        <table className="table table-bordered mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="text-align">P1</th>
                              <th className="text-align">P2</th>
                              <th className="text-align">P3</th>
                              <th className="text-align">P4</th>
                              <th className="text-align">P5</th>
                              <th className="text-align">P6</th>
                              <th className="text-align">P7</th>
                              <th className="text-align">P8</th>
                              <th className="text-align">P9</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {[...Array(9).keys()].map((column, i) => (
                                <TableInput
                                  key={`confirmation_re_policies${i}`}
                                  name={`policy_and_management.commitments_goals_and_targets.p${i+1}`}
                                  value={_get(data, `policy_and_management.commitments_goals_and_targets.p${i+1}`, "")}
                                  onChange={onHandleChange}
                                  onFocus={oninputFocus}
                                  title={`P${i+1}`}
                                />
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={10}>6. Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met. </td>
                    </tr>
                    <tr>
                      <td colSpan={10}>
                        <table className="table table-bordered mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="text-align">P1</th>
                              <th className="text-align">P2</th>
                              <th className="text-align">P3</th>
                              <th className="text-align">P4</th>
                              <th className="text-align">P5</th>
                              <th className="text-align">P6</th>
                              <th className="text-align">P7</th>
                              <th className="text-align">P8</th>
                              <th className="text-align">P9</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {[...Array(9).keys()].map((column, i) => (
                                <TableInput
                                  key={`confirmation_re_policies${i}`}
                                  name={`policy_and_management.performance_of_the_entity_against.p${i+1}`}
                                  value={_get(data, `policy_and_management.performance_of_the_entity_against.p${i+1}`, "")}
                                  onChange={onHandleChange}
                                  onFocus={oninputFocus}
                                  title={`P${i+1}`}
                                />
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th colSpan="10">Governance, leadership and oversight</th>
                    </tr>
                    <tr>
                      <td>7. Statement by director responsible for the business responsibility report, highlighting ESG related challenges,
                       targets and achievements <i> (listed entity has flexibility regarding the placement of this disclosure)
                      </i></td>
                      <TableTextArea
                        colSpan={9}
                        className="text-align"
                        name="director_responsible_for_the_business_responsibility"
                        value={_get(data, "director_responsible_for_the_business_responsibility", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>8. Details of the highest authority responsible for implementation and oversight of the Business Responsibility policy (ies). </td>
                      <TableTextArea
                        colSpan={9}
                        className="text-align"
                        name="details_of_the_highest_authority_responsible"
                        value={_get(data, "details_of_the_highest_authority_responsible", "")}
                        onChange={onHandleChange}
                      />
                    </tr>
                    <tr>
                      <td>9. Does the entity have a specified Committee of the Board/ Director responsible for decision making on
                       sustainability related issues? (Yes / No). If yes, provide details. </td>
                      <td colSpan="9" className="text-align">
                        <RadioButton
                          name="does_the_entity_have_a_specified_committee_of_the_board"
                          value={_get(data, "does_the_entity_have_a_specified_committee_of_the_board", "")}
                          onChange={onHandleChange}
                          id="radio_2button" />

                        <TextArea
                          name="provide_details"
                          value={_get(data, "provide_details", "")}
                          onChange={onHandleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.businesses_demonstrate?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "businesses_demonstrate" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}
                  {filesData?.businesses_demonstrate?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "businesses_demonstrate" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "businesses_demonstrate" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2">10. Details of Review of NGRBCs by the Company:</p>
                <table className="table table-bordered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th rowSpan="2">Subject for Review</th>
                      <th colSpan="9">Indicate whether review was undertaken by Director / Committee of the Board/ Any other Committee </th>
                      <th colSpan="9">Frequency (Annually/ Half yearly/ Quarterly/ Any other – please specify) </th>
                    </tr>
                    <tr>
                      <th>P1</th>
                      <th>P2</th>
                      <th>P3</th>
                      <th>P4</th>
                      <th>P5</th>
                      <th>P6</th>
                      <th>P7</th>
                      <th>P8</th>
                      <th>P9</th>
                      <th>P1</th>
                      <th>P2</th>
                      <th>P3</th>
                      <th>P4</th>
                      <th>P5</th>
                      <th>P6</th>
                      <th>P7</th>
                      <th>P8</th>
                      <th>P9</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="20%" className="text-align-left">Performance against above policies dand follow up action </td>
                      <TableTextArea
                        colSpan={9}
                        className="text-align"
                        name="details_of_review_of_ngrbcs_by_the_company.performance_against_above_policies.indicate.p1"
                        value={_get(data, "details_of_review_of_ngrbcs_by_the_company.performance_against_above_policies.indicate.p1", "")}
                        onChange={onHandleChange}
                      />
                      <td colSpan={9} className="text-align">
                        <select
                          name="details_of_review_of_ngrbcs_by_the_company.performance_against_above_policies.frequency.p1"
                          value={_get(data, "details_of_review_of_ngrbcs_by_the_company.performance_against_above_policies.frequency.p1", "")}
                          onChange={onHandleChange}
                          className="form-select">
                          <option value="">Select Option</option>
                          <option value="Annually">Annually</option>
                          <option value="Half yearly">Half yearly</option>
                          <option value="Quarterly">Quarterly</option>
                          <option value="Monthly">Monthly</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%" className="text-align-left">Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliances </td>
                      <TableTextArea
                        name="details_of_review_of_ngrbcs_by_the_company.compliance_with_statutory.indicate.p1"
                        value={_get(data, "details_of_review_of_ngrbcs_by_the_company.compliance_with_statutory.indicate.p1", "")}
                        onChange={onHandleChange}
                        colSpan={9}
                        className="text-align"
                      />
                      <td colSpan={9} className="text-align">
                        <select
                          name="details_of_review_of_ngrbcs_by_the_company.compliance_with_statutory.frequency.p1"
                          value={_get(data, "details_of_review_of_ngrbcs_by_the_company.compliance_with_statutory.frequency.p1", "")}
                          onChange={onHandleChange}
                          className="form-select">
                          <option value="">Select Option</option>
                          <option value="Annually">Annually</option>
                          <option value="Half yearly">Half yearly</option>
                          <option value="Quarterly">Quarterly</option>
                          <option value="Monthly">Monthly</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.review_of_ngrbcs?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "review_of_ngrbcs" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}
                  {filesData?.review_of_ngrbcs?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "review_of_ngrbcs" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "review_of_ngrbcs" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                <p className="mt-2"><b>11. Has the entity carried out independent assessment/ evaluation of the working of
               its policies by an external agency? (Yes/No). If yes, provide name of the agency.</b></p>
                <table className="table table-bordered mb-0 ">
                  <thead className="table-light">
                    <tr>
                      <th className="text-align">P1</th>
                      <th className="text-align">P2</th>
                      <th className="text-align">P3</th>
                      <th className="text-align">P4</th>
                      <th className="text-align">P5</th>
                      <th className="text-align">P6</th>
                      <th className="text-align">P7</th>
                      <th className="text-align">P8</th>
                      <th className="text-align">P9</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {[...Array(9).keys()].map((column, i) => (
                        <td key={`external_agency${i}`}>
                          <RadioButton
                            name={`entity_carried_out_independent_assessment.p${i+1}`}
                            value={_get(data, `entity_carried_out_independent_assessment.p${i+1}`, "")}
                            onChange={onHandleChange}
                            id={`radio_120button${i}`}
                          />
                          <Input
                            name={`entity_carried_out_independent_assessment.p${i+1}name`}
                            value={_get(data, `entity_carried_out_independent_assessment.p${i+1}name`, "")}
                            onChange={onHandleChange}
                            onFocus={oninputFocus}
                            title={`P${i+1}`}
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>

                <Row className="mt-2 align-items-center">
                  {filesData?.carried_out_independent?.map((item, index) => {
                    let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                    let name = item.split("-")[0];
                    return (
                      <Col xl={2} sm={4} key={index}>
                        <Card className="shadow-none border">
                          <div >
                            <div className="float-end" style={{ marginTop: "0.625rem" }}>
                              <UncontrolledDropdown direction="left mt-2">
                                <DropdownToggle
                                  color="white"
                                  className="btn btn-link text-muted mt-n2"
                                >
                                  <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>

                                <DropdownMenu>
                                  <button type="button" className="dropdown-item"
                                    onClick={ async () => {
                                      try {
                                        const userToken = localStorage.getItem("accessToken");
                                        const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                        if (res && (res.statusCode === 200 && res.data[0])) {
                                          let a = document.createElement("a");
                                          a.download = `${name}.${ext}`;
                                          a.href = `data:application/${ext};base64,${res.data[0]}`;
                                          a.click();
                                          a.remove();
                                          toast.success("Download Succesfully");
                                        }
                                      } catch (error) {
                                        toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                      }
                                    }}
                                  >Download</button>
                                  <button type="button" className="dropdown-item"
                                    onClick={() => handleDeleteFile(item, "carried_out_independent" )}
                                  >Delete</button>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="avatar-xs me-3 mb-3">
                              <div className="avatar-title bg-transparent rounded">
                                {ext === "sheet"
                                  ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                  : ext === "png"
                                    ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                    : ext === "pdf"
                                      ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                      <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                }
                              </div>
                            </div>
                            <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                          </div>
                        </Card>
                      </Col>);

                  })}
                  {filesData?.carried_out_independent?.length > 0 && <Col className="add_doc" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "carried_out_independent" });
                  }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                </Row>

                <div style={{ maxWidth: "20%" }}>
                  <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                    tog_backdrop();
                    setFileData({ ...fileData, keyName: "carried_out_independent" });
                  }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                </div>

                {(data?.policy_and_management?.policies_cover_each_principle?.p1 === "No" || data?.policy_and_management?.policies_cover_each_principle?.p2 === "No" ||
                 data?.policy_and_management?.policies_cover_each_principle?.p3 === "No" || data?.policy_and_management?.policies_cover_each_principle?.p4 === "No" ||
                 data?.policy_and_management?.policies_cover_each_principle?.p5 === "No" || data?.policy_and_management?.policies_cover_each_principle?.p6 === "No" ||
                 data?.policy_and_management?.policies_cover_each_principle?.p7 === "No" || data?.policy_and_management?.policies_cover_each_principle?.p8 === "No" ||
                 data?.policy_and_management?.policies_cover_each_principle?.p9 === "No")
                  ?
                  (
                    <>
                      <p className="mt-2">12. If answer to question (1) above is “No” i.e. not all Principles are covered by a policy,reasons to be stated:</p>
                      <div className="table-responsive">
                        <table className="table table-bordered border-primary mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="text-align" width="5%">Sr. No.</th>
                              <th className="text-align">Questions</th>
                              <th className="text-align">P1</th>
                              <th className="text-align">P2</th>
                              <th className="text-align">P3</th>
                              <th className="text-align">P4</th>
                              <th className="text-align">P5</th>
                              <th className="text-align">P6</th>
                              <th className="text-align">P7</th>
                              <th className="text-align">P8</th>
                              <th className="text-align">P9</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ textAlign: "center" }}>1</td>
                              <td>The company does not consider the Principles material to its business.</td>
                              {[...Array(9).keys()].map((column, i) => (
                                <td key={`reasons_to_be_stated${i}`}>
                                  <RadioButton
                                    name={`reasons_to_be_stated.principles_material_to_its_business.p${i+1}`}
                                    value={_get(data, `reasons_to_be_stated.principles_material_to_its_business.p${i+1}`, "")}
                                    onChange={onHandleChange}
                                    className="sebi-radio"
                                    id={`radio_80button${i}`}
                                  />
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td style={{ textAlign: "center" }}>2</td>
                              <td>The company is not at a stage where it is in a position to formulate and implement the policies on specified principles.</td>
                              {[...Array(9).keys()].map((column, i) => (
                                <td key={`formulate_and_implement${i}`}>
                                  <RadioButton
                                    name={`reasons_to_be_stated.formulate_and_implement.p${i+1}`}
                                    value={_get(data, `reasons_to_be_stated.formulate_and_implement.p${i+1}`, "")}
                                    onChange={onHandleChange}
                                    className="sebi-radio"
                                    id={`radio_81button${i}`}
                                  />
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td style={{ textAlign: "center" }}>3</td>
                              <td>The company does not have the financial or/human and<br/> technical resources available for the task.</td>
                              {[...Array(9).keys()].map((column, i) => (
                                <td key={`technical_resources_available${i}`}>
                                  <RadioButton
                                    name={`reasons_to_be_stated.technical_resources_available.p${i+1}`}
                                    value={_get(data, `reasons_to_be_stated.technical_resources_available.p${i+1}`, "")}
                                    onChange={onHandleChange}
                                    className="sebi-radio"
                                    id={`radio_82button${i}`}
                                  />
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td style={{ textAlign: "center" }}>4</td>
                              <td>It is planned to be done in the next financial year .</td>
                              {[...Array(9).keys()].map((column, i) => (
                                <td key={`planned_to_be_done${i}`}>
                                  <RadioButton
                                    name={`reasons_to_be_stated.planned_to_be_done.p${i+1}`}
                                    value={_get(data, `reasons_to_be_stated.planned_to_be_done.p${i+1}`, "")}
                                    onChange={onHandleChange}
                                    className="sebi-radio"
                                    id={`radio_49button${i}`}
                                  />
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td style={{ textAlign: "center" }}>5</td>
                              <td>Any other reason (please specify).</td>
                              <TableTextArea
                                name={"reasons_to_be_stated.any_other_reason.p1"}
                                value={_get(data, "reasons_to_be_stated.any_other_reason.p1", "")}
                                onChange={onHandleChange}
                                colSpan={9}
                                onFocus={oninputFocus}
                                title={"Any other reason (please specify)."}
                              />
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <Row className="mt-2 align-items-center">
                        {filesData?.reasons_to_be?.map((item, index) => {
                          let ext = item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2);
                          let name = item.split("-")[0];
                          return (
                            <Col xl={2} sm={4} key={index}>
                              <Card className="shadow-none border">
                                <div >
                                  <div className="float-end" style={{ marginTop: "0.625rem" }}>
                                    <UncontrolledDropdown direction="left mt-2">
                                      <DropdownToggle
                                        color="white"
                                        className="btn btn-link text-muted mt-n2"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>
                                      </DropdownToggle>

                                      <DropdownMenu>
                                        <button type="button" className="dropdown-item"
                                          onClick={ async () => {
                                            try {
                                              const userToken = localStorage.getItem("accessToken");
                                              const res = await AxiosGet(`${apipath}/download-brsr-uploaded-document/${item}/${userToken}`);
                                              if (res && (res.statusCode === 200 && res.data[0])) {
                                                let a = document.createElement("a");
                                                a.download = `${name}.${ext}`;
                                                a.href = `data:application/${ext};base64,${res.data[0]}`;
                                                a.click();
                                                a.remove();
                                                toast.success("Download Succesfully");
                                              }
                                            } catch (error) {
                                              toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
                                            }
                                          }}
                                        >Download</button>
                                        <button type="button" className="dropdown-item"
                                          onClick={() => handleDeleteFile(item, "reasons_to_be" )}
                                        >Delete</button>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="avatar-xs me-3 mb-3">
                                    <div className="avatar-title bg-transparent rounded">
                                      {ext === "sheet"
                                        ? <i className="fas fa-file-csv font-size-24 align-middle text-primary"></i>
                                        : ext === "png"
                                          ? <i className="mdi mdi-image font-size-24 align-middle text-info"></i>
                                          : ext === "pdf"
                                            ? <i className="bx bxs-file-pdf font-size-24 align-middle text-danger"></i> :
                                            <i className="mdi mdi-text-box font-size-24 text-warning"></i>
                                      }
                                    </div>
                                  </div>
                                  <h5 className="font-size-14 text-truncate mb-1" style={{ marginLeft: "0.312rem" }}>{name}</h5>
                                </div>
                              </Card>
                            </Col>);

                        })}
                        {filesData?.reasons_to_be?.length > 0 && <Col className="add_doc" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "reasons_to_be" });
                        }}><i className="bx bxs-plus-circle mdi-add-btn-50" /></Col>}
                      </Row>

                      <div style={{ maxWidth: "20%" }}>
                        <h5 className="txt-decoration mt-3 text-primary" onClick={() => {
                          tog_backdrop();
                          setFileData({ ...fileData, keyName: "reasons_to_be" });
                        }}><b className="cursor-pointer">Attach Supporting Documents <i className="dripicons-paperclip"></i></b></h5>
                      </div>
                    </>
                  )
                  : null
                }

                <div className="mt-4 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <button type="button" className="btn btn-primary w-md "
                      onClick={() =>{
                        onSectionChange(7);
                        onSectionToggle("sectionB", 7);
                      }}>Previous</button>{" "}
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary w-md ">Save</button>{" "}
                    <button type="button" className="btn btn-primary w-md "
                      onClick={() =>{
                        onSectionChange(9);
                        onSectionToggle("sectionC");
                      }}>Next</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );};

function mapStateToProps (state) {
  return {
    newReport: state.NewReportReducer.sebiForm,
    oldData: state.NewReportReducer?.sebiForm?.currentYear?.sectionB,
    draftReport: state.NewReportReducer.draftSebiForm,
    draftData: state.NewReportReducer?.draftSebiForm?.MPD_disclosure,
    filesData: state.NewReportReducer?.draftFiles?.mdp
  };
}
const mapDispatchToProps = { updateSebiData, draftSebiData, getFile };

export default connect(mapStateToProps, mapDispatchToProps)(MPD);